import PropTypes from "prop-types";
import {useActivitiesQuery} from "../../../queries/Activities";


export const useActivitiesQueryWithDict = ({
  start = '', end = '', actor = '', tags= [], order_by = '', search_term = '',
  tlps = null, targeted_sectors = [], targeted_countries = [], origin_countries = [],
  next = null, limit = 0, category = null
}) => {
  return useActivitiesQuery(
     limit, start, end, actor, tags, order_by, search_term, tlps, targeted_sectors,
    targeted_countries, origin_countries, next, category
  )
}


useActivitiesQueryWithDict.propTypes = {
  filters: PropTypes.shape().isRequired
};
