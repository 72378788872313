export const activityCategories = [
    'Data Breach',
    'Data Leak',
    'Combo List',
    "Malware",
    "DDoS Attack",
    "Phishing",
    "Logs",
    "Defacement",
    "Alert",
    "Vulnerability",
    "Ransomware"
]

export const networkTypes = [
    'telegram','openweb', 'tor'
]