import React, {useCallback, useEffect, useState} from "react";
import {Col, Container, Row, Tooltip} from "reactstrap";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import PropTypes from "prop-types";
import ColorTagInlineBadge from "../../../shared/components/badge/ColorTagInlineBadge";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import {HashLink} from "react-router-hash-link";
import {apiUrlInternal} from "../../../config/api";
import {getColorBadgeByPriority} from "../../../shared/helpers/priority";
import DeleteIconModal from "../../../shared/components/modal/DeleteIconModal";
import {useDeleteTicket} from "../../../mutations/Tickets/DeleteTicket";
import toast from "react-hot-toast";
import DownloadFileIcon from "../../../shared/components/icons/DownloadFileIcon";
import ThemedClampLines from "../../../shared/components/clamp-lines/ThemedClampLines";
import _ from "lodash";
import TLPBadge from "../../../shared/components/badge/TLPBadge";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import {hasUserEditorPermissionOnFeature} from "../../../shared/helpers/permissions";
import {PLATFORM_FEATURE_TICKETS_ANALYSIS} from "../../../shared/helpers/features";


const TicketHeader = ({ticket, toggle}) => {
  const mutation = useDeleteTicket();
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = useCallback(() => setTooltipOpen(!tooltipOpen), [setTooltipOpen, tooltipOpen]);

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast.loading('Deleting ticket');
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          toast.success('Ticket deleted successfully');
        } else if (mutation.isError) {
          toast.error('Error during the deletion of the ticket');
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.error, mutation.isLoading, mutation.isSuccess])

  const customHandle = (e) => {
    if (e.target.id !== "clamp-lines__button_id") {
      toggle();
    }
  }

  return (
    <Container className={'card-header__events'} style={{'position': 'relative'}} onClick={(e) => customHandle(e)}>
      <Row>
        <Col md={12}>
          <div className={"card__title__events"}>
            <h4 className={'bold-text'}>{ticket.title}</h4>
            {
              ticket.created_by && ticket.created_dt &&
              <p className={'subhead'}>Created
                by <b>{ticket.created_by.email}</b> on <b>{formatDateUTCtoYearMonthDayTime(ticket.created_dt)}</b></p>
            }
            <p className="descriptionInfo">
              <ThemedClampLines
                text={ticket.description}
                lines={6}
                maxLines={12}
              />
            </p>
          </div>
          {
            !_.isNull(ticket.attachment) &&
            <DownloadFileIcon
              url={`${apiUrlInternal}/tickets/attachment/${ticket.attachment.uid}`}
              filename={_.split(ticket.attachment.storage_id, '/')[1]}
              style={{fill: 'white', 'position': 'absolute', top: '0px', right: '20px'}}
              timeout={60000}
              size={24}
              className={'border-hover-white'}
            />
          }
        </Col>
        <Col md={12} className={'ticket-badge__status_ptions'}>
          <ColorTagInlineBadge
            color={getColorBadgeByPriority(ticket.priority)}
            tag={`PRIORITY:${ticket.priority.toUpperCase()}`}
          />
          <ColorTagInlineBadge
            color={'primary'}
            tag={`PRIMARY:${ticket.status.toUpperCase()}`}
          />
          {
            ticket?.ticket_coin &&
            <ColorTagInlineBadge
              color={'info'}
              tag={`TICKET COINS:${ticket.ticket_coin.coins}`}
            />
          }

          {
            ticket.tlp &&
            <TLPBadge style={{'margin-right': '7px', 'float': 'right'}} tlp={ticket.tlp.tlp}/>
          }
        </Col>

        {
          hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TICKETS_ANALYSIS) &&
          <Col md={12} className={'d-flex justify-content-end mt-2'}>
            <DeleteIconModal
              onDelete={() => {
                mutation.mutate(ticket.uid);
              }}
              message={'Do you really want to delete the ticket?'}
            />
          </Col>
        }


      </Row>

      <HashLink
        to={`tickets/${ticket.uid}`}
      >
        <ChevronRightIcon
          outline={true}
          className={'float-right ticektRightIcon'} size={32}
          id={`content-${ticket.uid}`}
        />
      </HashLink>

      <Tooltip
        placement="left"
        isOpen={tooltipOpen}
        target={`content-${ticket.uid}`}
        toggle={toggleTooltip}
      >Go to the details
      </Tooltip>

    </Container>
  )
}

TicketHeader.propTypes = {
  ticket: PropTypes.shape().isRequired,
  toggle: PropTypes.func.isRequired,
}

export default TicketHeader;
