import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";
import _ from 'lodash';

export const RulesFetcher = (accessToken, tags = [], next = null, limit = null) => {
  const urlParams = new URLSearchParams();
  if (tags) {
    tags.forEach((tag) => urlParams.append('tags', tag));
  }
  if (next !== null) {
    urlParams.set('next', next);
  }
  if (limit !== null) {
    urlParams.set('limit', limit);
  }
  return axios.get(`${apiUrlInternal}/rules?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useRulesQuery = (tags = null, next = null, limit = null, fetcher = RulesFetcher) => {
  return useQueryWithAuthentication(
    ['rules', tags, next, limit],
    (accessToken) => fetcher(accessToken, tags, next, limit),
    {staleTIme: Infinity, refetchOnWindowFocus: false, enabled: !(_.isUndefined(tags) || tags === '')}
  )
}

useRulesQuery.propTypes = {
  tags: PropTypes.array
};
