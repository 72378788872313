import React, { useState} from "react";
import PropTypes from "prop-types";
import TargetIcon from "mdi-react/TargetIcon";
import LoadingSpinner from "../../LoadingSpinner";
import {useSectorsListQuery} from "../../../../queries/SectorsList";
import {ColorTagInlineBadgeList} from "../../badge/BadgeLists";
import CardWithIcon from "../CardWithIcon";


const SectorCard = ({sectors, updateFilters, setTargetedSectors}) => {
  let sectorLabels = []

  const {data, isIdle, isLoading, isError} = useSectorsListQuery();
  const [isSectorLabelsLoad, setSectorLabelsLoad] = useState(false);
  if (isIdle || isLoading) {
    return <CardWithIcon title={"TARGETED SECTORS"} content={<LoadingSpinner/>} icon={<TargetIcon/>}/>
  }

  if (!isError && !isLoading && !isIdle && sectors.length > 0) {
    sectors.forEach((sec) => {
      const sector = data.data.find((item) => (item.uid === sec.uid || item.uid === sec))
      if (sector?.name !== undefined) {
        sectorLabels.push({
          id: sector.uid,
          name: sector.name
        })
      }
    });
    if (setTargetedSectors && !isSectorLabelsLoad) {
      setTargetedSectors(sectorLabels);
      setSectorLabelsLoad(true);
    }
  }

  return (
    <CardWithIcon
      title={"TARGETED SECTORS"}
      content={
        sectorLabels.length > 0 ?
          <ColorTagInlineBadgeList type="targeted_sectors" onTagClick={updateFilters} items={sectorLabels}
                                   size={'sm'}/> :
          <p className="custom_card__container-title">NOT AVAILABLE</p>
      }
      icon={<TargetIcon/>}
    />
  )

}

SectorCard.propTypes = {
  sectors: PropTypes.array.isRequired,
  updateFilters: PropTypes.func.isRequired,
  setTargetedSectors: PropTypes.func
}

export default SectorCard;
