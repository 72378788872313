import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrlInternal} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index";


export const SetTypoSquattingResultsAsSolvedFetcher = (accessToken, project_uid, uids) => {
  return axios.put(
    `${apiUrlInternal}/typosquatting_projects/${project_uid}/assets/results/select-as-solved`,
    uids,
    addAuthToFetchOpts(accessToken, {}, 0)
  ).then(
    res => res.data
  );
}

export const useSetTypoSquattingResultsAsSolved = (fetcher = SetTypoSquattingResultsAsSolvedFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['set-typosquatting-results-solved'],
    (accessToken, {project_uid, uids}) => fetcher(accessToken, project_uid, uids),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('set-typosquatting-results-solved');
        queryClient.invalidateQueries(['typosquatting-asset-result']);
      }}
  )
}

