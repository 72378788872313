import PropTypes from "prop-types";
import {useEventContentsIndicatorsWithPaginationQuery} from "./EventContentsIndicatorsWithPagination";


export const useSearchEventIndicatorDictPagination = (
  {uid, ioc_type}, page = null, limit = null
) => {
  return useEventContentsIndicatorsWithPaginationQuery(uid, ioc_type, limit, page);
}


useSearchEventIndicatorDictPagination.propTypes = {
  uid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  page: PropTypes.number,
  limit: PropTypes.number,
};
