const TLP_COLORS = {
  RED: 'danger',
  AMBER: 'warning',
  GREEN: 'success',
  WHITE: 'secondary',
}

const TLP_COLORS_HEX = {
  RED: '#d9534f',
  AMBER: '#f0ad4e',
  GREEN: '#5cb85c',
  WHITE: '#f7f7f7',
}

export const TLP_LABELS = ["WHITE", "GREEN", "AMBER", "RED"];


export const getTlpColor = (tlp) => TLP_COLORS[(tlp.toUpperCase())];
export const getTlpColorHex = (tlp) => TLP_COLORS_HEX[(tlp.toUpperCase())];
export const getTlpIndex = (tlp) => TLP_LABELS.indexOf(tlp.toUpperCase());
