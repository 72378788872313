import React from "react";
import {Container, Row} from "reactstrap";
import FeedlyFeedCard from "../../shared/components/card/FeedlyFeedCard";
import {useFeedlyFeedDetailQuery} from "../../queries/FeedlyFeedDetail";
import {useParams} from "react-router-dom";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";

const FeedlyFeedDetail = () => {
  const params = useParams()
  const {data, isIdle, isLoading, isError, error} = useFeedlyFeedDetailQuery(params.uid)
  if (isError) {
    return <ErrorHandler error={error}/>
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      onlyOnline
    >
      <Container>
        <Row className={'mt-3'}>
          {(isIdle || isLoading) ?
            <LoadingSpinner/> : <FeedlyFeedCard
              event={data?.data}
              isDetailCard
            />}
        </Row>
      </Container>
    </Page>
  )
}


export default FeedlyFeedDetail