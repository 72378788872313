import React, {Fragment, useEffect, useState} from "react";
import {useProjectsOrganizationKeywordQuery} from "../../queries/ProjectsOrganizationKeyword";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import paths from "../../config/paths";
import {Button, Col, Container, Row} from "reactstrap";
import IntelligenceIdentitySection from "./components/IntelligenceIdentitySection";
import {useHistory} from "react-router-dom";
import logo from "../../shared/img/iceberg.svg";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const IntelligenceIdentity = () => {
  const {
    data: dataUser,
    isIdle: isIdleOrg,
    isLoading: isLoadingOrg,
    isError: isErrorOrg,
    error: errorOrg
  } = useCurrentUserQuery();
  const [currProject, setCurrProject] = useState();
  const history = useHistory();

  const {data, isIdle, isLoading, isError, error} = useProjectsOrganizationKeywordQuery(
    dataUser?.data?.organization?.uid
  );

  useEffect(() => {
    if (!isIdle && !isLoading && data?.data && data?.data?.length > 0) {
      setCurrProject(data.data[0]);
    }
  }, [isLoading, isIdle, data?.data])

  if (isLoading || isIdle || isLoadingOrg || isIdleOrg) {
    return <LoadingSpinnerPage/>
  }

  if (isError || isErrorOrg) {
    return <ErrorHandler error={error || errorOrg}/>
  }


  if (data.data.length === 0) {
    return (
      <Page
        feature={PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS}
        onlyOnline
      >
        <img
          src={logo}
          alt={'Darkweb icon'}
          title={'Darkweb icon'}
          className={"img__darkweb-icon"}
        />
        <Container className={'dashboard'}>
          <Row>
            {
              hasUserEditorPermissionOnFeature(dataUser?.data?.modules || [], PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS) &&
              <Col>
                <Button
                  className={"rounded float-right"}
                  size="sm"
                  outline
                  onClick={() => history.push(paths.profilePath)}>
                  Edit keywords to monitor
                </Button>
              </Col>
            }
          </Row>
          <Row>
            <Col>
              <h2 className={"h2_investigate-title"}>No Projects Found</h2>
              <h3 className={"h3_investigate-subtitle"}>
                In order to use the Identity Intelligence module you must create at least one engine. <br/>
                Create a new one in the "Identity Intelligence" in your profile section.
              </h3>
            </Col>
          </Row>
        </Container>
      </Page>
    )
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS}
      onlyOnline
    >
      <Container className="dashboard">
        <Row>
          <Col>
            {
              data.data.map((project) =>
                <Button
                  className={"rounded"}
                  size="sm"
                  outline
                  key={project.uid}
                  active={currProject?.uid === project.uid}
                  onClick={() => setCurrProject(project)}>
                  {project.name}
                </Button>
              )
            }

            {
              hasUserEditorPermissionOnFeature(dataUser?.data?.modules || [], PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS) &&
              <Button className={"rounded float-right"} size="sm" outline
                      onClick={() => history.push(paths.profilePath)}>
                Edit keywords
              </Button>
            }

          </Col>
        </Row>

        {currProject && <IntelligenceIdentitySection project_uid={currProject.uid} project_name={currProject.name}/>}

      </Container>
    </Page>
  )
}


export default IntelligenceIdentity;
