import React from "react";
import PropTypes from "prop-types";
import DarkWebKeywordForm from "../form/DarkWebKeywordForm";


const KeywordProjectFormCollapseContent = ({
  project,
  keywords,
  keywordTypes,
  organization
}) =>
  <DarkWebKeywordForm
    project={project}
    keywords={keywords}
    keywordTypes={keywordTypes}
    organization={organization}
  />


KeywordProjectFormCollapseContent.propTypes = {
  project: PropTypes.object.isRequired,
  keywords: PropTypes.array.isRequired,
  keywordTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  organization: PropTypes.object.isRequired
}


export default KeywordProjectFormCollapseContent;
