import PropTypes from "prop-types";
import {Button, ButtonToolbar, Card, CardBody, Col, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import paths from "../../../config/paths";
import {useHistory} from "react-router-dom";
import ThemeModal from "../../../shared/components/ThemeModal";
import {toast} from "react-hot-toast";
import env_const from "../../../config/env_const";
import {APP_AUTH0_PROVIDER, APP_LOCAL_PROVIDER} from "../../../shared/helpers/validators";
import {useForm} from "react-hook-form";
import {sha256} from 'js-sha256';
import {useResetUserPasswordMutation} from "../../../mutations/ResetUserPassword";
import {getErrorMessageFromResponse} from "../../../shared/helpers";
import RegisteredPasswordField from "../../../shared/components/form/RegisteredPasswordField";


const BasicInfoCard = ({picture, username, email}) => {
  const h = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const {register, handleSubmit, getValues, formState: {errors}} = useForm();
  const mutation = useResetUserPasswordMutation();

  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  const onSubmit = (data) => {
    if (data.password === data.confirmpassword) {
      toggleModal()
    } else {
      toast.error('Password And Confirm Password does not match')
    }
  }

  const resetPassword = () => {
    toast.loading("Resetting password")
    let data = {};
    if (env_const.react_app_auth_provider === APP_LOCAL_PROVIDER) {
      data = {
        password: sha256(getValues('password')),
        password2: sha256(getValues('confirmpassword'))
      }
    }
    mutation.mutate(data);
  }

  const showForm = () => {
    setShowResetForm(prevState => !prevState);
  }

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        if (env_const.react_app_auth_provider === APP_AUTH0_PROVIDER) {
          h.push(paths.redirectLogoutPath);
        } else if (env_const.react_app_auth_provider === APP_LOCAL_PROVIDER) {
          toast.success('Password changed correctly');
        }
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.error, mutation.isSuccess])


  return (
    <Card>
      <CardBody>
        {env_const.react_app_auth_provider === APP_AUTH0_PROVIDER && <>
          <div className={'profile__avatar mx-auto'}>
            <img src={picture} alt="avatar"/>
          </div>
          <Row>
            <Col md={12} className={'d-flex justify-content-center mt-3 text-center'}>
              <div className={"card__title text-center"} style={{marginBottom: 0}}>
                <h5 className={'bold-text d-inline'}>{username}</h5>
                <p className={'subhead'}>{email}</p>
              </div>
            </Col>
          </Row>
        </>
        }
        <Button
          color="success"
          outline
          onClick={env_const.react_app_auth_provider === APP_AUTH0_PROVIDER ? toggleModal : showForm}
          className={'mt-4 d-block w-100'}
          style={{padding: '0.25em 0.4em'}}
        >
          Reset password
        </Button>
        {showResetForm && <form className="form form--vertical">
          <RegisteredPasswordField
            title={'Password'}
            name={'password'}
            register={register}
            errors={errors}
            rules={{required: 'The Password is required'}}
          />
          <RegisteredPasswordField
            title={'Confirm Password'}
            name={'confirmpassword'}
            register={register}
            errors={errors}
            rules={{required: 'The Confirm Password is required'}}
          />
          <Button
            outline
            className="w-100"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
            color={'success'}
          >
            Update Password
          </Button>
        </form>
        }
        <ThemeModal
          isOpen={showModal}
          toggle={toggleModal}
          modalClassName={'ltr-support'}
          className={'modal-dialog--header modal-dialog--dark'}
        >
          <div className="modal__header" key={"modal__header"}>Reset password</div>
          <div className="modal__body" key={"modal__body"}>
            {
              env_const.react_app_auth_provider === APP_AUTH0_PROVIDER ?
                'Confirm password reset? It will be sent an email to your inbox and you will be logged out.' :
                'Confirm password reset? '
            }
          </div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_cancel rounded" outline onClick={toggleModal}>Cancel</Button>{' '}
            <Button className="modal_ok rounded" outline color={'success'} onClick={
              () => {
              resetPassword();
              toggleModal();
            }
            }>Ok</Button>
          </ButtonToolbar>
        </ThemeModal>
      </CardBody>
    </Card>
  )
}


BasicInfoCard.propTypes = {
  picture: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
}

export default BasicInfoCard;
