import moment from "moment";
import {formatDateUTCtoYearMonthDayTime} from "../../shared/helpers/date";

const getIntelligenceBlock = (organization, property) => {
  const organisationActivationDate = moment(organization.activation_dt)
  const thisYear = moment().year()
  const yearDifference = thisYear - organisationActivationDate.year()
  const blockResetDate = moment(organisationActivationDate)
  blockResetDate.add(yearDifference, 'years')
  const lastBlockUpdate = organization[property] ? moment(organization[property]) : 0
  const years = blockResetDate.diff(lastBlockUpdate, 'years')
  return {block: !(years >= 1), unblockDate: formatDateUTCtoYearMonthDayTime(blockResetDate)}
}

export default getIntelligenceBlock;