import React, {Fragment, useCallback, useState} from "react";
import ThemeModal from "../ThemeModal";
import PropTypes from "prop-types";
import {Button, ButtonToolbar} from "reactstrap";


const ConfirmModal = ({
  onConfirm,
  btnText,
  title,
  body,
  disabled = false
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(
    () => setShowModal(!showModal),
    [setShowModal, showModal]
  );
  const handleConfirm = () => {
    onConfirm();
    setShowModal(!showModal);
  }

  return (
    <Fragment>
      <Button
        outline
        onClick={() => setShowModal(true)}
        color={'success'}
        className={'w-100'}
        disabled={disabled}
      >
        {btnText}
      </Button>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark consentmodal'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal}/>
          <h4 className="text-modal modal__title">{title}</h4>
        </div>
        <div className="modal__body">{body}</div>
        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
          <Button outline className="rounded" color='success' onClick={handleConfirm}>Confirm</Button>
        </ButtonToolbar>
      </ThemeModal>
    </Fragment>
  );
}

ConfirmModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  btnText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default ConfirmModal;
