import React from "react";
import OrganizationForm from "../../shared/components/form/organizations-form/OrganizationForm";
import {PLATFORM_FEATURE_ORGANIZATIONS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";


const OrganizationAdd = () =>
  <Page
    feature={PLATFORM_FEATURE_ORGANIZATIONS}
    permission={PLATFORM_PERMISSION_EDITOR}
    onlyOnline
  >
    <OrganizationForm/>
  </Page>


export default OrganizationAdd;
