import React from "react";
import {Badge} from "reactstrap";
import PropTypes from "prop-types";
import {getBadgeClasses} from "../../helpers/badge";
import {Link} from "react-router-dom";


const _ = require('lodash')


const ColorTagInlineBadgeWithLink = ({
  color,
  tag,
  url,
  size = '',
  badgeStyle = {}
}) => {
  const classes = getBadgeClasses(size);

  return <Link to={`${url}/${tag.uid}`}><Badge color={color} className={classes} style={badgeStyle}>
    {_.toUpper(tag.name)}
  </Badge></Link>;
}

ColorTagInlineBadgeWithLink.propTypes = {
  color: PropTypes.string.isRequired,
  tag: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  size: PropTypes.string,
  badgeStyle: PropTypes.object
}

export default ColorTagInlineBadgeWithLink;
