/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class RegistredRadioField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    radioValue: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    defaultChecked: false,
    disabled: false,
    radioValue: '',
    className: '',
  };

  componentDidMount() {
    const { onChange, defaultChecked } = this.props;
    onChange(defaultChecked);
  }

  render() {
    const {
      disabled, className, name, value, onChange, label, radioValue
    } = this.props;
    const RadioButtonClass = classNames({
      'radio-btn': true,
      disabled,
    });

    return (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label
          className={`${RadioButtonClass}${className ? ` radio-btn--${className}` : ''}`}
        >
          <input
            className="radio-btn__radio"
            name={name}
            type="radio"
            onChange={(e) => {
                onChange(e);
              }}
            checked={value === radioValue}
            value={radioValue}
            disabled={disabled}
          />
          <span className="radio-btn__radio-custom" />
          {className === 'button'
            ? (
              <span className="radio-btn__label-svg">
                <CheckIcon className="radio-btn__label-check" />
                <CloseIcon className="radio-btn__label-uncheck" />
              </span>
            ) : ''}
          <span className="radio-btn__label">{label}</span>
        </label>
      );
  }
}
