import React, {Fragment, useEffect, useState} from "react"
import PropTypes from "prop-types";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import ChatData from "../../TakeDownService/components/ChatData";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import {useTicketsUsersQuery} from "../../../queries/Tickets";
import {canUserEditTickets, hasUserEditorPermissionOnFeature} from "../../../shared/helpers/permissions";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import ControlledSelectWithTitle from "../../../shared/components/form/ControlledSelectWithTitle";
import _ from "lodash";
import {useAssignUserTakedownTicketMutation} from "../../../mutations/TakeDown/AssignUserTicket";
import toast from "react-hot-toast";
import {useForm} from "react-hook-form";
import DeleteButtonModal from "../../../shared/components/modal/DeleteButtonModal";
import {useRequestSentTakedownTicketMutation} from "../../../mutations/TakeDown/RequestSent";
import {useCloseTakedownTicketMutation} from "../../../mutations/TakeDown/CloseTicket";
import {useIndicatorDetailsByValueQuery} from "../../../queries/IndicatorInvestigate";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import IndicatorRiskScoreGauge from "../../InvestigateDetail/components/IndicatorRiskScoreGauge";
import {ALLOWED_ATTACHMENT_FORMATS, isFilesValid, isTakeDownTicketClosed} from "../../TakeDownService/helper";
import axios from "axios";
import {apiUrlInternal} from "../../../config/api";
import {addAuthToAxiosOpts} from "../../../config/queryopts";
import ControlledFilepond from "../../../shared/components/form/ControlledFilepond";
import {useCookieAccessToken} from "../../../queries";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import {useQueryClient} from "react-query";
import TakeDownStatusHistory from "./TakeDownStatusHistory";
import {CLOSED_OPTIONS, getTicketEvents} from "../helper";
import TakeDownStatusBadges from "../../TakeDownService/components/TakeDownStatusBadges";
import TakedownTicketAttachmentTable from "./TakedownTicketAttachmentTable";
import {takeDownIndicatorQuerySections} from "../../../shared/helpers/investigate";
import TakedownProgressBar from "./TakedownProgressBar";
import {PLATFORM_FEATURE_TAKEDOWN_TICKETS} from "../../../shared/helpers/features";
import {getErrorMessageFromResponse} from "../../../shared/helpers";


const TakeDownDetailSec = ({ticketDetail}) => {
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const {data: dataUsers, isIdle: isIdleUsers, isLoading: isLoadingUsers, error: isUsersError} = useTicketsUsersQuery(
    canUserEditTickets(dataCurrentUser ? dataCurrentUser.data : null)
  );
  const [ticketUsers, setTicketUsers] = useState([]);

  const {
    data: accessToken,
    isError: tokenIsError,
    isLoading: tokenIsLoading,
    isIdle: tokenIsIdle,
    error: tokenError,
  } = useCookieAccessToken()
  const {control, getValues} = useForm();
  const [indicator_details, setIndicator_details] = useState(null);
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState(false);
  const {
    data: investigateData,
    isIdle: investigateIsIdle,
    isLoading: investigateIsLoading,
    isError: investigateIsError
  } = useIndicatorDetailsByValueQuery(
    ticketDetail.indicator,
    ticketDetail.indicator_type,
    true,
    takeDownIndicatorQuerySections
  );

  const updateUserTicketMutation = useAssignUserTakedownTicketMutation();
  const requestSentMutation = useRequestSentTakedownTicketMutation();
  const closeMutation = useCloseTakedownTicketMutation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isIdleUsers && !isLoadingUsers && !isUsersError) {
      setTicketUsers(dataUsers.data);
    }
  }, [isIdleUsers, isLoadingUsers, isUsersError, dataUsers])

  useEffect(() => {
    if (!investigateIsIdle && !investigateIsLoading && !investigateIsError) {
      setIndicator_details(investigateData.data);
    }
  }, [investigateIsIdle, investigateIsLoading, investigateIsError, investigateData])


  useEffect(() => {
    if (!closeMutation.isIdle) {
      if (closeMutation.isLoading) {
        toast.loading('Closing ticket');
      } else {
        toast.dismiss();
        if (closeMutation.isSuccess) {
          toast.success('Ticket closed successfully');
        } else if (closeMutation.isError) {
          toast.error(getErrorMessageFromResponse(closeMutation));
          closeMutation.reset();
        }
      }
    }
  }, [closeMutation.isIdle, closeMutation.isError, closeMutation.isLoading, closeMutation.isSuccess])


  useEffect(() => {
    if (!updateUserTicketMutation.isIdle) {
      if (updateUserTicketMutation.isLoading) {
        toast.loading('Assigning user');
      } else {
        toast.dismiss();
        if (updateUserTicketMutation.isSuccess) {
          toast.success('Assigned to user successfully');
        } else if (updateUserTicketMutation.isError) {
          toast.error(getErrorMessageFromResponse(updateUserTicketMutation));
          updateUserTicketMutation.reset();
        }
      }
    }
  }, [updateUserTicketMutation.isIdle, updateUserTicketMutation.isError, updateUserTicketMutation.isLoading, updateUserTicketMutation.isSuccess])

  useEffect(() => {
    if (!requestSentMutation.isIdle) {
      if (requestSentMutation.isLoading) {
        toast.loading('Change ticket status to requested');
      } else {
        toast.dismiss();
        if (requestSentMutation.isSuccess) {
          toast.success('Status changed successfully');
        } else if (requestSentMutation.isError) {
          toast.error(getErrorMessageFromResponse(requestSentMutation));
          requestSentMutation.reset();
        }
      }
    }
  }, [requestSentMutation.isIdle, requestSentMutation.isError, requestSentMutation.isLoading, requestSentMutation.isSuccess])


  const handleAssigneeChange = (option) => {
    updateUserTicketMutation.mutate({
      uid: ticketDetail.uid,
      user_uid: option.value
    });
  }

  const onTicketRequestSent = () => {
    requestSentMutation.mutate({
      uid: ticketDetail.uid
    });
  };

  const handleCloseChange = (option) => {
    closeMutation.mutate({
      uid: ticketDetail.uid,
      closed_as: option.value
    });
  }

  const btnClass = uploadBtnDisabled ? 'w-100 disabled mb-0' : 'w-100 mb-0';


  const onUpload = () => {
    const data = getValues('report');

    if (data && data.length > 0) {
      if (!isFilesValid(data)) {
        toast.error('uploaded files are not valid ex: allowed formats zip,rar,email,png,jpeg,jpg')
      } else {
        setUploadBtnDisabled(true);
        const formData = new FormData();
        _.forEach(data, (report) => {
          formData.append('files', report.file, report.name)
        });
        toast.loading('Validating and uploading files.');
        axios.post(`${apiUrlInternal}/takedown_tickets/attachment/${ticketDetail.uid}`, formData,
          addAuthToAxiosOpts(accessToken, {'Content-Type': 'multipart/form-data'}, 0),
        ).then((res) => {
          toast.dismiss();
          if (res.status === 200) {
            toast.success(`file uploaded successfully`);
            queryClient.invalidateQueries(['takedown-tickets', `/${ticketDetail.uid}`]);
            setUploadBtnDisabled(false);
          } else {
            toast.error(`Error while uploading the attachments. Please contact the administrator.`);
            setUploadBtnDisabled(false);
          }
        }).catch(() => {
          toast.dismiss();
          toast.error(`Error while uploading the attachments. Please contact the administrator.`);
          setUploadBtnDisabled(false);
        })
      }
    } else {
      toast.error('file is missing');
      setUploadBtnDisabled(false);
    }
  }

  const isEditable = canUserEditTickets(dataCurrentUser ? dataCurrentUser.data : null);
  const assigneeOptions = ticketUsers ? ticketUsers.map((user) => ({
    value: user.uid, label: _.toLower(user.email)
  })) : [];

  if (tokenIsIdle || tokenIsLoading) {
    return <Container><LoadingSpinner/></Container>
  }

  if (tokenIsError) {
    return <ErrorHandler error={tokenError}/>
  }

  return (
    <Fragment>
      <Row>
        <Col md={12} className={'mb-3'}>
          <TakedownProgressBar ticketDetail={ticketDetail}/>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          {investigateIsLoading || investigateIsIdle ? <LoadingSpinner/> : indicator_details && <IndicatorRiskScoreGauge
            score={indicator_details?.score}
            tags={indicator_details?.tags}/>
          }
          <TakeDownStatusHistory events={getTicketEvents(ticketDetail)}/>
        </Col>
        <Col md={8}>
          <Card className={'h-auto center'}>
            <CardBody className={'card__title__events mb-0'} style={{'padding': '25px'}}>
              <h4 className={'bold-text'}>Takedown request related to {ticketDetail.indicator}</h4>
              {
                ticketDetail.created_by && ticketDetail.created_dt &&
                <p className={'subhead'}>Created
                  by <b>{ticketDetail.created_by.email}</b> on <b>{formatDateUTCtoYearMonthDayTime(ticketDetail.created_dt)}</b>
                </p>
              }

              <pre className="descriptionInfo mb-2">
                {ticketDetail.description}
              </pre>
              <div style={{'margin-top': '18px'}}>
                <TakeDownStatusBadges ticket={ticketDetail} type="detail"/>
              </div>

              {isEditable && !isTakeDownTicketClosed(ticketDetail.status) &&
                <Row className="mt-2 takeDownEditOptions">
                  <Col md={4}>
                    {ticketUsers && ticketUsers.length > 0 && <ControlledSelectWithTitle
                      name={'assigned_to'}
                      title={'Assigned To'}
                      onChangeCustomHandle={handleAssigneeChange}
                      rules={{required: 'The assigned to is required'}}
                      defaultValue={_.filter(assigneeOptions, (option) => option.value === ticketDetail?.assigned_to?.uid)}
                      control={control}
                      valueFn={(value) => value}
                      options={assigneeOptions}
                    />}
                  </Col>
                  <Col md={4}>
                    <div style={{'marginTop': '25px'}}>
                      <DeleteButtonModal
                        buttonText="Request sent"
                        buttonActionText="submit"
                        onDelete={() => {
                          onTicketRequestSent();
                        }}
                        disabled={!ticketDetail.assigned_dt}
                        message={<p> are you sure ?</p>}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <ControlledSelectWithTitle
                      name={'close_as'}
                      title={'Close as'}
                      onChangeCustomHandle={handleCloseChange}
                      rules={{required: 'close option is required'}}
                      defaultValue={_.filter(CLOSED_OPTIONS, (option) => option.value === ticketDetail?.status)}
                      control={control}
                      valueFn={(value) => value}
                      options={CLOSED_OPTIONS}
                    />
                  </Col>
                </Row>
              }
            </CardBody>
          </Card>
          <Card className={'h-auto'}>
            <CardBody>
              <div className={"card__title"}>
                <h4 className={'bold-text'}>Attachments</h4>
              </div>

              {
                ticketDetail.attachments && ticketDetail.attachments.length > 0 ?
                  <TakedownTicketAttachmentTable attachments={ticketDetail.attachments}/>
                  : <p>no attachments yet to display</p>
              }

              {
                hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TAKEDOWN_TICKETS) &&
                <>
                  <span className={'form__form-group-label mt-4 mb-2'}>
                    Allowed values are: {_.join(ALLOWED_ATTACHMENT_FORMATS, ', ')}
                  </span>

                  <ControlledFilepond
                    control={control}
                    name={'report'}
                    allowMultiple={false}
                    allowRevert={false}
                    maxFiles={1}
                    defaultValue={''}
                    instantUpload={false}
                    labelIdle={'Drag & Drop your report or <span class="filepond--label-action">Browse</span>'}
                  />
                  <Button
                    outline
                    color={'success'}
                    onClick={(e) => {
                      e.preventDefault();
                      onUpload()
                    }}
                    className={btnClass}>
                    upload
                  </Button>
                </>
              }
            </CardBody>
          </Card>
          <Card className={'h-auto'}>
            <CardBody>
              <div className={"card__title"}>
                <h4 className={'bold-text'}>Messages</h4>
              </div>
              <ChatData ticket={ticketDetail}/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

TakeDownDetailSec.propTypes = {
  ticketDetail: PropTypes.shape().isRequired
}

export default TakeDownDetailSec;
