import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const AddCollectionsFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/collections`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useAddCollectionsMutation = (fetcher = AddCollectionsFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-collection'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('add-collection')}
  )
}

