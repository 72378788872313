import {default as ReactSelect} from "react-select/creatable";
import React from "react";


const CreatableSelect = ({...props}) => {
  return (
    <ReactSelect
      className="react-select"
      classNamePrefix="react-select"
      {...props}
    />
  )
}

export default CreatableSelect;
