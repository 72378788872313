import React from 'react';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import TasksTable from './components/TasksTable';
import {useHistory} from "react-router-dom";
import TaskUploadModal from './components/TaskUploadModal'
import paths from "../../config/paths";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {PLATFORM_FEATURE_SANDBOX} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";


const SandboxTasks = () => {
  const history = useHistory();
  const {data: dataCurrentUser} = useCurrentUserQuery();

  return (
    <Page
      feature={PLATFORM_FEATURE_SANDBOX}
    >
      <Container className="tasks-container">
        <Row className={'div__sticky-top'}>
          <Col md={12}>
            <h3 className="page-title">Tasks</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TaskUploadModal
              disabled={!hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_SANDBOX)}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <TasksTable onRowClick={(dataId) => {
                  history.push(`${paths.sandboxTasksPath}/${dataId}`)
                }}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default SandboxTasks;