import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import RetroHuntTaskUploadModal from './components/RetroHuntTaskUploadModal'
import {useRetroHuntTasks} from "../../queries/RetroHunt";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import InfiniteScroll from "react-infinite-scroller";
import RetroHuntTaskDetailCard from "./components/RetroHuntTaskDetailCard";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {PLATFORM_FEATURE_RETROHUNT} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";


const RetroHuntTasks = () => {
  const LOADING_STEP = 5;
  const INITIAL_ITEMS = 10;
  const [loadedItems, setLoadedItems] = useState(INITIAL_ITEMS);
  const [displayTaskArray, setDisplayTaskArray] = useState([]);
  const [taskArray, setTaskArray] = useState([]);
  const {data, isIdle, isLoading, isError, error} = useRetroHuntTasks();
  const {data: dataCurrentUser} = useCurrentUserQuery();
  
  
  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setTaskArray(data.data);
      setDisplayTaskArray(data.data.slice(0, INITIAL_ITEMS));
      setLoadedItems(INITIAL_ITEMS);
    }
  }, [isIdle, isLoading, isError, data])
  
  if (isError) {
    return <ErrorHandler error={error}/>
  }
  
  const loadMoreItems = () => {
    setTimeout(() => {
      setDisplayTaskArray(taskArray.slice(0, loadedItems + LOADING_STEP));
      setLoadedItems(loadedItems + LOADING_STEP);
    }, 1000);
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_RETROHUNT}
    >
      <Container>
        <div className={'div__sticky-top'}>
          <Row>
            <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto'}>
              <h3 className="page-title">Retrohunt Tasks</h3>
              <RetroHuntTaskUploadModal
                disabled={!hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_RETROHUNT)}
              />
            </Col>
          </Row>
        </div>

        {
          isIdle || isLoading ? <LoadingSpinner /> :
            <Row>
              <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto mb-2'}>
                {
                  taskArray.length > 0 &&
                  <InfiniteScroll
                    loadMore={loadMoreItems}
                    hasMore={loadedItems < taskArray.length}
                    loader={<LoadingSpinner />}
                  >
                    {
                      displayTaskArray.map((i, index) => (
                        <RetroHuntTaskDetailCard task={displayTaskArray[index]} key={index} />
                      ))
                    }
                  </InfiniteScroll>
                }
              </Col>
            </Row>
        }
      </Container>
    </Page>
  );
}

export default RetroHuntTasks;
