import {Badge, Button, ButtonToolbar, Card, CardBody, Col, Container, Row} from "reactstrap";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {
  useRulesByTTPQuery,
  useRulesMalwareFamiliesCountQuery,
  useRulesMalwareFamiliesQuery,
  useRulesSearchQuery,
  useRulesTTPSCountQuery
} from "../../queries/RulesSection";
import MitreFrameworkCard from "../../shared/components/card/MitreFrameworkCard";
import {useMalwareFamilyQuery} from "../../queries/MalwareFamily";
import MalwareFamilyDetailCard from "../../shared/components/card/MalwareFamilyDetailCard";
import SearchBarOnEnter from "../../shared/components/search-menu/SearchBarOnEnter";
import {paramsToObject, useQueryParams} from "../../shared/components/router/QueryNavigationHelpers";
import paths from "../../config/paths";
import ThemeModal from "../../shared/components/ThemeModal";
import RulesCollapseContent from "../RulesSection/components/collapse/RulesCollapseContent"
import RulesContentSection from "./components/RulesContentSection";
import _ from "lodash";
import RuleItem from "../Rules/components/RuleItem";
import FakeItem from "../Indicators/components/FakeItem";
import WindowedInfiniteLoader from "../../shared/components/infinite-loader/WindowedInfiniteLoader";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const RulesSection = () => {
  const TTPS_SEC = 0;
  const MALWARE_FAMILIES_SEC = 1;
  const SEARCH_SEC = 2;
  const history = useHistory();
  const params = paramsToObject(useQueryParams().entries());
  const [searchTerm, setSearchTerm] = useState(params.search || "")
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [currentSec, setCurrentSec] = useState(params.search?.length > 3 ? SEARCH_SEC : TTPS_SEC);
  const [previousSec, setPreviousSec] = useState(TTPS_SEC);
  const [malwareFamiliesDisplay, setMalwareFamiliesDisplay] = useState([]);
  const [currentRules, setCurrentRules] = useState([])
  const [ttpUid, setTTPUid] = useState()
  const [malwareFamilyUid, setMalwareFamilyUid] = useState()
  const [showModal, setShowModal] = useState(false);
  const [typingTimer, setTypingTimer] = useState()
  const RULE_LIMIT = 10
  const FAKE_ITEMS = 5
  const [next, setNext] = useState(null);
  const [totalRules, setTotalRules] = useState(0);
  const [rulesArray, setRulesArray] = useState([]);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal]);

  const {
    data: dataTTPSCount,
    isIdle: isIdleTTPSCount,
    isLoading: isLoadingTTPSCount,
    isError: isErrorTTPSCount,
    error: errorTTPSCount
  } = useRulesTTPSCountQuery();
  const {
    data: dataMalwareFamiliesCount,
    isIdle: isIdleMalwareFamiliesCount,
    isLoading: isLoadingMalwareFamiliesCount,
    isError: isErrorMalwareFamiliesCount,
    error: errorMalwareFamiliesCount
  } = useRulesMalwareFamiliesCountQuery();
  const {
    data: dataMalwareFamilies,
    isIdleMalwareFamilies,
    isLoadingMalwareFamilies,
    isErrorMalwareFamilies,
    errorMalwareFamilies
  } = useMalwareFamilyQuery(true);
  const {
    data: dataCommandsSearch,
    isIdle: isIdleCommandsSearch,
    isLoading: isLoadingCommandsSearch,
    isError: isErrorCommandsSearch,
    error: errorCommandsSearch
  } = useRulesSearchQuery(searchTerm, typingTimer, next, RULE_LIMIT)

  const {
    data: dataCommandsByTTPUid,
    isIdle: isIdleCommandsByTTPUid,
    isLoading: isLoadingCommandsByTTPUid,
    isError: isErrorCommandsByTTPUid,
    error: errorCommandsByTTPUid
  } = useRulesByTTPQuery(ttpUid)

  const {
    data: dataCommandsByMalwareFamilyUid,
    isIdle: isIdleCommandsByMalwareFamilyUid,
    isLoading: isLoadingCommandsByMalwareFamilyUid,
    isError: isErrorCommandsByMalwareFamilyUid,
  } = useRulesMalwareFamiliesQuery(malwareFamilyUid)

  useEffect(() => {
    if (!isLoadingCommandsSearch && !isIdleCommandsSearch && !isErrorCommandsSearch && dataCommandsSearch) {
      setTotalRules(dataCommandsSearch.metadata.total);

      if (!next) {
        if (dataCommandsSearch.metadata.total <= FAKE_ITEMS) {
          setRulesArray(dataCommandsSearch.data);
        } else {
          setRulesArray(dataCommandsSearch.data.concat(_.range(FAKE_ITEMS).map(() => ({isFake: true}))));
        }
      } else {
        setRulesArray(prevIndicatorArray =>
          !dataCommandsSearch.metadata.next ? _.filter(prevIndicatorArray.concat(dataCommandsSearch.data), (ioc) => !ioc.isFake) :
            _.filter(prevIndicatorArray, (ioc) => !ioc.isFake).concat(dataCommandsSearch.data).concat(_.range(FAKE_ITEMS).map(() => ({isFake: true})))
        );
      }
    }
  }, [isLoadingCommandsSearch, isIdleCommandsSearch, isErrorCommandsSearch, dataCommandsSearch]);
  useEffect(() => {
    if (!isErrorCommandsByTTPUid && !isLoadingCommandsByTTPUid && !isIdleCommandsByTTPUid && dataCommandsByTTPUid) {
      setCurrentRules(dataCommandsByTTPUid.data)
    } else {
      setCurrentRules([])
    }
  }, [dataCommandsByTTPUid, isIdleCommandsByTTPUid, isLoadingCommandsByTTPUid, isErrorCommandsByTTPUid])

  useEffect(() => {
    if (!isIdleCommandsByMalwareFamilyUid && !isLoadingCommandsByMalwareFamilyUid && !isErrorCommandsByMalwareFamilyUid && dataCommandsByMalwareFamilyUid) {
      setCurrentRules(dataCommandsByMalwareFamilyUid.data)
    } else {
      setCurrentRules([])
    }
  }, [dataCommandsByMalwareFamilyUid, isIdleCommandsByMalwareFamilyUid, isLoadingCommandsByMalwareFamilyUid, isErrorCommandsByMalwareFamilyUid])

  useEffect(() => {
    if ((searchTerm !== '' || !_.isUndefined(params.search)) && searchTerm !== params.search) {
      if (searchTerm && searchTerm.length >= 4) {
        if (typingTimer < 1 || typingTimer === undefined) {
          history.push(`${paths.rulesSectionPath}?search=${searchTerm}`);
        }
        if (currentSec !== SEARCH_SEC) {
          setPreviousSec(currentSec)
        }
        setCurrentSec(SEARCH_SEC)
      } else if (searchTerm && searchTerm.length < 4) {
        if (previousSec !== SEARCH_SEC && currentSec === SEARCH_SEC) {
          setCurrentSec(previousSec)
        }
        if (typingTimer < 1 || typingTimer === undefined) {
          history.push(`${paths.rulesSectionPath}?search=${searchTerm}`);
        }
      } else {
        history.push(`${paths.rulesSectionPath}`)
        if (previousSec !== SEARCH_SEC && currentSec === SEARCH_SEC) setCurrentSec(previousSec)
      }
      setTriggerSearch(false)
    }
  }, [searchTerm, triggerSearch, typingTimer])

  useEffect(() => {
    setSearchTerm(params.search || "");
  }, [params.search])


  useEffect(() => {
    if (!isIdleMalwareFamilies && !isLoadingMalwareFamilies && !isErrorMalwareFamilies && dataMalwareFamiliesCount?.data && dataMalwareFamilies?.data && !isIdleMalwareFamiliesCount && !isLoadingMalwareFamiliesCount) {
      setMalwareFamiliesDisplay(dataMalwareFamilies.data.filter(data => {
        const count = dataMalwareFamiliesCount.data.find(d => (d.uid === data.uid))?.count
        if (count > 0) {
          data.commandsCount = count
          return data
        }
      }));
    }
  }, [
    isIdleMalwareFamilies, isLoadingMalwareFamilies, isErrorMalwareFamilies, dataMalwareFamilies,
    isIdleMalwareFamiliesCount, isLoadingMalwareFamiliesCount, isErrorMalwareFamiliesCount, dataMalwareFamiliesCount
  ])

  useEffect(() => {
    if ((isIdleCommandsSearch || isLoadingCommandsSearch) && !dataCommandsSearch) {
      setRulesArray([])
      setNext(null)
    }
  }, [searchTerm])

  const eventOnClick = (event) => {
    if (event.type === 'techniques') setTTPUid(event.id)
    else if (event.type === 'malware_families') setMalwareFamilyUid(event.id)
    else if (event.type === 'search_rule') {
      setCurrentRules(rulesArray.filter(rule => !rule.isFake && rule.uid === event.id))
    }
    toggleModal()
  }

  const isItemLoaded = ({index}) => !rulesArray[index].isFake;
  const loadMoreItems = () => {
    if (dataCommandsSearch?.metadata?.next) {
      setNext(dataCommandsSearch.metadata.next);
    }
    return new Promise(() => {
    });
  };

  const RowItem = ({index, style}) => {
    style = {...style, ...{borderColor: '#333246', borderStyle: 'solid', borderWidth: '1px 0px 1px 0px', margin: '0px'}}
    const rule = rulesArray[index];

    const onTagClick = (tag) => {
      history.replace(`${paths.rulesPath}?tags=${tag}`);
    }

    return !rule.isFake ? <RuleItem
        key={rule.uid} style={style} rule={rule} onTagClick={onTagClick}
        onRowClick={() => eventOnClick({type: 'search_rule', id: rule.uid})}
        rulesSection
      /> :
      <FakeItem style={style}/>
  }

  const onChange = (e) => {
    const timer = setTimeout(() => {
      setTypingTimer(0)
    }, 500)
    setTypingTimer(timer)
    setSearchTerm(e.target.value)
  }

  const onSearchTriggered = () => {
    setTriggerSearch(true)
  }

  if (isIdleTTPSCount || isLoadingTTPSCount || isIdleMalwareFamilies || isLoadingMalwareFamilies || isLoadingMalwareFamiliesCount || isIdleMalwareFamiliesCount) {
    return <LoadingSpinnerPage/>
  }

  if (isErrorTTPSCount || isErrorMalwareFamilies || isErrorMalwareFamiliesCount || isErrorCommandsSearch || isErrorCommandsByTTPUid) {
    return <ErrorHandler
      error={errorTTPSCount || errorMalwareFamiliesCount || errorMalwareFamilies || errorCommandsSearch || errorCommandsByTTPUid}/>
  }

  if (dataTTPSCount?.data.length === 0 && dataMalwareFamiliesCount?.data.length === 0) {
    return (
      <Page
        feature={PLATFORM_FEATURE_CONTENTS}
      >
        <Container className={'dashboard'}>
          <Row>
            <Col>
              <h2 className={"h2_investigate-title"}>No Rules Found</h2>
            </Col>
          </Row>
        </Container>
      </Page>
    )
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      fullPageWrapper
    >
      <Container className="div__sticky-top">
        <SearchBarOnEnter
          searchPlaceholder={'Type to search for commands...'}
          setSearch={setSearchTerm}
          searchTerm={searchTerm}
          withSearchButton
          onChangeHandler={(e) => onChange(e)}
          customHandle={onSearchTriggered}
        />

        <Row style={{marginTop: '20px'}}>
          <Col>
            <Button
              className={"rounded"}
              size="sm"
              outline
              active={currentSec === TTPS_SEC}
              onClick={() => {
                setPreviousSec(currentSec);
                setCurrentSec(TTPS_SEC)
              }}
              hidden={currentSec === SEARCH_SEC}
            >
              TTPS
            </Button>
            <Button
              className={"rounded"}
              size="sm"
              outline
              active={currentSec === MALWARE_FAMILIES_SEC}
              onClick={() => {
                setPreviousSec(currentSec);
                setCurrentSec(MALWARE_FAMILIES_SEC)
              }}
              hidden={currentSec === SEARCH_SEC}>
              Malware Families
            </Button>
          </Col>
        </Row>
      </Container>

      <Container className={'dashboard'}>
        {currentSec === TTPS_SEC ?
          dataTTPSCount?.length > 0 ?
            <MitreFrameworkCard
              updateFilters={eventOnClick}
              techniques={dataTTPSCount?.data} minimalMitre
              count
              section={"Rules"}
            />
          :
          <h4>No rules related to TTPs</h4> : <></>
        }
        {currentSec === MALWARE_FAMILIES_SEC &&
          <>
            <Row className={'mt-3'}>
              <Col md={12}>
                <h3 className="page-title">{malwareFamiliesDisplay.length} results</h3>
              </Col>
            </Row>
            <Row className={'malware-family-wrapper'}>
              {
                malwareFamiliesDisplay.map((malwareFamily) => (
                  <Col lg={3} md={6} sm={6} xs={6} key={malwareFamily.uid}>
                    <MalwareFamilyDetailCard
                      key={malwareFamily.uid}
                      malwareFamily={malwareFamily}
                      withTruncateText
                      withDecoration
                      customOnClick={eventOnClick}
                      section={"Rules"}
                    />
                  </Col>
                ))
              }
            </Row>
          </>
        }

        <ThemeModal
          isOpen={showModal}
          toggle={toggleModal}
          modalClassName={'ltr-support'}
          className={'modal-dialog--header-xl modal-dialog--dark'}
        >
          {currentSec === SEARCH_SEC && <div className="modal__header" key={"modal__header"}>
            <h4 className="bold-text d-inline">
              {currentRules[0]?.title}
            </h4>
          </div>}
          <div className="modal__body" key={"modal__body"}>
            {currentSec === SEARCH_SEC && <RulesCollapseContent rule={currentRules[0]}/>}
            {
              (currentSec === TTPS_SEC || currentSec === MALWARE_FAMILIES_SEC) &&
              <RulesContentSection rules={currentRules ? currentRules : []} inModal />
            }
          </div>
          <ButtonToolbar className="modal__footer">
            <Button outline className="rounded" onClick={() => toggleModal()}>Close</Button>
          </ButtonToolbar>

        </ThemeModal>

        {currentSec === SEARCH_SEC && <>
          {dataCommandsSearch?.data.length === 0 &&
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <Badge text={'white'} className={'w-100 outline badge-lg'}>NO RULES FOUND</Badge>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          }
          <Row>
            <Col>
              <WindowedInfiniteLoader
                loadMoreItems={loadMoreItems}
                RowItem={RowItem}
                isItemLoaded={isItemLoaded}
                isLoading={(isLoadingCommandsSearch || isIdleCommandsSearch) && rulesArray.length === 0}
                itemsArray={rulesArray}
                searchTerm={searchTerm}
              />
            </Col>
          </Row>
        </>
        }
      </Container>

    </Page>
  )
}


export default RulesSection;
