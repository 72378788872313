import React, {useEffect} from "react";
import {Button, Card, CardBody, Col} from "reactstrap";
import PropTypes from "prop-types";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import {useForm} from "react-hook-form";
import RegisteredInputField from "../../../shared/components/form/RegisteredInputField";
import {hasUserEditorPermissionOnFeature} from "../../../shared/helpers/permissions";
import {PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS,} from "../../../shared/helpers/features";
import {useProjectsOrganizationTypoSquattingQuery} from "../../../queries/ProjectsOrganizationTypoSquatting";
import TypoSquattingProjectFormCollapse from "./collapse/TypoSquattingProjectFormCollapse";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import toast from "react-hot-toast";
import {useAddTypoSquattingProject} from "../../../mutations/AddTypoSquattingProject";
import {getErrorMessageFromResponse} from "../../../shared/helpers";


const TypoSquattingSection = ({organization}) => {
  const {data, isIdle, isLoading, isError} = useProjectsOrganizationTypoSquattingQuery(
    organization.uid
  );
  const {
    data: dataUser,
    isIdle: isIdleUser,
    isLoading: isLoadingUser,
    isError: isErrorUser
  } = useCurrentUserQuery();
  const {register, handleSubmit, setValue, formState: {errors}} = useForm();
  const mutation = useAddTypoSquattingProject();

  const onSubmit = (formData) => {
    toast.loading(`Creating Engine ${formData.project_name}`)
    mutation.mutate({
      params: {
        name: formData.project_name
      },
      organizationUid: organization.uid
    })
  }

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss()
      if (mutation.isSuccess) {
        toast.success('Engine created');
        setValue('project_name', '');
      } else if (mutation.isError) {
        toast.error(getErrorMessageFromResponse(mutation));
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])


  if (isIdle || isLoading || isIdleUser || isLoadingUser || isError || isErrorUser) {
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Domain Squatting Intelligence</h4>
            {
              (isLoading || isIdle || isIdleUser || isLoadingUser) ? <LoadingSpinner/> :
                <h4>Unable to retrieve the engines from your organization</h4>
            }
          </div>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Domain Squatting Engine</h4>
          <p className={'subhead'}>
            You created {data.data.length}/{dataUser.data.organization.max_project_typosquatting} engines.
            Contact the administrator to extend this limit.
          </p>
          {
            data.data.length === dataUser.data.organization.max_project_typosquatting &&
            <p className={'subhead'} style={{'color': 'red'}}>You reach the max number of engines you can create.</p>
          }
        </div>
        <form className="form form--vertical">
          <Col md={12}>
            <RegisteredInputField
              name={'project_name'}
              title={'Engine Name'}
              register={register}
              errors={errors}
              rules={{required: 'The name is required'}}
            />
            <Button
              color={'success'}
              className={'float-right'}
              type={'submit'}
              onClick={handleSubmit(onSubmit)}
              disabled={
                data.data.length === dataUser.data.organization.max_project_typosquatting ||
                !hasUserEditorPermissionOnFeature(dataUser?.data?.modules || [], PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS)
              }
            >
              Create Engine
            </Button>
          </Col>
        </form>
        {
          data.data.map((project) =>
            <TypoSquattingProjectFormCollapse
              project={project}
              organization={organization}
              key={project.uid}
            />
          )
        }
      </CardBody>
    </Card>
  )
}

TypoSquattingSection.propTypes = {
  organization: PropTypes.object.isRequired
}


export default TypoSquattingSection;
