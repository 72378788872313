import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";

export const FeedlyFeedsAuthorsFetcher = (
  accessToken, limit = 100
) => {
  const urlParams = new URLSearchParams();
  if (limit) {
    urlParams.set('limit', limit);
  }
  return axios.get(`${apiUrlInternal}/news_feeds/authors?${urlParams.toString()}`,
    addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useFeedlyFeedsAuthorsQuery = (
  limit = 100, fetcher = FeedlyFeedsAuthorsFetcher) => {
  return useQueryWithAuthentication(
    ['feedly-feeds-authors-query', limit],
    (accessToken) => fetcher(accessToken, limit))
}

