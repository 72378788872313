import React from 'react'
import HorizontalBarChartCard from "../../../../shared/components/charts/HorizontalBarChartCard";
import {useStatsSectorsMostTargeted} from "../../../../queries/Stats";
import {useHistory} from "react-router-dom";
import paths from "../../../../config/paths";
import PropTypes from "prop-types";


const BAR_COLORS = [
  '#ff4861', '#40e5e8', '#f6da6e',
  '#c88ffa', '#4ce1b6', '#70bbfd',
]

const TargetedSectorsHorizontalBarChart = ({sectorsLimit = 6}) => {
  const history = useHistory();
  const { data: rawData, isIdle, isLoading, isError } = useStatsSectorsMostTargeted(sectorsLimit + 1);

  const data = !rawData ? [] :
    rawData.data.map((val) => ({name: val.sector.name, num_contents: val.num_contents, uid: val.sector.uid}))
      .filter((val) => val.name !== 'Generic');

  return (
    <HorizontalBarChartCard
      data={data}
      dataKey={'num_contents'}
      labelDataKey={'name'}
      title={'MOST TARGETED SECTORS'}
      isError={isError}
      isLoading={isLoading || isIdle}
      onBarClick={(item, index) => {
        history.push(`${paths.contentsPath}?targeted_sectors=${data[index].uid}`)
      }}
      barColors={BAR_COLORS}
    />
  )
}

TargetedSectorsHorizontalBarChart.propTypes = {
  sectorsLimit: PropTypes.number
}

export default TargetedSectorsHorizontalBarChart;
