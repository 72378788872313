import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index"
import {useQueryClient} from "react-query";

const InvestigateQuotaPurchaseFetcher = (accessToken, params) => {
  return axios.post(
    `${apiUrlInternal}/investigate_quota_purchase`,
    params,
    addAuthToFetchOpts(accessToken, {}, 0)
  ).then(res => res.data);
}

export const useInvestigateQuotaPurchaseMutation = (fetcher = InvestigateQuotaPurchaseFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['investigate-quota-purchase'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(['organizations', params.organization_uid])
      }
    }
  )
}