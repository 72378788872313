import React from "react";
import {useParams} from "react-router-dom";
import {useMalwareFamilyDetailsQuery} from "../../queries/MalwareFamilyDetails";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import MalwareFamilyForm from "../../shared/components/form/malware-family-form/MalwareFamilyForm";
import {useEditMalwareFamilyMutation} from "../../mutations/UpdateMalwareFamily";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";

const MalwareFamilyEdit = () => {
  const {malwareUid: uid} = useParams();
  const {data, isIdle, isLoading, isError, error} = useMalwareFamilyDetailsQuery(uid);

  if (isIdle || isLoading) {
    return <LoadingSpinnerPage />
  }

  if (isError) {
    return <ErrorHandler error={error} />
  }

  const defaultValues = data.data;

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
      unavailableOnTenant
    >
      <MalwareFamilyForm
        editMalwareFamily
        malwareFamilyName={data.data.name}
        defaultValues={defaultValues}
        mutationFn={useEditMalwareFamilyMutation}
      />
    </Page>
  )
}

export default MalwareFamilyEdit;
