import React, {Fragment} from 'react';
import {Col, Row, Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import {useSandboxTaskDetailsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import TabsWithDataTable from "../../../shared/components/tab/TabsWithDataTable";
import {getCopiableVtColumn, getCopiableVtPrivateIpColumn} from "../../../shared/helpers/tables";



const SandboxTaskNetworkSection = ({taskId}) => {
    const { data, isIdle, isLoading, isError,error } = useSandboxTaskDetailsQuery(taskId);

    if (isLoading || isIdle) {
        return <LoadingSpinner />
    }

    if (isError || !data.data) {
        return <ErrorHandler error={error} />
    }

    const hostsTableColumns = [
        getCopiableVtPrivateIpColumn('actions', 'ip'),
        { Header: 'IP', accessor: 'ip' },
        { Header: 'Country', accessor: 'country_name' },
        { Header: 'Hostname', accessor: 'hostname' },
        { Header: 'Inaddrarpa', accessor: 'inaddrarpa' }
    ];

    const domainsTableColumns = [
        getCopiableVtPrivateIpColumn('actionsIp', 'ip'),
        { Header: 'IP', accessor: 'ip', Cell: ({value}) => value ? value : 'N/A' },
        getCopiableVtColumn('actionsDomain', 'domain'),
        { Header: 'Domain', accessor: 'domain' }
    ];
    /* TO-DO: aggiungerle, serve capire che formato ha il json di cape qui
    const icmpTableColumns = [];
    const smtpTableColumns = [];
    */

    function renderHttpData(data) {
        data = data.replace(/\r\n/g,',');
        let array = data.split(',').filter(x=>x !== "");
        const elems = array.map(
            (x,i) => <Fragment>
                <p>{x}</p>
                {i !== data.length-1 ? <br/> : null}
            </Fragment>
        );
        return <p>{elems}</p>;
    }

    const httpTableColumns = [
        { Header: 'Host', accessor: 'host' },
        { Header: 'Port', accessor: 'port' },
        { Header: 'Method', accessor: 'method' },
        { Header: 'UserAgent', accessor: 'data' , Cell: ({value})=> renderHttpData(value)}
    ];

    const tcpTableColumns = [
        { Header: 'Source', accessor: 'src' },
        { Header: 'Source Port', accessor: 'sport' },
        getCopiableVtPrivateIpColumn('actionsDestination', 'dst'),
        { Header: 'Destination', accessor: 'dst' },
        { Header: 'Destination Port', accessor: 'dport' },
        { Header: 'Offset', accessor: 'offset' },
        { Header: 'Time', accessor: 'time' }
    ];

    const udpTableColumns = [
        getCopiableVtPrivateIpColumn('actionsSource', 'src'),
        { Header: 'Source', accessor: 'src' },
        { Header: 'Source Port', accessor: 'sport' },
        getCopiableVtPrivateIpColumn('actionsDestination', 'dst'),
        { Header: 'Destination', accessor: 'dst' },
        { Header: 'Destination Port', accessor: 'dport' },
        { Header: 'Offset', accessor: 'offset' },
        { Header: 'Time', accessor: 'time' }
    ];

    function renderDnsAnswer(arrayOfDnsAnswers) {
        if(arrayOfDnsAnswers === undefined || arrayOfDnsAnswers.length === 0) {
            return <p>NONE</p>
        }
        const elems = arrayOfDnsAnswers.map(
            (x,i) => <Fragment>
                <p>{x.type} {x.data}</p>
                {i !== arrayOfDnsAnswers.length-1 ? <br/> : null}
            </Fragment>
        );
        return <p>{elems}</p>;
    }

    const dnsTableColumns = [
        getCopiableVtColumn('actionsDomain', 'request'),
        { Header: 'Request', accessor: 'request' },
        { Header: 'Type', accessor: 'type' },
        { Header: 'Answers', accessor: 'answers', Cell: ({value})=> renderDnsAnswer(value)}
    ];

    // dataPerTag assignments
    // have to be done conditionally to ensure first tab correctly selected
    const dataPerTag = {}
    const networkKeys = ["hosts","domains","udp","dns","tcp"]
    const columns = {
        hosts:hostsTableColumns,
        domains:domainsTableColumns,
        udp:udpTableColumns,
        dns:dnsTableColumns,
        tcp:tcpTableColumns
    }
    networkKeys.forEach(
        function fn(x) {
            if (data.data.report.network[x] && data.data.report.network[x].length > 0) {
                dataPerTag[x] = {
                    data: data.data.report.network[x],
                    tableColumns: columns[x]
                }
            }
        }
    )

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <TabsWithDataTable
                            dataPerTag={dataPerTag}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

SandboxTaskNetworkSection.propTypes = {
    taskId: PropTypes.string.isRequired,
}

export default SandboxTaskNetworkSection;
