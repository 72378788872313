import React from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useAnalysisDetailsQuery} from "../../queries/AnalysisDetails";
import {useHistory, useParams} from "react-router-dom";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import TargetVictimCard from "../../shared/components/card/TargetVictimCard";
import SectorCard from "../../shared/components/card/withIcon/SectorCard";
import MitreFrameworkCard from "../../shared/components/card/MitreFrameworkCard";
import ContentCardHeader from "../../shared/components/card/accessories/ContentCardHeader";
import ContentCardFooter from "../../shared/components/card/accessories/ContentCardFooter";
import {canUserEditContent} from "../../shared/helpers/permissions";
import {useCookieAccessToken} from "../../queries";
import RulesCard from "../../shared/components/card/RulesCard";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import ContentEditsBar from "../../shared/components/card/accessories/ContentEditsBar";
import IndicatorsTabWithTablePaginationCard from "../../shared/components/card/IndicatorsTabWithTablePaginationCard";
import {useEventContentsIndicatorsCountQuery} from "../../queries/EventContentsIndicatorsCount";
import {useSearchEventIndicatorDictPagination} from "../../queries/SearchEventIndicatorDictPagination";
import paths from "../../config/paths";
import ReferencesRow from "../../shared/components/card/accessories/ReferencesRow";
import ActorsCard from "../../shared/components/card/ActorsCard";
import MalwareFamiliesTagsCard from "../../shared/components/card/withIcon/MalwareFamiliesTagsCard";
import {getMalwareFamilyTags} from "../../shared/helpers";
import DownloadReport from "../../shared/components/card/DownloadReport";
import PurchaseContent from "../../shared/components/card/PurchaseContent";
import CommandsCard from "../../shared/components/card/CommandsCard"
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const AnalysisDetail = () => {
  const { analysisUid: uid } = useParams();
  const { data: accessToken, isIdle: isIdleAccessToken, isLoading: isLoadingAccessToken } = useCookieAccessToken();
  const { data: dataCurrentUser, isIdle: isIdleCurrentUser, isLoading: isLoadingCurrentUser } = useCurrentUserQuery();
  const { data, isIdle, isLoading, isError, error } = useAnalysisDetailsQuery(uid);
  const history = useHistory();
  const updateFilters = (field, value) => {
    const urlParams = new URLSearchParams();
    urlParams.set(field, value);

    history.push(`${paths.contentsPath}?${urlParams.toString()}`);
  }

  const onCountryClick = (data) => {
    history.push(`${paths.contentsPath}?targeted_countries=${data.target.dataItem.dataContext.id}`)
  }

  if (isError) {
    return <ErrorHandler error={error} />
  }

  if (isLoading || isIdle || isLoadingAccessToken || isIdleAccessToken || isIdleCurrentUser || isLoadingCurrentUser) {
    return <LoadingSpinnerPage />
  }

  const analysis = data.data;
  const tlp = analysis.tlp.tlp;
  const isEditable = canUserEditContent(dataCurrentUser?.data, analysis);

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
    >
      <Container>
        <Row>
          <Col lg={7} md={12}>
            <Card className={'h-auto'}>
              <CardBody>
                <ContentCardHeader
                  updateFilters={updateFilters}
                  confidence={analysis.confidence}
                  tlp={tlp}
                  title={analysis.title}
                  created_dt={analysis.created_dt}
                />
                <pre className={'mb-3 pre--text'}>{analysis.text}</pre>
                {analysis.references?.length > 0 && <ReferencesRow references={analysis.references}/>}
                <ContentCardFooter tags={analysis.tags} tlp={tlp} />
                {isEditable && <div style={{position: 'absolute', bottom: '40px', right: '10px'}}><ContentEditsBar content={analysis} type={'analysis'} /></div>}
              </CardBody>
            </Card>

            <PurchaseContent info={analysis} org={dataCurrentUser?.data?.organization} />
            <DownloadReport info={analysis} />

          </Col>
          <Col lg={5} md={12} className={'d-flex flex-column'} style={{paddingBottom: '30px'}}>
            <ActorsCard actors={analysis.actors} />
            <SectorCard updateFilters={(val)=> { updateFilters('targeted_sectors', val) }}  sectors={analysis.sectors} />
            {getMalwareFamilyTags(analysis.tags).length > 0 && <MalwareFamiliesTagsCard malwareFamilies={getMalwareFamilyTags(analysis.tags)} />}
          </Col>
        </Row>

        {/* Indicators */}
        <Row>
          <Col md={12}>
            <IndicatorsTabWithTablePaginationCard
              dataHookCount={useEventContentsIndicatorsCountQuery}
              uid={uid}
              filters={{uid: uid}}
              dataHook={useSearchEventIndicatorDictPagination}
              withExportButton
              exportFilters={{content: uid}}
              withExportdRules
              customRuleText={`${window.location.origin}${paths.analysisPath}/${analysis.uid}`}
            />
          </Col>
        </Row>

        {/* COUNTRIES */}
        <Row>
          <Col md={12}>
            <TargetVictimCard updateFilters={updateFilters} onCountryClick={onCountryClick} targetedCountries={analysis.targeted_countries} />
          </Col>
        </Row>

        {/* MITRE FRAMEWORK */}
        <Row>
          <Col md={12}>
            <MitreFrameworkCard updateFilters={(data) => updateFilters(data.type,data.name)} techniques={analysis.techniques} />
          </Col>
        </Row>

        {/* RULES */}
        {
          analysis?.rules?.length > 0 &&
          <Row>
            <Col md={12}>
              <RulesCard  rules={analysis.rules} />
            </Col>
          </Row>
        }

        {/* COMMANDS */}
        {
          analysis?.commands?.length > 0 &&
          <Row>
            <Col md={12}>
              <CommandsCard  commands={analysis.commands}/>
            </Col>
          </Row>
        }
      </Container>
    </Page>
  )
}

export default AnalysisDetail;
