import React from "react"
import env_const from "../../config/env_const";
import OnlineIntelligenceSearch from "./Online";
import OnPremIntelligenceSearch from "./OnPrem";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_INTELLIGENCE, PLATFORM_PERMISSION_VIEWER} from "../../shared/helpers/features";


const IntelligenceSearch = () =>
  <Page
    feature={PLATFORM_FEATURE_INTELLIGENCE}
    
  >
    {env_const.is_on_prem ? <OnPremIntelligenceSearch /> : <OnlineIntelligenceSearch/>}
  </Page>

export default IntelligenceSearch;
