import React, {useEffect, useMemo, useState} from "react"
import {Col, Container, Row} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import paths from "../../config/paths";
import LegendItem from "./components/LegendItem";
import IndicatorItem from "./components/IndicatorItem";
import FakeItem from "./components/FakeItem";
import WindowedInfiniteLoader from "../../shared/components/infinite-loader/WindowedInfiniteLoader";
import {useSearchIndicatorQueryWithDict} from "./queries/SearchIndicator";
import ErrorHandler from "../../shared/components/ErrorHandler";
import SearchMenu from "../../shared/components/form/SearchMenu";
import {useTagsQuery} from "../../queries/Tags";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {getIndicatorsFilterMenu} from "../../shared/helpers/filter_menu";
import {getIndicatorsOrderMenu} from "../../shared/helpers/order_menu";
import {downloadFormats, downloadIndicators} from "../../shared/helpers/download_file";
import {useIndicatorTypesQuery} from "../../queries/IndicatorTypes";
import DropdownMenuExportFormats from "../../shared/components/form/DropdownMenuExportFormats";
import {useActorDetailsQuery} from "../../queries/Actor";
import {paramsToObject, useQueryParams} from "../../shared/components/router/QueryNavigationHelpers";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_OBSERVABLES} from "../../shared/helpers/features";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const _ = require("lodash");


const IndicatorTable = () => {
  const FAKE_ITEMS = 10;
  const history = useHistory();
  const [indicatorArray, setIndicatorArray] = useState([]);
  const [next, setNext] = useState(null);
  const SEARCH_FIELD= 'search_term';
  const params = paramsToObject(useQueryParams().entries());
  const [filters, setFilters] = useState({search_term: ''});
  const indicatorPerRequest = 400;

  const {data: dataTags, isIdle: isIdleTags, isLoading: isLoadingTags, isError: isErrorTags} = useTagsQuery();
  const {
    data: dataActors,
    isIdle: isIdleActors,
    isLoading: isLoadingActors,
    isError: isErrorActors
  } = useActorDetailsQuery();
  const {
    data: dataTypes,
    isIdle: isIdleTypes,
    isLoading: isLoadingTypes,
    isError: isErrorTypes
  } = useIndicatorTypesQuery();
  const isLoadingIdleErrorMenu = isIdleTags || isLoadingTags || isErrorTags || isIdleActors || isLoadingActors
    || isErrorActors || isIdleTypes || isLoadingTypes || isErrorTypes;

  const ORDER_MENU = getIndicatorsOrderMenu();
  const FILTER_MENU = useMemo(
    () => (!isLoadingIdleErrorMenu) ? getIndicatorsFilterMenu(dataTags, dataActors, dataTypes) : [],
    [isLoadingIdleErrorMenu]
  );

  const {data, isIdle, isLoading, isError, error} = useSearchIndicatorQueryWithDict(
    Object.assign({}, filters, {next: next, limit: indicatorPerRequest})
  );

  useEffect(() => {
    if (!isLoading && !isIdle && !isError && data) {
      if (!next) {
        if (data.metadata.total <= FAKE_ITEMS) {
          setIndicatorArray([{isFake: false}].concat(data.data));
        } else {
          setIndicatorArray([{isFake: false}].concat(data.data.concat(_.range(FAKE_ITEMS).map(() => ({isFake: true})))));
        }
      } else {
        setIndicatorArray(prevIndicatorArray =>
          !data.metadata.next ? _.filter(prevIndicatorArray.concat(data.data), (ioc) => !ioc.isFake) :
            _.filter(prevIndicatorArray, (ioc) => !ioc.isFake).concat(data.data).concat(_.range(FAKE_ITEMS).map(() => ({isFake: true})))
        );
      }

    }
  }, [isLoading, isIdle, isError, data]);

  useEffect(() => {
    if ((isIdle || isLoading) && !data) {
      setIndicatorArray([])
      setNext(null)
    }
  }, [filters])

  if (error) {
    return <ErrorHandler error={error}/>
  }

  const isItemLoaded = ({index}) => !indicatorArray[index].isFake;
  const loadMoreItems = () => {
    if (data?.metadata?.next) {
      setNext(data.metadata.next);
    }
    return new Promise(() => {
    });
  };

  const userFilters = (data) => {
    const urlParams = new URLSearchParams();
    Object.entries(data).forEach(([filter_field, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => urlParams.append(filter_field, v));
      } else {
        urlParams.set(filter_field, value)
      }
    });

    setFilters(data);
  }

  const RowItem = ({index, style}) => {
    style = {...style, ...{borderColor: '#333246', borderStyle: 'solid', borderWidth: '1px 0px 1px 0px', margin: '0px'}}
    const ioc = indicatorArray[index];

    const onRowClick = () => history.push(`${paths.investigatePath}?indicator=${(ioc.value)}`);
    if (index === 0) {
      return <LegendItem style={style}/>
    }

    return !ioc.isFake ? <IndicatorItem style={style} ioc={ioc} onRowClick={onRowClick}/> : <FakeItem style={style}/>
  }

  if (isIdleTags || isLoadingTags || isIdleActors || isLoadingActors || isIdleTypes || isLoadingTypes) {
    return <LoadingSpinnerPage/>
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_OBSERVABLES}
      fullPageWrapper
    >
      <Container>
        <div className={'div__sticky-top'}>
          <SearchMenu
            setFilters={userFilters}
            defaultFilters={params}
            baseUrl={paths.indicatorPath}
            searchPlaceholder={'Search indicator...'}
            orderMenu={ORDER_MENU}
            filterMenu={FILTER_MENU}
            fromDateField={'start'}
            toDateField={'end'}
            searchField={SEARCH_FIELD}
            additionalColumns={[
              <DropdownMenuExportFormats
                downloadDataHook={downloadIndicators}
                downloadFormats={downloadFormats}
                filters={filters}
              />
            ]}
          />
        </div>

        {
          (isIdle || isLoading) && indicatorArray.length <= 1 ?
            <LoadingSpinner/> :
            indicatorArray.length > 1 ?
              <Row>
                <Col md={12}>
                  <WindowedInfiniteLoader
                    loadMoreItems={loadMoreItems}
                    RowItem={RowItem}
                    isItemLoaded={isItemLoaded}
                    isLoading={(isIdle || isLoading) && indicatorArray.length === 0}
                    itemsArray={indicatorArray}
                    searchTerm={filters.search_term}
                  />
                </Col>
              </Row> :
              <Row>
                <Col md={12}>
                  <h2>
                    No indicators found.
                    {
                      filters.searchTerm &&
                      <Link to={`${paths.investigatePath}?indicator=${filters.searchTerm}`}
                            className={'a--with-underline-hover'}>
                        Click here to search "{filters.searchTerm}" into investigate section
                      </Link>
                    }
                    <br/>
                    {
                      !filters.start &&
                      <a onClick={() => {
                        filters.start = '2021-01-01T00:00:00.000000Z';
                        userFilters(filters);
                      }} className={'a--with-underline-hover'}>
                        The results show the indicators of the latest 3 months. Click here to expand your search, it can take few minutes.
                      </a>
                    }

                  </h2>
                </Col>
              </Row>
        }
      </Container>
    </Page>
  )
}

export default IndicatorTable;
