import React, {useState} from "react";
import {Card, CardBody} from "reactstrap";
import ControlledFilepond from "../../ControlledFilepond";
import PropTypes from "prop-types";
import FileRelationshipForm from "./FileRelationshipForm";

const _ = require('lodash')

const AttachmentsUploadCard = ({control, defaultValues}) => {

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Attachments</h4>
        </div>
        <ControlledFilepond
          control={control}
          name={'attachments'}
          allowMultiple={true}
          allowRevert={false}
          maxFiles={5}
          instantUpload={false}
          defaultValue={defaultValues?.attachments}
          labelIdle={'Drag & Drop your attachments or <span class="filepond--label-action">Browse</span>'}
        />
      </CardBody>
    </Card>
  )
}

FileRelationshipForm.propsType = {
  control: PropTypes.shape().isRequired,
}

export default AttachmentsUploadCard;
