import TimeLineItem from "../TimeLineItem";
import {getPostTitle} from "../../helpers/contents";
import {formatDateUTCtoYearMonthDay, formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import paths from "../../../config/paths";
import AlphaPIcon from "mdi-react/AlphaPIcon";
import AlphaAIcon from "mdi-react/AlphaAIcon";
import ThemedClampLines from "../clamp-lines/ThemedClampLines";
import React from "react";
import PropTypes from "prop-types";
import AlphaCIcon from "mdi-react/AlphaCIcon";
import _ from "lodash";
import moment from "moment";
import AlphaFIcon from "mdi-react/AlphaFIcon";


const PostTimeLineItem = ({context}) =>
  <TimeLineItem
    title={getPostTitle(context)}
    date={formatDateUTCtoYearMonthDayTime(context.created_dt)}
    link={`${paths.postPath}/${context.uid}`}
    icon={<AlphaPIcon size={48} />}
  >
    <p>
      <ThemedClampLines
        text={context.text}
        lines={6}
      />
    </p>
  </TimeLineItem>


const AnalysisTimeLineItem = ({context}) =>
  <TimeLineItem
    title={context.title}
    date={formatDateUTCtoYearMonthDayTime(context.created_dt)}
    link={`${paths.analysisPath}/${context.uid}`}
    icon={<AlphaAIcon size={48} />}
  >
    <p>
      <ThemedClampLines
        text={context.text}
        lines={6}
      />
    </p>
  </TimeLineItem>


const FlashAlertTimeLineItem = ({context}) =>
  <TimeLineItem
    title={context.title}
    date={formatDateUTCtoYearMonthDayTime(context.created_dt)}
    link={`${paths.flashAlertPath}/${context.uid}`}
    icon={<AlphaFIcon size={48} />}
  >
    <p>
      <ThemedClampLines
        text={context.text}
        lines={6}
      />
    </p>
  </TimeLineItem>


const CollectionTimeLineItem = ({context, collectionFilters = {}}) => {
  const urlParams = new URLSearchParams();
  _.forEach(collectionFilters, (value, filter) => urlParams.set(filter, value));

  urlParams.set('start', formatDateUTCtoYearMonthDay(moment.utc(context.created_dt).local()))
  urlParams.set('end', formatDateUTCtoYearMonthDay(moment.utc(context.created_dt).add(1, 'days').local()))

  return (
    <TimeLineItem
      title={"New collection of indicators"}
      date={formatDateUTCtoYearMonthDayTime(context.created_dt)}
      link={`${paths.indicatorPath}?${urlParams.toString()}`}
      icon={<AlphaCIcon size={48} />}
    />
  )
}


const ContextsTimeline = ({contexts, collectionFilters = {}}) =>
  <div className="timeline">
    {
      contexts.map((context) => {
        if (context.type === "post") {
          return <PostTimeLineItem key={context.uid} context={context} />
        } else if (context.type === "analysis") {
          return <AnalysisTimeLineItem key={context.uid} context={context} />
        } else if (context.type === "flashalert") {
          return <FlashAlertTimeLineItem key={context.uid} context={context} />
        } else {
          return <CollectionTimeLineItem  key={context.uid} context={context} collectionFilters={collectionFilters} />
        }
      })
    }
  </div>


ContextsTimeline.propTypes = {
  contexts: PropTypes.array.isRequired,
  collectionFilters: PropTypes.object
}


export default ContextsTimeline;