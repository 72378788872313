import React, {useEffect} from "react";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import NotAllowed401 from "../DefaultPage/401";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import toast from "react-hot-toast";
import ErrorHandler from "../../shared/components/ErrorHandler";
import {useUserDetailsQuery} from "../../queries/Users";
import {formatDateUTCtoYearMonthDayTime} from "../../shared/helpers/date";
import TLPBadge from "../../shared/components/badge/TLPBadge";
import TableWithData from "../../shared/components/table/TableWithData";
import {useDeleteUsers} from "../../mutations/DeleteUser";
import {getElementPropertyOrDefault} from "../../shared/helpers";
import {hasUserEditorPermissionOnFeature, userCreatableByRole} from "../../shared/helpers/permissions";
import {useQueryClient} from "react-query";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import {useHistory} from "react-router-dom";
import paths from "../../config/paths";
import {PLATFORM_FEATURE_USERS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const _ = require('lodash');


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
}


const UsersList = () => {
  const mutation = useDeleteUsers();
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();
  const {
    data: dataUsers,
    isIdle: isIdleUsers,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    error: errorUsers
  } = useUserDetailsQuery();

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        queryClient.invalidateQueries(['user-details', null]);
      } else if (mutation.isError) {
        toast.error('Error during the deletion of the user');
        mutation.reset();
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])


  if (isLoadingCurrentUser || isIdleCurrentUser) {
    return <LoadingSpinnerPage/>
  }

  if (isErrorCurrentUser) {
    return <Page><NotAllowed401 /></Page>
  }

  if (isErrorUsers && !isLoadingUsers && !isIdleUsers) {
    return <ErrorHandler error={errorUsers}/>
  }

  const tableColumns = [
    {Header: 'Email', accessor: 'email'},
    {Header: 'Role', accessor: 'role'},
    {Header: 'Status', accessor: 'active', Cell: ({value}) => value ? 'Active' : 'Blocked'},
    {Header: 'TLP', accessor: 'tlp', Cell: ({value}) => <TLPBadge tlp={value.tlp}/>},
    {Header: 'PRIVATE TLP', accessor: 'private_tlp', Cell: ({value}) => <TLPBadge tlp={value.tlp}/>},
    {
      Header: 'Organization',
      accessor: (row) => getElementPropertyOrDefault(row.organization, 'name', 'No organization')
    },
    {Header: 'Created date', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},

  ];

  if (hasUserEditorPermissionOnFeature(dataCurrentUser.data.modules, PLATFORM_FEATURE_USERS)) {
    tableColumns.push(
      {
        Header: 'Edit user', accessor: 'uid', Cell: ({value, row}) => {
          if (!hasUserEditorPermissionOnFeature(dataCurrentUser.data.modules, PLATFORM_FEATURE_USERS) || value === dataCurrentUser.data.uid) {
            return (<></>);
          } else {
            return (
              <>
                <PencilOutlineIcon size={24} style={{fill: '#ffffff', margin: '0'}} onClick={(e) => {
                  e.stopPropagation();
                  history.push(`${paths.listUserPath}/${value}`);
                }} className={'border-hover-white mr-3 editIcon'}/>
              </>
            )
          }
        }
      }
    )
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_USERS}
    >
      <Container>
        <Row className={'div__sticky-top'}>
          <Col md={9}>
            <h3 className="page-title">Manage Users</h3>
          </Col>
          {
            userCreatableByRole(dataCurrentUser.data.role).length > 0 &&
            <Col md={3} className={'justify-content-end d-flex'}>
              <Button outline onClick={() => {
                history.push(`${paths.listUserPath}/add`);
              }} color={'success'}>Add user</Button>
            </Col>}
        </Row>

        <Card>
          <CardBody>
            <TableWithData
              data={_.sortBy(dataUsers?.data || [], (user) => user.email)}
              tableColumns={tableColumns}
              tableConfig={tableConfig}
              isLoading={isIdleUsers || isLoadingUsers}
            />
          </CardBody>
        </Card>
      </Container>
    </Page>
  )
}


export default UsersList;
