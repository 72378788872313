import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useAddFeedlyFeedsPreferenceQuery} from "../../../mutations/AddFeedlyFeedsPreference";
import {useDeleteFeedlyFeedsPreferenceQuery} from "../../../mutations/DeleteFeedlyFeedsPreference";
import {useActorDetailsQuery} from "../../../queries/Actor";
import {Button, Col, Container, Row} from "reactstrap";
import ControlledSelectWithTitle from "./ControlledSelectWithTitle";
import {useForm} from "react-hook-form";
import LoadingSpinner from "../LoadingSpinner";
import ErrorHandler from "../ErrorHandler";
import toast from "react-hot-toast";
import {useMalwareFamilyQuery} from "../../../queries/MalwareFamily"

const FeedlyFeedsPreferencesForm = ({
                                      defaultValues = {
                                        actor_preferences: [],
                                        malware_families_preferences: []
                                      },
                                    }) => {
  const mutationAdd = useAddFeedlyFeedsPreferenceQuery()
  const mutationDel = useDeleteFeedlyFeedsPreferenceQuery()


  const {
    data: dataActors,
    isIdle: isIdleActors,
    isLoading: isLoadingActors,
    isError: isErrorActors
  } = useActorDetailsQuery();
  const {
    data: dataMalwareFamilies,
    isIdle: isIdleMalwareFamilies,
    isLoading: isLoadingMalwareFamilies,
    isError: isErrorMalwareFamilies
  } = useMalwareFamilyQuery();


  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);


  const {register, handleSubmit, formState: {errors}, control, watch, clearErrors} = useForm({
    defaultValues: defaultValues
  });
  const malwareFamilyOptions = dataMalwareFamilies?.data.map((family) => ({
    value: family.name,
    label: family.name,
  }));
  const actorOptions = dataActors?.data.map((actor) => ({
    value: actor.name,
    label: actor.name
  }))

  const isIdleLoading = isIdleMalwareFamilies || isLoadingMalwareFamilies || isIdleActors || isLoadingActors
  const isError = isErrorActors || isErrorMalwareFamilies
  const onSubmit = (data) => {
    defaultValues.actor_preferences.forEach((actor) => {
      if (!data?.actors.find((a) => a.value === actor.value)) {
        mutationDel.mutate({uid: actor.uid})
      }
    })
    data.actors.forEach((actor) => {
      if (!defaultValues.actor_preferences.find((a) => a.value === actor.value)) {
        mutationAdd.mutate({preferenceType: 'actor', value: actor.value})
      }
    })
    defaultValues.malware_families_preferences.forEach(family => {
      if (!data?.malware_families.find((c) => c.value === family.value)) {
        mutationDel.mutate({uid: family.uid})
      }
    })
    data.malware_families.forEach(family => {
      if (!defaultValues.malware_families_preferences.find((c) => c.value === family.value)) {
        mutationAdd.mutate({preferenceType: 'malware_family', value: family.value})
      }
    })

  }

  useEffect(() => {
    if (!mutationDel.isIdle) {
      if (mutationDel.isLoading) {
        toast.loading('Deleting preference');
      } else {
        toast.dismiss();
        if (mutationDel.isSuccess) {
          toast.success('Deleted preference successfully');
        } else if (mutationDel.isError) {
          toast.error('Error during preference deletion');
          mutationDel.reset();
        }
      }
    }
  }, [mutationDel.isIdle, mutationDel.isError, mutationDel.isLoading, mutationDel.isSuccess])

  useEffect(() => {
    if (!mutationAdd.isIdle) {
      if (mutationAdd.isLoading) {
        toast.loading('Adding preference');
      } else {
        toast.dismiss();
        if (mutationAdd.isSuccess) {
          toast.success('Added preference successfully');
        } else if (mutationAdd.isError) {
          toast.error('Error during preference addition');
          mutationAdd.reset();
        }
      }
    }
  }, [mutationAdd.isIdle, mutationAdd.isError, mutationAdd.isLoading, mutationAdd.isSuccess])


  if (isIdleLoading) {
    return <Container><LoadingSpinner/></Container>
  }

  if (isError) {
    return <ErrorHandler/>
  }

  return (
    <>
      <ControlledSelectWithTitle
        name={'malware_families'}
        title={'Malware Families'}
        control={control}
        isSearchable isMulti isClearable
        valueFn={(value) => malwareFamilyOptions?.find((c) => c.value === value?.value)}
        options={malwareFamilyOptions}
        defaultValue={
          defaultValues.malware_families_preferences?.map((family) => malwareFamilyOptions.find((c) => c.value.toLowerCase() === family.value.toLowerCase()))
        }
      />
      <ControlledSelectWithTitle
        name={'actors'}
        title={'Actors'}
        control={control}
        isSearchable isMulti isClearable
        valueFn={(value) => actorOptions?.find((c) => c.value === value?.value)}
        options={actorOptions}
        defaultValue={
          defaultValues.actor_preferences?.map((actor) => actorOptions.find((a) => a.value.toLowerCase() === actor.value.toLowerCase()))
        }
      />
      <Row>
        <Col>
          <Button size={'xs'} className={'float-right'} onClick={() => {
            clearErrors('');
            handleSubmit(onSubmit)();
          }} color={'success'} disabled={buttonUploadDisabled}>{'Save'}</Button>
        </Col>

      </Row>
    </>
  )
}


FeedlyFeedsPreferencesForm.propTypes = {
  defaultValues: PropTypes.shape(),
}
export default FeedlyFeedsPreferencesForm;
