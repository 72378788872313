import PropTypes from "prop-types";
import {useMalwareFamilyDetailIndicatorsWithPaginationQuery} from "../../../queries/MalwareFamily";


export const useSearchEventIndicatorDictPagination = (
  {uid, ioc_type}, limit = null, page = null
) => {
  return useMalwareFamilyDetailIndicatorsWithPaginationQuery(uid, ioc_type, page, limit);
}


useSearchEventIndicatorDictPagination.propTypes = {
  uid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  page: PropTypes.number,
  limit: PropTypes.number,
};
