import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const ModulesListFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/modules`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useModulesListQuery = (fetcher = ModulesListFetcher) => {
  return useQueryWithAuthentication(
    ['modules-list'],
    (accessToken) => fetcher(accessToken),
    {cacheTime: Infinity, staleTime: Infinity}
  )
}
