import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


const CommandsFetcher = (accessToken, endpoint) => {
  return axios.get(`${apiUrlInternal}/commands${endpoint}`, addAuthToAxiosOpts(accessToken, {}, 1200000))
    .then(res => res.data);
}

const useCommandsQuery = (endpoint = null, options = {}, fetcher = CommandsFetcher) => {
  return useQueryWithAuthentication(['commands', endpoint], (accessToken) => fetcher(accessToken, endpoint), options)
}
export const useCommandsTTPSCountQuery = () => {
  return useCommandsQuery(`/ttps/count`)
}

export const useCommandsMalwareFamiliesCountQuery = () => {
  return useCommandsQuery(`/malware_families/count`)
}

export const useCommandsSearchQuery = (search_term, typingTimer) => {
  const urlParams = new URLSearchParams();
  let enabled = false;
  if (search_term !== null && search_term?.length >= 4 && (typingTimer < 1 || typingTimer === undefined)) {
    urlParams.set('q', search_term);
    enabled = true
  }
  return useCommandsQuery(`/search?${urlParams.toString()}`, {enabled: enabled})
}

export const useCommandsByTTPQuery = (uid) => {
  return useCommandsQuery(`/ttp/${uid}`, {enabled: !!(uid)})
}

export const useCommandsByUidQuery = (uid) => {
  return useCommandsQuery(`/${uid}`)
}




