import React from "react";
import {Document, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import {getPostTitle} from "../../../shared/helpers/contents";
import {getTlpColorHex} from "../../../shared/helpers/tlp";
import c25logo from "../../../shared/img/logo/cluster25ext.png"

const pdfStyles = StyleSheet.create({
  page: {
    color: "#DDDDDD",
    backgroundColor: "#2A2A31",
    padding: 32
  },
  font: {
    global: {
      fontFamily: "Helvetica",
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.6,
    },
    sizeBig: {fontSize: 18},
    sizeMedium: {fontSize: 14},
    sizeSmall: {fontSize: 10},
    weightBold: {fontWeight: 700}
  },
  margin: {
    top: {
      small: {marginTop: 8},
      medium: {marginTop: 16},
      big: {marginTop: 32}
    }
  },
  card: {
    backgroundColor: "#232329",
    borderRadius: 4,
    padding: 16,
    display: "flex",
    flexDirection: "column"
  },
  listItem: {
    backgroundColor: "#2A2A31",
    borderRadius: 4,
    padding: 16,
    margin: 8
  },
  pageNumbers: {
    fontSize: 11,
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center'
  }
});

const getActorPdfDocument = function (actor) {
  return (
    <Document>
      <Page size="A4" style={[pdfStyles.page, pdfStyles.font.global]}>
        <Image style={{width: 92, height: 28, padding: 4}} src={c25logo} fixed/>
        <View style={pdfStyles.card} wrap={false}>
          <View
            style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center"}}>
            <Text style={pdfStyles.font.sizeBig}>{actor.name}</Text>
            <Text style={pdfStyles.font.sizeSmall}>{actor.origin_country.name}</Text>
          </View>
          <Text style={pdfStyles.font.sizeSmall}>
            <Text>Known as </Text>
            <Text style={[pdfStyles.font.weightBold]}>{actor.aliases.join(", ")}</Text>
          </Text>
          <Text style={[pdfStyles.margin.top.medium]}>{actor.description}</Text>
        </View>
        <View style={[pdfStyles.card, pdfStyles.margin.top.medium]} wrap={false}>
          <View>
            <Text style={pdfStyles.font.sizeBig}>GROUP'S MOTIVATION</Text>
            <Text>{actor.motivations}</Text>
          </View>
        </View>
        <View style={[pdfStyles.card, pdfStyles.margin.top.medium]} wrap={false}>
          <View>
            <Text style={pdfStyles.font.sizeBig}>TARGETED SECTORS</Text>
            <Text>{actor.targeted_sectors.map(x => x.name).join(", ")}</Text>
          </View>
        </View>
        <View style={[pdfStyles.card, pdfStyles.margin.top.medium]} wrap={false}>
          <View>
            <Text style={pdfStyles.font.sizeBig}>ASSOCIATED MALWARE</Text>
            <Text>{actor.associated_malwares.join(", ")}</Text>
          </View>
        </View>
        <View style={[pdfStyles.card, pdfStyles.margin.top.medium]} wrap={false}>
          <View>
            <Text style={pdfStyles.font.sizeBig}>TARGETED COUNTRIES</Text>
            <Text>{actor.targeted_countries.map(x => x.name).join(", ")}</Text>
          </View>
        </View>
        <View style={[pdfStyles.card, pdfStyles.margin.top.medium]} wrap={true}>
          <Text style={pdfStyles.font.sizeBig}>RELATED CONTENT</Text>
          {actor.related_content.map((content, i) => {
              if (content.type === "post") {
                return (
                  <View style={pdfStyles.listItem} wrap={false} key={i}>
                    <View style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "space-between"
                    }}>
                      <View>
                        <Text
                          style={pdfStyles.font.sizeSmall}>{formatDateUTCtoYearMonthDayTime(content.created_dt)}</Text>
                        <Text style={pdfStyles.font.sizeMedium}>{getPostTitle(content)}</Text>
                      </View>
                      {
                        content.tlp &&
                        <Text style={{color: getTlpColorHex(content.tlp.tlp)}}>
                          TLP: {content.tlp.tlp.toUpperCase()}
                        </Text>
                      }
                    </View>
                    <Text>{content.text}</Text>
                  </View>
                )
              } else if (content.type === "analysis") {
                return (
                  <View style={pdfStyles.listItem} wrap={false} key={i}>
                    <View style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "space-between"
                    }}>
                      <View>
                        <Text
                          style={pdfStyles.font.sizeSmall}>{formatDateUTCtoYearMonthDayTime(content.created_dt)}</Text>
                        <Text style={pdfStyles.font.sizeMedium}>{content.title}</Text>
                      </View>
                      {
                        content.tlp &&
                        <Text style={{color: getTlpColorHex(content.tlp.tlp)}}>
                          TLP: {content.tlp.tlp.toUpperCase()}
                        </Text>
                      }
                    </View>
                    <Text>{content.text}</Text>
                  </View>
                )
              } else {
                return (
                  <View style={pdfStyles.listItem} wrap={false} key={i}>
                    <View style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "space-between"
                    }}>
                      <View>
                        <Text
                          style={pdfStyles.font.sizeSmall}>{formatDateUTCtoYearMonthDayTime(content.created_dt)}</Text>
                        <Text style={pdfStyles.font.sizeMedium}>New collection of indicators</Text>
                      </View>
                      {
                        content.tlp &&
                        <Text style={{color: getTlpColorHex(content.tlp.tlp)}}>
                          TLP: {content.tlp.tlp.toUpperCase()}
                        </Text>
                      }
                    </View>
                  </View>
                )
              }
            }
          )}
        </View>
        <View style={[pdfStyles.card, pdfStyles.margin.top.medium]} wrap={true}>
          <Text style={pdfStyles.font.sizeBig}>MITRE FRAMEWORK</Text>
          {
            actor.mitre.map((tactic, i) => {
              return (
                <View style={[pdfStyles.margin.top.small]} key={i} wrap={false}>
                  <Text style={[pdfStyles.font.weightBold, {textDecoration: 'underline'}]}>{tactic.name}: </Text>
                  <Text>{tactic.techniques.map((t) => `(${t.mitre_code}) ${t.name}`).join(", ")}</Text>
                </View>
              )
            })
          }
        </View>
        <Text
          style={pdfStyles.pageNumbers} render={({pageNumber, totalPages}) => (`${pageNumber} / ${totalPages}`)}
          fixed/>
      </Page>
    </Document>
  )
};

export default getActorPdfDocument;