import React from "react";
import TableWithData from "../table/TableWithData";
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import PropTypes from "prop-types";
import ThemedClampLines from "../clamp-lines/ThemedClampLines";
import _ from "lodash";
import DownloadContentIcon from "../icons/DownloadContentIcon";


export const tableColumns = [
  {
    Header: 'Seen on', accessor: 'created_dt',
    Cell: ({value}) => value ? formatDateUTCtoYearMonthDayTime(value) : 'N/A'
  },
  {Header: 'Author', accessor: 'author'},
  {Header: 'Forum', accessor: 'forum_name'},
  {Header: 'URL', accessor: 'url'},
  {Header: 'Thread Name', accessor: 'thread_name'},
  {
    Header: 'Content', accessor: 'post_content',
    Cell: ({value}) => !value ? 'N/A' :
      <div style={{maxWidth: '600px'}}><ThemedClampLines text={value} maxLines={100}/></div>
  },
  {
    id: 'download', Header: '', accessor: 'post_content',
    Cell: ({value, row}) => <DownloadContentIcon
      content={value}
      filename={`${row.values.created_dt}_${row.values.forum_name}.txt`}
    />
  },
];


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
  isSortable: true
}


const ThreadsTable = ({threads, hasDownloadIcon = true, extraColumnsAfter = [], extraColumnsBefore = []}) =>
  <TableWithData
    data={_.orderBy(threads, ['created_dt'], ['desc'])}
    tableColumns={
      hasDownloadIcon ?
        extraColumnsBefore.concat(tableColumns.concat(extraColumnsAfter)) :
        extraColumnsBefore.concat(tableColumns.slice(0, -1).concat(extraColumnsAfter))
    }
    tableConfig={tableConfig}
  />


ThreadsTable.propTypes = {
  threads: PropTypes.array.isRequired,
  hasDownloadIcon: PropTypes.bool,
  extraColumnsAfter: PropTypes.array,
  extraColumnsBefore: PropTypes.array,
}


export default ThreadsTable;
