import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SetAllCredentialsExpSolved = (project_uid, accessToken) => {
  return axios.put(
    `${apiUrlInternal}/keyword_projects/${project_uid}/results/credentials_exposure/select-as-solved/all`,
    {},
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data );
}


export const useSetAllCredentialsExpSolved = (fetcher = SetAllCredentialsExpSolved) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['set-as-solved-all-credentials-exposure'],
    (accessToken, project_uid) => fetcher(project_uid, accessToken),
    {onSuccess: (_, project_uid) => {
        queryClient.invalidateQueries(['set-as-solved-all-credentials-exposure']);
        queryClient.invalidateQueries(['keyword-project-results', project_uid]);
      }}
  )
}
