import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";

export const AddRansomwareTrackerEventsPreferencesFetcher = (
  accessToken, params
) => {
  const urlParams = new URLSearchParams();
  if (params.preferenceType) {
    urlParams.set('preference_type', params.preferenceType);
  }

  const data = {preferences: [{value: params.value}]}
  return axios.post(`${apiUrlInternal}/ransomware_tracker_events/preferences?${urlParams.toString()}`, data,
    addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useAddRansomwareTrackerEventsPreferenceQuery = (
  fetcher = AddRansomwareTrackerEventsPreferencesFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-ransomware-tracker-events-preferences'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ransomware-tracker-events-preferences', 'victim_country'])
        queryClient.invalidateQueries(['ransomware-tracker-events-preferences', 'actor']);
      }
    }
  )
}
