import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useAddMalwareMutation} from "../../../../mutations/AddMalware";
import {useHistory} from "react-router-dom";
import toast from "react-hot-toast";
import paths from "../../../../config/paths";
import {getErrorMessageFromResponse} from "../../../helpers";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import RegisteredInputField from "../RegisteredInputField";
import ControlledTextAreaWithTitle from "../ControlledTextAreaWithTitle";
import PropTypes from "prop-types";


const MalwareFamilyForm = ({
  defaultValues = {},
  mutationFn = useAddMalwareMutation,
  malwareFamilyName = null,
  editMalwareFamily = false
}) => {
  const {register, handleSubmit, formState: {errors}, control, reset} = useForm({defaultValues: defaultValues});
  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);
  const mutation = mutationFn();
  const history = useHistory();

  useEffect(() => {
    if (!mutation.isIdle && mutation.isLoading && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        toast.success(`malware ${editMalwareFamily ? 'updated' : 'added'} correctly`);
        setTimeout(() => {
          toast.dismiss();
          reset({name: '', description: ''});
          setButtonUploadDisabled(false);
          history.push(mutation.data?.data?.uid ? `/malware/${mutation.data.data.uid}` : paths.actorPath);
        }, 2000);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
        setButtonUploadDisabled(false);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])


  const onSubmit = (data) => {
    let params;
    if (!editMalwareFamily) {
      params = {
        name: data.name,
        description: data.description,
      }
    } else {
      params = {
        uid: malwareFamilyName,
        params: {
          description: data.description,
        }
      }
    }

    setButtonUploadDisabled(true);
    toast.loading(`Validating and ${editMalwareFamily ? 'editing' : 'creating'} malware.`);
    mutation.mutate(params);
  }

  return (
    <Container>
      <Row className={'div__sticky-top'}>
        <Col md={9}>
          <h3 className="page-title">{editMalwareFamily ? 'Update Malware' : 'New Malware'}</h3>
        </Col>
        <Col md={3} className={'justify-content-end d-flex'}>
          <Button outline onClick={() => {
            handleSubmit(onSubmit)();
          }} color={'success'} disabled={buttonUploadDisabled}>{editMalwareFamily ? 'Update' : 'Add'}</Button>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <form className="form form--vertical">
            <RegisteredInputField
              title={'Name'}
              name={'name'}
              register={register}
              errors={errors}
              rules={{required: 'The name is required'}}
              placeholder={'Name'}
              disabled={editMalwareFamily}
            />
            <ControlledTextAreaWithTitle
              name={'description'}
              control={control}
              register={register}
              title={'Description'}
              textAreaClassNames={'textarea--add-contents'}
            />
          </form>
        </CardBody>
      </Card>
    </Container>
  )
}


MalwareFamilyForm.propTypes = {
  defaultValues: PropTypes.object,
  mutationFn: PropTypes.func,
  malwareFamilyName: PropTypes.string,
  editMalwareFamily: PropTypes.bool
}


export default MalwareFamilyForm;
