import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const EditMalwareFamilyFetcher = (accessToken, params, uid) => {
  return axios.put(
    `${apiUrlInternal}/tags/${uid}`,
    params,
    addAuthToFetchOpts(accessToken, {}, 0)
  ).then(res => res.data);
}

export const useEditMalwareFamilyMutation = (fetcher = EditMalwareFamilyFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['edit-malware-family'],
    (accessToken, {params, uid}) => fetcher(accessToken, params, uid),
    {onSuccess: (_, {uid}) => {
        queryClient.invalidateQueries(['edit-malware-family']);
        queryClient.invalidateQueries(['malware-family', uid]);
        queryClient.invalidateQueries('malware-family');
      }}
  )
}
