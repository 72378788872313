import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import moment from "moment";
import {formatDateUTCtoYearMonthDayTimeFile} from "./date";
import {toast} from "react-hot-toast";
import {getElementPropertyOrDefault} from "./index";
import {paramsToObject} from "../components/router/QueryNavigationHelpers";


const fileDownload = require('js-file-download');
const _ = require('lodash');


export const downloadFormats = ['csv', 'json', 'stix2', "stix"];
export const actorExportFormatEnum = {JSON: 'JSON', PDF: 'PDF'};


export const downloadIndicators = (filters, format, accessToken) => {
  const urlParams = new URLSearchParams();

  _.forEach(filters, (value, key) => {
    if (Array.isArray(value)) {
      value.forEach((val) => urlParams.append(key, val));
    } else {
      urlParams.set(key, value);
    }
  });

  toast.loading('Downloading the file, it can take a while...')
  axios.get(
    `${apiUrlInternal}/export/indicators?include_info=true&export_format=${format}${!_.isEmpty(paramsToObject(urlParams.entries())) ? '&' + urlParams.toString() : ''}`,
    addAuthToAxiosOpts(accessToken, {}, 120000)
  ).then(res => {
    if (format === 'json' || format === 'stix2') {
      res.data = JSON.stringify(res.data);
    }

    if (format === 'stix') {
      format = 'xml'
    } else if (format === 'stix2') {
      format = 'json'
    }

    fileDownload(String(res.data), `export-${formatDateUTCtoYearMonthDayTimeFile(moment())}.` + format);
    toast.dismiss()
  }).catch((err) => {
    toast.dismiss();
    toast.error('Error during the download of the file');
  });
}

export const download_file = (
  url, file_name, accessToken, timeout = 20000, download_message = 'Download started'
) => {
  const promiseDownloadFile = axios.get(url, addAuthToAxiosOpts(accessToken, {responseType: 'blob',}, timeout));

  toast.promise(promiseDownloadFile, {
      loading: download_message,
      success: res => {
        fileDownload(res.data, file_name);
        return 'Download completed successfully';
      },
      error: (error) => `Error: ${getElementPropertyOrDefault(error, 'message', 'unknown')}`
    },
  );
}

const getTmpFileNameRuleFromNow = () =>
  `rules-${formatDateUTCtoYearMonthDayTimeFile(moment())}.rules`


const BASE_SID = 1000001;


const getSnortRulesByIPs = (ips, customRuleText, baseSid = BASE_SID) => {
  let rules = ""

  _.forEach(ips, (ip, index) => {
    const tags = _.join(ip.tags.map((tag) => tag.name), ',');
    rules += `alert ip $HOME_NET any -> ${ip.value} any (msg:"Cluster25 rules automatically generated for threat${tags.length > 1 ? 's' : ''} ${ip.tags.length === 0 ? 'generic' : tags}"; ` +
      `${customRuleText}; sid:${baseSid + index * 2 - 1}; rev:1;)\n` +
      `alert ip ${ip.value} -> $HOME_NET any (msg:"Cluster25 rules automatically generated for threat${tags.length > 1 ? 's' : ''} ${ip.tags.length === 0 ? 'generic' : tags}"; ` +
      `${customRuleText}; sid:${baseSid + index * 2}; rev:1;)\n`;
  })

  return rules;
}

const getSnortRulesByDomains = (domains, customRuleText, baseSid = BASE_SID) => {
  let rules = ""

  _.forEach(domains, (domain, index) => {
    const tags = _.join(domain.tags.map((tag) => tag.name), ',');
    rules += `alert dns $HOME_NET any -> any any (msg:"Cluster25 rules automatically generated for threat${tags.length > 1 ? 's' : ''} ${domain.tags.length === 0 ? 'generic' : tags}"; ` +
      `target:src_ip; dns_query; content:"${domain.value}"; depth:${domain.value.length}; fast_pattern; nocase; ${customRuleText}; sid:${baseSid + index}; rev:1;)\n`;
  })

  return rules;
}


export const downloadIpSnortAutogeneratedRules = (accessToken, filters, customRuleText) => {
  const urlParams = new URLSearchParams();
  _.forEach(filters, (value, filter) => urlParams.set(filter, value));
  urlParams.append('types', 'ipv4');
  urlParams.set('include_info', 'true');

  toast.promise(
    axios.get(`${apiUrlInternal}/indicators?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {})), {
      loading: 'Download started',
      success: res => {
        fileDownload(getSnortRulesByIPs(res.data.data, customRuleText), getTmpFileNameRuleFromNow())
        return 'Download completed successfully';
      },
      error: (error) => `Error: ${getElementPropertyOrDefault(error, 'message', 'unknown')}`
    }
  )
}

export const downloadDnsSnortAutogeneratedRules = (accessToken, filters, customRuleText) => {
  const urlParams = new URLSearchParams();
  _.forEach(filters, (value, filter) => urlParams.set(filter, value));
  urlParams.append('types', 'domain');
  urlParams.set('include_info', 'true');

  toast.promise(
    axios.get(`${apiUrlInternal}/indicators?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {})), {
      loading: 'Download started',
      success: res => {
        fileDownload(getSnortRulesByDomains(res.data.data, customRuleText), getTmpFileNameRuleFromNow())
        return 'Download completed successfully';
      },
      error: (error) => `Error: ${getElementPropertyOrDefault(error, 'message', 'unknown')}`
    }
  )
}

export const downloadIpsDnsSnortAutogeneratedRules = (accessToken, filters, customRuleText) => {
  const urlParams = new URLSearchParams();
  _.forEach(filters, (value, filter) => urlParams.set(filter, value));
  urlParams.append('types', 'domain');
  urlParams.append('types', 'ipv4');
  urlParams.set('include_info', 'true');

  toast.promise(
    axios.get(`${apiUrlInternal}/indicators?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {})), {
      loading: 'Download started',
      success: res => {
        const ips = res.data.data.filter((ioc) => ioc.type === 'ipv4');
        const domains = res.data.data.filter((ioc) => ioc.type === 'domain');
        const ipsRules = getSnortRulesByIPs(ips, customRuleText);
        const domainsRules = getSnortRulesByDomains(domains, customRuleText, BASE_SID + ips.length * 2 - 1);

        fileDownload(`${ipsRules}${domainsRules}`, getTmpFileNameRuleFromNow())
        return 'Download completed successfully';
      },
      error: (error) => `Error: ${getElementPropertyOrDefault(error, 'message', 'unknown')}`
    }
  )
}
