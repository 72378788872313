import React from "react";
import TableWithData from "../../components/table/TableWithData";
import PropTypes from "prop-types";
import {formatDateUTCtoYearMonthDay} from "../../helpers/date";


export const tableColumns = [
  { Header: 'Dump Date', accessor: 'dumpdate', Cell: ({value}) => formatDateUTCtoYearMonthDay(value) },
  { Header: 'Username', accessor: 'username' },
  { Header: 'Password', accessor: 'password' },
  { Header: 'URL', accessor: 'url' },
  { Header: 'IP', accessor: 'ip' },
  { Header: 'Source', accessor: (row) => row?.source || row?.file_path },
];

const getCsvMappedData = (data)=>{
  let finalData = [];
  if(data && data.length > 0){
    data.forEach((item) => {
      finalData.push({
        'Dump Date': item.dumpdate ? formatDateUTCtoYearMonthDay( item.dumpdate ) : 'N/A',
        'Username': item.username,
        'Password': item.password,
        'URL':  item.url,
        'Source': item.source
      })
    });
  }
  return finalData && finalData.length > 0 ? finalData : [];
};


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
  // manualPageSize: [20],
  isSortable: true,
  withCsvDownload: true
}


const CredentialTable = ({credentials, extraColumns = []}) =>
  <TableWithData
    data={credentials}
    tableColumns={tableColumns.concat(extraColumns)}
    tableConfig={tableConfig}
    csvData={getCsvMappedData(credentials)}
  />



CredentialTable.propTypes = {
  credentials: PropTypes.array.isRequired,
  extraColumns: PropTypes.array
}


export default CredentialTable;
