import React, {Fragment} from "react";
import PropTypes from "prop-types";
import CommandsCard from "../../../../shared/components/card/CommandsCard";
import {useCommandsByUidQuery} from "../../../../queries/Commands";
import ActorsCard from "../../../../shared/components/card/ActorsCard";
import {Card, CardBody, Col, Row} from "reactstrap";
import ContentCardHeader from "../../../../shared/components/card/accessories/ContentCardHeader";
import ReferencesRow from "../../../../shared/components/card/accessories/ReferencesRow";
import ContentCardFooter from "../../../../shared/components/card/accessories/ContentCardFooter";
import ContentEditsBar from "../../../../shared/components/card/accessories/ContentEditsBar";
import {useCurrentUserQuery} from "../../../../queries/CurrentUser";
import {canUserCreateContent} from "../../../../shared/helpers/permissions";
import paths from "../../../../config/paths";
import {useHistory} from "react-router-dom";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../../shared/components/ErrorHandler";

const CommandsCollapseContent = ({command}) => {
  const {data: dataCurrentUser, isIdle: isIdleCurrentUser, isLoading: isLoadingCurrentUser} = useCurrentUserQuery();
  const isEditable = canUserCreateContent(dataCurrentUser?.data);

  const history = useHistory();
  const updateFilters = (field, value) => {
    const urlParams = new URLSearchParams();
    urlParams.set(field, value);

    history.push(`${paths.contentsPath}?${urlParams.toString()}`);
  }
  const {
    data: dataCommandsByUid,
    isLoading: isLoadingCommandsUid,
    isIdle: isIdleCommandsUid,
    isError: isErrorCommandsUid,
    error: errorCommandsUid
  } = useCommandsByUidQuery(command.uid)

  if (isErrorCommandsUid) {
    return <ErrorHandler
      error={errorCommandsUid}/>
  }
  if (!command) {
    return <Fragment/>
  }
  if (isLoadingCommandsUid || isIdleCommandsUid) {
    return <Card><CardBody><LoadingSpinner/></CardBody></Card>;
  }
  return (
    <>
      <Row>
        <CommandsCard commands={[command]} withTitle={false} singleTab={true}/>
      </Row>

      <Row>
        <Col md={5} lg={5} xl={5}>
          {dataCommandsByUid?.data && <ActorsCard actors={dataCommandsByUid.data.related_analysis.actors}/>}
        </Col>
        <Col md={7} lg={7} xl={7}>
          {dataCommandsByUid?.data && <Card className={'h-auto'}>
            <CardBody>
              <ContentCardHeader
                updateFilters={updateFilters}
                confidence={dataCommandsByUid.data.related_analysis.confidence}
                tlp={dataCommandsByUid.data.related_analysis.tlp.tlp}
                title={dataCommandsByUid.data.related_analysis.title}
                created_dt={dataCommandsByUid.data.related_analysis.created_dt}
                uid={dataCommandsByUid.data.related_analysis.uid}
              />
              <pre className={'mb-3 pre--text'}>{dataCommandsByUid.data.related_analysis.text}</pre>
              {dataCommandsByUid.data.related_analysis.references?.length > 0 &&
                <ReferencesRow references={dataCommandsByUid.data.related_analysis.references}/>}
              <ContentCardFooter tags={dataCommandsByUid.data.related_analysis.tags}
                                 tlp={dataCommandsByUid.data.related_analysis.tlp.tlp}/>
              {isEditable && <div style={{position: 'absolute', bottom: '40px', right: '10px'}}><ContentEditsBar
                content={dataCommandsByUid.data.related_analysis} type={'analysis'}/></div>}
            </CardBody>
          </Card>}
        </Col>
      </Row>

    </>

  )

}


CommandsCollapseContent.propTypes = {
  command: PropTypes.object
}


export default CommandsCollapseContent;
