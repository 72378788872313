import TimeLineChartCard from "../../../shared/components/charts/TimeLineChartCard";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";


const MalwareFamilyTimeLineHistogram = ({stats, malware}) =>
  <TimeLineChartCard
    title={`${malware} activities`}
    dateTimeField={'date'}
    items={stats.map((s) => ({date: moment(s.day).toDate(), hits: s.count}))}
    monthlyScale
  />


MalwareFamilyTimeLineHistogram.propTypes = {
  malware: PropTypes.string.isRequired,
  stats: PropTypes.array.isRequired
}


export default MalwareFamilyTimeLineHistogram;
