import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useAddRansomwareTrackerEventsPreferenceQuery} from "../../../mutations/AddRansomwareTrackerEventPreference";
import {
  useDeleteRansomwareTrackerEventsPreferenceQuery
} from "../../../mutations/DeleteRansomwareTrackerEventPreference";
import {useActorDetailsQuery} from "../../../queries/Actor";
import {useCountriesQuery} from "../../../queries/Countries";
import {Button, Col, Container, Row} from "reactstrap";
import ControlledSelectWithTitle from "./ControlledSelectWithTitle";
import ReactCountryFlag from "react-country-flag";
import {useForm} from "react-hook-form";
import {getCountryNameOrCodeShort} from "../../helpers/country-data";
import LoadingSpinner from "../LoadingSpinner";
import ErrorHandler from "../ErrorHandler";
import toast from "react-hot-toast";


const RansomwareTrackerEventPreferencesForm = ({
                                                 defaultValues = {actor_preferences: [], country_preferences: []},
                                               }) => {
  const mutationAdd = useAddRansomwareTrackerEventsPreferenceQuery()
  const mutationDel = useDeleteRansomwareTrackerEventsPreferenceQuery()


  const {
    data: dataActors,
    isIdle: isIdleActors,
    isLoading: isLoadingActors,
    isError: isErrorActors
  } = useActorDetailsQuery();
  const {
    data: dataCountries,
    isIdle: isIdleCountries,
    isLoading: isLoadingCountries,
    isError: isErrorCountries
  } = useCountriesQuery();


  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);


  const {register, handleSubmit, formState: {errors}, control, watch, clearErrors} = useForm({
    defaultValues: defaultValues
  });
  const countryOptions = dataCountries?.data.map((country) => ({
    value: country.name,
    label: getCountryNameOrCodeShort(country.iso_code),
    iso_code: country.iso_code
  }));
  const actorOptions = dataActors?.data.map((actor) => ({
    value: actor.name,
    label: actor.name
  }))

  const isIdleLoading = isIdleCountries || isLoadingCountries || isIdleActors || isLoadingActors
  const isError = isErrorActors || isErrorCountries

  const onSubmit = (data) => {

    defaultValues.actor_preferences.forEach((actor) => {
      if (!data?.actors.find((a) => a.value === actor.value)) {
        mutationDel.mutate({uid: actor.uid})
      }
    })
    data.actors.forEach((actor) => {
      if (!defaultValues.actor_preferences.find((a) => a.value === actor.value)) {
        mutationAdd.mutate({preferenceType: 'actor', value: actor.value})
      }
    })
    defaultValues.country_preferences.forEach(country => {
      if (!data?.victim_countries.find((c) => c.value === country.value)) {
        mutationDel.mutate({uid: country.uid})
      }
    })
    data.victim_countries.forEach(country => {
      if (!defaultValues.country_preferences.find((c) => c.value === country.value)) {
        mutationAdd.mutate({preferenceType: 'victim_country', value: country.value})
      }
    })

  }

  useEffect(() => {
    if (!mutationDel.isIdle) {
      if (mutationDel.isLoading) {
        toast.loading('Deleting preference');
      } else {
        toast.dismiss();
        if (mutationDel.isSuccess) {
          toast.success('Deleted preference successfully');
        } else if (mutationDel.isError) {
          toast.error('Error during preference deletion');
          mutationDel.reset();
        }
      }
    }
  }, [mutationDel.isIdle, mutationDel.isError, mutationDel.isLoading, mutationDel.isSuccess])

  useEffect(() => {
    if (!mutationAdd.isIdle) {
      if (mutationAdd.isLoading) {
        toast.loading('Adding preference');
      } else {
        toast.dismiss();
        if (mutationAdd.isSuccess) {
          toast.success('Added preference successfully');
        } else if (mutationAdd.isError) {
          toast.error('Error during preference addition');
          mutationAdd.reset();
        }
      }
    }
  }, [mutationAdd.isIdle, mutationAdd.isError, mutationAdd.isLoading, mutationAdd.isSuccess])


  if (isIdleLoading) {
    return <Container><LoadingSpinner/></Container>
  }

  if (isError) {
    return <ErrorHandler/>
  }

  return (
        <>
          <ControlledSelectWithTitle
            name={'victim_countries'}
            title={'Victim countries'}
            control={control}
            isSearchable isMulti isClearable
            valueFn={(value) => countryOptions?.find((c) => c.value === value?.value)}
            options={countryOptions}
            defaultValue={
              defaultValues.country_preferences?.map((country) => countryOptions.find((c) => c.value.includes(country.value)))
            }
            getOptionLabel={
              option => <p><ReactCountryFlag countryCode={option.iso_code} className={'mr-1'} svg/>{option.label}</p>
            }
          />
          <ControlledSelectWithTitle
            name={'actors'}
            title={'Actors'}
            control={control}
            isSearchable isMulti isClearable
            valueFn={(value) => actorOptions?.find((c) => c.value === value?.value)}
            options={actorOptions}
            defaultValue={
              defaultValues.actor_preferences?.map((actor) => actorOptions.find((a) => a.value.toLowerCase() === actor.value.toLowerCase()))
            }
          />
          <Row>
            <Col>
              <Button size={'xs'} className={'float-right'} onClick={() => {
                clearErrors('');
                handleSubmit(onSubmit)();
              }} color={'success'} disabled={buttonUploadDisabled}>{'Save'}</Button>
            </Col>

          </Row>
        </>
  )
}


RansomwareTrackerEventPreferencesForm.propTypes = {
  defaultValues: PropTypes.shape(),
}
export default RansomwareTrackerEventPreferencesForm;
