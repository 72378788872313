import PropTypes from "prop-types";
import {Col, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import React, {Fragment, useEffect, useState} from "react";
import OrderDropDownMenu from "./OrderDropDownMenu";
import {formatDateUTCtoYearMonthDay} from "../../helpers/date";
import moment from "moment";
import FiltersMenu from "../search-menu/FiltersMenu";
import SearchBar from "../search-menu/SearchBar";
import DatePickerWithLabel from "./DatePickerWithLabel";
import FiltersList from "./FiltersList";
import {useHistory, useLocation} from "react-router-dom";
import {paramsToObject, useQueryParams} from "../router/QueryNavigationHelpers";


const _ = require('lodash');


const SearchMenu = ({
  setFilters,
  baseUrl,
  defaultFilters = {},
  searchPlaceholder = '',
  orderMenu = [],
  filterMenu = [],
  fromDateField = '',
  toDateField = '',
  searchField,
  additionalColumns = []
}) => {
  const h = useHistory();
  const location = useLocation();
  const params = paramsToObject(useQueryParams().entries());
  const MAX_DATE = moment();
  const FROM_DATE = moment().subtract(1, 'months');
  const TO_DATE = moment();
  const initFilters = [];
  let initSearchTerm = "";
  let initFromDate = FROM_DATE;
  let initToDate = TO_DATE;
  if (defaultFilters) {
    for (const [field, value] of Object.entries(defaultFilters)) {
      if (field !== fromDateField && field !== toDateField && field !== searchField && field !== 'order_by') {
        const filterSelected = _.find(filterMenu, (filter) => filter.field === field);
        if (!filterSelected) {
          continue;
        }

        let values;
        if (!_.isArray(value)) {
          values = [value];
        } else {
          values = value;
        }

        _.forEach(values, (value) => {
          let filterSelected;

          if (['malware_family', 'tags', 'botnet', 'campaign'].includes(field)) {
            filterSelected = _.find(
              filterMenu,
              (filter) => filter.values.map(v => v.toLowerCase()).includes(value)
            );
          } else {
            filterSelected = _.find(filterMenu, (filter) => filter.field === field);
          }

          if (!filterSelected) {
            return
          }

          let valueSelected = '';
          if (filterSelected.field !== 'tlps') {
            valueSelected = _.find(filterSelected.values, (filterValue) => filterValue.toLowerCase() === value.toLowerCase());
          } else {
            valueSelected = _.find(filterSelected.values, (filterValue) => filterValue === value);
          }

          if (!valueSelected || initFilters.map(f => f.value).includes(value)) {
            return;
          }

          initFilters.push({
            displayField: filterSelected.displayField,
            field: filterSelected.field,
            filter_field: filterSelected.filter_field,
            value: valueSelected,
            displayValue: filterSelected.displayValueFn(valueSelected),
            multi: filterSelected.multi,
            filterSelectedPredicate: filterSelected.filterSelectedPredicate
          })
        })

      } else if (field === 'order_by') {
        const filterSelected = _.find(orderMenu, (filter) => filter.value === value);

        initFilters.push({
          field: 'order_by',
          value: filterSelected.value,
          displayField: 'ORDER BY',
          displayValue: filterSelected.label
        });
      } if (field === searchField) {
        initFilters.push({
          field: field,
          value: value
        });
        initSearchTerm = value;
      } else if (field === fromDateField || field === toDateField) {
        if (!moment(value).isValid()) {
          continue;
        }
        let dateValue = moment(value);
        if (field === fromDateField) {
          if (dateValue.isAfter(initToDate)) {
            dateValue = initFromDate;
          }
          initFilters.push({
            displayField: 'FROM',
            field: field,
            value: dateValue.toISOString(),
            displayValue: formatDateUTCtoYearMonthDay(dateValue)
          });
          initFromDate = dateValue;
        } else {
          if (initFromDate.isAfter(dateValue)) {
            dateValue = moment().isAfter(initFromDate.clone().add(1, 'months')) ?
              initFromDate.clone().add(1, 'months') : moment();
          }
          initFilters.push({
            displayField: 'TO',
            field: field,
            value: dateValue.toISOString(),
            displayValue: formatDateUTCtoYearMonthDay(dateValue)
          });
          initToDate = dateValue;
        }
      }
    }
  }

  const [searchTerm, setSearch] = useState(initSearchTerm);
  const [currentFilters, setCurrentFilters] = useState(
    initFilters.length === 0 && searchField ? [{
      field: searchField,
      value: ''
    }] : initFilters);
  const [fromDate, setFromDate] = useState(initFromDate.toDate());
  const [toDate, setToDate] = useState(initToDate.toDate());

  const filtersToQuery = () => {
    if (currentFilters.length === 0) {
      return {};
    }

    const f = {};
    currentFilters.forEach((filter) => {
      const field = filter.filter_field || filter.field;

      if (!filter.multi) {
        f[field] = filter.value;
      } else {
        if (f[field]) {
          f[field].push(filter.value);
        } else {
          f[field] = [filter.value];
        }
      }
    });

    return f;
  }


  const filtersToObject = (filters) => {
    const obj = {}

    _.forEach(filters, (elem) => {
      if (!_.isUndefined(obj[elem.field])) {
        if (!_.isArray(obj[elem.field])) {
          obj[elem.field] = [obj[elem.field], elem.value];
        } else {
          obj[elem.field].push(elem.value);
        }

      } else {
        obj[elem.field] = elem.value;
      }
    })

    return obj;
  }

  useEffect(() => {
    setFilters(filtersToQuery());
    const filterObjs = filtersToObject(currentFilters);
    const urlParams = new URLSearchParams();

    let areParamsChanged = false;

    const filter_keys = _.keys(filterObjs);
    const param_keys = _.keys(params);

    if (filter_keys.length === param_keys.length) {
      for (let i = 0; i < filter_keys.length; ++i) {
        const filter_key = filter_keys[i];
        const param_item = params[filter_key];
        const filter_item = filterObjs[filter_key];


        if (_.isUndefined(param_item)) {
          areParamsChanged = true;
          break;
        } else {
          if (
            _.isArray(param_item) &&
            ((param_item.length !== filter_item.length) ||
            _.some(param_item.map(v => !filter_item.includes(v))))
          ) {
            areParamsChanged = true;
          } else if (
            !_.isArray(param_item) &&
            ((param_item !== filter_item && !_.isArray(filter_item)) ||
            (_.isArray(filter_item) && !filter_item.includes(param_item)))
          ) {
            areParamsChanged = true;
            break;
          }
        }
      }
    } else {
      areParamsChanged = true;
    }

    if (filterObjs[searchTerm] === "") {
      delete filterObjs[searchTerm];
    }

    _.entries(filterObjs).forEach(([filter_field, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => urlParams.append(filter_field, v));
      } else {
        urlParams.set(filter_field, value)
      }
    });

    if (
      `${baseUrl}?${location.search}` !== `${baseUrl}?${urlParams}` &&
      (urlParams.size !== 1 || urlParams.get(searchField) !== '') &&
      areParamsChanged
    ) {
      h.replace(`${baseUrl}?${urlParams}`);
    }

  }, [currentFilters]);


  useEffect(() => {
    const t = setTimeout(() => {
      const filters = currentFilters.filter((value) => value.field !== searchField);
      const currentSearchTerm = currentFilters.filter((value) => value.field !== searchField);

      if (
        ((currentSearchTerm.value === searchTerm && currentSearchTerm.value === '') ||
          (_.isArray(currentSearchTerm) && currentSearchTerm.length === 0)) && _.isUndefined(searchField)
      ) {
        return
      }

      if ((searchField && searchTerm.length >= 3) || !_.isUndefined(searchField)) {
        filters.push({
          field: searchField,
          value: searchTerm
        });

        setCurrentFilters(filters);
      }

    }, 500);
    return () => clearTimeout(t);
  }, [searchTerm]);

  const addDateFilter = (field, value, is_from_date) => {
    const filters = currentFilters.filter((filter) => filter.field !== field);
    if (is_from_date) {
      if (value > toDate) {
        return;
      }

      value = moment(value).hours(0).minutes(0).seconds(0).milliseconds(0).toDate();
      filters.push({
        displayField: 'FROM',
        field: field,
        value: value.toISOString(),
        displayValue: formatDateUTCtoYearMonthDay(value)
      });
      setFromDate(value);
    } else {
      if (value < fromDate) {
        return;
      }

      value = moment(value).hours(23).minutes(59).seconds(59).milliseconds(99).toDate();
      filters.push({
        displayField: 'TO',
        field: field,
        value: value.toISOString(),
        displayValue: formatDateUTCtoYearMonthDay(value)
      });
      setToDate(value);
    }

    setCurrentFilters(filters);
  }

  const deleteFilter = (index) => {
    const filters = currentFilters.slice();
    const filter = filters.splice(index, 1);
    if (filter[0].field === toDateField) {
      setToDate(TO_DATE.toDate());
    } else if (filter[0].field === fromDateField) {
      setFromDate(FROM_DATE.toDate());
    }
    setCurrentFilters(filters);
  }

  const orderBy = (q) => {
    const filters = currentFilters.filter((value) => value.field !== 'order_by');
    filters.push({
      field: 'order_by',
      value: q.value,
      displayField: 'ORDER BY',
      displayValue: q.label
    });
    setCurrentFilters(filters);
  }

  return (
    <Fragment>
      <Row>
        {
          searchField && <Col md={6}>
            <SearchBar
              searchPlaceholder={searchPlaceholder}
              setSearch={setSearch}
              searchTerm={searchTerm}
            />
          </Col>
        }

        {
          filterMenu && filterMenu.length > 0 && <Col>
            <FiltersMenu
              currentFilters={currentFilters}
              filterMenu={filterMenu}
              setCurrentFilters={setCurrentFilters}
            />
          </Col>
        }

        {
          orderMenu && orderMenu.length > 0 && <Col>
            <OrderDropDownMenu
              orderMenu={orderMenu}
              orderBy={orderBy}
            />
          </Col>
        }

        {
          (fromDateField || toDateField) && <Col>
            <UncontrolledDropdown>
              <DropdownToggle
                className="icon icon--right outline dropdown__toggle"
                outline
                color={'success'}
              >
                <p className={'justify-content-between'}>Date<ChevronDownIcon/></p>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu__custom">
                <form className="form">
                  {
                    fromDateField && <div className={'mb-2 w-100'}><DatePickerWithLabel
                      label={'From'}
                      selected={fromDate}
                      maxDate={MAX_DATE}
                      onChange={(date) => addDateFilter(fromDateField, date, true)}
                    /></div>
                  }
                  {
                    toDateField && <div className={'w-100'}><DatePickerWithLabel
                      label={'To'}
                      selected={toDate}
                      maxDate={MAX_DATE}
                      onChange={(date) => addDateFilter(toDateField, date, false)}
                    /></div>
                  }
                </form>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        }

        {
          additionalColumns.map(col => (
            <Col>
              {col}
            </Col>
          ))
        }

      </Row>
      <FiltersList currentFilters={currentFilters} deleteFilter={deleteFilter}/>
    </Fragment>
  )
}

SearchMenu.propType = {
  setFilters: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
  orderMenu: PropTypes.array,
  filterMenu: PropTypes.array,
  fromDateField: PropTypes.string,
  toDateField: PropTypes.string,
  searchField: PropTypes.string,
  additionalColumns: PropTypes.array,
}

export default SearchMenu;