import {Button, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import DarksearchBotsTable from './DarksearchBotsTable';
import _ from 'lodash';
import SellersTable from './SellersTable';
import ThreadsTable from './ThreadsTable';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useCurrentUserQuery} from '../../../queries/CurrentUser';
import {isUserInternal} from '../../helpers/permissions';
import {useSetAllDarknetsSolved} from '../../../mutations/SetAllDarknetsSolved';
import {useSetDarknetsSolved} from '../../../mutations/SetDarknetsSolved';
import {useForm} from 'react-hook-form';
import RegisteredCheckBoxField from '../form/RegisteredCheckBoxField';
import toast from 'react-hot-toast';
import {getErrorMessageFromResponse} from '../../helpers';
import DarknetTableWithPagination
  from '../../../containers/IntelligenceIdentity/components/Darknet/components/DarknetTableWithPagination';
import {useCookieAccessToken} from "../../../queries";
import {download_file} from "../../helpers/download_file";
import {apiUrlInternal} from "../../../config/api";
import {formatDateUTCtoYearMonthDayTimeFile} from "../../helpers/date";
import moment from "moment";

const SECTIONS = {
  bots: 0,
  sellers: 1,
  threads: 2,
}


const DarksearchTabs = ({
  bots,
  sellers,
  threads,
  isIdentityIntelligence = false,
  project_uid = null,
  project_name = null
}) => {
  const botsCount = isIdentityIntelligence ? bots : bots.length;
  const sellersCount = isIdentityIntelligence ? sellers : sellers.length;
  const threadsCount = isIdentityIntelligence ? threads : threads.length;
  const [activeTab, setActiveTab] = useState(
    _.indexOf([botsCount, sellersCount, threadsCount], _.maxBy([botsCount, sellersCount, threadsCount], (v) => v))
  );

  const mutationSetAllDarknetsSolved = useSetAllDarknetsSolved();
  const mutationSetDarknetsSolved = useSetDarknetsSolved();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnSelectDisabled, setBtnSelectDisabled] = useState(true);
  const {
    register,
    setValue,
    watch
  } = useForm();
  const [uidsSelected, setUidsSelected] = useState([]);
  const {data: accessToken} = useCookieAccessToken();

  const unsetSelection = () => {
    for (let i = 0; i < _.max([botsCount, sellersCount, threadsCount]); ++i) {
      setValue(`darknet.${i}`, false);
    }
  };

  useEffect(() => {
    setBtnSelectDisabled(uidsSelected.length === 0);
  }, [uidsSelected]);

  useEffect(() => {
    toast.dismiss();

    if (!mutationSetAllDarknetsSolved.isIdle) {
      if (mutationSetAllDarknetsSolved.isLoading) {
        toast.loading('Marking all Darknets as solved');
      } else {
        setBtnDisabled(false);
        if (mutationSetAllDarknetsSolved.isSuccess) {
          toast.success('Darknets marked as solved');
          unsetSelection();
        } else if (mutationSetAllDarknetsSolved.isError) {
          toast.error(getErrorMessageFromResponse(mutationSetAllDarknetsSolved));
          mutationSetAllDarknetsSolved.reset();
        }
      }
    }
  }, [
    mutationSetAllDarknetsSolved.isLoading,
    mutationSetAllDarknetsSolved.isIdle,
    mutationSetAllDarknetsSolved.isSuccess,
    mutationSetAllDarknetsSolved.isError,
    getErrorMessageFromResponse(mutationSetAllDarknetsSolved),
  ]);

  useEffect(() => {
    if (!mutationSetDarknetsSolved.isIdle) {
      toast.dismiss();

      if (mutationSetDarknetsSolved.isLoading) {
        toast.loading('Marking Darknets as solved');
      } else {
        setBtnDisabled(false);
        if (mutationSetDarknetsSolved.isSuccess) {
          toast.success('Darknets marked as solved');
          unsetSelection();
        } else if (mutationSetDarknetsSolved.isError) {
          toast.error(getErrorMessageFromResponse(mutationSetDarknetsSolved));
          mutationSetDarknetsSolved.reset();
        }
      }
    }
  }, [
    mutationSetDarknetsSolved.isLoading,
    mutationSetDarknetsSolved.isIdle,
    mutationSetDarknetsSolved.isSuccess,
    mutationSetDarknetsSolved.isError,
    getErrorMessageFromResponse(mutationSetDarknetsSolved),
  ]);

  const {data} = useCurrentUserQuery();

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const hasDownloadIcon = !isUserInternal(data.data);

  const getSectionNameByIndex = (index) => {
    return _.keys(SECTIONS)[index];
  };

  const getEnumByIndex = (index) => {
    const section = getSectionNameByIndex(index);

    if (section === 'sellers') {
      return 'seller';
    } else if (section === 'bots') {
      return 'botnet';
    } else if (section === 'threads') {
      return 'thread'
    }
  };

  const getExtraColumnBefore = (section) => {
    return (
      [
        {
          Header: 'Status',
          accessor: 'solved',
          Cell: ({value, row}) => {
            const label = value ?
              <p className={'d-inline'} style={{color: '#4ce1b6'}}>Solved</p> :
              <p className={'d-inline'} style={{color: '#ff4861'}}>New Item</p>;

            const index = row.index;
            const uid = row.original.uid;

            return (
              <>
                {
                  !value &&
                  <RegisteredCheckBoxField
                    name={`${section}.${index}`}
                    onChange={() => {
                      if (watch(`${section}.${index}`) && !uidsSelected.includes(uid)) {
                        setUidsSelected(uidsSelected.concat([uid]));
                      } else if (!watch(`${section}.${index}`) && uidsSelected.includes(uid)) {
                        setUidsSelected(uidsSelected.filter(val => val !== uid));
                      }
                    }}
                    value={watch(`${section}.${index}`)}
                    defaultChecked={false}
                    label={''}
                    register={register}
                    style={{display: 'inline'}}
                    className={'d-inline'}
                  />
                }
                {label}
              </>
            );
          }
        }
      ]
    );
  };

  return (
    <div className="tabs tabs--bordered-bottom">
      <div className="tabs__wrap">
        {
          isIdentityIntelligence &&
          <>
            <Button
              onClick={(e) => {
                e.preventDefault();
                download_file(
                  `${apiUrlInternal}/keyword_projects/${project_uid}/results/darknets/export?darknet_type=${getEnumByIndex(activeTab)}`,
                  `export_${project_name}_darknet_${getEnumByIndex(activeTab)}_${formatDateUTCtoYearMonthDayTimeFile(moment())}.csv`,
                  accessToken,
                  60000,
                  'Creating the CSV file, it can take few minutes'
                );
              }}
              outline
              color={'success'}
              className={'float-right py-1 mr-0'}
            >
              Download
            </Button>
            <Button
              onClick={() => {
                setBtnDisabled(true);
                mutationSetAllDarknetsSolved.mutate({
                  project_uid: project_uid,
                  section: getEnumByIndex(activeTab)
                });
              }}
              className={'float-right py-1 mr-2'}
              disabled={btnDisabled}
            >
              Mark All As Solved
            </Button>
            <Button
              onClick={() => {
                setBtnDisabled(true);
                mutationSetDarknetsSolved.mutate({
                  project_uid: project_uid,
                  uids: uidsSelected
                });
              }}
              className={'float-right py-1 mr-2'}
              disabled={btnDisabled || btnSelectDisabled}
            >
              Mark Selected As Solved
            </Button>
          </>
        }
        <Nav tabs>
          {
            botsCount > 0 &&
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === SECTIONS.bots})}
                onClick={() => toggleTab(SECTIONS.bots)}
              >BOTS ({botsCount})</NavLink>
            </NavItem>
          }
          {
            sellersCount > 0 &&
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === SECTIONS.sellers})}
                onClick={() => toggleTab(1)}
              >SELLERS ({sellersCount})</NavLink>
            </NavItem>
          }
          {
            threadsCount > 0 &&
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === SECTIONS.threads})}
                onClick={() => toggleTab(SECTIONS.threads)}
              >THREADS ({threadsCount})</NavLink>
            </NavItem>
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          {
            botsCount > 0 &&
            <TabPane tabId={SECTIONS.bots}>
              {isIdentityIntelligence ? <DarknetTableWithPagination
                project_uid={project_uid}
                type={'bots'}
                totalItems={botsCount}
                extraColumnsAfter={[{
                  Header: 'Keyword',
                  accessor: 'keyword.value'
                },]}
                extraColumnsBefore={getExtraColumnBefore('bots')}
                isIdentityIntelligence={isIdentityIntelligence}
                customSort={true}
              /> : <DarksearchBotsTable
                bots={_.sortBy(bots, (b) => b.created_dt).reverse()}
                hasDownloadIcon={hasDownloadIcon}
              />}
            </TabPane>
          }
          {
            sellersCount > 0 &&
            <TabPane tabId={SECTIONS.sellers}>
              {isIdentityIntelligence ? <DarknetTableWithPagination
                  project_uid={project_uid}
                  type={'sellers'}
                  totalItems={sellersCount}
                  extraColumnsAfter={[{
                    Header: 'Keyword',
                    accessor: 'keyword.value'
                  }]}
                  extraColumnsBefore={getExtraColumnBefore('sellers')}
                  customSort={true}
                /> :
                <SellersTable
                  sellers={_.sortBy(sellers, (s) => s.created_dt).reverse()}
                  hasDownloadIcon={hasDownloadIcon}
                />}
            </TabPane>
          }
          {
            threadsCount > 0 &&
            <TabPane tabId={SECTIONS.threads}>
              {isIdentityIntelligence ? <DarknetTableWithPagination
                  project_uid={project_uid}
                  type={'threads'}
                  totalItems={threads}
                  extraColumnsAfter={[{
                    Header: 'Keyword',
                    accessor: 'keyword.value'
                  },]}
                  extraColumnsBefore={getExtraColumnBefore('threads')}
                  customSort={true}
                /> :
                <ThreadsTable
                  threads={_.sortBy(threads, (t) => t.created_dt).reverse()}
                  hasDownloadIcon={hasDownloadIcon}
                />}
            </TabPane>
          }
        </TabContent>
      </div>
    </div>
  );
};

DarksearchTabs.propTypes = {
  bots: PropTypes.number.isRequired,
  sellers: PropTypes.number.isRequired,
  threads: PropTypes.number.isRequired,
  isIdentityIntelligence: PropTypes.bool,
  project_uid: PropTypes.string,
};

export default DarksearchTabs;
