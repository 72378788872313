import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {useQueryWithAuthentication} from "../index";
import PropTypes from "prop-types";
import {addAuthToAxiosOpts} from "../../config/queryopts";


export const BotnetExposureFetcher = (query, accessToken, query_type = null) => {
  const urlParams = new URLSearchParams();
  if (query_type) {
    urlParams.set('query_type', query_type);
  }
  urlParams.set('query', query);

  return axios.get(
    `${apiUrlInternal}/botnet_exposure?${urlParams.toString()}`,
    addAuthToAxiosOpts(accessToken, {}, 60000)
  ).then(res => res.data);
}

export const useBotnetExposureQuery = (query = null, query_type = null, fetcher = BotnetExposureFetcher) => {
  return useQueryWithAuthentication(
    ['botnet-exposure', query], (accessToken) => fetcher(query, accessToken, query_type),
    {staleTime: Infinity, enabled: !!query, retry: false, refetchOnWindowFocus: false}
  )
}

useBotnetExposureQuery.propTypes = {
  query: PropTypes.string.isRequired,
}
