import {Col, Row} from "reactstrap";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import ExpandVerticalIcon from "mdi-react/ExpandVerticalIcon";
import DeleteIconModal from "../../modal/DeleteIconModal";
import React, {useEffect} from "react";
import {useDeleteContextTask} from "../../../../mutations/DeleteContext";
import {useHistory} from "react-router-dom";
import toast from "react-hot-toast";
import paths from "../../../../config/paths";
import PropTypes from "prop-types";
import {useQueryClient} from "react-query";


const ContentEditsBar = ({content, type}) => {
  const mutation = useDeleteContextTask();
  const h = useHistory();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast.loading('Deleting content');
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          queryClient.invalidateQueries('event-contents', {refetchActive: true, refetchInactive: true});
          toast.success('Content deleted successfully');
          h.push(paths.contentsPath);
        } else if (mutation.isError) {
          toast.error('Error during the deletion of the content');
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])


  return (
    <Row>
      <Col md={12} className={'d-flex justify-content-end mt-2'}>
        <span>
          <PencilOutlineIcon size={24} style={{fill: '#ffffff'}} onClick={(e) => {
            e.stopPropagation();
            h.push(`/${type}/edit/${content.uid}`);
          }} className={'border-hover-white mr-3'}/>
          <DeleteIconModal
            message={`Do you really want to delete the ${type}?`}
            onDelete={() => mutation.mutate(content.uid)}
          />
        </span>
      </Col>
    </Row>
  )
}


ContentEditsBar.propTypes = {
  content: PropTypes.shape().isRequired,
  type:  PropTypes.string.isRequired,
  toggle:PropTypes.func,
  showExpand:PropTypes.bool,
}

export default ContentEditsBar;
