import React from "react"
import env_const from "../../config/env_const";
import OnlineInvestigateDetail from "./Online";
import OnPremInvestigateDetail from "./OnPrem";
import {PLATFORM_FEATURE_INVESTIGATE} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";


const _ = require('lodash');


const InvestigateDetail = () =>
  <Page
    feature={PLATFORM_FEATURE_INVESTIGATE}
  >
    {env_const.is_on_prem ? <OnPremInvestigateDetail /> : <OnlineInvestigateDetail />}
  </Page>

export default InvestigateDetail;
