import React from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useHistory, useParams} from "react-router-dom";
import {usePostDetailsQuery} from "../../queries/PostDetails";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import TargetVictimCard from "../../shared/components/card/TargetVictimCard";
import SectorCard from "../../shared/components/card/withIcon/SectorCard";
import ContentCardHeader from "../../shared/components/card/accessories/ContentCardHeader";
import ContentCardFooter from "../../shared/components/card/accessories/ContentCardFooter";
import {canUserEditContent} from "../../shared/helpers/permissions";
import {getPostTitle} from "../../shared/helpers/contents";
import RulesCard from "../../shared/components/card/RulesCard";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import ContentEditsBar from "../../shared/components/card/accessories/ContentEditsBar";
import IndicatorsTabWithTablePaginationCard from "../../shared/components/card/IndicatorsTabWithTablePaginationCard";
import {useSearchEventIndicatorDictPagination} from "../../queries/SearchEventIndicatorDictPagination";
import {useEventContentsIndicatorsCountQuery} from "../../queries/EventContentsIndicatorsCount";
import paths from "../../config/paths";
import ReferencesRow from "../../shared/components/card/accessories/ReferencesRow";
import ActorsCard from "../../shared/components/card/ActorsCard";
import {getMalwareFamilyTags} from "../../shared/helpers";
import MalwareFamiliesTagsCard from "../../shared/components/card/withIcon/MalwareFamiliesTagsCard";
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const PostDetail = () => {
  const { postUid: uid } = useParams();
  const { data, isIdle, isLoading, isError, error } = usePostDetailsQuery(uid);
  const { data: dataCurrentUser, isIdle: isIdleCurrentUser, isLoading: isLoadingCurrentUser } = useCurrentUserQuery();
  const history = useHistory();
  const updateFilters = (data) => {
    const urlParams = new URLSearchParams();
    urlParams.set(data.type, data.name);
    history.push(`${paths.contentsPath}?${urlParams.toString()}`);
  }

  const onCountryClick = (data) => {
    history.push(`${paths.contentsPath}?targeted_countries=${data.target.dataItem.dataContext.id}`)
  }

  if (isError) {
    return <ErrorHandler error={error} />
  }

  if (isLoading || isIdle || isIdleCurrentUser || isLoadingCurrentUser) {
    return <LoadingSpinnerPage />
  }

  const post = data.data;
  const tlp = post.tlp.tlp;
  const isEditable = canUserEditContent(dataCurrentUser?.data, post);

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
    >
      <Container>
        <Row>
          <Col lg={7} md={12}>
            <Card className={'h-auto'}>
              <CardBody>
                <ContentCardHeader
                  created_dt={post.created_dt}
                  confidence={post.confidence}
                  tlp={tlp}
                  updateFilters={(type,val) => updateFilters({type :type,name:val})}
                  title={getPostTitle(post)}
                />
                <pre className={'mb-3 pre--text'}>{post.text}</pre>
                {post.references?.length > 0 && <ReferencesRow references={post.references}/>}
                <ContentCardFooter tags={post.tags} tlp={tlp} />
                {isEditable && <div style={{position: 'absolute', bottom: '40px', right: '10px'}}><ContentEditsBar content={post} type={'post'} /></div>}
              </CardBody>
            </Card>
          </Col>
          <Col lg={5} md={12} className={'d-flex flex-column'} style={{paddingBottom: '30px'}}>
            <ActorsCard actors={post.actors} />
            <SectorCard updateFilters={(val)=> { updateFilters({type : 'targeted_sectors',name:val}) } } sectors={post.sectors} />
            {getMalwareFamilyTags(post.tags).length > 0 && <MalwareFamiliesTagsCard malwareFamilies={getMalwareFamilyTags(post.tags)} />}
          </Col>
        </Row>

        {/* Indicators */}
        <Row>
          <Col md={12}>
            <IndicatorsTabWithTablePaginationCard
              dataHookCount={useEventContentsIndicatorsCountQuery}
              uid={uid}
              filters={{uid: uid}}
              dataHook={useSearchEventIndicatorDictPagination}
              withExportButton
              exportFilters={{content: uid}}
              withExportdRules
              customRuleText={`${window.location.origin}${paths.postPath}/${post.uid}`}
            />
          </Col>
        </Row>

        {/* COUNTRIES */}
        <Row>
          <Col md={12}>
            <TargetVictimCard
              onCountryClick={(data) => updateFilters({type : 'targeted_countries',name:data.target.dataItem.dataContext.id})}
              updateFilters={(type,val) => updateFilters({type : type,name:val})} targetedCountries={post.targeted_countries} />
          </Col>
        </Row>

        {/* Rules */}
        <Row>
          <Col md={12}>
            <RulesCard   rules={post.rules} />
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

export default PostDetail;
