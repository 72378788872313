import React from "react";
import paths from '../../../config/paths';
import {useHistory} from 'react-router-dom';
import ThemedFabMenu from '../../../shared/components/ThemedFabMenu';
import AlphaAIcon from "mdi-react/AlphaAIcon";

const FabActivitiesMenu = () => {
    
    const history = useHistory();
   
    const actions = [
        {
          text: "Add Activity",
          icon: <AlphaAIcon />,
          onClick: () => history.push(paths.addActivitiesPath)
        }
      ];
    return (
        <ThemedFabMenu actions={actions} />
    );
}

export default FabActivitiesMenu;