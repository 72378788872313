import React from "react";
import {Route} from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import env_const from "../../../config/env_const";
import PropTypes from "prop-types";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {APP_AUTH0_PROVIDER, APP_LOCAL_PROVIDER} from "../../helpers/validators";


const PrivateRoute = ({
  component,
  ...rest
}) => {
  if (env_const.react_app_auth_provider === APP_LOCAL_PROVIDER) {
    return (
      <Route component={component} {...rest} />
    )
  } else if (env_const.react_app_auth_provider === APP_AUTH0_PROVIDER) {
    return (
      <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <LoadingSpinner/>
          }
        )}
        {...rest}
      />
    )
  }

};

PrivateRoute.propTypes = {
  component: PropTypes.element.isRequired
}

export default PrivateRoute;
