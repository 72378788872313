import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import PropTypes from "prop-types";
import {useCookieAccessToken} from "../../../queries";
import {downloadSiemRules} from "../../helpers/siem";


const DropdownMenuExportSIEMRules = ({
  filters,
  totalIoc = 10000,
}) => {
  const { data: accessToken } = useCookieAccessToken()

  return (
    <UncontrolledDropdown className={'float-right'}>
      <DropdownToggle className="icon icon--right outline mr-2" outline color={'success'} style={{height: "48px"}}>
        <p>Export SIEM Rules <ChevronDownIcon /></p>
      </DropdownToggle>
      <DropdownMenu className="dropdown__menu__custom_small">
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'arcsight')}>ArcSight</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'aws')}>AWS OpenSearch</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'carbonblack')}>CarbonBlack</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'chronicle')}>Chronicle</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'crowdstrike')}>CrowdStrike</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'elastic')}>Elastic Stack</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'fireeye')}>FireEye Helix</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'graylog')}>GrayLog</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'humio')}>Humio</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'logpoint')}>LogPoint</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'microsoft_bitdefender')}>Microsoft Defender</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'microsoft_sentinel')}>Microsoft Sentinel</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'qradar')}>QRadar</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'qualys')}>Qualys</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'rsa')}>RSA Netwitness</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'securonix')}>Securonix</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'sentinelone')}>SentinelOne</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'splunk')}>Splunk</DropdownItem>
        <DropdownItem onClick={() => downloadSiemRules(accessToken, {...filters, ...{limit: totalIoc}}, 'sumo')}>Sumo</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

DropdownMenuExportSIEMRules.propType = {
  filters: PropTypes.array.isRequired,
  totalIoc: PropTypes.number.isRequired
}

export default DropdownMenuExportSIEMRules;
