import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import IndicatorRiskScoreGauge from '../../IndicatorRiskScoreGauge';
import RelatedActorsCard from '../../RelatedActorsCard';
import PropTypes from 'prop-types';
import ContentTimeLineCard from '../../ContentTimeLineCard';
import PassiveDomainTableCard from '../DomainDetails/components/PassiveDomainTableCard';
import WhoisDetailsCard from '../../Whois/WhoisDetailsCard';
import CommunicatingFilesCard from '../../CommunicatingFilesCard';
import IndicatorDetailFooter from '../../IndicatorDetailFooter';
import ThreatStatsPieChart from '../../ThreatStatsPieChart';
import CounterAttackTableCard from '../../CounterAttackTableCard';
import FirstLastSeenRow from '../../FirstLastSeenRow';

const IPv4Details = ({ indicator_details }) => {
  const passive_dns = indicator_details.passive_dns;

  return (
    <Fragment>
      <Row>
        <Col lg={5} md={12}>
          <Row>
            <Col>
              <IndicatorRiskScoreGauge score={!indicator_details.whitelisted ? indicator_details.score : 0}
                                       tags={indicator_details.tags} whitelisted={indicator_details.whitelisted}/>
              <FirstLastSeenRow last_seen={indicator_details.last_seen} first_seen={indicator_details.first_seen}/>
              <RelatedActorsCard actors={indicator_details.actors} isKnown={indicator_details.is_known}/>
              {indicator_details.stats && <ThreatStatsPieChart stats={indicator_details.stats}/>}
            </Col>
          </Row>
        </Col>

        <Col lg={7} md={12}>
          <Row>
            <Col>
              <WhoisDetailsCard
                indicator={indicator_details.indicator}
                whois={indicator_details.whois}
                indicatorType={indicator_details.type}
              />
              {indicator_details.related_contexts.length > 0 &&
                <ContentTimeLineCard contexts={indicator_details.related_contexts}/>}
              {indicator_details.attacker_activities.length > 0 &&
                <CounterAttackTableCard attacker_activities={indicator_details.attacker_activities}/>}
            </Col>
          </Row>
        </Col>
      </Row>

      {
        passive_dns && passive_dns.resolutions && passive_dns.resolutions.length > 0 &&
        <Row>
          <Col>
            <PassiveDomainTableCard
              passive_dns={passive_dns}
              indicator={indicator_details.indicator}
            />
          </Col>
        </Row>
      }

      <Row>
        <Col>
          <CommunicatingFilesCard indicator={indicator_details.indicator}/>
        </Col>
      </Row>

      <IndicatorDetailFooter indicator_details={indicator_details}/>
    </Fragment>
  );
};

IPv4Details.propTypes = {
  indicator_details: PropTypes.object.isRequired,
};

export default IPv4Details;
