import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import React from "react";


const ResourceMonitorEmptyResultCard = ({
  resource
}) =>
  <Card>
    <CardBody>
      <div className="card__title resource_monitor-card-empty-result-title">
        <h4 className="bold-text d-inline">No vulnerabilities found on <b>{resource}</b></h4>
      </div>
    </CardBody>
  </Card>


ResourceMonitorEmptyResultCard.propTpes = {
  resource: PropTypes.string.isRequired
}


export default ResourceMonitorEmptyResultCard;
