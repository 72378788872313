import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const OrganizationsFetcher = (uid = null, accessToken) => {
  const uri = uid ? `/${uid}` : '';
  return axios.get(`${apiUrlInternal}/organizations${uri}`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}


export const useOrganizationsQuery = (uid = null, enabled = true, fetcher = OrganizationsFetcher) => {
  return useQueryWithAuthentication(
    ['organizations', uid],
    (accessToken) => fetcher(uid, accessToken),
    {enabled: enabled}
  )
}
