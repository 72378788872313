import React, {Fragment, useCallback} from 'react';
import {Col, Row,  Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import {useSandboxTaskDetailsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import TableWithData from "../../../shared/components/table/TableWithData";
import {getCopiableColumn, getCopiableVtPrivateIpColumn} from "../../../shared/helpers/tables";


const _ = require('lodash')


const SandboxTaskDetectionSection = ({taskId}) => {
    const { data, isIdle, isLoading, isError,error } = useSandboxTaskDetailsQuery(taskId);

    const getRowDataId = useCallback((row, i) => (row.original.dataId), [])

    if (isLoading || isIdle) {
        return <LoadingSpinner />
    }

    if (isError || !data.data) {
        return <ErrorHandler error={error} />
    }

    const yaraSignaturesTableColumns = [
        { Header: 'Name', accessor: 'name', className: "truncated-columns" },
        { Header: 'Description', accessor: 'description', className: "truncated-columns" }
    ];

    const suricataAlertsTableColumns = [
        { Header: 'Signature', accessor:'signature', className: "truncated-columns" },
        { Header: 'Category ', accessor:'category' },
        getCopiableVtPrivateIpColumn('actions', 'dstip'),
        { Header: 'Destination IP', accessor:'dstip' },
        { Header: 'Destination Port', accessor:'dstport' },
        { Header: 'Protocol', accessor:'protocol' },
        { Header: 'Severity', accessor: (row) => row['severity'].toString() }
    ];

    const suricataJa3SignaturesTableColumns = [
        getCopiableColumn('HASH', 'ja3.hash', true),
        { Header: 'String ', accessor: (row) => row['ja3']['string'], className: "truncated-columns" },
        { Header: 'Subject', accessor:'subject'} ,
        { Header: 'SNI', accessor:'sni' }
    ];

    let mergedYarasAcrossAllPayloads = []
    data.data.report.payloads.forEach((y) =>
      mergedYarasAcrossAllPayloads = _.union(
          mergedYarasAcrossAllPayloads,
          y.yara.map(x => ({name:x.name,description:x.meta.description})),
          y.cape_yara.map(x => ({name:x.name,description:x.meta.description}))
        )
    )

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="react-table__wrapper">
                                <div className="card__title">
                                    <h5 className="bold-text">YARA signatures</h5>
                                </div>
                            </div>
                            {
                                mergedYarasAcrossAllPayloads.length > 0 &&
                                    <TableWithData
                                        data={mergedYarasAcrossAllPayloads}
                                        tableColumns={yaraSignaturesTableColumns}
                                        getRowDataId={getRowDataId}
                                    />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="react-table__wrapper">
                                <div className="card__title">
                                    <h5 className="bold-text">Alerts</h5>
                                </div>
                            </div>
                            {
                                data.data.report.suricata.alerts && data.data.report.suricata.alerts.length > 0 &&
                                    <TableWithData
                                        data={_.orderBy(data.data.report.suricata.alerts,['severity'],['desc'])}
                                        tableColumns={suricataAlertsTableColumns}
                                        getRowDataId={getRowDataId}
                                    />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="react-table__wrapper">
                                <div className="card__title">
                                    <h5 className="bold-text">JA3 signatures</h5>
                                </div>
                            </div>
                            {
                                data.data.report.suricata.tls && data.data.report.suricata.tls.length > 0 &&
                                    <TableWithData
                                        data={data.data.report.suricata.tls}
                                        tableColumns={suricataJa3SignaturesTableColumns}
                                        getRowDataId={getRowDataId}
                                    />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

SandboxTaskDetectionSection.propTypes = {
    taskId: PropTypes.string.isRequired,
}

export default SandboxTaskDetectionSection;
