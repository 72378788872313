import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import _ from "lodash";
import {useOfflineUpdateStatus} from "../../../queries/OfflineUpdates";
import {formatDateUTCtoMonthDayTime, getDaysArray} from "../../../shared/helpers/date";
import {apiUrlInternal, apiUrlRetrohunt} from "../../../config/api";
import {download_file} from "../../../shared/helpers/download_file";
import {useAuth0AccessToken} from "../../../queries";
import moment from "moment";


const DownloadUpdatesSection = () => {
  const {data, isIdle, isLoading, isError} = useOfflineUpdateStatus();
  const {data: accessToken} = useAuth0AccessToken();
  const [updatesStatus, setUpdatesStatus] = useState({});

  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setUpdatesStatus(data.data)
    }
  }, [isIdle, isLoading, isError, data]);

  if (isIdle || isLoading) {
    return <LoadingSpinner/>
  }

  const days = getDaysArray(moment().subtract(3, 'days'), moment()).slice(0, -1);

  return (
    <Fragment>
      <Card className={'h-auto'}>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Download Updates</h4>
            <p className={'subhead'}>In this section you can download the latest updates for your on-prem platform</p>
          </div>
          <Row>
            <Col>
              {
                _.isNull(updatesStatus.timestamp) ?
                  <h4>You have not start a download yet.</h4> :
                  updatesStatus.ready ?
                    <p>
                      The download related to the updates of {formatDateUTCtoMonthDayTime(updatesStatus.timestamp)} is
                      ready. <br/>
                      <a href={''} className={'a-colored'} onClick={
                        (e) => {
                          e.preventDefault();
                          download_file(
                            `${apiUrlInternal}/offline_update/download`,
                            `update_${moment(updatesStatus.timestamp).format('X')}.${updatesStatus.timestamp.slice(20, 26)}.zip`,
                            accessToken,
                            60000
                          );
                        }
                      }> Click here to download the zip file.</a>
                    </p> :
                    <p>The download related to the updates of {formatDateUTCtoMonthDayTime(updatesStatus.timestamp)} is
                      not ready. </p>
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className={'h-auto'}>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Download Retrohunt Samples</h4>
            <p className={'subhead'}>In this section you can download the latest samples to feed the retrohunt</p>
          </div>

          <Row>
            <Col>
              {
                days.map((d) => {
                  const date = moment(d).format("YYYY-MM-DD");

                  return <a
                    href={`${apiUrlRetrohunt}/offline_update/download_retrohunt?date=${date}&auth=${accessToken}`}
                    download>
                    <Button
                      outline
                      className={'w-100'}
                      color={'success'}
                    >Download archive {date}</Button>
                  </a>
                })
              }
            </Col>
          </Row>


        </CardBody>
      </Card>
    </Fragment>
  )
}


DownloadUpdatesSection.propTypes = {
}


export default DownloadUpdatesSection;
