import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


const OfflineUpdateFetcher = (accessToken, endpoint, timeout = 20000) => {
    return axios.get(`${apiUrlInternal}/offline_update${endpoint}`, addAuthToAxiosOpts(accessToken, {}, timeout))
      .then(res => res.data);
}
  
const useOfflineUpdateQuery = (
  endpoint,
  options,
  timeout = 20000,
  fetcher = OfflineUpdateFetcher
) => {
    return useQueryWithAuthentication(['offile-update', endpoint],(accessToken) => fetcher(accessToken, endpoint), options, timeout)
}

export const useOfflineUpdateStatus = () => {
    return useOfflineUpdateQuery('/status');
}

export const useOfflineUpdatesIsUpdatedQuery = () => {
    return useOfflineUpdateQuery('/is_updated', {refetchInterval: 60000});
}
