import React, {Fragment, useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {Col, Row} from 'reactstrap';
import RegisteredCheckBoxField from './form/RegisteredCheckBoxField';
import RegistredRadioField from './form/RegisteredRadioField';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import {isAllFeaturesEnabledForRole, userRoleModuleEnabled} from "../helpers/permissions";
import {PLATFORM_PERMISSION_EDITOR, PLATFORM_PERMISSION_VIEWER} from "../helpers/features";

const _ = require('lodash');


const Permissions = ({
  groups,
  onPermissionChange = () => {},
  register,
  watch,
  setValue,
  role = null
}) => {
  const [selectedSection, setSelectedSection] = useState();
  role = watch('role')?.value || role;

  useEffect(() => {
    let items = [...groups];
    items = items.map((g, index) => {
      g.z_order = index;
      g.selected = !_.isNull(userRoleModuleEnabled(role, g.name));
      g.features = g.features && g.features.map((f, innerIndex) => {
          f.z_order = innerIndex;
          f.selected = !_.isNull(userRoleModuleEnabled(role, g.name, f.name));
          if (!_.isNull(userRoleModuleEnabled(role, g.name))) {
            f.permission = userRoleModuleEnabled(role, g.name, f.name)
          }
          return f;
        })
      return g;
    });

    updateValues(items);
    onPermissionChange(items);
  }, [role])

  useEffect(() => {
    if (groups.length === 0) {
      setValue('all', false);
    } else {
      setValue('all', isAllFeaturesEnabledForRole(groups, role));
    }
  }, [groups, role, isAllFeaturesEnabledForRole(groups, role)])


  const getItemsFromGroups = (gs, data) => {
    let items = [...gs];
    items = items.map((g, index) => {
      g.z_order = index;
      if (g.name === data.name) {
        g.selected = !g.selected;
        if (g.features) {
          g.features = g.features && g.features.map((f, innerIndex) => {
            f.selected = g.selected && userRoleModuleEnabled(role, g.name, f.name);
            f.z_order = innerIndex;
            return f;
          })
        }
      } else if (g.features && g.features.length > 0) {
        g.features = g.features && g.features.map((f, innerIndex) => {
          f.z_order = innerIndex;
          if (f.name === data.name) {
            f.selected = !f.selected && userRoleModuleEnabled(role, g.name, f.name);
          }
          return f;
        })
      }
      return g;
    });

    return items;
  }

  const onUpdate = (data, value) => {
    if (value && value.target) {
      const items = getItemsFromGroups(groups, data);
      updateValues(items);
      onPermissionChange(items);
    }
  }

  const updateAllFiled = (groups) => {
    let status = groups.length > 0;

    groups.forEach(g => {
      if (!g.selected && !_.isNull(role) && !_.isNull(userRoleModuleEnabled(role, g.name))) {
        status = false;
      }
      g.features && g.features.forEach((f) => {
        if (!f.selected && !_.isNull(role) && !_.isNull(userRoleModuleEnabled(role, g.name, f.name))) {
          status = false;
        }
      })
    })
    setValue('all', status);
  }

  const updateValues = (groups) => {
    groups.forEach(g => {
      setValue(g.name, g.selected);
      g.features && g.features.forEach((f) => {
        setValue(f.name, f.selected);
      })
    });
    updateAllFiled(groups);
  }

  const onSelectAll = () => {
    const selectAllStatus = watch('all');

    let items = [...groups];
    items = items.map((g, index) => {
      g.selected = selectAllStatus && (_.isNull(role) || !_.isNull(userRoleModuleEnabled(role, g.name)));
      g.z_order = index;
      if (g.features) {
        g.features = g.features && g.features.map((f, innerIndex) => {
          f.selected = g.selected && !_.isNull(role) && !_.isNull(userRoleModuleEnabled(role, g.name, f.name));
          f.z_order = innerIndex;
          if (!_.isNull(role)) {
            if (userRoleModuleEnabled(role, g.name, f.name) === PLATFORM_PERMISSION_VIEWER) {
              f.permission = PLATFORM_PERMISSION_VIEWER;
            } else if (userRoleModuleEnabled(role, g.name, f.name) === PLATFORM_PERMISSION_EDITOR) {
              f.permission = PLATFORM_PERMISSION_EDITOR;
            }
          }
          return f;
        })
      }
      return g;
    });

    updateValues(items);
    onPermissionChange(items);

    setValue('all', selectAllStatus);
  }

  const onPermissionUpdate = (event, name) => {
    if (event && event.target) {
      let items = [...groups];
      items = items.map((g, index) => {
        g.z_order = index;
        if (g.features) {
          g.features = g.features && g.features.map((f, innerIndex) => {
            f.z_order = innerIndex;
            if (f.name === name.split('__')[0]) {
              f.permission = event.target.value;
            }
            return f;
          })
        }
        return g;
      });
      updateValues(items);
      onPermissionChange(items);
    }
  }

  return (
    <>
      <div className='w-100 pl-3 d-flex flex-row'>
        <RegisteredCheckBoxField
          name={'all'}
          value={watch('all')}
          onChange={onSelectAll}
          defaultChecked={isAllFeaturesEnabledForRole(groups, role)}
          label={'All'}
          register={register}
        />
      </div>
      {
        groups.map((group, index) =>
          <Row className='w-100 modules_list' key={index}>
            <Col lg={4} className='d-flex flex-row'>
              <span className='actions' onClick={() => {
                setSelectedSection(prev => prev !== group.value ? group.value : '')
              }}>
              {
                selectedSection !== group.value ? <ChevronDownIcon/> : <ChevronUpIcon/>
              }
              </span>
              <RegisteredCheckBoxField
                name={group.name}
                value={group.selected}
                onChange={(value) => onUpdate(group, value)}
                defaultChecked={false}
                label={group.value}
                register={register}
                disabled={!_.isNull(role) && _.isNull(userRoleModuleEnabled(role, group.name))}
              />
            </Col>
            {selectedSection === group.value && <Col lg={12} className="modules_list_inner">
              <Row className={'w-100'}>
                {
                  group?.features && group.features.map((feature, index) =>
                    <Fragment key={index}>
                      <Col lg={4} className='d-flex flex-row' key={index}>
                        <RegisteredCheckBoxField
                          name={feature.name}
                          value={feature.selected}
                          onChange={(value) => onUpdate(feature, value)}
                          defaultChecked={false}
                          label={feature.value}
                          register={register}
                          disabled={!group.selected || (!_.isNull(role) && _.isNull(userRoleModuleEnabled(role, group.name, feature.name)))}
                        />
                      </Col>
                      <Col lg={8} className='d-flex flex-row justify-content-between'>
                        <RegistredRadioField
                          name={feature.name + '__permission'}
                          value={feature.selected && userRoleModuleEnabled(role, group.name, feature.name) === PLATFORM_PERMISSION_EDITOR ? feature.permission : ''}
                          onChange={(value) => onPermissionUpdate(value, feature.name + '__permission')}
                          defaultChecked={false}
                          label={'Editor'}
                          radioValue={PLATFORM_PERMISSION_EDITOR}
                          register={register}
                          disabled={
                            !feature.selected ||
                            !group.selected ||
                            (!_.isNull(role) && userRoleModuleEnabled(role, group.name, feature.name) !== PLATFORM_PERMISSION_EDITOR)
                        }
                        />
                        <RegistredRadioField
                          name={feature.name + '__permission'}
                          value={feature.selected ? feature.permission : ''}
                          onChange={(value) => onPermissionUpdate(value, feature.name + '__permission')}
                          defaultChecked={feature.selected}
                          label={'Viewer'}
                          radioValue={PLATFORM_PERMISSION_VIEWER}
                          register={register}
                          disabled={!feature.selected || !group.selected}
                        />

                      </Col>
                    </Fragment>
                  )
                }
              </Row>
            </Col>
            }
          </Row>
        )
      }
    </>
  )
}

Permissions.propTypes = {
  groups: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  onPermissionChange: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
}


export default Permissions;
