import {logoBucketUrl} from "../../config/paths";
import logo from "../img/logo/cluster25.svg"


export const getIcon = (user) => {
  if (user && user.organization && user.organization.logo && user.organization.has_custom_logo) {
    return `${logoBucketUrl}/img/${user.organization.logo}`;
  }

  return logo;
}

export const ADMIN_ROLE = 'admin';
export const INTERNAL_ROLE = 'internal';
export const DEMO_ROLE = 'demo';
export const EDITOR_ROLE = 'editor';
export const SALES_ROLE = 'sales';
export const CUSTOMER_ROLE = 'customer';
export const OPERATOR_ROLE = 'operator';
