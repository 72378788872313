import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import _ from "lodash";


export const eventContentsIndicatorsCountFetcher = (accessToken, contentUid) => {
  return axios.get(`${apiUrlInternal}/contents/${contentUid}/indicators/count`, addAuthToAxiosOpts(accessToken, {}))
    .then(res => res.data);
}

export const useEventContentsIndicatorsCountQuery = (contentUid, fetcher = eventContentsIndicatorsCountFetcher) => {
  return useQueryWithAuthentication(['contents-indicator-count', contentUid],
    (accessToken) => fetcher(accessToken, contentUid),
    {enabled: contentUid !== null && !_.isUndefined(contentUid)}
  )
}
