import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index";
import {useQueryClient} from "react-query";

const ReportPurchaseFetcher = (accessToken, params) => {
  return axios.post(
    `${apiUrlInternal}/report_purchase`,
    params,
    addAuthToAxiosOpts(accessToken, {})
  ).then(res => res.data);
}

export const useReportPurchaseMutation = (fetcher = ReportPurchaseFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['report-purchase'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(['analysis-details', params['content_id']]);
        queryClient.invalidateQueries(['flashalert-details', params['content_id']]);
      }
    }
  )
}