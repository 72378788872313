import React, {Fragment} from "react";
import PropTypes from "prop-types";
import TableWithData from "./TableWithData";
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import {useHistory} from "react-router-dom";
import paths from "../../../config/paths";
import {ColorTagInlineBadgeList} from "../badge/BadgeLists";
import VirusTotalIndicatorLink from "../icons/VirusTotalIndicatorLink";
import CopyIconToClipboard from "../icons/CopyIconToClipboard";
import {getBotnetTags, getCampaignTags, getCommonTags, getMalwareFamilyTags} from "../../helpers";


export const INDICATOR_TABLE_COLUMNS = [
  { Header: 'Type', accessor: 'type', minWidth: 100 },
  { Header: 'Value', accessor: 'value' },
  { Header: 'Created date', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
  { Header: 'Tags', accessor: 'tags', Cell: ({value}) => <ColorTagInlineBadgeList items={getCommonTags(value)} />},
  { id:'malware_family', Header: 'Malware Family', accessor: 'tags', Cell: ({value}) => <ColorTagInlineBadgeList items={getMalwareFamilyTags(value)} /> },
  { id:'botnet', Header: 'Botnet', accessor: 'tags', Cell: ({value}) => <ColorTagInlineBadgeList items={getBotnetTags(value)} /> },
  { id:'campaign', Header: 'Campaign', accessor: 'tags', Cell: ({value}) => <ColorTagInlineBadgeList items={getCampaignTags(value)} /> },
  { id: 'VT', Header: '', accessor: 'value', Cell: ({value}) =>
      <Fragment>
        <VirusTotalIndicatorLink value={value} />
        <CopyIconToClipboard value={value} />
      </Fragment>

  },
];

export const INDICATOR_TABLE_CONFIG = {
  withPagination: true,
  withHover: true,
  withSearchEngine: true,
}

const IndicatorsTable = ({indicators}) => {
  const history = useHistory();

  return (
    <TableWithData
      data={indicators}
      tableColumns={INDICATOR_TABLE_COLUMNS}
      tableConfig={INDICATOR_TABLE_CONFIG}
      onRowClick={(dataId, row) => {history.push(`${paths.investigatePath}?indicator=${row.original.value}`)}}
    />
  );
}

IndicatorsTable.propsType = {
  indicators: PropTypes.array.isRequired,
}


export default IndicatorsTable;
