import React, {Fragment, useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Button, ButtonToolbar} from "reactstrap";
import ThemeModal from "../../../shared/components/ThemeModal";


const SubmitButtonModal = ({
  onClick = () => {},
  onSubmit,
  title,
  messages,
  buttonText = 'Add to Engine',
  buttonActionText = 'Confirm',
  disabled = false,
  unblockDate,
  postScriptum
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal]);
  return (
    <Fragment>
      <Button
        className={'float-right'}
        disabled={disabled}
        onClick={() => {
          onClick()
          toggleModal()
        }}
        color={'success'}
        outline
      >
        {buttonText}
      </Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog modal-dialog--dark'}
      >
        <div className="text-modal modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => {
            toggleModal();
          }}/>
        </div>
        <h4 className="text-modal modal__title">{title}</h4>
        <div
          className="text-modal modal__body">
          {messages?.length ? messages.map(message => message.message) : "No modifications detected"}
        </div>
        {
          postScriptum && unblockDate && messages?.length > 0 &&
          <div style={{padding: "25px"}}>{postScriptum}<br/><b className="text-danger">{unblockDate}</b></div>
        }
        <ButtonToolbar className="modal__footer">
          {
            messages?.length > 0 &&
            <Button outline className="rounded" color='success' onClick={() => {
                onSubmit();
                toggleModal()
            }}
            >{buttonActionText}
            </Button>
          }
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
        </ButtonToolbar>
      </ThemeModal>
    </Fragment>
  )
}


SubmitButtonModal.propTypes = {
  onClick: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  messages: PropTypes.element,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  postScriptum: PropTypes.string,
  unblockDate: PropTypes.string
}

export default SubmitButtonModal;

