import {Col, Row} from "reactstrap";
import MenuEntry from "../../../../shared/components/MenuEntry";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import PropTypes from "prop-types";
import React from "react";

const RansomwareTrackerNotification = ({content}) => {
  return (
    <Row>
      <Col md={4}>
        <div className="custom_card__container">
          <MenuEntry title={'DATE'} value={content.date ? formatDateUTCtoYearMonthDayTime(content.date) : 'N/A'} />
          <MenuEntry title={'ACTOR'} value={content.actor_name} />
          <MenuEntry title={'VICTIM'} value={content.victim}/>
          <MenuEntry title={'VICTIM COUNTRY'} value={content.victim_country}/>
          <MenuEntry title={'VICTIM URL'} value={content.website}/>
        </div>
      </Col>
      <Col md={7}>
        <div className="custom_card__container">
          {
              <MenuEntry
                title={'DESCRIPTION'}
                value={content?.description ? content.description : "N/A"}
                valueStyle={{wordBreak: 'break-word', whiteSpace: 'normal'}}
              />

          }
        </div>
      </Col>
    </Row>
  )
}


RansomwareTrackerNotification.propTypes = {
  content: PropTypes.object.isRequired
}


export default RansomwareTrackerNotification;
