import PropTypes from "prop-types";
import SmallGaugeChart from "../../../shared/components/charts/SmallGaugeChart";
import React, {useMemo} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {getColorByScore, getProportionalScore, getRiskLabelByScore} from "../../../shared/helpers/risk_score";
import {ColorTagInlineBadgeList, ColorTagInlineBadgeWithLinkList} from "../../../shared/components/badge/BadgeLists";
import {getBotnetTags, getCampaignTags, getCommonTags, getMalwareFamilyTags} from "../../../shared/helpers";
import paths from "../../../config/paths";
import { useHistory } from "react-router-dom";
import ColorTagInlineBadge from "../../../shared/components/badge/ColorTagInlineBadge";


const IndicatorRiskScoreGauge = ({score, tags, whitelisted = false}) => {
  const commonTags = useMemo(() => getCommonTags(tags), [tags]);
  const malwareFamilyTags = useMemo(() => getMalwareFamilyTags(tags), [tags]);
  const botnetTags = useMemo(() => getBotnetTags(tags), [tags]);
  const campaignTags = useMemo(() => getCampaignTags(tags), [tags]);
  const history = useHistory();
  const updateFilters = (data) =>{
    const urlParams = new URLSearchParams();
    urlParams.set(data.type, data.name);
    history.push(`${paths.indicatorPath}?${urlParams.toString()}`);
  }
  return (
    <Card className={'h-auto'}>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">
            Risk score
            {whitelisted && <span className={'float-right'}><ColorTagInlineBadge color={'secondary'} tag={'WHITELISTED'}/></span>}
          </h4>
          <p className="subhead">The risk score is based on our intelligence</p>

        </div>

        {
          score === 0 ?
            <SmallGaugeChart value={0} color={'#ffffff'} label={'CLEAN'} /> :
            <SmallGaugeChart
              value={getProportionalScore(score)}
              color={getColorByScore(score)}
              label={getRiskLabelByScore(score)}
            />
        }

        <Row>
          {
            commonTags.length > 0 &&
            <Col md={malwareFamilyTags.length > 0 ? 6 : 12}>
              <h5 className={'my-2'}>TAGS</h5>
              <ColorTagInlineBadgeList
                items={commonTags}
                size={'sm'}
                type="tags"
                onTagClick={(val)=>updateFilters({type:'tags',name:val})}
              />
            </Col>
          }
          {
            malwareFamilyTags.length > 0 &&
            <Col md={commonTags.length > 0 ? 6 : 12} className={'text-right'}>
              <h5 className={'my-2'}>MALWARE FAMILIES</h5>
              <ColorTagInlineBadgeWithLinkList
                items={malwareFamilyTags}
                size={'sm'}
                type="tags"
                url={paths.malwareFamilyBasePath}
              />
            </Col>
          }
        </Row>

        <Row>
          {
            botnetTags.length > 0 &&
            <Col md={campaignTags.length > 0 ? 6 : 12}>
              <h5 className={'my-2'}>BOTNET</h5>
              <ColorTagInlineBadgeList
                items={botnetTags}
                size={'sm'}
                type="tags"
                onTagClick={(val)=>updateFilters({type:'tags',name:val})}
              />
            </Col>
          }
          {
            campaignTags.length > 0 &&
            <Col md={botnetTags.length > 0 ? 6 : 12} className={'text-right'}>
              <h5 className={'my-2'}>CAMPAIGN</h5>
              <ColorTagInlineBadgeList
                items={campaignTags}
                size={'sm'}
                type="tags"
                onTagClick={(val)=>updateFilters({type:'tags',name:val})}
              />
            </Col>
          }
        </Row>


        {/*{*/}
        {/*  commonTags && commonTags.length > 0 &&*/}
        {/*    <div className="mt-3 mb-0">*/}
        {/*      /!*<p className="custom_card__container-subhead my-2">TAGS</p>*!/*/}
        {/*      <p className="custom_card__container-title"><ColorTagInlineBadgeList items={commonTags} size={'sm'} /></p>*/}
        {/*    </div>*/}
        {/*}*/}

      </CardBody>
    </Card>
  )
}


IndicatorRiskScoreGauge.propTypes = {
  score: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  whitelisted: PropTypes.bool
}


export default IndicatorRiskScoreGauge;