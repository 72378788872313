import React from "react";
import CollectionForm from "../../../shared/components/form/events-form/CollectionForm";
import Page from "../../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_EDITOR} from "../../../shared/helpers/features";


const CollectionAdd = () => {
  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
    >
      <CollectionForm />
    </Page>
  )
}


export default CollectionAdd;
