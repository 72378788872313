import React, {useEffect, useState} from "react";
import {Badge, Button, Card, CardBody, Col, Row} from "reactstrap";
import {useBotNetProjectResultsCount} from "../../../../queries/IdentityMonitorResults";

import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import PropTypes from "prop-types";
import BotNetTableWithPagination from "./components/BotNetTableWithPagination";
import {useSetAllBotnetsSolved} from "../../../../mutations/SetAllBotnetsSolved";
import {useSetBotnetsSolved} from "../../../../mutations/SetBotnetsSolved";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../../../shared/helpers";
import RegisteredCheckBoxField from "../../../../shared/components/form/RegisteredCheckBoxField";
import {download_file} from "../../../../shared/helpers/download_file";
import {apiUrlInternal} from "../../../../config/api";
import moment from "moment/moment";
import {useCookieAccessToken} from "../../../../queries";
import {formatDateUTCtoYearMonthDayTimeFile} from "../../../../shared/helpers/date";


const BotNet = ({project_uid, project_name}) => {
  const {
    data,
    isIdle,
    isLoading,
    isError,
    isFetching
  } = useBotNetProjectResultsCount(project_uid);

  const mutationSetAllBotnetsSolved = useSetAllBotnetsSolved();
  const mutationSetBotnetsSolved = useSetBotnetsSolved();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnSelectDisabled, setBtnSelectDisabled] = useState(true);
  const {register, setValue, watch} = useForm();
  const [uidsSelected, setUidsSelected] = useState([]);
  const [count, setCount] = useState(0);
  const {data: accessToken} = useCookieAccessToken();

  const unsetSelection = () => {
    for (let i = 0; i < count; ++i) {
      setValue(`botnet.${i}`, false)
    }
  }

  useEffect(() => {
    setBtnSelectDisabled(uidsSelected.length === 0);
  }, [uidsSelected])

  useEffect(() => {
    if (!mutationSetAllBotnetsSolved.isIdle) {
      toast.dismiss();

      if (mutationSetAllBotnetsSolved.isLoading) {
        toast.loading('Marking all botnets as solved');
      } else {
        setBtnDisabled(false);
        if (mutationSetAllBotnetsSolved.isSuccess) {
          toast.success('botnets marked as solved');
          unsetSelection();
        } else if (mutationSetAllBotnetsSolved.isError) {
          toast.error(getErrorMessageFromResponse(mutationSetAllBotnetsSolved));
          mutationSetAllBotnetsSolved.reset();
        }
      }
    }
  }, [
    mutationSetAllBotnetsSolved.isLoading,
    mutationSetAllBotnetsSolved.isIdle,
    mutationSetAllBotnetsSolved.isSuccess,
    mutationSetAllBotnetsSolved.isError,
    getErrorMessageFromResponse(mutationSetAllBotnetsSolved),
  ]);

  useEffect(() => {
    if (!mutationSetBotnetsSolved.isIdle) {
      toast.dismiss();

      if (mutationSetBotnetsSolved.isLoading) {
        toast.loading('Marking  botnets as solved');
      } else {
        setBtnDisabled(false);
        if (mutationSetBotnetsSolved.isSuccess) {
          toast.success('botnets marked as solved');
          unsetSelection();
        } else if (mutationSetBotnetsSolved.isError) {
          toast.error(getErrorMessageFromResponse(mutationSetBotnetsSolved));
          mutationSetBotnetsSolved.reset();
        }
      }
    }
  }, [
    mutationSetBotnetsSolved.isLoading,
    mutationSetBotnetsSolved.isIdle,
    mutationSetBotnetsSolved.isSuccess,
    mutationSetBotnetsSolved.isError,
    getErrorMessageFromResponse(mutationSetBotnetsSolved),
  ]);

  useEffect(() => {
    if (!isIdle && !isLoading && !isFetching && data?.data) {
      setCount(data.data.count);
    }

  }, [isIdle, isLoading, isFetching, data])

  if (isIdle || isLoading || isFetching) {
    return <LoadingSpinner/>
  }

  if (isError) {
    return <Card><CardBody><h4>Unable to load the content</h4></CardBody></Card>;
  }

  const botnets_count = data.data.count;

  if (botnets_count === 0) {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Badge color={'success'} className={'w-100 outline badge-lg'}>NO BOTNET FOUND</Badge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <Badge color={'danger'} className={'w-100 outline badge-lg'}>
              {
                botnets_count === 1 ? `THERE IS ${botnets_count} HOST COMPROMISED BY BOTNET` :
                  `THERE ARE ${botnets_count} HOSTS COMPROMISED BY BOTNET`
              }
            </Badge>
          </Col>
        </Row>

        {/*<Row className={'mt-3'}>*/}
        {/*  <Col md={12} lg={6}>*/}
        {/*    <BotNetSourceWordCloud botnets={botnets} />*/}
        {/*  </Col>*/}

        {/*  <Col md={12} lg={6}>*/}
        {/*    <BotNetSourcePieChart botnets={botnets} />*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        {/*<Row className={'mt-3'}>*/}
        {/*  <Col>*/}
        {/*    <BotNetSourceMap botnets={botnets} />*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        <Row className={'mt-3'}>
          <Col>
            <div className="card__title">
              <h4 className={'bold-text d-inline'}>RESULTS</h4>
              <Button
                onClick={(e) => {
                    e.preventDefault();
                    download_file(
                      `${apiUrlInternal}/keyword_projects/${project_uid}/results/botnets/export`,
                      `export_${project_name}_botnet_${formatDateUTCtoYearMonthDayTimeFile(moment())}.csv`,
                      accessToken,
                      60000,
                      'Creating the CSV file, it can take few minutes'
                    );
                }}
                outline
                color={'success'}
                className={'float-right py-1 mr-0'}
              >
                Download
              </Button>
              <Button
                onClick={() => {
                  setBtnDisabled(true);
                  mutationSetAllBotnetsSolved.mutate(project_uid);
                }}
                className={'float-right py-1 mr-2'}
                disabled={btnDisabled}
              >
                Mark All As Solved
              </Button>
              <Button
                onClick={() => {
                  setBtnDisabled(true);
                  mutationSetBotnetsSolved.mutate({
                    project_uid: project_uid,
                    uids: uidsSelected
                  });
                }}
                className={'float-right py-1 mr-2'}
                disabled={btnDisabled || btnSelectDisabled}
              >
                Mark Selected As Solved
              </Button>
            </div>

            <BotNetTableWithPagination
              extraColumnsAfter={[{Header: 'Keyword', accessor: 'keyword.value'},]}
              totalItems={botnets_count}
              extraColumnsBefore={[
                {
                  Header: 'Status', accessor: 'solved',
                  Cell: ({value, row}) => {
                    const label = value ?
                      <p className={'d-inline'} style={{color: '#4ce1b6'}}>Solved</p> :
                      <p className={'d-inline'} style={{color: '#ff4861'}}>New Item</p>;

                    const index = row.index;
                    const uid = row.original.uid;

                    return (
                      <>
                        {
                          !value &&
                          <RegisteredCheckBoxField
                            name={`botnet.${index}`}
                            onChange={() => {
                              if (watch(`botnet.${index}`) && !uidsSelected.includes(uid)) {
                                setUidsSelected(uidsSelected.concat([uid]));
                              } else if (!watch(`botnet.${index}`) && uidsSelected.includes(uid)) {
                                setUidsSelected(uidsSelected.filter(val => val !== uid));
                              }
                            }}
                            value={watch(`botnet.${index}`)}
                            defaultChecked={false}
                            label={''}
                            register={register}
                            style={{display: 'inline'}}
                            className={'d-inline'}
                          />
                        }
                        {label}
                      </>
                    )
                  }
                },
              ]}
              project_uid={project_uid}
              customSort={true}
            />
          </Col>
        </Row>

      </CardBody>
    </Card>
  )
}


BotNet.propTypes = {
  project_uid: PropTypes.string.isRequired,
  project_name: PropTypes.string.isRequired,
}


export default BotNet;
