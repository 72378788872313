import React from "react";
import {Card, CardBody} from "reactstrap";
import {useStatsContentsByCountries} from "../../../../queries/Stats";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import SimpleMapsWithData from "../../../../shared/components/maps/EasyVectorMap/SimpleMapsWithData";
import {useHistory} from "react-router-dom";
import paths from "../../../../config/paths";
import allCountries from './../../../../shared/components/maps/EasyVectorMap/sample-data.json';


const _ = require('lodash');


const TargetVictimStats = () => {
  const history = useHistory();
  const { data, isIdle, isLoading, isError, error } = useStatsContentsByCountries();

  if (isError) {
    console.log(error);
  }

  const targetedCountries = isError ? [] :
    allCountries.map((country) => {
      const match = _.find(data?.data, (c) => c.iso_code === country.id);
      if (!match) {
        return {id: country.id, value: 0}
      } else {
        return {id: match.iso_code, value: match.num_contents}
      }
    });

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Targeted Countries</h4>
        </div>
        {
          isIdle || isLoading ? <LoadingSpinner /> :
            <SimpleMapsWithData
              data={targetedCountries}
              height={'70vh'}
              isError={isError}
              valueLabel={'contents'}
              onCountryClick={(ev) => {
                history.push(`${paths.contentsPath}?targeted_countries=${ev.target.dataItem.dataContext.id}`)
              }}
              divName={'contents-most-targeted'}
            />
        }
      </CardBody>
    </Card>
  )
}

TargetVictimStats.propTypes = {
}

export default TargetVictimStats;
