import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const CountriesFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/countries`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useCountriesQuery = (fetcher = CountriesFetcher) => {
  return useQueryWithAuthentication(
    ['countries'],
    (accessToken) => fetcher(accessToken),
    {cacheTime: Infinity, staleTime: Infinity}
  )
}
