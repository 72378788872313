import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import CveReferenceRows from "../../../../../../shared/components/cve/CveReferenceRows";


const CveReferencesList = ({refs}) =>
  <Card>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>PUBLIC EXPLOITS</h4>
      </div>
      <CveReferenceRows refs={refs}/>
    </CardBody>
  </Card>


CveReferencesList.propTypes = {
  refs: PropTypes.arrayOf(PropTypes.object).isRequired
}


export default CveReferencesList;
