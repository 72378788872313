import React, {useEffect} from "react";
import {Button, Card, CardBody, Col} from "reactstrap";
import PropTypes from "prop-types";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import {useKeywordTypesQuery} from "../../../queries/KeywordTypes";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import {useForm} from "react-hook-form";
import {useAddKeywordProject} from "../../../mutations/AddKeywordProject";
import RegisteredInputField from "../../../shared/components/form/RegisteredInputField";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../../shared/helpers";
import {useProjectsOrganizationKeywordQuery} from "../../../queries/ProjectsOrganizationKeyword";
import KeywordProjectFormCollapse from "./collapse/KeywordProjectFormCollapse";
import {hasUserEditorPermissionOnFeature} from "../../../shared/helpers/permissions";
import {
  PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS,
} from "../../../shared/helpers/features";

const DarkWebKeywordsSection = ({organization}) => {
  const {data, isIdle, isLoading, isError} = useProjectsOrganizationKeywordQuery(organization.uid);
  const {
    data: dataUser,
    isIdle: isIdleUser,
    isLoading: isLoadingUser,
    isError: isErrorUser
  } = useCurrentUserQuery();

  const {
    data: dataKeywordType,
    isIdle: isIdleKeywordType,
    isLoading: isLoadingKeywordType,
    isError: isErrorKeywordType
  } = useKeywordTypesQuery();
  const {register, handleSubmit, formState: {errors}} = useForm();
  const mutation = useAddKeywordProject();
  const onSubmit = (formData) => {
    toast.loading(`Creating Engine ${formData.project_name}`)
    mutation.mutate({
      params: {
        name: formData.project_name
      },
      organizationUid: organization.uid
    })
  }

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss()
      if (mutation.isSuccess) {
        toast.success('Engine created');
      } else if (mutation.isError) {
        toast.error(getErrorMessageFromResponse(mutation));
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])

  if (
    isIdle || isLoading || isIdleUser || isLoadingUser || isIdleKeywordType || isLoadingKeywordType ||
    isError || isErrorUser || isErrorKeywordType
  ) {
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Identity Intelligence Engine</h4>
            {
              (isLoading || isIdle || isIdleUser || isLoadingUser || isIdleKeywordType || isLoadingKeywordType) ?
                <LoadingSpinner/> :
                <h4>Unable to retrieve the engines from your organization</h4>
            }
          </div>
        </CardBody>
      </Card>
    )
  }

  return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Identity Intelligence Engine</h4>
            <p className={'subhead'}>
              You created {data.data.length}/{dataUser.data.organization.max_project_keyword} engines.
              Contact the administrator to extend this limit.
            </p>
            {
              data.data.length === dataUser.data.organization.max_project_keyword &&
              <p className={'subhead'} style={{'color': 'red'}}>You reach the max number of engines you can create.</p>
            }
          </div>
          <form className="form form--vertical">
            <Col md={12}>
              <RegisteredInputField
                name={'project_name'}
                title={'Engine Name'}
                register={register}
                errors={errors}
                rules={{required: 'The name is required'}}
              />
              <Button
                color={'success'}
                className={'float-right'}
                type={'submit'}
                onClick={handleSubmit(onSubmit)}
                disabled={
                  data.data.length === dataUser.data.organization.max_project_keyword ||
                  !hasUserEditorPermissionOnFeature(dataUser?.data?.modules || [], PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS)
                }
              >
                Create Engine
              </Button>
            </Col>
          </form>
          {
            data.data.map((project) =>
              <KeywordProjectFormCollapse
                project={project}
                organization={organization}
                keywordTypes={dataKeywordType.data}
                key={project.uid}
              />
            )
          }
        </CardBody>
      </Card>

)

}


DarkWebKeywordsSection.propTypes = {
  organization: PropTypes.object.isRequired
}


export default DarkWebKeywordsSection;
