import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {useQueryWithAuthentication} from "../index";
import {addAuthToAxiosOpts} from "../../config/queryopts";


const StatsFetcher = (accessToken, endpoint) => {
  return axios.get(`${apiUrlInternal}/sandbox${endpoint}`, addAuthToAxiosOpts(accessToken, {}))
    .then(res => res.data);
}

const useSandboxQuery = (endpoint = null, fetcher = StatsFetcher) => {
  return useQueryWithAuthentication(['sandbox', endpoint],(accessToken) => fetcher(accessToken, endpoint))
}

export const useSandboxTasksReported = (limit = 0) => {
  const endpoint = `/tasks/reported?limit=${limit}`;
  return useSandboxQuery(endpoint);
}
