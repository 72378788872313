import Collapse from "../../../../shared/components/Collapse";
import React from "react";
import ResourceMonitorProjectFormCollapseContent from "./ResourceMonitorProjectFormCollapseContent";
import PropTypes from "prop-types";
import HighlightSpan from "../../../../shared/components/span/HighlightSpan";


const ResourceMonitorProjectFormCollapse = ({
  project,
  resourceMonitorTypes,
  organization
}) =>
  <Collapse
    title={
      <>Engine "<HighlightSpan text={project.name}/>".
        You inserted {project.resource_monitors.length}/{project.max_resources_to_monitor} resources to monitor.
      </>
    }
    className={"with-shadow"}
  >
    <ResourceMonitorProjectFormCollapseContent
      project={project}
      resource_monitors={project.resource_monitors}
      organization={organization}
      resourceMonitorTypes={resourceMonitorTypes}
    />
  </Collapse>


ResourceMonitorProjectFormCollapse.propTypes = {
  project: PropTypes.object.isRequired,
  resourceMonitorTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  organization: PropTypes.object.isRequired
}


export default ResourceMonitorProjectFormCollapse;
