import React, {Fragment} from "react";
import PropTypes from "prop-types";
import TableWithData from "../../../shared/components/table/TableWithData";
import VirusTotalIndicatorLink from "../../../shared/components/icons/VirusTotalIndicatorLink";
import CopyIconToClipboard from "../../../shared/components/icons/CopyIconToClipboard";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import paths from "../../../config/paths";
import {useHistory} from "react-router-dom";


const tableColumns = [
  { id: 'VT', Header: '', accessor: 'record_name', Cell: ({value}) =>
      <Fragment>
        <VirusTotalIndicatorLink value={value} />
        <CopyIconToClipboard value={value} />
      </Fragment>
  },
  { Header: 'Record Name', accessor: 'record_name' },
  { Header: 'Record Value', accessor: 'record_value' },
  { Header: 'Record Type', accessor: 'record_type' },
  { Header: 'Country', accessor: 'country_name' },
  { Header: 'First Seen', accessor: 'first_seen', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value) },
  { Header: 'Last Seen', accessor: 'last_seen', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value) },
];


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
  withHover: true,
  isSortable: true
}


const PassiveDnsTable = ({resolutions, indicator}) => {
  const history = useHistory();
  if (resolutions.length === 0) {
    return <Fragment />
  }

  return (
    <TableWithData
      data={resolutions}
      tableColumns={tableColumns}
      tableConfig={tableConfig}
      onRowClick={(index, row) => {
        if (row.original.record_name === indicator && row.original.record_value !== 'NXDOMAIN') {
          history.push(`${paths.investigatePath}?indicator=${row.original.record_value}`)
        } else {
          history.push(`${paths.investigatePath}?indicator=${row.original.record_name}`)
        }
      }}
    />
  )
}


PassiveDnsTable.propTypes = {
  resolutions: PropTypes.array.isRequired,
  indicator: PropTypes.string.isRequired
}


export default PassiveDnsTable;
