import {addAuthToFetchOpts} from '../../config/queryopts'
import {useMutationWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteTicketFetcher = (uid, accessToken) => {
  return axios.delete(`${apiUrlInternal}/tickets/${uid}`, addAuthToFetchOpts(accessToken, {})).then(res => res.data );
}

export const useDeleteTicket = (fetcher = DeleteTicketFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-ticket'],
    (accessToken, uid) => fetcher(uid, accessToken),
    {onSuccess: () => {
        queryClient.invalidateQueries('delete-ticket')
        queryClient.invalidateQueries(['tickets', ''])
     }}
  )
}