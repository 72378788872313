import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const EditOrganizationFetcher = (accessToken, params, uid) => {
  return axios.put(
    `${apiUrlInternal}/organizations/${uid}`,
    params, 
    addAuthToFetchOpts(accessToken, {}, 0)
  ).then(res => res.data);
}

export const useEditOrganizationMutation = (fetcher = EditOrganizationFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['edit-organizations'],
    (accessToken, {params, uid}) => fetcher(accessToken, params, uid),
    {onSuccess: (_, {uid}) => {
        queryClient.invalidateQueries(['edit-organizations'])
        queryClient.invalidateQueries(['users-me'])
        queryClient.invalidateQueries(['organizations', null])
        queryClient.invalidateQueries(['organizations', uid])
        queryClient.invalidateQueries(['user-details', null])
      }}
  )
}
