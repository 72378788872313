import React, {useState} from "react";
import PropTypes from "prop-types";
import TableWithDataHookPagination from "../../../../../shared/components/table/TableWithDataHookPagination";
import {useCredentialExposureProjectResults} from "../../../../../queries/IdentityMonitorResults";
import {tableColumns} from "../../../../../shared/components/darkweb/CredentialTable";
import {getFilterField} from "../../../../../shared/helpers/intelligence";


const CredentialTableWithPagination = ({
  project_uid,
  totalItems,
  extraColumnsAfter = [],
  extraColumnsBefore = [],
  customSort = false
}) => {
  const [orderByFilter, setOrderByFilter] = useState("")
  const [orderBy, setOrderBy] = useState('')
  const tableConfig = {
    withPagination: true,
    withSearchEngine: false,
    isSortable: !customSort,
  }
  const customOnHeaderClick = (target) => {
    setOrderByFilter(getFilterField(target))
  }

  const customOnHeaderIconClick = (target) => {
    setOrderBy(target)
  }

  return (
    <TableWithDataHookPagination
      tableColumns={extraColumnsBefore.concat(tableColumns.concat(extraColumnsAfter))}
      tableConfig={tableConfig}
      useDataQuery={useCredentialExposureProjectResults}
      queryParams={project_uid}
      totalItems={totalItems}
      orderByFilter={orderByFilter}
      orderBy={orderBy}
      onHeaderClick={customOnHeaderClick}
      onHeaderIconClick={customOnHeaderIconClick}
    />
  )
}


CredentialTableWithPagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  project_uid: PropTypes.string.isRequired,
  extraColumnsAfter: PropTypes.array,
  extraColumnsBefore: PropTypes.array,
  customSort: PropTypes.bool
}


export default CredentialTableWithPagination;
