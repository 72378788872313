import PaperclipIcon from "mdi-react/PaperclipIcon";
import PropTypes from "prop-types";
import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {apiUrlInternal} from "../../../config/api";
import {useCookieAccessToken} from "../../../queries";
import {download_file} from "../../helpers/download_file";


const DownloadReport = ({info}) => {
  const {data: accessToken} = useCookieAccessToken();

  return (
    <>
      {info?.is_report_purchased && (info.report_filename || (info.attachments && info.attachments.length > 0)) &&
        <Card className={'h-auto'}>
          <CardBody className="text-white">
            <Row>
              {
                info.report_filename &&
                <Col md={6} xs={12} className={'mb-2'}>
                  <h5 className="pb-2">Report: </h5>
                  <div
                    className={`file-download ${info['is_report_purchased'] ? '' : 'disabled'}`}
                    onClick={() => download_file(
                      `${apiUrlInternal}/attachments/${info.report_id}`, info.report_filename, accessToken, 60000
                    )}
                  >
                    <PaperclipIcon size={14}/><span>{info.report_filename}</span>
                  </div>
                </Col>
              }
              {
                info.attachments && info.attachments.length > 0 &&
                <Col md={6} xs={12} className={'mb-2'}>
                  <h5 className="pb-2">Attachments: </h5>
                  {
                    info.attachments.map((attachment) =>
                      <div
                        className={`file-download ${info['is_report_purchased'] ? '' : 'disabled'}`}
                        onClick={() => download_file(
                          `${apiUrlInternal}/attachments/${attachment.file_id}`, attachment.file_name, accessToken, 60000
                        )}
                      >
                        <PaperclipIcon size={14}/><span>{attachment.file_name}</span>
                      </div>
                    )
                  }
                </Col>
              }
            </Row>
          </CardBody>
        </Card>
      }
    </>
  )
}


DownloadReport.propsType = {
  info: PropTypes.array.isRequired,
}

export default DownloadReport;
