import PropTypes from "prop-types";
import {useEventContentsQuery} from "../../../queries/EventContents";


export const useSearchEventContentsQueryWithDict = ({
  start = '', end = '', actor = '', tags= [], order_by = '', search_term = '',
  tlps = null, targeted_sectors = [], targeted_countries = [], origin_countries = [],
  content_type = null, techniques = [], tactics = [], next = null, limit = 0
}) => {
  return useEventContentsQuery(
    false, limit, start, end, actor, tags, order_by, search_term, tlps, targeted_sectors,
    targeted_countries, origin_countries, content_type, techniques, tactics, next
  )
}


useSearchEventContentsQueryWithDict.propTypes = {
  filters: PropTypes.shape().isRequired
};
