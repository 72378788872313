import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import IndicatorRiskScoreGauge from "../../IndicatorRiskScoreGauge";
import RelatedActorsCard from "../../RelatedActorsCard";
import ContentTimeLineCard from "../../ContentTimeLineCard";
import IndicatorDetailFooter from "../../IndicatorDetailFooter";
import RelatingIndicatorByFileCard from "./components/RelatingIndicatorByFileCard";
import FirstLastSeenRow from "../../FirstLastSeenRow";

const FileNameDetails = ({indicator_details}) => {
  return (
    <Fragment>
      <Row>
        <Col lg={5} md={12}>
          <Row>
            <Col>
              <IndicatorRiskScoreGauge score={!indicator_details.whitelisted ? indicator_details.score : 0} tags={indicator_details.tags} whitelisted={indicator_details.whitelisted} />
              <FirstLastSeenRow last_seen={indicator_details.last_seen} first_seen={indicator_details.first_seen} />
              <RelatedActorsCard actors={indicator_details.actors} isKnown={indicator_details.is_known} />
            </Col>
          </Row>
        </Col>

        <Col lg={7} md={12}>
          <Row>
            <Col>
              {indicator_details.related_indicators.by_file.length > 0 &&
                <RelatingIndicatorByFileCard by_file={indicator_details.related_indicators.by_file} indicator={indicator_details.indicator}/>}
              {indicator_details.related_contexts.length > 0 && <ContentTimeLineCard contexts={indicator_details.related_contexts}/>}
            </Col>
          </Row>
        </Col>
      </Row>

      <IndicatorDetailFooter indicator_details={indicator_details} excludeIntelxTable />
    </Fragment>
  )
}


FileNameDetails.propTypes = {

}


export default FileNameDetails;
