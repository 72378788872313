export const logoBucketUrl = process.env.REACT_APP_LOGO_BUCKET_URL;

export const homePath = "/";
export const loginPath = "/login";
export const logOutPath = "/logout";
export const contentsPath = "/contents";
export const analysisPath = "/analysis";
export const analysisDetailPath = "/analysis/:analysisUid";
export const postPath = "/post";
export const postDetailPath = "/post/:postUid";
export const indicatorPath = "/indicator";
export const investigateDetailPath = "/investigate/:IndicatorValue?";
export const investigatePath = "/investigate";
export const sandboxTasksPath = "/sandbox/tasks";
export const sandboxTasksDetailPath = "/sandbox/tasks/:taskId";
export const actorPath = "/actor";
export const actorDetailPath = "/actor/:actorUid";
export const actorAddPath = "/actor/add";
export const editActorPath = "/actor/edit/:actorUid";
export const retroHuntTasks = "/retrohunt/tasks";
export const addAnalysisPath = "/analysis/add";
export const addPostPath = "/post/add";
export const addCollectionPath = "/collection/add";
export const editAnalysisPath = "/analysis/edit/:analysisUid";
export const editPostPath = "/post/edit/:postUid";
export const profilePath = "/profile";
export const redirectLogoutPath = "/redirect/logout";
export const addUserPath = "/user/add";
export const listUserPath = "/user";
export const addApplicationPath = "/application/add";
export const listApplicationPath = "/application";
export const addOrganizationPath = "/organization/add";
export const listOrganizationPath = "/organization";
export const geneAnalysisPath = "/genes-analysis";
export const intelligenceSearchPath = "/intelligence/search";
export const malwareFamilyPath = "/malware/:malwareFamilyUid";
export const malwareFamilyBasePath = "/malware";
export const intelligenceIdentityPath = "/intelligence/identity";
export const notificationPath = "/notification";
export const resourceMonitorPath = "/vulnerability";
export const ticketsPath = "/tickets";
export const ticketDetailPath = "/tickets/:ticketUid";
export const addMalwarePath = "/malware/add";
export const editMalwarePath = "/malware/edit/:malwareUid";
export const takeDownServicePath = "/takedownservices";
export const takeDownServiceDetailPath = "/takedownservices/:uid";
export const rulesPath = "/rules";
export const addFlashAlertPath = "/flashalert/add";
export const flashAlertDetailPath = "/flashalert/:uid";
export const flashAlertPath = "/flashalert";
export const editFlashAlertPath = "/flashalert/edit/:uid";
export const activitiesDetailPath = "/activities/:uid";
export const activitiesPath = "/activities";
export const editActivitiesPath = "/activities/edit/:uid";
export const addActivitiesPath = "/activities/add";
export const ransomwareTrackerEventsPath = "/ransomwaretrackerevents"
export const feedlyFeedsPath = "/news"
export const feedlyFeedDetailPath = "/news/:uid"
export const moduleSettingsPath = "/moduleSettings";
export const editUserPath = "/user/:uid";
export const editOrganizationPath = "/organization/edit/:organizationUid";
export const editApplicationPath = "/application/edit/:applicationUid";
export const commandsPath = "/commands"
export const rulesSectionPath = "/rulesmitre"
export const typoSquattingPath = "/typosquatting"

export default {
  homePath,
  loginPath,
  contentsPath,
  analysisPath,
  analysisDetailPath,
  postPath,
  postDetailPath,
  indicatorPath,
  investigateDetailPath,
  investigatePath,
  actorPath,
  actorDetailPath,
  actorAddPath,
  editActorPath,
  addAnalysisPath,
  addPostPath,
  sandboxTasksPath,
  sandboxTasksDetailPath,
  profilePath,
  retroHuntTasks,
  addUserPath,
  listUserPath,
  addApplicationPath,
  listApplicationPath,
  editAnalysisPath,
  editPostPath,
  geneAnalysisPath,
  addCollectionPath,
  redirectLogoutPath,
  intelligenceSearchPath,
  addOrganizationPath,
  listOrganizationPath,
  malwareFamilyPath,
  malwareFamilyBasePath,
  intelligenceIdentityPath,
  notificationPath,
  resourceMonitorPath,
  ticketsPath,
  ticketDetailPath,
  addMalwarePath,
  editMalwarePath,
  takeDownServicePath,
  takeDownServiceDetailPath,
  logOutPath,
  rulesPath,
  flashAlertPath,
  addFlashAlertPath,
  flashAlertDetailPath,
  editFlashAlertPath,
  ransomwareTrackerEventsPath,
  feedlyFeedsPath,
  feedlyFeedDetailPath,
  moduleSettingsPath,
  editUserPath,
  editOrganizationPath,
  editApplicationPath,
  activitiesDetailPath,
  activitiesPath,
  editActivitiesPath,
  addActivitiesPath,
  commandsPath,
  rulesSectionPath,
  typoSquattingPath
}
