import React, {Fragment, useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import InfiniteScroll from "react-infinite-scroller";
import TakeDownModal from './components/TakeDownModal';
import TakeDownDetailCard from './components/TakeDownDetailCard';
import {useGetTakedownQuery} from '../../queries/TakeDownService';
import ticketsIcon from '../../shared/img/tickets.svg'
import EmptyDashboard from '../../shared/components/EmptyDashboard';
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {PLATFORM_FEATURE_TAKEDOWN_TICKETS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";

const TakeDownService = () => {
  const LOADING_STEP = 5;
  const INITIAL_ITEMS = 10;
  const [loadedItems, setLoadedItems] = useState(INITIAL_ITEMS);
  const [displayTicketsData, setDisplayTicketsData] = useState([]);
  const [ticketsData, setTicketsData] = useState([]);
  const {data, isIdle, isLoading, isError, error} = useGetTakedownQuery();
  const {data: dataCurrentUser} = useCurrentUserQuery();

  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setTicketsData(data.data);
      setDisplayTicketsData(data.data.slice(0, INITIAL_ITEMS));
      setLoadedItems(INITIAL_ITEMS);
    }
  }, [isIdle, isLoading, isError, data])

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  const loadMoreItems = () => {
    setTimeout(() => {
      setDisplayTicketsData(ticketsData.slice(0, loadedItems + LOADING_STEP));
      setLoadedItems(loadedItems + LOADING_STEP);
    }, 1000);
  }

  if (isIdle || isLoading) {
    return <LoadingSpinnerPage/>
  }

  if (displayTicketsData.length === 0 && !isLoading && !isIdle) {
    return (
      <Page
        feature={PLATFORM_FEATURE_TAKEDOWN_TICKETS}
        onlyOnline
      >
        <EmptyDashboard
          title="Open a case to our research team!"
          description={<>
            You’ve never opened a ticket to take down an artifact yet. <br/>
            Use our "Takedown Service" to report an abuse of domain, email, URL, or IPv4 to the Cluster25 team. <br/>
            The team will verify the threat of the indicator and manage the procedure to take it down. <br/>
            You can start by clicking the button below.
          </>}
          modalComp={<TakeDownModal
            disabled={!hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TAKEDOWN_TICKETS)}/>}
          icon={ticketsIcon}
        />
      </Page>
    )
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_TAKEDOWN_TICKETS}
      onlyOnline
    >
      <Container className='ticketWrapper'>
        {
          isIdle || isLoading ? <LoadingSpinner/> :
            <Fragment>
              <div className={'div__sticky-top'}>
                <Row>
                  <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto'}>
                    <h3 className="page-title">Take Down Service Tickets</h3>
                    <TakeDownModal
                      disabled={
                        !hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TAKEDOWN_TICKETS) ||
                        dataCurrentUser?.data?.organization?.used_takedown_ticket >= dataCurrentUser?.data?.organization?.takedown_ticket
                      }
                    />
                    {
                      dataCurrentUser?.data?.organization?.used_takedown_ticket >= dataCurrentUser?.data?.organization?.takedown_ticket &&
                      <h5 style={{color: "#ad4444"}}>You have reached your takedown ticket quota</h5>
                    }
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto mb-2'}>
                  {
                    ticketsData.length > 0 &&
                    <InfiniteScroll
                      loadMore={loadMoreItems}
                      hasMore={loadedItems < ticketsData.length}
                      loader={<LoadingSpinner/>}
                    >
                      {
                        displayTicketsData.map((_i, index) => (
                          <TakeDownDetailCard ticket={displayTicketsData[index]} key={index}/>
                        ))
                      }
                    </InfiniteScroll>
                  }
                </Col>
              </Row>
            </Fragment>
        }
      </Container>
    </Page>
  );
}

export default TakeDownService;
