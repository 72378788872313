import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


const TakeDownFetcher = (accessToken, endpoint) => {
    return axios.get(`${apiUrlInternal}/takedown_tickets${endpoint}`, addAuthToAxiosOpts(accessToken, {}))
      .then(res => res.data);
}
  
const useTakedownServiceQuery = (
  endpoint,
  options,
  fetcher = TakeDownFetcher
) => {
    return useQueryWithAuthentication(['takedown-tickets', endpoint],(accessToken) => fetcher(accessToken, endpoint), options)
}

export const useGetTakedownMessages = (uid) => {
    return useTakedownServiceQuery(`/${uid}/messages`, {refetchInterval: 20000});
}

export const useGetTakedownQuery = (uid = null) => {
    const endpoint = (uid) ? `/${uid}` : '';
    return useTakedownServiceQuery(endpoint);
}

export const useTakedownCategoryQuery = () => {
    return useTakedownServiceQuery('/categories');
}

export const useTakedownIndicatorTypeQuery = () => {
    return useTakedownServiceQuery('/indicators_type');
}

export const useTakedownConfidenceQuery = () => {
    return useTakedownServiceQuery('/confidences');
}

export const useGetTakedownTicketByValue = (value) => {
    const urlParams = new URLSearchParams();

    urlParams.set('value', value);

    return useTakedownServiceQuery(
      `?${urlParams.toString()}`,
      {enabled: !!value}
    );
}
