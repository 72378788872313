import PropTypes from "prop-types";
import Collapse from "../../../Collapse";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import paths from "../../../../../config/paths";
import {formatDateUTCtoYearMonthDayTime} from "../../../../helpers/date";
import {ColorTagInlineBadgeList} from "../../../badge/BadgeLists";
import React, {useMemo} from "react";
import {getCommonTags} from "../../../../helpers";


const IndicatorsCollapses = ({ioc}) => {
  const commonTags = useMemo(() => getCommonTags(ioc.tags), [ioc.tags]);

 return (
   <Collapse title={ioc.value} className={"with-shadow"} key={ioc.value}>
     <Row>
       <Col md={3}><p>Value</p></Col>
       <Col md={9}><p><Link to={`${paths.investigatePath}?indicator=${ioc.value}`}>{ioc.value}</Link></p></Col>
     </Row>
     <Row>
       <Col md={3}><p>Creation date</p></Col>
       <Col md={9}><p>{formatDateUTCtoYearMonthDayTime(ioc.created_dt)}</p></Col>
     </Row>
     {
       commonTags.length > 0 &&
       <Row>
         <Col md={3}><p>Tags</p></Col>
         <Col md={9}>
           <ColorTagInlineBadgeList items={commonTags}/>
         </Col>
       </Row>
     }
   </Collapse>
 )
}


IndicatorsCollapses.propTypes = {
  ioc: PropTypes.shape().isRequired
}


export default IndicatorsCollapses;
