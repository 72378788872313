import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


const RulesFetcher = (accessToken, endpoint) => {
  return axios.get(`${apiUrlInternal}/rules${endpoint}`, addAuthToAxiosOpts(accessToken, {}, 1200000))
    .then(res => res.data);
}

const useRulesQuery = (endpoint = null, options = {}, fetcher = RulesFetcher) => {
  return useQueryWithAuthentication(['rules', endpoint], (accessToken) => fetcher(accessToken, endpoint), options)
}
export const useRulesTTPSCountQuery = () => {
  return useRulesQuery(`/ttps/count`)
}

export const useRulesMalwareFamiliesCountQuery = () => {
  return useRulesQuery(`/malware_families/count`)
}

export const useRulesMalwareFamiliesQuery = (uid) => {
  return useRulesQuery(`/malware_families/${uid}`, {enabled: !!(uid)})
}

export const useRulesSearchQuery = (search_term, typingTimer, next = null, limit=null) => {
  const urlParams = new URLSearchParams();
  let enabled = false;
  if (next !== null) {
    urlParams.set('next', next);
  }
  if (limit !== null) {
    urlParams.set('limit', limit);
  }
  if (search_term !== null && search_term?.length >= 4 && (typingTimer < 1 || typingTimer === undefined)) {
    urlParams.set('search_term', search_term);
    enabled = true
  }
  return useRulesQuery(`?${urlParams.toString()}`, {enabled: enabled})
}

export const useRulesByTTPQuery = (uid) => {
  return useRulesQuery(`/ttp/${uid}`, {enabled: !!(uid)})
}

export const useRulesByUidQuery = (uid) => {
  return useRulesQuery(`/${uid}`, {enabled: !!(uid)})
}