import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import IndicatorRiskScoreGauge from "../../IndicatorRiskScoreGauge";
import RelatedActorsCard from "../../RelatedActorsCard";
import PropTypes from "prop-types";
import ContentTimeLineCard from "../../ContentTimeLineCard";
import IndicatorAsnCard from "./components/IndicatorAsnCard";
import IndicatorDetailFooter from "../../IndicatorDetailFooter";
import _ from "lodash";
import FirstLastSeenRow from "../../FirstLastSeenRow";

const AsnDetails = ({indicator_details}) =>
  <Fragment>
    <Row>
      <Col lg={5} md={12}>
        <Row>
          <Col>
            <IndicatorRiskScoreGauge score={!indicator_details.whitelisted ? indicator_details.score : 0} tags={indicator_details.tags} whitelisted={indicator_details.whitelisted} />
            <FirstLastSeenRow last_seen={indicator_details.last_seen} first_seen={indicator_details.first_seen} />
            <RelatedActorsCard actors={indicator_details.actors} isKnown={indicator_details.is_known} />
          </Col>
        </Row>
      </Col>

      <Col lg={7} md={12}>
        <Row>
          <Col>
            {!_.isNull(indicator_details.asn_info) && <IndicatorAsnCard asn_info={indicator_details.asn_info} indicator={indicator_details.indicator}/>}
            {indicator_details.related_contexts.length > 0 && <ContentTimeLineCard contexts={indicator_details.related_contexts}/>}
          </Col>
        </Row>
      </Col>
    </Row>

    <IndicatorDetailFooter indicator_details={indicator_details} />
  </Fragment>


AsnDetails.propTypes = {
  indicator_details: PropTypes.object.isRequired
}

export default AsnDetails;
