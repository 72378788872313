import React from "react";
import PropTypes from "prop-types";
import BotNetNotificationContent from "./NotificationContent/BotNetNotificationContent";
import CredentialNotificationContent from "./NotificationContent/CredentialNotificationContent";
import DarknetNotificationContent from "./NotificationContent/DarknetNotificationContent";
import AnalysisPostContent from "./NotificationContent/AnalysisPostContent";
import IntelxNotificationContent from "./NotificationContent/IntelxNotificationContent";
import ResourceMonitorResultCollapseContent
  from "../../ResourceMonitor/components/ResourceMonitorResultCollapseContent";
import DifferentAnalysisContent from "./NotificationContent/DifferentAnalysisContent";
import RansomwareTrackerNotificationContent from "./NotificationContent/RansomwareTrackerNotificationContent";
import FeedlyFeedNotificationContent from "./NotificationContent/FeedlyFeedNotificationContent";
import TyposquattingNotificationContent from "./NotificationContent/TyposquattingNotificationContent";


const NotificationBody = ({notification}) => {
  if (notification.source === 'botnet') {
    return <BotNetNotificationContent botnet={notification.content}/>
  } else if (notification.source === 'darknet') {
    return <DarknetNotificationContent darknet={notification.content}/>
  } else if (notification.source === 'credential_exp') {
    return <CredentialNotificationContent credential={notification.content}/>
  } else if (notification.source === 'intel') {
    return <IntelxNotificationContent intelx_result={notification.content}/>
  } else if (['analysis', 'post'].includes(notification.source)) {
    return <AnalysisPostContent content={notification.content} type={notification.source}/>
  } else if (['flash_alert'].includes(notification.source)) {
    return <DifferentAnalysisContent content={notification.content} type={notification.source}/>
  } else if (notification.source === 'resource_monitor') {
    const content = {
      'host': notification.content.host,
      'created_dt': notification.content.created_dt,
      'cve': notification.content.cve.cve,
      'nvd_info': notification.content.cve.nvd_info,
      'resource_monitor': notification.content.resource_monitor.value
    }
    return <div className={'div__notification-resource-monitor-content'}>
      <ResourceMonitorResultCollapseContent vuln={content}/>
    </div>
  } else if (notification.source === 'ransomware_tracker') {
    return <RansomwareTrackerNotificationContent content={notification.content}/>
  } else if (notification.source === 'news_feeds') {
    return <FeedlyFeedNotificationContent content={notification.content}/>
  } else if (notification.source === 'typosquatting') {
    return <TyposquattingNotificationContent content={notification.content}/>
  }
  return notification.source;
}


NotificationBody.propTypes = {
  notification: PropTypes.object.isRequired
}

export default NotificationBody;
