import React from "react";
import ActivityForm from "../../../shared/components/form/events-form/ActivityForm";
import Page from "../../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_EDITOR} from "../../../shared/helpers/features";


const ActivityAdd = () => {
  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
    >
      <ActivityForm />
    </Page>
  )
}

export default ActivityAdd;
