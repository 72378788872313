import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const EditPostFetcher = (accessToken, params, uid) => {
  return axios.put(`${apiUrlInternal}/posts/${uid}`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useEditPostMutation = (fetcher = EditPostFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['edit-post'],
    (accessToken, {params, postUid}) => fetcher(accessToken, params, postUid),
    {onSuccess: (_, {postUid}) => {
        queryClient.invalidateQueries('edit-post')
        queryClient.invalidateQueries(['post-details', postUid, true])
        queryClient.invalidateQueries(['post-details', postUid, false])
      }}
  )
}
