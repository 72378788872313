import {Col, Row} from "reactstrap";
import MenuEntry from "../../../../shared/components/MenuEntry";
import MenuEntryWithLink from "../../../../shared/components/MenuEntryWithLink";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import PropTypes from "prop-types";
import React from "react";
import paths from "../../../../config/paths";
import HighlightSpan from "../../../../shared/components/span/HighlightSpan";
const FeedlyFeedNotificationContent = ({content}) => {
  return (
    <Row>
      <Col md={12}>
        <div className="custom_card__container">
          <MenuEntry title={'DATE'} value={content.date ? formatDateUTCtoYearMonthDayTime(content.date) : 'N/A'} />
          <MenuEntry title={'AUTHOR'} value={content.source_title} />
          <MenuEntryWithLink title={'TITLE'} value={<HighlightSpan text={content.article_title}/>} url={`${paths.feedlyFeedsPath}/${content.uid}`}/>
        </div>
      </Col>
    </Row>
  )
}


FeedlyFeedNotificationContent.propTypes = {
  content: PropTypes.object.isRequired
}


export default FeedlyFeedNotificationContent;
