import PropTypes from "prop-types";
import DownIcon from "mdi-react/ChevronDownIcon";
import {Collapse} from "reactstrap";
import ReactTableCustomizerToggle from "./table/components/ReactTableCustomizerToggle";
import React, {Fragment, useEffect, useState} from "react";
import _ from "lodash";


const Filter = ({
  buttonText,
  items,
  setItems,
  preselectedFilters = null,
  filterId = ''
}) => {
  const [collapse, setCollapse] = useState(false);
  const [filters, setFilters] = useState(
    !_.isNull(preselectedFilters) ?
      items.map((item) => preselectedFilters.includes(item)):
      items.map(() => true)
  );

  const [arrayTableCustomizerAddictionToggle, setArrayTableCustomizerAddictionToggle] = useState(
    items.map((item, index) => ({
      id: item + index.toString() + filterId,
      text: item,
      func: () => {
        setFilters(filters.map((filter, filter_index) => filter_index === index ? !filter : filter))
      },
      isChecked: true
    }))
  );

  useEffect(() => {
    setArrayTableCustomizerAddictionToggle(
      items.map((item, index) => ({
        id: item + index.toString() + filterId,
        text: item,
        func: () => {
          setFilters(filters.map((filter, filter_index) => filter_index === index ? !filter : filter))
        },
        isChecked: filters[index]
      }))
    );

    setItems(items.filter((_value, index) => filters[index]));
  }, [filters])

  const handleOpen = () => {
    setCollapse(!collapse);
  };

  return (
    <Fragment>
      <button className="table__btn float-right" type="button" onClick={handleOpen}>
        <h5 style={{margin: 'auto'}}>{buttonText}</h5>
        <DownIcon className="table__icon" />
      </button>
      {collapse && <button className={"table__collapse-back"} type="button" onClick={handleOpen} />}
      <Collapse
        isOpen={collapse}
        className={"table__collapse-content table__collapse-content--notification"}
      >
        <div className="table__collapse-item">
          {arrayTableCustomizerAddictionToggle.map(item => (
            <ReactTableCustomizerToggle
              key={item.id}
              id={item.id}
              text={item.text}
              handleClick={item.func}
              isChecked={item.isChecked}
            />
          ))}
        </div>
      </Collapse>
    </Fragment>
  )
}


Filter.propTypes = {
  buttonText: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  setItems: PropTypes.func.isRequired,
  filterId: PropTypes.string
}


export default Filter;
