import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const TypoSquattingAssetResultsFetcher = (
  project_uid, uid, search_term, next = null, limit = 20, accessToken
) => {
  const urlParams = new URLSearchParams();

  if (next) {
    urlParams.set('next', next);
  }

  if (search_term) {
    urlParams.set('search_term', search_term);
  }

  urlParams.set('limit', limit);

  return axios.get(
    `${apiUrlInternal}/typosquatting_projects/${project_uid}/assets/${uid}/results?${urlParams.toString()}`,
    addAuthToAxiosOpts(accessToken, {})
  ).then(res => res.data);
}

export const useTypoSquattingAssetResultsQuery = (
  project_uid, asset_uid, search_term, next = null, limit = 20, fetcher = TypoSquattingAssetResultsFetcher
) => {
  return useQueryWithAuthentication(
    ['typosquatting-asset-result', project_uid, asset_uid, search_term, next, limit],
    (accessToken) => fetcher(project_uid, asset_uid, search_term, next, limit, accessToken),
    {enabled: !!asset_uid, refetchOnWindowFocus: false}
  )
}
