import {addAuthToFetchOpts} from '../../config/queryopts'
import {useMutationWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


const _ = require('lodash');


export const EditTicketFetcher = (accessToken, params) => {
  return axios.put(
    `${apiUrlInternal}/tickets/close/${params.uid}`, _.omit(params, ['uid']), addAuthToFetchOpts(accessToken, {})
  ).then(res => res);
}

export const useCloseTicketMutation = (fetcher = EditTicketFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['close-ticket'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: (_, params) => {
        queryClient.invalidateQueries('close-ticket')
        queryClient.invalidateQueries(['tickets', `/${params.uid}`])
      }
    }
  )
}

