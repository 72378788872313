import React from "react";
import {Card, CardBody} from "reactstrap";
import TargetVictimMaps from "../maps/EasyVectorMap/TargetVictimMaps";
import PropTypes from "prop-types";
import {ColorTagInlineBadgeList, CountryBadgeList} from "../badge/BadgeLists";

const TargetVictimCard = ({
  targetedCountries,
  targetedSectors = [],
  updateFilters,
  onCountryClick = null
}) =>{
  
  return (<Card>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>Targeted Countries</h4>
      </div>
      <CountryBadgeList updateFilters={updateFilters} countries={targetedCountries} size={'sm'}/>
      <div className={'mt-3'}><TargetVictimMaps
        onCountryClick={onCountryClick}
       countryCodes={targetedCountries}/></div>
      {targetedSectors.length > 0 && <ColorTagInlineBadgeList size={'sm'} items={targetedSectors.map((sector) => ({name: sector}))} /> }
    </CardBody>
  </Card>);
}

TargetVictimCard.propTypes = {
  targetedCountries: PropTypes.array.isRequired,
  targetedSectors: PropTypes.arrayOf(PropTypes.string),
  updateFilters:PropTypes.func.isRequired,
}

export default TargetVictimCard;
