import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import TimeLineChartCard from "../../../shared/components/charts/TimeLineChartCard";
import {useStatsActorsContextsByDay} from "../../../queries/Stats";
import moment from "moment/moment";


const ActorActivityHistogram = ({actorUid}) => {
  const {data, isIdle, isLoading, isError} = useStatsActorsContextsByDay(actorUid);
  const [dateArr, setDateArr] = useState([]);

  useEffect(() => {
    if (!isLoading && !isIdle && !isError && data.data) {
      setDateArr(data.data.map((s) => ({date: moment(s.day).toDate(), hits: s.count})));
    }
  }, [data?.data, isLoading, isIdle, isError])

  return (
    <TimeLineChartCard
      title={'Activities history'}
      dateTimeField={'date'}
      dataName={'Actor activities'}
      isLoading={isIdle || isLoading}
      isError={isError}
      items={dateArr}
    />
  )
}


ActorActivityHistogram.propTypes = {
  actorUid: PropTypes.array.isRequired
}


export default ActorActivityHistogram;
