import React from 'react';
import PropTypes from "prop-types";
import CloseIcon from 'mdi-react/CloseIcon';
import {NODE_TOOLTIP_CLASS} from "../../../../../shared/helpers/node-chart";
import ColorTagInlineBadge from "../../../../../shared/components/badge/ColorTagInlineBadge";
import {getIndStatsInfo} from "../../../../../shared/helpers/badge";


const TooltipHeader = ({node}) =>
  <div className="card__title">
    <button className="customizer__close-btn" type="button" onClick={
      () => {
        document.querySelector(NODE_TOOLTIP_CLASS).style.display = 'none';
      }
    }>
      <CloseIcon/>
    </button>
    <h4 className="bold-text">
      {node.label}
    </h4>

    {
      node.data?.stats &&
        <ColorTagInlineBadge
          color={getIndStatsInfo(node.data.stats, 'color')}
          tag={getIndStatsInfo(node.data.stats, 'maximumRisk')}
        />
    }

  </div>


TooltipHeader.propTypes = {
  node: PropTypes.shape().isRequired,
}

export default TooltipHeader;
