import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";
import _ from "lodash";


const IntelxFetcher = (accessToken, endpoint, urlParamsString) => {
  return axios.get(
    `${apiUrlInternal}/intel/${endpoint}${urlParamsString ? '?' + urlParamsString : ''}`,
    addAuthToAxiosOpts(accessToken, {}, 60000)
  ).then(res => res.data);
}


const useIntelxQuery = (
  endpoint = null,
  options = {staleTime: Infinity, refetchOnWindowFocus: false},
  urlParamsString = null,
  fetcher = IntelxFetcher
) => {
  return useQueryWithAuthentication(
    ['intelx', endpoint, urlParamsString],
    (accessToken) => fetcher(accessToken, endpoint, urlParamsString),
    options
    )
}


export const useIntelxSearchStatsQuery = (keyword) => {
  const urlParams = new URLSearchParams();
  urlParams.set('query', keyword);

  return useIntelxQuery(`stats`, {enabled: !!keyword, staleTime: Infinity}, urlParams.toString());
}


export const useIntelxSearchKeywordBySourceQuery = (keyword, source, offset, limit) => {
  const urlParams = new URLSearchParams();
  urlParams.set('query', keyword)
  urlParams.set('source', source)

  if (limit) {
    urlParams.set('limit', limit);
  }

  if (offset) {
    urlParams.set('offset', offset);
  }

  return useIntelxQuery(
    `search`,
    {staleTime: Infinity, refetchOnWindowFocus: false, enabled: !_.isUndefined(keyword) && !_.isUndefined(source)},
    urlParams.toString()
  );
}
