import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import IndicatorRiskScoreGauge from "../../IndicatorRiskScoreGauge";
import PropTypes from "prop-types";
import ContentTimeLineCard from "../../ContentTimeLineCard";
import BtcDetailsCard from "./components/BtcDetailsCard";
import IndicatorDetailFooter from "../../IndicatorDetailFooter";
import BtcReportTimeLineCard from "./components/BtcReportTimeLineCard";
import RelatedActorsCard from "../../RelatedActorsCard";
import FirstLastSeenRow from "../../FirstLastSeenRow";

const BtcDetails = ({indicator_details}) =>
  <Fragment>
    <Row>
      <Col lg={5} md={12}>
        <Row>
          <Col>
            <IndicatorRiskScoreGauge score={!indicator_details.whitelisted ? indicator_details.score : 0} tags={indicator_details.tags} whitelisted={indicator_details.whitelisted} />
            <FirstLastSeenRow last_seen={indicator_details.last_seen} first_seen={indicator_details.first_seen} />
            <RelatedActorsCard actors={indicator_details.actors} isKnown={indicator_details.is_known} />
            {indicator_details.related_contexts.length > 0 && <ContentTimeLineCard contexts={indicator_details.related_contexts}/>}
          </Col>
        </Row>
      </Col>

      <Col lg={7} md={12}>
        <Row>
          <Col>
            {indicator_details?.btcaddress_info && <BtcDetailsCard btcaddress_info={indicator_details.btcaddress_info}/>}
            {indicator_details.btcaddress_info?.recent?.length > 0 && <BtcReportTimeLineCard reports={indicator_details.btcaddress_info.recent}/>}
          </Col>
        </Row>
      </Col>
    </Row>


    <IndicatorDetailFooter indicator_details={indicator_details} />
  </Fragment>


BtcDetails.propTypes = {
  indicator_details: PropTypes.object.isRequired
}

export default BtcDetails;