import React from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import CveDetailsContent from "../../../../../../shared/components/cve/CveDetailsContent";


const IndicatorCVECard = ({cve, cveItems}) =>
  <Card className={'h-auto'}>
    <CardBody>
      <CveDetailsContent cve={cve} cveItems={cveItems} />
    </CardBody>
  </Card>


IndicatorCVECard.propTypes = {
  cve: PropTypes.object.isRequired,
  cveItems: PropTypes.object.isRequired
}

export default IndicatorCVECard;