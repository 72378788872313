import PropTypes from "prop-types";
import React, {Fragment, useState} from "react";
import {Button, Col, Row} from "reactstrap";
import CredentialExposure from "./CredentialExposure";
import BotNet from "./BotNet";
import Darknet from "./Darknet";
import IntelSect from "./IntelSect";


const IntelligenceIdentitySection = ({project_uid, project_name}) => {
  const CREDENTIALS_SECT = 0;
  const BOTNET_SECT = 1;
  const DARKNET_SECT = 2;
  const INTELLIGENCE_SECT = 3;
  const [section, setSection] = useState(CREDENTIALS_SECT);

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Button className="rounded" size="sm" outline active={section === CREDENTIALS_SECT}
                  onClick={() => setSection(CREDENTIALS_SECT)}>Credentials</Button>
          <Button className="rounded" size="sm" outline active={section === BOTNET_SECT}
                  onClick={() => setSection(BOTNET_SECT)}>BotNet</Button>
          <Button className="rounded" size="sm" outline active={section === DARKNET_SECT}
                  onClick={() => setSection(DARKNET_SECT)}>Darknet</Button>
          <Button className="rounded" size="sm" outline active={section === INTELLIGENCE_SECT}
                  onClick={() => setSection(INTELLIGENCE_SECT)}>Intelligence</Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {
            section === CREDENTIALS_SECT && <CredentialExposure project_uid={project_uid} project_name={project_name}/> ||
            section === BOTNET_SECT && <BotNet project_uid={project_uid} project_name={project_name}/> ||
            section === DARKNET_SECT && <Darknet project_uid={project_uid} project_name={project_name}/> ||
            section === INTELLIGENCE_SECT && <IntelSect project_uid={project_uid} project_name={project_name}/>
          }
        </Col>
      </Row>
    </Fragment>
  )
}


IntelligenceIdentitySection.propTypes = {
  project_uid: PropTypes.string.isRequired,
  project_name: PropTypes.string.isRequired,
}


export default IntelligenceIdentitySection;
