import React from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap"
import {Link, useHistory, useParams} from "react-router-dom";
import {sandboxTasksPath} from "../../config/paths";
import SandboxTaskReportSection from "./components/SandboxTaskReportSection"

import {
  areParamsValid,
  fixHistoryURL,
  isParamValid,
  useQueryParams
} from "../../shared/components/router/QueryNavigationHelpers";
import SandboxTaskFilesSection from "./components/SandboxTaskFilesSection";
import SandboxTaskRegistriesSection from "./components/SandboxTaskRegistriesSection";
import SandboxTaskNetworkSection from "./components/SandboxTaskNetworkSection";
import SandboxTaskBehaviouralSection from "./components/SandboxTaskBehaviouralSection";
import SandboxTaskDetectionSection from "./components/SandboxTaskDetectionSection";
import {PLATFORM_FEATURE_SANDBOX} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";


export const SECT_REPORT = "0";
export const SECT_FILES = "1";
export const SECT_REGISTRY = "2";
export const SECT_NETWORK = "3";
export const SECT_BEHAVIOURAL = "4";
export const SECT_DETECTION = "5";


const SandboxTaskDetails = () => {
  const history = useHistory();
  const params = useQueryParams();
  const requiredParams = {
    overview: [SECT_REPORT, SECT_FILES, SECT_REGISTRY, SECT_NETWORK, SECT_BEHAVIOURAL, SECT_DETECTION]
  }

  const overview = isParamValid(params.get('overview'), requiredParams.overview) ? params.get('overview') : SECT_REPORT;
  if (!areParamsValid(params, requiredParams)) {
    fixHistoryURL(params, requiredParams, history);
  }

  const {taskId} = useParams();

  return (
    <Page
      feature={PLATFORM_FEATURE_SANDBOX}
    >
      <Container className="dogma-boards-container">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Task Details</h3>
            <h3 className={'subhead mb-2'}>
              {taskId}
            </h3>
            <h3 className="page-subhead subhead">
              <Link to={`${sandboxTasksPath}`}>Back to Sandbox tasks</Link>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Link to={`?overview=${SECT_REPORT}`} className={"p-sm-1"}>
              <Button className="rounded" size="sm" outline active={overview === SECT_REPORT}>Report</Button>
            </Link>
            <Link to={`?overview=${SECT_FILES}`} className={"p-sm-1"}>
              <Button className="rounded" size="sm" outline active={overview === SECT_FILES}>Files</Button>
            </Link>
            <Link to={`?overview=${SECT_REGISTRY}`} className={"p-sm-1"}>
              <Button className="rounded" size="sm" outline active={overview === SECT_REGISTRY}>Registries</Button>
            </Link>
            <Link to={`?overview=${SECT_NETWORK}`} className={"p-sm-1"}>
              <Button className="rounded" size="sm" outline active={overview === SECT_NETWORK}>Network</Button>
            </Link>
            <Link to={`?overview=${SECT_BEHAVIOURAL}`} className={"p-sm-1"}>
              <Button className="rounded" size="sm" outline active={overview === SECT_BEHAVIOURAL}>Behavioural</Button>
            </Link>
            <Link to={`?overview=${SECT_DETECTION}`} className={"p-sm-1"}>
              <Button className="rounded" size="sm" outline active={overview === SECT_DETECTION}>Detection</Button>
            </Link>
          </Col>
        </Row>
        {
          (overview === SECT_REPORT) ? (
              <SandboxTaskReportSection taskId={taskId}/>
            ) :
            (overview === SECT_FILES) ? (
                <SandboxTaskFilesSection taskId={taskId}/>
              ) :
              (overview === SECT_REGISTRY) ? (
                  <SandboxTaskRegistriesSection taskId={taskId}/>
                ) :
                (overview === SECT_NETWORK) ? (
                    <SandboxTaskNetworkSection taskId={taskId}/>
                  ) :
                  (overview === SECT_BEHAVIOURAL) ? (
                      <SandboxTaskBehaviouralSection taskId={taskId}/>
                    ) :
                    (overview === SECT_DETECTION) ? (
                        <SandboxTaskDetectionSection taskId={taskId}/>
                      ) :
                      (
                        <Row><Col><Card><CardBody>ERROR! SHOULD NEVER REACH THIS!</CardBody></Card></Col></Row>
                      )
        }
      </Container>
    </Page>
  );
}

SandboxTaskDetails.propTypes = {}

export default SandboxTaskDetails;
