import React from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import {useRansomwareTrackerEventsPreferenceQuery} from "../../../queries/RansomwareTrackerEventsPreferences";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import RansomwareTrackerEventPreferencesForm from "../../../shared/components/form/RansomwareTrackerEventsPreferences";

const RansomwareTrackerPreferencesSection = () => {

  const {
    data: actorPreferenceData,
    isError: actorPreferenceIsError,
    isLoading: actorPreferenceIsLoading,
    isIdle: actorPreferenceIsIdle,
    error: actorPreferenceError
  } = useRansomwareTrackerEventsPreferenceQuery('actor');

  const {
    data: victimCountryPreferenceData,
    isError: victimCountryPreferenceIsError,
    isLoading: victimCountryPreferenceIsLoading,
    isIdle: victimCountryPreferenceIsIdle,
    error: victimCountryPreferenceError
  } = useRansomwareTrackerEventsPreferenceQuery('victim_country');

  if (actorPreferenceIsLoading || victimCountryPreferenceIsLoading || actorPreferenceIsIdle || victimCountryPreferenceIsIdle) {
    return <LoadingSpinner/>
  }

  if (actorPreferenceError || victimCountryPreferenceError) {
    return <ErrorHandler error={actorPreferenceError || victimCountryPreferenceError}/>
  }

  const defaultValues = {actor_preferences: actorPreferenceData?.data, country_preferences: victimCountryPreferenceData?.data}

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Ransomware Tracker Preferences</h4>
          <p className={'subhead'}>
          </p>
            <RansomwareTrackerEventPreferencesForm defaultValues={defaultValues}/>
        </div>
      </CardBody>
    </Card>
  )
}

RansomwareTrackerPreferencesSection.propTypes = {
  defaultValues: PropTypes.shape(),
}

export default RansomwareTrackerPreferencesSection;