import {Card, CardBody} from "reactstrap";
import LineChart from "./LineChart";
import React, {useMemo} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {formatDateUTCtoYearMonth, formatDateUTCtoYearMonthDay, getDaysArray, getMonthsArray} from "../../helpers/date";
import _ from "lodash";


const TimeLineChartCard = ({
  title,
  dataName,
  items,
  dateTimeField = 'created_dt',
  isLoading = false,
  isError = false,
  height = 150,
  tickY = false,
  monthlyScale = false,
  scale = 'linear',
  beginDate = moment().set({years: 2021, date: 1, month: 0}),
  CustomToolTip = null,
}) => {
  const charData = useMemo(() => {
    if (!isLoading && !isError) {
      const days = monthlyScale ? getMonthsArray(beginDate) : getDaysArray(beginDate);
      return days.map((d) => ({
        day: d,
        count: monthlyScale ?
          _.sumBy(items.filter((c) => formatDateUTCtoYearMonth(c[dateTimeField]) === formatDateUTCtoYearMonth(d)), o => o.hits) || (scale === 'log' ? 0.1 : 0) :
          _.sumBy(items.filter((c) => formatDateUTCtoYearMonthDay(c[dateTimeField]) === formatDateUTCtoYearMonthDay(d)), o => o.hits) || (scale === 'log' ? 0.1 : 0),
        tooltip: CustomToolTip ?
                  monthlyScale ?
                  items.filter((c) => formatDateUTCtoYearMonth(c[dateTimeField]) === formatDateUTCtoYearMonth(d)).flatMap(x => (x.tooltip)) :
                    items.filter((c) => formatDateUTCtoYearMonthDay(c[dateTimeField]) === formatDateUTCtoYearMonthDay(d)).flatMap(x => (x.tooltip)) : []
      }));
    } else {
      return []
    }
  }, [isError, isLoading, items])

  return (
    <Card className={'card--histogram-timeline'}>
      <CardBody>
        <LineChart
          data={charData}
          isLoading={isLoading}
          isError={isError}
          title={title}
          dataName={dataName}
          dataKey={'count'}
          height={height}
          tickY={tickY}
          scale={scale}
          CustomToolTip={CustomToolTip}
        />
      </CardBody>
    </Card>
  )
}


TimeLineChartCard.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  dateTimeField: PropTypes.object,
  subtitle: PropTypes.string,
  height: PropTypes.number,
  tickY: PropTypes.bool,
  monthlyScale: PropTypes.bool,
  dataKey: PropTypes.string,
  CustomToolTip: PropTypes.element,
}

export default TimeLineChartCard;
