import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useAllNotificationByUser} from "../../../queries/Notification";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import {Card, CardBody, Col, Row} from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";
import NotificationItem from "./NotificationItem";
import Filter from "../../../shared/components/Filter";
import _ from "lodash";
import {paramsToObject, useQueryParams} from "../../../shared/components/router/QueryNavigationHelpers";


const ReadNotificationTab = ({user_uid, notification_types}) => {
  const LIMIT = 30;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const params = paramsToObject(useQueryParams().entries());
  const [sources, setSources] = useState(
    params.sources ?
      _.isArray(params.sources) ? params.sources : [params.sources] :
      notification_types
  );
  const {data, isIdle, isLoading, isError, error} = useAllNotificationByUser(
    user_uid, LIMIT, offset, sources
  );


  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data) {
      if (data.data.length <= LIMIT) {
        setNotifications(notifications.concat(data.data));
      }
      setTotal(data.metadata.total);
    }
  }, [isIdle, isLoading, isError, data])


  useEffect(() => {
    if (_.isArray(params.sources)) {
      const filter_sources = [];
      _.forEach(params.sources, (source) => {
        if (notification_types.includes(source)) {
          filter_sources.push(source);
        }
      })

      if (sources.length > filter_sources) {
        setSources(filter_sources);
      }

    } else {
      if (notification_types.includes(params.sources) && sources.length !== 1) {
        setSources([params.sources]);
      }
    }
  }, [params.sources, notification_types])

  useEffect(() => {
    setNotifications([]);
    setOffset(0);
  }, [sources])

  useEffect(() => {
    if (!params.sources && _.isEmpty(sources) && notification_types) {
      setSources(notification_types)
    }
  }, [notifications, params])


  const loadMoreItems = () => {
    if (!isLoading && !isIdle && offset + LIMIT < total) {
      setOffset(offset + LIMIT);
    }
  }

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  return (
    <Row className={'m-0'}>
      <Col className={"justify-content-end col__notification-source-btn"}>
        <Filter
          items={notification_types}
          buttonText={'Source'}
          setItems={setSources}
          filterId={'allNotification'}
          preselectedFilters={sources}
        />
      </Col>

      <Card>
        <CardBody>
          {
            (isLoading || isIdle) && offset === 0 ?
              <LoadingSpinner/> :
              total > 0 ?
                <InfiniteScroll
                  loadMore={loadMoreItems}
                  hasMore={notifications.length < total}
                  loader={<LoadingSpinner/>}
                >
                  {
                    notifications.map((notification, index) => (
                      <NotificationItem
                        key={index}
                        notification={notification}
                      />
                    ))
                  }
                </InfiniteScroll> :
                <Col>
                  <h4>No new notification</h4>
                </Col>
          }
        </CardBody>
      </Card>
    </Row>
  )
}


ReadNotificationTab.propTypes = {
  user_uid: PropTypes.string.isRequired,
  notification_types: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default ReadNotificationTab;
