import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Col, Row} from "reactstrap";
import VirusTotalIndicatorLink from "../../../../../../shared/components/icons/VirusTotalIndicatorLink";
import MenuEntryWithCopyIcon from "../../../../../../shared/components/MenuEntryWithCopyIcon";


const IndicatorUrlCard = ({indicator}) =>
  <Card className={'h-auto'}>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>URL details</h4>
        <span className={'float-right'}><VirusTotalIndicatorLink value={indicator} /></span>
      </div>

      <Row className={'mx-md-0'}>
        <Col md={10}>
          <div className="custom_card__container m-0 p-0">
            <MenuEntryWithCopyIcon title={'URL'} value={indicator} />
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>


IndicatorUrlCard.propTypes = {
  indicator: PropTypes.string.isRequired
}


export default IndicatorUrlCard;
