import _ from "lodash";

class FileManagement {
  constructor() {
    this.toUpload = [];
    this.old = [];
  }
}

export const getFilesToUpload = (files, oldFiles) => {
  const result = new FileManagement();
  if (!files || files.length === 0) return result;
  _.forEach(files, (el) => {
    if (el.fileSize > 0) {
      result.toUpload.push({file: el.file, name: el.filename});
    } else if (oldFiles.length > 0) {
      const found = _.filter(oldFiles, {"file_name": el.filename})[0];
      result.old.push({file_id: found['file_id'], file_name: found['file_name']});
    }
  });
  return result;
}

export const fillAttachments = (attachments, files) => {
  _.forEach(files, (el) => {
    attachments.push({file_id: el['file_id'], file_name: el['file_name']});
  })
}