import React from 'react'
import HorizontalBarChartCard from "../../../../shared/components/charts/HorizontalBarChartCard";
import {useStatsMalwareFamiliesCommon} from "../../../../queries/Stats";
import {useHistory} from "react-router-dom";
import paths from "../../../../config/paths";
import PropTypes from "prop-types";


const MalwareFamiliesHorizontalBarChart = ({malwareFamiliesLimit = 5}) => {
  const history = useHistory();
  const { data, isIdle, isLoading, isError } = useStatsMalwareFamiliesCommon();
  const BAR_COLORS = [
    '#40e5e8', '#ff4861', '#f6da6e',
    '#c88ffa', '#4ce1b6', '#70bbfd',
    '#f198ca'
  ]

  return (
    <HorizontalBarChartCard
      data={data?.data?.slice(0, malwareFamiliesLimit)}
      dataKey={'num_indicators'}
      labelDataKey={'tag'}
      title={'MOST ACTIVE MALWARE FAMILIES'}
      isError={isError}
      isLoading={isLoading || isIdle}
      onBarClick={(item) =>
        history.push(`${paths.contentsPath}?malware_family=${item.tag}`)
      }
      barColors={BAR_COLORS}
    />
  )
}

MalwareFamiliesHorizontalBarChart.propTypes = {
  malwareFamiliesLimit: PropTypes.number
}

export default MalwareFamiliesHorizontalBarChart;
