import React, {useCallback, useEffect, useState} from "react";
import {Badge, Col, Container, Row, Tooltip} from "reactstrap";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import PropTypes from "prop-types";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import {HashLink} from "react-router-hash-link";
import DeleteIconModal from "../../../shared/components/modal/DeleteIconModal";
import toast from "react-hot-toast";
import ThemedClampLines from "../../../shared/components/clamp-lines/ThemedClampLines";
import _ from "lodash";
import TLPBadge from "../../../shared/components/badge/TLPBadge";
import {useDeleteTakedownTicket} from "../../../mutations/TakeDown/DeleteTicket";
import { getTicketStatus } from "../helper";
import TakeDownStatusBadges from "./TakeDownStatusBadges";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import {hasUserEditorPermissionOnFeature} from "../../../shared/helpers/permissions";
import {PLATFORM_FEATURE_TAKEDOWN_TICKETS} from "../../../shared/helpers/features";
import {getErrorMessageFromResponse} from "../../../shared/helpers";


const TakeDownHeader = ({ticket, toggle}) => {
  const mutation = useDeleteTakedownTicket();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const toggleTooltip = useCallback(() => setTooltipOpen(!tooltipOpen), [setTooltipOpen, tooltipOpen]);

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast.loading('Deleting ticket');
      } else {
        toast.dismiss();
        if (mutation.isSuccess) {
          toast.success('Ticket deleted successfully');
        } else if (mutation.isError) {
          toast.error(getErrorMessageFromResponse(mutation));
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.error, mutation.isLoading, mutation.isSuccess])

  const customHandle = (e) => {
    if (e.target.id !== "clamp-lines__button_id") {
      toggle();
    }
  }

  return (
    <Container className={'card-header__events'} style={{'position': 'relative'}} onClick={(e) => customHandle(e)}>
      <Row>
        <Col md={12}>
          <div className={"card__title__events"}>
            <h4 className={'bold-text'}>Takedown request related to {ticket.indicator}</h4>
            {
              ticket.created_by && ticket.created_dt &&
              <p className={'subhead'}>Created
                by <b>{ticket.created_by.email}</b> on <b>{formatDateUTCtoYearMonthDayTime(ticket.created_dt)}</b></p>
            }
            <p className="descriptionInfo">
              <ThemedClampLines
                text={ticket.description}
                lines={6}
                maxLines={12}
              />
            </p>
          </div>
        </Col>

        <TakeDownStatusBadges ticket={ticket} />

        {
          hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TAKEDOWN_TICKETS) &&
          <Col md={12} className={'d-flex justify-content-end mt-2'}>
            <DeleteIconModal
              onDelete={() => {
                mutation.mutate(ticket.uid);
              }}
              message={'Do you really want to delete the ticket?'}
            />
          </Col>
        }

      </Row>

      <HashLink
        to={`takedownservices/${ticket.uid}`}
      >
        <ChevronRightIcon
          outline={true}
          className={'float-right ticektRightIcon'} size={32}
          id={`content-${ticket.uid}`}
        />
      </HashLink>

      <Tooltip
        placement="left"
        isOpen={tooltipOpen}
        target={`content-${ticket.uid}`}
        toggle={toggleTooltip}
      >Go to the details
      </Tooltip>

    </Container>
  )
}

TakeDownHeader.propTypes = {
  ticket: PropTypes.shape().isRequired,
  toggle: PropTypes.func.isRequired,
}

export default TakeDownHeader;
