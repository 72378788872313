import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";


export const IndicatorDetailsFetcher = (
  accessToken, include_info= false, search_term= null, tags = [], types = [],
  start = null, end = null, actor = null, order_by = null, next = null, limit = null
) => {

  const urlParams = new URLSearchParams();
  if (include_info) {
    urlParams.set('include_info', true);
  }
  if (search_term) {
    urlParams.set('search_term', search_term);
  }
  if (start) {
    urlParams.set('start', start);
  }
  if (end) {
    urlParams.set('end', end);
  }
  if (actor) {
    urlParams.set('actor', actor);
  }
  if (order_by) {
    urlParams.set('order_by', order_by);
  }
  if (types) {
    types.forEach((type) => urlParams.append('types', type));
  }
  if (tags) {
    tags.forEach((tag) => urlParams.append('tags', tag));
  }
  if (next !== null) {
    urlParams.set('next', next);
  }
  if (limit !== null) {
    urlParams.set('limit', limit);
  }

  return axios.get(
    `${apiUrlInternal}/indicators?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {}, 60000)
  ).then(res => res.data);
}

export const useIndicatorDetailsQuery = (
  include_info= false, search_term = null, tags = null, types = null,
  start = null, end = null, actor= null, order_by = null, next = null, limit = null,
  fetcher = IndicatorDetailsFetcher
) => {
  return useQueryWithAuthentication(
    ['indicator-details', include_info, search_term, tags, types, start, end, actor, order_by, next, limit],
    (accessToken) => fetcher(accessToken, include_info, search_term, tags, types, start, end, actor, order_by, next, limit)
  )
}

useIndicatorDetailsQuery.propTypes = {
  include_info: PropTypes.bool,
  search_term: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  actor: PropTypes.string,
  tags: PropTypes.array,
  types: PropTypes.array,
};
