import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";

export const DeleteRansomwareTrackerEventsPreferencesFetcher = (
  accessToken, params
) => {
  const urlParams = new URLSearchParams();
  if (params.uid) {
    urlParams.set('uid', params.uid);
  }
  return axios.delete(`${apiUrlInternal}/ransomware_tracker_events/preferences?${urlParams.toString()}`,
    addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useDeleteRansomwareTrackerEventsPreferenceQuery = (
  fetcher = DeleteRansomwareTrackerEventsPreferencesFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-ransomware-tracker-events-preferences'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ransomware-tracker-events-preferences', 'victim_country'])
        queryClient.invalidateQueries(['ransomware-tracker-events-preferences', 'actor']);
      }
    }
  )
}
