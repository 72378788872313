import {getPattern} from "./validators";
import {sha256} from "js-sha256";

const _ = require("lodash");


const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777',
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd',
};

export const themes = {
  ligthTheme,
  darkTheme,
};

export const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

function getTooltipStyles(themeName, type) {
  switch (themeName) {
    case 'theme-dark': {
      const { backgroundColor, color } = darkTheme;
      return {
        contentStyle: { backgroundColor },
        itemStyle: type === 'defaultItems' ? null : { color },
      };
    }
    case 'theme-light': {
      return ligthTheme;
    }
    default: return ligthTheme;
  }
}


export const isDomain = (string) =>
  !!string.match(/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,63}$)/);


export const getElementPropertyOrDefault = (element, propertyName, defval) =>
  element && element[propertyName] ? element[propertyName] : defval;


export const copyTargetContent = (target) => {
  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = target;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}


export const getErrorMessageFromResponse = (mutation) => {
  const resp = mutation?.error?.response;

  let errorMessage;
  if (!resp) {
    errorMessage = 'unknown error. Please contact the administrator'
  } else if (resp?.data?.detail) {
    if (Array.isArray(resp.data.detail)) {
      errorMessage = Array.isArray(resp.data.detail)
      ? _.join(resp.data.detail.map((err) => err.msg), '. ')
      : resp.data.detail;
    } else {
      errorMessage = resp.data.detail;
    }
  } else  {
    errorMessage = resp?.data?.message ? resp.data.message : `${resp.status} - ${resp.statusText}`;
  }

  return errorMessage;
}

export const upperCaseFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);


export const bytesToSizeOrNA = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (!bytes) return 'N/A';

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

export const getVirusTotalLink = (value) => {
  let endpoint = '';

  if (getPattern('url').test(value)) {
    endpoint = `url/${sha256(value)}`;
  } else {
    endpoint = `search/${value}`;
  }

  return `https://www.virustotal.com/gui/${endpoint}`;
}


export const countByField = (arr, getValueFn) => {
  const res = {};

  _.forEach(arr, (val) => {
    if (!res[getValueFn(val)]) {
      res[getValueFn(val)] = 1;
    } else {
      ++res[getValueFn(val)];
    }
  });

  return res;
}


export const formatIocType = (iocType) => {
  if (iocType.toLowerCase().includes('address')) {
    return `${iocType.slice(0, 3).toUpperCase()} ADDRESS`;
  } else if (iocType.toLowerCase() === 'videntity') {
    return "VIRTUAL IDENTITY";
  } else {
    return iocType.toUpperCase();
  }
}


export const getCommonTags = (tags) =>
  !_.isUndefined(tags) && !_.isEmpty(tags) && !_.isNull(tags) ? tags.filter((tag) => !tag.category) : [];

export const getMalwareFamilyTags = (tags) =>
  !_.isUndefined(tags) && !_.isEmpty(tags) && !_.isNull(tags) ? tags.filter((tag) => tag.category === 'malware_family') : [];

export const getBotnetTags = (tags) =>
  !_.isUndefined(tags) && !_.isEmpty(tags) && !_.isNull(tags) ? tags.filter((tag) => tag.category === 'botnet') : [];

export const getCampaignTags = (tags) =>
  !_.isUndefined(tags) && !_.isEmpty(tags) && !_.isNull(tags) ? tags.filter((tag) => tag.category === 'campaign') : [];

export const stringToBoolean = (str) =>
  !_.isNull(str) && !_.isUndefined(str) && (_.lowerCase(str) === 'true' || _.toInteger(str) === 1)

export default getTooltipStyles;

export const flatten = (array, attribute) => {
  let result = [];
  array.forEach(function (a) {
    if (Array.isArray(a[attribute])) {
      result = result.concat(a[attribute]);
    }
  });
  return result;
}