import {useMutationWithAuthentication} from "./index";
import axios from "axios";
import {useQueryClient} from "react-query";
import { addAuthToFetchOpts } from "../config/queryopts";
import { apiUrlInternal } from "../config/api";


export const updateIntelligenceFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/intelligence?query=${params.q}`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useUpdateIntelligence = (fetcher = updateIntelligenceFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['intelligence-query'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries('intelligence-query');
        queryClient.invalidateQueries(['users-me']);
        queryClient.invalidateQueries(['organizations-intel-search', params.uid])
      }
    }
  )
}
