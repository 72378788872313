import axios from "axios";
import {useQueryClient} from "react-query";
import { useMutationWithAuthentication } from "..";
import { apiUrlInternal } from "../../config/api";
import { addAuthToFetchOpts } from "../../config/queryopts";


export const AddActorFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/actors`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useAddActorMutation = (fetcher = AddActorFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-actor'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => { 
      queryClient.invalidateQueries('add-actor')
      queryClient.invalidateQueries(['actor', 'detail', null]);
    }
  }
  )
}

