import axios from 'axios';
import { apiUrlInternal } from '../config/api';
import { addAuthToAxiosOpts } from '../config/queryopts';
import { useQueryWithAuthentication } from './index';
import { darkNetTypes, intelSectBuckets } from '../shared/helpers/intelligence';

const KeywordProjectResultsFetcher = (accessToken, project_uid, endpoint, urlParamsString) => {
  return axios.get(
    `${apiUrlInternal}/keyword_projects/${project_uid}/${endpoint}${urlParamsString ? '?' + urlParamsString : ''}`,
    addAuthToAxiosOpts(accessToken, {})
  )
    .then(res => res.data);
};

const useKeywordProjectResultsQuery = (
  endpoint = null, urlParamsString = '', fetcher = KeywordProjectResultsFetcher
) => {
  return useQueryWithAuthentication(
    ['keyword-project-results', endpoint, urlParamsString],
    (accessToken) => fetcher(accessToken, endpoint, urlParamsString),
    { refetchOnWindowFocus: false }
  );
};

export const useBotNetProjectResults = (project_uid, limit, offset, orderByFilter, orderBy) => {
  const urlParams = new URLSearchParams();

  urlParams.set('limit', limit);
  urlParams.set('offset', offset * limit);

  if (orderByFilter){
    urlParams.set('order_by', orderByFilter)
  }
  if (orderBy) {
    urlParams.set('order_direction', orderBy)
  }

  return useKeywordProjectResultsQuery(project_uid, `results/botnets?${urlParams.toString()}`);
};

export const useBotNetProjectResultsCount = (project_uid) =>
  useKeywordProjectResultsQuery(project_uid, 'results/botnets/count');

export const useCredentialExposureProjectResults = (project_uid, limit, offset, orderByFilter, orderBy) => {
  const urlParams = new URLSearchParams();

  urlParams.set('limit', limit);
  urlParams.set('offset', offset * limit);

  if (orderByFilter){
    urlParams.set('order_by', orderByFilter)
  }
  if (orderBy) {
    urlParams.set('order_direction', orderBy)
  }
  return useKeywordProjectResultsQuery(project_uid, `results/credentials_exposure?${urlParams.toString()}`);
};

export const useCredentialExposureProjectResultsCount = (project_uid) =>
  useKeywordProjectResultsQuery(project_uid, `results/credentials_exposure/count`);

export const useDarknetProjectResultsCount = (project_uid, type) => {
  const urlParams = new URLSearchParams();
  urlParams.set('types', type);
  return useKeywordProjectResultsQuery(project_uid, `results/darknets/count?${urlParams.toString()}`);
};

export const useDarknetResults = (project_uid, limit, offset, orderByFilter, orderBy, type) => {
  const urlParams = new URLSearchParams();
  urlParams.set('limit', limit);
  urlParams.set('offset', offset * limit);
  if (type) {
    const darknetType = darkNetTypes[type];
    urlParams.set('types', darknetType);
  }
  if (orderByFilter){
    urlParams.set('order_by', orderByFilter)
  }
  if (orderBy) {
    urlParams.set('order_direction', orderBy)
  }

  return useKeywordProjectResultsQuery(project_uid, `results/darknets?${urlParams.toString()}`);
};

export const useIntelResultsCount = (project_uid, type) => {
  const urlParams = new URLSearchParams();
  urlParams.set('buckets', type);
  return useKeywordProjectResultsQuery(project_uid, `results/intel/count?${urlParams.toString()}`);
};

export const useIntelResults = (project_uid, limit, offset, orderByFilter, orderBy, type) => {
  const urlParams = new URLSearchParams();
  urlParams.set('limit', limit);
  urlParams.set('offset', offset * limit);
  if (type) {
    const darknetType = intelSectBuckets[type];
    urlParams.set('buckets', darknetType);
  }
  if (orderByFilter){
    urlParams.set('order_by', orderByFilter)
  }
  if (orderBy) {
    urlParams.set('order_direction', orderBy)
  }
  return useKeywordProjectResultsQuery(project_uid, `results/intel?${urlParams.toString()}`);
};
