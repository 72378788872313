import React from "react";
import {useParams} from "react-router-dom";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import {getFlashAlertDefaultValue} from "../../../shared/helpers/contents";
import FlashAlertForm from "../../../shared/components/form/events-form/FlashAlertForm";
import {useEditFlashAlertMutation} from "../../../mutations/UpdateFlashAlert";
import {useFlashAlertDetailsQuery} from "../../../queries/FlashAlertDetails";
import env_const from "../../../config/env_const";
import NotAllowed401 from "../../DefaultPage/401";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_EDITOR} from "../../../shared/helpers/features";
import Page from "../../../shared/components/Page";
import LoadingSpinnerPage from "../../../shared/components/LoadingSpinnerPage";


const FlashAlertEdit = () => {
  const { uid:uid } = useParams();
  const { data, isError, isLoading, isIdle, error } = useFlashAlertDetailsQuery(uid);

  if (isLoading || isIdle) {
    return <LoadingSpinnerPage />
  }

  if (isError) {
    return <ErrorHandler error={error} />
  }

  if (env_const.is_clone && !data.data.is_private) {
    return <Page><NotAllowed401 /></Page>
  }

  const defaultValues = getFlashAlertDefaultValue(data.data);

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
    >
      <FlashAlertForm
        defaultValues={defaultValues}
        mutationFn={useEditFlashAlertMutation}
        uid={data.data.uid}
        editForm
      />
    </Page>
  )
}

export default FlashAlertEdit;
