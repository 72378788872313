import IndicatorsForm from "./IndicatorsForm";
import RulesForm from "./RulesForm";
import React, {Fragment} from "react";
import RelationshipsForm from "./RelationshipsForm";
import PropTypes from "prop-types";
import CommandsForm from "./CommandsForm";


const IocRuleRelCommandForm = ({control, errors, register, watch, setValue,showOnlyRules = false, showCommands = false}) => {
  return (
    <Fragment>
      { !showOnlyRules && <IndicatorsForm
        control={control}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
      />
      }

      <RulesForm
        control={control}
        register={register}
        errors={errors}
      />

    { !showOnlyRules && <RelationshipsForm
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
      />
    }
    { showCommands && <CommandsForm
      control={control}
      register={register}
      errors={errors}
    />
    }
    </Fragment>
  )
}


IocRuleRelCommandForm.propsType = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired
}


export default IocRuleRelCommandForm;
