import React, {Fragment, useEffect, useState} from "react"
import {Col, Container, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import logo from "../../../shared/img/eye.svg";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import AppError from "../../DefaultPage/AppError";
import {paramsToObject, useQueryParams} from "../../../shared/components/router/QueryNavigationHelpers";
import SearchBarOnEnter from "../../../shared/components/search-menu/SearchBarOnEnter";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import IndicatorDetail from "../components/IndicatorDetail";
import {useIndicatorDetailsByValueQuery} from "../../../queries/IndicatorInvestigate";
import {genericSectionsOnly, investigateNoIntelSections} from "../../../shared/helpers/investigate";
import paths from "../../../config/paths";
import ConfirmModal from "../../../shared/components/modal/ConfirmModal";

const _ = require('lodash');


const OnPremInvestigateDetail = () => {
  const params = paramsToObject(useQueryParams().entries());
  const [searchTerm, setSearchTerm] = useState(params.indicator);
  const [iocType, setIocType] = useState("");
  const [isEmptySearch, setIsEmptySearch] = useState(_.isUndefined(searchTerm));
  const [searchExternalSource, setSearchExternalSource] = useState(false)
  const history = useHistory();
  const {data, isIdle, isLoading, isError, error} = useIndicatorDetailsByValueQuery(
    searchTerm, iocType, true, searchExternalSource ? investigateNoIntelSections : genericSectionsOnly
  );

  useEffect(() => {
    if (searchTerm && params.indicator !== searchTerm) {
      setIocType("");
      setSearchExternalSource(false);
      history.push(`${paths.investigatePath}?indicator=${searchTerm}`);
    }
    setIsEmptySearch(!searchTerm);
  }, [searchTerm])

  useEffect(() => {
    setSearchTerm(params.indicator);
    setIocType("");
  }, [params.indicator])

  const indicator_details = data?.data;
  let ErrorHandlerForm = <Fragment/>
  if (isError) {
    if (error.response?.status === 400) {
      ErrorHandlerForm = <Row><Col><AppError header={"Insert a valid indicator"} msg={" "}/></Col></Row>
    } else {
      ErrorHandlerForm = <Row><Col><ErrorHandler error={error}/></Col></Row>
    }
  }

  return (
    <Fragment>
      {isEmptySearch &&
        <img src={logo} alt={'Investigate icon'} title={'Investigate icon'} className={"img__investigate-icon"}/>}
      <Container className={'dashboard'}>
        <Row>
          <Col md={12}>
            <SearchBarOnEnter
              searchPlaceholder={'Type an indicator and press enter'}
              setSearch={setSearchTerm}
              searchTerm={searchTerm}
              withSearchButton
            />
            {indicator_details?.guessed_types.length > 0 &&
              <Fragment>
                <p className={'mb-3'}>Indicator has been classified as <b>{indicator_details.indicator_type}</b>.
                  Search other results considering the indicator as
                  {
                    indicator_details.guessed_types.filter((guess_type) => guess_type !== indicator_details.indicator_type).map((type, index) =>
                      <Fragment>
                        {index !== 0 ? ', ' : ' '}
                        <a className={'link__with_decoration'} onClick={() => setIocType(type)}>
                          <u>{type}</u>
                        </a>
                      </Fragment>
                    )
                  }.
                </p>
              </Fragment>
            }
          </Col>
        </Row>
        {
          isError ? ErrorHandlerForm :
            isEmptySearch ?
              <Row>
                <Col>
                  <h2 className={"h2_investigate-title"}>Indicator investigation</h2>
                  <h3 className={"h3_investigate-subtitle"}>Try typing a domain name, an ipv4, an url or file hash to
                    discover all the threat intelligence information related to this indicator.</h3>
                </Col>
              </Row> :
              isIdle || isLoading ? <LoadingSpinner/> :
              <>
                <IndicatorDetail indicator_details={indicator_details}/>
              </>
        }

        {
          !(isIdle || isLoading) && !searchExternalSource &&
            <ConfirmModal
              onConfirm={() => setSearchExternalSource(true)}
              btnText={`Search information on external sources about ${indicator_details.indicator}`}
              title={"Use external sources"}
              body={`Are you sure to use investigate to search "${indicator_details.indicator}" on our external sources?`}
            />
        }

      </Container>
    </Fragment>
  )
}

export default OnPremInvestigateDetail;
