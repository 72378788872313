import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import PropTypes from "prop-types";
import {useCookieAccessToken} from "../../../queries";
import {
  downloadDnsSnortAutogeneratedRules,
  downloadIpsDnsSnortAutogeneratedRules,
  downloadIpSnortAutogeneratedRules
} from "../../helpers/download_file";


const DropdownMenuExportAutogeneratedRules = ({
  filters,
  totalIp,
  totalDomain,
  customRuleText = ''
}) => {
  const { data: accessToken } = useCookieAccessToken()

  return (
    <UncontrolledDropdown className={'float-right'}>
      <DropdownToggle className="icon icon--right outline mr-2" outline color={'success'} style={{height: "48px"}}>
        <p>Export Snort Rules <ChevronDownIcon /></p>
      </DropdownToggle>
      <DropdownMenu className="dropdown__menu__custom_small">
        {totalDomain > 0 && <DropdownItem onClick={() => downloadDnsSnortAutogeneratedRules(accessToken, {...filters, ...{limit: totalDomain}}, customRuleText)}>DNS Rules</DropdownItem>}
        {totalIp > 0 && <DropdownItem onClick={() => downloadIpSnortAutogeneratedRules(accessToken, {...filters, ...{limit: totalIp}}, customRuleText)}>IP Rules</DropdownItem>}
        {totalDomain > 0 && totalIp > 0 &&
          <DropdownItem onClick={() => downloadIpsDnsSnortAutogeneratedRules(accessToken, {...filters, ...{limit: totalDomain + totalIp}}, customRuleText)}>
            IP+ DNS Rules
          </DropdownItem>
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

DropdownMenuExportAutogeneratedRules.propType = {
  filters: PropTypes.array.isRequired,
  totalIp: PropTypes.number,
  totalDomain: PropTypes.number,
  customRuleText: PropTypes.string
}

export default DropdownMenuExportAutogeneratedRules;
