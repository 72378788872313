import React from "react";
import PropTypes from "prop-types";
import TypoSquattingForm from "../form/TypoSquattingForm";


const TypoSquattingProjectFormCollapseContent = ({
  project,
  assets,
  organization
}) =>
  <TypoSquattingForm
    project={project}
    assets={assets}
    organization={organization}
  />


TypoSquattingProjectFormCollapseContent.propTypes = {
  project: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired
}


export default TypoSquattingProjectFormCollapseContent;
