import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import React from "react";
import PropTypes from "prop-types";


const OrderDropDownMenu = ({
  orderMenu,
  orderBy,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        className="icon icon--right outline dropdown__toggle"
        outline 
        color={'success'}
      >
        <p className={'justify-content-between'}>Order by<ChevronDownIcon/></p>
      </DropdownToggle>
      <DropdownMenu className="dropdown__menu__custom">
        {
          orderMenu.map((item) =>
            <DropdownItem onClick={() => orderBy(item)} key={item.value}>{item.label}</DropdownItem>
          )
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
  
}

OrderDropDownMenu.propType = {
  orderMenu: PropTypes.array.isRequired,
  orderBy: PropTypes.func.isRequired,
}

export default OrderDropDownMenu;
