import PropTypes from "prop-types";
import PieChartMultipleItems from "../../../shared/components/charts/PieChartMultipleItems";
import React from "react";
import ColorTagInlineBadge from "../../../shared/components/badge/ColorTagInlineBadge";
import {Card, CardBody} from "reactstrap";
import {getIndStatsInfo} from "../../../shared/helpers/badge";


const ThreatStatsPieChart = ({stats}) => {
  return (
    <Card className={'h-auto'}>
      <CardBody>
        <PieChartMultipleItems
          data={getIndStatsInfo(stats, 'data')}
          title={
            <span>External Detection
              <span className={'float-right'}>
                <ColorTagInlineBadge
                  color={getIndStatsInfo(stats, 'color')}
                  tag={getIndStatsInfo(stats, 'maximumRisk')}/>
              </span>
            </span>
          }
          subtitle={"The risk score is based on external intelligence"}
          classNamePie={'dashboard__chart-pie--investigate-detail'}
        />
      </CardBody>
    </Card>
  )
}

ThreatStatsPieChart.propTypes = {
  stats: PropTypes.object.isRequired
}


export default ThreatStatsPieChart;
