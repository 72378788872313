

export const getActorListOrderMenu = () => [
  {label: 'Name', value: 'name'},
  {label: 'Origin Country', value: 'origin_country'},
  {label: 'Last seen', value: 'last_seen'},
];
export const getFeedlyFeedsListOrderMenu = () => [
  {label: 'Title', value: 'article_title'},
  {label: 'Author', value: 'source_title'},
  {label: 'First seen', value: 'date'},
];

export const getRansomwareTrackerEventsListOrderMenu = () => [
  {label: 'Actor', value: 'actor_name'},
  {label: 'Victim Country', value: 'victim_country'},
  {label: 'First seen', value: 'date'},
];

const getModifiedCreatedOrderMenu = () => [
  {label: 'Most recently created', value: 'created_desc'},
  {label: 'Less recent created', value: 'created_asc'},
  {label: 'Most recent modified', value: 'modified_desc'},
  {label: 'Less recent modified', value: 'modified_asc'},
]

export const getIndicatorsOrderMenu = () => [].concat(getModifiedCreatedOrderMenu());
export const getEventContentsOrderMenu = () => [].concat(getModifiedCreatedOrderMenu());
