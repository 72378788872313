import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";


export const ActivitiesFetcher = (
    accessToken, path = "", params = new URLSearchParams({})
) => {
    return axios.get(`${apiUrlInternal}/activities${path}?${params.toString()}`, addAuthToAxiosOpts(accessToken, {}))
        .then(res => res.data);
}

export const useActivitiesQuery = (
    limit = 0, start = null, end = null,
    actor = null, tags = [], order_by = null, search_term = null, tlps = [],
    targeted_sectors = [], targeted_countries = [], origin_countries = [],
    next = null, category = null, fetcher = ActivitiesFetcher
) => {
    const urlParams = new URLSearchParams();
    if (limit) {
        urlParams.set('limit', limit.toString());
    }
    if (start) {
        urlParams.set('start', start);
    }
    if (end) {
        urlParams.set('end', end);
    }
    if (actor) {
        urlParams.set('actor', actor);
    }
    if (order_by) {
        urlParams.set('order_by', order_by);
    }
    if (search_term) {
        urlParams.set('search_term', search_term);
    }
    if (tags) {
        tags.forEach((tag) => urlParams.append('tags', tag));
    }
    if (targeted_countries) {
        targeted_countries.forEach((country) => urlParams.append('targeted_countries', country));
    }
    if (targeted_sectors) {
        targeted_sectors.forEach((sector) => urlParams.append('targeted_sectors', sector));
    }
    if (tlps) {
        tlps.forEach((tlp) => urlParams.append('tlps', tlp));
    }
    if (next !== null) {
        urlParams.set('next', next);
    }
    if (category !== null) {
        urlParams.set('category', category);
    }

    return useQueryWithAuthentication(
        ['activities',
            limit, start, end, actor, tags, order_by, search_term, tlps, targeted_sectors,
            targeted_countries, origin_countries, next, category],
        (accessToken) => fetcher(
            accessToken, "", urlParams
        ), {cacheTime: 0}
    )
}


export const useActivityDetailsQuery = (uid = null, fetcher = ActivitiesFetcher) => {
    return useQueryWithAuthentication(
        ['activities', uid, 'details'],
        (accessToken) => fetcher(accessToken, `/${uid}`))
}

useActivityDetailsQuery.propTypes = {
    uid: PropTypes.string.isRequired
};
