import {Button, Col, Row} from "reactstrap";
import CloseIcon from "mdi-react/CloseIcon";
import ControlledSelectWithTitle from "../../ControlledSelectWithTitle";
import ControlledCreatableSelectWithTitle from "../../ControlledCreatableSelectWithTitle";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useFieldArray} from "react-hook-form";
import classNames from "classnames";
import {useAddContentFormQuery} from "../../../../../queries/AddContentForm";
import {getTagOptionsByTypes} from "../../../../helpers/contents";
import {flatten} from "../../../../helpers";
import TagControlledSelect from "../../components/TagControlledSelect";


const _ = require('lodash')


const RulesForm = ({control, errors, register}) => {
  let isErrorIdleLoading = false;
  const {
    ruleTypes: {data: dataRuleTypes, ...ruleTypesProps},
    tags: {data: dataTags, ...tagsProps},
    mitre: {data: dataMITRE, ...mitreTechniquesProps},
  } = useAddContentFormQuery();

  _.forEach([ruleTypesProps, tagsProps, mitreTechniquesProps], (props) => {
    isErrorIdleLoading |= props.isError || props.isLoading || props.isIdle;
  })

  const { fields: fieldsRules, append: appendRules, remove: removeRules } = useFieldArray(
    {control, name: "rules"}
  );

  if (isErrorIdleLoading) {
    return <Fragment />
  }
  const mitreTechniques = flatten(dataMITRE.data, 'techniques').map((technique) => ({
    value: technique.uid,
    label: `${technique.mitre_code} - ${technique.name}`
  }))
  const ruleTypesOptions = dataRuleTypes.data.map((type) => ({value: type, label: type}));
  const [commonTagOptions, malwareFamilyTagOptions, ] = getTagOptionsByTypes(dataTags.data);

  return (
    <Fragment>
      {
        fieldsRules.map((item, index) =>
          <Row className={'w-100'} key={item.id}>
            <Col md={3} lg={3} xl={2}>
              <Row>
                <Col md={2} className={'d-flex flex-column justify-content-center'}>
                  <CloseIcon
                    style={{fill: 'white', cursor: 'pointer'}}
                    onClick={() => removeRules(index)}
                  />
                </Col>
                <Col md={10}>
                  <ControlledSelectWithTitle
                    name={`rules.${index}.type`}
                    title={'Type'}
                    control={control}
                    options={ruleTypesOptions}
                    valueFn={(value) => ruleTypesOptions.find(c => c.value === value?.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={3} lg={3} xl={3}>
              <div className={classNames({'form__form-group': true, "form__form-group-error-validation": errors.title})}>
                <span className="form__form-group-label">Title</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <input {...register(`rules.${index}.title`, {required: 'The title of the rule is required'})} />
                    {
                      errors?.rules && errors.rules[index] && errors.rules[index].title
                      && <span className="form__form-group-error">{errors.rules[index].title.message}</span>
                    }
                  </div>
                </div>
              </div>
              <div className="form__form-group">
                <TagControlledSelect
                  control={control}
                  commonTagOptions={commonTagOptions}
                  name={`rules.${index}.tags`}
                  isClearable
                />
                <ControlledSelectWithTitle
                  name={`rules.${index}.malware_families`} title={'Malware Families'} control={control}
                  isClearable isSearchable isMulti valueFn={(value) => value}
                  options={malwareFamilyTagOptions}
                />
                <ControlledSelectWithTitle
                  name={`rules.${index}.techniques`} title={'Mitre Techniques'} control={control}
                  isSearchable isMulti isClearable valueFn={(value) => value}
                  options={mitreTechniques}
                />
              </div>
            </Col>
            <Col md={6} lg={6} xl={7}>
              <div className={classNames({
                'form__form-group': true,
                "form__form-group-error-validation": errors?.rules && errors.rules[index] && errors.rules[index].text
              })}>
                <span className="form__form-group-label">Rule body</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    <textarea
                      {...register(`rules.${index}.text`, {required: 'The body of the rule is required'})}
                      className={'textarea--rule-body'}
                    />
                    {
                      errors?.rules && errors.rules[index] && errors.rules[index].text
                      && <span className="form__form-group-error">{errors.rules[index].text.message}</span>
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )
      }
      <Row className={'justify-content-end w-100'}>
        <Button outline color={'success'} onClick={() => appendRules({type: ruleTypesOptions[0]})}>
          Add rule
        </Button>
      </Row>
    </Fragment>
  )
}


RulesForm.propsType = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  register: PropTypes.func.isRequired,
}


export default RulesForm;
