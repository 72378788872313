import React from "react";
import AlphaAIcon from "mdi-react/AlphaAIcon";
import {useHistory} from "react-router-dom";
import ThemedFabMenu from "../../../shared/components/ThemedFabMenu";
import paths from "../../../config/paths";


const FabActorMenu = () => {
  const history = useHistory();
  const actions = [
    {
      text: "Add Actor",
      icon: <AlphaAIcon/>,
      onClick: () => history.push(paths.actorAddPath)
    }
  ];

  return (
    <ThemedFabMenu actions={actions}/>
  );
}


export default FabActorMenu;
