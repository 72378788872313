import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import {getRoutePath} from '../topbar_with_navigation/tobar_nav/TopbarNav';
import {MANAGEMENT, REPORT_AND_ANALYSIS, SURFACE_ATTACK_MANAGEMENT, TRIAGE} from "../../../shared/helpers/modules";
import {
  PLATFORM_FEATURE_CONTENTS,
  PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS,
  PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS,
  PLATFORM_FEATURE_TAKEDOWN_TICKETS
} from "../../../shared/helpers/features";
import paths from "../../../config/paths";
import env_const from "../../../config/env_const";
import {canAccessModule} from "../layoutUtils";

const _ = require('lodash');

const SidebarContent = ({sidebarCollapse, user, modules}) => {
  const hideSidebar = (e) => {
    e.stopPropagation();
  };

  const getRouteIcon = (name) => {
    let icon = 'eye';

    switch (name) {
      case REPORT_AND_ANALYSIS:
        return 'bullhorn';
      case TRIAGE:
        return 'bug';
      case SURFACE_ATTACK_MANAGEMENT:
        return 'earth';
      case MANAGEMENT:
        return 'cog';
    }

    return icon;
  }

  let userModules = user?.modules;
  let allowMitreFeatures = false;
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink title="Dashboard" exact route="/" icon="home" onClick={hideSidebar}/>
        {
          userModules && modules && modules.map((m) => {
            if (!canAccessModule(user, m.name)) return <></>;
            const userModule = _.find(userModules, {name: m.name});
            return (
              m.features && m.features.length === 1 ?
                <SidebarLink
                  title={_.capitalize(m.features[0].value)}
                  route={getRoutePath(m.features[0].name)}
                  icon={getRouteIcon(m.features[0].name)}
                />
                :
                <SidebarCategory
                  title={_.capitalize(m.value)} icon={getRouteIcon(m.name)}
                  sidebarCollapse={sidebarCollapse}
                >
                  {m.features && m.features.map((f, index) => {
                    const sidebarLinks = [];
                    const userFeature = !!(_.find(_.get(userModule, 'features', []), {name: f.name}));
                    const icon = !userFeature ? 'lock' : ''
                    if (f.name === PLATFORM_FEATURE_CONTENTS && userFeature) allowMitreFeatures = true
                    sidebarLinks.push(
                      <SidebarLink
                        title={_.capitalize(f.value)}
                        route={getRoutePath(f.name)}
                        disabled={!userFeature}
                        icon={icon}
                      />
                    )

                    if (f.name === PLATFORM_FEATURE_CONTENTS) {
                      if (env_const.is_on_prem) {
                        sidebarLinks.push(
                          <>
                            <SidebarLink
                              title={'Activities'}
                              route={paths.activitiesPath}
                              disabled={!userFeature}
                              icon={icon}
                            />
                            <SidebarLink
                              title={'Malware Families'}
                              route={paths.malwareFamilyBasePath}
                              disabled={!userFeature}
                              icon={icon}
                            />
                          </>
                        )
                      } else {
                        sidebarLinks.push(
                          <>
                            <SidebarLink
                              title={'Activities'}
                              route={paths.activitiesPath}
                              disabled={!userFeature}
                              icon={icon}
                            />
                            <SidebarLink
                              title={'Ransomware Tracker'}
                              route={paths.ransomwareTrackerEventsPath}
                              disabled={!userFeature}
                              icon={icon}
                            />
                            <SidebarLink
                              title={'News Feed'}
                              route={paths.feedlyFeedsPath}
                              disabled={!userFeature}
                              icon={icon}
                            />
                            <SidebarLink
                              title={'Malware Families'}
                              route={paths.malwareFamilyBasePath}
                              disabled={!userFeature}
                              icon={icon}
                            />
                          </>
                        )
                      }
                    } else if (m.name === 'triage' && m.features.length === index+1) {
                      const icon = !allowMitreFeatures ? 'lock' : ''
                      sidebarLinks.push(
                        <>
                          <SidebarCategory title="Mitre">
                              <SidebarLink
                                title={'Commands'} route={paths.commandsPath} icon={icon} disabled={!allowMitreFeatures}/>
                            <SidebarLink
                              title={'Rules'} route={paths.rulesSectionPath} icon={icon} disabled={!allowMitreFeatures}/>
                          </SidebarCategory>
                        </>)
                    } else if  (
                      env_const.is_on_prem && (
                        [
                          PLATFORM_FEATURE_TAKEDOWN_TICKETS, PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS,
                          PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS
                        ].includes(f.name)
                      )
                    ) {
                      return <></>
                    }

                    return sidebarLinks
                  })}
                </SidebarCategory>
            )
          })
        }
      </ul>
      <ul className="sidebar__block">
        <SidebarLink title="Log Out" icon="exit" route="/logout"/>
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
