import CountryBadge from "./CountryBadge";
import React from "react";
import PropTypes from "prop-types";
import ColorTagInlineBadge from "./ColorTagInlineBadge";
import ColorTagInlineBadgeWithLink from "./ColorTagInlineBadgeWithLink";


const _ = require('lodash');


export const CountryBadgeList = ({
  countries,
  size = '',
  space = ' ',
  flagOnly = false,
  updateFilters = null
}) => {
  if (countries.length === 0) {
    return null;
  }

  const className = !_.isNull(updateFilters) ? 'cursor' : '';
  const onClick = !_.isNull(updateFilters) ? updateFilters : () => {};

  return countries.map((countryCode) =>
    <span className={className} onClick={()=> onClick('targeted_countries', countryCode)}>
      <CountryBadge countryCode={countryCode} size={size} flagOnly={flagOnly} key={countryCode} />
    </span>
  ).reduce((prev, curr) => [prev, space, curr])
}

CountryBadgeList.propType = {
  countries: PropTypes.array.isRequired,
  size: PropTypes.string,
  space: PropTypes.string,
  flagOnly: PropTypes.bool,
  updateFilters:PropTypes.func.isRequired,
}


export const ColorTagInlineBadgeList = ({
  items,
  color = 'secondary',
  size = '',
  space = ' ',
  onTagClick = () => {
  },
  type=''
}) => {
  if (items.length === 0) {
    return null;
  }
  return items.map((item) =>
    <span className="cursor" onClick={() => { onTagClick(type === 'targeted_sectors' ? item.id : item.name) }}>
    <ColorTagInlineBadge color={color} tag={item.name} size={size} key={item.name} />
    </span>
  ).reduce((prev, curr) => [prev, space, curr])
}


ColorTagInlineBadgeList.propType = {
  items: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.string,
  space: PropTypes.string,
  onTagClick:PropTypes.func,
  type:PropTypes.string,
}


export const ColorTagInlineBadgeWithLinkList = ({items, url, color = 'secondary', size = '', space = ' '}) => {
  if (items.length === 0) {
    return null;
  }

  return items.map((item) =>
    <ColorTagInlineBadgeWithLink
      color={color} url={url} tag={item} size={size} key={item.name}
    />
  ).reduce((prev, curr) => [prev, space, curr])
}


ColorTagInlineBadgeWithLinkList.propType = {
  items: PropTypes.array.isRequired,
  url: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.string,
  space: PropTypes.string,
}


