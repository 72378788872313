import React from "react";
import ColorTagInlineBadge from "../../badge/ColorTagInlineBadge";
import {getConfidenceByLevel} from "../../../helpers/confidence";
import TLPBadge from "../../badge/TLPBadge";
import {formatDateUTCtoYearMonthDayTime} from "../../../helpers/date";
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";
import { getTlpIndex } from "../../../helpers/tlp";
import paths from "../../../../config/paths";
import {Link} from "react-router-dom";

const ContentCardHeader = ({title, created_dt, tlp, confidence = null,updateFilters, uid = null}) => {
  return (
    <div className="card__title">
      <Row>
        <Col md={8}>
          {uid ? <Link to={`${paths.analysisPath}/${uid}`}>
            <h4 className={'bold-text d-inline'} style={{textTransform:'none'}}>{title}</h4>
          </Link> :
            <h4 className={'bold-text d-inline'} style={{textTransform:'none'}}>{title}</h4>
          }
          <p className={'subhead'}>{created_dt && formatDateUTCtoYearMonthDayTime(created_dt)}</p>
        </Col>
        <Col md={4}>
          <span className={'float-right'}>
            {confidence !== null &&
            <ColorTagInlineBadge tag={`CONFIDENCE:${getConfidenceByLevel(confidence)}`} color={'secondary'} size={'sm'}/>} 
            {tlp &&<span className="cursor" onClick={() => updateFilters('tlps', getTlpIndex(tlp))}> <TLPBadge tlp={tlp} size={'sm'}/> </span>}
          </span>
        </Col>
      </Row>
    </div>
  )
}


ContentCardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  confidence: PropTypes.number,
  created_dt: PropTypes.string.isRequired,
  tlp: PropTypes.string.isRequired,
  updateFilters:PropTypes.func.isRequired,
  uid: PropTypes.bool
}

export default ContentCardHeader;
