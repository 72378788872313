import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Col, Container, Row} from "reactstrap";
import InfiniteScroll from 'react-infinite-scroller';
import _ from "lodash";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import {useTagsQuery} from "../../queries/Tags";
import {getActivitiesFilterMenu} from "../../shared/helpers/filter_menu";
import {getEventContentsOrderMenu} from "../../shared/helpers/order_menu";
import {useActorDetailsQuery} from "../../queries/Actor";
import FabActivitiesMenu from "./components/FabActivitiesMenu";
import SearchMenu from "../../shared/components/form/SearchMenu";
import {useCountriesQuery} from "../../queries/Countries";
import {useSectorsListQuery} from "../../queries/SectorsList";
import {canUserCreateContent, canUserEditContent} from "../../shared/helpers/permissions";
import {paramsToObject, useQueryParams} from "../../shared/components/router/QueryNavigationHelpers";
import {useMitreTTPSQuery} from '../../queries/MitreTTPS';
import paths from '../../config/paths';
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";
import {useActivitiesQueryWithDict} from "./queries/SearchActivity";
import ActivityDetailCard from "./components/ActivityDetailCard";


const Activities = () => {
  const h = useHistory();
  const params = paramsToObject(useQueryParams().entries());
  const LOADING_STEP = 3;
  const INITIAL_ITEMS = 4;
  const PAGE_SIZE = 4;
  const [loadedItems, setLoadedItems] = useState(INITIAL_ITEMS);
  const [next, setNext] = useState(null);
  const [totalActivities, setTotalActivities] = useState(0);
  const [show, setShow] = useState(false);
  const [displayActivityArray, setDisplayActivityArray] = useState([]);
  const [activityArray, setActivityArray] = useState([]);
  const [forceUpdateFilters, setForceUpdateFilters] = useState(false);
  const {
    data: dataTags,
    isIdle: isIdleTags,
    isLoading: isLoadingTags,
    isError: isErrorTags
  } = useTagsQuery();
  const {
    data: dataActors,
    isIdle: isIdleActors,
    isLoading: isLoadingActors,
    isError: isErrorActors
  } = useActorDetailsQuery();
  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();
  const {
    data: dataCountries,
    isIdle: isIdleCountries,
    isLoading: isLoadingCountries,
    isError: isErrorCountries
  } = useCountriesQuery();
  const {
    data: dataSectors,
    isIdle: isIdleSectors,
    isLoading: isLoadingSectors,
    isError: isErrorSectors
  } = useSectorsListQuery();
  const {
    data: dataMitre,
    isIdle: isIdleMitre,
    isLoading: isLoadingMitre,
    isError: isErrorMitre
  } = useMitreTTPSQuery();
  const [filters, setFilters] = useState({});
  const {
    data,
    isIdle,
    isLoading,
    isError,
    error
  } = useActivitiesQueryWithDict(
    Object.assign({}, filters, {next: next, limit: PAGE_SIZE})
  );

  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data) {
      setTotalActivities(data.metadata.total);

      if (!next && displayActivityArray.length === 0) {
        setActivityArray(activityArray.concat(data.data));
        setDisplayActivityArray(displayActivityArray.concat(data.data));
        setLoadedItems(INITIAL_ITEMS);
      } else {
        setActivityArray(activityArray.concat(data.data));
        setDisplayActivityArray(displayActivityArray.concat(data.data));
        setLoadedItems(loadedItems + LOADING_STEP);
      }
    }
  }, [isIdle, isLoading, isError, data])


  const isLoadingIdleErrorMenu = isLoadingTags || isErrorTags || isIdleTags || isIdleActors
    || isLoadingActors || isErrorActors || isIdleCurrentUser || isLoadingCurrentUser || isErrorCurrentUser
    || isLoadingSectors || isIdleSectors || isLoadingCountries || isIdleCountries || isErrorCountries || isErrorSectors
    || isIdleMitre || isLoadingMitre || isErrorMitre;

  const isLoadingIdleMenu = isLoadingTags || isIdleTags || isIdleActors || isLoadingActors || isIdleCurrentUser ||
    isLoadingCurrentUser || isLoadingSectors || isIdleSectors || isLoadingCountries || isIdleCountries;

  const ORDER_MENU = getEventContentsOrderMenu();
  const FILTER_MENU = useMemo(
    () => (!isLoadingIdleErrorMenu) ?
      getActivitiesFilterMenu(
        dataTags, dataActors, dataSectors, dataCountries, _.max([dataCurrentUser.data.tlp.id, dataCurrentUser.data.private_tlp.id])
      ) : [],
    [isLoadingIdleErrorMenu]
  );

  useEffect(() => {
    if (isIdle || isLoading || !data) {
      setNext(null);
      setShow(false);
      setDisplayActivityArray([]);
      setActivityArray([]);
      setTimeout(() => {
        setShow(true)
      }, 1000)
    }
  }, [filters])

  const loadMoreItems = () => {
    if (activityArray.length > loadedItems + LOADING_STEP) {
      setDisplayActivityArray(activityArray.slice(0, loadedItems + LOADING_STEP));
      setLoadedItems(loadedItems + LOADING_STEP);
    } else if (activityArray.length === totalActivities) {
      setDisplayActivityArray(activityArray);
      setLoadedItems(totalActivities);
    } else if (data?.metadata.next) {
      setNext(data?.metadata?.next);
    }
  }

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  if ((isIdleTags || isLoadingTags || isIdleActors || isLoadingActors || isIdleCurrentUser || isLoadingCurrentUser || isLoadingIdleMenu)) {
    return <LoadingSpinnerPage/>
  }

  const updateFilters = (display_value, selected_field = null) => {
    let filterSelected = null;

    if (!selected_field) {
      let index = -1;
      FILTER_MENU.forEach(
        (filter, i) => {
          if (filter.values.map((v) => filter.displayValueFn(v)).includes(display_value)) {
            index = i;
          }
        }
      )

      if (index < 0) {
        return
      }

      filterSelected = FILTER_MENU[index];
    } else {
      filterSelected = FILTER_MENU.find((filter) => filter.field === selected_field);
    }

    const filter_field = filterSelected.field;
    const filter_value = filterSelected.values.find((v) => filterSelected.displayValueFn(v) === display_value);

    if (filters[filter_field]?.includes(filter_value)) {
      return;
    }

    if (!filterSelected.multi) {
      filters[filter_field] = filter_value;
    } else {
      if (filters[filter_field]) {
        filters[filter_field].push(filter_value);
      } else {
        filters[filter_field] = [filter_value];
      }
    }

    const urlParams = new URLSearchParams();

    _.entries(filters).forEach(([filter_field, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => urlParams.append(filter_field, v));
      } else {
        urlParams.set(filter_field, value)
      }
    });

    setForceUpdateFilters(true);
    h.replace(`${paths.activitiesPath}?${urlParams}`);
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
    >
      <Container className={"dashboard"}>
        {canUserCreateContent(dataCurrentUser?.data) && <FabActivitiesMenu/>}
        <div className={'div__sticky-top mb-3'}>
          <Row>
            <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto'}>
              <SearchMenu
                setFilters={setFilters}
                baseUrl={paths.activitiesPath}
                forceUpdateFilters={forceUpdateFilters}
                setForceUpdateFilters={setForceUpdateFilters}
                defaultFilters={params}
                searchPlaceholder={'Search activity...'}
                orderMenu={ORDER_MENU}
                filterMenu={FILTER_MENU}
                fromDateField={'start'}
                toDateField={'end'}
                searchField={'search_term'}
              />
            </Col>
          </Row>
        </div>
        {
          (isIdle || isLoading || !show) && displayActivityArray.length === 0 ?
            <LoadingSpinner/> :
            <Row>
              <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto'}>
                {
                  activityArray.length > 0 &&
                  <InfiniteScroll
                    loadMore={loadMoreItems}
                    hasMore={loadedItems < totalActivities}
                    loader={<LoadingSpinner/>}
                  >
                    {
                      displayActivityArray.map((i, index) => (
                        <ActivityDetailCard
                          key={index}
                          activity={displayActivityArray[index]}
                          updateFilters={updateFilters}
                        />
                      ))
                    }
                  </InfiniteScroll>
                }
              </Col>
            </Row>
        }
      </Container>
    </Page>
  )
}


export default Activities;
