import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import paths from "../../../../config/paths";
import toast from 'react-hot-toast';
import LoadingSpinner from "../../LoadingSpinner";
import ErrorHandler from "../../ErrorHandler";
import {getErrorMessageFromResponse} from "../../../helpers";
import PropTypes from "prop-types";
import {useAddActorMutation} from "../../../../mutations/Actor/AddActor";
import RegisteredInputField from "../RegisteredInputField";
import MitreTableSelectionCard from "../events-form/components/MitreTableSelectionCard";
import ControlledSelectWithTitle from "../ControlledSelectWithTitle";
import {getCountryNameOrCodeShort} from "../../../helpers/country-data";
import ReactCountryFlag from "react-country-flag";
import ControlledCreatableSelectWithTitle from "../ControlledCreatableSelectWithTitle";
import DatePickerWithLabel from "../DatePickerWithLabel";
import RegisteredCheckBoxField from "../RegisteredCheckBoxField";
import moment from "moment";
import ControlledTextAreaWithTitle from "../ControlledTextAreaWithTitle";
import {useAddActorFormQuery} from "../../../../queries/AddActorForm";
import ControlledMultiSelectTextWithTitle from "../ControlledMultiSelectTextWithTitle";


const _ = require('lodash')


const ActorForm = ({
  defaultValues = {},
  mutationFn = useAddActorMutation,
  actorUid,
  editForm = false,
}) => {
  let isIdleLoading = false;
  let isError = false;

  const mutation = mutationFn();
  const history = useHistory();
  const [ttpsSelected, setTtpsSelected] = useState(defaultValues.techniques ? defaultValues.techniques : []);
  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);
  const {register, handleSubmit, formState: {errors}, control, watch, clearErrors} = useForm({
    defaultValues: defaultValues
  });
  const [firstSeen, setFirstSeen] = useState(defaultValues.first_seen && moment(defaultValues.first_seen).toDate());

  const {
    countries: {data: dataCountries, ...countriesProps},
    sectors: {data: dataSectors, ...sectorsProps},
    motivations: {data: mData, ...mDataProps},
    associateMalwares: {data: asData, ...asDataProps},
  } = useAddActorFormQuery();


  const onSubmit = (data) => {
    const params = {
      name: data.name,
      description: data.description,
      origin_country: data.origin_country && data.origin_country.iso_code ? data.origin_country.iso_code : null,
      first_seen: firstSeen,
      last_seen: null,
      state_sponsored: data.state_sponsored,
      motivations: data.motivations ? data.motivations.map((tag) => tag.label) : [],
      associated_malwares: data.associated_malwares ? data.associated_malwares.map((tag) => tag.label) : [],
      techniques: ttpsSelected ? ttpsSelected : [],
      targeted_countries: data.targeted_countries ? data.targeted_countries.map((tag) => tag.iso_code) : [],
      targeted_sectors: data.targeted_sectors ? data.targeted_sectors.map((tag) => tag.value) : [],
      aliases: data.aliases ? data.aliases.map((tag) => tag.label) : [],
    };
    if (editForm) {
      toast.loading(`Checking and updating an actor`);
      mutation.mutate({params: params, actorId: actorUid});
    } else {
      toast.loading(`Checking and creating an actor`);
      mutation.mutate(params);
    }
  }

  useEffect(() => {
    if (!mutation.isIdle && mutation.isLoading && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }

    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        toast.success(`Actor Added correctly, you will be redirect.`);
        const targetPath = actorUid ? `${paths.actorPath}/${actorUid}` : `${paths.actorPath}/${mutation.data.data.uid}`;
        setTimeout(() => {
          history.push(targetPath);
          toast.dismiss();
        }, 2000);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
        setButtonUploadDisabled(false);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])

  _.forEach([countriesProps, mDataProps, asDataProps, sectorsProps], (props) => {
    isIdleLoading |= props.isLoading || props.isIdle;
    isError |= props.isError
  })

  if (isIdleLoading) {
    return <Container><LoadingSpinner/></Container>
  }

  if (isError) {
    return <ErrorHandler/>
  }

  const countryOptions = dataCountries.data.map((country) => ({
    value: country.name,
    label: getCountryNameOrCodeShort(country.iso_code),
    iso_code: country.iso_code
  }));

  const motivations = mData.data.map((m) => ({value: m, label: m}));
  const sectorOptions = dataSectors.data.map((sector) => ({value: sector.uid, label: sector.name}));
  const associatedMalwareOptions = asData.data.map((m) => ({value: m, label: m}));


  return (
    <Container>
      <Row className={'div__sticky-top'}>
        <Col md={9}>
          <h3 className="page-title">{editForm ? 'Edit' : 'New'} Actor </h3>
        </Col>
        <Col md={3} className={'justify-content-end d-flex'}>
          <Button outline onClick={() => {
            clearErrors('');
            handleSubmit(onSubmit)();
          }} color={'success'} disabled={buttonUploadDisabled}>{editForm ? 'Edit' : 'Add'}</Button>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Basic information</h4>
            {
              Object.keys(errors).length > 0 && <span className="form__form-group-error float-right">
                There are some validation errors inside the form
              </span>
            }
          </div>
          {
            <form className="form form--vertical">
              <Row className={'w-100'}>
                <Col md={6}>
                  <RegisteredInputField
                    title={'Name'}
                    name={'name'}
                    register={register}
                    placeholder={'name'}
                    errors={errors}
                    rules={{required: 'The title is required'}}
                  />
                </Col>
                <Col md={6}>
                  <ControlledSelectWithTitle
                    name={'origin_country'}
                    title={'Origin Country'}
                    control={control}
                    valueFn={(value) => countryOptions.find((c) => c.value === value?.value)}
                    defaultValue={defaultValues.orig_country ? countryOptions.find((c) => c.iso_code === defaultValues.orig_country) : null}
                    options={[{value: null, iso_code: null, label: 'None'}].concat(countryOptions)}
                    errors={errors}
                    getOptionLabel={
                      option => <p><ReactCountryFlag countryCode={option.iso_code} className={'mr-1'} svg/>{option.label}</p>
                    }
                  />
                </Col>
                <Col md={6}>
                  <ControlledSelectWithTitle
                    name={'motivations'} title={'Motivations'} control={control}
                    isSearchable isMulti isClearable
                    valueFn={(value) => value}
                    options={motivations}
                  />
                </Col>
                <Col md={6}>
                  <ControlledMultiSelectTextWithTitle
                    name={'aliases'}
                    title={'Aliases'}
                    control={control}
                    isSearchable isMulti isClearable
                    valueFn={(value) => value}
                  />
                </Col>
                <Col md={6}>
                  <ControlledCreatableSelectWithTitle
                    name={`associated_malwares`}
                    title={'Associated Malware'}
                    control={control}
                    isClearable isSearchable isMulti
                    valueFn={(value) => value}
                    options={associatedMalwareOptions}
                  />
                </Col>
                <Col md={6}>
                  <ControlledSelectWithTitle
                    name={'targeted_countries'}
                    title={'Targeted countries'}
                    control={control}
                    isSearchable isMulti isClearable
                    valueFn={(value) => countryOptions.find((c) => c.value === value?.value)}
                    options={countryOptions}
                    defaultValue={
                      defaultValues.countries ?
                        defaultValues.countries.map((countryCode) => countryOptions.find((c) => c.iso_code === countryCode)) : []
                    }
                    getOptionLabel={
                      option => <p><ReactCountryFlag countryCode={option.iso_code} className={'mr-1'} svg/>{option.label}</p>
                    }
                  />
                </Col>
                <Col md={6}>
                  <ControlledSelectWithTitle
                    name={'targeted_sectors'} title={'Sectors'} control={control}
                    isSearchable isMulti isClearable
                    valueFn={(value) => sectorOptions.find((c) => c.value === value?.value)}
                    defaultValue={
                      defaultValues.sectors ?
                        defaultValues.sectors.map((sectorUid) => sectorOptions.find((s) => s.value === sectorUid)) : []
                    }
                    options={sectorOptions}
                  />
                </Col>
                <Col md={6}>
                  <DatePickerWithLabel
                    label={'first seen'}
                    selected={firstSeen}
                    onChange={(date) => setFirstSeen(date)}
                  />
                </Col>
                <Col>
                  <RegisteredCheckBoxField
                    name={'state_sponsored'}
                    value={watch('notify')}
                    onChange={(value) => {}}
                    defaultChecked={defaultValues.state_sponsored ? defaultValues.state_sponsored : false}
                    label={"state sponsored"}
                    register={register}
                  />
                </Col>
              </Row>
            </form>
          }
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <ControlledTextAreaWithTitle
            register={register}
            name={'description'}
            control={control}
            title={'Description'}
            textAreaClassNames={'textarea--add-contents'}
            cardTitle={true}
            rules={{required: 'The description is required'}}
          />
        </CardBody>
      </Card>

      <MitreTableSelectionCard
        setSelection={setTtpsSelected}
        defaultMitreValues={defaultValues.techniques}
      />

    </Container>
  )
}

ActorForm.propTypes = {
  defaultValues: PropTypes.shape(),
  mutationFn: PropTypes.func,
  editForm: PropTypes.bool
}

export default ActorForm;
