import ipRegex from "ip-regex";
import cidrRegex from "cidr-regex";

export const THREAD = 'thread';
export const SELLER = 'seller';
export const BOT = 'bot';

export const APP_AUTH0_PROVIDER = 'auth0';
export const APP_LOCAL_PROVIDER = 'local';


export const getPattern = (type) => {
  if (type === 'md5') {
    return /^[A-Fa-f0-9]{32}$/
  } else if (type === 'ja3') {
    return /^[a-f0-9]{32}$/
  } else if (type === 'sha1') {
    return /^[A-Fa-f0-9]{40}$/
  } else if (type === 'sha256') {
    return /^[A-Fa-f0-9]{64}$/
  } else if (type === 'asn') {
    return /^\d{1,10}$/
  } else if (type === 'domain') {
    return /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-_]{0,61}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-_]{0,61}[A-Za-z]$/
  } else if (type === 'ipv4') {
    return ipRegex.v4({exact: true});
  } else if (type === 'ipv6') {
    return ipRegex.v6({exact: true});
  } else if (type === 'url') {
    return /^(([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-_]+)(\:[0-9]{1,5})?((?:\/[\:\+~%\/.\w-_\!\']*)?\??(?:[-\+=&;%@.\w_\!]*)#?(?:.*))?$/
  } else if (type === 'cve') {
    return /^CVE|cve-\d{4}-\d{4,7}$/
  } else if (type === 'xmraddress') {
    return /^4[0-9AB][1-9A-HJ-NP-Za-km-z]{93}$/
  } else if (type === 'ethaddress') {
    return /^0x[a-fA-F0-9]{40}$/
  } else if (type === 'btcaddress') {
    return /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/
  } else if (type === 'email') {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  } else if (type === 'cidr') {
    return cidrRegex({exact: true});
  } else {
    return /^.*$/
  }
}


export const isValidDomain = (d) =>
  getPattern('domain').test(d)


export const isIocSearchableOnVT = (ioc) =>
  new RegExp(getPattern('domain')).test(ioc) ||
    new RegExp(getPattern('ipv4')).test(ioc) ||
    new RegExp(getPattern('md5')).test(ioc) ||
    new RegExp(getPattern('sha1')).test(ioc) ||
    new RegExp(getPattern('sha256')).test(ioc) ||
    new RegExp(getPattern('url')).test(ioc)


export const isValidBotnetSearchValue = (value) =>
  new RegExp(getPattern('domain')).test(value) ||
    new RegExp(getPattern('ipv4')).test(value)

export const isIP = (value) =>
  new RegExp(getPattern("ipv4")).test(value) || new RegExp(getPattern("ipv6")).test(value)