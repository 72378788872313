import React, {Fragment} from "react";
import PropTypes from "prop-types";
import MaliciousStatsBadge from "../../../shared/components/badge/MaliciousStatsBadge";
import {ColorTagInlineBadgeList} from "../../../shared/components/badge/BadgeLists";
import VirusTotalIndicatorLink from "../../../shared/components/icons/VirusTotalIndicatorLink";
import CopyIconToClipboard from "../../../shared/components/icons/CopyIconToClipboard";
import TableWithData from "../../../shared/components/table/TableWithData";
import paths from "../../../config/paths";
import {useHistory} from "react-router-dom";
import {getCommonTags} from "../../../shared/helpers";


const tableColumns = [
  { id: 'VT', Header: '', accessor: 'value', Cell: ({value}) =>
      <Fragment>
        <VirusTotalIndicatorLink value={value} />
        <CopyIconToClipboard value={value} />
      </Fragment>
  },
  { Header: 'Value', accessor: 'value' },
  { Header: 'Registrar', accessor: 'registrar' },
  { Header: 'Detection', accessor: 'stats', Cell: ({value}) =>  <MaliciousStatsBadge stats={value} />},
  { Header: 'Tags', accessor: 'tags', Cell: ({value}) => <ColorTagInlineBadgeList items={getCommonTags(value)} />},
];

const tableConfig = {
  withHover: true,
  withSearchEngine: true,
}


const DomainsInfoTable = ({data}) => {
  const history = useHistory();

 return (
   <TableWithData
     data={data}
     tableColumns={tableColumns}
     tableConfig={tableConfig}
     onRowClick={(index, row) => history.push(`${paths.investigatePath}?indicator=${row.original.value}`)}
   />
 )
}


DomainsInfoTable.propTypes = {
  data: PropTypes.array.isRequired
}

export default DomainsInfoTable;
