import PropTypes from "prop-types";
import React from "react";
import DomainDetails from "./IndicatorsDetailTypes/DomainDetails";
import CveDetails from "./IndicatorsDetailTypes/CveDetails";
import GenericDetails from "./IndicatorsDetailTypes/GenericDetails";
import IPv4Details from "./IndicatorsDetailTypes/IPv4Details";
import HashDetails from "./IndicatorsDetailTypes/HashDetails";
import UrlDetails from "./IndicatorsDetailTypes/UrlDetails";
import FileNameDetails from "./IndicatorsDetailTypes/FileNameDetails";
import AsnDetails from "./IndicatorsDetailTypes/AsnDetails";
import BtcDetails from "./IndicatorsDetailTypes/BtcDetails";
import EmailDetails from "./IndicatorsDetailTypes/EmailDetails";


const IndicatorDetail = ({indicator_details}) => {
  if (indicator_details.indicator_type === 'domain') {
    return <DomainDetails
      indicator_details={indicator_details}
    />
  } else if (indicator_details.indicator_type === 'ipv4') {
    return <IPv4Details
      indicator_details={indicator_details}
    />
  } else if (indicator_details.indicator_type === 'url') {
    return <UrlDetails
      indicator_details={indicator_details}
    />
  } else if (['sha256', 'sha1', 'md5'].includes(indicator_details.indicator_type)) {
    return <HashDetails
      indicator_details={indicator_details}
    />
  } else if (indicator_details.indicator_type === 'cve') {
    return <CveDetails
      indicator_details={indicator_details}
    />
  } else if (indicator_details.indicator_type === 'filename') {
    return <FileNameDetails
      indicator_details={indicator_details}
    />
  } else if (indicator_details.indicator_type === 'asn') {
    return <AsnDetails
      indicator_details={indicator_details}
    />
  } else if (indicator_details.indicator_type === 'btcaddress') {
    return <BtcDetails
      indicator_details={indicator_details}
    />
  } else if (indicator_details.indicator_type === 'email') {
    return <EmailDetails
      indicator_details={indicator_details}
    />
  } else {

    /* No supported type was found */
    return  <GenericDetails indicator_details={indicator_details} />
  }
}


IndicatorDetail.propTypes = {
  indicator_details: PropTypes.object.isRequired,
  indicator: PropTypes.string
}


export default IndicatorDetail;
