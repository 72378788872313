import React from "react";
import PropTypes from "prop-types";
import ColorTagInlineBadge from "../../../shared/components/badge/ColorTagInlineBadge";


const TypoSquattingScoreBadge = ({score}) => {
  let color;

  if (score < 33) {
    color = 'success';
  } else if (score < 66) {
    color = 'warning';
  } else {
    color = 'danger';
  }


  return <ColorTagInlineBadge color={color} tag={Math.ceil(score)} badgeStyle={{width: '100%'}} />
}

TypoSquattingScoreBadge.propTypes = {
  score: PropTypes.number.isRequired
}


export default TypoSquattingScoreBadge;