import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteMalwareFamilyFetcher = (name, accessToken) => {
  return axios.delete(
    `${apiUrlInternal}/tags/${name}`, addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useDeleteMalwareFamily = (fetcher = DeleteMalwareFamilyFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-malware-family'],
    (accessToken, name) => fetcher(name, accessToken),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('delete-malware-family');
        queryClient.invalidateQueries('malware-family');
        queryClient.invalidateQueries(['tags', null]);
      }
    }
  )
}
