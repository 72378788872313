import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";


const MenuEntryWithLink = ({title, value, url, titleStyle = {}, valueStyle = {}}) =>
  <Fragment>
    <p className="custom_card__container-subhead" style={titleStyle}>{title}</p>
    <p className="custom_card__container-title" style={valueStyle}><Link to={url}>{value}</Link></p>
  </Fragment>


MenuEntryWithLink.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  titleStyle: PropTypes.object,
  valueStyle: PropTypes.object
}


export default MenuEntryWithLink;
