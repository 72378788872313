import {addAuthToFetchOpts} from '../../config/queryopts'
import {useMutationWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


const _ = require('lodash');


export const RequestSentTakeDownFetcher = (accessToken, params) => {
  return axios.put(
    `${apiUrlInternal}/takedown_tickets/reported/${params.uid}`, params, addAuthToFetchOpts(accessToken, {})
  ).then(res => res);
}

export const useRequestSentTakedownTicketMutation = (fetcher = RequestSentTakeDownFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['report-takedown-ticket'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: (_, params) => {
        queryClient.invalidateQueries('report-takedown-ticket')
        queryClient.invalidateQueries(['takedown-tickets', `/${params.uid}`])
        queryClient.invalidateQueries(['tickets', ''])
      }
    }
  )
}

