import React from 'react';
import CompanyDashboard from "./HomepageDashboard";
import Page from "../../shared/components/Page";


const Dashboard = () => {
  return (
    <Page>
      <CompanyDashboard/>
    </Page>
  )
}


export default Dashboard;