import React, {Fragment, useCallback} from 'react';
import {Col, Row,  Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import {useSandboxTaskDetailsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import FilterableFilesTable from "./FilterableFilesTable";
import TableWithData from "../../../shared/components/table/TableWithData";
import VirusTotalIndicatorLink from "../../../shared/components/icons/VirusTotalIndicatorLink";
import {bytesToSizeOrNA} from "../../../shared/helpers";
import {getCopiableColumn} from "../../../shared/helpers/tables";



const SandboxTaskFilesSection = ({taskId}) => {
    const { data, isIdle, isLoading, isError,error } = useSandboxTaskDetailsQuery(taskId);

    const getRowDataId = useCallback((row, i) => (row.original.dataId), [])

    if (isLoading || isIdle) {
        return <LoadingSpinner />
    }

    if (isError || !data.data) {
        return <ErrorHandler error={error} />
    }

    const droppedFilesTableColumns = [
        { Header: 'Names', accessor:(row) => row['name'].toString(), className: "truncated-columns" },
        { Header: 'Size', accessor: (row) => bytesToSizeOrNA(row.size) },
        getCopiableColumn('MD5', 'md5', true),
        getCopiableColumn('SHA1', 'sha1', true),
        getCopiableColumn('SHA256', 'sha256', true),
        { id: 'VT', Header: '', accessor: 'sha1', Cell: ({value}) => <VirusTotalIndicatorLink value={value} /> }
    ];

    const dropped_files = data.data.report.dropped_files ? data.data.report.dropped_files.filter((file) => file.size > 0) : [];
    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="react-table__wrapper">
                                <div className="card__title">
                                    <h5 className="bold-text">Dropped files</h5>
                                </div>
                            </div>
                            {
                                dropped_files.length > 0 &&
                                    <TableWithData
                                        data={dropped_files}
                                        tableColumns={droppedFilesTableColumns}
                                        getRowDataId={getRowDataId}
                                    />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <FilterableFilesTable taskId={taskId} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

SandboxTaskFilesSection.propTypes = {
    taskId: PropTypes.string.isRequired,
}

export default SandboxTaskFilesSection;
