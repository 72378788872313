import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Collapse} from "reactstrap";
import classNames from "classnames";
import TicketHeader from "./TicketHeader";
import ChatData from "./ChatData";


const TicketDetailCard = ({ticket}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  return (
    <Card>
      <div className={classNames({['card-body__priority-' + ticket.priority]: true})}>
        <TicketHeader ticket={ticket} toggle={toggle} />
        <Collapse isOpen={isOpen}>
          <CardBody>
            <div className={"card__title"}>
              <h4 className={'bold-text'}>Messages</h4>
            </div>
            <ChatData ticket={ticket} />
          </CardBody>
        </Collapse>
      </div>
    </Card>
  )
}

TicketDetailCard.propTypes = {
  ticket: PropTypes.shape().isRequired
};


export default TicketDetailCard;
