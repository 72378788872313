import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Col, Row} from "reactstrap";
import ColorTagInlineBadge from "../../../shared/components/badge/ColorTagInlineBadge";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import {hasUserEditorPermissionOnFeature} from "../../../shared/helpers/permissions";
import {PLATFORM_FEATURE_TAKEDOWN_TICKETS} from "../../../shared/helpers/features";
import TakeDownModal from "../../TakeDownService/components/TakeDownModal";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import RegisteredCheckBoxField from "../../../shared/components/form/RegisteredCheckBoxField";
import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import TypoSquattingScoreBadge from "./TypoSquattingScoreBadge";
import TextModal from "../../../shared/components/modal/TextModal";
import {get_example_by_squatting_type, get_message_by_squatting_type} from "../../../shared/helpers/typosquatting";
import TypoSquattingScreenShotModal from "./TypoSquattingScreenShotModal";


const TypoSquattingResultRow = ({result, index, register, watch, setValue, totalResults}) => {
  const {data: dataCurrentUser} = useCurrentUserQuery();

  const {
    result_id,
    domain_name,
    domain_telemetries,
    added_dt,
    ip_telemetries,
    score,
    solved,
    squatting_type
  } = result;

  const get_status_badge_color = (status) => {
    if (status === 0) {
      return 'primary'
    } else if (200 <= status && status < 300) {
      return 'success'
    } else if (300 <= status && status < 500) {
      return 'warning'
    } else if (status >= 500) {
      return 'error'
    }
  }

  const http_status_badge_color = domain_telemetries?.length > 0 ? get_status_badge_color(domain_telemetries[0].http_status) : 'primary';
  const https_status_badge_color = domain_telemetries?.length > 0 ? get_status_badge_color(domain_telemetries[0].https_status) : 'primary';

  return (
    <Card>
      <CardBody className={`card-body__tlp-${solved ? 'green' : 'red'}`}>
        <Row>
          <Col md={3}>
            <RegisteredCheckBoxField
              name={`result.${index}`}
              value={watch(`result.${index}`)}
              onChange={() => {
                let allTrue = true;
                for (let i = 0; i < totalResults; ++i) {
                  allTrue &= watch(`result.${i}`);
                }

                setValue('all', allTrue);
              }}
              defaultChecked={false}
              label={''}
              register={register}
              style={{display: 'inline'}}
              labelStyle={{marginTop: '4px'}}
              className={'d-inline'}
            />
            <h4 className={'d-inline'}>{domain_name}</h4>
            <h4 style={{paddingLeft: '27px', height: '24px'}} className={'mt-1'}>
              {ip_telemetries?.length > 0 ? ip_telemetries[0].ip_address : ''}
            </h4>
          </Col>
          <Col md={1}>
            <h4><TypoSquattingScoreBadge score={score}/></h4>
          </Col>
          <Col md={1}>
            <h4 className={'align-content-center'}>
              {
                domain_telemetries?.length > 0 && domain_telemetries[0].http_status > 0 ?
                  <>
                    <ColorTagInlineBadge color={http_status_badge_color} tag={domain_telemetries[0].http_status}/>
                    <a href={`https://${domain_name}`} target={'_blank'}>
                      <OpenInNewIcon size={28}/>
                    </a>
                  </> :
                  <ColorTagInlineBadge color={'primary'} tag={'N/A'} badgeStyle={{width: '100%'}}/>
              }
            </h4>
          </Col>
          <Col md={1}>
            <h4>
              {
                domain_telemetries?.length > 0 && domain_telemetries[0].https_status > 0 ?
                  <>
                    <ColorTagInlineBadge color={https_status_badge_color} tag={domain_telemetries[0].https_status}/>
                    <a href={`http://${domain_name}`} target={'_blank'}>
                      <OpenInNewIcon size={28}/>
                    </a>
                  </> :
                  <ColorTagInlineBadge color={'primary'} tag={'N/A'} badgeStyle={{width: '100%'}}/>
              }
            </h4>
          </Col>
          <Col md={1}>
            <h4>
              {
                ip_telemetries?.length > 0 && ip_telemetries[0].port_80_status === 0 ?
                  <ColorTagInlineBadge color={'primary'} tag={'CLOSED'} badgeStyle={{width: '100%'}}/> :
                  <ColorTagInlineBadge color={'success'} tag={'OPEN'} badgeStyle={{width: '100%'}}/>
              }
            </h4>
          </Col>
          <Col md={1}>
            <h4>
              {
                ip_telemetries?.length > 0 && ip_telemetries[0].port_443_status === 0 ?
                  <ColorTagInlineBadge color={'primary'} tag={'CLOSED'} badgeStyle={{width: '100%'}}/> :
                  <ColorTagInlineBadge color={'success'} tag={'OPEN'} badgeStyle={{width: '100%'}}/>
              }
            </h4>
          </Col>
          <Col md={3}>
            <p>
              Added on <b>{formatDateUTCtoYearMonthDayTime(added_dt)}</b>
              {
                domain_telemetries?.length > 0 &&
                <>, <br/>Last monitored on <b>{formatDateUTCtoYearMonthDayTime(domain_telemetries[0].update_dt)}</b></>
              }
            </p>
          </Col>
          <Col>
            <TextModal
              title={squatting_type}
              message={get_message_by_squatting_type(squatting_type)}
              label={squatting_type}
              secondMessage={get_example_by_squatting_type(squatting_type)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3 className={'d-inline'}>
              {
                solved ?
                  <ColorTagInlineBadge badgeStyle={{marginTop: '5px'}} color={'success'} tag={'SOLVED'}/> :
                  <ColorTagInlineBadge badgeStyle={{marginTop: '5px'}} color={'danger'} tag={'NEW MATCH'}/>
              }
            </h3>
            {
              hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TAKEDOWN_TICKETS) &&
              <TakeDownModal
                disabled={
                  !hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TAKEDOWN_TICKETS) ||
                  dataCurrentUser?.data?.organization?.used_takedown_ticket >= dataCurrentUser?.data?.organization?.takedown_ticket
                }
                classNames={'float-right mb-0 mx-2'}
                indicator={domain_name}
                buttonLabel={`Takedown ${domain_name} domain`}
                checkTicketExists
              />
            }
            {
              domain_telemetries[0]?.http_status > 0 &&
              <TypoSquattingScreenShotModal
                result_id={result_id}
                domain_name={domain_name}
                protocol={'http'}
              />
            }
            {
              domain_telemetries[0]?.https_status > 0 &&
              <TypoSquattingScreenShotModal
                result_id={result_id}
                domain_name={domain_name}
                protocol={'https'}
              />
            }
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

TypoSquattingResultRow.propTypes = {
  result: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired
}


export default TypoSquattingResultRow;
