import React from "react";
import ResourceMonitorForm from "../form/ResourceMonitorForm";
import PropTypes from "prop-types";


const ResourceMonitorProjectFormCollapseContent = ({
  project,
  resource_monitors,
  resourceMonitorTypes,
  organization
}) =>
  <ResourceMonitorForm
    project={project}
    resource_monitors={resource_monitors}
    resourceMonitorTypes={resourceMonitorTypes}
    organization={organization}
  />


ResourceMonitorProjectFormCollapseContent.propTypes = {
  project: PropTypes.object.isRequired,
  resource_monitors: PropTypes.array.isRequired,
  resourceMonitorTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  organization: PropTypes.object.isRequired
}


export default ResourceMonitorProjectFormCollapseContent;
