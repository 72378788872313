import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";


export const UserDetailsFetcher = (accessToken, uid = null) => {
  const uri = (uid) ? `/${uid}` : '';
  return axios.get(`${apiUrlInternal}/users${uri}`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useUserDetailsQuery = (uid = null, fetcher = UserDetailsFetcher) => {
  return useQueryWithAuthentication(
    ['user-details', uid],
    (accessToken) => fetcher(accessToken, uid),
    {refetchOnWindowFocus: false}
  )
}

useUserDetailsQuery.propTypes = {
  uid: PropTypes.string,
};
