import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Col, Row} from "reactstrap";
import MenuEntry from "../../../../../../shared/components/MenuEntry";
import {getElementPropertyOrDefault} from "../../../../../../shared/helpers";
import {formatDateUTCtoYearMonthDayTime} from "../../../../../../shared/helpers/date";
import IpInfoIcon from "./IpInfoIcon";



const IndicatorAsnCard = ({asn_info, indicator}) =>
  <Card className={'h-auto'}>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>ASN Details</h4>
        <span className={'float-right'}><IpInfoIcon asn={indicator}/></span>
      </div>

      <Row>
        <Col>
          <Row>
            <Col md={7}>
              <div className="custom_card__container">
                <MenuEntry title={'NAME'} value={getElementPropertyOrDefault(asn_info, 'name', 'Unknown')} />
                <MenuEntry title={'ORGANIZATION'} value={getElementPropertyOrDefault(asn_info, 'organisation', 'Unknown')} />
                <MenuEntry title={'REGISTRANT COUNTRY'} value={
                  asn_info.country ? `${asn_info.registered_country_name} (${asn_info.registered_country})` : 'Unknown'
                } />
              </div>
            </Col>
            <Col md={5}>
              <div className="custom_card__container">
                <MenuEntry title={'TOTAL IPV4'} value={getElementPropertyOrDefault(asn_info, 'total_ipv4', 'Unknown')} />
                <MenuEntry title={'REGISTRATION DATE'} value={
                  asn_info.registration_dt ? formatDateUTCtoYearMonthDayTime(asn_info.registration_dt) : 'N/A'
                } />
                <MenuEntry title={'LAST CHANGE DATE'} value={
                  asn_info.registration_last_change ? formatDateUTCtoYearMonthDayTime(asn_info.registration_last_change) : 'N/A'
                } />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

    </CardBody>
  </Card>


IndicatorAsnCard.propTypes = {
  asn_info: PropTypes.string.isRequired,
  indicator: PropTypes.number.isRequired
}


export default IndicatorAsnCard;
