import {useHistory, useParams} from "react-router-dom";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import ActorsCard from "../../shared/components/card/ActorsCard";
import React, {useEffect} from "react";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ContentTimeLineCard from "../InvestigateDetail/components/ContentTimeLineCard";
import {useMalwareFamilyDetailsQuery} from "../../queries/MalwareFamilyDetails";
import MalwareFamilyTimeLineHistogram from "./components/MalwareFamilyTimeLineHistogram";
import SectorCard from "../../shared/components/card/withIcon/SectorCard";
import _ from "lodash";
import TargetVictimCard from "../../shared/components/card/TargetVictimCard";
import RulesCard from "../../shared/components/card/RulesCard";
import {formatDateUTCtoYearMonthDay} from "../../shared/helpers/date";
import IndicatorsTabWithTablePaginationCard from "../../shared/components/card/IndicatorsTabWithTablePaginationCard";
import {useSearchEventIndicatorDictPagination} from "./queries/SearchMalwareFamilyIndicatorDictPagination";
import {
  useMalwareFamilyDetailCommandsQuery,
  useMalwareFamilyDetailContentsQuery,
  useMalwareFamilyDetailIndicatorsCountQuery
} from "../../queries/MalwareFamily";
import {useStatsMalwareFamiliesContextsByMonth} from "../../queries/Stats";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import paths from "../../config/paths";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import DeleteIconModal from "../../shared/components/modal/DeleteIconModal";
import {useDeleteMalwareFamily} from "../../mutations/DeleteMalwareFamily";
import toast from "react-hot-toast";
import {useRulesQuery} from "../../queries/Rules";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_FEATURE_ORGANIZATIONS} from "../../shared/helpers/features";
import CommandsCard from "../../shared/components/card/CommandsCard";
import env_const from "../../config/env_const";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const MalwareFamilyDetail = () => {
  const {malwareFamilyUid: uid} = useParams();
  const history = useHistory();
  const mutation = useDeleteMalwareFamily();
  const {data, isIdle, isLoading, isError, error} = useMalwareFamilyDetailsQuery(uid);

  const {
    data: dataCommands,
    isIdle: isIdleCommands,
    isLoading: isLoadingCommands,
  } = useMalwareFamilyDetailCommandsQuery(uid)

  const {
    data: dataStats,
    isIdle: isIdleStats,
    isLoading: isLoadingStats
  } = useStatsMalwareFamiliesContextsByMonth(uid)

  const {
    data: dataContent,
    isIdle: isIdleContent,
    isLoading: isLoadingContent
  } = useMalwareFamilyDetailContentsQuery(data?.data?.name);

  const {
    data: dataRule,
    isIdle: isIdleRule,
    isLoading: isLoadingRule
  } = useRulesQuery(data?.data?.name ? [data.data.name] : undefined, null, 100);

  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
  } = useCurrentUserQuery();

  useEffect(() => {
    if (!mutation.isIdle) {
      if (mutation.isLoading) {
        toast.loading('Deleting malware family');
      } else {
        if (mutation.isSuccess) {
          history.push(paths.indicatorPath);
        } else if (mutation.isError) {
          toast.error('Error during the deletion of the malware family');
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  if (isLoading || isIdle || isLoadingCurrentUser || isIdleCurrentUser) {
    return <LoadingSpinnerPage/>
  }

  const malware = data.data;
  const stats = dataStats?.data || [];
  const contents = dataContent?.data || [];
  const rules = dataRule?.data || [];
  const sectors = _.uniqBy(_.flatten(contents.map((content) => content.sectors)), (sector) => sector.name)
  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
    >
      <Container>
        <Row>
          <Col>
            {
              (isIdleStats || isLoadingStats) ? <LoadingSpinner/> :
                <MalwareFamilyTimeLineHistogram
                  stats={stats}
                  malware={malware.name}
                />
            }
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={12}>
            <Card className={'h-auto'}>
              <CardBody>
                <div className="card__title">
                  <h4 className={'bold-text'}>{malware.name}</h4>
                  {malware.first_seen &&
                    <p className={'subhead'}>First seen on {formatDateUTCtoYearMonthDay(malware.first_seen)}</p>}
                </div>
                <pre className={'mb-3 pre--text'}>{malware.description}</pre>
                {
                  hasUserEditorPermissionOnFeature(dataCurrentUser.data.modules, PLATFORM_FEATURE_ORGANIZATIONS) &&
                  !env_const.is_clone &&
                  <div className="actionBar float-right">
                    <PencilOutlineIcon
                      size={24} style={{fill: '#ffffff', margin: '0'}}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`${paths.malwareFamilyBasePath}/edit/${malware.uid}`);
                      }}
                      lassName={'border-hover-white mr-3 editIcon'}
                    />
                    <DeleteIconModal
                      onDelete={() => mutation.mutate(malware.name)}
                      message={'Do you really want to delete the malware family?'}
                    />
                  </div>
                }
              </CardBody>
            </Card>
            <ActorsCard actors={_.uniqBy(_.flatten(contents.map((content) => content.actors)), (actor) => actor.name)}/>
          </Col>
          <Col lg={6} md={12} className={'d-flex flex-column'} style={{paddingBottom: '30px'}}>
            <SectorCard
              sectors={sectors}/>
            {(isIdleContent || isLoadingContent) ? <LoadingSpinner/> : <ContentTimeLineCard contexts={contents}/>}
          </Col>
        </Row>

        {/* Indicators */}
        <Row>
          <Col md={12}>
            <IndicatorsTabWithTablePaginationCard
              dataHookCount={useMalwareFamilyDetailIndicatorsCountQuery}
              uid={uid}
              filters={{uid: uid}}
              dataHook={useSearchEventIndicatorDictPagination}
              withExportButton
              exportFilters={{tags: [malware.name]}}
              customRuleText={``}
            />
          </Col>
        </Row>

        {/* COUNTRIES */}
        <Row>
          <Col md={12}>
            {
              (isIdleContent || isLoadingContent) ? <LoadingSpinner/> :
                <TargetVictimCard
                  targetedCountries={_.uniq(_.flatten(contents.map((content) => content.targeted_countries)))}/>
            }
          </Col>
        </Row>

        {/* Rules */}
        <Row>
          <Col md={12}>
            {
              (isIdleRule || isLoadingRule) ? <LoadingSpinner/> :
                <RulesCard rules={rules
                  // _.flatten(contents.map((content) => content.rules)).filter(
                  //   (rule) => _.some(rule.tags, (tag) => tag.name === malware.name)
                  // )
                }
                />
            }
          </Col>
        </Row>
        {/* Commands */}
        <Row>
          <Col md={12}>
            {
              (isIdleCommands  || isLoadingCommands) ? <LoadingSpinner/> :
                <CommandsCard  commands={dataCommands.data}/>
            }
          </Col>
        </Row>
      </Container>
    </Page>
  )
}


export default MalwareFamilyDetail;
