import React from "react";
import {AutoSizer, InfiniteLoader, List, WindowScroller} from "react-virtualized";
import PropTypes from "prop-types";
import LoadingSpinner from "../LoadingSpinner";
import {Link} from "react-router-dom";
import paths from "../../../config/paths";


const WindowedInfiniteLoader = ({
  isItemLoaded,
  loadMoreItems,
  RowItem,
  isLoading,
  itemsArray
}) => {
  const rowCount = itemsArray.length;

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <WindowScroller>
      {({
          height, isScrolling, onChildScroll, scrollTop,
        }) => (
        <InfiniteLoader
          isRowLoaded={isItemLoaded}
          loadMoreRows={loadMoreItems}
          rowCount={rowCount}
          minimumBatchSize={10}
          threshold={300}
        >
          {({onRowsRendered, ref}) => (
            <AutoSizer disableHeight>
              {({width}) => (
                <List
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                  isScrolling={isScrolling}
                  autoHeight={true}
                  height={height}
                  width={width > 1000 ? width : 1000}
                  onRowsRendered={onRowsRendered}
                  ref={ref}
                  rowCount={rowCount}
                  rowHeight={50}
                  rowRenderer={RowItem}
                  scrollToIndex={-1}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      )}
    </WindowScroller>
  )
}

WindowedInfiniteLoader.propTypes = {
  isItemLoaded: PropTypes.func.isRequired,
  loadMoreItems: PropTypes.func.isRequired,
  RowItem: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  itemsArray: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired
}

export default WindowedInfiniteLoader;
