import React from "react";
import UserForm from "../../shared/components/form/user-form/UserForm";
import {PLATFORM_FEATURE_USERS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";


const UserAdd = () => {
  return (
    <Page
      feature={PLATFORM_FEATURE_USERS}
      permission={PLATFORM_PERMISSION_EDITOR}
    >
      <UserForm defaultValues={{'active':true}}/>
    </Page>
  )
}

export default UserAdd;
