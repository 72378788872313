import {useRansomwareTrackerEventsQuery} from "./RansomwareTrackerEvents";
import PropTypes from "prop-types";


export const useSearchRansomwareTrackerEventsDictPagination = ({
                                                                 order_by = '',
                                                                 search_term = '',
                                                                 actor_name = '',
                                                                 victim_country = '',
                                                                 start = '',
                                                                 end = ''
                                                               },
                                                               limit = 0,
                                                               offset = 0) => {
  return useRansomwareTrackerEventsQuery(
    limit, offset * limit, order_by, search_term, actor_name, victim_country, start, end
  )
}

useSearchRansomwareTrackerEventsDictPagination.propTypes = {
  filters: PropTypes.shape().isRequired,
  limit: PropTypes.number,
  offset: PropTypes.number,

};