import React, {useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom";
import {Button, Col, Container, Row} from "reactstrap";
import paths from "../../config/paths";
import {useGetTakedownQuery} from "../../queries/TakeDownService";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import TakeDownDetailSec from "./components/TakeDownDetailSec";
import TakeDownInvestigateSec from "./components/TakeDownInvestigateSec";
import {PLATFORM_FEATURE_TAKEDOWN_TICKETS, PLATFORM_PERMISSION_VIEWER} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const TakeDownServiceDetail = () => {
  const {uid: uid} = useParams();
  const {data, isIdle, isLoading, isError, error} = useGetTakedownQuery(uid);
  const [ticketDetail, setTicketDetail] = useState({});
  const DETAIL_SECT = 1;
  const INVESTIGATE_SECT = 2;
  const [section, setSection] = useState(DETAIL_SECT);
  const history = useHistory();


  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setTicketDetail(data.data);
    }
  }, [isIdle, isLoading, isError, data])

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  if (isLoading || isIdle || !ticketDetail) {
    return <LoadingSpinnerPage/>
  }

  return (
    ticketDetail && ticketDetail.uid ?
      <Page
        feature={PLATFORM_FEATURE_TAKEDOWN_TICKETS}
        
        onlyOnline
      >
        <Container className="ticketDetailWrapper">
          <Row>
            <Col md={12}>
              <Button className="rounded" size="sm" outline active={section === DETAIL_SECT}
                      onClick={() => setSection(DETAIL_SECT)}>Ticket</Button>
              <Button className="rounded" size="sm" outline active={section === INVESTIGATE_SECT}
                      onClick={() => setSection(INVESTIGATE_SECT)}>{ticketDetail.indicator}</Button>
            </Col>
          </Row>
          <h3
            className="page-subhead subhead cursor"
            style={{'cursor': 'pointer', 'max-width': '150px'}}
            onClick={() => history.push(paths.takeDownServicePath)}
          >
            Back to takedown list
          </h3>
          <Row>
            <Col md={12} className="dashboard">
              {
                section === DETAIL_SECT && <TakeDownDetailSec ticketDetail={ticketDetail}/> ||
                section === INVESTIGATE_SECT && <TakeDownInvestigateSec ticketDetail={ticketDetail}/>
              }
            </Col>
          </Row>
        </Container>
      </Page> :
      <p>it should not reach here</p>
  )
}

export default TakeDownServiceDetail;
