import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const AddUserFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/users`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useAddUserMutation = (fetcher = AddUserFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['add-user'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => {
        queryClient.invalidateQueries('add-user');
        queryClient.invalidateQueries(['user-details', null]);
      }}
  )
}
