import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index";
import {useQueryClient} from "react-query";

const ReportQuotaPurchaseFetcher = (accessToken, params) => {
  return axios.post(
    `${apiUrlInternal}/report_quota_purchases`,
    params,
    addAuthToFetchOpts(accessToken, {}, 0)
  ).then(res => res.data);
}

export const useReportQuotaPurchaseMutation = (fetcher = ReportQuotaPurchaseFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['report-quota-purchase'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(['organizations', params.organization_uid])
      }
    }
  )
}