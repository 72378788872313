import React, {Fragment, useCallback, useState} from 'react';
import {Button, ButtonToolbar} from 'reactstrap';
import SandboxTaskFilepond from './SandboxTaskFilepond'
import ThemeModal from "../../../shared/components/ThemeModal";
import PropTypes from "prop-types";


const TaskUploadModal = ({disabled = false}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal]);

  return (
    <Fragment>
      <Button className="rounded float-right" outline size="sm" onClick={toggleModal} disabled={disabled}>
        <span className="lnr lnr-upload"/> Submit task
      </Button>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header modal-dialog--dark'}
      >

        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal}/>
          <h4 className="text-modal  modal__title">Submit task</h4>
        </div>

        <div className="modal__body">
          <SandboxTaskFilepond onSuccess={toggleModal}/>
        </div>

        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" onClick={toggleModal}>Close</Button>
        </ButtonToolbar>

      </ThemeModal>
    </Fragment>
  );
};

TaskUploadModal.propTypes = {
  disabled: PropTypes.bool
}

export default TaskUploadModal;
