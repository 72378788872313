import React from "react";
import GenericResponse from "../components/GenericResponse";
import Page from "../../../shared/components/Page";

export const NotAllowed401 = () => {
  return (
    <Page>
      <GenericResponse statusCode={401} message={"You don't have the permissions to visit this page."} />
    </Page>
  )
}

export default NotAllowed401;
