import React from 'react'
import HorizontalBarChartCard from "../../../../shared/components/charts/HorizontalBarChartCard";
import {useStatsRansomwareTrackerEventsByActors} from "../../../../queries/Stats";
import {useHistory} from "react-router-dom";
import paths from "../../../../config/paths";
import {useActorDetailsQuery} from "../../../../queries/Actor";
import _ from "lodash";


const BAR_COLORS = [
  '#ff4861', '#40e5e8', '#f6da6e',
  '#c88ffa', '#4ce1b6', '#70bbfd',
]

const RansomwareEventsByActorsHorizontalBarChart = ({sectorsLimit = 20}) => {
  const history = useHistory();
  const {
    data: dataActors,
    isIdle: isIdleActors,
    isLoading: isLoadingActors,
    isError: isErrorActors
  } = useActorDetailsQuery();

  const { data , isIdle, isLoading, isError } = useStatsRansomwareTrackerEventsByActors(10);

  const getCorrectActorName = (actor_id) => {
    return _.find(dataActors.data, (actor) => actor_id.includes(actor.uid))?.name
  }

  return (
    <HorizontalBarChartCard
      data={data?.data.slice(0,10)}
      dataKey={'value'}
      labelDataKey={'name'}
      title={'MOST ACTIVE GROUPS'}
      isError={isError}
      isLoading={isLoading || isIdle}
      onBarClick={(item, index) => {
        history.push(`${paths.ransomwareTrackerEventsPath}?actor_name=${getCorrectActorName(data?.data[index].uid)}`)
      }}
      barColors={BAR_COLORS}
    />
  )
}

export default RansomwareEventsByActorsHorizontalBarChart;
