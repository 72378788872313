import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import PropTypes from "prop-types";
import {useForm} from "react-hook-form";
import ControlledFilepond from "../../../shared/components/form/ControlledFilepond";
import toast from "react-hot-toast";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import {useCookieAccessToken} from "../../../queries";
import {useAddOrganizationLogoMutation} from "../../../mutations/AddOrganizationLogo";
import {getErrorMessageFromResponse} from "../../../shared/helpers";
import {getIcon} from "../../../shared/helpers/user";

const OrganizationSection = ({currentUser}) => {
  const mutation = useAddOrganizationLogoMutation();
  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);
  const {isError: tokenIsError, isLoading: tokenIsLoading, isIdle: tokenIsIdle, error} = useCookieAccessToken();
  const defaultValues = {url: ''};
  const {handleSubmit, control} = useForm({
    defaultValues: defaultValues
  });

  useEffect(() => {
    if (!mutation.isIdle && mutation.isLoading && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }

    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        toast.success('File uploaded successfully');
        setButtonUploadDisabled(false);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
        setButtonUploadDisabled(false);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])


  if (tokenIsIdle || tokenIsLoading) {
    return <Container><LoadingSpinner/></Container>
  }

  if (tokenIsError) {
    return <ErrorHandler error={error}/>
  }

  const onSubmit = data => {
    if (data.report && data.report.length > 0 && data.report[0].filename.split('.').pop() === 'ico') {
      setButtonUploadDisabled(true);
      const formData = new FormData();
      formData.append('file', data.report[0].file, data.report[0].filename);
      toast.loading('Validating and uploading file.');
      mutation.mutate({organizationUid: currentUser.organization.uid, params: formData});
    } else {
      toast.dismiss();
      toast.error(`Upload only .ico files`);
    }
  }

  const btnClass = buttonUploadDisabled ? 'disabled float-right' : 'float-right';

  return (
    <Container>
      <Row>
        <Col md={12}>
          <form className="form form--vertical" onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h4 className={'bold-text d-inline'}>Current Logo</h4>
                </div>
                <div className={'profile__avatar mx-auto small'}>
                  <img src={getIcon(currentUser)} alt="avatar"/>
                </div>
                <div className="card__title">
                  <h4 className={'bold-text d-inline'}>Change Logo</h4>
                </div>
                <ControlledFilepond
                  control={control}
                  name={'report'}
                  allowMultiple={false}
                  allowRevert={false}
                  maxFiles={1}
                  instantUpload={false}
                  defaultValue={defaultValues?.url}
                  labelIdle={'Drag & Drop your report or <span class="filepond--label-action">Browse</span>'}
                />
                <Button color="success" outline className={btnClass} type="submit">Upload</Button>
              </CardBody>
            </Card>
          </form>
        </Col>
      </Row>
    </Container>
  )

}

OrganizationSection.propTypes = {
  currentUser: PropTypes.shape().isRequired
}

export default OrganizationSection;