import React from "react";
import {Badge, Col} from "reactstrap";
import PropTypes from "prop-types";
import TLPBadge from "../../../shared/components/badge/TLPBadge";
import {getTicketStatus} from "../helper";


const TakeDownStatusBadges = ({ticket, type = 'list'}) => {
  const extraClass = type === 'detail' ? 'ticket-badge__status_ptions mt-2 mb-2 p-0' : 'ticket-badge__status_ptions';

  return (
    <Col md={12} className={extraClass}>

      <Badge
        color={'secondary'}
        className={'outline badge-l'}
      >
        {`CATEGORY:${ticket.category.toUpperCase()}`}
      </Badge>

      <Badge
        color={'secondary'}
        className={'outline badge-l'}
      >
        {`CONFIDENCE:${ticket.confidence.toUpperCase()}`}
      </Badge>

      <Badge
        color={
          ticket.status === 'assigned' ?
            'success' :
            ticket.status.includes('cloed') ?
              'danger' :
              'secondary'
        }
        className={'outline badge-l'}
      >
        {`STATUS:${getTicketStatus(ticket.status)}`}
      </Badge>

      <TLPBadge style={{'margin-right': '7px', 'float': 'right'}} tlp={ticket.tlp.tlp}/>

    </Col>
  )
}

TakeDownStatusBadges.propTypes = {
  ticket: PropTypes.shape().isRequired,
  type: PropTypes.string
}

export default TakeDownStatusBadges;
