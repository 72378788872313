import React from "react";
import {useParams} from "react-router-dom";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import ActorForm from "../../shared/components/form/actors-form/ActorForm";
import {useActorDetailsQuery} from "../../queries/Actor";
import {useEditActorMutation} from "../../mutations/Actor/UpdateActor";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_ACTORS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const ActorEdit = () => {
  const { actorUid } = useParams();
  const { data, isError, isLoading, isIdle, error } = useActorDetailsQuery(actorUid, true);

  if (isLoading || isIdle) {
    return <LoadingSpinnerPage />
  }

  if (isError) {
    return <ErrorHandler error={error} />
  }

  const getDefaultActorFormValues = (actor) => ({
    associated_malwares: actor.associated_malwares.map((val) => ({label: val, value: val})),
    motivations: actor.motivations.map((val) => ({label: val, value: val})),
    aliases: actor.aliases.map((val) => ({label: val, value: val})),
    sectors: actor.targeted_sectors,
    countries: actor.targeted_countries,
    orig_country: actor.origin_country,
    name: actor.name,
    first_seen: actor.first_seen,
    description: actor.description,
    techniques: actor.techniques,
    state_sponsored: actor.state_sponsored,
  })

  const defaultValues = getDefaultActorFormValues(data.data);

  return (
    <Page
      feature={PLATFORM_FEATURE_ACTORS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
      unavailableOnTenant
    >
      <ActorForm
        defaultValues={defaultValues}
        mutationFn={useEditActorMutation}
        actorUid={data.data.uid}
        editForm
      />
    </Page>
  )
}

export default ActorEdit;
