import ControlledSelectWithTitle from "../ControlledSelectWithTitle";
import ControlledCreatableSelectWithTitle from "../ControlledCreatableSelectWithTitle";
import React from "react";
import env_const from "../../../../config/env_const";
import PropTypes from "prop-types";


const BotnetControlledSelect = ({botnetOptions, control, name}) => {
  if (env_const.is_clone) {
    return (
      <ControlledSelectWithTitle
        name={name} title={'Botnets'} control={control}
        isClearable={false}
        isSearchable
        isMulti
        valueFn={(value) => value}
        options={botnetOptions}
      />
    )
  }
  else {
    return (
      <ControlledCreatableSelectWithTitle
        name={name} title={'Botnets'} control={control}
        isClearable={false}
        isSearchable
        isMulti
        valueFn={(value) => value} options={botnetOptions}
      />
    )
  }
}


BotnetControlledSelect.propTypes = {
  botnetOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
}


export default BotnetControlledSelect;
