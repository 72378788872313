import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import { Input } from 'reactstrap';
import { CSVLink} from "react-csv";
import DownloadIcon from "mdi-react/DownloadIcon";
//import { propTypes } from 'react-country-flag';
const ReactTableFilter = ({
  rows,
  setGlobalFilter, setFilterValue,
  globalFilter, searchPlaceholder, dataLength,
  withCsvDownload,
  withSearchEngine,
  csvData
}) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((item) => {
    setGlobalFilter(item || undefined);
  }, 200);
  setFilterValue(value);
  return (
    <div className="table__search" style={{'justify-content':'space-between'}}>
      <span style={{'display':'flex','align-items': 'baseline','width': '100%'}}>
        { withSearchEngine &&  (
          <Input
            className="table__search table__search-input"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${searchPlaceholder}`}
          />
        )}
        {withSearchEngine && dataLength !== rows.length && (
          <span>Found {rows.length} matches</span>
        )}
      </span>
      {withCsvDownload  && csvData && csvData.length > 0 && (
        <CSVLink 
            data={csvData} 
            filename={'report.csv'} 
            style={{'text-align': 'right', flex: '0','padding-right':'10px'}}
            >
            <DownloadIcon 
              className='border-hover-white' 
              size={24}
              style={{fill: 'white', padding: '2px'}}
            />
        </CSVLink>
      )}
    </div>
  );
};

const filterGreaterThan = (rows, id, filterValue) => rows.filter((row) => {
  const rowValue = row.values[id];
  return rowValue >= filterValue;
});

filterGreaterThan.autoRemove = val => typeof val !== 'number';

ReactTableFilter.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func,
  globalFilter: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  dataLength: PropTypes.number.isRequired,
  withCsvDownload:PropTypes.bool.isRequired,
  withSearchEngine:PropTypes.bool.isRequired,
  csvData:PropTypes.shape().isRequired
};

ReactTableFilter.defaultProps = {
  setFilterValue: () => {},
  globalFilter: '',
  searchPlaceholder: 'Search...',
};

export default ReactTableFilter;
