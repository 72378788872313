import React, {useMemo} from 'react';
import PieChartMultipleItems from "../../../../shared/components/charts/PieChartMultipleItems";
import {useStatsIndicatorsTypes} from "../../../../queries/Stats";
import {useHistory} from "react-router-dom";
import paths from "../../../../config/paths";
import {Card, CardBody} from "reactstrap";


const PIE_COLORS = [
  '#4ce1b6', '#70bbfd', '#ff4861',
  '#c88ffa', '#40e5e8', '#f6da6e',
  '#607d8b', '#ff3d00', '#8c9eff',
  '#c6ff00', '#ff80ab', '#e57373',
  '#a1887f', '#00e5ff', '#00c853',
  '#82b1ff'
]

const IndicatorsPieChart = () => {
  const history = useHistory();
  const {data: rawData, isLoading, isIdle, isError} = useStatsIndicatorsTypes();

  const data = useMemo(() =>
    (!isIdle && !isLoading && !isError) ?
      rawData.data.map((val, index) => ({
        name: val.type,
        value: val.num_indicators,
        fill: PIE_COLORS[index],
      })) : [], [rawData, isIdle, isLoading, isError]
  )

  return (
    <Card>
      <CardBody>
        <PieChartMultipleItems
          data={data}
          isError={isError}
          isLoading={isLoading}
          isIdle={isIdle}
          title={"Indicators type"}
          classNamePie={'dashboard__chart-pie--indicators'}
          onLegendClick={(type) => {
            history.push(`${paths.indicatorPath}?types=${type.toLowerCase()}`)
          }}
        height={430}/>
      </CardBody>
    </Card>
  )
}


IndicatorsPieChart.propTypes = {};

export default IndicatorsPieChart;
