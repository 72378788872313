import React, {useEffect} from "react";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import toast from "react-hot-toast";
import ErrorHandler from "../../shared/components/ErrorHandler";
import {formatDateUTCtoYearMonthDayTime} from "../../shared/helpers/date";
import TLPBadge from "../../shared/components/badge/TLPBadge";
import TableWithData from "../../shared/components/table/TableWithData";
import DeleteIconModal from "../../shared/components/modal/DeleteIconModal";
import {getElementPropertyOrDefault, getErrorMessageFromResponse} from "../../shared/helpers";
import {useHistory} from "react-router-dom";
import {useApplicationDetailsQuery} from "../../queries/Applications";
import {useDeleteApplication} from "../../mutations/DeleteApplication";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import paths from "../../config/paths";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import {PLATFORM_FEATURE_APPLICATIONS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
}


const ApplicationsList = () => {
  const mutation = useDeleteApplication();
  const history = useHistory();

  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser,
    error: errorCurrentUser
  } = useCurrentUserQuery();
  const {
    data: dataApplications,
    isIdle: isIdleApplications,
    isLoading: isLoadingApplications,
    isError: isErrorApplications,
    error: errorApplications,
  } = useApplicationDetailsQuery();

  useEffect(() => {
    if (!mutation.isIdle) {
      toast.dismiss();

      if (mutation.isLoading) {
        toast.loading('Deleting application');
      } else {
        if (mutation.isSuccess) {
          toast.success('Application deleted')
        } else if (mutation.isError) {
          toast.error(getErrorMessageFromResponse(mutation));
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])

  if (isLoadingCurrentUser || isIdleCurrentUser) {
    return <LoadingSpinnerPage/>
  }

  if (isErrorApplications || isErrorCurrentUser) {
    return <ErrorHandler error={errorApplications || errorCurrentUser}/>
  }

  const tableColumns = [
    {Header: 'Name', accessor: 'name'},
    {Header: 'Client ID', accessor: 'client_id'},
    {Header: 'Client Secret', accessor: 'client_secret'},
    {Header: 'TLP', accessor: 'tlp', Cell: ({value}) => <TLPBadge tlp={value.tlp}/>},
    {Header: 'PRIVATE TLP', accessor: 'private_tlp', Cell: ({value}) => <TLPBadge tlp={value.tlp}/>},
    {
      Header: 'Organization',
      accessor: (row) => getElementPropertyOrDefault(row.organization, 'name', 'No organization')
    },
    {Header: 'Created date', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
  ];

  if (hasUserEditorPermissionOnFeature(dataCurrentUser.data.modules, PLATFORM_FEATURE_APPLICATIONS)) {
    tableColumns.push({
      Header: 'Edit appplication', accessor: 'uid', Cell: ({value, row}) => (<>
        <PencilOutlineIcon
          size={24}
          style={{fill: '#ffffff', margin: '0'}}
          onClick={(e) => {
            e.stopPropagation();
            history.push(`${paths.listApplicationPath}/edit/${value}`);
          }}
          className={'border-hover-white mr-3 editIcon'}/>
        <DeleteIconModal
          onDelete={() => mutation.mutate(value)}
          message={`Do you really want to delete the application ${row.values.name}?`}
        />
      </>)
    })
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_APPLICATIONS}
    >
      <Container>
        <Row className={'div__sticky-top'}>
          <Col md={9}>
            <h3 className="page-title">Manage Applications</h3>
          </Col>
          {
            hasUserEditorPermissionOnFeature(dataCurrentUser.data.modules, PLATFORM_FEATURE_APPLICATIONS) &&
            <Col md={3} className={'justify-content-end d-flex'}>
              <Button outline onClick={() => {
                history.push(`${paths.listApplicationPath}/add`);
              }} color={'success'}>Add application</Button>
            </Col>}
        </Row>

        <Card>
          <CardBody>
            <TableWithData
              data={dataApplications?.data}
              tableColumns={tableColumns}
              tableConfig={tableConfig}
              isLoading={isIdleApplications || isLoadingApplications}
            />
          </CardBody>
        </Card>
      </Container>
    </Page>
  )
}


export default ApplicationsList;
