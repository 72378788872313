import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useNotificationUnreadByUser} from "../../../queries/Notification";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import {Card, CardBody, Col, Row} from "reactstrap";
import InfiniteScroll from "react-infinite-scroller";
import Filter from "../../../shared/components/Filter";
import NotificationItem from "./NotificationItem";
import {paramsToObject, useQueryParams} from "../../../shared/components/router/QueryNavigationHelpers";
import _ from "lodash";
import {useHistory} from "react-router-dom";

const UnreadNotificationTab = ({user_uid, notification_sources}) => {
  const LIMIT = 30;
  const history = useHistory();
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const params = paramsToObject(useQueryParams().entries());
  const [sources, setSources] = useState(
    params.sources ?
      _.isArray(params.sources) ? params.sources : [params.sources] :
      notification_sources
  );
  const {data, isIdle, isLoading, isError, error} = useNotificationUnreadByUser(
    user_uid, LIMIT, offset, sources
  );

  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data) {
      if (data.data.length <= LIMIT) {
        setNotifications(notifications.concat(data.data));
      }
      setTotal(data.metadata.total);
    }
  }, [isIdle, isLoading, isError, data?.data, data?.metadata])

  useEffect(() => {
    setNotifications([]);
    setOffset(0);
  }, [sources])

  useEffect(() => {
    if (!params.sources && _.isEmpty(sources) && notification_sources) {
      setSources(notification_sources)
    }
  }, [notifications, params])

  const loadMoreItems = () => {
    if (!isLoading && !isIdle && offset + LIMIT < total) {
      setOffset(offset + LIMIT);
    }
  }

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  return (
    notification_sources.length > 0 ?
      <Row className={'m-0'}>
        <Col className={"justify-content-end col__notification-source-btn"}>
          <Filter
            items={notification_sources}
            buttonText={'Source'}
            setItems={setSources}
            preselectedFilters={sources}
            filterId={'unreadNotification'}
          />
        </Col>

        <Card>
          <CardBody>
            {
              (isLoading || isIdle) && offset === 0 ?
                <LoadingSpinner/> :
                total > 0 ?
                  <InfiniteScroll
                    loadMore={loadMoreItems}
                    hasMore={notifications.length < total}
                    loader={<LoadingSpinner/>}
                  >
                    {
                      notifications.map((notification, index) => (
                        <NotificationItem
                          key={index}
                          notification={notification}
                        />
                      ))
                    }
                  </InfiniteScroll> :
                  <Col>
                    <h4>No new unread notification</h4>
                  </Col>
            }
          </CardBody>
        </Card>
      </Row> :
      <Row className={'m-0'}>
        <Card>
          <CardBody>
            <Col>
              <h4>No new unread notification</h4>
            </Col>
          </CardBody>
        </Card>
      </Row>
  )
}


UnreadNotificationTab.propTypes = {
  user_uid: PropTypes.string.isRequired,
  notification_sources: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default UnreadNotificationTab;
