import React from 'react';
import fileDownload from "js-file-download";
import {getCountryNameOrCodeShort} from "../../../shared/helpers/country-data";
import {pdf} from "@react-pdf/renderer";
import getActorPdfDocument from "./ActorPdfDocument";
import {toast} from "react-hot-toast";
import axios from "axios";
import {apiUrlInternal} from "../../../config/api";
import {addAuthToAxiosOpts} from "../../../config/queryopts";
import {formatDateUTCtoYearMonthDayTimeFile} from "../../../shared/helpers/date";
import moment from "moment/moment";
import {actorExportFormatEnum} from "../../../shared/helpers/download_file";

const _ = require('lodash')

class Actor {

  constructor(
    uid,
    name,
    aliases,
    description,
    first_seen,
    last_seen,
    state_sponsored,
    motivations,
    associated_malwares,
    origin_country,
    targeted_sectors,
    related_content,
    mitre,
    targeted_countries,
    indicators
  ) {
    this.uid = uid;
    this.name = name;
    this.aliases = aliases;
    this.description = description;
    this.first_seen = first_seen;
    this.last_seen = last_seen;
    this.state_sponsored = state_sponsored;
    this.motivations = motivations;
    this.associated_malwares = associated_malwares;
    this.origin_country = origin_country;
    this.targeted_sectors = targeted_sectors;
    this.related_content = related_content;
    this.mitre = mitre;
    this.targeted_countries = targeted_countries;
    this.indicators = indicators;
  }

}

class ActorBuilder {

  constructor() {
  }

  setMainActorData(actorData) {
    this.uid = actorData['uid'];
    this.name = actorData['name'];
    this.aliases = actorData['aliases'];
    this.description = actorData['description'];
    this.first_seen = actorData['first_seen'];
    this.last_seen = actorData['last_seen'];
    this.state_sponsored = actorData['state_sponsored'];
    this.motivations = actorData['motivations'];
    this.associated_malwares = actorData['associated_malwares'];
    return this
  }

  setOriginCountry(origin_country_iso_code) {
    this.origin_country = {
      iso: origin_country_iso_code,
      name: getCountryNameOrCodeShort(origin_country_iso_code)
    };
    return this;
  }

  setTargetedSectors(targetedSectors) {
    this.targeted_sectors = targetedSectors.map(
      x => ({uid: x.id, name: x.name})
    );
    return this;
  }

  setRelatedContent(relatedContent) {
    if ('data' in relatedContent) this.related_content = relatedContent.data;
    return this;
  }

  setTargetedCountries(targeted_countries) {
    this.targeted_countries = targeted_countries.map(
      x => ({iso: x, name: getCountryNameOrCodeShort(x)})
    )
    return this;
  }

  setMitre(actorTechniques, actorTTPs, dataMitreTTPS) {
    this.mitre = [];
    if ('data' in actorTTPs && 'data' in dataMitreTTPS) {
      const techniques = _.uniq(
        _.concat(
          actorTechniques,
          _.flatten(actorTTPs.data.map((tactic) => tactic.techniques)).map((tec) => tec.uid)
        )
      )
      _.forEach(dataMitreTTPS.data, (tactic, index) => {
        const techniquesToExport = [];
        _.forEach(techniques, (t) => {
          _.forEach(tactic.techniques, (technique) => {
            if (t === technique.uid) {
              techniquesToExport.push(technique)
            }
          })
        })
        if (techniquesToExport.length > 0) {
          this.mitre.push({
            uid: tactic.uid,
            name: tactic.name,
            mitre_code: tactic.mitre_code,
            description: tactic.description,
            techniques: techniquesToExport
          });
        }
      })
    }
    return this;
  }

  setIndicators(indicators) {
    if ('data' in indicators) this.indicators = _.sortBy(indicators.data, ['type'], ['asc']);
    return this;
  }

  build() {
    return new Actor(
      this.uid, this.name, this.aliases, this.description, this.first_seen, this.last_seen, this.state_sponsored,
      this.motivations, this.associated_malwares, this.origin_country, this.targeted_sectors, this.related_content,
      this.mitre, this.targeted_countries, this.indicators
    )
  }

}

const exportJSON = (actor, filename) => {
  fileDownload(
    JSON.stringify(actor, null, 4), `${filename}.json`, 'application/json'
  );
}

const exportPDF = (actor, filename) => {
  const actorPdfDocument = getActorPdfDocument(actor);
  // convert Document to blob and download
  pdf(actorPdfDocument).toBlob().then(blob => {
    const a = document.createElement('a');
    a.download = `${filename}.pdf`;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  })

}


const exportActorInformation = function (format, actor, targetedSectors, relatedContent, actorTTPs, mitreTTPs, accessToken) {
  toast.loading('Exporting the actor, it can take a while...')
  const filename = `actor_${actor.name}_${formatDateUTCtoYearMonthDayTimeFile(moment())}`;
  const actorBuilder = new ActorBuilder()
    .setMainActorData(actor)
    .setOriginCountry(actor.origin_country)
    .setTargetedSectors(targetedSectors)
    .setRelatedContent(relatedContent)
    .setMitre(actor.techniques, actorTTPs, mitreTTPs)
    .setTargetedCountries(actor.targeted_countries);
  if (format === actorExportFormatEnum.JSON) {
    axios.get(
      `${apiUrlInternal}/export/indicators?include_info=true&export_format=json&actor=${actor.uid}`,
      addAuthToAxiosOpts(accessToken, {}, 120000)
    ).then(res => {
      actorBuilder.setIndicators(res);
      exportJSON(actorBuilder.build(), filename);
      toast.dismiss();
    }).catch(() => {
      toast.dismiss();
      toast.error('Error during the export of the actor');
    });
  } else if (format === actorExportFormatEnum.PDF) {
    exportPDF(actorBuilder.build(), filename);
    toast.dismiss();
  }
}

export default exportActorInformation;