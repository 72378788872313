import PropTypes from "prop-types";
import {useTypoSquattingAssetResultsQuery} from "../../../queries/TypoSquattingAssetResults";


export const useSearchTypoSquattingQueryWithDict = ({
  project_uid = '', asset_uid = '', search_term = '', next = null, limit = 30,
  length = 0
}) => {
  return useTypoSquattingAssetResultsQuery(project_uid, asset_uid, search_term, next, limit);
}


useSearchTypoSquattingQueryWithDict.propTypes = {
  search_term: PropTypes.string.isRequired
};
