import React, {useState} from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import {Collapse} from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import {useCookieAccessToken} from "../../../queries";
import paths from "../../../config/paths";
import {Redirect, useHistory} from "react-router-dom";
import env_const from "../../../config/env_const";
import {APP_AUTH0_PROVIDER, APP_LOCAL_PROVIDER} from "../../../shared/helpers/validators";
import {useCookies} from "react-cookie";
import {useQueryClient} from "react-query";
import {useAuth0} from "@auth0/auth0-react";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const {isAuthenticated, user} = useCookieAccessToken();
  const {history} = useHistory();
  const [, , removeCookie] = useCookies(['accessToken']);
  const queryClient = useQueryClient();
  const {logout} = useAuth0();

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img
          className="topbar__avatar-img"
          src={(user && user.picture) || (user && user.avatar) || Ava}
          alt="avatar"
        />
        <DownIcon className="topbar__icon"/>
      </button>
      {isCollapsed && <button className="topbar__back" type="button" onClick={toggleProfile}/>}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {isAuthenticated ?
            <React.Fragment>
              <TopbarMenuLink
                title="My Profile"
                icon="user"
                path="/profile"
                onClick={toggleProfile}
              />
              {/*<TopbarMenuLink*/}
              {/*  title="Inbox"*/}
              {/*  icon="inbox"*/}
              {/*  path="/mail"*/}
              {/*  onClick={toggleProfile}*/}
              {/*/>*/}
              {/*<div className="topbar__menu-divider" />*/}
              {/*<TopbarMenuLink*/}
              {/*  title="Account Settings"*/}
              {/*  icon="cog"*/}
              {/*  path="/account/profile"*/}
              {/*  // onClick={toggleProfile}*/}
              {/*  disabled*/}
              {/*/>*/}
              {/*<div className="topbar__menu-divider" />*/}
              <TopbarMenuLink
                title="Log Out"
                icon="exit"
                // path="/logout"
                onClick={() => {
                  queryClient.invalidateQueries(['users-me']);
                  if (env_const.react_app_auth_provider === APP_AUTH0_PROVIDER) {
                    logout({ returnTo: `${window.location.origin}` })
                  } else if (env_const.react_app_auth_provider === APP_LOCAL_PROVIDER) {
                    removeCookie('accessToken');
                    return <Redirect to={paths.homePath}/>
                  }
                  toggleProfile()
                }}
              />
            </React.Fragment>
            :
            <React.Fragment>
              <TopbarMenuLink
                title="Log in"
                icon="enter"
                path="/log_in"
                onClick={() => history.push(paths.loginPath)}
              />
            </React.Fragment>
          }
          {/*<TopbarMenuLink*/}
          {/*  title="Log Out"*/}
          {/*  icon="exit"*/}
          {/*  path="/log_in"*/}
          {/*  onClick={logout}*/}
          {/*/>*/}
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  // user: UserProps.isRequired,
  // User: AuthOProps.isRequired,
};

export default TopbarProfile;
