import {Col, Row} from "reactstrap";
import MenuEntry from "../../../../shared/components/MenuEntry";
import PropTypes from "prop-types";
import React from "react";

const TyposquattingNotificationContent = ({content}) => {
  const {
    domain_name,
    domain_telemetries,
    added_dt,
    ip_telemetries,
  } = content;

  return (
    <Row>
      <Col md={6}>
        <div className="custom_card__container">
          <MenuEntry title={'DOMAIN'} value={domain_name}/>
          <MenuEntry title={'HTTP STATUS'}
                     value={domain_telemetries[0]?.http_status ? domain_telemetries[0].http_status : 'N/A'}/>
          <MenuEntry title={'PORT 80'}
                     value={ip_telemetries[0]?.port_80_status ? ip_telemetries[0].port_80_status : 'N/A'}/>
          <MenuEntry title={'ADDED ON'} value={added_dt}/>
        </div>
      </Col>
      <Col md={6}>
        <div className="custom_card__container">
          <MenuEntry title={'IP ADDRESS'} value={ip_telemetries?.length > 0 ? ip_telemetries[0].ip_address : 'N/A'}/>
          <MenuEntry title={'HTTPS STATUS'}
                     value={domain_telemetries[0]?.https_status ? domain_telemetries[0].https_status : 'N/A'}/>
          <MenuEntry title={'PORT 443'}
                     value={ip_telemetries[0]?.port_443_status ? ip_telemetries[0].port_443_status : 'N/A'}/>
        </div>
      </Col>
    </Row>
  )
}


TyposquattingNotificationContent.propTypes = {
  content: PropTypes.object.isRequired
}


export default TyposquattingNotificationContent;
