import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const IndicatorHashesFetcher = (sha256s, accessToken) => {
  return axios.post(
    `${apiUrlInternal}/indicators/hashes/sha256/bulk`,
    sha256s,
    addAuthToFetchOpts(accessToken, {})).then(res => res.data
  );
}


export const useIndicatorHashesMutation = (fetcher = IndicatorHashesFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['indicator', 'hashes', 'bulk'],
    (accessToken, sha256s) => fetcher(sha256s, accessToken),
    {onSuccess: () => queryClient.invalidateQueries(['indicator', 'hashes', 'bulk'])}
  )
}
