import React, {Fragment, useState} from "react";
import {Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent} from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import {ColorTagInlineBadgeList} from "../badge/BadgeLists";
import CopyIconToClipboard from "../icons/CopyIconToClipboard";
import paths from "../../../config/paths";
import { useHistory } from "react-router-dom";
import {useMitreTTPSQuery} from "../../../queries/MitreTTPS";
import {flatten} from "../../helpers/index";
import LoadingSpinner from "../LoadingSpinner";


const CommandCard = ({commands, withTitle, singleTab}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabCommand, setActiveTabCommand] = useState(0);
  const history = useHistory();

  const { data, isIdle, isLoading, error } = useMitreTTPSQuery();
  const structuredCommands = commands
  if (!commands || commands.length === 0) {
    return <Fragment />
  }
  if (isIdle || isLoading) {
    return <Card><CardBody><LoadingSpinner /></CardBody></Card>;
  }

  if (error) {
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4>Something went wrong getting the Mitre Framework</h4>
          </div>
        </CardBody>
      </Card>
    )
  }

  const mitreTable = data.data;
  const dataMITRE = flatten(mitreTable, 'techniques')
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setActiveTabCommand(tab);
    }
  };

  const onTagClick = (type,val) =>{
    if (type === 'techniques') {
      const match = dataMITRE.find((tech)=> val.includes(tech.mitre_code))
      if (match) {
        val = match.uid
      }
    }
    const urlParams = new URLSearchParams();
    urlParams.set(type, val);
    history.push(`${paths.contentsPath}?${urlParams.toString()}`);
  }



  return (
    <Card>
      <CardBody>
        {withTitle && <div className="card__title">
          <h4 className={'bold-text d-inline'}>Commands</h4>
        </div>}
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            {!singleTab && <Nav tabs className={'commands__nav-title'}>
              {
                structuredCommands.map((command, index) =>
                  <NavItem key={`Command ${index+1}`}>
                    <NavLink
                      className={classnames({active: activeTab === index})}
                      onClick={() => toggleTab(index)}
                      key={`Command ${index+1}`}
                    >
                      {`Command ${index+1}`.toUpperCase()}
                    </NavLink>
                  </NavItem>
                )
              }
            </Nav>}
            <TabContent activeTab={activeTab}>
              <div className="tabs tabs--vertical">
                <div className="tabs__wrap">
                  <TabContent activeTab={activeTab} className={'w-100 h-100'}>
                    <p style={{margin: '20px', textTransform: 'none'}}>{structuredCommands[activeTabCommand].description}</p>
                    <Row className={'my-2 rules__card-body'}>
                      <Col md={8} className={'rules__col-rule-body'}>
                        <CopyIconToClipboard
                          value={structuredCommands[activeTabCommand].value}
                          copiedMessage={'command'}
                          style={{float: 'right'}}
                          toastProps={{position: 'bottom-right'}}
                        />
                        <pre className={'pre--rules'} style={{ textTransform: 'none'}}>{structuredCommands[activeTabCommand].value}</pre>
                      </Col>
                      <Col md={2}>
                        {
                          structuredCommands[activeTabCommand].techniques.length > 0 &&
                          <Fragment>
                            <h5 className={'my-2'}>TECHNIQUES</h5>
                            <ColorTagInlineBadgeList
                              items={structuredCommands[activeTabCommand].techniques.map((technique) => {
                                const match = dataMITRE.find((tech)=>tech.uid === technique)
                                if (match) {
                                  return {name: `${match.mitre_code} - ${match.name}`}
                                }
                                  })}
                              size={'sm'}
                              type="yara_tags"
                              onTagClick={ (val) => { onTagClick('techniques',val)  }}
                            />
                          </Fragment>
                        }
                        {
                          structuredCommands[activeTabCommand].malware_families.length > 0 &&
                          <Fragment>
                            <h5 className={'my-2'}>MALWARE FAMILIES</h5>
                            <ColorTagInlineBadgeList
                              items={structuredCommands[activeTabCommand].malware_families}
                              size={'sm'}
                              type="yara_tags"
                              onTagClick={ (val) => { onTagClick('tags',val)  }}
                            />
                          </Fragment>
                        }
                      </Col>
                    </Row>
                  </TabContent>
                </div>
              </div>
            </TabContent>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

CommandCard.propTypes = {
  commands: PropTypes.array.isRequired
}

export default CommandCard;
