import React from "react";
import PropTypes from "prop-types";
import TableWithData from "../../../../../../shared/components/table/TableWithData";
import {formatDateUTCtoYearMonthDayTimeAMPM} from "../../../../../../shared/helpers/date";
import CountryBadge from "../../../../../../shared/components/badge/CountryBadge";



const tableColumns = [
  { Header: 'Country', accessor: 'country_code', Cell: ({value}) =>  <CountryBadge countryCode={value} />},
  { Header: 'Q-Type', accessor: 'qtype' },
  { Header: 'Date', accessor: 'timestamp', Cell: ({value}) => formatDateUTCtoYearMonthDayTimeAMPM(value) },
];


const tableConfig = {
  withPagination: true,
  isSortable: true,
  manualPageSize: [7],
}


const DnsResolutionTable = ({resolutions}) => {
  return (
    <TableWithData
      data={resolutions}
      tableColumns={tableColumns}
      tableConfig={tableConfig}
    />
  )
}


DnsResolutionTable.propTypes = {
  resolutions: PropTypes.array.isRequired
}


export default DnsResolutionTable;
