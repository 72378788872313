import {Card, CardBody} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import CountryBadge from "../../../shared/components/badge/CountryBadge";
import classNames from "classnames";
import ThemedClampLines from "../clamp-lines/ThemedClampLines";
import {getCountryCode} from "../../helpers/country-data"
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import LockOutlineIcon from "mdi-react/LockOutlineIcon"


const RansomwareTrackerEventCard = ({
  event,
  withTruncateText = false,
  withDecoration = false,
  style = {},
  updateFilters = null
  }) => {
  const classes = classNames({
    "card__with_background_icon": true,
    "card__with_background_icon_decoration": withDecoration
  })

  return (
    <Card style={style} className={classes}>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>
            {event.actor_name} attacks {event.victim}
          </h4>

        </div>
        <LockOutlineIcon style={{'zIndex': '0'}}/>
        <span
          onClick={() => {
            updateFilters({'type': 'victim_country', 'name': event.victim_country})
          }}
          className={`${updateFilters !== null ? 'cursor' : ''}`}
          style={{'position': 'relative', 'zIndex': '1'}}>
                    <CountryBadge countryCode={getCountryCode(event.victim_country) ?
                      getCountryCode(event.victim_country) : ""} style={{fontSize: '12px'}}/>
                </span>
        {
          <p className={'subhead'}><b>{formatDateUTCtoYearMonthDayTime(event.date)}</b></p>
        }
        {
          <p className={'subhead'}><a href={event.website !== 'N/D' ? event.website : ""}>{event.website !== 'N/D' ? event.website : ""}</a></p>
        }

        {withTruncateText ? <p className="description"><ThemedClampLines
          text={event.description ? event.description : ''}
          maxLines={100}
        /></p> : <p>{event.description}</p>}

      </CardBody>
    </Card>
  )
}

RansomwareTrackerEventCard.propType = {
  event: PropTypes.shape().isRequired,
  style: PropTypes.shape().isRequired,
  withLink: PropTypes.bool,
  withTruncateText: PropTypes.bool,
  withDecoration: PropTypes.bool,
  withEditableButton: PropTypes.bool,
  updateFilters: PropTypes.func
}

export default RansomwareTrackerEventCard;
