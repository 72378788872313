import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const eventContentsFetcher = (
  accessToken, include_indicators= false, limit = 0, start = null, end = null,
  actor = null, tags = [], order_by = null, search_term = null, tlps = [],
  targeted_sectors = [], targeted_countries = [], origin_countries = [],
  content_type = null, techniques = [], tactics = [],
  next = null
) => {
  const urlParams = new URLSearchParams();
  if (include_indicators) {
    urlParams.set('include_indicators', true);
  }
  if (limit) {
    urlParams.set('limit', limit);
  }
  if (start) {
    urlParams.set('start', start);
  }
  if (end) {
    urlParams.set('end', end);
  }
  if (actor) {
    urlParams.set('actor', actor);
  }
  if (order_by) {
    urlParams.set('order_by', order_by);
  }
  if (search_term) {
    urlParams.set('search_term', search_term);
  }
  if (content_type) {
    urlParams.set('content_type', content_type);
  }
  if (tags) {
    tags.forEach((tag) => urlParams.append('tags', tag));
  }
  if (targeted_countries) {
    targeted_countries.forEach((country) => urlParams.append('targeted_countries', country));
  }
  if (targeted_sectors) {
    targeted_sectors.forEach((sector) => urlParams.append('targeted_sectors', sector));
  }
  if (origin_countries) {
    origin_countries.forEach((country) => urlParams.append('origin_countries', country));
  }
  if (tlps) {
    tlps.forEach((tlp) => urlParams.append('tlps', tlp));
  }
  if (next !== null) {
    urlParams.set('next', next);
  }
  if (techniques) {
    techniques.forEach((tec) => urlParams.append('techniques', tec));
  }
  if (tactics) {
    tactics.forEach((tac) => urlParams.append('tactics', tac));
  }

  return axios.get(`${apiUrlInternal}/contents?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {}))
    .then(res => res.data);
}

export const useEventContentsQuery = (
  include_indicators = false, limit = 0, start = null, end = null,
  actor = null, tags = [], order_by = null, search_term = null, tlps = [],
  targeted_sectors = [], targeted_countries = [], origin_countries = [], content_type = null,
  techniques = [], tactics = [],
  next = null, enabled = true, fetcher = eventContentsFetcher
) => {
  return useQueryWithAuthentication(
    [
      'event-contents',
      include_indicators, limit, start, end, actor, tags, order_by, search_term, tlps, targeted_sectors,
      targeted_countries, origin_countries, content_type, techniques, tactics , next
    ],
    (accessToken) => fetcher(
      accessToken, include_indicators, limit, start, end, actor, tags, order_by, search_term, tlps, targeted_sectors,
      targeted_countries, origin_countries, content_type, techniques, tactics, next
    ), {cacheTime: 0, enabled: enabled})
}
