import _ from "lodash";
import React, {Fragment} from "react";
import {useActorsQuery} from "../../../queries/Actors";
import ReactCountryFlag from "react-country-flag";
import {Badge} from "reactstrap";
import PropTypes from "prop-types";
import {getBadgeClasses} from "../../helpers/badge";
import {getCountryNameOrCodeShort} from "../../helpers/country-data";


const ActorCountryBadgeList = ({actors = [], size = '', flagOnly = false, style = {}}) => {
  const queries = useActorsQuery(actors.map((actor) => actor.uid));

  if (_.isNull(actors) || _.isUndefined(actors) || _.isEmpty(actors)) {
    return <h5>NO ORIGIN COUNTRIES</h5>
  }

  if (queries.some((q) => q.isLoading || q.isIdle)) {
    return <Fragment />
  }

  let countries = [];
  const classes = getBadgeClasses(size);

  _.forEach(queries, (q) => {
    const c = q.data.data.origin_country;
    if (!_.isNull(c) && !_.isUndefined(c)) {
      countries.push(c);
    }
  });

  if (_.isEmpty(countries)) {
    return <h5>NO ORIGIN COUNTRIES</h5>
  }

  return (
    countries.map((c) =>
      <Badge className={classes} color={'secondary'} style={{...style, ...{}}}>
        <ReactCountryFlag countryCode={c} svg/>
        {!flagOnly && <span className={'ml-1'}>{getCountryNameOrCodeShort(c)}</span>}
      </Badge>
    )
  )
}


ActorCountryBadgeList.propTypes = {
  actors: PropTypes.string.isRequired,
  flagOnly: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object
}


export default ActorCountryBadgeList;
