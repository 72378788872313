import {useRansomwareTrackerEventsQuery} from "../../../queries/RansomwareTrackerEvents";
import PropTypes from "prop-types";


export const
useSearchRansomwareTrackerEventsQueryWithDict = ({
  limit = 0,
  offset = 0,
  order_by = '',
  search_term = '',
  actor_name = '',
  victim_country = '',
  start = '',
  end = ''
}) => {
    return useRansomwareTrackerEventsQuery(
        limit, offset, order_by, search_term, actor_name, victim_country, start, end
    )
}

useSearchRansomwareTrackerEventsQueryWithDict.propTypes = {
    filters: PropTypes.shape().isRequired
};