import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Button, ButtonToolbar} from "reactstrap";
import ThemeModal from "../ThemeModal";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";


const LimitModal = ({onDoAction, onCancel, onPremPlatform}) => {
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const max_intelligence_search = dataCurrentUser ? dataCurrentUser.data.organization.max_intelligence_search : -1;
  const used_intelligence_search = dataCurrentUser ? dataCurrentUser.data.organization.used_intelligence_search : -1;
  const canIViewModal = used_intelligence_search < max_intelligence_search;

  return (
    <Fragment>
      <ThemeModal
        isOpen={true}
        modalClassName={'ltr-support'}
        className={'modal-dialog modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={(e) => {
            onCancel();
          }}/>
          <h4 className="text-modal modal__title">
            Your currently search plan usage is {used_intelligence_search}/{max_intelligence_search}
            <br/>
            {max_intelligence_search > used_intelligence_search ? 'are you sure to go forward and use your limit?' : 'Contact the administrator to extend this limit.'}
          </h4>
          {onPremPlatform && (max_intelligence_search > used_intelligence_search) && <h4 className="text-modal modal__title">
            <br/>
            The intelligence search will be performed on our external sources.
          </h4>}
        </div>
        {
          canIViewModal && <><ButtonToolbar className="modal__footer">
            <Button outline className="rounded" onClick={() => onCancel()}>Cancel</Button>
            <Button outline className="rounded" color='success' onClick={() => {
              onDoAction();
            }}>
              Go
            </Button>
          </ButtonToolbar> </>}
      </ThemeModal>
    </Fragment>
  )
}


LimitModal.propTypes = {
  onDoAction: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPremPlatform: PropTypes.func
}

export default LimitModal;
