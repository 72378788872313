import _ from "lodash";
import {toast} from "react-hot-toast";
import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import fileDownload from "js-file-download";
import {getElementPropertyOrDefault} from "./index";
import {formatDateUTCtoYearMonthDayTimeFile} from "./date";
import moment from "moment";


export const SIEM_INDICATORS = ['ipv4', 'domain', 'md5', 'sha1', 'sha256', 'url'];


const groupIndicatorsByTypes = (indicators) => {
  const groups = {};

  _.forEach(indicators, (ioc) => {
    if (!groups[ioc.type]) {
      groups[ioc.type] = [ioc]
    } else {
      groups[ioc.type].push(ioc)
    }
  });

  return groups;
}


const getConditions = (indicators, equalOperator, fieldMapping, formatTypeCondition, formatCondition) => {
  const conditions = [];

  _.forEach(indicators, (iocs, type) => {
    if (fieldMapping[type]) {
      let typeCondition = [];
      _.forEach(fieldMapping[type], (field) => {
        _.forEach(iocs, (ioc) => {
          typeCondition.push(formatTypeCondition(field, equalOperator, ioc))
        })
      })

      conditions.push(formatCondition(typeCondition))
    }
  });

  return conditions;
}

const getGroupedConditions = (indicators, fieldMapping, formatCondition) => {
  const conditions = [];

  _.forEach(indicators, (iocs, type) => {
    if (fieldMapping[type]) {
      _.forEach(fieldMapping[type], (field) => {
        let typeCondition = [];
        _.forEach(iocs, (ioc) => {
            typeCondition.push(ioc)
          })
        conditions.push(formatCondition(field, typeCondition))
      })
    }
  });

  return conditions;
}


const getGroupedByFieldMappingConditions = (indicators, fieldMapping, formatCondition) => {
  const groupedIndicators = {};
  const conditions = [];

  _.forEach(indicators, (iocs, type) => {
    if (fieldMapping[type]) {
      _.forEach(fieldMapping[type], mappedType => {
        if (!groupedIndicators[mappedType]) {
          groupedIndicators[mappedType] = iocs;
        } else {
          groupedIndicators[mappedType] = groupedIndicators[mappedType].concat(iocs);
        }
      })
    }
  });

  _.forEach(groupedIndicators, (iocs, type) => {
    let typeCondition = [];
    _.forEach(iocs, (ioc) => {
      typeCondition.push(ioc)
    })
    conditions.push(formatCondition(type, typeCondition));
  });

  return conditions;
}


const getRulesMicrosoftSentinel = (indicators) => {
  const equalOperator = "=~";
  const fieldMapping = {
    ipv4: ["SourceIp", "DestinationIP"],
    domain: ["DestinationHostname"],
    md5: ["FileHashMd5"],
    sha1: ["FileHashSha1"],
    sha256: ["FileHashSha256"],
    url: ["URL"]
  };

  // union * | where ((DestinationIP =~ '94.54.136.191' or DestinationIP =~ '94.130.6.212') or (SourceIp =~ '94.54.136.191' or SourceIp =~ '94.130.6.212')
  // or (DestinationHostname =~ 'test1.com' or DestinationHostname =~ 'test2.com') or (FileHashMd5 =~ '3b7f63d394a507bf4c7f136528b48d86')
  // or (FileHashSha1 =~ '2c23ebe87bfe34cb9426558d19a5ca91019acf71') or (FileHashSha256 =~ 'e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203')
  // or (URL =~ 'https://test1.com/' or URL =~ 'https://test2.com/'))

  return `union * | where (${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field} ${equalOperator} '${ioc.value}'`,
      (typeCondition) => `(${_.join(typeCondition, ' or ')})`
    ), ' or ')})`;
}


const getRulesHumio = (indicators) => {
  const equalOperator = "=";
  const fieldMapping = {
    ipv4: ["dst_ip", "src_ip"],
    domain: ["host"],
    md5: ["file.hash.md5"],
    sha1: ["file.hash.sha1"],
    sha256: ["file.hash.sha256"],
    url: ["url"]
  };

  // @stream="http" dst_ip="94.54.136.191" or dst_ip="94.130.6.212" or
  // src_ip="94.54.136.191" or src_ip="94.130.6.212" or host="test1.com" or host="test2.com" or file.hash.md5="3b7f63d394a507bf4c7f136528b48d86"
  // or file.hash.sha1="2c23ebe87bfe34cb9426558d19a5ca91019acf71" or file.hash.sha256="e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203"
  // or url="https://test1.com/" or url="https://test2.com/"

  return `@stream="http" ${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
    (field, op, ioc) => `${field}${equalOperator}"${ioc.value}"`,
    (typeCondition) => `${_.join(typeCondition, ' or ')}`
    ),  ' or ')}`;
}


const getRulesElastic = (indicators) => {
  const fieldMapping = {
    ipv4: ["destination.ip", "source.ip"],
    domain: ["destination.domain"],
    md5: ["file.hash.md5"],
    sha1: ["file.hash.sha1"],
    sha256: ["file.hash.sha256"],
    url: ["url.original"]
  };

  // (destination.ip:("94.54.136.191" OR "94.130.6.212") OR source.ip:("94.54.136.191" OR "94.130.6.212") OR
  // destination.domain:("test1.com" OR "test2.com") OR file.hash.md5:("3b7f63d394a507bf4c7f136528b48d86") OR
  // file.hash.sha1:("2c23ebe87bfe34cb9426558d19a5ca91019acf71") OR
  // file.hash.sha256:("e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203") OR
  // url.original:("https://test1.com/" OR "https://test2.com/"))

  return `(${_.join(
    getGroupedConditions(indicators, fieldMapping,
      (field, typeCondition) => `${field}:(${_.join(typeCondition.map((ioc) => `"${ioc.value}"`), ' OR ')})`
    ),  ' OR ')})`;
}


const getRulesSplunk = (indicators) => {
  const equalOperator = "=";
  const fieldMapping = {
    ipv4: ["dest_ip", "src_ip"],
    domain: ["dest_host"],
    md5: ["file_hash"],
    sha1: ["file_hash"],
    sha256: ["file_hash"],
    url: ["url"]
  };

  // ((dest_ip="94.54.136.191" OR dest_ip="94.130.6.212") OR (src_ip="94.54.136.191" OR src_ip="94.130.6.212") OR
  // (dest_host="test1.com" OR dest_host="test2.com") OR (file_hash="3b7f63d394a507bf4c7f136528b48d86" OR
  // file_hash="2c23ebe87bfe34cb9426558d19a5ca91019acf71" OR
  // file_hash="e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203") OR (url="https://test1.com/"
  // OR url="https://test2.com/"))

  return `(${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field}${equalOperator}"${ioc.value}"`,
      (typeCondition) => `(${_.join(typeCondition, ' OR ')})`
    ),  ' OR ')})`;
}


const getRulesSumo = (indicators) => {
  const equalOperator = "=";
  const fieldMapping = {
    ipv4: ["dest_ip", "src_ip"],
    domain: ["host"],
    md5: ["fileHash"],
    sha1: ["fileHash"],
    sha256: ["fileHash"],
    url: ["url"]
  };

  // ((dst_ip = "94.54.136.191" OR dst_ip = "94.130.6.212") OR (src_ip = "94.54.136.191" OR src_ip =
  // "94.130.6.212") OR (host = "test1.com" OR host = "test2.com") OR (fileHash =
  // "3b7f63d394a507bf4c7f136528b48d86" OR fileHash = "2c23ebe87bfe34cb9426558d19a5ca91019acf71" OR fileHash =
  // "e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203") OR (url = "https://test1.com/" OR url = "https://test2.com/"))

  return `(${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field} ${equalOperator} "${ioc.value}"`,
      (typeCondition) => `(${_.join(typeCondition, ' OR ')})`
    ),  ' OR ')})`;
}


const getRulesSentinelone = (indicators) => {
  const fieldMapping = {
    ipv4: ["DstIP", "SrcIP"],
    domain: ["DNS"],
    md5: ["Md5"],
    sha1: ["Sha1"],
    sha256: ["Sha256"],
    url: ["Url"]
  };

  // (DstIP in contains anycase ("94.54.136.191", "94.130.6.212") OR SrcIP in contains anycase ("94.54.136.191",
  // "94.130.6.212") OR DNS in contains anycase ("test1.com", "test2.com") OR Md5 in contains anycase
  // ("3b7f63d394a507bf4c7f136528b48d86") OR Sha1 in contains anycase
  // ("2c23ebe87bfe34cb9426558d19a5ca91019acf71") OR Sha256 in contains anycase
  // ("e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203") OR Url in contains anycase ("https://test1.com/", "https://test2.com/"))

  return `(${_.join(
    getGroupedConditions(indicators, fieldMapping,
      (field, typeCondition) => `${field} in contains anycase (${_.join(typeCondition.map((ioc) => `"${ioc.value}"`), ' OR ')})`
    ),  ' OR ')})`;
}


const getRulesArcsight = (indicators) => {
  const equalOperator = "=";
  const fieldMapping = {
    ipv4: ["destinationAddress", "sourceAddress"],
    domain: ["destinationDnsDomain"],
    md5: ["fileHash"],
    sha1: ["fileHash"],
    sha256: ["fileHash"],
    url: ["requestUrl"]
  };

  // (destinationAddress = 94.54.136.191 OR destinationAddress = 94.130.6.212 OR sourceAddress = 94.54.136.191 OR
  // sourceAddress = 94.130.6.212 OR destinationDnsDomain = test1.com OR destinationDnsDomain = test2.com OR
  // fileHash = 3b7f63d394a507bf4c7f136528b48d86 OR fileHash = 2c23ebe87bfe34cb9426558d19a5ca91019acf71 OR
  // fileHash = e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203 OR requestUrl =
  // https://test1.com/ OR requestUrl = https://test2.com/) AND type != 2 | rex field = flexString1 mode=sed "s//Sigma: None/g"

  return `(${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field} ${equalOperator} ${ioc.value}`,
      (typeCondition) => `${_.join(typeCondition, ' OR ')}`
    ),  ' OR ')}) AND type != 2 | rex field = flexString1 mode=sed "s//Sigma: None/g`;
}


const getRulesCarbonblack = (indicators) => {
  const equalOperator = ":";
  const fieldMapping = {
    ipv4: ["ipaddr"],
    domain: ["domain"],
    md5: ["md5"],
  };

  // ((ipaddr:94.54.136.191 OR ipaddr:94.130.6.212) OR (domain:test1.com OR domain:test2.com)
  // OR (md5:3b7f63d394a507bf4c7f136528b48d86))

  return `(${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field}${equalOperator}${ioc.value}`,
      (typeCondition) => `(${_.join(typeCondition, ' OR ')})`
    ),  ' OR ')})`;
}


const getRulesGraylog = (indicators) => {
  const fieldMapping = {
    ipv4: ["destination.ip", "source.ip"],
    domain: ["destination.domain"],
    md5: ["file.hash.md5"],
    sha1: ["file.hash.sha1"],
    sha256: ["file.hash.sha256"],
    url: ["url.original"]
  };

  // (destination.ip:("94.54.136.191" "94.130.6.212") OR source.ip:("94.54.136.191" "94.130.6.212") OR
  // destination.domain:("test1.com" "test2.com") OR file.hash.md5:("3b7f63d394a507bf4c7f136528b48d86") OR
  // file.hash.sha1:("2c23ebe87bfe34cb9426558d19a5ca91019acf71") OR
  // file.hash.sha256:("e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203") OR url.original:("https://test1.com/" "https://test2.com/"))

  return `(${_.join(
    getGroupedConditions(indicators, fieldMapping,
      (field, typeCondition) => `${field}:(${_.join(typeCondition.map((ioc) => `"${ioc.value}"`), ' ')})`
    ),  ' OR ')})`;
}


const getRulesLogpoint = (indicators) => {
  const fieldMapping = {
    ipv4: ["dst_ip", "src_ip"],
    domain: ["host"],
    md5: ["hash"],
    sha1: ["hash"],
    sha256: ["hash"],
    url: ["url"]
  };

  // (dst_ip IN ["94.54.136.191", "94.130.6.212"] src_ip IN ["94.54.136.191", "94.130.6.212"] host IN ["test1.com", "test2.com"] hash IN ["3b7f63d394a507bf4c7f136528b48d86",
  // "2c23ebe87bfe34cb9426558d19a5ca91019acf71",
  // "e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203"] url IN ["https://test1.com/", "https://test2.com/"])

  return `(${_.join(
    getGroupedConditions(indicators, fieldMapping,
      (field, typeCondition) => `${field} IN [${_.join(typeCondition.map((ioc) => `"${ioc.value}"`), ' ')}]`
    ),  ' ')})`;
}


const getRulesMicrosoftBitdefender = (indicators) => {
  const equalOperator = "=~";
  const fieldMapping = {
    ipv4: ["RemoteIP", "LocalIP"],
    domain: ["RemoteUrl"],
    md5: ["InitiatingProcessMD5"],
    sha1: ["InitiatingProcessSHA1"],
    sha256: ["InitiatingProcessSHA256"],
    url: ["RemoteUrl"]
  };

  // DeviceNetworkEvents | where ((RemoteIP =~ "94.54.136.191" or RemoteIP =~ "94.130.6.212") or (LocalIP =~
  // "94.54.136.191" or LocalIP =~ "94.130.6.212") or (RemoteUrl =~ "test1.com" or RemoteUrl =~ "test2.com" or
  // RemoteUrl =~ "https://test1.com/" or RemoteUrl =~ "https://test2.com/") or (InitiatingProcessMD5 =~
  // "3b7f63d394a507bf4c7f136528b48d86") or (InitiatingProcessSHA1 =~ "2c23ebe87bfe34cb9426558d19a5ca91019acf71")
  // or (InitiatingProcessSHA256 =~ "e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203"))

  return `DeviceNetworkEvents | where (${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field} ${equalOperator} "${ioc.value}"`,
      (typeCondition) => `(${_.join(typeCondition, ' or ')})`
    ),  ' or ')})`;
}


const getRulesQradar = (indicators) => {
  const equalOperator = "=";
  const fieldMapping = {
    ipv4: ["destinationip", "sourceip"],
    domain: ["Hostname"],
    md5: ["File Hash"],
    sha1: ["File Hash"],
    sha256: ["File Hash"],
    url: ["URL"]
  };

  // SELECT UTF8(payload) from events where ("destinationip" = '94.54.136.191' or "destinationip" =
  // '94.130.6.212') or ("sourceip" = '94.54.136.191' or "sourceip" = '94.130.6.212') or ("Hostname" =
  // 'test1.com' or "Hostname" = 'test2.com') or ("File Hash" = '3b7f63d394a507bf4c7f136528b48d86' or "File Hash"
  // = '2c23ebe87bfe34cb9426558d19a5ca91019acf71' or "File Hash" =
  // 'e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203') or ("URL" = 'https://test1.com/' or "URL" = 'https://test2.com/')

  return `SELECT UTF8(payload) from events where (${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `"${field}" ${equalOperator} '${ioc.value}'`,
      (typeCondition) => `(${_.join(typeCondition, ' or ')})`
    ),  ' or ')})`;
}


const getRulesRsa = (indicators) => {
  const fieldMapping = {
    ipv4: ["ip.dst", "ip.src"],
    domain: ["domain"],
    md5: ["hash"],
    sha1: ["hash"],
    sha256: ["hash"],
    url: ["web.page"]
  };

  // ((ip.dst = '94.54.136.191', '94.130.6.212') or (ip.src = '94.54.136.191', '94.130.6.212') or (domain = 'test1.com', 'test2.com')
  // or (hash = '3b7f63d394a507bf4c7f136528b48d86', '2c23ebe87bfe34cb9426558d19a5ca91019acf71', 'e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203')
  // or (web.page = 'https://test1.com/', 'https://test2.com/'))

  return `(${_.join(
    getGroupedByFieldMappingConditions(indicators, fieldMapping,
      (field, typeCondition) => `(${field} = ${_.join(typeCondition.map((ioc) => `'${ioc.value}'`), ', ')})`
    ),  ' or ')})`;
}


const getRulesQualys = (indicators) => {
  const equalOperator = ":";
  const fieldMapping = {
    ipv4: ["network.remote.address.ip", "network.local.address.ip"],
    domain: ["domain"],
    md5: ["file.hash.md5"],
    sha1: ["file.hash.sha1"],
    sha256: ["file.hash.sha256"],
    url: ["url"]
  };

  // ((network.remote.address.ip:`94.54.136.191` or network.remote.address.ip:`94.130.6.212`) or (
  // network.local.address.ip:`94.54.136.191` or network.local.address.ip:`94.130.6.212`) or (domain:`test1.com`
  // or domain:`test2.com`) or (file.hash.md5:`3b7f63d394a507bf4c7f136528b48d86`) or (
  // file.hash.sha1:`2c23ebe87bfe34cb9426558d19a5ca91019acf71`) or (
  // file.hash.sha256:`e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203`) or (url:`https://test1.com/` or url:`https://test2.com/`))

  return `(${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field}${equalOperator}\`${ioc.value}\``,
      (typeCondition) => `(${_.join(typeCondition, ' or ')})`
    ),  ' or ')})`;
}


const getRulesSecuronix = (indicators) => {
  const equalOperator = "CONTAINS";
  const fieldMapping = {
    ipv4: ["@destinationaddress", "@sourceaddress"],
    domain: ["@destinationhostname"],
    md5: ["@filehash"],
    sha1: ["@filehash"],
    sha256: ["@filehash"],
    url: ["@requesturl"]
  };

  // index = archive AND (@destinationaddress CONTAINS "94.54.136.191" OR @destinationaddress CONTAINS
  // "94.130.6.212") OR (@sourceaddress CONTAINS "94.54.136.191" OR @sourceaddress CONTAINS "94.130.6.212") OR
  // (@destinationhostname CONTAINS "test1.com" OR @destinationhostname CONTAINS "test2.com") OR (@filehash
  // CONTAINS "3b7f63d394a507bf4c7f136528b48d86" OR @filehash CONTAINS "2c23ebe87bfe34cb9426558d19a5ca91019acf71"
  // OR @filehash CONTAINS "e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203") OR
  // (@requesturl CONTAINS "https://test1.com/" OR @requesturl CONTAINS "https://test2.com/")

  return `index = archive AND (${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field} ${equalOperator} "${ioc.value}"`,
      (typeCondition) => `(${_.join(typeCondition, ' OR ')})`
    ),  ' OR ')})`;
}


const getRulesFireeye = (indicators) => {
  const fieldMapping = {
    ipv4: ["dstipv4", "srcipv4"],
    domain: ["domain"],
    md5: ["hash"],
    sha1: ["hash"],
    sha256: ["hash"],
    url: ["url"]
  };

  // (~dstipv4:[`94.54.136.191`,`94.130.6.212`] ~srcipv4:[`94.54.136.191`,`94.130.6.212`]
  // domain:[`test1.com`,`test2.com`] ~hash:[`3b7f63d394a507bf4c7f136528b48d86`,`2c23ebe87bfe34cb9426558d19a5ca910
  // 19acf71`,`e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203`] url:[`https://test1.com/`,`https://test2.com/`])

  return `(${_.join(
    getGroupedByFieldMappingConditions(indicators, fieldMapping,
      (field, typeCondition) => `~${field}[${_.join(typeCondition.map((ioc) => `\`${ioc.value}\``), ' OR ')}]`
    ),  ' ')})`;
}


const getRulesCrowdstrike = (indicators) => {
  const equalOperator = "=";
  const fieldMapping = {
    ipv4: ["RemoteAddressIP4", "LocalAddressIP4"],
    domain: ["DomainName"],
    md5: ["MD5HashData"],
    sha1: ["SHA1HashData"],
    sha256: ["SHA256HashData"],
    url: ["HttpUrl"]
  };

  // ((RemoteAddressIP4="94.54.136.191" OR RemoteAddressIP4="94.130.6.212") (LocalAddressIP4="94.54.136.191" OR
  // LocalAddressIP4="94.130.6.212") (DomainName="test1.com" OR DomainName="test2.com") (
  // MD5HashData="3b7f63d394a507bf4c7f136528b48d86") (SHA1HashData="2c23ebe87bfe34cb9426558d19a5ca91019acf71") (
  // SHA256HashData="e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203") (HttpUrl="https://test1.com/" OR HttpUrl="https://test2.com/"))

  return `(${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field}${equalOperator}"${ioc.value}"`,
      (typeCondition) => `(${_.join(typeCondition, ' OR ')})`
    ),  ' ')})`;
}


const getRulesChronicle = (indicators) => {
  const equalOperator = "=";
  const fieldMapping = {
    ipv4: ["target.ip", "principal.ip"],
    domain: ["target.hostname"],
    md5: ["target.file.md5"],
    sha1: ["target.file.sha1"],
    sha256: ["target.file.sha256"],
    url: ["target.url"]
  };

  // target.ip = "94.54.136.191" or target.ip = "94.130.6.212" or principal.ip = "94.54.136.191" or principal.ip
  // = "94.130.6.212" or target.hostname = "test1.com" or target.hostname = "test2.com" or target.file.md5 =
  // "3b7f63d394a507bf4c7f136528b48d86" or target.file.sha1 = "2c23ebe87bfe34cb9426558d19a5ca91019acf71" or
  // target.file.sha256 = "e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203" or
  // target.url = "https://test1.com/" or target.url = "https://test2.com/"

  return `${_.join(
    getConditions(indicators, equalOperator, fieldMapping,
      (field, op, ioc) => `${field} ${equalOperator} "${ioc.value}"`,
      (typeCondition) => `${_.join(typeCondition, ' or ')}`
    ),  ' ')}`;
}


const getRulesAws = (indicators) => {
  const fieldMapping = {
    ipv4: ["destination.ip", "source.ip"],
    domain: ["destination.domain"],
    md5: ["file.hash.md5"],
    sha1: ["file.hash.sha1"],
    sha256: ["file.hash.sha256"],
    url: ["url.original"]
  };

  // (destination.ip:("94.54.136.191" OR "94.130.6.212") OR source.ip:("94.54.136.191" OR "94.130.6.212") OR
  // destination.domain:("test1.com" OR "test2.com") OR file.hash.md5:("3b7f63d394a507bf4c7f136528b48d86") OR
  // file.hash.sha1:("2c23ebe87bfe34cb9426558d19a5ca91019acf71") OR
  // file.hash.sha256:("e0b1d407ce970837c18c18e6f492ce1d4f7173e68290ab6785f2bc8eba9df203") OR url.original:("https://test1.com/" OR "https://test2.com/"))

  return `(${_.join(
    getGroupedConditions(indicators, fieldMapping,
      (field, typeCondition) => `${field}:(${_.join(typeCondition.map((ioc) => `"${ioc.value}"`), ' OR ')})`
    ),  ' OR ')})`;
}


const export_mapping = {
  microsoft_sentinel: getRulesMicrosoftSentinel,
  humio: getRulesHumio,
  elastic: getRulesElastic,
  splunk: getRulesSplunk,
  sumo: getRulesSumo,
  sentinelone: getRulesSentinelone,
  arcsight: getRulesArcsight,
  carbonblack: getRulesCarbonblack,
  graylog: getRulesGraylog,
  logpoint: getRulesLogpoint,
  microsoft_bitdefender: getRulesMicrosoftBitdefender,
  qradar: getRulesQradar,
  rsa: getRulesRsa,
  qualys: getRulesQualys,
  securonix: getRulesSecuronix,
  fireeye: getRulesFireeye,
  crowdstrike: getRulesCrowdstrike,
  chronicle: getRulesChronicle,
  aws: getRulesAws,
}


const getTmpFileNameRuleFromNow = (format) =>
  `rules-${format}-${formatDateUTCtoYearMonthDayTimeFile(moment())}.rules`


export const downloadSiemRules = (accessToken, filters, format) => {
  const urlParams = new URLSearchParams();
  _.forEach(filters, (value, filter) => urlParams.set(filter, value));
  urlParams.set('include_info', 'false');

  _.forEach(SIEM_INDICATORS, (ioc_type) => {
    urlParams.append('types', ioc_type);
  })

  toast.promise(
    axios.get(`${apiUrlInternal}/indicators?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {})), {
      loading: 'Download started',
      success: res => {
        fileDownload(export_mapping[format](groupIndicatorsByTypes(res.data.data)), getTmpFileNameRuleFromNow(format))
        return 'Download completed successfully';
      },
      error: (error) => `Error: ${getElementPropertyOrDefault(error, 'message', 'unknown')}`
    }
  )
}