import PropTypes from "prop-types";
import {useAddPostMutation} from "../../../../mutations/AddPosts";
import {useHistory} from "react-router-dom";
import {useAddContentFormQuery} from "../../../../queries/AddContentForm";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {getPostParamsWithValidation} from "../../../helpers/contents";
import paths from "../../../../config/paths";
import {getErrorMessageFromResponse} from "../../../helpers";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import LoadingSpinner from "../../LoadingSpinner";
import BasicInformationForm from "./components/BasicInformationForm";
import IocRuleRelCommandForm from "./components/IocRuleRelCommandForm";
import DescriptionCard from "./components/DescriptionCard";
import * as Sentry from "@sentry/react";


const PostForm = ({
  defaultValues = {indicators: [{tags: [], value: '', type: {value: 'domain', label: 'domain'}}]},
  mutationFn = useAddPostMutation,
  postUid,
  editForm = false
}) => {
  let isIdleLoading = false;
  let isError = false;
  const mutation = mutationFn();
  const history = useHistory();
  const formQuery = useAddContentFormQuery();
  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);
  const {register, handleSubmit, formState: {errors}, control, watch, clearErrors, setError, setValue,getValues} = useForm({
    defaultValues: defaultValues
  });

  const onSubmit = (data) => {
    if (isIdleLoading) {
      toast.error('Wait until the form is loaded');
      return;
    } else if (isError) {
      toast.error('There was an error while loading the form');
      return;
    }
    const params = getPostParamsWithValidation(data);
    toast.dismiss();

    if (Object.keys(params.errors).length > 0) {
      Sentry.captureException(params.errors);
      console.log(params.errors);
      for (const [field, value] of Object.entries(params.errors)) {
        setError(field, value);
      }
    } else {
      if (editForm) {
        mutation.mutate({params: params, postUid: postUid});
      } else {
        mutation.mutate(params);
      }
      toast.loading('Validating and uploading post');
    }
  };

  useEffect(() => {
    if (!mutation.isIdle && mutation.isLoading && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }

    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        toast.success(`Post uploaded correctly, you will be redirect`);
        const targetPath = postUid ? `${paths.postPath}/${postUid}` :
          mutation.data?.data?.uid ? `${paths.postPath}/${mutation.data.data.uid}` : paths.contentsPath;
        setTimeout(() => {
          history.push(targetPath);
          toast.dismiss();
        }, 2000);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}`);
        setButtonUploadDisabled(false);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])


  for (const props of Object.values(formQuery)) {
    isIdleLoading |= props.isIdle || props.isLoading;
    isError |= props.isError;
  }

  return (
    <Container>
      <Row className={'div__sticky-top'}>
        <Col md={9}>
          <h3 className="page-title">{editForm ? "Edit" : "New"} Post</h3>
        </Col>
        <Col md={3} className={'justify-content-end d-flex'}>
          <Button outline onClick={() => {
            clearErrors('');
            handleSubmit(onSubmit)()
          }} color={'success'} disabled={buttonUploadDisabled}>{editForm ? 'Edit' : 'Publish'}</Button>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Basic information</h4>
            {
              Object.keys(errors).length > 0 && <span className="form__form-group-error float-right">
                There are some validation errors inside the form
              </span>
            }
          </div>
          {
            isIdleLoading ? <LoadingSpinner /> :
              isError ? <h3>Unable to load the form correctly</h3> :
                <form className="form form--vertical">
                  <BasicInformationForm
                    control={control}
                    register={register}
                    errors={errors}
                    watch={watch}
                    defaultValues={defaultValues}
                    editForm={editForm}
                    isPost
                    setValue={setValue}
                    getValues={getValues}
                  />

                  <IocRuleRelCommandForm
                    control={control}
                    register={register}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                  />
                </form>
          }
        </CardBody>
      </Card>

      <DescriptionCard
        register={register}
      />

    </Container>
  )
}


PostForm.propTypes = {
  defaultValues: PropTypes.shape(),
  mutationFn: PropTypes.func,
  editForm: PropTypes.bool
}


export default PostForm;
