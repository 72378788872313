import {Card, CardBody} from "reactstrap";
import SimpleMapsWithData from "../../../../../../shared/components/maps/EasyVectorMap/SimpleMapsWithData";
import React from "react";
import PropTypes from "prop-types";
import allCountries from "../../../../../../shared/components/maps/EasyVectorMap/sample-data.json";
import _ from "lodash";



const DnsResolutionWorldMap = ({dns_resolutions}) => {
  const resolutionData = () =>  {
    if(!dns_resolutions) return [];
    const resolutionData = Object.values(dns_resolutions.resolutions.reduce((a, {country_code}) => {
      a[country_code] = a[country_code] || {country_code, value: 0};
      a[country_code].value++;
      return a;
    }, Object.create(null)));
    return allCountries.map((country) => {
      const match = _.find(resolutionData, (c) => c.country_code.includes(country.id));
      if (!match) {
        return {id: country.country_code, value: 0}
      } else {
        return {id: match.country_code, value: match.value}
      }
    });
  }
  return <Card>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>DNS Telemetries Source Countries</h4>
      </div>
      {
        <SimpleMapsWithData
          data={resolutionData()}
          height={'45vh'}
          valueLabel={'resolutions'}
          divName={'dns0-countries-resolutions'}
        />
      }
    </CardBody>
  </Card>
}

DnsResolutionWorldMap.propTypes = {
  dns_resolutions: PropTypes.array.isRequired
}


export default DnsResolutionWorldMap;
