import {addAuthToAxiosOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";
import {useQueryClient} from "react-query"


export const UserMFAResetFetcher = (accessToken, uid) => {
  const uri = `/${uid}`;
  return axios.post(`${apiUrlInternal}/users${uri}/reset_mfa`, {}, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useUserMFAResetMutation = (fetcher = UserMFAResetFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['user-reset-mfa'],
    (accessToken, uid) => fetcher(accessToken, uid),
    {onSuccess: () => queryClient.invalidateQueries('user-reset-mfa')}
  )
}

useUserMFAResetMutation.propTypes = {
  uid: PropTypes.string,
};
