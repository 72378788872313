import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import env_const from "../../../../config/env_const";
import paths from "../../../../config/paths";
import {useCurrentUserQuery} from "../../../../queries/CurrentUser";
import {useModulesListQuery} from "../../../../queries/ModuleList";
import {useOrganizationsQuery} from "../../../../queries/Organizations";
import ErrorHandler from "../../ErrorHandler";
import LoadingSpinner from "../../LoadingSpinner";
import Permissions from "../../Permissions";
import RegisteredInputField from "../RegisteredInputField";
import RegisteredPasswordField from "../RegisteredPasswordField";
import ControlledSelectWithTitle from "../ControlledSelectWithTitle";
import {getErrorMessageFromResponse} from "../../../helpers";
import {
  canRoleUpdateAllUsersFields,
  getFormattedFeaturesList, getUserFeature,
  isFeatureExistsForCurrentUser,
  isModuleExistsForCurrentUser,
  userCreatableByRole, userRoleModuleEnabled
} from "../../../helpers/permissions";
import {TLP_LABELS} from "../../../helpers/tlp";
import {useAddUserMutation} from "../../../../mutations/AddUser";
import {APP_LOCAL_PROVIDER} from "../../../helpers/validators";
import {CUSTOMER_ROLE} from "../../../helpers/user";
import RegisteredCheckBoxField from "../RegisteredCheckBoxField";
import {useUserMFAResetMutation} from "../../../../mutations/UserResetMFA";
import ConfirmModal from "../../modal/ConfirmModal";

const _ = require('lodash');


const UserForm = ({
  defaultValues = {},
  mutationFn = useAddUserMutation,
  editUid = null,
  editForm = false,
  userOrganization = {},
}) => {
  const mutation = mutationFn();
  const resetMFAMutation = useUserMFAResetMutation();
  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();
  const {
    data: dataOrganization,
    isIdle: isIdleOrganization,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
    error
  } = useOrganizationsQuery(null, canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role));

  const {
    data: dataModules,
    isIdle: isIdleModules,
    isLoading: isLoadingModules,
    isError: isErrorModules
  } = useModulesListQuery();

  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
    control,
    setValue
  } = useForm({defaultValues: defaultValues});
  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);
  const [groups, setGroups] = useState([]);
  const history = useHistory();
  const [organizationModules, setOrganizationModules] = useState({});

  useEffect(() => {
    if (!mutation.isIdle && mutation.isLoading && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }

    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        toast.success(`Users ${editForm ? 'updated' : 'created'} correctly, you will be redirected.`);
        setTimeout(() => {
          toast.dismiss();
          history.push(paths.listUserPath);
        }, 2000);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
        setButtonUploadDisabled(false);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])

  useEffect(() => {
    if (!resetMFAMutation.isIdle && resetMFAMutation.isLoading && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }

    if (!resetMFAMutation.isIdle && !resetMFAMutation.isLoading) {
      toast.dismiss();
      if (resetMFAMutation.isSuccess) {
        toast.success(`User's MFA reset correctly, you will be redirected.`);
        setTimeout(() => {
          toast.dismiss();
          history.push(paths.listUserPath);
        }, 2000);
      } else if (resetMFAMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(resetMFAMutation)}.`);
        setButtonUploadDisabled(false);
      }
      resetMFAMutation.reset();
    }
  }, [resetMFAMutation.isIdle, resetMFAMutation.isLoading, resetMFAMutation.isError, resetMFAMutation.isSuccess, resetMFAMutation.error])


  useEffect(() => {
    if (!isIdleModules && !isLoadingModules && !isErrorModules) {
      let cdata = [];

      if (!canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role)) {
        let user_org = {}
        if (dataCurrentUser?.data?.role === CUSTOMER_ROLE) {
          user_org = dataCurrentUser?.data?.organization;
        } else {
          user_org = userOrganization;
        }

        const org_modules = {}
        org_modules[user_org?.uid] = user_org?.modules.map((m) => {
          if (editForm) {
            m.selected = isModuleExistsForCurrentUser(defaultValues.modules, m.name);
          }

          m.features.map((f) => {
            if (editForm) {
              f.selected = isFeatureExistsForCurrentUser(defaultValues.modules, f.name);
            }
            return f;
          })
          return m;
        });

        setOrganizationModules(org_modules);
      } else if (editForm) {
        cdata = defaultValues.modules.map((i) => {
          i.selected = isModuleExistsForCurrentUser(defaultValues.modules, i.name)
          if (i.features) {
            i.features = i.features.map((f) => {
              f.selected = isFeatureExistsForCurrentUser(defaultValues.modules, f.name);
              f.permission = getUserFeature(defaultValues.modules, f.name).permission;
              return f;
            });
          }
          return i
        });
      } else {
        cdata = dataModules.data && dataModules.data.map((i) => {
          i.selected = !_.isNull(userRoleModuleEnabled(CUSTOMER_ROLE, i.name));
          if (i.features) {
            i.features = i.features.map((f) => {
              if (f.selected) {
                f.permission = getUserFeature(defaultValues.modules, f.name).permission;
              } else {
                f.selected = !_.isNull(userRoleModuleEnabled(CUSTOMER_ROLE, i.name, f.name));
                f.permission = userRoleModuleEnabled(CUSTOMER_ROLE, i.name, f.name);
              }
              return f;
            });
          }
          return i
        });
      }

      setGroups(cdata);
    }
  }, [isIdleModules, isLoadingModules, isErrorModules]);

  useEffect(() => {
    if (!isIdleModules && !isLoadingModules && !isErrorModules && dataOrganization) {
      const org_modules = {}

      _.forEach(dataOrganization.data, (org) => {
        org_modules[org.uid] = org.modules.map((m) => {
          if (editForm) {
            m.selected = isModuleExistsForCurrentUser(defaultValues.modules, m.name);
          } else {
            m.selected = watch('role')?.value && !_.isNull(userRoleModuleEnabled(watch('role')?.value, m.name));
          }

          m.features.map((f) => {
            if (editForm) {
              f.selected = isFeatureExistsForCurrentUser(defaultValues.modules, f.name);
              f.permission = getUserFeature(defaultValues.modules, f.name).permission;
            } else {
              f.selected = watch('role')?.value && !_.isNull(userRoleModuleEnabled(watch('role')?.value, m.name, f.name));
            }
            return f;
          })
          return m;
        });
      })

      setOrganizationModules(org_modules);
    }

  }, [isIdleOrganization, isLoadingOrganization, isErrorOrganization, dataOrganization])

  useEffect(() => {
    if (!_.isEmpty(organizationModules) && watch('organization_id')?.value) {
      setGroups(organizationModules[watch('organization_id').value]);
    }
  }, [watch('organization_id')?.value])


  if (isLoadingCurrentUser || isIdleModules || isLoadingModules || isIdleCurrentUser || ((isIdleOrganization || isLoadingOrganization) && canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role))) {
    return <LoadingSpinner/>
  }

  if (isErrorOrganization || isErrorModules) {
    return <ErrorHandler error={error}/>
  }

  const tlpOptions = TLP_LABELS.slice(0, dataCurrentUser.data.tlp.id + 1).map((tlp, index) => ({
    value: index,
    label: tlp
  }));
  const organizationOptions = (env_const.is_on_prem || env_const.react_app_auth_provider === APP_LOCAL_PROVIDER) ?
    dataOrganization.data.map((org) => ({value: org.uid, label: org.name})) :
    !canRoleUpdateAllUsersFields(dataCurrentUser.data.role) ?
      [{value: dataCurrentUser?.data?.organization?.uid, label: dataCurrentUser?.data?.organization?.name}] :
      dataOrganization.data.map((org) => ({value: org.uid, label: org.name}));

  const roleOptions = userCreatableByRole(dataCurrentUser.data.role).sort().map((role) => ({value: role, label: role}));

  const onSubmit = (data) => {
    const params = {
      display_name: data.display_name,
      email: data.email,
      role: data.role.value,
      tlp: data.tlp.value,
      private_tlp: data.private_tlp.value,
      active: data.active
    }

    if (data.password) {
      params.password = data.password
    }

    if (data.organization_id.value) {
      params.organization_id = data.organization_id.value;
    }
    params.modules = getFormattedFeaturesList(groups);

    setButtonUploadDisabled(true);
    toast.loading(`Validating and ${editForm ? 'updating' : 'creating'} user.`);

    if (editForm) {
      params.uid = editUid;
      mutation.mutate(params);
    } else {
      mutation.mutate(params);
    }

  }

  const updateSelection = (data) => {
    setGroups(prev => data);
  }

  const onMFAResetSubmit = () => {
    setButtonUploadDisabled(true);
    toast.loading(`Resetting MFA`);
    resetMFAMutation.mutate(editUid);
  }

  return (
    <Container>
      <Row className={'div__sticky-top'}>
        <Col md={9}>
          <h3 className="page-title">{editForm ? 'Update User' : 'New User'} </h3>
        </Col>
        <Col md={3} className={'justify-content-end d-flex'}>
          <Button outline onClick={() => {
            handleSubmit(onSubmit)();
          }} color={'success'} disabled={buttonUploadDisabled}>{editForm ? 'Update' : 'Add'}</Button>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <form className="form form--vertical">
            <Col md={6} xs={12}>
              <RegisteredInputField
                title={'Name'}
                name={'display_name'}
                register={register}
                errors={errors}
                rules={{required: 'The name is required'}}
                disabled={!canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role)}
              />

              <RegisteredInputField
                title={'Email'}
                name={'email'}
                register={register}
                errors={errors}
                rules={{
                  required: 'The email is required',
                  pattern: {value: /^\S+@\S+$/i, message: 'Insert a valid email'}
                }}
                disabled={!canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role)}
              />

              {
                !editForm &&
                <RegisteredPasswordField
                  name={'password'}
                  title={'Password'}
                  register={register}
                  errors={errors}
                  rules={!editForm && {required: 'The password is required'}}
                  disabled={!canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role)}
                />
              }

              <RegisteredCheckBoxField
                name={'active'}
                value={watch('active')}
                onChange={(value) => {
                }}
                defaultChecked={defaultValues.active}
                label={'Is Active'}
                register={register}
                style={{paddingTop: '25px', paddingBottom: '25px'}}
              />
            </Col>

            <Col md={6} xs={12}>
              <ControlledSelectWithTitle
                name={'tlp'}
                title={'TLP'}
                control={control}
                rules={{required: true}}
                defaultValue={editForm ? defaultValues.tlp : tlpOptions[0]}
                options={tlpOptions}
                valueFn={(value) => tlpOptions.find((c) => c.value === value?.value)}
                isDisabled={!canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role)}
              />
              {errors.tlp && <span className="form__form-group-error">{errors.tlp.message}</span>}

              <ControlledSelectWithTitle
                name={'private_tlp'}
                title={'TLP for private content'}
                control={control}
                rules={{required: true}}
                defaultValue={editForm ? defaultValues.private_tlp : tlpOptions[0]}
                options={tlpOptions}
                valueFn={(value) => tlpOptions.find((c) => c.value === value?.value)}
              />
              {errors.private_tlp && <span className="form__form-group-error">{errors.private_tlp.message}</span>}

              <ControlledSelectWithTitle
                name={'organization_id'} title={'Organization'} control={control}
                rules={{required: true}} defaultValue={editForm ? defaultValues.organization : organizationOptions[0]}
                isDisabled={(env_const.is_on_prem || env_const.react_app_auth_provider === APP_LOCAL_PROVIDER || !canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role))}
                options={organizationOptions}
                valueFn={(value) => organizationOptions.find((c) => c.value === value?.value)}
              />
              {errors.organization_id &&
                <span className="form__form-group-error">{errors.organization_id.message}</span>}

              <ControlledSelectWithTitle
                name={'role'} title={'Role'} control={control}
                rules={{required: true}} defaultValue={editForm ? defaultValues.role : roleOptions[0]}
                isDisabled={(env_const.is_on_prem || env_const.react_app_auth_provider === APP_LOCAL_PROVIDER || !canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role))}
                options={roleOptions} valueFn={(value) => value}
              />
              {errors.role && <span className="form__form-group-error">{errors.role.message}</span>}
            </Col>
            <p className="form__form-group-label pl-3">Modules </p>
            <Permissions
              register={register}
              watch={watch}
              groups={groups}
              setValue={setValue}
              onPermissionChange={updateSelection}
            />
          </form>
        </CardBody>
      </Card>

      {
        editForm &&
        <Card>
          <CardBody>
            <Col xs={12}><p className="form__form-group-label py-1"><b>Authentications</b></p></Col>
            <Col md={3} sm={6} xs={12} className={'pt-1'}>
              <ConfirmModal
                btnText={'Reset MFA'}
                onConfirm={() => onMFAResetSubmit()}
                title={'MFA Reset'}
                body={`Are you sure you want to reset ${defaultValues.display_name}'s Multi Factor Authentication?`}/>
            </Col>
          </CardBody>
        </Card>
      }

    </Container>
  )
}


UserForm.propTypes = {
  defaultValues: PropTypes.shape(),
  mutationFn: PropTypes.func,
  modules: PropTypes.array,
  editForm: PropTypes.bool,
}

export default UserForm;
