import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import React, {useMemo} from "react";
import _ from "lodash";
import {CVE_RISK_COLORS, getBaseScore} from "../../../../shared/helpers/cve";
import moment from "moment";
import {formatDateUTCtoYearMonthDay, getDaysArray} from "../../../../shared/helpers/date";
import {LineChart, CartesianGrid, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import getTooltipStyles from "../../../../shared/helpers";


const VulnerabilitiesRiskLineChart = ({vulns}) => {
  const riskVulns  = {
    "low": [],
    "medium": [],
    "high": [],
    "critical": [],
  }

  _.forEach(
    vulns,
    (vuln) => {
      const [, , baseSeverity] = getBaseScore(vuln.nvd_info.impact);
      riskVulns[_.lowerCase(baseSeverity)].push(vuln.created_dt)
    }
  )

  _.forEach(riskVulns, (_, key) => riskVulns[key].sort());

  const minDate = _.min(_.flatten(_.values(riskVulns)));
  const startDate = moment(minDate).subtract(3, 'days');

  const charData = useMemo(() => {
    const days = getDaysArray(startDate, moment());
    const data = [];

    _.forEach(days,
      (d, index) => {
        const item = {};

        if (index === 0) {
          _.forEach(_.keys(riskVulns), (risk) => item[risk] = 0);
        } else {
          _.forEach(
            _.keys(riskVulns),
            (risk) => {
              item[risk] = data[index - 1][risk];
              item[risk] += _.filter(riskVulns[risk],
                (day) => formatDateUTCtoYearMonthDay(day) === formatDateUTCtoYearMonthDay(d)
              ).length;
            }
          );
        }

        item.day = formatDateUTCtoYearMonthDay(d);
        data.push(item);
      }
    )

    return data
  }, [riskVulns])
  return (
    <Card className={'h-auto'}>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">Active CVE risk trend</h4>
        </div>
        <div>
          <ResponsiveContainer height={200} className="dashboard__area">
            <LineChart data={charData} margin={{ top: 20, left: -15, bottom: 20 }}>
              <XAxis
                dataKey={'day'}
                tickLine={false}
                reversed={false}
                minTickGap={charData.length / 2}
              />
              <YAxis />
              <Tooltip
                {...getTooltipStyles('dark', 'defaultItems')}
                formatter={(value, name, props) => value === 0.1 ? 0 : value}
              />
              <Legend />
              <CartesianGrid />
              <Line
                name={'low'}
                dataKey={'low'}
                stroke={CVE_RISK_COLORS['low']}
                type="monotone"
              />
              <Line
                name={'medium'}
                dataKey={'medium'}
                stroke={CVE_RISK_COLORS['medium']}
                type="monotone"
              />
              <Line
                name={'high'}
                dataKey={'high'}
                stroke={CVE_RISK_COLORS['high']}
                type="monotone"
              />
              <Line
                name={'critical'}
                dataKey={'critical'}
                stroke={CVE_RISK_COLORS['critical']}
                type="monotone"
              />
            </LineChart>
          </ResponsiveContainer>
      </div>
      </CardBody>
    </Card>
  )
}


VulnerabilitiesRiskLineChart.propTypes = {
  vulns: PropTypes.arrayOf(PropTypes.object).isRequired
}


export default VulnerabilitiesRiskLineChart;
