import {Controller} from "react-hook-form";
import CreatableSelect from "./CreatableSelect";
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const ControlledCreatableSelectWithTitle = ({
  control,
  title,
  name,
  valueFn,
  rules = {},
  defaultValue = null,
  errors = null,
  ...selectProps
}) => {
  const styles = {
    multiValue: (base, state) =>
      state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base,
    multiValueLabel: (base, state) =>
      state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base,
    multiValueRemove: (base, state) =>
      state.data.isFixed ? { ...base, display: 'none' } : base
  };

  const orderOptions = values =>
    _.isArray(values) ? values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed)) : values;

  const onChangeCustom = (value, { action, removedValue }, onChange) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = value.filter(v => v.isFixed);
        break;
    }

    onChange(orderOptions(value))
  }

  return (
    <div className={`form__form-group ${errors && errors[name] ? "form__form-group-error-validation" : ""}`}>
      <span className="form__form-group-label">{title}</span>
      <div className="form__form-group-field">
        <div className='form__form-group-input-wrap'>
          <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({field: {onChange, value, ref}}) => (
              <CreatableSelect
                onChange={(valueSelect, select) => onChangeCustom(valueSelect, select, onChange)}
                styles={styles}
                inputRef={ref}
                defaultValue={defaultValue}
                value={valueFn(value)}
                {...selectProps}
              />
            )}
          />
          {errors && errors[name] && errors[name].message && <span className="form__form-group-error">{errors[name].message}</span>}
        </div>
      </div>
    </div>
  )
}


ControlledCreatableSelectWithTitle.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  valueFn: PropTypes.func,
  title: PropTypes.string.isRequired,
}


export default ControlledCreatableSelectWithTitle;
