import React, { useEffect} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import vector_map_data from './vector-map-data.json';
import maps from './world';
import PropTypes from "prop-types";
const vector_map = vector_map_data.vector_map;

const strokeColor = '#4ce1b6';
const mapMainColor = '#4ce1b6';
const lightGrayColor = '#F2F2F2';


const heatRule = {
  property: 'fill',
  min: am4core.color('rgb(63, 68, 76)'),
};


const SimpleMapsWithData = ({
  data,
  valueLabel = '',
  width = '100%',
  height = '54vh',
  onCountryClick = null,
  wheelable = false,
  isError = false,
  divName = 'mapDiv'
}) => {
  useEffect(() => {
    const map = am4core.create(divName, am4maps.MapChart);
    map.geodata = maps;
    map.centerMapOnZoomOut = false;
    map.chartContainer.wheelable = wheelable;
    map.chartContainer.cursorOverStyle = am4core.MouseCursorStyle.grab;
    map.chartContainer.cursorDownStyle = am4core.MouseCursorStyle.grabbing;

    // Set projection
    map.projection = new am4maps.projections.Miller();
    const polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
    heatRule.max = am4core.color(mapMainColor);
    heatRule.target = polygonSeries.mapPolygons.template;
    polygonSeries.heatRules.push(heatRule);
    polygonSeries.useGeodata = true;

    const noDataLabel = map.createChild(am4core.Label);
    noDataLabel.text = 'There are no data to show';
    noDataLabel.fontSize = 14;
    noDataLabel.align = 'center';
    noDataLabel.valign = 'middle';
    noDataLabel.fill = am4core.color('#fff');
    noDataLabel.padding(10, 20, 10, 20);
    noDataLabel.background.fill = am4core.color('#232329');
    noDataLabel.background.stroke = am4core.color('#232329');
    noDataLabel.fillOpacity = data.length === 0 && !isError ? 1 : 0;
    noDataLabel.background.strokeOpacity = data.length === 0 && !isError ? 1 : 0;
    noDataLabel.disabled = data.length !== 0;

    const isErrorLabel = map.createChild(am4core.Label);
    isErrorLabel.text = 'Unable to load the data';
    isErrorLabel.fontSize = 14;
    isErrorLabel.align = 'center';
    isErrorLabel.valign = 'middle';
    isErrorLabel.fill = am4core.color('#fff');
    isErrorLabel.padding(10, 20, 10, 20);
    isErrorLabel.background.fill = am4core.color('#232329');
    isErrorLabel.background.stroke = am4core.color('#232329');
    isErrorLabel.fillOpacity = isError ? 1 : 0;
    isErrorLabel.background.strokeOpacity = isError ? 1 : 0;
    isErrorLabel.disabled = !isError;

    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;
    polygonTemplate.fill = am4core.color('rgb(63, 68, 76)');
    polygonTemplate.stroke = am4core.color('rgb(51,54,57)');

    const tooltipFormatter = (target) => {
      const { value } = target.dataItem.dataContext;
      const textValue = value >= 0 ? `: ${value} ${valueLabel}` : '';

      return `${vector_map.countries[target.dataItem.dataContext['hc-key']]}${textValue}`;
    };

    polygonSeries.data = [...data];
    polygonTemplate.adapter.add('tooltipText', (text, target) => tooltipFormatter(target));
    polygonSeries.tooltip.getFillFromObject = false;
    polygonSeries.tooltip.background.fill = am4core.color('rgba(135,152,160, 0.6)');
    polygonSeries.tooltip.label.fill = am4core.color('#000');
    if (onCountryClick) {
      polygonTemplate.events.on("hit", onCountryClick);
      polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    }

    const hs = polygonTemplate.states.create('hover');
    hs.properties.stroke = am4core.color(strokeColor);
    hs.properties.strokeWidth = 2;

    const zoomControl = new am4maps.ZoomControl();
    zoomControl.align = 'left';
    zoomControl.valign = 'middle';
    map.zoomControl = zoomControl;
  }, []);

  return (
    <div id={divName} style={{ width: width, height: height }} />
  );
};

SimpleMapsWithData.propsType = {
  data: PropTypes.array.isRequired,
  height: PropTypes.string,
  valueLabel: PropTypes.string,
  width: PropTypes.string,
  isError: PropTypes.bool,
  wheelable: PropTypes.bool,
  onCountryClick: PropTypes.func,
  divName: PropTypes.string
}

export default SimpleMapsWithData;
