import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";


const _ = require('lodash');


export const IndicatorInvestigateFetcher = (
  accessToken, endpoint_type, indicator, next=null,
) => {

  const urlParams = new URLSearchParams();

  if (indicator) {
    urlParams.set('indicator', indicator);
  }

  if (next) {
    urlParams.set('next', next);
  }

  return axios.get(
    `${apiUrlInternal}/investigate/services/${endpoint_type}?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {}, 40000)
  ).then(res => res.data);
}


export const useIndicatorInvestigateQuery = (
  endpoint_type, indicator, next = null, fetcher = IndicatorInvestigateFetcher
) => {
  return useQueryWithAuthentication(
    ['investigate', endpoint_type, indicator, next],
    (accessToken) => fetcher(accessToken, endpoint_type, indicator, next),
    {staleTIme: Infinity, refetchOnWindowFocus: false, enabled: !_.isUndefined(indicator) && indicator !== ''}
  )
}


useIndicatorInvestigateQuery.propTypes = {
  endpoint_type: PropTypes.string.isRequired,
  indicator: PropTypes.string.isRequired,
  next: PropTypes.string,
};
