import {FilePond} from 'react-filepond'
import React, {useState} from "react";
import 'filepond/dist/filepond.min.css'
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

import _ from "lodash";

const getDefaultValue = (defaultValue) => {
  const files = []
  if (defaultValue && Array.isArray(defaultValue)) {
    _.forEach(defaultValue, (el) => {
      files.push({
        source: el['file_name'],
        options: {
          type: 'local',
          file: {
            name: el['file_name'],
            size: 0,
          },
        }
      })
    });
  } else if (defaultValue) {
    files.push({
      source: defaultValue,
      options: {
        type: 'local',
        file: {
          name: defaultValue,
          size: 0,
        },
      }
    });
  }
  return files;
}

const ControlledFilepond = ({
                              control,
                              name,
                              maxFiles = 1,
                              defaultValue = '',
                              ...filepondProps
                            }) => {
  const [files, setFiles] = useState(() => getDefaultValue(defaultValue));

  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange}}) => (
        <div>
          <FilePond
            files={files}
            maxFiles={maxFiles}
            onupdatefiles={(files) => {
              onChange(files.map(f => ({
                fileSize: f.fileSize,
                filename: f.filename,
                file: f.file
              })));
              setFiles(files);
            }}
            {...filepondProps}
            credits={null}
          />
        </div>)}
    />
  )
}

ControlledFilepond.propTypes = {
  control: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string | PropTypes.array,
  maxFiles: PropTypes.number
}


export default ControlledFilepond;