import React, {useCallback, Fragment} from 'react';
import PropTypes from 'prop-types';

import TableWithDataHook from "../../../shared/components/table/TableWithDataHook";
import {useSandboxTaskDetailsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";

const _ = require('lodash')

const tableColumns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Description', accessor: 'description' },
    { Header: 'Severity', accessor: (row) => row['severity'].toString() },
    { Header: 'Weight', accessor: (row) => row['weight'].toString()},
    { Header: 'Confidence', accessor: (row) => row['confidence'].toString()}
];

const tableConfig = {
    withPagination: true,
    withHover: true,
    withSearchEngine: true,
}

const SandboxTaskSignaturesTable = ({taskId, onRowClick,...props}) => {
    const getRowDataId = useCallback((row, i) => (i), [])
    return (
        <Fragment>
            <TableWithDataHook
                useDataQuery={useSandboxTaskDetailsQuery}
                queryParam={taskId}
                tableColumns={tableColumns}
                tableConfig={tableConfig}
                getRowDataId={getRowDataId}
                dataAccessor={(data) => _.orderBy(data.data.report.signatures,['severity'],['desc'])}
                onRowClick={onRowClick}
                {...props}
            />
        </Fragment>
    );
}

SandboxTaskSignaturesTable.propTypes = {
    onRowClick: PropTypes.func,
};

export default SandboxTaskSignaturesTable;
