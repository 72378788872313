import React, {Fragment, useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Button, ButtonToolbar} from "reactstrap";
import ThemeModal from "../ThemeModal";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";


const TextModal = ({label, title, message, secondMessage = null}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal]);

  return (
    <Fragment>
      <p><a onClick={toggleModal} className={'link__with_decoration'}>{label}</a></p>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal}/>
          <h4 className="text-modal modal__title">{title}</h4>
        </div>
        <div className="modal__body">{message}</div>
        {secondMessage && <div className="modal__body mt-2">{secondMessage}</div>}
      </ThemeModal>
    </Fragment>
  )
}


TextModal.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  secondMessage: PropTypes.string
}

export default TextModal;
