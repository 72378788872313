import {Card, CardBody, Progress} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {toPercent} from "../../../shared/helpers";


const InformativeHostsCveCard = ({
  subtitle,
  hosts,
  totalHosts,
  percentageValue,
  titleColorClass,
  progressBarColorClass
}) => {


  return (
    <Card>
      <CardBody className="resource_monitor-card">
        <div className="resource_monitor-total-container">
          <h5 className={`resource_monitor-title`}>
            <span className={titleColorClass}>{hosts}</span><span className={'resource_monitor-title-total'}>/{totalHosts} HOSTS</span>
          </h5>
        </div>
        <p className="resource_monitor-card-progress-description">{subtitle}</p>
        <div className={`progress-wrap progress-wrap--small progress-wrap--rounded ${progressBarColorClass}`}>
          <p className="resource_monitor-card-progress-label progress__label">{toPercent(percentageValue)}</p>
          <Progress value={percentageValue} />
        </div>
      </CardBody>
    </Card>
  )
}


InformativeHostsCveCard.propTypes = {
  subtitle: PropTypes.element.isRequired,
  percentageValue: PropTypes.number.isRequired,
  hosts: PropTypes.number.isRequired,
  totalHosts: PropTypes.number.isRequired,
  progressBarColorClass: PropTypes.string.isRequired,
  titleColorClass: PropTypes.string.isRequired,
}


export default InformativeHostsCveCard;
