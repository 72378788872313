import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {getVirusTotalLink} from "../../helpers";
import {isIocSearchableOnVT} from "../../helpers/validators";
import env_const from "../../../config/env_const";


const logo = require("../../img/vt.png");


const VirusTotalIndicatorLink = ({value}) => {

  if (!isIocSearchableOnVT(value) || env_const.is_on_prem) {
    return <Fragment />
  }

  return (
    <a
      onClick={(e) => e.stopPropagation()} target={'_blank'}
      href={getVirusTotalLink(value)}
    >
      <img
        src={logo}
        alt={'Search on VirusTotal'}
        title={'Search on VirusTotal'}
        className={'img__vt-icon mr-3 border-hover-white'}
      />
    </a>
  )
}


VirusTotalIndicatorLink.propTypes = {
  value: PropTypes.string.isRequired
}


export default VirusTotalIndicatorLink;
