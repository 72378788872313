import React, {useEffect, useState} from "react";
import {Button, Col, Container, Row} from "reactstrap";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {useProjectsOrganizationTypoSquattingQuery} from "../../queries/ProjectsOrganizationTypoSquatting";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import logo from "../../shared/img/radar.svg";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS} from "../../shared/helpers/features";
import paths from "../../config/paths";
import {useHistory} from "react-router-dom";
import _, {isUndefined} from "lodash";
import TypoSquattingResultLegend from "./components/TypoSquattingResultLegend";
import RegisteredCheckBoxField from "../../shared/components/form/RegisteredCheckBoxField";
import {useForm} from "react-hook-form";
import {useSetTypoSquattingResultsAsSolved} from "../../mutations/TypoSquattingResults/SetTypoSquattingResultsAsSolved";
import {useTypoSquattingDeleteResults} from "../../mutations/TypoSquattingResults/DeleteTypoSquattingResults";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../shared/helpers";
import SearchBar from "../../shared/components/search-menu/SearchBar";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";
import InfiniteScroll from "react-infinite-scroller";
import TypoSquattingResultRow from "./components/TypoSquattingResultRow";
import {useSearchTypoSquattingQueryWithDict} from "./queries/SearchTypoSquatting";


const TypoSquatting = () => {
  const LOADING_STEP = 10;
  const PAGE_SIZE = 10;
  const INITIAL_ITEMS = 10;
  const [next, setNext] = useState(null);
  const [totalTypoSquattingResults, setTotalTypoSquattingResults] = useState(0);
  const [displayTypoSquattingResultsArray, setDisplayTypoSquattingResultsArray] = useState([]);
  const [typoSquattingResultsArray, setTypoSquattingResultsArray] = useState([]);
  const [loadedItems, setLoadedItems] = useState(INITIAL_ITEMS);

  const [currProject, setCurrProject] = useState({});
  const [currAsset, setCurrAsset] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const mutationSetAsSolved = useSetTypoSquattingResultsAsSolved();
  const mutationDeleteResults = useTypoSquattingDeleteResults();

  const {data: dataUser, isIdle: isIdleOrg, isLoading: isLoadingOrg} = useCurrentUserQuery();

  const {
    data: dataTypoSquatting,
    isIdle: isIdleTypoSquatting,
    isLoading: isLoadingTypoSquatting,
    isError: isErrorTypoSquatting,
    error: errorTypoSquatting,
  } = useProjectsOrganizationTypoSquattingQuery(dataUser?.data?.organization?.uid);

  const {
    data,
    isIdle,
    isLoading,
    isRefetching,
    isError,
    error
  } = useSearchTypoSquattingQueryWithDict({
    project_uid: currProject.uid,
    asset_uid: typoSquattingResultsArray.length <= totalTypoSquattingResults && (loadedItems <= totalTypoSquattingResults || totalTypoSquattingResults === 0) ? currAsset.uid : undefined,
    search_term: searchTerm,
    next: next,
    limit: PAGE_SIZE + Math.ceil(loadedItems / PAGE_SIZE)
  });

  useEffect(() => {
    setNext(null);
    setDisplayTypoSquattingResultsArray([]);
    setTypoSquattingResultsArray([]);
    setLoadedItems(INITIAL_ITEMS);
  }, [currProject, currAsset]);


  useEffect(() => {
    if (isIdle || isLoading || !data) {
      setNext(null);
      setDisplayTypoSquattingResultsArray([]);
      setTypoSquattingResultsArray([]);
      setLoadedItems(INITIAL_ITEMS);
    }
  }, [searchTerm]);


  useEffect(() => {
    setSearchTerm('');
  }, [currProject, currAsset]);


  useEffect(() => {
    setCurrAsset({});
  }, [currProject]);


  const loadMoreItems = () => {
    if (typoSquattingResultsArray.length > loadedItems + LOADING_STEP) {
      setDisplayTypoSquattingResultsArray(typoSquattingResultsArray.slice(0, loadedItems + LOADING_STEP));
      setLoadedItems(loadedItems + LOADING_STEP);
    } else if (typoSquattingResultsArray.length === totalTypoSquattingResults) {
      setDisplayTypoSquattingResultsArray(typoSquattingResultsArray);
      setLoadedItems(totalTypoSquattingResults);
    } else if (data?.metadata.next) {
      setNext(data?.metadata?.next);
    }
  }

  const history = useHistory();
  const {register, setValue, watch} = useForm();

  const unselectItems = () => {
    for (let index = 0; index < displayTypoSquattingResultsArray.length; ++index) {
      setValue(`result.${index}`, false);
    }
  }

  const getSelectedResultsIDs = () => {
    const ids = []
    for (let index = 0; index < displayTypoSquattingResultsArray.length; ++index) {
      if (watch(`result.${index}`)) {
        ids.push(displayTypoSquattingResultsArray[index].result_id);
      }
    }

    return ids;
  }

  const setAsSolvedSubmit = () => {
    const ids = getSelectedResultsIDs();

    toast.loading('Setting results as solved...');
    mutationSetAsSolved.mutate({
      project_uid: currProject.uid,
      uids: ids,
    });
  }

  const deleteResultsSubmit = () => {
    const ids = getSelectedResultsIDs();

    toast.loading('Deleting results...');
    mutationDeleteResults.mutate({
      project_uid: currProject.uid,
      uids: ids,
    });
  }


  useEffect(() => {
    if (!mutationSetAsSolved.isIdle && !mutationSetAsSolved.isLoading) {
      toast.dismiss();
      if (mutationSetAsSolved.isSuccess) {
        toast.success('Results marked as solved successfully');
        unselectItems();
        setSearchTerm('');
      } else if (mutationSetAsSolved.isError) {
        toast.error(getErrorMessageFromResponse(mutationSetAsSolved));
        mutationSetAsSolved.reset();
      }
      mutationSetAsSolved.reset();
    }
  }, [
    mutationSetAsSolved.isIdle,
    mutationSetAsSolved.isLoading,
    mutationSetAsSolved.isError,
    mutationSetAsSolved.isSuccess,
    mutationSetAsSolved.error
  ])

  useEffect(() => {
    if (!mutationDeleteResults.isIdle && !mutationDeleteResults.isLoading) {
      toast.dismiss();
      if (mutationDeleteResults.isSuccess) {
        toast.success('Results deleted successfully');
        unselectItems();
        setSearchTerm('');
      } else if (mutationDeleteResults.isError) {
        toast.error(getErrorMessageFromResponse(mutationDeleteResults));
        mutationDeleteResults.reset();
      }
      mutationDeleteResults.reset();
    }
  }, [
    mutationDeleteResults.isIdle,
    mutationDeleteResults.isLoading,
    mutationDeleteResults.isError,
    mutationDeleteResults.isSuccess,
    mutationDeleteResults.error
  ])

  useEffect(
    () => {
      for (let index = 0; index < displayTypoSquattingResultsArray.length; ++index) {
        if (watch(`result.${index}`)) {
          setButtonDisabled(false);
          return
        }

        setButtonDisabled(true);
      }
    },
    [_.map(displayTypoSquattingResultsArray, (_, index) => watch(`result.${index}`))]
  )

  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data) {
      setTotalTypoSquattingResults(data.metadata.total);

      if (!next && displayTypoSquattingResultsArray.length === 0) {
        setTypoSquattingResultsArray(typoSquattingResultsArray.concat(data.data));
        setDisplayTypoSquattingResultsArray(displayTypoSquattingResultsArray.concat(data.data));
        setLoadedItems(INITIAL_ITEMS);
      } else {
        setTypoSquattingResultsArray(typoSquattingResultsArray.concat(data.data));
        setDisplayTypoSquattingResultsArray(displayTypoSquattingResultsArray.concat(data.data));
        setLoadedItems(loadedItems + LOADING_STEP);
      }
    }
  }, [isIdle, isLoading, isError, data])

  const onSelectAll = () => {
    const set = watch('all');

    _.forEach(displayTypoSquattingResultsArray, (_, index) => {
      setValue(`result.${index}`, set)
    })
  }

  if (isIdleTypoSquatting || isLoadingTypoSquatting || isLoadingOrg || isIdleOrg) {
    return <LoadingSpinnerPage/>
  }

  if (isErrorTypoSquatting || isError) {
    return <ErrorHandler error={errorTypoSquatting || error}/>
  }

  if (dataTypoSquatting.data.length === 0) {
    return (
      <Page
        feature={PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS}
        onlyOnline
      >
        <img
          src={logo}
          alt={'Vulnerability Exposure icon'}
          title={'Vulnerability Exposure icon'}
          className={"img__resource-monitor-icon"}
        />
        <Container className={'dashboard'}>
          <Row>
            {
              hasUserEditorPermissionOnFeature(
                dataUser?.data?.modules || [], PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS
              ) &&
              <Col>
                <Button
                  className={"rounded float-right"}
                  size="sm"
                  outline
                  onClick={() => history.push(paths.profilePath)}>
                  Edit asset to monitor
                </Button>
              </Col>
            }
          </Row>
          <Row>
            <Col>
              <h2 className={"h2_investigate-title"}>No Engines Found</h2>
              <h3 className={"h3_investigate-subtitle"}>
                In order to use the Typosquatting module you must create at least one engine. <br/>
                Create a new one in the "Typosquatting Settings" in your profile section.
              </h3>
            </Col>
          </Row>
        </Container>
      </Page>
    )
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS}
      onlyOnline
    >
      <Container className="div__sticky-top">
        <Row>
          <Col>
            {
              dataTypoSquatting.data.map((project) =>
                <Button
                  className={"rounded"}
                  size="sm"
                  outline
                  key={project.uid}
                  active={currProject?.uid === project.uid}
                  onClick={() => setCurrProject(project)}>
                  {project.name}
                </Button>
              )
            }

            {
              hasUserEditorPermissionOnFeature(
                dataUser?.data?.modules || [], PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS
              ) &&
              <Button
                className={"rounded float-right"}
                size="sm"
                outline
                onClick={() => history.push(paths.profilePath)}>
                Edit assets to monitor
              </Button>
            }

          </Col>
        </Row>

        <Row>
          <Col>
            {
              currProject?.assets && currProject.assets.map(
                (asset) =>
                  <Button
                    className={"rounded"}
                    size="sm"
                    outline
                    active={currAsset?.value === asset.value}
                    onClick={() => setCurrAsset(asset)}
                  >
                    {asset.value}
                  </Button>
              )
            }
            {
              totalTypoSquattingResults &&
              <h4 className={'float-right'} style={{marginTop: '13px'}}>{totalTypoSquattingResults} results</h4>
            }
          </Col>
        </Row>

        <Row>
          <Col md={12} className={'mb-2'}>
            <SearchBar
              searchPlaceholder={'Search a domain in the results...'}
              setSearch={setSearchTerm}
              searchTerm={searchTerm}
            />
          </Col>
        </Row>

        <Row>
          <Col style={{paddingLeft: '40px'}}>
            <RegisteredCheckBoxField
              name={'all'}
              value={watch('all')}
              onChange={onSelectAll}
              defaultChecked={false}
              label={'Select All'}
              register={register}
            />
          </Col>
          <Col>
            <Button
              color={'danger'}
              className={'float-right mb-2 py-1 mr-0'}
              disabled={buttonDisabled}
              onClick={deleteResultsSubmit}
            >
              DELETE RESULTS
            </Button>
            <Button
              className={'float-right mb-2 py-1 mx-2'}
              disabled={buttonDisabled}
              onClick={setAsSolvedSubmit}
            >
              MARK AS SOLVED
            </Button>
          </Col>
        </Row>
        <TypoSquattingResultLegend/>
      </Container>

      {
        _.isEmpty(currProject) || _.isEmpty(currProject.assets) &&
        <img
          src={logo}
          alt={'Vulnerability Exposure icon'}
          title={'Vulnerability Exposure icon'}
          className={"img__resource-monitor-icon"}
        />
      }
      <Container>
        {
          !_.isEmpty(currAsset) ?
            (isIdle || isLoading || isRefetching) && displayTypoSquattingResultsArray.length === 0 ?
              <LoadingSpinner/> :
              <Row>
                <Col md={12} xs={12} className={'mx-auto'}>
                  {
                    typoSquattingResultsArray.length > 0 &&
                    <InfiniteScroll
                      loadMore={loadMoreItems}
                      // hasMore={!isUndefined(data?.data) && _.isArray(data.data) && !_.isEmpty(data.data)}
                      hasMore={typoSquattingResultsArray.length < totalTypoSquattingResults}
                      loader={<LoadingSpinner/>}
                    >
                      {
                        displayTypoSquattingResultsArray.map((i, index) => (
                          <TypoSquattingResultRow
                            index={index}
                            result={displayTypoSquattingResultsArray[index]}
                            register={register}
                            watch={watch}
                            setValue={setValue}
                            totalResults={totalTypoSquattingResults}
                          />
                        ))
                      }
                    </InfiniteScroll>
                  }
                </Col>
              </Row> :
            <h4>Select a project and an asset to check the results</h4>
        }

      </Container>
    </Page>
  )
}


export default TypoSquatting;
