import React, {Fragment, useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import TableWithDataHook from "../../../shared/components/table/TableWithDataHook";
import {useSandboxTaskDetailsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";
import ReactTableGeneralCustomizer from "../../../shared/components/table/components/ReactTableGeneralCustomizer";

const _ = require('lodash')

const tableColumns = [
    { Header: 'File', accessor: 'file'},
    { Header: 'Action', accessor: 'type',filter:stringIncludesFilter },
];

const tableConfig = {
    withPagination: true,
    withHover: false,
    withSearchEngine: true,
}

function stringIncludesFilter(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return filterValue.indexOf(rowValue) > -1
    })
}

function mergeArraysDataAccessor(data) {
    if(!data.data.report.behavior.summary)
        return []
    var read_files = data.data.report.behavior.summary.read_files ? data.data.report.behavior.summary.read_files.map(x => ({file:x,type:"Read"})) : [];
    var write_files = data.data.report.behavior.summary.write_files ? data.data.report.behavior.summary.write_files.map(x => ({file:x,type:"Write"})): [];
    var delete_files = data.data.report.behavior.summary.delete_files ? data.data.report.behavior.summary.delete_files.map(x => ({file:x,type:"Delete"})): [];
    return _(read_files)
        .concat( write_files, delete_files)
        .value();
}

const FilterableFilesTable = ({taskId, onRowClick, ...props}) => {
    const [fileStatus, setFileStatus] = useState('ReadWriteDelete')
    const [showReadFiles, setShowReadFiles] = useState(true);
    const [showWriteFiles, setShowWriteFiles] = useState(true);
    const [showDeleteFiles, setShowDeleteFiles] = useState(true);

    const handleClickShowReadFiles = () => {
        if(showReadFiles) {
            setFileStatus(oldStatus => oldStatus.replace('Read',''));
        } else {
            setFileStatus(oldStatus => oldStatus+'Read');
        }
        setShowReadFiles(!showReadFiles);
    }

    const handleClickShowWriteFiles = () => {
        if(showWriteFiles) {
            setFileStatus(oldStatus => oldStatus.replace('Write',''));
        } else {
            setFileStatus(oldStatus => oldStatus+'Write');
        }
        setShowWriteFiles(!showWriteFiles);
    }

    const handleClickShowDeleteFiles = () => {
        if(showDeleteFiles) {
            setFileStatus(oldStatus => oldStatus.replace('Delete',''));
        } else {
            setFileStatus(oldStatus => oldStatus+'Delete');
        }
        setShowDeleteFiles(!showDeleteFiles);
    }

    const getRowDataId = useCallback((row, i) => (row.original.uid), [])

    const filters = [{
        accessorName:'type',
        filterState:fileStatus
    }]

    const arrayTableCustomizerSingleToggle = [
        {
            id: 0, text: 'Read files', func: handleClickShowReadFiles, isChecked: showReadFiles,
        },
        {
            id: 1, text: 'Write files', func: handleClickShowWriteFiles, isChecked: showWriteFiles,
        },
        {
            id: 2, text: 'Deleted files', func: handleClickShowDeleteFiles, isChecked: showDeleteFiles,
        },
    ];

    return (
            <Fragment>
                <div className="react-table__wrapper">
                    <div className="card__title">
                        <h5 className="bold-text">Files</h5>
                    </div>
                    <ReactTableGeneralCustomizer buttonText={"Filter"} arrayTableCustomizerSingleToggle={arrayTableCustomizerSingleToggle}/>
                </div>
                <TableWithDataHook
                    useDataQuery={useSandboxTaskDetailsQuery}
                    queryParam={taskId}
                    tableColumns={tableColumns}
                    tableConfig={tableConfig}
                    getRowDataId={getRowDataId}
                    onRowClick={onRowClick}
                    dataAccessor={(data) => mergeArraysDataAccessor(data)}
                    filters={filters}
                    {...props}
                />
            </Fragment>
    );
}

FilterableFilesTable.propTypes = {
    onRowClick: PropTypes.func,
};

export default FilterableFilesTable;
