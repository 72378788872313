export const PLATFORM_FEATURE_CONTENTS = 'contents';
export const PLATFORM_FEATURE_ACTORS = 'actors';
export const PLATFORM_FEATURE_INVESTIGATE = 'investigate';
export const PLATFORM_FEATURE_GENES_ANALYSIS = 'genes_analysis';
export const PLATFORM_FEATURE_RETROHUNT = 'retrohunt';
export const PLATFORM_FEATURE_SANDBOX = 'sandbox';
export const PLATFORM_FEATURE_TICKETS_ANALYSIS = 'tickets_analysis';
export const PLATFORM_FEATURE_INTELLIGENCE = 'intelligence';
export const PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS = 'identity_monitor_projects';
export const PLATFORM_FEATURE_TAKEDOWN_TICKETS = 'takedown_tickets';
export const PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS = 'resource_monitor_projects';
export const PLATFORM_FEATURE_OBSERVABLES = 'observables';
export const PLATFORM_FEATURE_USERS = 'users';
export const PLATFORM_FEATURE_APPLICATIONS = 'applications';
export const PLATFORM_FEATURE_ORGANIZATIONS = 'organizations';
export const PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS = 'typosquatting_projects';

export const PLATFORM_PERMISSION_EDITOR = 'editor';
export const PLATFORM_PERMISSION_VIEWER = 'viewer';
