import React from "react";
import ActorsCard from "../../../shared/components/card/ActorsCard";
import PropTypes from "prop-types";


const RelatedActorsCard = ({
  actors,
  isKnown = true
}) =>
  <ActorsCard
    actors={actors}
    isKnown={isKnown}
    title={'Related actors'}
    classNames={'mt-2'}
  />


RelatedActorsCard.propTypes = {
  actors: PropTypes.array.isRequired,
  isKnown: PropTypes.bool
}

export default RelatedActorsCard;

