import React, {Fragment, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import paths from "../../../config/paths";
import SearchBarOnEnter from "../../../shared/components/search-menu/SearchBarOnEnter";
import {Col, Container, Row} from "reactstrap";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import {isIP, isValidBotnetSearchValue} from "../../../shared/helpers/validators";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import DarkSearchDomainResults from "../components/DarkSearchDomainResults";
import logo from "../../../shared/img/iceberg.svg";
import AppError from "../../DefaultPage/AppError";
import {paramsToObject, useQueryParams} from "../../../shared/components/router/QueryNavigationHelpers";
import toast from "react-hot-toast";
import LimitModal from "../../../shared/components/modal/LimitModal";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import _ from "lodash";
import is_ip_private from "private-ip";
import {hasUserEditorPermissionOnFeature} from "../../../shared/helpers/permissions";
import {PLATFORM_FEATURE_INVESTIGATE} from "../../../shared/helpers/features";
import env_const from "../../../config/env_const";
import {useIntelligenceSearchQuery} from "../../../queries/IntelligenceSearch/IntelligenceSearch";

const OnPremIntelligenceSearch = () => {
  const params = paramsToObject(useQueryParams().entries());
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState(params.keyword || "");
  const [isEmptySearch, setIsEmptySearch] = useState(true);
  const [searchStartStatus, setSearchStartStatus] = useState(false);
  const [intelResults, setIntelResults] = useState({});
  const [intelSearchTerm, setIntelSearchTerm] = useState("");
  const [searchHasResults, setSearchHasResults] = useState(false);
  const isKeywordValid = (keyword) => keyword.length > 5 && searchStartStatus;
  const [isPrivateIp, setIsPrivateIp] = useState(isIP(params.keyword) && is_ip_private(params.keyword))

  const {
    data: dataCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser,
    error: errorCurrentUser
  } = useCurrentUserQuery();

  const {
    data: dataIntelligence,
    isLoading: isLoadingIntelligence,
    isError: isErrorIntelligence,
    error: errorIntelligence
  } = useIntelligenceSearchQuery((isValidBotnetSearchValue(intelSearchTerm) || isKeywordValid(intelSearchTerm)) ? intelSearchTerm : null, ["BOTNET_EXPOSURE", "CREDENTIAL_EXPOSURE", "DARKSEARCH"]);

  const [limitModalStatus, setLimitModalStatus] = useState(false);

  useEffect(() => {
    if (searchTerm && params.keyword !== searchTerm) {
      history.push(`${paths.intelligenceSearchPath}?keyword=${searchTerm}`);
    }
    setIsEmptySearch(!searchTerm);
  }, [searchTerm])

  const isLoadingResults = isLoadingIntelligence || isLoadingCurrentUser;
  const isErrorResults = isErrorIntelligence && isErrorCurrentUser;
  const emptyResult = {data: {total: 0}};

  useEffect(() => {
    if (!_.isUndefined(dataIntelligence?.data)) {
      toast.dismiss()
      setIntelResults({
        dataCredential: _.isUndefined(dataIntelligence.data.credentials_exposure) ? emptyResult : dataIntelligence.data.credentials_exposure,
        dataBotnet: _.isUndefined(dataIntelligence.data.botnets_exposure) ? emptyResult : dataIntelligence.data.botnets_exposure,
        dataDarkSearch: _.isUndefined(dataIntelligence.data.darksearch) ? emptyResult : dataIntelligence.data.darksearch,
      });
    }

    if (!_.isEmpty(dataIntelligence?.data.botnets_exposure)) {
      let totalIntelx = 0;
      const filteredDataBotnet = dataIntelligence?.data.botnets_exposure.length > 0 ?
        dataIntelligence?.data.botnets_exposure.filter((e) => e.isp && e.ip && e.ip !== 'NULL') : [];

      if (
        dataIntelligence.data.darksearch.length +
        dataIntelligence.data.credentials_exposure.length +
        filteredDataBotnet.length +
        totalIntelx !== 0
      ) {
        setSearchHasResults(true);
      }

    }

  }, [dataIntelligence?.data]);

  let ErrorHandlerForm = <Fragment/>;

  if (isErrorResults || isPrivateIp) {
    const error = errorIntelligence?.request || errorCurrentUser?.request;
    if (!error) {
      ErrorHandlerForm = <Col>
        <AppError header={"Insert a valid IP address"} msg={"You cannot search for internal IP address"}/>
      </Col>
    } else {
      if (error.status === 400) {
        ErrorHandlerForm =
          <Col><AppError header={" "} msg={`The domain "${searchTerm}" is too generic. Try a different one.`}/></Col>
      } else {
        ErrorHandlerForm = <Col><ErrorHandler error={error}/></Col>;
      }
    }
  }

  const customHandle = (val) => {
    if (val && val.length > 0) {
      setLimitModalStatus(true);
      setSearchStartStatus(false);
    } else {
      toast.error('input is missing');
    }
  }

  const onChange = (e) => {
    setIsPrivateIp(isIP(e.target.value) && is_ip_private(e.target.value));
  }

  return (
    <Fragment>
      {(isEmptySearch || _.isEmpty(intelResults)) && !isLoadingResults && !isErrorResults &&
        <img src={logo} alt={'Darkweb icon'} title={'Darkweb icon'} className={"img__darkweb-icon"}/>}
      {!isPrivateIp && limitModalStatus &&
        <LimitModal
          onDoAction={() => {
            toast.loading('Validating your search')
            setLimitModalStatus(false);
            setSearchStartStatus(true);
            setIntelSearchTerm(searchTerm);
            setSearchHasResults(false);
          }}
          onCancel={() => {
            setLimitModalStatus(false);
          }}
          onPremPlatform={env_const.is_on_prem}
        />
      }
      <Container>
        <Row>
          <Col md={12}>
            <SearchBarOnEnter
              searchPlaceholder={'Type any keyword and press enter'}
              setSearch={setSearchTerm}
              searchTerm={searchTerm}
              errorMessage={searchTerm && searchTerm.length <= 5 && 'Insert a keyword longer than 5 characters'}
              withSearchButton
              customHandle={customHandle}
              buttonDisabled={isPrivateIp}
              onChangeHandler={onChange}
              searchDisabled={
                !hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_INVESTIGATE) ||
                isPrivateIp
              }
            />
          </Col>
        </Row>
        {searchHasResults && isLoadingResults && <Row><LoadingSpinner/></Row>}
        <Row>
          {
            (isErrorResults || isPrivateIp) ? ErrorHandlerForm :
              (isEmptySearch || _.isEmpty(intelResults)) && !isLoadingResults ?
                <Col>
                  <h2 className={"h2_darkweb-title"}>Explore the Dark Web</h2>
                  <h3 className={"h3_darkweb-subtitle"}>
                    Try typing any keyword in the search bar to discover the related information gathered through our
                    intelligence.
                  </h3>
                </Col> :
                (!searchHasResults && isLoadingResults) ? <LoadingSpinner/> :
                  <DarkSearchDomainResults
                    dataCredential={isErrorIntelligence ? emptyResult : {
                      data: {
                        credentials: intelResults.dataCredential,
                        total: intelResults.dataCredential.length
                      }
                    }}
                    dataBotnet={isErrorIntelligence || !isValidBotnetSearchValue(intelSearchTerm) ? emptyResult : {
                      data: {
                        events: intelResults.dataBotnet,
                        total: intelResults.dataBotnet.length
                      }
                    }}
                    dataDarkSearch={isErrorIntelligence ? emptyResult : {
                      data: {...intelResults.dataDarkSearch}
                    }}
                    keyword={intelSearchTerm}
                    isLoadingResults={isLoadingResults}
                    dataIntelligenceStats={emptyResult}/>
          }
        </Row>
      </Container>
    </Fragment>
  )
}


export default OnPremIntelligenceSearch;
