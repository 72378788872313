import TimeLineChartCard from "../../../../../../shared/components/charts/TimeLineChartCard";
import React from "react";
import PropTypes from "prop-types";
import {getCountryNameOrCodeShort} from "../../../../../../shared/helpers/country-data";
import moment from "moment";
import DnsResolutionHistogramTooltip from "../../../../../../shared/components/tooltips/DnsResolutionHistogramTooltip";

const DnsResolutionTimelineHistogram = ({dns_resolutions}) => {

  const data = dns_resolutions?.histogram ? dns_resolutions?.histogram.map(element => ({
    'created_dt': element.date,
    'hits': element.count,
    'tooltip': element.resolutions.map(res => ({value: `${res.count}`, name: `${getCountryNameOrCodeShort(res.country_code)}`,}))
  })) : []
  const beginDate = data.length ? moment(
    Math.min(
      ...data.map(element => {
        return moment(element.created_dt);
      }),
    ),
  ) : moment().set({years: 2021, date: 1, month: 0})

  return <TimeLineChartCard
    title={'DNS Telemetries history'}
    dataName={'Resolutions activity'}
    dataKey={'count'}
    items={data}
    beginDate={beginDate}
    monthlyScale={!data.length}
    CustomToolTip={DnsResolutionHistogramTooltip}
  />

}


DnsResolutionTimelineHistogram.propTypes = {
  dns_resolutions: PropTypes.array.isRequired
}


export default DnsResolutionTimelineHistogram;