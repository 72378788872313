import {addAuthToFetchOpts} from '../../config/queryopts'
import {useMutationWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SubmitTicketMessageFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/tickets/${params.uid}/messages/send`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useAddTicketMessageMutation = (fetcher = SubmitTicketMessageFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-ticket-message'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries('add-ticket-message')
        queryClient.invalidateQueries(['tickets', `/${params.uid}/messages`])
      }
    }
  )
}
