import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


const _ = require('lodash');


export const EditUserFetcher = (accessToken, params) => {
  return axios.put(
    `${apiUrlInternal}/users/${params.uid}`, _.omit(params, ['uid']), addAuthToFetchOpts(accessToken, {})
  ).then(res => res);
}

export const useEditUserMutation = (fetcher = EditUserFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['update-user'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: (_, {uid}) => {
      queryClient.invalidateQueries(['user-details'])
      queryClient.invalidateQueries(['user-details', uid])
      queryClient.invalidateQueries('dogma-block-list')
    }}
  )
}

