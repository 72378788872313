import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import IndicatorRiskScoreGauge from "../../IndicatorRiskScoreGauge";
import RelatedActorsCard from "../../RelatedActorsCard";
import IndicatorCveCard from "./components/IndicatorCVECard";
import IndicatorsTabWithTableCard from "../../../../../shared/components/card/IndicatorsTabWithTableCard";
import PropTypes from "prop-types";
import ContentTimeLineCard from "../../ContentTimeLineCard";
import CveReferencesList from "./components/CveReferencesList";
import CveScoreGaugeCard from "./components/CveScoreGaugeCard";
import {getBaseScore, getCommonReferences, getCveItems, getExploitsReferences} from "../../../../../shared/helpers/cve";
import IndicatorDetailFooter from "../../IndicatorDetailFooter";
import _ from "lodash";
import CvePublicExploitList from "./components/CvePublicExploitList";
import FirstLastSeenRow from "../../FirstLastSeenRow";

const CveDetails = ({indicator_details}) => {
  const cve = indicator_details.cve_info;
  const [baseScore, cvssVersion, baseSeverity] = getBaseScore(cve?.impact);
  const cveItems = getCveItems(cve?.impact, cvssVersion);

  return (
    <Fragment>
      <Row>
        <Col lg={5} md={12}>
          <Row>
            <Col>
              <IndicatorRiskScoreGauge
                score={!indicator_details.whitelisted ? indicator_details.score : 0}
                tags={indicator_details.tags}
              />
              <FirstLastSeenRow last_seen={indicator_details.last_seen} first_seen={indicator_details.first_seen} />
              <CveScoreGaugeCard baseScore={baseScore} cvssVersion={cvssVersion} baseSeverity={baseSeverity}/>
              <RelatedActorsCard actors={indicator_details.actors} isKnown={indicator_details.is_known}/>
            </Col>
          </Row>
        </Col>

        <Col lg={7} md={12}>
          <Row>
            <Col>
              {!_.isNull(cve) && <IndicatorCveCard cve={cve} cveItems={cveItems}/>}
              {indicator_details.related_contexts.length > 0 &&
                <ContentTimeLineCard contexts={indicator_details.related_contexts}/>}
            </Col>
          </Row>
        </Col>
      </Row>

      {
        indicator_details.related_indicators.length > 0 &&
        <Row className={'mt-4'}>
          <Col>
            <IndicatorsTabWithTableCard
              indicators={indicator_details.related_indicators}
              title={'Related indicators'}
            />
          </Col>
        </Row>
      }

      {
        !_.isNull(cve) && getExploitsReferences(cve.references).length > 0 &&
        <Row>
          <Col>
            <CvePublicExploitList refs={getExploitsReferences(cve.references)}/>
          </Col>
        </Row>
      }

      {
        !_.isNull(cve) && getCommonReferences(cve.references).length > 0 &&
          <Row>
            <Col>
              <CveReferencesList refs={getCommonReferences(cve.references)}/>
            </Col>
          </Row>
      }

      <IndicatorDetailFooter
        indicator_details={indicator_details}
        excludeIntelxTable
      />

    </Fragment>
  )
}


CveDetails.propTypes = {
  indicator_details: PropTypes.object.isRequired
}

export default CveDetails;