import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Card, CardBody} from "reactstrap";
import ContentTimeLine from "./ContentTimeLine";
import {formatDateUTCtoYearMonthDay} from "../../../shared/helpers/date";


const ContentTimeLineCard = ({contexts}) => {
  const contents = contexts.filter((c) => c.type !== 'collection');

  if (contents.length === 0) {
    return <Fragment />
  }

  const lastSeen = contents.map((content) => content.created_dt).sort().reverse()[0];

  return (
    <Card className={'h-auto'}>
      <CardBody className={'card__body-content-timeline'}>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Related contents</h4>
          <p className={'subhead'}>Last seen on {formatDateUTCtoYearMonthDay(lastSeen)}</p>
        </div>
        <ContentTimeLine contents={contents} />
      </CardBody>
    </Card>
  )
}



ContentTimeLineCard.propTypes = {
  contexts: PropTypes.array.isRequired,
}

export default ContentTimeLineCard;