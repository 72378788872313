import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Col, Row} from "reactstrap";
import MenuEntryWithCopyIcon from "../../../../../../shared/components/MenuEntryWithCopyIcon";
import VirusTotalIndicatorLink from "../../../../../../shared/components/icons/VirusTotalIndicatorLink";
import {ColorTagInlineBadgeList} from "../../../../../../shared/components/badge/BadgeLists";
import MenuEntry from "../../../../../../shared/components/MenuEntry";
import DownloadSha256Icon from "../../../../../../shared/components/icons/DownloadSha256Icon";
import env_const from "../../../../../../config/env_const";
import { bytesToSizeOrNA } from "../../../../../../shared/helpers";


const IndicatorHashCard = ({file_info, indicator, indicator_type}) =>
  <Card className={'h-auto'}>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>File details</h4>
        <span className={'float-right'}>
          {file_info?.ssdeep && !env_const.is_on_prem && <DownloadSha256Icon sha256={file_info.sha256} />}
          <VirusTotalIndicatorLink value={indicator}/>
        </span>
      </div>

      <Row className={'mx-md-0 card__body-file-details '}>
        <Col>
          <div className="custom_card__container m-0 p-0">
            {
              file_info ?
                <Fragment>
                  <MenuEntry
                    title={'FILE TYPE DESCRIPTION'} value={file_info.type_description}
                    valueStyle={{marginLeft: '30px'}} titleStyle={{marginLeft: '30px'}}
                  />
                  <MenuEntryWithCopyIcon title={'MD5'} value={file_info.md5} />
                  <MenuEntryWithCopyIcon title={'SHA1'} value={file_info.sha1} />
                  <MenuEntryWithCopyIcon title={'SHA256'} value={file_info.sha256} />
                  <MenuEntryWithCopyIcon title={'SSDEEP'} value={file_info.ssdeep} />
                  <MenuEntryWithCopyIcon title={'FILE SIZE'} value={bytesToSizeOrNA(file_info.size)} />
                  {
                    file_info.names.length > 0 &&
                      <Fragment>
                        <p className="custom_card__container-subhead" style={{marginLeft: '30px'}}>FILE NAMES</p>
                        {
                          file_info.names.map((name) =>
                            <p className="custom_card__container-title" style={{marginLeft: '30px'}}>{name}</p>
                          )
                        }
                      </Fragment>
                  }
                </Fragment> :
                <MenuEntryWithCopyIcon title={indicator_type.toUpperCase()} value={indicator} />
            }
          </div>
        </Col>
      </Row>

      <Row className={'mt-3'}>
        <Col>
          <h4><span className={'float-right'}><ColorTagInlineBadgeList items={file_info?.tags?.map((tag) => ({name: tag})) || []}/></span></h4>
        </Col>
      </Row>

    </CardBody>
  </Card>


IndicatorHashCard.propTypes = {
  file_info: PropTypes.shape().isRequired,
  indicator: PropTypes.string.isRequired,
  indicator_type: PropTypes.string.isRequired,
}


export default IndicatorHashCard;
