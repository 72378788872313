import React from "react";


const ReferencesRow = ({references}) =>
  <div className="custom_card__container" style={{paddingLeft: '0px'}}>
    <p className="custom_card__container-subhead">REFERENCES</p>
    {
      references.map((url) =>
        <p className="custom_card__container-title">
          <a href={url} target={'_blank'} className={'link__with_decoration'}>{url}</a>
        </p>
      )
    }
  </div>


export default ReferencesRow;
