import React, {Fragment, useCallback, useState} from "react";
import PropTypes from "prop-types";
import ThemeModal from "../../ThemeModal";
import IndicatorsCollapses from "./components/IndicatorsCollapses";


const Index = ({indicators, iocType}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal]);

  return (
    <Fragment>
      <p
        className="chart__small_card__total-label-modal"
        onClick={() => {toggleModal();}}
      >Show complete indicators list</p>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header-lg modal-dialog--dark'}
      >
        <div className="modal__header" key={"modal__header"}>{iocType.toUpperCase()}</div>
        <div className="modal__body" key={"modal__body"}>
          {indicators?.map((ioc) => <IndicatorsCollapses ioc={ioc} />)}
        </div>

      </ThemeModal>
    </Fragment>
  )
}

Index.propTypes = {
  indicators: PropTypes.array.isRequired,
  iocType: PropTypes.string.isRequired
}

export default Index;
