import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Button, ButtonToolbar, Col, Container, Row} from 'reactstrap';
import ThemeModal from "../../../shared/components/ThemeModal";
import {useForm} from "react-hook-form";
import ControlledSelectWithTitle from "../../../shared/components/form/ControlledSelectWithTitle";
import {getErrorMessageFromResponse} from "../../../shared/helpers";
import toast from "react-hot-toast";
import RegisteredInputField from '../../../shared/components/form/RegisteredInputField';
import ControlledFilepond from '../../../shared/components/form/ControlledFilepond';
import {useCookieAccessToken} from '../../../queries';
import {apiUrlInternal} from '../../../config/api';
import axios from 'axios';
import {addAuthToAxiosOpts} from '../../../config/queryopts';
import ErrorHandler from '../../../shared/components/ErrorHandler';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import {TLP_LABELS} from '../../../shared/helpers/tlp';
import {
  useGetTakedownTicketByValue,
  useTakedownCategoryQuery,
  useTakedownConfidenceQuery,
  useTakedownIndicatorTypeQuery
} from '../../../queries/TakeDownService';
import {useIndicatorDetailsByValueQuery} from '../../../queries/IndicatorInvestigate';
import {useAddTakdownTicketMutation} from '../../../mutations/TakeDown/AddTicket';
import {ColorTagInlineBadgeList} from '../../../shared/components/badge/BadgeLists';
import {getColorByScore, getRiskLabelByScore} from '../../../shared/helpers/risk_score';
import {ALLOWED_ATTACHMENT_FORMATS, isFilesValid, isIndicatorValid} from '../helper';
import _ from "lodash";
import {genericSectionsOnly} from "../../../shared/helpers/investigate";
import PropTypes from "prop-types";
import {useQueryClient} from "react-query";


const TakeDownModal = ({
  disabled = false,
  classNames = "",
  indicator = null,
  buttonLabel = "Open Ticket",
  checkTicketExists = false
}) => {
  const {data, isIdle, isLoading, isError, error} = useTakedownCategoryQuery();
  const {
    data: itData,
    isIdle: itIsIdle,
    isLoading: itIsLoading,
    isError: itIsError,
    error: itError
  } = useTakedownIndicatorTypeQuery();
  const {
    data: cData,
    isIdle: cIsIdle,
    isLoading: cIsLoading,
    isError: cIsError,
    error: cError
  } = useTakedownConfidenceQuery();
  const {
    data: accessToken,
    isError: tokenIsError,
    isLoading: tokenIsLoading,
    isIdle: tokenIsIdle,
    error: tokenError,
  } = useCookieAccessToken()
  const mutation = useAddTakdownTicketMutation();

  const [showModal, setShowModal] = useState(false);
  const [uploadBtnDisabled, setUploadBtnDisabled] = useState(false);
  const [confidenceData, setConfidenceData] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [indicatorType, setIndicatorType] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [indicatorTypesData, setIndicatorTypesData] = useState([]);
  const [consentModalStatus, setConsentModalStatus] = useState(false);
  const [indicatorBtnStatus, setIndicatorBtnStatus] = useState(!_.isNull(indicator));
  const [searchValue, setSearchValue] = useState(undefined);

  const queryClient = useQueryClient();

  const {register, handleSubmit, getValues, watch, formState: {errors}, control} = useForm(
    {defaultValues: {indicator: indicator}}
  );

  const toggleModal = useCallback(() => setShowModal(!showModal), [setShowModal, showModal]);
  const watchIndicator = watch("indicator", '');
  const watchIndicatorType = watch("indicator_type", '');

  const {
    data: iocData,
    isIdle: iocIsIdle,
    isLoading: iocIsLoading,
    isError: iocError
  } = useIndicatorDetailsByValueQuery(searchTerm, indicatorType, true, genericSectionsOnly);

  const {
    data: ticketData,
    isIdle: ticketIsIdle,
    isLoading: ticketIsLoading,
  } = useGetTakedownTicketByValue(searchValue);

  const onSubmit = (data) => {
    // if (!data.consent) {
    //   toast.error('Accept the terms of condition to proceed with the takedown request');
    //   return;
    // }

    if (data.report && data.report.length > 0 && data.consent) {
      if (!isFilesValid(data.report)) {
        toast.error('uploaded files are not valid ex: allowed formats zip,rar,email,png,jpeg,jpg')
      } else {
        setUploadBtnDisabled(true);
        const formData = new FormData();
        _.forEach(data.report, (report) => {
          formData.append('files', report.file, report.name)
        });
        toast.loading('Validating and uploading files.');
        axios.post(`${apiUrlInternal}/takedown_tickets/attachment`, formData,
          addAuthToAxiosOpts(accessToken, {'Content-Type': 'multipart/form-data'}, 0),
        ).then((res) => {
          if (res.status === 200) {
            toast.dismiss();
            toast.loading('Validating and creating the takedown ticket');
            let attachments = res.data.data.map((i) => {
              return i.uid
            });
            mutation.mutate({
              description: data.description,
              indicator: data.indicator,
              indicator_type: data.indicator_type.value,
              confidence: data.confidence.value,
              category: data.category.value,
              tlp: data.tlp.value,
              attachments: attachments
            });
          } else {
            toast.dismiss();
            toast.error(`Error while uploading the attachments. Please contact the administrator.`);
            setUploadBtnDisabled(false);
          }
        }).catch(() => {
          toast.dismiss();
          toast.error(`Error while uploading the attachments. Please contact the administrator.`);
          setUploadBtnDisabled(false);
        })
      }
    } else {
      toast.loading('Validating and creating the takedown ticket');
      mutation.mutate({
        description: data.description,
        indicator: data.indicator,
        indicator_type: data.indicator_type.value,
        confidence: data.confidence.value,
        category: data.category.value,
        tlp: data.tlp.value
      });
    }
  }

  const onGetIocData = () => {
    let i = getValues("indicator");
    let i_type = getValues("indicator_type");

    if (isIndicatorValid(i, i_type)) {
      setSearchTerm(i);
      setIndicatorType(i_type.value);
    } else {
      toast.error('Indicator missing or not valid');
    }
  }

  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setCategoryData(data.data);
    }
  }, [isIdle, isLoading, isError, data])

  useEffect(() => {
    if (!cIsIdle && !cIsLoading && !cIsError) {
      setConfidenceData(cData.data);
    }
  }, [cIsIdle, cIsLoading, cIsError, cData])

  useEffect(() => {
    if (!itIsIdle && !itIsLoading && !itIsError) {
      setIndicatorTypesData(itData.data);
    }
  }, [itIsIdle, itIsLoading, itIsError, itData])


  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      setUploadBtnDisabled(false);
      if (mutation.isSuccess) {
        toast.success(`Your ticket has been created`);
        queryClient.invalidateQueries(['takedown-tickets', `?value=${searchValue}`]);
        toggleModal();
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.error, mutation.isSuccess])


  const tlpOptions = TLP_LABELS.map((tlp, index) => ({value: index, label: tlp}));
  const categoryOptions = categoryData && categoryData.length > 0 ? categoryData.map((i) => ({value: i, label: i})) : [];
  const indicatorTypes = indicatorTypesData && indicatorTypesData.length > 0 ? indicatorTypesData.map((i) => ({value: i, label: i})) : [];
  const confidenceOptions = confidenceData && confidenceData.length > 0 ? confidenceData.map((i) => ({value: i, label: i})) : [];
  const indicatorDetails = iocData?.data;
  const indicatorTags = indicatorDetails && indicatorDetails.tags ? indicatorDetails.tags.concat(indicatorDetails.actors) : [];

  useEffect(() => {
    setIndicatorBtnStatus(isIndicatorValid(watchIndicator, watchIndicatorType));
  }, [watchIndicator, watchIndicatorType])


  if (tokenIsIdle || tokenIsLoading || cIsLoading || cIsIdle || itIsIdle || itIsLoading || isIdle || isLoading) {
    return <Container><LoadingSpinner/></Container>
  }

  if (tokenIsError || cIsError || itIsError || isError) {
    return <ErrorHandler error={tokenError || cError || itError || error}/>
  }

  const indicatorBtnClass = indicatorBtnStatus ? 'rounded btn btn-outline-success' : 'disabled rounded btn btn-outline-success';
  const uploadBtnClass = uploadBtnDisabled ? 'disabled rounded float-right mr-0' : 'rounded float-right mr-0';

  const takedownTicketModal = <ThemeModal
    isOpen={showModal}
    toggle={toggleModal}
    modalClassName={'ltr-support'}
    className={'modal-dialog--header-xl modal-dialog--dark'}
  >
    <div className="modal__header">
      <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal}/>
      <h4 className="text-modal modal__title">Create ticket</h4>
    </div>

    <div className="modal__body">
      <form className="form form--vertical">
        <Container>
          <Row>
            <Col md={3}>
              <ControlledSelectWithTitle
                name={'indicator_type'}
                title={'Indicator Type'}
                rules={{required: 'The indicator type is required'}}
                defaultValue={indicatorTypes[0]}
                control={control}
                errors={errors}
                withError={true}
                valueFn={(value) => value}
                options={indicatorTypes}
              />
            </Col>
            <Col md={3}>
              <RegisteredInputField
                title={'Indicator'}
                name={'indicator'}
                register={register}
                placeholder={'indicator'}
                errors={errors}
                rules={{required: 'The indicator is required'}}
              />
            </Col>
            <Col md={3} className={'mt-4'}>
              <div onClick={onGetIocData} className={indicatorBtnClass}> Get Indicator Risk Score</div>
            </Col>
            <Col md={3} className={'mt-4'}>
              <Row>
                {
                  iocError ? <p>Error while compute the Risk Score</p> :
                    (iocIsLoading || iocIsIdle) && searchTerm ?
                      <LoadingSpinner classNames={'no_margin'}/> :
                      indicatorDetails && indicatorDetails.score &&
                      <h4>Risk Score: <span
                        style={{'color': getColorByScore(indicatorDetails.score)}}>{getRiskLabelByScore(indicatorDetails.score)}</span>
                      </h4>
                }
              </Row>
              {
                indicatorTags && indicatorTags.length > 0 &&
                <Row className={'mt-2'}>
                  <ColorTagInlineBadgeList items={indicatorTags}/>
                </Row>
              }

            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <ControlledSelectWithTitle
                name={'confidence'}
                title={'Confidence'}
                rules={{required: 'The confidence is required'}}
                defaultValue={confidenceOptions[0]}
                control={control}
                errors={errors}
                withError={true}
                valueFn={(value) => value}
                options={confidenceOptions}
              />
            </Col>
            <Col md={3}>
              <ControlledSelectWithTitle
                name={'category'}
                title={'Category'}
                rules={{required: 'The category is required'}}
                defaultValue={categoryOptions[0]}
                control={control}
                errors={errors}
                withError={true}
                valueFn={(value) => value}
                options={categoryOptions}
              />
            </Col>
            <Col md={3}>
              <ControlledSelectWithTitle
                name={'tlp'}
                title={'TLP'}
                control={control}
                errors={errors}
                withError={true}
                rules={{required: 'You must specify the TLP of the ticket'}}
                options={tlpOptions}
                defaultValue={tlpOptions[0]}
                valueFn={(value) => tlpOptions.find((c) => c.value === value?.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <span className={'form__form-group-label'}>Description</span>
              <textarea
                name={'description'}
                placeholder={"Describe the case"}
                className={'textarea--add-contents form-group'}
                {...register('description')}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
                  <span className={'form__form-group-label mb-2'}>
                    Attachments (select maximum 5 files) <br />
                    Allowed values are: {_.join(ALLOWED_ATTACHMENT_FORMATS, ', ')}
                  </span>
              <ControlledFilepond
                control={control}
                name={'report'}
                allowMultiple={true}
                allowRevert={false}
                maxFiles={5}
                instantUpload={false}
                labelIdle={'Drag & Drop your report or <span class="filepond--label-action">Browse</span>'}
              />
            </Col>
          </Row>
          <Row>
            {/*<Col md={2}>*/}
            {/*  <RegisteredCheckBoxField*/}
            {/*    style={{marginLeft: '5px'}}*/}
            {/*    name={'consent'}*/}
            {/*    value={watch('consent')}*/}
            {/*    onChange={(value) => {}}*/}
            {/*    defaultChecked={false}*/}
            {/*    label={"Accept Conditions"}*/}
            {/*    register={register}*/}
            {/*  />*/}
            {/*  <InformationOutlineIcon*/}
            {/*    onClick={(e) => {*/}
            {/*      e.preventDefault();*/}
            {/*      setConsentModalStatus(true)*/}
            {/*    }}*/}
            {/*    size={24}*/}
            {/*    style={{fill: 'white', padding: '2px', position: 'absolute', top: '-3px', left: '-10px'}}*/}
            {/*    className={'border-hover-white'}*/}
            {/*  />*/}
            {/*  {*/}
            {/*    consentModalStatus &&*/}
            {/*      <ConsentModal*/}
            {/*        onClose={() => setConsentModalStatus(false)}*/}
            {/*        showModalStatus={true}*/}
            {/*      />*/}
            {/*  }*/}
            {/*</Col>*/}
            <Col md={12}>
              <Button
                outline
                className={uploadBtnClass}
                color={'success'}
                onClick={() => handleSubmit(onSubmit)()}
              >OPEN</Button>
              <Button outline
                      className="rounded float-right mr-2"
                      onClick={toggleModal}
              >CANCEL</Button>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  </ThemeModal>;
  let modal;

  if (!checkTicketExists) {
    modal = takedownTicketModal
  } else if (ticketIsLoading || ticketIsIdle) {
    modal = <ThemeModal
      isOpen={showModal}
      toggle={toggleModal}
      modalClassName={'ltr-support'}
      className={'modal-dialog--header-xl modal-dialog--dark'}
    >
      <div className="modal__header">
        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal}/>
        <h4 className="text-modal modal__title">Create ticket</h4>
      </div>

      <div className="modal__body">
        <LoadingSpinner />
      </div>
    </ThemeModal>
  } else if (ticketData?.data) {
    modal = <ThemeModal
      isOpen={showModal}
      toggle={toggleModal}
      modalClassName={'ltr-support'}
      className={'modal-dialog modal-dialog--dark'}
    >
      <div className="modal__header">
        <h4 className="text-modal modal__title">A ticket to takedown {indicator} already exists</h4>
      </div>

    </ThemeModal>
  } else {
    modal = takedownTicketModal;
  }

  return (
    <Fragment>
      <Button
        className={`rounded float-right ${classNames}`}
        outline size="sm"
        onClick={
          () => {
            if (checkTicketExists) {
              setSearchValue(indicator);
            }

            toggleModal();
          }
        }
        disabled={disabled}
      >
        {buttonLabel}
      </Button>

      {modal}

    </Fragment>
  );
};


TakeDownModal.propTypes = {
  disabled: PropTypes.bool,
  classNames: PropTypes.string,
  indicator: PropTypes.string,
  buttonLabel: PropTypes.string,
  checkTicketExists: PropTypes.bool
}

export default TakeDownModal;
