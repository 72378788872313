import React, {Fragment} from "react";
import {Badge} from "reactstrap";
import ReactCountryFlag from "react-country-flag";
import PropTypes from "prop-types";
import {getCountryNameOrCodeShort} from "../../helpers/country-data";
import {getBadgeClasses} from "../../helpers/badge";
import {useActorDetailsQuery} from "../../../queries/Actor";



const ActorCountryBadge = ({actorUid, size = '', flagOnly = false, style = {}}) => {
  const { data, isIdle, isLoading, isError } = useActorDetailsQuery(actorUid);
  const classes = getBadgeClasses(size);

  if (isIdle || isLoading || isError || !data.data.origin_country) {
    return <Fragment />
  }

  const countryName = getCountryNameOrCodeShort(data.data.origin_country);
  return (
    <Badge className={classes} color={'secondary'} style={{...style, ...{}}}>
      <ReactCountryFlag countryCode={data.data.origin_country} svg/>
      {!flagOnly && <span className={'ml-1'}>{countryName}</span>}
    </Badge>
  )
}

ActorCountryBadge.propTypes = {
  actorUid: PropTypes.string.isRequired,
  flagOnly: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object
}

export default ActorCountryBadge;
