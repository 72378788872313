import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const EditAnalysisFetcher = (accessToken, params, uid) => {
    return axios.put(`${apiUrlInternal}/analyses/${uid}`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useEditAnalysisMutation = (fetcher = EditAnalysisFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['edit-analysis'],
    (accessToken, {params, analysisUid}) => fetcher(accessToken, params, analysisUid),
    {onSuccess: (_, {analysisUid}) => {
        queryClient.invalidateQueries('edit-analysis');
        queryClient.invalidateQueries(['analysis-details', analysisUid, true]);
        queryClient.invalidateQueries(['analysis-details', analysisUid, false]);
      }}
  )
}
