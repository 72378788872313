import {Badge, Col, Row} from "reactstrap";
import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import React from "react";
import PropTypes from "prop-types";


const FiltersList = ({
  currentFilters,
  deleteFilter
}) => {
  return (
    <Row>
      <Col style={{paddingBottom: '10px'}}>
        {
          currentFilters.length > 0 && currentFilters.map((f, index) =>
            f.displayField &&
            <Badge color={'secondary'} className={'outline icon icon--right badge-sm badge-filter'} key={f.displayValue}>
              {f.displayField + ': ' + f.displayValue} <CloseCircleOutlineIcon onClick={() => deleteFilter(index)}/>
            </Badge>
          ).reduce((prev, curr) => [prev, ' ', curr])
        }
      </Col>
    </Row>
  )
}

FiltersList.propTypes = {
  currentFilters: PropTypes.array.isRequired,
  deleteFilter: PropTypes.func.isRequired
}


export default FiltersList;
