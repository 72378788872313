
export const darkNetTypes  = {
  bots: 'botnet',
  sellers: 'seller',
  threads: 'thread',
}

export const intelSectBuckets = {
  leaks: 'leaks',
  dumpster: 'dumpster',
  pastes: 'pastes',
  darknet: 'darknet',
}


export const getFilterField = (target) => {
  let filterField = target;

  if (target === 'keyword.value') {
    filterField = 'keyword';
  } else if (target === 'geoip') {
    filterField = 'country';
  }

  return filterField;
}