import Page from "./Page";
import {PLATFORM_PERMISSION_VIEWER} from "../helpers/features";
import LoadingSpinner from "./LoadingSpinner";
import PropTypes from "prop-types";
import React from "react";


const LoadingSpinnerPage = ({
    feature = null,
    permission = PLATFORM_PERMISSION_VIEWER,
    onlyOnline = false,
    unavailableOnTenant = false,
    fullPageWrapper = false
}) => {
  return (
    <Page
      feature={feature}
      permission={permission}
      onlyOnline={onlyOnline}
      unavailableOnTenant={unavailableOnTenant}
      fullPageWrapper= {fullPageWrapper}
    >
      <LoadingSpinner />
    </Page>
  )
}

LoadingSpinnerPage.propTypes = {
  feature: PropTypes.string,
  permission: PropTypes.string,
  onlyOnline: PropTypes.bool,
  unavailableOnTenant: PropTypes.bool
}


export default LoadingSpinnerPage;