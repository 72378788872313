import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const EditFlashAlertFetcher = (accessToken, params, uid) => {
  return axios.put(`${apiUrlInternal}/flash_alert/${uid}`, params, addAuthToFetchOpts(accessToken, {}, 0)).then(res => res.data);
}

export const useEditFlashAlertMutation = (fetcher = EditFlashAlertFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['edit-flashalert'],
    (accessToken, {params, uid}) => fetcher(accessToken, params, uid),
    {onSuccess: (_, {uid}) => {
        queryClient.invalidateQueries('edit-flashalert');
        queryClient.invalidateQueries(['flashalert-details', uid, true]);
        queryClient.invalidateQueries(['flashalert-details', uid, false]);
      }}
  )
}
