import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const AddActivityFetcher = (accessToken, params) => {
  return axios.post(
      `${apiUrlInternal}/activities`,
      params,
      addAuthToFetchOpts(accessToken, {}, 0)
  ).then(res => res.data);
}

export const useAddActivityMutation = (fetcher = AddActivityFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-Activity'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => queryClient.invalidateQueries('add-Activity')}
  )
}

