import React from "react";
import PropTypes from "prop-types";
import {Badge, Card, CardBody, Col, Row} from "reactstrap";
import DarksearchTabs from "../../../shared/components/darkweb/DarksearchTabs";


const DarkSearchResults = ({bots, sellers, threads}) => {
  const totalLength = bots.length + sellers.length + threads.length;

  return (
    <Row>
      <Col>
        <Card className={'h-auto'}>
          <CardBody>
            <Row>
              <Col md={12}>
                <Badge color={'danger'} className={'w-100 outline badge-lg'}>
                  {
                    totalLength === 1 ? `THERE IS 1 EVENT IN THE DARKWEB` :
                      `THERE ARE ${totalLength} EVENTS IN THE DARKWEB`
                  }
                </Badge>
              </Col>
            </Row>

            <Row className={'mt-3'}>
              <Col>
                <DarksearchTabs bots={bots} threads={threads} sellers={sellers} />
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}


DarkSearchResults.propTypes = {
  bots: PropTypes.array.isRequired,
  sellers: PropTypes.array.isRequired,
  threads: PropTypes.array.isRequired
}

export default DarkSearchResults;
