import Collapse from "../../../../shared/components/Collapse";
import React from "react";
import PropTypes from "prop-types";
import RulesCollapseContent from "./RulesCollapseContent";


const RulesCollapse = ({rule}) => {
  return (
    <Collapse
      title={<>{rule.title}</>}
      className={"with-shadow"}
    >
      <RulesCollapseContent rule={rule}/>
    </Collapse>
  )
}


RulesCollapse.propTypes = {
  rule: PropTypes.object
}


export default RulesCollapse;

