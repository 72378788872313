import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SetBotnetsSolved = (project_uid, uids, accessToken) => {
  return axios.put(
    `${apiUrlInternal}/keyword_projects/${project_uid}/results/botnets/select-as-solved`,
    uids,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data );
}


export const useSetBotnetsSolved = (fetcher = SetBotnetsSolved) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['set-as-solved-botnets'],
    (accessToken, {project_uid, uids}) => fetcher(project_uid, uids, accessToken),
    {onSuccess: (_, {project_uid}) => {
        queryClient.invalidateQueries(['set-as-solved-botnets']);
        queryClient.invalidateQueries(['keyword-project-results', project_uid]);
      }}
  )
}
