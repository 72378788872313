import React, {Fragment} from "react";
import _ from "lodash";
import {Col, Row} from "reactstrap";
import InformativeHostsCveCard from "./InformativeHostsCveCard";
import ResourceMonitorRiskGauge from "./charts/ResourceMonitorRiskGauge";
import MostVulnHostsVerticalBarCard from "./charts/MostVulnHostsVerticalBarCard";
import VulnRisksPieChartCard from "./charts/VulnRisksPieChartCard";
import ResourceMonitorResultCard from "./ResourceMonitorResultCard";
import {getBaseScore, CVE_RISK_COLORS} from "../../../shared/helpers/cve";
import ResourceMonitorEmptyResultCard from "./ResourceMonitorEmptyResultCard";
import VulnerabilitiesRiskLineChart from "./charts/VulnerabilitiesRiskLineChart";
import PropTypes from "prop-types";


const ResourceMonitorDashboard = ({
  project,
  resource_monitor_results,
}) => {
  const resourceVulns = {};
  const hostVulnerabilities = {};
  const monitoringResources = project.resource_monitors || [];
  const vulns = resource_monitor_results;

  const severities = [
    {value: 0, fill: CVE_RISK_COLORS["low"], name: "low"},
    {value: 0, fill: CVE_RISK_COLORS["medium"], name: "medium"},
    {value: 0, fill: CVE_RISK_COLORS["high"], name: "high"},
    {value: 0, fill: CVE_RISK_COLORS["critical"], name: "critical"},
  ];

  const hostCves = {
    "low": new Set(),
    "medium": new Set(),
    "high": new Set(),
    "critical": new Set(),
    "all": new Set()
  }

  _.forEach(vulns, (v) => {
    const item = {
      'host': v.host,
      'created_dt': v.created_dt,
      'cve': v.cve.cve,
      'resource_monitor': v.resource_monitor.value,
      'nvd_info': v.cve.nvd_info
    }

    if (_.isNull(resourceVulns[v.resource_monitor.value]) || _.isUndefined(resourceVulns[v.resource_monitor.value])) {
      resourceVulns[v.resource_monitor.value] = [item]
    } else {
      resourceVulns[v.resource_monitor.value].push(item)
    }

    const cve_info = item.nvd_info;
    const [, , baseSeverity] = getBaseScore(cve_info.impact);

    _.filter(severities, (s) => s.name === _.lowerCase(baseSeverity))[0].value += 1;

    if (_.isNull(hostVulnerabilities[v.host]) || _.isUndefined(hostVulnerabilities[v.host])) {
      hostVulnerabilities[v.host] = 1;
    } else {
      hostVulnerabilities[v.host] += 1;
    }

    const lowerBaseSeverity = _.lowerCase(baseSeverity);
    if (hostCves[lowerBaseSeverity]) {
      hostCves[lowerBaseSeverity].add(v.host);
    }

    hostCves.all.add(v.host);
  });


  if (monitoringResources.length === 0) {
    return (
      <Row>
        <Col>
          <h2 className={"h2_investigate-title"}>No Hosts Found</h2>
          <h3 className={"h3_investigate-subtitle"}>
            In order to use the Attack Surface Intelligence module you have to insert at least a resource to the engine. <br/>
            Insert new resources in the "{project.name}" engine inside "Attack Surface Intelligence" in your profile section.
          </h3>
        </Col>
      </Row>
    )
  }

  return (
    <Fragment>
      <Row>
        <Col md={6} xl={3} lg={3} xs={12}>

          <InformativeHostsCveCard
            subtitle={<>with <span className={'resource_monitor-total-title--dark-red'}>critical</span> CVE</>}
            hosts={hostCves.critical.size}
            totalHosts={monitoringResources.length}
            percentageValue={hostCves.critical.size / monitoringResources.length * 100}
            progressBarColorClass={'progress-wrap--red-gradient'}
            titleColorClass={'resource_monitor-total-title--dark-red'}
          />

        </Col>
        <Col md={6} xl={3} lg={3} xs={12}>

          <InformativeHostsCveCard
            subtitle={<>with <span className={'resource_monitor-total-title--red'}>high</span> CVE</>}
            hosts={hostCves.high.size}
            totalHosts={monitoringResources.length}
            percentageValue={hostCves.high.size / monitoringResources.length * 100}
            progressBarColorClass={'progress-wrap--pink-gradient'}
            titleColorClass={'resource_monitor-total-title--red'}
          />

        </Col>
        <Col md={6} xl={3} lg={3} xs={12}>

          <InformativeHostsCveCard
            subtitle={<>with <span className={'resource_monitor-total-title--yellow'}>MEDIUM</span> cve</>}
            hosts={hostCves.medium.size}
            totalHosts={monitoringResources.length}
            percentageValue={hostCves.medium.size / monitoringResources.length * 100}
            progressBarColorClass={'progress-wrap--yellow-gradient'}
            titleColorClass={'resource_monitor-total-title--yellow'}
          />

        </Col>

        <Col md={6} xl={3} lg={3} xs={12}>

          <InformativeHostsCveCard
            subtitle={<>with <span className={'resource_monitor-total-title--blue'}>vulnerabilities</span></>}
            hosts={hostCves.all.size}
            totalHosts={monitoringResources.length}
            percentageValue={hostCves.all.size / monitoringResources.length * 100}
            progressBarColorClass={'progress-wrap--blue-gradient'}
            titleColorClass={'resource_monitor-total-title--blue'}
          />

        </Col>
      </Row>

      <Row>
        <Col md={6} xl={3} lg={3} xs={12}>
          <ResourceMonitorRiskGauge
            hostCves={hostCves}
            totalHosts={monitoringResources.length}
          />
        </Col>

        <Col md={6} xl={9} lg={9} xs={12}>
          <VulnerabilitiesRiskLineChart
            vulns={_.flatten(_.values(resourceVulns))}
          />
        </Col>
      </Row>

      {
        _.isEmpty(resourceVulns) ?
          <Row>
            <Col>
              <h2 className={"h2_investigate-title"}>No Vulnerabilities Found</h2>
              <h3 className={"h3_investigate-subtitle"}>
                The vulnerability exposure module did not found any vulnerabilities on your resources. <br/>
                Insert new resources in the "Attack Surface Intelligence" in your profile section.
              </h3>
            </Col>
          </Row> :
          <Fragment>
            <Row>
              <Col md={6}>
                <MostVulnHostsVerticalBarCard
                  hostVulnerabilities={hostVulnerabilities}
                />
              </Col>

              <Col md={6}>
                <VulnRisksPieChartCard
                  severities={severities}
                />
              </Col>
            </Row>

            {
              _.keys(resourceVulns).map(
                (resource_monitor) =>
                  <ResourceMonitorResultCard
                    key={resource_monitor.uid}
                    vulns={resourceVulns[resource_monitor]}
                    resource_monitor={resource_monitor}
                  />
              )
            }

            {
              _.filter(
                monitoringResources,
                (resource) => !_.keys(resourceVulns).includes(resource.value)
              ).map(
                (resource) =>
                  <ResourceMonitorEmptyResultCard
                    resource={resource.value}
                  />
              )
            }
          </Fragment>
      }
    </Fragment>
  )
}


ResourceMonitorDashboard.propTypes = {
  project: PropTypes.object.isRequired,
  resource_monitor_results: PropTypes.arrayOf(PropTypes.object).isRequired
}


export default ResourceMonitorDashboard;
