import React, {Fragment} from "react";
import classNames from "classnames";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import CloseIcon from "mdi-react/CloseIcon";
import {useFieldArray} from "react-hook-form";

const TargetedOrganizationsForm = ({control, errors, register}) => {
    const {
        fields: fieldsTargetedOrganizations,
        append: appendTargetedOrganizations,
        remove: removeTargetedOrganizations
    } = useFieldArray(
        {control, name: "targetedOrganizations"}
    );

    return (
        <Card>
            <CardBody>
                <div className="card__title">
                    <h4 className={'bold-text d-inline'}>Targeted organizations</h4>
                </div>
                <form className="form form--vertical">
                    <Fragment>
                        {
                            fieldsTargetedOrganizations.map((item, index) =>
                                <Row className={'w-100'} key={item.id}>
                                    <Col>
                                        <Row>
                                            <Col md={1} className={'d-flex flex-column justify-content-center'}>
                                                <CloseIcon
                                                    style={{fill: 'white', cursor: 'pointer'}}
                                                    onClick={() => removeTargetedOrganizations(index)}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <div className={classNames({
                                                    'form__form-group': true,
                                                    "form__form-group-error-validation": errors?.targetedOrganizations && errors.targetedOrganizations[index] && errors.targetedOrganizations[index].name
                                                })
                                                }>
                                                    <span className="form__form-group-label">Name</span>
                                                    <div className="form__form-group-field">
                                                        <div className="form__form-group-input-wrap">
                                                            <input {...register(`targetedOrganizations.${index}.name`, {required: 'The name is required'})} />
                                                            {
                                                                errors?.targetedOrganizations && errors.targetedOrganizations[index] && errors.targetedOrganizations[index].name
                                                                && <span
                                                                    className="form__form-group-error">{errors.targetedOrganizations[index].name.message}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={5}>
                                                <div className={classNames({
                                                    'form__form-group': true,
                                                    "form__form-group-error-validation": errors?.targetedOrganizations && errors.targetedOrganizations[index] && errors.targetedOrganizations[index].site
                                                })
                                                }>
                                                    <span className="form__form-group-label">Site</span>
                                                    <div className="form__form-group-field">
                                                        <div className="form__form-group-input-wrap">
                                                            <input {...register(`targetedOrganizations.${index}.site`, {required: 'The site is required'})} />
                                                            {
                                                                errors?.targetedOrganizations && errors.targetedOrganizations[index] && errors.targetedOrganizations[index].site
                                                                && <span
                                                                    className="form__form-group-error">{errors.targetedOrganizations[index].site.message}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        }
                    </Fragment>

                </form>
                {
                    errors.targetedOrganizations && errors.targetedOrganizations.message &&
                    <span className="form__form-group-error">{errors.targetedOrganizations.message}</span>
                }
                <Row className={'justify-content-end w-100'}>
                    <Button outline color={'success'} onClick={() => appendTargetedOrganizations({name: '', site: ''})}>
                        Add targeted organization
                    </Button>
                </Row>

            </CardBody>
        </Card>
    )
}


TargetedOrganizationsForm.propsType = {
    control: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    register: PropTypes.func.isRequired,
}


export default TargetedOrganizationsForm;
