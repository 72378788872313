import PropTypes from "prop-types";
import {Badge, Card, CardBody, Col, Row} from "reactstrap";
import React from "react";
import _ from "lodash";
import IntelxStatsTabsResult from "../../../shared/components/tab/IntelxStatsTabsResult";


const IntelxResults = ({stats, keyword}) => {
  let total = 0;
  _.forEach(stats, (value, ) => {total += value});

  return (
    <Row>
      <Col>
        <Card className={'h-auto'}>
          <CardBody>
            <Row>
              <Col md={12}>
                <Badge color={'danger'} className={'w-100 outline badge-lg'}>
                  {
                    total === 1 ? `THERE IS 1 EVENT RELATED TO ${_.toUpper(keyword)}` :
                      `THERE ARE ${total} EVENTS RELATED TO ${_.toUpper(keyword)}`
                  }
                </Badge>
              </Col>
            </Row>

            <Row className={'mt-3'}>
              <Col>
                <IntelxStatsTabsResult stats={stats} keyword={keyword} />
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}


IntelxResults.propTypes = {
  stats: PropTypes.object.isRequired,
  keyword: PropTypes.string.isRequired
}

export default IntelxResults;
