import {getIndStatsInfo} from "../../helpers/badge";
import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";

const NodeStats = ({node}) =>
  <>
    <p className='custom_card__container-subhead'>
      STATS
    </p>
    <p className='custom_card__container-title'>
      {
        getIndStatsInfo(node.data.stats, 'data') && getIndStatsInfo(node.data.stats, 'data').map((s) => (
          <p>
            <Row>
              <Col md={2}>
                <span className='preCircle' style={{'backgroundColor': s.fill}}></span>{s.value}
              </Col>
              <Col>
                {s.name}
              </Col>
            </Row>

          </p>
        ))}
    </p>
  </>

NodeStats.propTypes = {
  node: PropTypes.shape().isRequired
}

export default NodeStats;
