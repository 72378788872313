import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteProjectKeywords = (rmUid, projectUid, accessToken) => {
  return axios.delete(
    `${apiUrlInternal}/keyword_projects/keywords/${rmUid}`,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useDeleteProjectKeywords = (fetcher = DeleteProjectKeywords) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-project-keywords'],
    (accessToken, {rmUid}) => fetcher(rmUid, accessToken),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('delete-project-keywords');
        queryClient.invalidateQueries('users-me');
      }
    }
  )
}
