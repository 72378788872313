import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import {useMalwareFamilyQuery} from "../../queries/MalwareFamily"
import MalwareFamilyDetailCard from "../../shared/components/card/MalwareFamilyDetailCard";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import SearchMenu from "../../shared/components/form/SearchMenu";
import paths from "../../config/paths";
import FabMalwareFamilyMenu from "./components/FabMalwareFamilyMenu";
import {paramsToObject, useQueryParams} from "../../shared/components/router/QueryNavigationHelpers";
import {useHistory} from "react-router-dom";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {canUserCreateMalwareFamily} from "../../shared/helpers/permissions";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";

const _ = require('lodash');

const MalwareFamilies = () => {
  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();
  const params = paramsToObject(useQueryParams().entries());
  const SEARCH_FIELD = 'name';
  const ORDER_MENU = [
    {label: 'Name', value: 'name'},
    {label: 'First seen', value: 'first_seen'},
  ]
  const {data, isIdle, isLoading, isError, error} = useMalwareFamilyQuery(true);
  const [malwareFamiliesDisplay, setMalwareFamiliesDisplay] = useState([]);
  const [filters, setFilters] = useState({});
  const history = useHistory();

  const userFilters = (data) => {
    const urlParams = new URLSearchParams();
    Object.entries(data).forEach(([filter_field, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => urlParams.append(filter_field, v));
      } else {
        urlParams.set(filter_field, value)
      }
    });

    setFilters(data);
  }

  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data) {
      setMalwareFamiliesDisplay(data.data);
    }
  }, [isIdle, isLoading, isError, data])

  useEffect(() => {
    const searchTerm = filters[SEARCH_FIELD] ? filters[SEARCH_FIELD].toLowerCase() : '';
    const orderTerm = filters['order_by'] ? filters['order_by'] : '';
    const filteredMalwareFamilies = _.filter(data ? data.data : [], (el) => {
      return el['name'].toLowerCase().indexOf(searchTerm) > -1;
    }).sort((a, b) => {
      if (!orderTerm) return 1
      else return (a[orderTerm] < b[orderTerm] ? -1 : (a[orderTerm] > b[orderTerm] ? 1 : 0))
    })
    setMalwareFamiliesDisplay(filteredMalwareFamilies)
  }, [filters])

  if (isLoadingCurrentUser || isIdleCurrentUser || isLoading) {
    return <LoadingSpinnerPage/>
  }

  if (isError || isErrorCurrentUser) {
    return <ErrorHandler error={error}/>
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
    >
      <Container>
        <div className={'div__sticky-top'}>
          <SearchMenu
            setFilters={userFilters}
            baseUrl={paths.malwareFamilyBasePath}
            defaultFilters={params}
            searchPlaceholder={'Type a malware family name...'}
            orderMenu={ORDER_MENU}
            searchField={SEARCH_FIELD}
          />
        </div>
        <Row className={'mt-3'}>
          <Col md={12}>
            <h3 className="page-title">{malwareFamiliesDisplay.length} results</h3>
          </Col>
        </Row>
        <Row className={'malware-family-wrapper'}>
          {
            malwareFamiliesDisplay.map((malwareFamily) => (
              <Col lg={3} md={6} sm={6} xs={6} key={malwareFamily.uid}>
                <MalwareFamilyDetailCard
                  key={malwareFamily.uid}
                  malwareFamily={malwareFamily}
                  withLink
                  withTruncateText={true}
                  withDecoration
                  withEditableButton={canUserCreateMalwareFamily(dataCurrentUser?.data)}
                />
              </Col>
            ))
          }
          {canUserCreateMalwareFamily(dataCurrentUser?.data) && <FabMalwareFamilyMenu/>}
        </Row>
      </Container>
    </Page>
  )
}

export default MalwareFamilies