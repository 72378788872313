import React from 'react'
import PropTypes from "prop-types";
import getTooltipStyles from "../../helpers";
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {connect} from 'react-redux';
import LoadingSpinner from "../LoadingSpinner";
import _ from "lodash";


const HorizontalBarChart = ({
  data,
  dataKey,
  labelDataKey,
  isLoading = false,
  isError = false,
  dir,
  onBarClick = null,
  barColors = ["#40e5e8"],
  domain = [0, "auto"],
  themeName
}) => {

  if (isError) {
    return <p>ERROR WHILE FETCHING DATA!</p>;
  }

  if (isLoading) {
    return <LoadingSpinner/>;
  }

  return (
    <ResponsiveContainer height={400}>
      <BarChart
        margin={{left: 50, right: 50}}
        data={data}
        layout="vertical"
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis
          type="number"
          reversed={dir === 'rtl'}
          domain={domain}
        />
        <YAxis
          type="category"
          dataKey={labelDataKey}
          tickLine={false}
          verticalAnchor="start"
          orientation={dir === 'rtl' ? 'right' : 'left'}
        />
        <Tooltip
          {...getTooltipStyles(themeName, 'defaultItems')}
          formatter={(value, name, props) => [value, 'Hits',]}
        />
        <Bar
          dataKey={dataKey}
          barSize={20}
          onClick={(item, value) => {
            if (onBarClick) onBarClick(item, value)
          }}
        >
          {data.map((entry, index) =>
            <Cell
              cursor={!_.isNull(onBarClick) ? 'pointer' : 'cursor'}
              key={`cell-${index}`}
              fill={barColors[index % barColors.length]}
            />)
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

HorizontalBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.array.isRequired,
  labelDataKey: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
  onBarClick: PropTypes.func,
  domain: PropTypes.array
}

export default connect(state => ({themeName: state.theme.className}))(HorizontalBarChart);