import React, {Fragment, useCallback, useState} from "react";
import PropTypes from "prop-types";
import ThemeModal from "../ThemeModal";
import LoadingSpinner from "../LoadingSpinner";
import {Card, CardBody, Col, Row} from "reactstrap";
import SectorCard from "../card/withIcon/SectorCard";
import ContentCardHeader from "../card/accessories/ContentCardHeader";
import ContentCardFooter from "../card/accessories/ContentCardFooter";
import {getPostTitle} from "../../helpers/contents";
import {Link} from "react-router-dom";
import paths from "../../../config/paths";
import ErrorHandler from "../ErrorHandler";
import ActorsCard from "../card/ActorsCard";


const ContentDetailsModal = ({uid, title, queryFetcher}) => {
  const [showModal, setShowModal] = useState(false);
  const { data, isIdle, isLoading, isError, error } = queryFetcher(uid);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal]);

  const content = data?.data;

  return (
    <Fragment>
      <p
        className="chart__small_card__total-label-modal a--with-underline-hover"
        onClick={() => toggleModal()}
      >Show more information about this content</p>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header-xl modal-dialog--dark'}
      >
        <div className="modal__header" key={"modal__header"} style={{backgroundColor: '#232329'}}>
          <Link to={`${title ? paths.analysisPath : paths.postPath}/${uid}`}>
            {content && (content.title ? content.title : getPostTitle(content))}
          </Link>
        </div>
        <div className="modal__body" key={"modal__body"} style={{backgroundColor: '#2a2a31'}}>
          {
            isError && <ErrorHandler error={error} /> || (isIdle || isLoading) ? <LoadingSpinner /> :
              <Row>
                <Col>
                  <Card md={12}>
                    <CardBody>
                      <ContentCardHeader
                        confidence={content.confidence}
                        tlp={content.tlp.tlp}
                        title={content.title ? content.title : getPostTitle(content)}
                        created_dt={content.created_dt}
                      />
                      <pre className={'mb-3 pre--text'}>{content.text}</pre>
                      <ContentCardFooter tags={content.tags} tlp={content.tlp.tlp} />
                    </CardBody>
                  </Card>
                </Col>
                <Col className={'d-flex flex-column'} md={12}>
                  <ActorsCard actors={content.actors} title={'Actor'} />
                  <SectorCard sectors={content.sectors} />
                </Col>
              </Row>
          }
        </div>
      </ThemeModal>
    </Fragment>
  )
}


ContentDetailsModal.propTypes = {
  uid: PropTypes.number.isRequired,
  queryFetcher: PropTypes.func.isRequired
}


export default ContentDetailsModal;
