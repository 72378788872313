import {getCountryNameOrCodeShort} from "./country-data";

export const categoryCountries = [
  {
    label: 'Middle East',
    value: 'Middle East',
    data: [
      {label: 'Afghanistan', value: 'Afghanistan', iso_code: 'AF'},
      {label: 'Bahrain', value: 'Bahrain', iso_code: 'BH'},
      {label: 'Comoros', value: 'Comoros', iso_code: 'KM'},
      {label: 'Cyprus', value: 'Cyprus', iso_code: 'CY'},
      {label: 'Djibouti', value: 'Djibouti', iso_code: 'DJ'},
      {label: 'Egypt', value: 'Egypt', iso_code: 'EG'},
      {label: 'Iran (Islamic Republic of)', value: 'Iran (Islamic Republic of)', iso_code: 'IR'},
      {label: 'Iraq', value: 'Iraq', iso_code: 'IQ'},
      {label: 'Israel', value: 'Israel', iso_code: 'IL'},
      {label: 'Jordan', value: 'Jordan', iso_code: 'JO'},
      {label: 'Kuwait', value: 'Kuwait', iso_code: 'KW'},
      {label: 'Lebanon', value: 'Lebanon', iso_code: 'LB'},
      {label: 'Oman', value: 'Oman', iso_code: 'OM'},
      {label: 'Pakistan', value: 'Pakistan', iso_code: 'PK'},
      {label: 'Palestine (State of)', value: 'Palestine (State of)', iso_code: 'PS'},
      {label: 'Syrian Arab Republic', value: 'Syrian Arab Republic', iso_code: 'SY'},
      {label: 'Sudan', value: 'Sudan', iso_code: 'SD'},
      {label: 'Somalia', value: 'Somalia', iso_code: 'SO'},
      {label: 'Turkey', value: 'Turkey', iso_code: 'TR'},
      {label: 'United Arab Emirates', value: 'United Arab Emirates', iso_code: 'AE'},
      {label: 'Yemen', value: 'Yemen', iso_code: 'YE'},
    ]
  },
  {
    label: 'Middle East and North Africa',
    value: 'Middle East and North Africa',
    data: [
      {label: 'Algeria', value: 'Algeria', iso_code: 'DZ'},
      {label: 'Bahrain', value: 'Bahrain', iso_code: 'BH'},
      {label: 'Djibouti', value: 'Djibouti', iso_code: 'DJ'},
      {label: 'Egypt', value: 'Egypt', iso_code: 'EG'},
      {label: 'Iran (Islamic Republic of)', value: 'Iran (Islamic Republic of)', iso_code: 'IR'},
      {label: 'Iraq', value: 'Iraq', iso_code: 'IQ'},
      {label: 'Jordan', value: 'Jordan', iso_code: 'JO'},
      {label: 'Kuwait', value: 'Kuwait', iso_code: 'KW'},
      {label: 'Lebanon', value: 'Lebanon', iso_code: 'LB'},
      {label: 'Libya', value: 'Libya', iso_code: 'LY'},
      {label: 'Morocco', value: 'Morocco', iso_code: 'MA'},
      {label: 'Oman', value: 'Oman', iso_code: 'OM'},
      {label: 'Qatar', value: 'Qatar', iso_code: 'QA'},
      {label: 'Saudi Arabia', value: 'Saudi Arabia', iso_code: 'SA'},
      {label: 'Syrian Arab Republic', value: 'Syrian Arab Republic', iso_code: 'SY'},
      {label: 'Tunisia', value: 'Tunisia', iso_code: 'TN'},
      {label: 'United Arab Emirates', value: 'United Arab Emirates', iso_code: 'AE'},
      {label: 'Yemen', value: 'Yemen', iso_code: 'YE'},
    ]
  },
  {
    label: 'Western Asia',
    value: 'Western Asia',
    data: [
      {label: 'Algeria', value: 'Algeria', iso_code: 'DZ'},
      {label: 'Egypt', value: 'Egypt', iso_code: 'EG'},
      {label: 'Libya', value: 'Libya', iso_code: 'LY'},
      {label: 'Morocco', value: 'Morocco', iso_code: 'MA'},
      {label: 'Sudan', value: 'Sudan', iso_code: 'SD'},
      {label: 'Tunisia', value: 'Tunisia', iso_code: 'TN'},
      {label: 'Western Sahara', value: 'Western Sahara', iso_code: 'EH'},
    ]
  },
  {
    label: 'Central Africa',
    value: 'Central Africa',
    data: [
      {label: 'Angola',  value: 'Angola', iso_code: 'AO'},
      {label: 'Cameroon', value: 'Cameroon', iso_code: 'CM'},
      {label: 'Central African Republic', value: 'Central African Republic', iso_code: 'CF'},
      {label: 'Chad', value: 'Chad', iso_code: 'TD'},
      {label: 'Congo', value: 'Congo', iso_code: 'CG'},
      {label: 'Equatorial Guinea', value: 'Equatorial Guinea', iso_code: 'GQ'},
      {label: 'Gabon', value: 'Gabon', iso_code: 'GA'},
      {label: 'Sao Tome and Principe', value: 'Sao Tome and Principe', iso_code: 'ST'},

    ]
  },
  {
    label: 'East African',
    value: 'East African',
    data: [
      {label: 'Burundi', value: 'Burundi', iso_code: 'BI'},
      {label: 'Comoros', value: 'Comoros', iso_code: 'KM'},
      {label: 'Djibouti', value: 'Djibouti', iso_code: 'DJ'},
      {label: 'Ethiopia', value: 'Ethiopia', iso_code: 'ET'},
      {label: 'Kenya', value: 'Kenya', iso_code: 'KE'},
      {label: 'Madagascar', value: 'Madagascar', iso_code: 'MG'},
      {label: 'Malawi', value: 'Malawi', iso_code: 'MW'},
      {label: 'Mauritius', value: 'Mauritius', iso_code: 'MU'},
      {label: 'Mozambique', value: 'Mozambique', iso_code: 'MZ'},
      {label: 'Reunion', value: 'Reunion', iso_code: 'RE'},
      {label: 'Rwanda', value: 'Rwanda', iso_code: 'RW'},
      {label: 'Seychelles', value: 'Seychelles', iso_code: 'SC'},
      {label: 'Somalia', value: 'Somalia', iso_code: 'SO'},
      {label: 'Tanzania (United Republic of)', value: 'Tanzania (United Republic of)', iso_code: 'TZ'},
      {label: 'Uganda', value: 'Uganda', iso_code: 'UG'},
      {label: 'Zambia', value: 'Zambia', iso_code: 'ZM'},
      {label: 'Zimbabwe', value: 'Zimbabwe', iso_code: 'ZW'},
    ]
  },
  {
    label: 'Central Europe',
    value: 'Central Europe',
    data: [
      {label: 'Germany', value: 'Germany', iso_code: 'DE'},
      {label: 'Switzerland', value: 'Switzerland', iso_code: 'CH'},
      {label: 'Liechtenstein', value: 'Liechtenstein', iso_code: 'LI'},
      {label: 'Austria', value: 'Austria', iso_code: 'AT'},
      {label: 'Slovenia', value: 'Slovenia', iso_code: 'SI'},
      {label: 'Hungary', value: 'Hungary', iso_code: 'HU'},
      {label: 'Slovakia', value: 'Slovakia', iso_code: 'SK'},
      {label: 'Czech Republic', value: 'Czech Republic', iso_code: 'CZ'},
      {label: 'Poland', value: 'Poland', iso_code: 'PL'},
    ]
  },
  {
    label: 'Eastern Europe',
    value: 'Eastern Europe',
    data: [
      {label: 'Russian Federation', value: 'Russian Federation', iso_code: 'RU'},
      {label: 'Ukraine', value: 'Ukraine', iso_code: 'UA'},
      {label: 'Moldova (Republic of)', value: 'Moldova (Republic of)', iso_code: 'MD'},
      {label: 'Belarus', value: 'Belarus', iso_code: 'BY'},
      {label: 'Lithuania', value: 'Lithuania', iso_code: 'LT'},
      {label: 'Latvia', value: 'Latvia', iso_code: 'LV'},
      {label: 'Estonia', value: 'Estonia', iso_code: 'EE'},
    ]
  },
  {
    label: 'Northern Europe',
    value: 'Northern Europe',
    data: [
      {label: 'Finland', value: 'Finland', iso_code: 'FI'},
      {label: 'Sweden', value: 'Sweden', iso_code: 'SE'},
      {label: 'Norway', value: 'Norway', iso_code: 'NO'},
      {label: 'Iceland', value: 'Iceland', iso_code: 'IS'},
      {label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen', iso_code: 'SJ'},
      {label: 'Faroe Islands', value: 'Faroe Islands', iso_code: 'FO'},
      {label: 'Denmark', value: 'Denmark', iso_code: 'DK'},
    ]
  },
  {
    label: 'South-eastern Europe',
    value: 'South-eastern Europe',
    data: [
      {label: 'Romania', value: 'Romania', iso_code: 'RO'},
      {label: 'Bulgaria', value: 'Bulgaria', iso_code: 'BG'},
      {label: 'Macedonia (the Former Yugoslav Republic of)', value: 'Macedonia (the Former Yugoslav Republic of)', iso_code: 'MK'},
      {label: 'Albania', value: 'Albania', iso_code: 'AL'},
      {label: 'Serbia', value: 'Serbia', iso_code: 'RS'},
      {label: 'Montenegro', value: 'Montenegro', iso_code: 'ME'},
      {label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina', iso_code: 'BA'},
      {label: 'Croatia', value: 'Croatia', iso_code: 'HR'},
    ]
  },
  {
    label: 'Southern Europe',
    value: 'Southern Europe',
    data: [
      {label: 'Italy', value: 'Italy', iso_code: 'IT'},
      {label: 'Holy See (Vatican City State)', value: 'Holy See (Vatican City State)', iso_code: 'VA'},
      {label: 'San Marino', value: 'San Marino', iso_code: 'SM'},
      {label: 'Greece', value: 'Greece', iso_code: 'GR'},
    ]
  },
  {
    label: 'South-western Europe',
    value: 'South-western Europe',
    data: [
      {label: 'Portugal', value: 'Portugal', iso_code: 'PT'},
      {label: 'Gibraltar', value: 'Gibraltar', iso_code: 'GI'},
      {label: 'Spain', value: 'Spain', iso_code: 'ES'},
      {label: 'Andorra', value: 'Andorra', iso_code: 'AD'},
    ]
  },
  {
    label: 'Western Europe',
    value: 'Western Europe',
    data: [
      {label: 'France', value: 'France', iso_code: 'FR'},
      {label: 'Ireland', value: 'Ireland', iso_code: 'IE'},
      {label: 'Isle of Man', value: 'Isle of Man', iso_code: 'IM'},
      {label: 'United Kingdom', value: 'United Kingdom', iso_code: 'GB'},
      {label: 'Netherlands', value: 'Netherlands', iso_code: 'NL'},
      {label: 'Belgium', value: 'Belgium', iso_code: 'BE'},
      {label: 'Luxembourg', value: 'Luxembourg', iso_code: 'LU'},
    ]
  },
  {
    label: 'Balkans',
    value: 'Balkans',
    data: [
      {label: 'Albania', value: 'Albania', iso_code: 'AL'},
      {label: 'Belgium', value: 'Belgium', iso_code: 'BE'},
      {label: 'Bulgaria', value: 'Bulgaria', iso_code: 'BG'},
      {label: 'Croatia', value: 'Croatia', iso_code: 'HR'},
      {label: 'Montenegro', value: 'Montenegro', iso_code: 'ME'},
      {label: 'Macedonia (the Former Yugoslav Republic of)', value: 'Macedonia (the Former Yugoslav Republic of)', iso_code: 'MK'},
      {label: 'Romania', value: 'Romania', iso_code: 'RO'},
      {label: 'Serbia', value: 'Serbia', iso_code: 'RS'},
      {label: 'Slovenia', value: 'Slovenia', iso_code: 'SI'},
    ]
  },
  {
    label: 'Caucasus',
    value: 'Caucasus',
    data: [
      {label: 'Georgia', value: 'Georgia', iso_code: 'GE'},
      {label: 'Azerbaijan', value: 'Azerbaijan', iso_code: 'AZ'},
      {label: 'Armenia', value: 'Armenia', iso_code: 'AM'},
      {label: 'Russian Federation', value: 'Russian Federation', iso_code: 'RU'},
    ]
  },
  {
    label: 'Western Africa',
    value: 'Western Africa',
    data: [
      {label: 'Benin', value: 'Benin', iso_code: 'BJ'},
      {label: 'Burkina Faso', value: 'Burkina Faso', iso_code: 'BF'},
      {label: 'Cape Verde', value: 'Cape Verde', iso_code: 'CV'},
      {label: "Cote d'Ivoire", value: "Cote d'Ivoire", iso_code: 'CI'},
      {label: 'Gambia', value: 'Gambia', iso_code: 'GM'},
      {label: 'Ghana', value: 'Ghana', iso_code: 'GH'},
      {label: 'Guinea', value: 'Guinea', iso_code: 'GN'},
      {label: 'Guinea-Bissau', value: 'Guinea-Bissau', iso_code: 'GW'},
      {label: 'Liberia', value: 'Liberia', iso_code: 'LR'},
      {label: 'Mali', value: 'Mali', iso_code: 'ML'},
      {label: 'Mauritania', value: 'Mauritania', iso_code: 'MR'},
      {label: 'Niger', value: 'Niger', iso_code: 'NE'},
      {label: 'Nigeria', value: 'Nigeria', iso_code: 'NG'},
      {label: 'Senegal', value: 'Senegal', iso_code: 'SN'},
      {label: 'Sierra Leone', value: 'Sierra Leone', iso_code: 'SL'},
      {label: 'Togo', value: 'Togo', iso_code: 'TG'},
    ]
  },
  {
    label: 'Sahel',
    value: 'Sahel',
    data: [
      {label: 'Niger', value: 'Niger', iso_code: 'NE'},
      {label: 'Nigeria', value: 'Nigeria', iso_code: 'NG'},
      {label: 'Sudan', value: 'Sudan', iso_code: 'SD'},
      {label: 'Senegal', value: 'Senegal', iso_code: 'SN'},
      {label: 'Burkina Faso', value: 'Burkina Faso', iso_code: 'BF'},
      {label: 'Chad', value: 'Chad', iso_code: 'TD'},
      {label: 'Eritrea', value: 'Eritrea', iso_code: 'ER'},
      {label: 'Gambia', value: 'Gambia', iso_code: 'GM'},
      {label: 'Mali', value: 'Mali', iso_code: 'ML'},
      {label: 'Mauritania', value: 'Mauritania', iso_code: 'MR'},
    ]
  },
  {
    label: 'Central Asia',
    value: 'Central Asia',
    data: [
      {label: 'Tajikistan', value: 'Tajikistan',iso_code: 'TJ'},
      {label: 'Uzbekistan', value: 'Uzbekistan',iso_code: 'UZ'},
      {label: 'Kazakhstan', value: 'Kazakhstan',iso_code: 'KZ'},
      {label: 'Turkmenistan', value: 'Turkmenistan',iso_code: 'TM'},
      {label: 'Kyrgyzstan', value: 'Kyrgyzstan',iso_code: 'KG'}
    ]
  },
  {
    label: 'East Asia',
    value: 'East Asia',
    data: [
      {label: 'China', value: 'China',iso_code: 'CN'},
      {label: 'Mongolia', value: 'Mongolia',iso_code: 'MN'},
      {label: 'North Korea', value: 'North Korea',iso_code: 'KP'},
      {label: 'South Korea', value: 'South Korea',iso_code: 'KR'},
      {label: 'Hong Kong', value: 'Hong Kong',iso_code: 'HK'},
      {label: 'Taiwan', value: 'Taiwan',iso_code: 'TW'},
      {label: 'Macau', value: 'Macau',iso_code: 'MO'},
    ]
  },
  {
    label: 'South Asia',
    value: 'South Asia',
    data: [
      {label: 'Sri Lanka', value: 'Sri Lanka',iso_code: 'LK'},
      {label: 'Bangladesh', value: 'Bangladesh',iso_code: 'BD'},
      {label: 'India', value: 'India',iso_code: 'IN'},
      {label: 'Afghanistan', value: 'Afghanistan',iso_code: 'AF'},
      {label: 'Pakistan', value: 'Pakistan',iso_code: 'PK'},
      {label: 'Bhutan', value: 'Bhutan',iso_code: 'BT'},
      {label: 'Nepal', value: 'Nepal',iso_code: 'NP'},
      {label: 'Iran', value: 'Iran',iso_code: 'IR'},
      {label: 'Maldives', value: 'Maldives',iso_code: 'MV'}
    ]
  },
  {
    label: 'Southeast Asia',
    value: 'Southeast Asia',
    data: [
      {label: 'Brunei', value: 'Brunei',iso_code: 'BN'},
      {label: 'Cambodia', value: 'Cambodia',iso_code: 'KH'},
      {label: 'Indonesia', value: 'Indonesia',iso_code: 'ID'},
      {label: 'Laos', value: 'Laos',iso_code: 'LA'},
      {label: 'Malaysia', value: 'Malaysia',iso_code: 'MY'},
      {label: 'Myanmar', value: 'Myanmar',iso_code: 'MM'},
      {label: 'Philippines', value: 'Philippines',iso_code: 'PH'},
      {label: 'Singapore', value: 'Singapore',iso_code: 'SG'},
      {label: 'Thailand', value: 'Thailand',iso_code: 'TH'},
      {label: 'Timor Leste', value: 'Timor Leste',iso_code: 'TL'},
      {label: 'Vietnam', value: 'Vietnam',iso_code: 'VN'}
    ]
  },
  {
    label: 'LATAM',
    value: 'LATAM',
    data: [
      {label: 'Argentina', value: 'Argentina',iso_code: 'AR'},
      {label: 'Bolivia', value: 'Bolivia',iso_code: 'BO'},
      {label: 'Brazil', value: 'Brazil',iso_code: 'BR'},
      {label: 'Chile', value: 'Chile',iso_code: 'CL'},
      {label: 'Colombia', value: 'Colombia',iso_code: 'CO'},
      {label: 'Costa Rica', value: 'Costa Rica',iso_code: 'CR'},
      {label: 'Cuba', value: 'Cuba',iso_code: 'CU'},
      {label: 'Domican Republic', value: 'Domican Republic',iso_code: 'DO'},
      {label: 'Ecuador', value: 'Ecuador',iso_code: 'EC'},
      {label: 'El Salvador', value: 'El Salvador',iso_code: 'SV'},
      {label: 'French Guiana', value: 'French Guiana',iso_code: 'GF'},
      {label: 'Guadeloupe', value: 'Guadeloupe',iso_code: 'GP'},
      {label: 'Guatemala', value: 'Guatemala',iso_code: 'GT'},
      {label: 'Haiti', value: 'Haiti',iso_code: 'HT'},
      {label: 'Honduras', value: 'Honduras',iso_code: 'HN'},
      {label: 'Martinque', value: 'Martinque',iso_code: 'MQ'},
      {label: 'Mexico', value: 'Mexico',iso_code: 'MX'},
      {label: 'Nicaragua', value: 'Nicaragua',iso_code: 'NI'},
      {label: 'Panama', value: 'Panama',iso_code: 'PA'},
      {label: 'Paraguay', value: 'Paraguay',iso_code: 'PY'},
      {label: 'Peru', value: 'Peru',iso_code: 'PE'},
      {label: 'Puerto Rico', value: 'Puerto Rico',iso_code: 'PR'},
      {label: 'Saint Barthélemy', value: 'Saint Barthélemy',iso_code: 'BL'},
      {label: 'Saint Martin', value: 'Saint Martin',iso_code: 'MF'},
      {label: 'Uruguay', value: 'Uruguay',iso_code: 'UY'},
      {label: 'Venezuela', value: 'Venezuela',iso_code: 'VE'}
    ]
  },
]

export const getCountriesByCategory = (c) =>
  categoryCountries.filter((i) => {
    if (i.value === c.value) {
      i.data = i.data.map((cc) => {
        cc.label = getCountryNameOrCodeShort(cc.iso_code);
        return cc;
      })
      return i;
    }
  });