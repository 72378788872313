import axios from "axios";
import {useQueryClient} from "react-query";
import {apiUrlInternal} from "../../config/api";
import {addAuthToFetchOpts} from "../../config/queryopts";
import {useMutationWithAuthentication} from "../index";


export const TypoSquattingDeleteResultsFetcher = (accessToken, project_uid, uids) => {
  return axios.put(
    `${apiUrlInternal}/typosquatting_projects/${project_uid}/assets/results/mark-as-deleted`,
    uids,
    addAuthToFetchOpts(accessToken, {}, 0)
  ).then(
    res => res.data
  );
}

export const useTypoSquattingDeleteResults = (fetcher = TypoSquattingDeleteResultsFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-typosquatting-results'],
    (accessToken, {project_uid, uids}) => fetcher(accessToken, project_uid, uids),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('delete-typosquatting-results');
        queryClient.invalidateQueries(['typosquatting-asset-result']);
      }
    }
  )
}

