import React from 'react';
import { useSandboxTaskDetailsScreenshotsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import {Col} from "reactstrap";
import '../../../scss/component/screenshot-image.scss';

const _ = require('lodash')

const SandboxScreenshotsImage = ({taskId, imageIndex}) => {
    const { data, isIdle, isLoading, isError,error } = useSandboxTaskDetailsScreenshotsQuery(taskId,imageIndex);

    if (isLoading || isIdle) {
        return <LoadingSpinner />
    }

    if (isError || !data) {
        return <ErrorHandler error={error} />
    }

    let base64Image = data.data
    let base64ImageWithEncoding = 'data:image/jpeg;base64,' + data.data;


    return (
        (base64Image &&
        <Col className={"col-image-with-link"}>
            <a href={base64ImageWithEncoding}>
                <img src={base64ImageWithEncoding} />
            </a>
        </Col>)
    )

}

export default SandboxScreenshotsImage;