import React, {useEffect, useMemo, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import {Col, Container, Row} from "reactstrap";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import {getFeedlyFeedsListFilterMenu} from "../../shared/helpers/filter_menu";
import {getFeedlyFeedsListOrderMenu} from "../../shared/helpers/order_menu";
import SearchMenu from "../../shared/components/form/SearchMenu";
import FeedlyFeedCard from "../../shared/components/card/FeedlyFeedCard";
import {paramsToObject, useQueryParams} from "../../shared/components/router/QueryNavigationHelpers";
import {useHistory} from "react-router-dom";
import paths from "../../config/paths";
import {useFeedlyFeedsAuthorsQuery} from "../../queries/FeedlyFeedsAuthors";
import {useFeedlyFeedQueryWithDict} from "./queries/SearchFeedlyFeeds";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";

const _ = require('lodash');


const FeedlyFeedsList = () => {
  const params = paramsToObject(useQueryParams().entries());
  const history = useHistory();
  const INITIAL_ITEMS = 10;
  const LOADING_STEP = 10;

  const [show, setShow] = useState(false);
  const [loadedItems, setLoadedItems] = useState(LOADING_STEP);
  const [offset, setOffset] = useState(loadedItems - LOADING_STEP);
  const [feedlyFeedsArray, setFeedlyFeedsArray] = useState([]);
  const [feedlyFeedsDisplay, setFeedlyFeedsDisplay] = useState([]);
  const [totalFeedlyFeeds, setTotalFeedlyFeedsEvents] = useState(0)
  const [forceUpdateFilters, setForceUpdateFilters] = useState(false);
  const {
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();

  const [filters, setFilters] = useState({});

  const {data, isIdle, isLoading, isError, error} = useFeedlyFeedQueryWithDict(
    Object.assign({}, filters, {offset: offset, limit: LOADING_STEP})
  );
  const {
    data: authorsData,
    isIdle: isIdleAuthors,
    isLoading: isLoadingAuthors,
    isError: isErrorAuthors,
    error: authorsError
  } = useFeedlyFeedsAuthorsQuery()

  const authors = authorsData?.data ? authorsData : []
  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data) {
      setTotalFeedlyFeedsEvents(data.metadata.total);
      if (!offset) {
        setFeedlyFeedsArray(data.data);
        setFeedlyFeedsDisplay(data.data.slice(0, INITIAL_ITEMS));
        setLoadedItems(INITIAL_ITEMS);
      } else {
        setFeedlyFeedsArray(feedlyFeedsArray.concat(data.data));
        setFeedlyFeedsDisplay(feedlyFeedsArray.concat(data.data).slice(0, loadedItems + LOADING_STEP));
        setLoadedItems(loadedItems + LOADING_STEP);
      }
    }
  }, [isIdle, isLoading, isError, data])

  const isLoadingIdleErrorMenu = isIdleAuthors || isErrorAuthors || isLoadingAuthors || isIdleCurrentUser || isLoadingCurrentUser || isErrorCurrentUser

  const isLoadingIdleMenu =  isLoadingAuthors ||isIdleAuthors || isIdleCurrentUser || isLoadingCurrentUser;


  const ORDER_MENU = getFeedlyFeedsListOrderMenu();
  const FILTER_MENU = useMemo(
    () => (!isLoadingIdleErrorMenu) ? getFeedlyFeedsListFilterMenu(authors) : [],
    [isLoadingIdleErrorMenu]
  )


  useEffect(() => {
    if (isIdle || isLoading || !data) {
      setOffset(null);

      setShow(false);
      setFeedlyFeedsDisplay([]);
      setFeedlyFeedsArray([]);
      setTimeout(() => {
        setShow(true)
      }, 1000)
    }
  }, [filters])

  const loadMoreItems = () => {

    if (feedlyFeedsArray.length >= loadedItems + LOADING_STEP) {
      setFeedlyFeedsDisplay(feedlyFeedsArray.slice(0, loadedItems + LOADING_STEP));
      setLoadedItems(loadedItems + LOADING_STEP);
    } else if (feedlyFeedsArray.length === totalFeedlyFeeds) {
      setFeedlyFeedsDisplay(feedlyFeedsArray);
      setLoadedItems(totalFeedlyFeeds);
    } else if (data?.metadata.next) {
      setOffset(offset + data?.metadata.next);
    }
  }

  if (isLoadingIdleMenu || isIdleCurrentUser || isLoadingCurrentUser) {
    return <LoadingSpinnerPage/>
  }

  if (isError || isErrorCurrentUser) {
    return <ErrorHandler error={error}/>
  }
  const updateFilters = (display_value, selected_field = null) => {
    let filterSelected = null;
    if (!selected_field) {
      let index = -1;
      FILTER_MENU.forEach(
        (filter, i) => {
          if (filter.values.map((v) => display_value.name.includes(filter.displayValueFn(v)))) {
            index = i;
          }
        }
      )

      if (index < 0) {
        return
      }

      filterSelected = FILTER_MENU[index];
    } else {
      filterSelected = FILTER_MENU.find((filter) => filter.field === selected_field);
    }

    const filter_field = filterSelected.field;
    const filter_value = filterSelected.values.find((v) => display_value.name.includes(filterSelected.displayValueFn(v)));

    if (filters[filter_field]?.includes(filter_value)) {
      return;
    }

    if (!filterSelected.multi) {
      filters[filter_field] = filter_value;
    } else {
      if (filters[filter_field]) {
        filters[filter_field].push(filter_value);
      } else {
        filters[filter_field] = [filter_value];
      }
    }

    const urlParams = new URLSearchParams();

    _.entries(filters).forEach(([filter_field, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => urlParams.append(filter_field, v));
      } else {
        urlParams.set(filter_field, value)
      }
    });

    setForceUpdateFilters(true);
    history.replace(`${paths.feedlyFeedsPath}?${urlParams}`);
  }
  const title = totalFeedlyFeeds !== 0 ? `${totalFeedlyFeeds} results` : ""
  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      onlyOnline
    >
      <Container>
        <Row>
          <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto'}>
            <SearchMenu
              setFilters={setFilters}
              baseUrl={paths.feedlyFeedsPath}
              forceUpdateFilters={forceUpdateFilters}
              setForceUpdateFilters={setForceUpdateFilters}
              defaultFilters={params}
              searchPlaceholder={'Search news feeds...'}
              orderMenu={ORDER_MENU}
              filterMenu={FILTER_MENU}
              fromDateField={'start'}
              toDateField={'end'}
              searchField={'search_term'}
            />
          </Col>
        </Row>
        {
          (isIdle || isLoading || !show) && feedlyFeedsDisplay.length === 0 ?
            <Row></Row> :<Row className={'mt-3'}>
              <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto'}>
                <h3 className="page-title">{title}</h3>
              </Col>
            </Row>
        }
        {
          (isIdle || isLoading || !show) && feedlyFeedsDisplay.length === 0 ?
            <LoadingSpinner/> :
            <Row>
              <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto mb-2'}>
                <InfiniteScroll
                  loadMore={loadMoreItems}
                  hasMore={loadedItems < totalFeedlyFeeds}
                  loader={isLoading && !isIdle && <LoadingSpinner key={loadedItems}/>}
                >
                  <Row className={'mt-3'}>
                    {
                      feedlyFeedsDisplay.map((event, index) => (
                        <FeedlyFeedCard
                          key={index}
                          event={event}
                          withLink
                          withTruncateText
                          withDecoration
                          updateFilters={updateFilters}
                        />

                      ))
                    }
                  </Row>

                </InfiniteScroll>
              </Col>
            </Row>
        }
      </Container>
    </Page>
  )
}

export default FeedlyFeedsList;
