import {Badge, Button, ButtonToolbar, Card, CardBody, Col, Container, Row} from "reactstrap";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {
  useCommandsByTTPQuery,
  useCommandsMalwareFamiliesCountQuery,
  useCommandsSearchQuery,
  useCommandsTTPSCountQuery
} from "../../queries/Commands";
import MitreFrameworkCard from "../../shared/components/card/MitreFrameworkCard";
import {useMalwareFamilyDetailCommandsQuery, useMalwareFamilyQuery} from "../../queries/MalwareFamily";
import MalwareFamilyDetailCard from "../../shared/components/card/MalwareFamilyDetailCard";
import SearchBarOnEnter from "../../shared/components/search-menu/SearchBarOnEnter";
import {paramsToObject, useQueryParams} from "../../shared/components/router/QueryNavigationHelpers";
import paths from "../../config/paths";
import CommandsContentSection from "./components/CommandsContentSection";
import ThemeModal from "../../shared/components/ThemeModal";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";
import _ from "lodash";


const Commands = () => {
  const TTPS_SEC = 0;
  const MALWARE_FAMILIES_SEC = 1;
  const SEARCH_SEC = 2;
  const history = useHistory();
  const params = paramsToObject(useQueryParams().entries());
  const [searchTerm, setSearchTerm] = useState(params.search || "")
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [currentSec, setCurrentSec] = useState(params.search?.length > 3 ? SEARCH_SEC : TTPS_SEC);
  const [previousSec, setPreviousSec] = useState(TTPS_SEC);
  const [malwareFamiliesDisplay, setMalwareFamiliesDisplay] = useState([]);
  const [currentCommands, setCurrentCommands] = useState([])
  const [ttpUid, setTTPUid] = useState()
  const [malwareFamilyUid, setMalwareFamilyUid] = useState()
  const [showModal, setShowModal] = useState(false);
  const [typingTimer, setTypingTimer] = useState()
  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal]);
  const {
    data: dataTTPSCount,
    isIdle: isIdleTTPSCount,
    isLoading: isLoadingTTPSCount,
    isError: isErrorTTPSCount,
    error: errorTTPSCount
  } = useCommandsTTPSCountQuery();
  const {
    data: dataMalwareFamiliesCount,
    isIdle: isIdleMalwareFamiliesCount,
    isLoading: isLoadingMalwareFamiliesCount,
    isError: isErrorMalwareFamiliesCount,
    error: errorMalwareFamiliesCount
  } = useCommandsMalwareFamiliesCountQuery();
  const {
    data: dataMalwareFamilies,
    isIdleMalwareFamilies,
    isLoadingMalwareFamilies,
    isErrorMalwareFamilies,
    errorMalwareFamilies
  } = useMalwareFamilyQuery(true);
  const {
    data: dataCommandsSearch,
    isIdle: isIdleCommandsSearch,
    isLoading: isLoadingCommandsSearch,
    isError: isErrorCommandsSearch,
    error: errorCommandsSearch
  } = useCommandsSearchQuery(searchTerm, typingTimer)

  const {
    data: dataCommandsByTTPUid,
    isIdle: isIdleCommandsByTTPUid,
    isLoading: isLoadingCommandsByTTPUid,
    isError: isErrorCommandsByTTPUid,
    error: errorCommandsByTTPUid
  } = useCommandsByTTPQuery(ttpUid)

  const {
    data: dataCommandsByMalwareFamilyUid,
    isIdle: isIdleCommandsByMalwareFamilyUid,
    isLoading: isLoadingCommandsByMalwareFamilyUid,
    isError: isErrorCommandsByMalwareFamilyUid,
  } = useMalwareFamilyDetailCommandsQuery(malwareFamilyUid)

  useEffect(() => {
    if (!isErrorCommandsByTTPUid && !isLoadingCommandsByTTPUid && !isIdleCommandsByTTPUid && dataCommandsByTTPUid) {
      setCurrentCommands(dataCommandsByTTPUid.data)
    } else {
      setCurrentCommands([])
    }
  }, [dataCommandsByTTPUid, isIdleCommandsByTTPUid, isLoadingCommandsByTTPUid, isErrorCommandsByTTPUid])

  useEffect(() => {
    if (!isIdleCommandsByMalwareFamilyUid && !isLoadingCommandsByMalwareFamilyUid && !isErrorCommandsByMalwareFamilyUid && dataCommandsByMalwareFamilyUid) {
      setCurrentCommands(dataCommandsByMalwareFamilyUid.data)
    } else {
      setCurrentCommands([])
    }
  }, [dataCommandsByMalwareFamilyUid, isIdleCommandsByMalwareFamilyUid, isLoadingCommandsByMalwareFamilyUid, isErrorCommandsByMalwareFamilyUid])


  useEffect(() => {
    if ((searchTerm !== '' || !_.isUndefined(params.search)) && searchTerm !== params.search) {
      if (searchTerm && searchTerm.length >= 4) {
        if (typingTimer < 1 || typingTimer === undefined) {
          history.push(`${paths.commandsPath}?search=${searchTerm}`)
        }
        if (currentSec !== SEARCH_SEC) {
          setPreviousSec(currentSec)
        }
        setCurrentSec(SEARCH_SEC);
      } else if (searchTerm && searchTerm.length < 4) {
        if (previousSec !== SEARCH_SEC && currentSec === SEARCH_SEC) {
          setCurrentSec(previousSec)
        }
        if (typingTimer < 1 || typingTimer === undefined) {
          history.push(`${paths.commandsPath}?search=${searchTerm}`)
        }
      } else {
        history.push(`${paths.commandsPath}`)
        if (previousSec !== SEARCH_SEC && currentSec === SEARCH_SEC) {
          setCurrentSec(previousSec)
        }
      }
      setTriggerSearch(false)
    }
  }, [searchTerm, triggerSearch, typingTimer])

  useEffect(() => {
    if (!isIdleMalwareFamilies && !isLoadingMalwareFamilies && !isErrorMalwareFamilies && dataMalwareFamiliesCount?.data && dataMalwareFamilies?.data && !isIdleMalwareFamiliesCount && !isLoadingMalwareFamiliesCount) {
      setMalwareFamiliesDisplay(dataMalwareFamilies.data.filter(data => {
        const count = dataMalwareFamiliesCount.data.find(d => (d.uid === data.uid))?.count
        if (count > 0) {
          data.commandsCount = count
          return data
        }
      }));
    }
  }, [
    isIdleMalwareFamilies, isLoadingMalwareFamilies, isErrorMalwareFamilies, dataMalwareFamilies,
    isIdleMalwareFamiliesCount, isLoadingMalwareFamiliesCount, isErrorMalwareFamiliesCount, dataMalwareFamiliesCount
  ])

  const updateFilters = (event) => {
    if (event.type === 'techniques') setTTPUid(event.id)
    else if (event.type === 'malware_families') setMalwareFamilyUid(event.id)
    toggleModal()
  }

  const onChange = (e) => {
    const timer = setTimeout(() => {
      setTypingTimer(0)
    }, 500)
    setTypingTimer(timer)
    setSearchTerm(e.target.value)
  }
  const onSearchTriggered = () => {
    setTriggerSearch(true)
  }

  if (isIdleTTPSCount || isLoadingTTPSCount || isIdleMalwareFamilies || isLoadingMalwareFamilies || isLoadingMalwareFamiliesCount || isIdleMalwareFamiliesCount) {
    return <LoadingSpinnerPage/>
  }

  if (isErrorTTPSCount || isErrorMalwareFamilies || isErrorMalwareFamiliesCount || isErrorCommandsSearch || isErrorCommandsByTTPUid) {
    return <ErrorHandler
      error={errorTTPSCount || errorMalwareFamiliesCount || errorMalwareFamilies || errorCommandsSearch || errorCommandsByTTPUid}/>
  }

  if (dataTTPSCount?.data.length === 0 || dataMalwareFamiliesCount?.data.length === 0) {
    return (
      <Page
        feature={PLATFORM_FEATURE_CONTENTS}
      >
        <Container className={'dashboard'}>
          <Row>
            <Col>
              <h2 className={"h2_investigate-title"}>No Commands Found</h2>
            </Col>
          </Row>
        </Container>
      </Page>
    )
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
    >
      <Container className="div__sticky-top">
        <SearchBarOnEnter
          searchPlaceholder={'Type to search for commands...'}
          setSearch={setSearchTerm}
          searchTerm={searchTerm}
          withSearchButton
          onChangeHandler={(e) => onChange(e)}
          customHandle={onSearchTriggered}
        />

        <Row style={{marginTop: '20px'}}>
          <Col>
            <Button
              className={"rounded"}
              size="sm"
              outline
              active={currentSec === TTPS_SEC}
              onClick={() => {
                setPreviousSec(currentSec);
                setCurrentSec(TTPS_SEC)
              }}
              hidden={currentSec === SEARCH_SEC}
            >
              TTPS
            </Button>
            <Button
              className={"rounded"}
              size="sm"
              outline
              active={currentSec === MALWARE_FAMILIES_SEC}
              onClick={() => {
                setPreviousSec(currentSec);
                setCurrentSec(MALWARE_FAMILIES_SEC)
              }}
              hidden={currentSec === SEARCH_SEC}>
              Malware Families
            </Button>
          </Col>
        </Row>
      </Container>

      <Container className={'dashboard'}>
        {currentSec === TTPS_SEC &&
          <MitreFrameworkCard
            updateFilters={updateFilters}
            techniques={dataTTPSCount?.data}
            minimalMitre
            count
          />
        }
        {currentSec === MALWARE_FAMILIES_SEC &&
          <>
            <Row className={'mt-3'}>
              <Col md={12}>
                <h3 className="page-title">{malwareFamiliesDisplay.length} results</h3>
              </Col>
            </Row>
            <Row className={'malware-family-wrapper'}>
              {
                malwareFamiliesDisplay.map((malwareFamily) => (
                  <Col lg={3} md={6} sm={6} xs={6} key={malwareFamily.uid}>
                    <MalwareFamilyDetailCard
                      key={malwareFamily.uid}
                      malwareFamily={malwareFamily}
                      withTruncateText
                      withDecoration
                      customOnClick={updateFilters}
                    />
                  </Col>
                ))
              }
            </Row>
          </>
        }
        {(currentSec === TTPS_SEC || currentSec === MALWARE_FAMILIES_SEC) &&
          <ThemeModal
            isOpen={showModal}
            toggle={toggleModal}
            modalClassName={'ltr-support'}
            className={'modal-dialog--header-xl modal-dialog--dark'}
          >
            <div className="modal__body" key={"modal__body"}>
              <CommandsContentSection commands={currentCommands ? currentCommands : []} inModal/>
            </div>
            <ButtonToolbar className="modal__footer">
              <Button outline className="rounded" onClick={() => toggleModal()}>Close</Button>
            </ButtonToolbar>

          </ThemeModal>
        }
        {currentSec === SEARCH_SEC && <>
          {dataCommandsSearch?.data.length === 0 &&
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <Badge text={'white'} className={'w-100 outline badge-lg'}>NO COMMANDS FOUND</Badge>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          }
          {(isLoadingCommandsSearch || isIdleCommandsSearch) ? <LoadingSpinner/> : <Row>
            <Col>
              {dataCommandsSearch?.data.length > 0 && <CommandsContentSection commands={dataCommandsSearch?.data}/>}
            </Col>
          </Row>
          }</>
        }
      </Container>

    </Page>
  )
}


export default Commands;
