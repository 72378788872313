import PropTypes from "prop-types";
import LoadingSpinner from "../LoadingSpinner";
import React, {Fragment} from "react";
import {RTLProps} from "../../prop-types/ReducerProps";
import {connect} from "react-redux";
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {formatDateUTCtoYearMonthDay} from "../../helpers/date";
import getTooltipStyles from "../../helpers";

const LineChart = ({
  title,
  data,
  dataKey,
  dataName,
  subtitle = "",
  isLoading = false,
  isError = false,
  themeName,
  height = 250,
  tickY = false,
  scale = 'linear',
  CustomToolTip,
}) => {
  return (
    <Fragment>
      <div className="card__title">
        <h4 className="bold-text">{title}</h4>
        <h4 className="subhead">{subtitle ? subtitle : ""}</h4>
      </div>
      {
        isError ? <p>ERROR WHILE FETCHING DATA!</p> :
          (isLoading) ?
            <LoadingSpinner/> :
            <div dir="ltr" style={{paddingRight: '45px'}}>
              <ResponsiveContainer height={height} className="dashboard__area">
                <AreaChart data={data} margin={{ top: 20, left: -15, bottom: 20 }}>
                  <XAxis dataKey={x => formatDateUTCtoYearMonthDay(x.day)} tickLine={false} reversed={false} minTickGap={data.length / 2} />
                  <YAxis
                    tick={tickY}
                    tickLine={false}
                    scale={scale}
                    domain={['auto', 'auto']}
                  />
                  <Tooltip
                    {...getTooltipStyles(themeName, 'defaultItems')}
                    formatter={(value, name, props) => value === 0.1 ? 0 : value}
                    content={CustomToolTip ?
                      <CustomToolTip style={{...getTooltipStyles(themeName, 'defaultItems')}}/> : null}
                  />
                  <Legend />
                  <CartesianGrid />
                  <Area
                    name={dataName}
                    type="monotone"
                    dataKey={dataKey}
                    fill="#4ce1b6"
                    stroke="#4ce1b6"
                    fillOpacity={0.2}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
      }
    </Fragment>
  )
}

LineChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  rtl: RTLProps.isRequired,
  scale: PropTypes.string,
  themeName: PropTypes.string.isRequired
};

export default connect(state => ({ themeName: state.theme.className, rtl: state.rtl }))(LineChart);