import React from 'react';
import Tree from "react-d3-tree";
import '../../../scss/component/behavioural-process-tree.scss';

import { useCallback, useState } from "react";

const useCenteredTree = (isHorizontal=true,defaultTranslate = { x: 0, y: 0 }) => {
    const [translate, setTranslate] = useState(defaultTranslate);
    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            const initialX = isHorizontal ? width/5 : width/2;
            const initialY = isHorizontal ? height/3 : height/10;
            setTranslate({ x: initialX, y: initialY });
        }
    }, [isHorizontal]);
    return [translate, containerRef];
};

const renderNodeWithLabel = ({ nodeDatum, toggleNode, isHorizontal}) => (
    <g>
        <circle fill="#f3f3ff" stroke="#2593B8" r="8" strokeWidth="2px" onClick={toggleNode} />
        <text fill="#F3F3FF" strokeWidth="1.5" x={isHorizontal?-(nodeDatum.name.length)*3.6:"15"} y={isHorizontal?"30":5}>
            {nodeDatum.name}
        </text>
        {nodeDatum.attributes?.pid && (
            <text fill="black" x={nodeDatum.attributes?.pid.toString().length*(isHorizontal?-6.5:4.3)} dy={isHorizontal?"46":"26"} strokeWidth="1">
                PID: {nodeDatum.attributes?.pid}
            </text>
        )}
    </g>
);

export default function BehaviouralProcessTree({data, orientation = "horizontal"}) {
    const isHorizontal = orientation === "horizontal";
    const [translate, containerRef] = useCenteredTree(isHorizontal);
    return (
        <div style={{ width: '100%', height: '500px' }} ref={containerRef}>
            <Tree
                data={data}
                translate={translate}
                rootNodeClassName="node__basic"
                branchNodeClassName="node__basic"
                leafNodeClassName="node__basic"
                renderCustomNodeElement={(customNodeElementProps) => renderNodeWithLabel({...customNodeElementProps, isHorizontal})}
                pathClassFunc={() => 'link__basic'}
                orientation={orientation}
            />
        </div>
    );
}