import React from "react";
import FlashAlertForm from "../../../shared/components/form/events-form/FlashAlertForm";
import Page from "../../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_EDITOR} from "../../../shared/helpers/features";


const FlashAlertAdd = () => {
  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
    >
      <FlashAlertForm />
    </Page>
  )
}

export default FlashAlertAdd;
