import PropTypes from "prop-types";
import Collapse from "../../../shared/components/Collapse";
import React from "react";
import ResourceMonitorResultCollapseHeader from "./ResourceMonitorResultCollapseHeader";
import ResourceMonitorResultCollapseContent from "./ResourceMonitorResultCollapseContent";


const ResourceMonitorResultCollapse = ({vuln}) =>
  <Collapse
    title={<ResourceMonitorResultCollapseHeader vuln={vuln}/>}
    className={"with-shadow"}
  >
    <ResourceMonitorResultCollapseContent vuln={vuln}/>
  </Collapse>


ResourceMonitorResultCollapse.propTypes = {
  vuln: PropTypes.object.isRequired
}


export default ResourceMonitorResultCollapse;
