import {Card, CardBody} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ThemedClampLines from "../clamp-lines/ThemedClampLines";
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import paths from "../../../config/paths";
import {useHistory} from "react-router-dom";
import {Interweave} from "interweave";
const FeedlyFeedCard = ({
                          event,
                          updateFilters = null,
                          isDetailCard = null
                        }) => {
  const classes = classNames({
    "card__with_background_icon": true,
    "card__with_background_icon_decoration": true
  })
  const history = useHistory();
  return (
    <Card className={classes}>
      <CardBody>
        <div className="card__title">
          <span onClick={() => {history.push(`${paths.feedlyFeedsPath}/${event.uid}`)}}
                className={`${event !== null ? 'cursor' : ''}`}>
          <h3 className={'bold-text d-inline'} >
            {event?.article_title}
          </h3>
          </span>
          <p className={'subhead'}>
            <b className={'cursor'} style={{pointerEvents: isDetailCard ? 'none' : 'auto'}}
               onClick={() => {updateFilters({'type': 'source_title', 'name': event?.source_title})
            }}>Author: {event?.source_title}</b>
          </p>
        </div>
        {
          <p className={'subhead'}><b>{formatDateUTCtoYearMonthDayTime(event?.date)}</b></p>
        }

        {!isDetailCard ? <ThemedClampLines
          text={event?.article_content}
          maxLines={300}
          lines={5}
          isTextHtml={true}
          footerMessage={event?.article_url}
        /> : <>
          <p className="description"><Interweave content={event?.article_content}/></p>
          <div className={'card-footer'} style={{'text-align': 'center'}}>
            <p className={'text-secondary'}><b>Article Source: </b>
              <a href={event?.article_url} target={"_blank"}>{event?.article_url}</a>
            </p>
          </div>
        </>
        }
      </CardBody>
    </Card>
  )
}

FeedlyFeedCard.propType = {
  event: PropTypes.shape().isRequired,
  style: PropTypes.shape().isRequired,
  withLink: PropTypes.bool,
  withTruncateText: PropTypes.bool,
  withDecoration: PropTypes.bool,
  withEditableButton: PropTypes.bool,
  updateFilters: PropTypes.func
}

export default FeedlyFeedCard;
