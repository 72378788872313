import {Card, CardBody} from "reactstrap";
import IndicatorsTab from "../tab/IndicatorsTab";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {downloadFormats, downloadIndicators} from "../../helpers/download_file";
import DropdownMenuExportFormats from "../form/DropdownMenuExportFormats";


const IndicatorsTabWithTableCard = ({indicators, title = 'Indicators', withExportButton = false, filters={}}) => {
  if (indicators.length === 0) {
    return <Fragment />
  }


  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>{title}</h4>
          {
            withExportButton &&
              <DropdownMenuExportFormats
                downloadDataHook={downloadIndicators}
                downloadFormats={downloadFormats}
                filters={filters}
              />
          }
        </div>
        <IndicatorsTab indicators={indicators} withTable />
      </CardBody>
    </Card>
  )
}


IndicatorsTabWithTableCard.propsType = {
  indicators: PropTypes.array.isRequired,
  withExportButton: PropTypes.bool,
  title: PropTypes.string
}

export default IndicatorsTabWithTableCard;
