import React from 'react';
import {Route} from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import WrappedRoutes from './Routes';


const Router = () => {
  return (
    <MainWrapper>
      <main>
        <Route path="/" component={WrappedRoutes}/>
      </main>
    </MainWrapper>)
};

export default Router;
