import PropTypes from "prop-types";
import InfoCircleOutlineIcon from "mdi-react/InfoCircleOutlineIcon";
import React, {useCallback, useState} from "react";
import ThemeModal from "../../../shared/components/ThemeModal";
import {Button, ButtonToolbar, CardBody} from "reactstrap";
import CopyIconToClipboard from "../../../shared/components/icons/CopyIconToClipboard";


const RuleInfoModal = ({body, title}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => setShowModal(!showModal), [setShowModal, showModal]);


  return (
    <>
      <InfoCircleOutlineIcon size={24} style={{fill: '#ffffff'}} id={`icon-delete`} onClick={(e) => {
        e.stopPropagation();
        toggleModal();
      }} className={`border-hover-white`}/>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header-xl modal-dialog--dark'}
      >
        <div className="modal__header">

          <CardBody className={'rules__card-body rules__col-rule-body'}>
            <CopyIconToClipboard
              value={body}
              copiedMessage={title}
              style={{float: 'right'}}
            />
            <pre className={'pre--rules'}>{body}</pre>
          </CardBody>
        </div>
      </ThemeModal>
    </>
  )
}


RuleInfoModal.propTypes = {
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default RuleInfoModal;
