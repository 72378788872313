import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteSandboxFetcher = (uid, accessToken) => {
  return axios.delete(`${apiUrlInternal}/sandbox/tasks/${uid}`, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useDeleteSandbox = (fetcher = DeleteSandboxFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-sandbox-task'],
    (accessToken, uid) => fetcher(uid, accessToken),
    {onSuccess: () => queryClient.invalidateQueries(['sandbox-tasks', null])}
  )
}
