import PropTypes from "prop-types";
import {getNotificationMessage} from "../../../shared/helpers/notification";
import {formatDateUTCtoMonthDayTime} from "../../../shared/helpers/date";
import React, {Fragment} from "react";


const NotificationHeader = ({notification}) => {
  return (
    <Fragment>
      {
        !notification.read &&
        <div className={'topbar__btn topbar__btn--new'}>
          <div className="topbar__btn-new-label topbar__btn-new-label-notification">
            <div/>
          </div>
        </div>
      }
      <span className={'span__notification-message'}>{getNotificationMessage(notification)}</span>
      <span className={'float-right mr-2'}>{formatDateUTCtoMonthDayTime(notification.created_dt)}</span>
    </Fragment>
  )
}


NotificationHeader.propTypes = {
  notification: PropTypes.object.isRequired
}

export default NotificationHeader;
