import axios from "axios";
import {apiUrlInternal} from "../config/api";
import {addAuthToFetchOpts} from "../config/queryopts";
import {useQueryClient} from "react-query";
import {useQueryWithAuthentication} from "./index";

const ReportRemainingQuotaFetcher = (accessToken, params) => {
  return axios.post(
    `${apiUrlInternal}/report_quota_remaining`,
    params,
    addAuthToFetchOpts(accessToken, {}, 0)
  ).then(res => res.data);
}

export const useReportRemainingQuotaQuery = (
  enabled = true, fetcher = ReportRemainingQuotaFetcher
) => {
  const queryClient = useQueryClient();
  return useQueryWithAuthentication(
    ['report-quota-purchase'],
    (accessToken, params) => fetcher(accessToken, params),
    {enabled: enabled}
  )
}