import {addAuthToFetchOpts} from '../../config/queryopts'
import {useMutationWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SubmitTicketFetcher = (accessToken, params) => {
  return axios.post(
    `${apiUrlInternal}/tickets`,
    params,
    addAuthToFetchOpts(accessToken, {}),
    60000
  ).then(res => res.data);
}

export const useAddTicketMutation = (fetcher = SubmitTicketFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-ticket'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('add-ticket');
        queryClient.invalidateQueries(['users-me']);
        queryClient.invalidateQueries(['tickets', '']);
      }
    }
  )
}
