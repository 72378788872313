import React, {Fragment, useState} from "react";
import {Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent} from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import {ColorTagInlineBadgeList} from "../badge/BadgeLists";
import CopyIconToClipboard from "../icons/CopyIconToClipboard";
import {flatten, getCommonTags, getMalwareFamilyTags} from "../../helpers";
import paths from "../../../config/paths";
import { useHistory } from "react-router-dom";
import {useMitreTTPSQuery} from "../../../queries/MitreTTPS";
import LoadingSpinner from "../LoadingSpinner";

const _ = require('lodash')


const RulesCard = ({rules}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabRule, setActiveTabRule] = useState(0);
  const history = useHistory();
  const { data, isIdle, isLoading, error } = useMitreTTPSQuery();
  const uniqueRulesType = _.uniqBy(rules, (rule) => rule.type).map(rule => (rule.type)).sort();
  let structuredRules = uniqueRulesType.reduce(
    (acc, cur) => ({...acc, [cur]: rules.filter((val) => val.type === cur)}), {}
  )
  const [activeRuleType, setActiveRuleType] = useState(Object.keys(structuredRules)[0]);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setActiveTabRule(0);
      setActiveRuleType(Object.keys(structuredRules)[tab])
    }
  };

  const onTagClick = (type,val) =>{
    const urlParams = new URLSearchParams();
    urlParams.set(type, val);
    history.push(`${paths.rulesPath}?${urlParams.toString()}`);
  }



  if (!rules || rules.length === 0) {
    return <Fragment />
  }
  if (isIdle || isLoading) {
    return <Card><CardBody><LoadingSpinner /></CardBody></Card>;
  }
  if (error) {
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4>Something went wrong getting the Mitre Framework</h4>
          </div>
        </CardBody>
      </Card>
    )
  }
  const mitreTable = data.data;
  const dataMITRE = flatten(mitreTable, 'techniques')
  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Rules</h4>
        </div>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs className={'rules__nav-title'}>
              {
                uniqueRulesType.map((ruleType, index) =>
                  <NavItem key={ruleType}>
                    <NavLink
                      className={classnames({active: activeTab === index})}
                      onClick={() => toggleTab(index)}
                      key={ruleType}
                    >
                      {ruleType.toUpperCase()} ({structuredRules[ruleType].length})
                    </NavLink>
                  </NavItem>
                )
              }
            </Nav>
            <TabContent activeTab={activeTab}>
              <div className="tabs tabs--vertical">
                <div className="tabs__wrap">
                  <Nav tabs className={'rules__nav_content'}>
                    {
                      structuredRules[activeRuleType].map((rule, index) =>
                        <NavItem key={rule.uid}>
                          <NavLink
                            className={classnames({active: activeTabRule === index})}
                            style={{borderBottom: '0px'}}
                            onClick={() => setActiveTabRule(index)}
                            key={rule.uid}
                          >
                            {rule.title.toUpperCase()}
                          </NavLink>
                        </NavItem>
                      )
                    }
                  </Nav>
                  <TabContent activeTab={activeTab} className={'w-100 h-100'}>
                    <Row className={'my-2 rules__card-body'}>
                      <Col md={10} className={'rules__col-rule-body'}>
                        <CopyIconToClipboard
                          value={structuredRules[activeRuleType][activeTabRule].text}
                          copiedMessage={structuredRules[activeRuleType][activeTabRule].title}
                          style={{float: 'right'}}
                          toastProps={{position: 'bottom-right'}}
                        />
                        <pre className={'pre--rules'}>{structuredRules[activeRuleType][activeTabRule].text}</pre>
                      </Col>
                      <Col md={2}>
                        {
                          getCommonTags(structuredRules[activeRuleType][activeTabRule].tags).length > 0 &&
                            <Fragment>
                              <h5 className={'my-2'}>TAGS</h5>
                              <ColorTagInlineBadgeList
                                items={getCommonTags(structuredRules[activeRuleType][activeTabRule].tags)}
                                size={'sm'}
                                type="yara_tags"
                                onTagClick={ (val) => { onTagClick('tags',val)  }}
                              />
                            </Fragment>
                        }
                        {
                          getMalwareFamilyTags(structuredRules[activeRuleType][activeTabRule].tags).length > 0 &&
                            <Fragment>
                              <h5 className={'my-2'}>MALWARE FAMILIES</h5>
                              <ColorTagInlineBadgeList
                                items={getMalwareFamilyTags(structuredRules[activeRuleType][activeTabRule].tags)}
                                size={'sm'}
                                type="yara_tags"
                                onTagClick={ (val) => { onTagClick('tags',val)  }}
                              />
                            </Fragment>
                        }
                        {
                          structuredRules[activeRuleType][activeTabRule].techniques.length > 0 &&
                          <Fragment>
                            <h5 className={'my-2'}>TECHNIQUES</h5>
                            <ColorTagInlineBadgeList
                              items={structuredRules[activeRuleType][activeTabRule].techniques.map((technique) => {
                                const match = dataMITRE.find((tech)=>tech.uid === technique)
                                if (match) {
                                  return {name: `${match.mitre_code} - ${match.name}`}
                                }
                              })}
                              size={'sm'}
                              type="yara_tags"
                              onTagClick={ (val) => { onTagClick('techniques',val)  }}
                            />
                          </Fragment>
                        }
                      </Col>
                    </Row>
                  </TabContent>
                </div>
              </div>
            </TabContent>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

RulesCard.propTypes = {
  rules: PropTypes.array.isRequired
}

export default RulesCard;
