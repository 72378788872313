import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTableDnDBody from './ReactTableDnDBody';
import { ThemeProps } from '../../../prop-types/ReducerProps';
import {Progress} from "reactstrap";

const onRowClickGen = (onRowClick, getRowDataId, row) => {
  return (onRowClick) ? (
    () => ((getRowDataId) ? onRowClick(getRowDataId(row, row.index), row) : onRowClick())
  ) : null;
}

const defaultPropGetter = () => ({})

const ReactTableDefaultBody = ({ page, getTableBodyProps, prepareRow, getRowDataId, onRowClick,
                                 getCellProps = defaultPropGetter,getColumnProps=defaultPropGetter}) => (
  <tbody className="table table--bordered" {...getTableBodyProps()}>
    {page.map((row) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()} id={(getRowDataId) ? "row-" + getRowDataId(row, row.index) : "row-" + row.index} data-key={(getRowDataId) ? getRowDataId(row, row.index) : row.index} onClick={onRowClickGen(onRowClick, getRowDataId, row)}>
          {row.cells.map(cell =>
                <td {...cell.getCellProps([
                  {
                    className: cell.column.className,
                    style: cell.column.style,
                  },
                  getColumnProps(cell.column),
                  getCellProps(cell),
                ])}>{
                  cell.row?.original?.isLoading ?
                    <div className={'progress-wrap progress-wrap--small progress-wrap--grey w-25'}><Progress animated value={100} /></div> :
                    cell.render('Cell')
                }
                </td>
          )}
        </tr>
      );
    })}
  </tbody>
);

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData, theme, getRowDataId, onRowClick
}) => (
  <Fragment>
    {withDragAndDrop
      ? (
        <ReactTableDnDBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateDraggableData={updateDraggableData}
          theme={theme}
        />
      ) : (
        <ReactTableDefaultBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          getRowDataId={getRowDataId}
          onRowClick={onRowClick}
        />
      )
    }
  </Fragment>
);

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  getRowDataId: PropTypes.func,
  onRowClick: PropTypes.func,
  withDragAndDrop: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
};

export default connect(state => ({
  theme: state.theme,
}))(ReactTableBody);
