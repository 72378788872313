import React from "react";
import TableWithData from "../table/TableWithData";
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import PropTypes from "prop-types";
import CountryBadge from "../badge/CountryBadge";
import ThemedClampLines from "../clamp-lines/ThemedClampLines";
import DownloadContentIcon from "../icons/DownloadContentIcon";
import _ from "lodash";



export const tableColumns = [
  { Header: 'Seen on', accessor: 'created_dt',
    Cell: ({value}) => value ? formatDateUTCtoYearMonthDayTime(value) : 'N/A'
  },
  { Header: 'Forum', accessor: 'forum_name' },
  { Header: 'Seller', accessor: 'account_seller' },
  { Header: 'Account Description', accessor: 'account_description',
    Cell: ({value}) => !value ? 'N/A' :
      <div style={{maxWidth: '600px'}}><ThemedClampLines text={value} maxLines={100} /></div>
  },
  { Header: 'Country', accessor: 'account_country',
    Cell: ({value}) => !!value && value.length === 2 && !['ww', 'en'].includes(value.toLowerCase()) ?
      <CountryBadge countryCode={value} /> : 'N/A'
  },
  { id:'download', Header: '', accessor: 'account_description',
    Cell: ({value, row}) => <DownloadContentIcon
      content={value}
      filename={`${row.values.created_dt}_${row.values.forum_name}.txt`}
    />
  },
];

const getCsvMappedData = (data)=>{
  let finalData = [];
  if(data && data.length > 0){
    data.forEach((item) => {
      finalData.push({
        'Seen on': item.created_dt ? formatDateUTCtoYearMonthDayTime( item.created_dt ) : 'N/A',
        'Forum': item.Forum,
        'Seller': item.account_seller,
        'Account Description': item.account_description,
        'Country': item.account_country
      })
    });
  }
  return finalData && finalData.length > 0 ? finalData : [];
};


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
  isSortable: true,
  withCsvDownload: true
}


const SellersTable = ({sellers, hasDownloadIcon = true, extraColumnsAfter = [], extraColumnsBefore = []}) =>
  <TableWithData
    data={_.orderBy(sellers,['created_dt'],['desc'])}
    tableColumns={
      hasDownloadIcon ?
        extraColumnsBefore.concat(tableColumns.concat(extraColumnsAfter)) :
        extraColumnsBefore.concat(tableColumns.slice(0, -1).concat(extraColumnsAfter))
    }
    tableConfig={tableConfig}
    csvData={hasDownloadIcon ? getCsvMappedData(_.orderBy(sellers,['created_dt'],['desc'])) : []}
  />



SellersTable.propTypes = {
  sellers: PropTypes.array.isRequired,
  hasDownloadIcon: PropTypes.bool,
  extraColumnsAfter: PropTypes.array,
  extraColumnsBefore: PropTypes.array,
}


export default SellersTable;
