import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useRulesByUidQuery} from "../../../../queries/RulesSection";
import ActorsCard from "../../../../shared/components/card/ActorsCard";
import {Card, CardBody, Col, Row} from "reactstrap";
import ContentCardHeader from "../../../../shared/components/card/accessories/ContentCardHeader";
import ReferencesRow from "../../../../shared/components/card/accessories/ReferencesRow";
import ContentCardFooter from "../../../../shared/components/card/accessories/ContentCardFooter";
import ContentEditsBar from "../../../../shared/components/card/accessories/ContentEditsBar";
import {useCurrentUserQuery} from "../../../../queries/CurrentUser";
import {canUserCreateContent} from "../../../../shared/helpers/permissions";
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../../shared/components/ErrorHandler";
import RulesSectionCard from "../../../../shared/components/card/RulesSectionCard";

const RulesCollapseContent = ({rule}) => {
  const {data: dataCurrentUser, isIdle: isIdleCurrentUser, isLoading: isLoadingCurrentUser} = useCurrentUserQuery();
  const isEditable = canUserCreateContent(dataCurrentUser?.data);

  const {
    data: dataRulesByUid,
    isLoading: isLoadingRulesUid,
    isIdle: isIdleRulesUid,
    isError: isErrorRulesUid,
    error: errorRulesUid
  } = useRulesByUidQuery(rule.uid)

  if (isErrorRulesUid) {
    return <ErrorHandler
      error={errorRulesUid}/>
  }
  if (!rule) {
    return <Fragment/>
  }
  if (isLoadingRulesUid || isIdleRulesUid) {
    return <Card><CardBody><LoadingSpinner/></CardBody></Card>;
  }
  return (
    <>
      <Row>
        <RulesSectionCard rules={[rule]} withTitle={false} singleTab={true}/>
      </Row>
      {dataRulesByUid.data?.related_analysis &&
        <Row>
          <Col md={5} lg={5} xl={5}>
            {
              dataRulesByUid?.data &&
              <ActorsCard
                actors={dataRulesByUid.data.related_analysis?.actors ? dataRulesByUid.data.related_analysis.actors : []}
              />
            }
          </Col>
          <Col md={7} lg={7} xl={7}>
            {dataRulesByUid?.data && <Card className={'h-auto'}>
              <CardBody>
                <ContentCardHeader
                  updateFilters={() => {
                  }}
                  confidence={dataRulesByUid.data.related_analysis?.confidence}
                  tlp={dataRulesByUid.data.related_analysis.tlp.tlp}
                  title={dataRulesByUid.data.related_analysis.title}
                  created_dt={dataRulesByUid.data.related_analysis.created_dt}
                  uid={dataRulesByUid.data.related_analysis.uid}
                />
                <pre className={'mb-3 pre--text'}>{dataRulesByUid.data.related_analysis.text}</pre>
                {
                  dataRulesByUid.data.related_analysis.references?.length > 0 &&
                  <ReferencesRow references={dataRulesByUid.data.related_analysis.references}/>
                }
                <ContentCardFooter
                  tags={dataRulesByUid.data.related_analysis.tags}

                  tlp={dataRulesByUid.data.related_analysis.tlp.tlp}
                />
                {
                  isEditable &&
                  <div style={{position: 'absolute', bottom: '40px', right: '10px'}}>
                    <ContentEditsBar content={dataRulesByUid.data.related_analysis} type={'analysis'}/>
                  </div>
                }
              </CardBody>
            </Card>}
          </Col>
        </Row>}
    </>
  )
}


RulesCollapseContent.propTypes = {
  rule: PropTypes.object.isRequired
}


export default RulesCollapseContent;
