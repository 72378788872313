import {indexOf} from "@amcharts/amcharts4/.internal/core/utils/Array";


const statusLabels = ['low_threat', 'suspicious', 'malicious'];
const statusLabelColors = ['success', 'warning', 'danger'];


export const getStatusColor = (value) => {
  return statusLabelColors[indexOf(statusLabels, value.toLowerCase())];
}
