import React, {Fragment, useMemo} from "react";
import PropTypes from "prop-types";
import {CardBody, Col, Row} from "reactstrap";
import ThemedClampLines from "../../../shared/components/clamp-lines/ThemedClampLines";
import {CountryBadgeList} from "../../../shared/components/badge/BadgeLists";
import TargetVictimMaps from "../../../shared/components/maps/EasyVectorMap/TargetVictimMaps";
import ActorCountryBadge from "../../../shared/components/badge/ActorCountryBadge";
import ContentCardFooter from "../../../shared/components/card/accessories/ContentCardFooter";
import {getCommonTags} from "../../../shared/helpers";
import {getCountryNameOrCodeShort} from "../../../shared/helpers/country-data";


const ActivityBody = ({activity, updateFilters = () => {}}) => {
  const commonTags = useMemo(() => getCommonTags(activity.tags), [activity.tags]);

  return (
    <CardBody>
      <Row>
        <Col md={4} xs={12}>
          <p>
            <ThemedClampLines
              text={activity.text}
              lines={6}
              maxLines={12}
            />
          </p>
        </Col>
        <Col md={8} xs={12}>
          <Row>
            <Col md={12}>
              <p className="bold-text">Targeted countries</p>
            </Col>
          </Row>
          <Row>
            <Col md={4} className={'mb-3'}>
              {
                activity.targeted_countries.length > 0 ?
                  <CountryBadgeList updateFilters={(display, item) => {
                    updateFilters(getCountryNameOrCodeShort(item), 'targeted_countries');
                  }} countries={activity.targeted_countries}/> :
                  <h5>NO TARGETED COUNTRIES</h5>
              }
              {
                activity.actors && activity.actors.length > 0 && <Fragment>
                  <Col md={12} className={'p-0 mt-2'}>
                    <p className="bold-text">Actors origin country</p>
                  </Col>
                  <Col md={12}  className={'p-0'}>
                    {activity.actors.map((actor) => <ActorCountryBadge actorUid={actor.uid}/>)}
                  </Col>
                </Fragment>
              }
            </Col>
            <Col md={8}>
              <TargetVictimMaps
                countryCodes={activity.targeted_countries}
                idMap={activity.uid}
                height={'24vh'}
                withZoomButton={false}
              />
            </Col>
          </Row>
        </Col>
        <Col md={12} xs={12}>
          <ContentCardFooter tags={commonTags} tlp={activity.tlp.tlp}/>
        </Col>
      </Row>
    </CardBody>
  )
}

ActivityBody.propTypes = {
  activity: PropTypes.shape().isRequired,
  updateFilters: PropTypes.func
}

export default ActivityBody;