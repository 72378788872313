import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { TabPane } from 'reactstrap';
import { useIndicatorInvestigateQuery } from '../../../queries/IndicatorInvestigateFilesServices';
import ErrorHandler from '../../../shared/components/ErrorHandler';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroller';

const PaginatedTabPane = ({
  indicator,
  tabId,
  Component,
  endpoint_type,
  setTotal,
}) => {
  const LOADING_STEP = 30;
  const INITIAL_ITEMS = 30;
  const [loadedItems, setLoadedItems] = useState(INITIAL_ITEMS);
  const [next, setNext] = useState(null);
  const [totalContents, setTotalContents] = useState(0);
  const [displayContentArray, setDisplayContentArray] = useState([]);
  const [contentArray, setContentArray] = useState([]);

  const {
    data,
    isIdle,
    isLoading,
    isError,
    error
  } = useIndicatorInvestigateQuery(
    endpoint_type, indicator, next
  );

  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data?.data) {
      setTotalContents(data.metadata.total);
      if (!next && displayContentArray.length === 0) {
        setContentArray(contentArray.concat(data.data));
        setDisplayContentArray(displayContentArray.concat(data.data));
        setLoadedItems(INITIAL_ITEMS);
        setTotal(data.data.length, endpoint_type)
      } else {
        setContentArray(contentArray.concat(data.data));
        setDisplayContentArray(displayContentArray.concat(data.data));
        setLoadedItems(loadedItems + LOADING_STEP);
        setTotal(contentArray.concat(data.data).length, endpoint_type)
      }
    }
  }, [isIdle, isLoading, isError, data?.data]);

  const loadMoreItems = () => {
    if (contentArray.length > loadedItems + LOADING_STEP) {
      setDisplayContentArray(contentArray.slice(0, loadedItems + LOADING_STEP));
      setLoadedItems(loadedItems + LOADING_STEP);
    } else if (contentArray.length === totalContents) {
      setDisplayContentArray(contentArray);
      setLoadedItems(totalContents);
      setTotal(totalContents, endpoint_type)
    } else if (data?.metadata.next) {
      setNext(data?.metadata?.next);
    }
  };
  if (isError) {
    return <ErrorHandler error={error}/>;
  }

  return (
    <TabPane tabId={tabId}>
      {
        (isIdle || isLoading) && displayContentArray.length === 0 &&
        <LoadingSpinner/>
      }
      {
        contentArray.length > 0 &&
        <div className="card__body-communicating-files">
          <InfiniteScroll
            loadMore={loadMoreItems}
            hasMore={loadedItems < totalContents}
            loader={isLoading && !isIdle && <LoadingSpinner/>}
            useWindow={false}
          >
            {
              <Component data={displayContentArray}/>
            }
          </InfiniteScroll>
        </div>
      }
    </TabPane>
  );
};

PaginatedTabPane.propTypes = {
  indicator: PropTypes.string,
  tabId: PropTypes.number,
  Component: PropTypes.element,
  endpoint_type: PropTypes.string,
  setTotal: PropTypes.func,
};

export default PaginatedTabPane;
