import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const AddKeywordProjectFetcher = (accessToken, organizationUid, params) => {
  return axios.post(
    `${apiUrlInternal}/keyword_projects/organizations/${organizationUid}`,
    params,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useAddKeywordProject = (fetcher = AddKeywordProjectFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-organization-keyword-project'],
    (accessToken, {params, organizationUid}) => fetcher(accessToken, organizationUid, params),
    {
      onSuccess: (_, {organizationUid}) => {
        queryClient.invalidateQueries('add-organization-keyword-project');
        queryClient.invalidateQueries(['organizations-keyword-projects', organizationUid]);
      }
    }
  )
}
