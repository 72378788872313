import React from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import CommandsCollapse from "./collapse/CommandsCollapse";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";


const CommandsContentSection = ({commands, inModal = false}) => {

  return (
    <Card>
      {!inModal ? <CardBody>
        <div className="card__title">
          {!commands.length ?
            <LoadingSpinner/> :
          <>
            <h4 className={'bold-text d-inline'}>{commands.length} {commands.length === 1 ? 'command': 'commands'} found</h4>
            {
              commands.map((command) =>
                <CommandsCollapse
                  command={command}
                />
              )
            }
          </>
          }
        </div>
      </CardBody> :
        <div className="card__title">
          {!commands.length ?
            <LoadingSpinner/> :
            <>
              <h4 className={'bold-text d-inline'}>{commands.length} {commands.length === 1 ? 'command': 'commands'} found</h4>
              {
                commands.map((command) =>
                  <CommandsCollapse
                    command={command}
                  />
                )
              }
            </>
          }
        </div>
      }
    </Card>
  )
}



CommandsContentSection.propTypes = {
  commands: PropTypes.object.isRequired
}


export default CommandsContentSection;