import React from "react";
import TableWithData from "../table/TableWithData";
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import PropTypes from "prop-types";
import CountryBadge from "../badge/CountryBadge";
import _ from "lodash";


export const tableColumns = [
  { Header: 'Collected time', accessor: 'date_collect',
    Cell: ({value}) => value ? formatDateUTCtoYearMonthDayTime(value) : 'N/A'
  },
  { Header: 'IP', accessor: 'ip' },
  { Header: 'Domain', accessor: 'domain' },
  { Header: 'Source', accessor: 'source',
    Cell: ({value}) => value?.replace(/andariel/i, '') || 'N/A'
  },
  { Header: 'ISP', accessor: 'isp' },
  { Header: 'Country', accessor: 'geoip',
    Cell: ({value}) => value?.country_code2 ? <CountryBadge countryCode={value.country_code2} /> : 'N/A'
  },
  { Header: 'Hostname', accessor: 'prefix' },
  { Header: 'OS', accessor: 'system' },
  { Header: 'User', accessor: 'username' },
  { Header: 'Password', accessor: 'password' },
];


export const getCsvMappedData = (data)=>{
  let finalData = [];
  if(data && data.length > 0){
    data.forEach((item) => {
      finalData.push({
        'Collected time': item.date_collect ? formatDateUTCtoYearMonthDayTime( item.date_collect ) : 'N/A',
        'IP': item.ip,
        'Domain': item.domain,
        'Source': item.source,
        'ISP':item.isp,
        'Country': item.geoip && item.geoip.country_code2 ? item.geoip.country_code2 : 'N/A',
        'Hostname': item.prefix,
        'OS': item.system,
        'User': item.username,
        'Password': item.password
      })
    });
  }
  return finalData && finalData.length > 0 ? finalData : [];
};


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
  isSortable: true,
  withCsvDownload: true
}


const BotNetTable = ({botnets, extraColumns = []}) =>
  <TableWithData
    data={_.orderBy(botnets, ['date_collect'], 'desc')}
    tableColumns={tableColumns.concat(extraColumns)}
    tableConfig={tableConfig}
    csvData={getCsvMappedData(_.orderBy(botnets, ['date_collect'], 'desc'))}
  />


BotNetTable.propTypes = {
  botnets: PropTypes.array.isRequired,
  extraColumns: PropTypes.array
}


export default BotNetTable;
