import React from "react";
import PropTypes from "prop-types";
import ContentCardHeader from "./ContentCardHeader";


const InfoRow = ({title, value}) => {
    return (
        <div className="custom_card__container" style={{paddingLeft: '0px', marginBottom: '0px'}}>
            <p className="custom_card__container-subhead">{title}</p>
            <p className="custom_card__container-title">
                {value}
            </p>
        </div>
    )
}

InfoRow.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
}

export default InfoRow;
