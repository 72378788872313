import React from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import RulesCollapse from "./collapse/RulesCollapse";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";


const RulesContentSection = ({rules, inModal = false}) => {

  return (
    <Card>
      {!inModal ?
        <CardBody>
          <div className="card__title">
            {!rules.length ?
              <LoadingSpinner/> :
              <>
                <h4 className={'bold-text d-inline'}>{rules.length} {rules.length === 1 ? 'rule' : 'rules'} found</h4>
                {
                  rules.map((rule) =>
                    <RulesCollapse  rule={rule} />
                  )
                }
              </>
            }
          </div>
        </CardBody> :
        <div className="card__title">
          {!rules.length ?
            <LoadingSpinner/> :
            <>
              <h4 className={'bold-text d-inline'}>{rules.length} {rules.length === 1 ? 'rule' : 'rules'} found</h4>
              {
                rules.map((rule) => <RulesCollapse rule={rule}/>)
              }
            </>
          }
        </div>
      }
    </Card>
  )
}


RulesContentSection.propTypes = {
  rules: PropTypes.object.isRequired,
  inModal: PropTypes.bool
}


export default RulesContentSection;