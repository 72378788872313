import React, {useEffect, useMemo, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import {Col, Container, Row} from "reactstrap";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import {getRansomwareTrackerEventsListFilterMenu} from "../../shared/helpers/filter_menu";
import {getRansomwareTrackerEventsListOrderMenu} from "../../shared/helpers/order_menu";
import SearchMenu from "../../shared/components/form/SearchMenu";
import RansomwareTrackerEventCard from "../../shared/components/card/RansomwareTrackerEventCard";
import {paramsToObject, useQueryParams} from "../../shared/components/router/QueryNavigationHelpers";
import {useHistory} from "react-router-dom";
import paths from "../../config/paths";
import {useCountriesQuery} from "../../queries/Countries";
import {useSearchRansomwareTrackerEventsQueryWithDict} from "./queries/SearchRansomwareTrackerEvents";
import {useActorDetailsQuery} from "../../queries/Actor";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const _ = require('lodash');


const RansomwareTrackerEventList = () => {
  const params = paramsToObject(useQueryParams().entries());
  const history = useHistory();
  const INITIAL_ITEMS = 20
  const LOADING_STEP = 20;

  const [show, setShow] = useState(false);
  const [loadedItems, setLoadedItems] = useState(LOADING_STEP);
  const [offset, setOffset] = useState(loadedItems - LOADING_STEP);
  const [ransomwareTrackerEventsArray, setRansomwareTrackerEventsArray] = useState([]);
  const [ransomwareTrackerEventsDisplay, setRansomwareTrackerEventsDisplay] = useState([]);
  const [totalRansomwareTrackerEvents, setTotalRansomwareTrackerEvents] = useState(0)
  const [forceUpdateFilters, setForceUpdateFilters] = useState(false);
  const {
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();
  const {
    data: dataCountries,
    isIdle: isIdleCountries,
    isLoading: isLoadingCountries,
    isError: isErrorCountries
  } = useCountriesQuery();
  const {
    data: dataActors,
    isIdle: isIdleActors,
    isLoading: isLoadingActors,
    isError: isErrorActors
  } = useActorDetailsQuery();

  const [filters, setFilters] = useState({});

  const {data, isIdle, isLoading, isError, error} = useSearchRansomwareTrackerEventsQueryWithDict(
    Object.assign({}, filters, {offset: offset, limit: LOADING_STEP})
  );

  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data) {
      setTotalRansomwareTrackerEvents(data.metadata.total);

      if (!offset) {
        setRansomwareTrackerEventsArray(data.data);
        setRansomwareTrackerEventsDisplay(data.data.slice(0, INITIAL_ITEMS));
        setLoadedItems(INITIAL_ITEMS);
      } else {
        setRansomwareTrackerEventsArray(ransomwareTrackerEventsArray.concat(data.data));
        setRansomwareTrackerEventsDisplay(ransomwareTrackerEventsArray.concat(data.data).slice(0, loadedItems + LOADING_STEP));
        setLoadedItems(loadedItems + LOADING_STEP);
      }
    }
  }, [isIdle, isLoading, isError, data])

  const isLoadingIdleErrorMenu = isIdleActors
    || isLoadingActors || isErrorActors || isIdleCurrentUser || isLoadingCurrentUser || isErrorCurrentUser
    || isLoadingCountries || isIdleCountries || isErrorCountries;

  const isLoadingIdleMenu = isIdleActors || isLoadingActors || isIdleCurrentUser ||
    isLoadingCurrentUser || isLoadingCountries || isIdleCountries;

  const ORDER_MENU = getRansomwareTrackerEventsListOrderMenu();
  const FILTER_MENU = useMemo(
    () => (!isLoadingIdleErrorMenu) ? getRansomwareTrackerEventsListFilterMenu(dataActors, dataCountries) : [],
    [isLoadingIdleErrorMenu]
  )


  useEffect(() => {
    if (isIdle || isLoading || !data) {
      setOffset(null);

      setShow(false);
      setRansomwareTrackerEventsDisplay([]);
      setRansomwareTrackerEventsArray([]);
      setTimeout(() => {
        setShow(true)
      }, 1000)
    }
  }, [filters])

  const loadMoreItems = () => {

    if (ransomwareTrackerEventsArray.length >= loadedItems + LOADING_STEP) {
      setRansomwareTrackerEventsDisplay(ransomwareTrackerEventsArray.slice(0, loadedItems + LOADING_STEP));
      setLoadedItems(loadedItems + LOADING_STEP);

    } else if (ransomwareTrackerEventsArray.length === totalRansomwareTrackerEvents) {
      setRansomwareTrackerEventsDisplay(ransomwareTrackerEventsArray);
      setLoadedItems(totalRansomwareTrackerEvents);
    } else if (data?.metadata.next) {
      setOffset(offset + data?.metadata.next);
    }
  }

  if (isLoadingIdleMenu || isIdleActors || isLoadingActors || isIdleCountries || isLoadingCountries || isIdleCurrentUser || isLoadingCurrentUser) {
    return <LoadingSpinnerPage/>
  }

  if (isError || isErrorCurrentUser) {
    return <ErrorHandler error={error}/>
  }
  const updateFilters = (display_value, selected_field = null) => {
    let filterSelected = null;
    if (!selected_field) {
      let index = -1;
      FILTER_MENU.forEach(
        (filter, i) => {
          if (filter.values.map((v) => display_value.name.includes(filter.displayValueFn(v)))) {
            index = i;
          }
        }
      )

      if (index < 0) {
        return
      }

      filterSelected = FILTER_MENU[index];
    } else {
      filterSelected = FILTER_MENU.find((filter) => filter.field === selected_field);
    }

    const filter_field = filterSelected.field;
    const filter_value = filterSelected.values.find((v) => display_value.name.includes(filterSelected.displayValueFn(v)));

    if (filters[filter_field]?.includes(filter_value)) {
      return;
    }

    if (!filterSelected.multi) {
      filters[filter_field] = filter_value;
    } else {
      if (filters[filter_field]) {
        filters[filter_field].push(filter_value);
      } else {
        filters[filter_field] = [filter_value];
      }
    }

    const urlParams = new URLSearchParams();

    _.entries(filters).forEach(([filter_field, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => urlParams.append(filter_field, v));
      } else {
        urlParams.set(filter_field, value)
      }
    });

    setForceUpdateFilters(true);
    history.replace(`${paths.ransomwareTrackerEventsPath}?${urlParams}`);
  }

  const title = (isIdle || isLoading || !show || ransomwareTrackerEventsDisplay.length === 0) ?
     "" : `${totalRansomwareTrackerEvents} results`;

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      onlyOnline
    >
      <Container>
        <Row>
          <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto'}>
            <SearchMenu
              setFilters={setFilters}
              baseUrl={paths.ransomwareTrackerEventsPath}
              forceUpdateFilters={forceUpdateFilters}
              setForceUpdateFilters={setForceUpdateFilters}
              defaultFilters={params}
              searchPlaceholder={'Search ransomware events...'}
              orderMenu={ORDER_MENU}
              filterMenu={FILTER_MENU}
              fromDateField={'start'}
              toDateField={'end'}
              searchField={'search_term'}
            />
          </Col>
        </Row>
        <Row className={'mt-3'}>
          <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto'}>
            <h3 className="page-title">{title}</h3>
          </Col>
        </Row>

        {
          (isIdle || isLoading || !show) && ransomwareTrackerEventsDisplay.length === 0 ?
            <LoadingSpinner/> :
            <Row>
              <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto mb-2'}>
                <InfiniteScroll
                  loadMore={loadMoreItems}
                  hasMore={loadedItems < totalRansomwareTrackerEvents}
                  loader={isLoading && !isIdle && <LoadingSpinner key={loadedItems}/>}
                >
                  <Row className={'mt-3'}>
                    {
                      ransomwareTrackerEventsDisplay.map((event, index) => (
                        <Col lg={3} md={6} sm={6} xs={6} key={index}>
                          <RansomwareTrackerEventCard
                            event={event}
                            withLink
                            withTruncateText
                            withDecoration
                            updateFilters={updateFilters}
                          />
                        </Col>

                      ))
                    }
                  </Row>

                </InfiniteScroll>
              </Col>
            </Row>
        }
      </Container>
    </Page>
  )
}

export default RansomwareTrackerEventList;
