import {addAuthToAxiosOpts} from '../../config/queryopts'
import {useQueryWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import PropTypes from "prop-types";


export const SandboxTaskDetailsFetcher = (accessToken, value = null) => {
    const uri = (value) ? `/${value}` : '';
    return axios.get(`${apiUrlInternal}/sandbox/tasks${uri}`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useSandboxTaskDetailsQuery = (value = null, fetcher = SandboxTaskDetailsFetcher) => {
    return useQueryWithAuthentication(['sandbox-task-details',  value],
        (accessToken) => fetcher(accessToken, value),{ cacheTime :1000*60*60,staleTime :1000*60*60,refetchOnMount:false,refetchOnWindowFocus: false })
}

export const SandboxTaskDetailsScreenshotsFetcher = (accessToken, value, index=null) => {
    return axios.get(`${apiUrlInternal}/sandbox/tasks/${value}/screenshots/${index}`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useSandboxTaskDetailsScreenshotsQuery = (value, index,fetcher = SandboxTaskDetailsScreenshotsFetcher) => {
    return useQueryWithAuthentication(['sandbox-task-details-screenshots',  value.toString()+index.toString()],
        (accessToken) => fetcher(accessToken, value, index))
}


useSandboxTaskDetailsQuery.propTypes = {
    value: PropTypes.string,
};

useSandboxTaskDetailsScreenshotsQuery.propTypes = {
    value: PropTypes.string.isRequired,
    index: PropTypes.string.isRequired
};