import {Button, Input} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";

const SearchBarOnEnter = ({
  searchTerm,
  searchPlaceholder,
  setSearch,
  errorMessage = '',
  withSearchButton = false,
  customHandle = () => {},
  buttonDisabled = false,
  onChangeHandler = () => {},
  searchDisabled = false
}) => {
  const [tmpSearchTerm, setTmpSearchTerm] = useState(searchTerm);


  useEffect(() => {
    setTmpSearchTerm(searchTerm);
  }, [searchTerm])

  return (
    <Fragment>
      {
        withSearchButton &&
          <Button
            outline
            onClick={() => {
              customHandle(tmpSearchTerm);
              setSearch(tmpSearchTerm);
            }}
            color={'success'}
            className={'float-right search_bar-button'}
            disabled={buttonDisabled || searchDisabled}
          >Search</Button>
      }
      <div>
        <Input
          type={'text'}
          name={'search'}
          id={'search'}
          value={tmpSearchTerm}
          placeholder={searchPlaceholder}
          className={'search_bar'}
          style={withSearchButton ? {width: '80%', display: "inline"} : {}}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && e.target.value !== '') {
              customHandle(tmpSearchTerm);
              setSearch(tmpSearchTerm);
            }
          }}
          onChange={(e) => {
            e.preventDefault();
            setTmpSearchTerm(e.target.value);
            onChangeHandler(e)
          }}
        />
        {tmpSearchTerm && errorMessage && <span className="form__form-group-error-searchbar">{errorMessage}</span>}
      </div>
    </Fragment>
  )
}

SearchBarOnEnter.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  withSearchButton: PropTypes.bool,
  searchDisabled: PropTypes.bool,
  customHandle: PropTypes.func,
  onChangeHandler: PropTypes.func
}


export default SearchBarOnEnter;
