import React from "react";
import PropTypes from "prop-types";
import {Badge, Card, CardBody, Col, Row} from "reactstrap";
import BotNetSourceMap from "../../../shared/components/darkweb/BotNetSourceMap";
import BotNetTable from "../../../shared/components/darkweb/BotNetTable";


const BotnetResults = ({events}) =>
  <Row>
    <Col>
      <Card className={'h-auto'}>
        <CardBody>
          <Row>
            <Col>
              <Badge color={'danger'} className={'w-100 outline badge-lg'}>
                {
                  events.length === 1 ? `THERE IS 1 HOST COMPROMISED BY BOTNET` :
                    `THERE ARE ${events.length} HOSTS COMPROMISED BY BOTNET`
                }
              </Badge>
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col>
              <BotNetSourceMap botnets={events}/>
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col>
              <div className="card__title">
                <h4 className={'bold-text d-inline'}>RESULTS</h4>
              </div>

              <BotNetTable botnets={events}/>
            </Col>
          </Row>

        </CardBody>
      </Card>
    </Col>
  </Row>


BotnetResults.propTypes = {
  events: PropTypes.array.isRequired
}

export default BotnetResults;
