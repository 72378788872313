import React, { useMemo } from "react";
import PieChartMultipleItems from "../../../../shared/components/charts/PieChartMultipleItems";
import { useStatsSandboxTasksAll } from "../../../../queries/Stats";
import {Card, CardBody} from "reactstrap";


const SandboxTaskResultsPie = () => {
    const { data, isIdle, isLoading, isError } = useStatsSandboxTasksAll();
    const details = data?.data?.details;
    const cleanResult = details?.low_threat;
    const suspiciousResult = details?.suspicious;
    const maliciousResult = details?.malicious;

    const dataSets = [
        { value: cleanResult, fill: "#4ce1b6", name: "Clean" },

        { value: suspiciousResult, fill: "#f6da6e", name: "Suspicious" },

        { value: maliciousResult, fill: "#ff4861", name: "Malicious" },
    ];

    const dataType = useMemo(
        () =>
            dataSets
                .filter((data) => data.value !== 0)
                .map((value) => ({
                    name: value.name,
                    value: value.value,
                    fill: value.fill,
                })),
        [dataSets]
    );

    return (
      <Card>
        <CardBody>
          <PieChartMultipleItems
            data={dataType}
            isError={isError}
            isLoading={isLoading || isIdle}
            title={"Sandbox Results"}
            classNamePie={'dashboard__chart-pie--sandbox-types'}
          />
        </CardBody>
      </Card>
    );
};

SandboxTaskResultsPie.propTypes = {};

export default SandboxTaskResultsPie;
