import PropTypes from "prop-types";
import React, {useCallback, useState} from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import _ from "lodash";
import classnames from "classnames";
import {bytesToSizeOrNA} from "../../helpers";
import TableWithDataHookPagination from "../table/TableWithDataHookPagination";
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import DownloadFileIcon from "../icons/DownloadFileIcon";
import {apiUrlInternal} from "../../../config/api";
import {useSearchIntelResultsDictPagination} from "../../../queries/SearchIntelResultsDictPagination";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import {isUserInternal} from "../../helpers/permissions";


const tableConfig = {
  withPagination: true,
  isSortable: false
}

export const INTELX_TABLE_COLUMNS = [
  {Header: 'Date', accessor: 'date', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
  {Header: 'Name', accessor: 'name', Cell: ({value, row}) => row.values.url === value ? '' : value},
  {Header: 'Url', accessor: 'url'},
  {Header: 'Similarity', accessor: 'xscore'},
  {Header: 'File Type', accessor: 'mediah'},
  {Header: 'Size', accessor: 'size', Cell: ({value}) => bytesToSizeOrNA(value)},
]


const IntelxStatsTabsResult = ({stats, keyword}) => {
  const [activeTab, setActiveTab] = useState(0);
  const {data} = useCurrentUserQuery();
  const intelxSources = _.pickBy(stats, (v) => v > 0);
  const [activeIntelxSource, setActiveIntelxSourceType] = useState(_.keys(intelxSources)[0]);

  const toggleTab = useCallback((tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setActiveIntelxSourceType(_.keys(intelxSources)[tab]);
    }
  }, [intelxSources, activeTab])

  return (
    <div className="tabs tabs--bordered-bottom">
      <div className="tabs__wrap">
        <Nav tabs>
          {
            _.toPairs(intelxSources).map(([intelxSource, count], index) =>
              <NavItem key={intelxSource}>
                <NavLink
                  className={classnames({active: activeTab === index})}
                  onClick={() => toggleTab(index)}
                  key={intelxSource}
                >{intelxSource} ({count})</NavLink>
              </NavItem>
            )
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          {
            _.keys(intelxSources).map((intelxSource, index) =>
              <TabPane tabId={index} key={index}>
                <TableWithDataHookPagination
                  tableColumns={
                    isUserInternal(data.data) ? INTELX_TABLE_COLUMNS :
                      INTELX_TABLE_COLUMNS.concat(
                        {
                          Header: '', accessor: 'systemid', disableSort: true, Cell: ({value, row}) =>
                            <DownloadFileIcon
                              url={`${apiUrlInternal}/intel/download?uuid=${value}&source=${row.original.bucket}`}
                              filename={value}
                              timeout={60000}
                            />
                        }
                      )
                  }
                  tableConfig={tableConfig}
                  useDataQuery={useSearchIntelResultsDictPagination}
                  queryParams={{"source": activeIntelxSource, "keyword": keyword}}
                  totalItems={intelxSources[activeIntelxSource]}
                />
              </TabPane>
            )
          }
        </TabContent>
      </div>
    </div>
  );
}


IntelxStatsTabsResult.propTypes = {
  stats: PropTypes.object.isRequired,
  keyword: PropTypes.string.isRequired
}


export default IntelxStatsTabsResult;
