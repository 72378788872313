import React from "react";
import PropTypes from "prop-types";
import CredentialPasswordWordCloud from "../../../shared/components/darkweb/CredentialPasswordWordCloud";
import {Badge, Card, CardBody, Col, Row} from "reactstrap";
import CredentialTable from "../../../shared/components/darkweb/CredentialTable";
import _ from "lodash";
import moment from "moment";


const CredentialExposureResults = ({credentials}) =>
  <Row>
    <Col>
      <Card className={'h-auto'}>
        <CardBody>
          <Row>
            <Col md={12}>
              <Badge color={'danger'} className={'w-100 outline badge-lg'}>
                {
                  credentials.length === 1 ? `THERE IS 1 COMPROMISED CREDENTIAL` :
                    `THERE ARE ${credentials.length} COMPROMISED CREDENTIALS`
                }
              </Badge>
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col md={12}>
              <CredentialPasswordWordCloud credentials={credentials} />
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col md={12}>
              <div className="card__title">
                <h4 className={'bold-text d-inline'}>CREDENTIALS</h4>
              </div>

              <CredentialTable credentials={
                _.sortBy(credentials, c => c.dumpdate).reverse()
              } />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>


CredentialExposureResults.propTypes = {
  credentials: PropTypes.array.isRequired
}

export default CredentialExposureResults;
