import LoadingSpinner from '../../../../shared/components/LoadingSpinner';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import React from 'react';
import IntelxTabsResult from '../../../../shared/components/tab/IntelxTabsResult';
import PropTypes from 'prop-types';
import { useIntelResultsCount } from '../../../../queries/IdentityMonitorResults';
import { intelSectBuckets } from '../../../../shared/helpers/intelligence';

const IntelSect = ({ project_uid, project_name }) => {
  const {
    data: leakData,
    isIdle: leakIsIdle,
    isLoading: leakIsLoading,
    isFetching: leakIsFetching,
    isError: leakIsError
  } = useIntelResultsCount(project_uid, intelSectBuckets.leaks);
  const {
    data: dumpsterData,
    isIdle: dumpsterIsIdle,
    isLoading: dumpsterIsLoading,
    isFetching: dumpsterIsFetching,
    isError: dumpsterIsError
  } = useIntelResultsCount(project_uid, intelSectBuckets.dumpster);
  const {
    data: pastesData,
    isIdle: pastesIsIdle,
    isLoading: pastesIsLoading,
    isFetching: pastesIsFetching,
    isError: pastesIsError
  } = useIntelResultsCount(project_uid, intelSectBuckets.pastes);
  const {
    data: darknetData,
    isIdle: darknetIsIdle,
    isLoading: darknetIsLoading,
    isFetching: darknetIsFetching,
    isError: darknetIsError
  } = useIntelResultsCount(project_uid, intelSectBuckets.darknet);

  if (leakIsIdle || leakIsLoading || leakIsFetching || dumpsterIsIdle || dumpsterIsLoading || dumpsterIsFetching ||
    pastesIsIdle || pastesIsLoading || pastesIsFetching || darknetIsIdle || darknetIsLoading || darknetIsFetching) {
    return <LoadingSpinner/>;
  }

  if (leakIsError || dumpsterIsError || pastesIsError || darknetIsError) {
    return <Card><CardBody><h4>Unable to load the content</h4></CardBody></Card>;
  }
  const leakCount = leakData?.data.count;
  const dumpsterCount = dumpsterData?.data.count;
  const pastesCount = pastesData?.data.count;
  const darknetCount = darknetData?.data.count;
  const total = leakCount + dumpsterCount + pastesCount + darknetCount;

  if (total === 0) {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Badge color={'success'} className={'w-100 outline badge-lg'}>
                NO FINDING FOUND RELATED TO THE MONITORING KEYWORDS
              </Badge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <Badge color={'danger'} className={'w-100 outline badge-lg'}>
              {total === 1 ? `THERE IS 1 EVENT FOUND` : `THERE ARE ${total} EVENTS FOUND`}
            </Badge>
          </Col>
        </Row>

        <Row className={'mt-3'}>
          <Col>
            <IntelxTabsResult
              leaks={leakCount}
              dumpster={dumpsterCount}
              pastes={pastesCount}
              darknet={darknetCount}
              project_uid={project_uid}
              project_name={project_name}
            />
          </Col>
        </Row>

      </CardBody>
    </Card>
  );
};

IntelSect.propTypes = {
  project_uid: PropTypes.string.isRequired,
  project_name: PropTypes.string.isRequired,
};

export default IntelSect;
