import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const UpdateProjectKeywordsFetcher = (accessToken, projectUid, params) => {
  return axios.put(
    `${apiUrlInternal}/keyword_projects/${projectUid}/keywords`,
    params,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useUpdateProjectKeywords = (fetcher = UpdateProjectKeywordsFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-organization-keywords'],
    (accessToken, {params, projectUid}) => fetcher(accessToken, projectUid, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('update-organization-keywords');
        queryClient.invalidateQueries('users-me');
      }
    }
  )
}
