import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import ActorDetailCard from "../../shared/components/card/ActorDetailCard";
import {useSectorsListQuery} from "../../queries/SectorsList";
import {useMitreTTPSQuery} from "../../queries/MitreTTPS";
import {getActorListFilterMenu} from "../../shared/helpers/filter_menu";
import {getActorListOrderMenu} from "../../shared/helpers/order_menu";
import SearchMenu from "../../shared/components/form/SearchMenu";
import {useActorDetailsQuery} from "../../queries/Actor";
import {paramsToObject, useQueryParams} from "../../shared/components/router/QueryNavigationHelpers";
import FabActorMenu from "./components/FabActorMenu";
import {canUserManageActor} from "../../shared/helpers/permissions";
import {useHistory} from "react-router-dom";
import paths from "../../config/paths";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {PLATFORM_FEATURE_ACTORS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";

const _ = require('lodash');


const ActorsList = () => {
  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();
  const {data, isIdle, isLoading, isError, error} = useActorDetailsQuery();
  const params = paramsToObject(useQueryParams().entries());
  const history = useHistory();

  const {
    data: dataSector,
    isIdle: isIdleSectors,
    isLoading: isLoadingSectors,
    isError: isErrorSectors
  } = useSectorsListQuery();
  const {
    data: dataMitre,
    isIdle: isIdleMitre,
    isLoading: isLoadingMitre,
    isError: isErrorMitre
  } = useMitreTTPSQuery();
  const [actorsDisplay, setActorsDisplay] = useState([]);
  const [filters, setFilters] = useState({});

  const isLoadingIdleError = isIdleMitre || isLoadingMitre || isErrorMitre || isIdleSectors || isLoadingSectors
    || isErrorSectors || isIdle || isLoading || isError;

  const ORDER_MENU = getActorListOrderMenu();
  const FILTER_MENU = useMemo(
    () => (!isLoadingIdleError) ? getActorListFilterMenu(data.data, dataMitre, dataSector) : [],
    [isLoadingIdleError]
  )
  const SEARCH_FIELD = 'name';
  const searchPredicate = useCallback((searchTerm, items) => _.filter(items, (item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    _.join(item.aliases, ', ').toLowerCase().includes(searchTerm.toLowerCase())
  ), [])

  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setActorsDisplay(data.data);
    }
  }, [isIdle, isLoading, isError]);

  useEffect(() => {
    const searchTerm = filters[SEARCH_FIELD];
    const tmpFilters = _.omit(filters, [SEARCH_FIELD]);
    let tmpItemsDisplay = data ? data.data : [];
    tmpItemsDisplay = searchTerm ? searchPredicate(searchTerm, tmpItemsDisplay) : tmpItemsDisplay;
    _.forEach(tmpFilters, (value, field) => {
      if (field === 'order_by') {
        tmpItemsDisplay = _.sortBy(tmpItemsDisplay, (item) => item[value]);
        return;
      }

      const filter = _.find(FILTER_MENU, (item) => item.field === field);
      if (Array.isArray(value)) {
        _.forEach(value, (v) => {
          tmpItemsDisplay = _.filter(tmpItemsDisplay.slice(), (item) => filter.filterPredicate(item, field, v));
        })
      } else {
        tmpItemsDisplay = _.filter(tmpItemsDisplay.slice(), (item) => filter.filterPredicate(item, field, value));
      }
    });

    setActorsDisplay(tmpItemsDisplay);
  }, [filters])

 
  if (isLoadingCurrentUser || isIdleCurrentUser || isLoading || isLoadingMitre || isLoadingSectors) {
    return <LoadingSpinnerPage/>
  }

  if (isError || isErrorCurrentUser) {
    return <ErrorHandler error={error}/>
  }

  const userFilters = (data) => {
    const urlParams = new URLSearchParams();
    Object.entries(data).forEach(([filter_field, value])=>{
      if (Array.isArray(value)) {
        value.forEach((v) => urlParams.append(filter_field, v));
      } else {
        urlParams.set(filter_field, value)
      }
    });

    setFilters(data);
  }


  return (
    <Page
      feature={PLATFORM_FEATURE_ACTORS}
    >
      <Container>
        <div className={'div__sticky-top'}>
          <SearchMenu
            setFilters={userFilters}
            baseUrl={paths.actorPath}
            defaultFilters={params}
            searchPlaceholder={'Type an actor name or an alias...'}
            orderMenu={ORDER_MENU}
            filterMenu={FILTER_MENU}
            searchField={SEARCH_FIELD}
          />
        </div>

        <Row className={'mt-3'}>
          <Col md={12}>
            <h3 className="page-title">{actorsDisplay.length} results</h3>
          </Col>
        </Row>

        <Row className="actors-wrapper">
          {
            actorsDisplay.map((actor) => (
              <Col lg={3} md={6} sm={6} xs={6} key={actor.uid}>
                <ActorDetailCard
                  actor={actor}
                  withLink
                  withTruncateText
                  withDecoration
                  key={actor.uid}
                  withEditableButton={canUserManageActor(dataCurrentUser?.data)}
                />
              </Col>
            ))
          }
        </Row>
        {canUserManageActor(dataCurrentUser?.data) && <FabActorMenu />}
      </Container>
    </Page>
  )
}

export default ActorsList;
