import React from "react";
import MalwareFamilyForm from "../../shared/components/form/malware-family-form/MalwareFamilyForm";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";

const MalwareFamilyAdd = () => (
  <Page
    feature={PLATFORM_FEATURE_CONTENTS}
    permission={PLATFORM_PERMISSION_EDITOR}
    onlyOnline
    unavailableOnTenant
  >
    <MalwareFamilyForm/>
  </Page>
)

export default MalwareFamilyAdd;
