import {Card, CardBody} from "reactstrap";
import {Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import React from "react";
import PropTypes from "prop-types";
import {renderLegendPierChart, stylePieChart} from "./index";


const EmptyPieChart = ({
  pieChartConfig,
  valueLabel
}) => {
  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">Malware families</h4>
        </div>
        <ResponsiveContainer className={pieChartConfig.containerClass} height={pieChartConfig.height}>
          <PieChart>
            <Pie
              data={[{name: valueLabel, value: 100, fill: '#fff'}]}
              dataKey="value"
              cy={pieChartConfig.cy}
              innerRadius={pieChartConfig.innerRadius}
              outerRadius={pieChartConfig.outerRadius}
              isAnimationActive={false}
            />
            <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={stylePieChart()} content={renderLegendPierChart} />
          </PieChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  )
}

EmptyPieChart.propTypes = {
  pieChartConfig: PropTypes.shape().isRequired,
  valueLabel: PropTypes.string.isRequired
};

export default EmptyPieChart;
