import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";
import {addAuthToFetchOpts} from "../config/queryopts";

export const AddOrganizationTaxiiAccountFetcher = (accessToken, organizationUid, data) => {
  return axios.post(`${apiUrlInternal}/organizations/${organizationUid}/taxii_user`,
    data,
    addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useAddOrganizationTaxiiAccountMutation = (fetcher = AddOrganizationTaxiiAccountFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-organization-taxii-account'],
    (accessToken, {organizationUid, password}) => fetcher(accessToken, organizationUid, {password: password}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('add-organization-taxii-account')
      }
    }
  )
}
