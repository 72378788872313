import React from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import {useMitreTTPSQuery} from "../../../queries/MitreTTPS";
import LoadingSpinner from "../LoadingSpinner";


const _ = require('lodash')


const getMitreColumns = (techniques, mitreTable) => {
  const maxColumns = _.max(mitreTable.map((tactic) => tactic.techniques.length));
  const maxRows = mitreTable.length;
  const techniqueTable = _.range(maxColumns).map(() => _.range(maxRows).map(() => ""));
  _.forEach(mitreTable, (tactic, index) => {
    _.forEach(techniques, (t) => {
      _.forEach(tactic.techniques, (technique) => {
        if (t === technique.uid || t.uid === technique.uid) {
          for (let i = 0; i < maxColumns; ++i) {
            if (techniqueTable[i][index] === "") {
              techniqueTable[i][index] = technique.name + '&&' + technique.uid;
              if (t.count) techniqueTable[i][index] += '&&' + t.count
              break;
            }
          }
        }
      })
    })
  });

  for (let i = maxColumns - 1; i > 0; --i) {
    if (_.filter(techniqueTable[i], (tech) => tech === "").length === maxRows) {
      techniqueTable.splice(i, 1);
    }
  }

  return techniqueTable;
}


const MitreFrameworkCard = ({techniques, updateFilters, count, minimalMitre, section="Commands"}) => {
  const {data, isIdle, isLoading, error} = useMitreTTPSQuery();
  if (isIdle || isLoading) {
    return <Card><CardBody><LoadingSpinner/></CardBody></Card>;
  }

  if (error) {
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4>Something went wrong getting the Mitre Framework</h4>
          </div>
        </CardBody>
      </Card>
    )
  }
  let mitreTable = data.data;
  const minimalMitreTable = mitreTable.filter((tactic) => (tactic.techniques.filter((tech) => (techniques.find((t) => (t.uid === tech.uid || t === tech.uid)))).length))
  if (minimalMitre) mitreTable = minimalMitreTable
  const techniqueTable = getMitreColumns(techniques, mitreTable);
  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text'}>Mitre Framework</h4>
        </div>
        <div className={'mitre__wrapper'}>
          <div className={'mitre__table_row'}>
            {
              mitreTable.map((tactic) =>
                <div className={'mitre__tactic-item-legend cursor'} key={tactic.uid}>
                  <p key={tactic.uid}>{tactic.name}
                  </p>
                </div>
              )
            }
          </div>
          {
            techniqueTable.map((row, index_row) => (
              <div className={'mitre__table_row'} key={index_row}>
                {
                  row.map((column, index_column) =>
                    column === "" ? <div className={'mitre__tactic-item-empty'} key={index_column}/> :
                      <div onClick={() => updateFilters({'type': 'techniques', 'id': column.split('&&')[1]})}
                           className={'mitre__tactic-item cursor'} key={index_column}>
                        <p>{column.split('&&')[0]}</p>
                        {count && <p> Associated {section}: {column.split('&&')[2]}</p>}
                      </div>
                  )
                }
              </div>
            ))
          }
        </div>
      </CardBody>
    </Card>
  )
}

MitreFrameworkCard.propTypes = {
  techniques: PropTypes.array.isRequired,
  updateFilters: PropTypes.func.isRequired,
  section: PropTypes.string,
}

export default MitreFrameworkCard;
