import React from "react";
import GenericResponse from "../components/GenericResponse";
import Page from "../../../shared/components/Page";

export const NotFound404 = () => {
  return (
    <Page>
      <GenericResponse statusCode={404} message={'The page you are looking for was not found.'} />
    </Page>
  )
}

export default NotFound404;
