import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const UpdateProjectResourceMonitorsFetcher = (accessToken, projectUid, params) => {
  return axios.put(
    `${apiUrlInternal}/resource_monitor_projects/${projectUid}/resource_monitors`,
    params,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useUpdateProjectResourceMonitors = (fetcher = UpdateProjectResourceMonitorsFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-organization-resource-monitors'],
    (accessToken, {params, projectUid}) => fetcher(accessToken, projectUid, params),
    {onSuccess: () => queryClient.invalidateQueries('update-organization-resource-monitors')}
  )
}
