import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {download_file} from '../../helpers/download_file';
import {useCookieAccessToken} from '../../../queries';
import {useCurrentUserQuery} from '../../../queries/CurrentUser';

const bubbleClass = (active) => {
  return classNames({
    chat__bubble: true,
    'chat__bubble--active': active,
  });
};

const ChatBubble = ({message, active, apiPath,canUploadFile}) => {
  const {data: accessToken} = useCookieAccessToken();
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const msgClass = dataCurrentUser?.data?.organization?.uid !== message?.from_user?.organization?.uid ?
    'left' :
    'right';
  return (
    <div className={bubbleClass(active) +' '+ msgClass}>
      <div className='chat__bubble-message-wrap mt-2 '>
        {message.from_user && <p className="chat__bubble-contact-name">{message.from_user.email}</p>}
        <p className="chat__bubble-message">{message.content}</p>
        {canUploadFile && message.attachment &&
          <span className='attachmentLink' onClick={(e) => {
            e.stopPropagation();
            download_file(apiPath + message.attachment.uid, message.attachment.filename, accessToken)
          }}>Download {message.attachment.filename}</span>
        }
        <p className="chat__bubble-date">{moment(message.created_dt).format('LT')}</p>
      </div>
    </div>
  );

};


ChatBubble.defaultProps = {
  active: false,
  apiPath: '',
  canUploadFile: true
};

export default ChatBubble;
