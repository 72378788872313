import React from "react"
import {Card, CardBody} from "reactstrap";
import TimeLineItem from "../../../shared/components/TimeLineItem";
import PropTypes from "prop-types";


const TakeDownStatusHistory = ({events}) =>
  <Card className={'h-auto center'}>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>Ticket Status History</h4>
      </div>
      <div className="timeline">
        {events && events.length > 0 && events.map((event) => (
          <TimeLineItem
            title={event.title}
            date={event.date}
            icon={event.letter}
          />
        ))
        }
      </div>
    </CardBody>
  </Card>


TakeDownStatusHistory.propTypes = {
  events: PropTypes.shape().isRequired
}


export default TakeDownStatusHistory;
