import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Col, Row} from "reactstrap";
import MenuEntry from "../../../../../../shared/components/MenuEntry";
import {formatDateUTCtoYearMonthDay} from "../../../../../../shared/helpers/date";


const _ = require('lodash');


const BtcDetailsCard = ({btcaddress_info}) => {
  if (_.isNull(btcaddress_info.balance) && _.isNull(btcaddress_info.total_received) && _.isNull(btcaddress_info.total_sent)) {
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>BTC details</h4>
            {btcaddress_info.report_first_seen && <p className="subhead">First report date on {formatDateUTCtoYearMonthDay(btcaddress_info.report_first_seen)}</p>}
            {btcaddress_info.report_last_seen && <p className="subhead">Last report date on {formatDateUTCtoYearMonthDay(btcaddress_info.report_last_seen)}</p>}
          </div>
          <Row>
            <Col>
              <p>No more information to show about this wallet.</p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card className={'h-auto'}>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>BTC details</h4>
          {btcaddress_info.report_first_seen &&
            <p className="subhead">
              First report on {formatDateUTCtoYearMonthDay(btcaddress_info.report_first_seen)}, last on {formatDateUTCtoYearMonthDay(btcaddress_info.report_last_seen)}
            </p>
          }
        </div>

        <Row>
          <Col>
            <Row>
              <Col md={7}>
                <div className="custom_card__container">
                  <MenuEntry title={'CURRENT BALANCE'} value={`${btcaddress_info.balance} BTC`} />
                  <MenuEntry title={'TOTAL BTC RECEIVED'} value={`${btcaddress_info.total_received} BTC`} />
                  <MenuEntry title={'TOTAL BTC SEND'} value={`${btcaddress_info.total_sent} BTC`} />
                </div>
              </Col>
              <Col md={5}>
                <div className="custom_card__container">
                  <MenuEntry title={'CONFIRMED TRANSACTION'} value={btcaddress_info.transaction_number} />
                  <MenuEntry title={'NUMBER OF REPORTS'} value={btcaddress_info.report_count} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

      </CardBody>
    </Card>
  )
}


BtcDetailsCard.propTypes = {
  btcaddress_info: PropTypes.object.isRequired,
}


export default BtcDetailsCard;
