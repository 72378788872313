import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


const ActorFetcher = (accessToken, endpoint) => {
  return axios.get(`${apiUrlInternal}/actors${endpoint}`, addAuthToAxiosOpts(accessToken, {}))
    .then(res => res.data);
}

const useActorQuery = (endpoint = null, keys = [], options = {}, fetcher = ActorFetcher) => {
  return useQueryWithAuthentication(['actor'].concat(keys),(accessToken) => fetcher(accessToken, endpoint), options)
}

export const useActorDetailsQuery = (uid = null) => {
  const endpoint = (uid) ? `/${uid}` : '';
  return useActorQuery(endpoint, ['detail', uid], {refetchOnWindowFocus: false});
}


export const useActorTTPsQuery = (uid) => {
  const endpoint = `/${uid}/ttps`;
  return useActorQuery(endpoint, ['ttps', uid], {refetchOnWindowFocus: false});
}


export const useActorContentQuery = (uid = null) => {
  const endpoint = `/${uid ? uid : ''}/contents`;
  return useActorQuery(endpoint, ['contents', uid]);
}

export const useActorIndicatorsByTypeQuery = (uid, ioc_type, page = null, limit = null) => {
  const urlParams = new URLSearchParams();
  if (page !== null) {
    urlParams.set('page', page);
  }
  if (limit) {
    urlParams.set('limit', limit);
  }
  if (ioc_type) {
    urlParams.set('ioc_type', ioc_type);
  }

  const endpoint = `/${uid}/indicators?${urlParams}`;
  return useActorQuery(endpoint, ['indicators', uid, ioc_type, page, limit], {staleTime: Infinity});
}

export const useActorIndicatorsCountQuery = (uid) => {
  const endpoint = `/${uid}/indicators/count`;
  return useActorQuery(endpoint, ['indicators', 'count', uid], {staleTime: Infinity});
}

export const useActorCommandsQuery = (uid) => {
  const endpoint = `/${uid}/commands`;
  return useActorQuery(endpoint, {staleTime: Infinity});
}