import moment from "moment";
import {getFormattedAnalysisName} from "./contents";
import {getCountryNameOrCodeShort} from "./country-data";
import {getBotnetTags, getCampaignTags, getCommonTags, getMalwareFamilyTags} from "./index";
import {activityCategories} from "./activity";


const _ = require('lodash');


const TODAY_DAYS = 1;
const LAST_WEEK_DAYS = 7;
const LAST_MONTH_DAYS = 30;

export const getFeedlyFeedsListFilterMenu = (dataAuthors) => {

    return [
        {
            displayField: 'AUTHOR',
            field: 'source_title',
            multi: true,
            values: dataAuthors.data.map((author) => author.name),
            displayValueFn: (author_name) => _.find(dataAuthors.data, (author) => author.name === author_name)?.name
        },
    ]

}

export const getRansomwareTrackerEventsListFilterMenu = (dataActors, dataCountries) => {

    return [
        {
            displayField: 'ACTOR',
            field: 'actor_name',
            multi: true,
            values: dataActors.data.map((actor) => actor.name),
            displayValueFn: (actor_name) => _.find(dataActors.data, (actor) => actor.name === actor_name)?.name
        },
        {
            displayField: 'VICTIM COUNTRY',
            field: 'victim_country',
            multi: true,
            values: dataCountries.data.map((country) => country.name),
            displayValueFn: (country_name) => _.find(dataCountries.data, (country) => country.name === country_name)?.name
        },
    ]

}
export const getActorListFilterMenu = (actors, dataMitre, dataSector) => {
    const techs = _.uniqBy(_.flatten(dataMitre.data.map((tactic) => tactic.techniques)), 'name');
    return [
        {
            displayField: 'ORIGIN COUNTRY',
            field: 'origin_country',
            values: _.sortedUniq(actors.map((actor) => actor.origin_country).sort()),
            displayValueFn: (v) => getCountryNameOrCodeShort(v),
            filterPredicate: (item, field, value) => item[field] === value || item[field]?.includes(value)
        },
        {
            displayField: 'ASSOCIATED MALWARE',
            field: 'associated_malwares',
            multi: true,
            values: _.sortedUniq(_.flatten(_.concat(actors.map((actor) => actor.associated_malwares))).sort()),
            displayValueFn: (v) => v,
            filterPredicate: (item, field, value) => item[field] === value || item[field]?.includes(value)
        },
        {
            displayField: 'TARGETED COUNTRY',
            field: 'targeted_countries',
            multi: true,
            values: _.sortedUniq(_.flatten(_.concat(actors.map((actor) => actor.targeted_countries))).sort()),
            displayValueFn: (v) => getCountryNameOrCodeShort(v),
            filterPredicate: (item, field, value) => item[field] === value || item[field]?.includes(value)
        },
        {
            displayField: 'TARGETED SECTOR',
            field: 'targeted_sectors',
            multi: true,
            values: _.sortedUniq(_.flatten(_.concat(actors.map((actor) => actor.targeted_sectors))).sort()),
            displayValueFn: (sectorUid) => _.find(dataSector.data, (sector) => sector.uid === sectorUid)?.name,
            filterPredicate: (item, field, value) => item[field] === value || item[field]?.includes(value)
        },
        {
            displayField: 'TECHNIQUES',
            field: 'techniques',
            multi: true,
            values: _.sortedUniq(_.flatten(_.concat(actors.map((actor) => actor.techniques))).sort()),
            displayValueFn: (ttpUid) => `(${_.find(techs, (ttp) => ttp.uid === ttpUid)?.mitre_code}) - 
      ${_.find(techs, (ttp) => ttp.uid === ttpUid)?.name}`,
            filterPredicate: (item, field, value) => item[field] === value || item[field]?.includes(value)
        },
        {
            displayField: 'TACTICS',
            field: 'tactics',
            multi: true,
            values: _.sortedUniq(_.flatten(_.concat(dataMitre.data.map((t) => t.uid))).sort()),
            displayValueFn: (ttpUid) => `${_.find(dataMitre.data, (ttp) => ttp.uid === ttpUid)?.name}`,
            filterPredicate: (item, field, value) => {
                let tacTechs = _.find(dataMitre.data, (ttp) => ttp.uid === value).techniques.map((t) => t.uid);
                return item['techniques'].some((r) => tacTechs.indexOf(r) >= 0)
            }
        },
        {
            displayField: 'MOTIVATION',
            field: 'motivations',
            multi: true,
            values: _.sortedUniq(_.flatten(_.concat(actors.map((actor) => actor.motivations))).sort()),
            displayValueFn: (v) => v,
            filterPredicate: (item, field, value) => item[field]?.includes(value)
        },
        {
            displayField: 'LAST SEEN',
            field: 'last_seen',
            values: [
                moment().subtract(TODAY_DAYS, 'days'),
                moment().subtract(LAST_WEEK_DAYS, 'days'),
                moment().subtract(LAST_MONTH_DAYS, 'days')
            ],
            displayValueFn: (v) => {
                switch (moment().diff(v, 'days')) {
                    case TODAY_DAYS:
                        return 'Today';
                    case LAST_WEEK_DAYS:
                        return 'Last week';
                    case LAST_MONTH_DAYS:
                        return 'Last month';
                }
            },
            filterPredicate: (item, field, value) => moment(item[field]) > value,
        }
    ]
}

export const getIndicatorsFilterMenu = (dataTags, dataActors, dataTypes = []) => [
    {
        displayField: 'TYPE',
        field: 'types',
        values: dataTypes.data.sort(),
        displayValueFn: (v) => v,
        multi: true
    },
    {
        displayField: 'TAG',
        field: 'tags',
        values: getCommonTags(dataTags.data).map((tag) => tag.name),
        displayValueFn: (v) => v,
        multi: true
    },
    {
        displayField: 'MALWARE FAMILY',
        field: 'malware_family',
        filter_field: 'tags',
        values: getMalwareFamilyTags(dataTags.data).map((tag) => tag.name),
        displayValueFn: (v) => v,
        multi: true
    },
    {
        displayField: 'BOTNET',
        field: 'botnet',
        filter_field: 'tags',
        values: getBotnetTags(dataTags.data).map((tag) => tag.name),
        displayValueFn: (v) => v,
        multi: true
    },
    {
        displayField: 'CAMPAIGN',
        field: 'campaign',
        filter_field: 'tags',
        values: getCampaignTags(dataTags.data).map((tag) => tag.name),
        displayValueFn: (v) => v,
        multi: true
    },
    {
        displayField: 'ACTOR',
        field: 'actor',
        values: dataActors.data.map((actor) => actor.uid),
        displayValueFn: (actorUid) => _.find(dataActors.data, (actor) => actor.uid === actorUid)?.name
    }
]

export const getEventContentsFilterMenu = (dataTags, dataActors, dataSectors, dataCountries, tlpLevel = 0, dataMitre) => {
    const techs = _.uniqBy(_.flatten(dataMitre.data.map((tactic) => tactic.techniques)), 'name');
    return [
        {
            displayField: 'TAG',
            field: 'tags',
            values: getCommonTags(dataTags.data).map((tag) => tag.name),
            displayValueFn: (v) => v,
            multi: true
        },
        {
            displayField: 'MALWARE FAMILY',
            field: 'malware_family',
            filter_field: 'tags',
            values: getMalwareFamilyTags(dataTags.data).map((tag) => tag.name),
            displayValueFn: (v) => v,
            multi: true
        },
        {
            displayField: 'BOTNET',
            field: 'botnet',
            filter_field: 'tags',
            values: getBotnetTags(dataTags.data).map((tag) => tag.name),
            displayValueFn: (v) => v,
            multi: true
        },
        {
            displayField: 'CAMPAIGN',
            field: 'campaign',
            filter_field: 'tags',
            values: getCampaignTags(dataTags.data).map((tag) => tag.name),
            displayValueFn: (v) => v,
            multi: true
        },
        {
            displayField: 'ACTOR',
            field: 'actor',
            values: dataActors.data.map((actor) => actor.uid),
            displayValueFn: (actorUid) => _.find(dataActors.data, (actor) => actor.uid === actorUid)?.name
        },
        {
            displayField: 'TARGETED SECTOR',
            field: 'targeted_sectors',
            multi: true,
            values: dataSectors.data.map((sector) => sector.uid),
            displayValueFn: (sectorUid) => _.find(dataSectors.data, (sector) => sector.uid === sectorUid)?.name
        },
        {
            displayField: 'TARGETED COUNTRY',
            field: 'targeted_countries',
            multi: true,
            values: dataCountries.data.map((country) => country.iso_code),
            displayValueFn: (countryISO) => _.find(dataCountries.data, (country) => country.iso_code === countryISO)?.name
        },
        {
            displayField: 'ACTOR ORIGIN COUNTRY',
            field: 'origin_countries',
            multi: true,
            values: dataCountries.data.map((country) => country.iso_code),
            displayValueFn: (countryISO) => _.find(dataCountries.data, (country) => country.iso_code === countryISO)?.name
        },
        {
            displayField: 'TECHNIQUES',
            field: 'techniques',
            multi: true,
            values: _.sortedUniq(_.flatten(_.concat(dataActors.data.map((actor) => actor.techniques))).sort()),
            displayValueFn: (ttpUid) => `(${_.find(techs, (ttp) => ttp.uid === ttpUid)?.mitre_code}) - 
    ${_.find(techs, (ttp) => ttp.uid === ttpUid)?.name}`,
            filterPredicate: (item, field, value) => item[field] === value || item[field]?.includes(value)
        },
        {
            displayField: 'TACTICS',
            field: 'tactics',
            multi: true,
            values: _.sortedUniq(_.flatten(_.concat(dataMitre.data.map((t) => t.uid))).sort()),
            displayValueFn: (ttpUid) => `${_.find(dataMitre.data, (ttp) => ttp.uid === ttpUid)?.name}`,
            filterPredicate: (item, field, value) => {
                let tacTechs = _.find(dataMitre.data, (ttp) => ttp.uid === value).techniques.map((t) => t.uid);
                return item['techniques'].some((r) => tacTechs.indexOf(r) >= 0)
            }
        },
        {
            displayField: 'TLP',
            field: 'tlps',
            multi: true,
            values: _.range(tlpLevel + 1).map((tlp) => _.toString(tlp)),
            displayValueFn: (tlp) => {
                switch (tlp) {
                    case '0':
                        return 'WHITE';
                    case '1':
                        return 'GREEN';
                    case '2':
                        return 'AMBER';
                    case '3':
                        return 'RED';
                }
            }
        },
        {
            displayField: 'TYPE',
            field: 'content_type',
            values: ['post', 'tir', 'tar', 'flash_alert'],
            displayValueFn: (v) => v === 'flash_alert' ? getFormattedAnalysisName(v).toUpperCase() : v.replaceAll('_', ' ').toUpperCase(),
        }
    ]
}


export const getActivitiesFilterMenu = (dataTags, dataActors, dataSectors, dataCountries, tlpLevel = 0) => {
    return [
        {
            displayField: 'TAG',
            field: 'tags',
            values: getCommonTags(dataTags.data).map((tag) => tag.name),
            displayValueFn: (v) => v,
            multi: true
        },
        {
            displayField: 'ACTOR',
            field: 'actor',
            values: dataActors.data.map((actor) => actor.uid),
            displayValueFn: (actorUid) => _.find(dataActors.data, (actor) => actor.uid === actorUid)?.name
        },
        {
            displayField: 'CATEGORY',
            field: 'category',
            values:  activityCategories,
            displayValueFn: (v) => v
        },
        {
            displayField: 'TARGETED SECTOR',
            field: 'targeted_sectors',
            multi: true,
            values: dataSectors.data.map((sector) => sector.uid),
            displayValueFn: (sectorUid) => _.find(dataSectors.data, (sector) => sector.uid === sectorUid)?.name
        },
        {
            displayField: 'TARGETED COUNTRY',
            field: 'targeted_countries',
            multi: true,
            values: dataCountries.data.map((country) => country.iso_code),
            displayValueFn: (countryISO) => _.find(dataCountries.data, (country) => country.iso_code === countryISO)?.name
        },
        {
            displayField: 'TLP',
            field: 'tlps',
            multi: true,
            values: _.range(tlpLevel + 1).map((tlp) => _.toString(tlp)),
            displayValueFn: (tlp) => {
                switch (tlp) {
                    case '0':
                        return 'WHITE';
                    case '1':
                        return 'GREEN';
                    case '2':
                        return 'AMBER';
                    case '3':
                        return 'RED';
                }
            }
        }
    ]
}