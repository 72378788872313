import React, {useState} from 'react';
import PropTypes from "prop-types";
import TableWithDataHookPagination from "../../../../../shared/components/table/TableWithDataHookPagination";
import {useDarknetResults} from "../../../../../queries/IdentityMonitorResults";
import {tableColumns as tableColumnsBots} from "../../../../../shared/components/darkweb/DarksearchBotsTable";
import {getFilterField} from "../../../../../shared/helpers/intelligence";
import {tableColumns as tableColumnsThreads} from "../../../../../shared/components/darkweb/ThreadsTable";
import {tableColumns as tableColumnsSellers} from "../../../../../shared/components/darkweb/SellersTable";

const DarknetTableWithPagination = ({project_uid, type, totalItems, extraColumnsAfter = [], extraColumnsBefore = []}, customSort = false) =>
{
  const [orderByFilter, setOrderByFilter] = useState("")
  const [orderBy, setOrderBy] = useState('')
  const tableConfig = {
    withPagination: true,
    withSearchEngine: false,
    isSortable: !customSort,
  }

  const customOnHeaderClick = (target) => {
    setOrderByFilter(getFilterField(target))
  }

  const customOnHeaderIconClick = (target) => {
    setOrderBy(target)
  }

  let columns = []

  if (type === 'threads') {
    columns = tableColumnsThreads;
  } else if (type === 'sellers') {
    columns = tableColumnsSellers;
  } else if (type === 'bots') {
    columns = tableColumnsBots;
  }

  return (<TableWithDataHookPagination
      tableColumns={extraColumnsBefore.concat(columns.concat(extraColumnsAfter))}
      tableConfig={tableConfig}
      useDataQuery={useDarknetResults}
      queryParams={project_uid}
      type={type}
      orderByFilter={orderByFilter}
      orderBy={orderBy}
      totalItems={totalItems}
      onHeaderClick={customOnHeaderClick}
      onHeaderIconClick={customOnHeaderIconClick}
    />
  )
}



DarknetTableWithPagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  project_uid: PropTypes.string.isRequired,
  extraColumnsAfter: PropTypes.array,
  extraColumnsBefore: PropTypes.array,
  onHeaderClick: PropTypes.func,
  customSort: PropTypes.bool
}


export default DarknetTableWithPagination;
