import React from 'react';
import {Col, Row, Container, Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import {useSandboxTaskDetailsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import FilterableRegistriesTable from "./FilterableRegistriesTable";

const SandboxTaskRegistriesSection = ({taskId}) => {
    const { data, isIdle, isLoading, isError,error } = useSandboxTaskDetailsQuery(taskId);

    if (isLoading || isIdle) {
        return <LoadingSpinner />
    }

    if (isError || !data.data) {
        return <ErrorHandler error={error} />
    }

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <FilterableRegistriesTable taskId={taskId} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

SandboxTaskRegistriesSection.propTypes = {
    taskId: PropTypes.string.isRequired,
}

export default SandboxTaskRegistriesSection;
