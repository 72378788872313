import React from "react";
import PropTypes from "prop-types";


const HighlightSpan = ({text}) =>
  <span className={'span__notification-source'}>{text}</span>


HighlightSpan.propTyeps = {
  text: PropTypes.string.isRequired
}


export default HighlightSpan
