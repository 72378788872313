import _ from "lodash";
import {upperCaseFirstLetter} from "./index";


const EXPLOIT_DB_URL = 'https://www.exploit-db.com/exploits/';

const COLORS = [
  '#b8e986', '#ff9800', '#d50000',
]


export const CVSSV3 = "cvssV3";
export const CVSSV2 = "cvssV2";

export const CVE_RISK_COLORS = {
  "low": "#4ce1b6",
  "medium": "#f6da6e",
  "high": "#ff4861",
  "critical": "#d50000",
}

export const isHighRiskValue = (title, value) =>
  _.isString(value) && (['complete', 'critical'].includes(value.toLowerCase()) || (value.toLowerCase() === 'high' && !title.includes('Complexity'))) ||
  (value.toLowerCase() === 'low' && title.includes('Complexity') || value.toLowerCase() === 'none' && title.includes('Authentication') ||
    (value.toLowerCase() === 'network' && title.includes('Vector') || ((value === 'TRUE' || value.toLowerCase() === 'none') && title.includes('Privilege')) ||
      ((value === 'FALSE' || value.toLowerCase() === 'none') && title.includes('Interaction')) || (value.toLowerCase() === 'changed' && title === 'Scope')))


export const isMediumRiskValue = (title, value) =>
  _.isString(value) && (['partial', 'medium', 'adjacent network'].includes(value.toLowerCase()) || (title.includes('Privilege') && value.toLowerCase() === 'low'))


export const splitCamelCaseTitle = (title) => {
  let t = "";
  let tmpWord = "";

  _.forEach(title, (letter) => {
    if (letter === letter.toLowerCase()) {
      tmpWord += letter;
    } else {
      t += tmpWord + " ";
      tmpWord = letter;
    }
  });

  t += tmpWord;
  return upperCaseFirstLetter(t);
};


export const getColorByScore = (score) => COLORS[_.min([Math.floor(score / (10 / COLORS.length)), COLORS.length - 1])];


export const getBaseScore = (impact) => {
  if (!impact) return [null, null, null,];
  if (impact.baseMetricV3) {
    return [impact.baseMetricV3?.cvssV3?.baseScore, CVSSV3, impact.baseMetricV3?.cvssV3?.baseSeverity];
  }
  if (impact.baseMetricV2) {
    return [impact.baseMetricV2?.cvssV2?.baseScore, CVSSV2, impact.baseMetricV2?.severity];
  } else {
    return [null, null, null,];
  }
}


export const getCveItems = (impact, cvssVersion) => {
  const cvssItems = {}
  if (cvssVersion === CVSSV3) {
    _.forEach(impact.baseMetricV3.cvssV3, (value, key) => cvssItems[key] = value);
    _.forEach(impact.baseMetricV3, (value, key) => {
      if (key !== "cvssV3") {
        cvssItems[key] = value;
      }
    });
  } else if (cvssVersion === CVSSV2) {
    _.forEach(impact.baseMetricV2.cvssV2, (value, key) => cvssItems[key] = value);
    _.forEach(impact.baseMetricV2, (value, key) => {
      if (key !== "cvssV2") {
        cvssItems[key] = _.isBoolean(value) ? _.toUpper(_.toString(value)) : value;
      }
    });
  }

  return cvssItems;
}


export const getCommonReferences = (references) =>
  references.filter((ref) => !ref.url.includes(EXPLOIT_DB_URL))


export const getExploitsReferences = (references) =>
  references.filter((ref) => ref.url.includes(EXPLOIT_DB_URL))


export const hasPublicExploits = (references) =>
  getExploitsReferences(references).length > 0
