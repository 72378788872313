import React, {useState} from 'react';
import PaperclipIcon from 'mdi-react/PaperclipIcon';
import ControlledFilepond from '../../../shared/components/form/ControlledFilepond';
import SendIcon from 'mdi-react/SendIcon';
import PropTypes from "prop-types";


const ChatField = ({onFormSubmit, register, control,canUploadFile}) => {
  const [toggleFile, setToggleFile] = useState(false);

  return (
    <div className="chat__text-field" style={{'height': 'auto'}}>
      <form className="chat__form">
        <textarea
          name={'chatField'}
          placeholder="Type here…"
          className={'textarea--add-contents form-group mb-0'}
          {...register('content')}
        />
        <div className="chat__field-controls">
         {canUploadFile && <button
            type="button"
            className="chat__icon-button"
            onClick={(e) => {
              e.preventDefault();
              toggleFile ? setToggleFile(false) : setToggleFile(true)
            }}
          >
            <PaperclipIcon/>
          </button> }
          <button
            type="button"
            className="chat__icon-button"
            onClick={(e) => {
              e.preventDefault();
              setToggleFile(false);
              // handleSubmit(onSubmit)()
              onFormSubmit();
            }}
          >
            <SendIcon/>
          </button>
        </div>
        {toggleFile && <ControlledFilepond
          control={control}
          name={'report'}
          allowMultiple={false}
          allowRevert={false}
          maxFiles={1}
          instantUpload={false}
          // defaultValue={defaultValues?.url}
          labelIdle={'Drag & Drop your report or <span class="filepond--label-action">Browse</span>'}
        />}
      </form>
    </div>
  );
};


ChatField.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  control: PropTypes.shape().isRequired,
  register: PropTypes.func.isRequired,
  canUploadFile:PropTypes.bool.isRequired
}

export default ChatField;
