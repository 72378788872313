import PropTypes from "prop-types";
import {useIntelxSearchKeywordBySourceQuery} from "./Intelx";


export const useSearchIntelResultsDictPagination = (
  {keyword, source}, limit = null, offset = null
) => {
  return useIntelxSearchKeywordBySourceQuery(keyword, source, offset * limit, limit);
}


useSearchIntelResultsDictPagination.propTypes = {
  uid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  offset: PropTypes.number,
  limit: PropTypes.number,
};
