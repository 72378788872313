import React from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import { useOrganizationsIntelSearchQuery } from "../../../queries/OrganizationIntelSearch";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import TableWithData from "../../../shared/components/table/TableWithData";
import { formatDateUTCtoYearMonthDayTime } from "../../../shared/helpers/date";

const IntelligenceHistory = ({currentUser}) => {

    const {
        data,
        isIdle,
        isLoading,
        isError
    } = useOrganizationsIntelSearchQuery(currentUser.organization.uid);

    const tableConfig = {
      withPagination: true,
      withSearchEngine: true,
    }

    const tableColumns = [
      { Header: 'Email', accessor: (row) => row['created_by']['email']},
      { Header: 'Searched value', accessor:'keyword'},
      { Header: 'Search on', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
    ];

    return (
        <Card>
            <CardBody>
                <div className="card__title">
                    <h4 className={'bold-text d-inline'}>Intelligence History</h4>
                    <p className={'subhead'}>
                        Your currently search plan usage is {currentUser.organization.used_intelligence_search}/{currentUser.organization.max_intelligence_search}  Contact the administrator to extend this limit.
                    </p>
                    {
                        currentUser.organization.used_intelligence_search === currentUser.organization.max_intelligence_search &&
                        <p className={'subhead'} style={{'color': 'red'}}>You reach the max number.</p>
                    }
                </div>
                <div className="historyTable">
                  <TableWithData
                    data={data?.data}
                    tableColumns={tableColumns}
                    tableConfig={tableConfig}
                    isLoading={isIdle || isLoading}
                    isError={isError}
                  />
                </div>
            </CardBody>
        </Card>
    )
}

IntelligenceHistory.propTypes = {
  currentUser: PropTypes.shape().isRequired
}

export default IntelligenceHistory;