import PropTypes from "prop-types";
import React from "react";

const ProfilePreferencesMessage = ({
  preferences,
  mode,
  preferencesType
}) => {
  const deletion = !!preferences.forDeletion.length
  const addition = !!preferences.forAddition.length
  const edition = !!preferences.forEdition.length
  const regexErrors  = !!preferences.regexErrors.length
  return {preferences: preferences, message: (<>
      {regexErrors && mode !== 'deletion' && <><p className="text-modal modal__body">{preferencesType.toUpperCase()} entries with <b className='text-warning'>invalid</b> {preferencesType} values will not be added:</p>
        <p className='text-warning' style={{'display': 'inline-block'}}>{preferences.regexErrors.map((pref) => <>{pref}<br/></>)}</p></>}

      {addition && mode !== 'deletion' && <><p className="text-modal modal__body">{preferencesType.toUpperCase()} entries that will be added:</p>
        <p className='text-success' style={{'display': 'inline-block'}}>{preferences.forAddition.map((pref) => <>{pref}<br/></>)}</p></>}

      {edition && mode !== 'deletion' && <><p className="text-modal modal__body">{preferencesType.toUpperCase()} entries that will be edited:</p>
        <p className='text-info' style={{'display': 'inline-block'}}>{preferences.forEdition.map((pref) => <>{pref}<br/></>)}</p></>}

      {deletion && mode === 'deletion' && <><p className="text-modal modal__body">{preferencesType.toUpperCase()} entries that will be deleted:</p>
        <p className='text-danger' style={{'display': 'inline-block'}}>{preferences.forDeletion.map((pref) => <>{pref}<br/></>)}</p></>}
    </>
  )}
}


ProfilePreferencesMessage.propTypes = {
  preferences: PropTypes.object.isRequired,
  mode: PropTypes.string,
  preferenceType: PropTypes.string.isRequired,
}
export default ProfilePreferencesMessage