import React from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import {SidebarProps} from '../../../../shared/prop-types/ReducerProps';
import SidebarContent from "../../sidebar/SidebarContent";

const Sidebar = ({
  changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar, user, modules
}) => {
  const sidebarClass = classNames({
    'sidebar sidebar--no-desktop': true,
    'sidebar--show': sidebar.show,
  });

  return (
    <div className={sidebarClass}>
      <button className="sidebar__back" type="button" onClick={
        () => {
          if (sidebar.show) {
            changeMobileSidebarVisibility()
          }
        }
      }/>
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent
            onClick={changeMobileSidebarVisibility}
            changeToDark={changeToDark}
            changeToLight={changeToLight}
            user={user}
            modules={modules}
          />
        </div>
      </Scrollbar>
    </div>
  );
};

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  modules: PropTypes.array
};


export default Sidebar;
