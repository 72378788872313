import React from "react";
import {Card, CardBody} from "reactstrap";
import {useEventContentsQuery} from "../../../../queries/EventContents";
import {Link, useHistory} from "react-router-dom";
import paths from "../../../../config/paths";
import TableWithData from "../../../../shared/components/table/TableWithData";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import TLPBadge from "../../../../shared/components/badge/TLPBadge";
import {LimitBadges, LimitCountriesBadges} from "../../../../shared/helpers/LimitBadges";
import {actorsToString, getAnalysisPathByValue, getPostTitle} from "../../../../shared/helpers/contents";
import PropTypes from "prop-types";
import _ from "lodash";
import {getCommonTags, getMalwareFamilyTags} from "../../../../shared/helpers";


const tableColumns = [
  { Header: 'Title', accessor: (row) =>
      _.join(
        _.split(row.type === 'post' ? getPostTitle(row) : row.title, ' ').map(
          (word) => _.upperFirst(_.toLower(word))
        ), ' ') },
  { Header: 'Actors', accessor: (row) => actorsToString(row.actors)},
  { Header: 'Tags', accessor: 'tags', Cell: ({value}) => <LimitBadges limit={2} items={getCommonTags(value)} /> },
  { id: 'malware_family', Header: 'Malware Family', accessor: 'tags', Cell: ({value}) => <LimitBadges limit={2} items={getMalwareFamilyTags(value)} /> },
  { Header: '# Indicators', accessor: row => row.indicators.length },
  { Header: 'Targeted countries', accessor: 'targeted_countries', Cell: ({value}) => <LimitCountriesBadges flagOnly limit={2} countries={value} /> },
  { Header: 'TLP', accessor: 'tlp', Cell: ({value}) => <TLPBadge tlp={value.tlp} /> },
  { Header: 'Date', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value) },
];


const LastContentsTable = ({contentsLimit = 10, ...props}) => {
  const history = useHistory();
  const { data, isIdle, isLoading, isError } = useEventContentsQuery(true, contentsLimit);

  const tableConfig = {
    withPagination: false,
    withHover: true,
    manualPageSize: [contentsLimit],
    striped: true
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}><Link to={paths.contentsPath}>Last contents</Link></h4>
        </div>
        <TableWithData
          data={data?.data}
          isLoading={isLoading || isIdle}
          isError={isError}
          tableColumns={tableColumns}
          tableConfig={tableConfig}
          onRowClick={(index, row) => {
            history.push(`${getAnalysisPathByValue(row.original.type)}/${row.original.uid}`)
          }}
          {...props}
        />
      </CardBody>
    </Card>
  )
}

LastContentsTable.propTypes = {
  contentsLimit: PropTypes.number
}

export default LastContentsTable;
