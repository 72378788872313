import DownloadOutlineIcon from "mdi-react/DownloadOutlineIcon";
import {download_file} from "../../helpers/download_file";
import React from "react";
import PropTypes from "prop-types";
import {useCookieAccessToken} from "../../../queries";


const DownloadFileIcon = ({url, filename, size = 24, style = {}, timeout = 20000, tmp = {}}) => {
  const {data: accessToken} = useCookieAccessToken();

  return <DownloadOutlineIcon
    onClick={(e) => {
      e.stopPropagation();
      download_file(url, filename, accessToken, timeout);
    }}
    size={size}
    style={Object.assign({}, {fill: 'white', padding: '2px'}, style)}
    className={'border-hover-white'}
  />
}


DownloadFileIcon.propTypes = {
  url: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  size: PropTypes.number,
  style: PropTypes.object,
  timeout: PropTypes.number
}


export default DownloadFileIcon;
