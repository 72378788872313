import React, {useState} from "react";
import PropTypes from "prop-types";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import ActorCard from "../card/withIcon/ActorCard";


const ActorsDetailTab = ({actors}) => {
  const actorNames = actors.map((actor) => actor.name);
  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="tabs tabs--bordered-bottom">
      <div className="tabs__wrap">
        <Nav tabs>
          {
            actorNames.map((actor, index) =>
              <NavItem key={actor}>
                <NavLink
                  className={classnames({active: activeTab === index})}
                  onClick={() => toggleTab(index)}
                  key={index}
                >
                  {actor.toUpperCase()}
                </NavLink>
              </NavItem>
            )
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          {
            actors.map((actor, index) =>
              <TabPane tabId={index} key={index}>
                <ActorCard actorUid={actor.uid} />
              </TabPane>
            )
          }
        </TabContent>
      </div>
    </div>
  )
}


ActorsDetailTab.propTypes = {
  actors: PropTypes.array.isRequired
}


export default ActorsDetailTab;
