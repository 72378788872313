import React from "react";
import {Col, Row, Progress} from "reactstrap";
import PropTypes from "prop-types";


const FakeItem = ({style}) => {
  const FakeDiv = () => <div className={'progress-wrap progress-wrap--small progress-wrap--grey'}><Progress animated value={100} /></div>;

  return (
    <Row style={style} className={'align-items-center'}>
      <Col md={1}><FakeDiv /></Col>
      <Col md={4}><FakeDiv /></Col>
      <Col md={2}><FakeDiv /></Col>
    </Row>
  )
}

FakeItem.propTypes = {
  style: PropTypes.shape().isRequired
}

export default FakeItem;
