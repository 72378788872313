import React, {useState} from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useHistory, useParams} from "react-router-dom";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {
  useActorCommandsQuery,
  useActorContentQuery,
  useActorDetailsQuery,
  useActorIndicatorsCountQuery,
  useActorTTPsQuery
} from "../../queries/Actor/index";
import {useMitreTTPSQuery} from "../../queries/MitreTTPS";
import TargetVictimCard from "../../shared/components/card/TargetVictimCard";
import MitreFrameworkCard from "../../shared/components/card/MitreFrameworkCard";
import SectorCard from "../../shared/components/card/withIcon/SectorCard";
import {formatDateUTCtoYear, formatDateUTCtoYearMonthDay} from "../../shared/helpers/date";
import ActorContentTimeline from "./components/ActorContentTimeline";
import AssociatedMalwareCard from "../../shared/components/card/withIcon/AssociatedMalwareCard";
import MotivationCard from "../../shared/components/card/withIcon/MotivationCard";
import ActorDetailCard from "../../shared/components/card/ActorDetailCard";
import ActorActivityHistogram from "./components/ActorActivityHistogram";
import IndicatorsTabWithTablePaginationCard from "../../shared/components/card/IndicatorsTabWithTablePaginationCard";
import {useSearchActorIndicatorDictPagination} from "./queries/SearchActorIndicatorDictPagination";
import _ from "lodash";
import {canUserManageActor} from "../../shared/helpers/permissions";
import paths from "../../config/paths";
import exportActorInformation from "./export/ExportActor";
import {useCookieAccessToken} from "../../queries";
import RansomwareAttacksTablePaginationCard from "../../shared/components/card/RansomwareAttacksTablePaginationCard";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import CommandsCard from "../../shared/components/card/CommandsCard";
import {PLATFORM_FEATURE_ACTORS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const ActorDetail = () => {
  const {actorUid: uid} = useParams();
  const {data, isIdle, isLoading, isError, error} = useActorDetailsQuery(uid);
  const history = useHistory();
  const updateFilters = (data) => {
    const urlParams = new URLSearchParams();
    urlParams.set(data.type, data.name);
    history.push(`${paths.actorPath}?${urlParams.toString()}`);
  }
  const onCountryClick = (data) => {
    history.push(`${paths.actorPath}?targeted_countries=${data.target.dataItem.dataContext.id}`)
  }

  const {
    data: dataCommands,
    isIdle: isIdleCommands,
    isLoading: isLoadingCommands,
  } = useActorCommandsQuery(uid)

  const {
    data: dataContent,
    isIdle: isIdleContent,
    isLoading: isLoadingContent,
    isError: isErrorContent,
    error: errorContent,
  } = useActorContentQuery(uid);
  const {
    data: dataTTPs,
    isIdle: isIdleTTps,
    isLoading: isLoadingTTPs,
    isError: isErrorTTPs,
    error: errorTTPs
  } = useActorTTPsQuery(uid);
  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser,
    error: errorCurrentUser
  } = useCurrentUserQuery();
  const {
    data: dataMitreTTPS,
    isIdle: isIdleMitreTTPS,
    isLoading: isLoadingMitreTTPS,
    error: isErrorMitreTTPS
  } = useMitreTTPSQuery();
  const [targetedSectors, setTargetedSectors] = useState([]);
  const {data: accessToken} = useCookieAccessToken()

  if (isError || isErrorTTPs || isErrorContent || isErrorCurrentUser || isErrorMitreTTPS) {
    return <ErrorHandler error={error || errorTTPs || errorContent || errorCurrentUser}/>
  }

  if (isLoading || isIdle || isLoadingTTPs || isIdleTTps || isLoadingContent || isIdleContent || isIdleCurrentUser
    || isLoadingCurrentUser || isIdleMitreTTPS || isLoadingMitreTTPS) {
    return <LoadingSpinnerPage/>
  }

  const actor = data.data;
  const actorTTPs = dataTTPs.data;

  const exportActor = (format) => {
    return exportActorInformation(
      format,
      actor,
      targetedSectors,
      dataContent,
      dataTTPs,
      dataMitreTTPS,
      accessToken
    );
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_ACTORS}
    >
      <Container>
        <Row>
          <Col>
            <ActorActivityHistogram actorUid={actor.uid}/>
          </Col>
        </Row>

        <Row>
          <Col md={6} xs={6} className={'d-flex flex-column justify-content-start'}>
            <ActorDetailCard
              actor={actor}
              updateFilters={updateFilters}
              style={{height: 'auto'}}
              withEditableButton={canUserManageActor(dataCurrentUser?.data)}
              exportActor={exportActor}
            />
            <MotivationCard updateFilters={updateFilters} motivations={actor.motivations}/>
            <SectorCard updateFilters={(val) => {
              history.push(paths.actorPath + '?targeted_sectors=' + val)
            }} sectors={actor.targeted_sectors} setTargetedSectors={setTargetedSectors}/>
            <AssociatedMalwareCard updateFilters={(val) => {
              history.push(paths.actorPath + '?associated_malwares=' + val)
            }} associatedMalware={actor.associated_malwares}/>
          </Col>
          <Col md={6} xs={12}>
            <Card>
              <CardBody style={{height: '500px', overflowY: 'scroll'}}>
                <div className="card__title">
                  <h4 className={'bold-text d-inline'}>Related contents</h4>
                  <p className={'subhead'}>
                    First registered activity on {formatDateUTCtoYear(actor.first_seen)}
                  </p>
                  <p className={'subhead'}>
                    {actor.last_seen && `Last seen on ${formatDateUTCtoYearMonthDay(actor.last_seen)}`}
                  </p>
                </div>
                <ActorContentTimeline actorUid={uid}/>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* RANSOMWARE ATTACKS */}
        <Row>
          <Col md={12} id={'ransomware-attacks'}>
            <RansomwareAttacksTablePaginationCard
              filters={{actor_name: actor.name}}
            />
          </Col>
        </Row>

        {/* MITRE FRAMEWORK */}
        <Row>
          <Col md={12}>
            <MitreFrameworkCard updateFilters={updateFilters} techniques={
              _.uniq(
                _.concat(
                  actor.techniques,
                  _.flatten(actorTTPs.map((tactic) => tactic.techniques)).map((tec) => tec.uid)
                )
              )
            }/>
          </Col>
        </Row>

        {/* INDICATORS */}
        <Row>
          <Col md={12} id={'indicators'}>
            <IndicatorsTabWithTablePaginationCard
              dataHookCount={useActorIndicatorsCountQuery}
              uid={uid}
              filters={{uid: uid}}
              dataHook={useSearchActorIndicatorDictPagination}
              withExportButton
              exportFilters={{actor: uid}}
            />
          </Col>
        </Row>

        {/* COUNTRIES */}
        <Row>
          <Col md={12}>
            <TargetVictimCard updateFilters={(type, val) => updateFilters({type: type, name: val})}
                              onCountryClick={onCountryClick} targetedCountries={
              _.uniq(
                actor.targeted_countries.concat(
                  _.flatten(
                    dataContent.data.map(
                      (content) => content?.targeted_countries && !content?.is_weekly_report ? content.targeted_countries : [])
                  )
                )
              )
            }/>
          </Col>
        </Row>
        {/* COMMANDS */}
        <Row>
          <Col md={12}>
            {
              (isIdleCommands  || isLoadingCommands) ? <LoadingSpinner/> :
                <CommandsCard  commands={dataCommands.data}/>
            }
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

export default ActorDetail;
