import {addAuthToAxiosOpts} from '../config/queryopts'
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import { useQueryWithAuthentication } from '.';


export const motivationFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/motivations`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useMotivationsQuery = (fetcher = motivationFetcher) => {
    return useQueryWithAuthentication(
      ['motivations'], (accessToken) => fetcher(accessToken), {staleTime: Infinity}
    )
}
