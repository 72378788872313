import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const FeedlyFeedsPreferencesFetcher = (
  accessToken, preference_type = null
) => {
  const urlParams = new URLSearchParams();
  if (preference_type) {
    urlParams.set('preference_type', preference_type);
  }

  return axios.get(`${apiUrlInternal}/news_feeds/preferences?${urlParams.toString()}`,
    addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useFeedlyFeedsPreferenceQuery = (
  preference_type = null,
  fetcher = FeedlyFeedsPreferencesFetcher) => {
  return useQueryWithAuthentication(
    ['feedly-feeds-preferences', preference_type],
    (accessToken) => fetcher(accessToken, preference_type
    ))
}

