import React, {Fragment} from "react";
import PropTypes from "prop-types";
import CopyIconToClipboard from "./icons/CopyIconToClipboard";


const COPY_ICON_SIZE = 21;
const LEFT_MARGIN_LABEL = 6;
const LEFT_MARGIN_TITLE = COPY_ICON_SIZE + LEFT_MARGIN_LABEL;
const LEFT_MARGIN_ICON = 3;


const MenuEntryWithCopyIcon = ({title, value}) =>
  <Fragment>
    <p className="custom_card__container-subhead" style={{marginLeft: `${LEFT_MARGIN_TITLE + LEFT_MARGIN_ICON}px`}}>{title}</p>
    <div className={'d-flex flex-row'}>
      <CopyIconToClipboard value={value} size={COPY_ICON_SIZE} style={{marginLeft: `${LEFT_MARGIN_ICON}px`}}/>
      <p className="custom_card__container-title" style={{marginLeft: `${LEFT_MARGIN_LABEL}px`}}>{value}</p>
    </div>
  </Fragment>


MenuEntryWithCopyIcon.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}


export default MenuEntryWithCopyIcon;
