import React, {useEffect, useState} from 'react'

// Import React FilePond
import {FilePond} from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import {apiUrlInternal} from "../../../config/api";
import axios from "axios";
import {useCookieAccessToken} from "../../../queries";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import {useMutation, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../../shared/helpers";

export default function SandboxTaskFilepond({onSuccess}) {
  const [files, setFiles] = useState([])
  const queryClient = useQueryClient()
  const {data: accessToken, isIdle, isLoading, isError, error} = useCookieAccessToken()
  const mutationFx = (newPayload) => {
    return axios.post(`${apiUrlInternal}/sandbox/tasks?filename=${newPayload['fileName']}`, newPayload['formdata'], newPayload['config'])
  }
  const mutation = useMutation(mutationFx, {
    onSuccess: (data, variables) => {
      variables.load(data);
      onSuccess();
      queryClient.invalidateQueries('sandbox-tasks')
    },
    onError: (error) => {
      console.log(error)
    }
  })

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isError) toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError])

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  if (isLoading || isIdle) {
    return <LoadingSpinner/>
  }

  return (
    <div>
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        allowRevert={false}
        maxFiles={1}
        credits={null}
        instantUpload={false}
        server={{
          process: (fieldName, file, metadata, load, error, progress, abort) => {
            const formData = new FormData();
            formData.append(fieldName, file, file.name);
            const cancelTokenSource = axios.CancelToken.source();
            const config = {
              headers: {Authorization: `Bearer ${accessToken}`},
              onUploadProgress: function (progressEvent) {
                progress(true, progressEvent.loaded, progressEvent.total);
              },
              cancelToken: cancelTokenSource.token
            };
            const newPayload = {
              fileName: file.name,
              formdata: formData,
              config: config,
              load: load
            }
            if (mutation.error) {
              mutation.reset()
            }
            mutation.mutate(newPayload)
            return {
              abort: () => {
                cancelTokenSource.cancel();
                abort();
              }
            };
          }
        }}
        name="file"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  )
}
