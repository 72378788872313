import {Button, Col, Container, Row} from "reactstrap";
import React, {Fragment, useEffect, useState} from "react";
import _ from "lodash";
import paths from "../../config/paths";
import {useHistory} from "react-router-dom";
import logo from "../../shared/img/radar.svg";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {useResourceMonitorResultQuery} from "../../queries/ResourceMonitorResult";
import {useProjectsOrganizationResourceMonitorQuery} from "../../queries/ProjectsOrganizationResourceMonitor";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import ResourceMonitorDashboard from "./components/ResourceMonitorDashboard";
import CveResults from "./components/CveResults";
import ResourseMonitorDetail from "./components/ResourseMonitorDetail";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS, PLATFORM_PERMISSION_VIEWER} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const ResourceMonitor = () => {
  const {data, isIdle, isLoading, isError, error} = useResourceMonitorResultQuery();
  const {data: dataUser, isIdle: isIdleOrg, isLoading: isLoadingOrg} = useCurrentUserQuery();
  const {
    data: dataResources,
    isIdle: isIdleResources,
    isLoading: isLoadingResources,
    isError: isErrorResources,
    error: errorResources,
  } = useProjectsOrganizationResourceMonitorQuery(dataUser?.data?.organization?.uid);
  const RISK_SEC = 0;
  const INTEL_SEC = 1;

  const [currentSec, setCurrentSec] = useState(RISK_SEC);
  const [currentCve, setCurrentCve] = useState('');
  const [currProject, setCurrProject] = useState({});

  const history = useHistory();

  useEffect(() => {
    if (!isErrorResources && !isLoadingResources && !isIdle && dataResources?.data && dataResources.data.length > 0) {
      setCurrProject(dataResources.data[0]);
    }
  }, [isIdleResources, isLoadingResources, isErrorResources, dataResources?.data])


  if (isLoading || isIdle || isIdleResources || isLoadingResources || isLoadingOrg || isIdleOrg) {
    return <LoadingSpinnerPage/>
  }

  if (isError || isErrorResources) {
    return <ErrorHandler error={error || errorResources}/>
  }


  if (dataResources.data.length === 0) {
    return (
      <Page
        feature={PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS}
        onlyOnline
      >
        <img
          src={logo}
          alt={'Vulnerability Exposure icon'}
          title={'Vulnerability Exposure icon'}
          className={"img__resource-monitor-icon"}
        />
        <Container className={'dashboard'}>
          <Row>
            {
              hasUserEditorPermissionOnFeature(dataUser?.data?.modules || [], PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS) &&
              <Col>
                <Button
                  className={"rounded float-right"}
                  size="sm"
                  outline
                  onClick={() => history.push(paths.profilePath)}>
                  Edit resources to monitor
                </Button>
              </Col>
            }
          </Row>
          <Row>
            <Col>
              <h2 className={"h2_investigate-title"}>No Vulnerabilities Found</h2>
              <h3 className={"h3_investigate-subtitle"}>
                In order to use the Attack Surface Intelligence module you must create at least one engine. <br/>
                Create a new one in the "Attack Surface Intelligence" in your profile section.
              </h3>
            </Col>
          </Row>
        </Container>
      </Page>
    )
  }


  return (
    <Page
      feature={PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS}
      onlyOnline
    >
      <Container className="div__sticky-top">
        <Row>
          <Col>
            {
              dataResources.data.map((project) =>
                <Button
                  className={"rounded"}
                  size="sm"
                  outline
                  key={project.uid}
                  active={currProject?.uid === project.uid}
                  onClick={() => setCurrProject(project)}>
                  {project.name}
                </Button>
              )
            }

            {
              hasUserEditorPermissionOnFeature(dataUser?.data?.modules || [], PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS) &&
              <Button
                className={"rounded float-right"}
                size="sm"
                outline
                onClick={() => history.push(paths.profilePath)}>
                Edit resources to monitor
              </Button>
            }

          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              className={"rounded"}
              size="sm"
              outline
              active={currentSec === RISK_SEC}
              onClick={() => setCurrentSec(RISK_SEC)}>
              Vulnerability Risk
            </Button>
            <Button
              className={"rounded"}
              size="sm"
              outline
              active={currentSec === INTEL_SEC}
              onClick={() => setCurrentSec(INTEL_SEC)}>
              Vulnerability Intelligence
            </Button>
          </Col>
        </Row>
      </Container>
      {
        _.isEmpty(currProject) || _.isEmpty(currProject.resource_monitors) &&
        <img
          src={logo}
          alt={'Vulnerability Exposure icon'}
          title={'Vulnerability Exposure icon'}
          className={"img__resource-monitor-icon"}
        />
      }
      <Container className={'dashboard'}>
        {currentSec === RISK_SEC &&
          <ResourceMonitorDashboard
            project={currProject}
            resource_monitor_results={
              !_.isEmpty(currProject) ? _.filter(
                data.data,
                (result) => currProject.resource_monitors.map((res) => res.uid).includes(result.resource_monitor.uid)
              ) : []
            }
          />
        }

        {currentSec === INTEL_SEC && !currentCve &&
          <CveResults
            customHandle={(id) => setCurrentCve(id)}
            resource_monitor_results={
              !_.isEmpty(currProject) ? _.filter(
                data.data,
                (result) => currProject.resource_monitors.map((res) => res.uid).includes(result.resource_monitor.uid)
              ) : []
            }
          />
        }

        {
          currentSec === INTEL_SEC && currentCve &&
          <ResourseMonitorDetail
            clearCve={() => setCurrentCve('')}
            id={currentCve}/>
        }

      </Container>
    </Page>
  )
}


export default ResourceMonitor;
