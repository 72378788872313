import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteApplicationFetcher = (uid, accessToken) => {
  return axios.delete(`${apiUrlInternal}/applications/${uid}`, addAuthToFetchOpts(accessToken, {})).then(res => res.data );
}

export const useDeleteApplication = (fetcher = DeleteApplicationFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-application'],
    (accessToken, uid) => fetcher(uid, accessToken),
    {onSuccess: () => {
        queryClient.invalidateQueries('delete-application');
        queryClient.invalidateQueries(['application-details', null]);
      }}
  )
}
