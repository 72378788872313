import classNames from "classnames";
import CopyIconToClipboard from "../components/icons/CopyIconToClipboard";
import React, {Fragment} from "react";
import VirusTotalIndicatorLink from "../components/icons/VirusTotalIndicatorLink";
import is_ip_private from "private-ip";


export const getCopiableColumn = (header, field, truncatedColumns = false) => {
  const classes = classNames({
    'truncated-columns': truncatedColumns
  });
  return (
    {
      Header: () => <span style={{marginLeft: '51px'}}>{header}</span>,
      accessor: field,
      className: "truncated-columns", Cell: ({value}) =>
        <div className={'d-flex flex-row ml-1'}>
          <CopyIconToClipboard value={value} size={'20'} style={{marginRight: '10px'}}/>
          <span style={{marginLeft: '20px'}} className={classes}>{value}</span>
        </div>
    })
}

export const getCopiableVtColumn = (id, accessor, header = '') => (
  {
    id: id, Header: header, style: {width: '30px'}, accessor: accessor, Cell: ({value}) =>
      <div>
        <VirusTotalIndicatorLink value={value} />
        <CopyIconToClipboard value={value} size={21} />
      </div>
  }
)

export const getCopiableVtPrivateIpColumn = (id, accessor, header = '') => (
  {
    id: id, Header: header, accessor: accessor, style: {width: '30px'}, Cell: ({value}) =>
      value &&
      <Fragment>
        {!is_ip_private(value) && <VirusTotalIndicatorLink value={value}/>}
        <CopyIconToClipboard value={value} size={21} />
      </Fragment>
  }
)

export const getTableHeaderAccessor= (accessor, mapping) => {
  if (!accessor) return
  // regex expr to replace spaces, and dots in accessors or id
  const parsedAccessor = accessor.toLowerCase().replace(/[. ]+/g, "_")
  const keysChecked = mapping[parsedAccessor] ? mapping[parsedAccessor] : undefined
  if (!keysChecked){
    return Object.values(mapping).find((a) => a === accessor)
  }
  else {
    return keysChecked
  }
}
