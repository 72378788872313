const one_edit_distance_typo = "One edit distance typo"
const kpt = "KPT"
const chars_swapping = "Chars swapping"
const deletion = "Deletion"
const insertion = "Insertion"
const combosquatting = "Combosquatting"
const substring = "Substring"
const confusables = "Confusables"

/**
 Character swapping, when two consecutive characters belonging to a trademark or domain are swapped
 Key proximity typo (KPT), when a character is replaced with another character located in a keyboard position really close to the former
 Character insertion/deletion: a character is inserted or deleted from the original domain name
 Combosquatting (add an extra word separated with a ‘-’)
 Numeric substitution (included in confusables category): replace a character with a very similar one
 Substring: the domain is part of a longest string
 One edit distance typo: one character needs to be modified to reach the original domain name

 */

const squatting_type_examples = {
  [one_edit_distance_typo]: "Example: google -> gqogle",
  [kpt]: "Example: google -> googke",
  [chars_swapping]: "Example: google -> goolge",
  [deletion]: "Example: google -> gogle",
  [insertion]: "Example: google -> gooogle",
  [combosquatting]: "Example: google -> google-store",
  [substring]: "Example: google -> mygoogleaccount",
  [confusables]: "Example: google -> g0gle",
}

const squatting_type_description = {
  [one_edit_distance_typo]: "One character needs to be modified to reach the original domain name.",
  [kpt]: "Key proximity typo (KPT), when a character is replaced with another character located in a keyboard position really close to the former.",
  [chars_swapping]: "Two consecutive characters belonging to a trademark or domain are swapped.",
  [deletion]: "A character is deleted from the original domain name.",
  [insertion]: "A character is inserted from the original domain name.",
  [combosquatting]: "Add an extra word separated with a '-'.",
  [substring]: "The domain is part of a longest string.",
  [confusables]: "Replace a character with a very similar one.",
}

export const get_message_by_squatting_type = (squatting_type) => squatting_type_description[squatting_type]
export const get_example_by_squatting_type = (squatting_type) => squatting_type_examples[squatting_type]