import Clamp from "react-multiline-clamp";
import React from "react";
import PropTypes from "prop-types"
import {Interweave} from "interweave";


const ThemedClampLines = ({
                            text,
                            moreText = 'Read more',
                            lessText = 'Read less',
                            lines = 4,
                            maxLines = 8,
                            isTextHtml = false,
                            footerMessage = null,
                          }) => {
  return (
    <Clamp
      lines={lines}
      maxLines={maxLines}
      withToggle

      showMoreElement={({ toggle }) => (
        <span id="clamp-lines__button_id" className={'clamp-lines__button'} onClick={toggle}>
          {moreText}
        </span>
      )}
      showLessElement={({ toggle }) => (
        <span id="clamp-lines__button_id" className={'clamp-lines__button'} onClick={toggle}>
          {lessText}
        </span>
      )}
    >
      <p className={'description'}>
        <div>{isTextHtml ? <Interweave content={text ? text : ''}/> : text}</div>
      </p>
      {footerMessage && isTextHtml &&
        <p className={'card-footer'} style={{'text-align': 'center'}}>
          <p className={'text-secondary'}>
            <b>Article Source: </b>
            <a href={footerMessage} target={"_blank"}>{footerMessage}</a>
          </p>
        </p>}
    </Clamp>
  )
}


ThemedClampLines.propTypes = {
  text: PropTypes.string.isRequired,
  moreText: PropTypes.string,
  lessText: PropTypes.string,
  lines: PropTypes.number,
  maxLines: PropTypes.number,
  isTextHtml: PropTypes.bool,
  footerMessage: PropTypes.string,
}

export default ThemedClampLines;
