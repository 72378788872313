import React, {Fragment, useCallback, useState} from "react";
import {Button, Popover, PopoverBody, PopoverHeader} from "reactstrap";
import AlertOutlineIcon from "mdi-react/AlertOutlineIcon";
import paths from "../../../config/paths";
import {Link} from "react-router-dom";


const TopBarNoKeywordAlertTooltip = () => {
  const [IsOpenedPopover, setIsOpenedPopover] = useState(false);
  const handleOpenPopover = useCallback(() => setIsOpenedPopover(!IsOpenedPopover), [IsOpenedPopover]);

  return (
    <Fragment>
      <Button
        id={"PopoverKeywordAlert"}
        onClick={handleOpenPopover}
        outline
        color={'danger'}
        className={"button-tooltip-alert"}
      >
        <AlertOutlineIcon />
      </Button>
      <Popover
        placement="bottom"
        isOpen={IsOpenedPopover}
        target={"PopoverKeywordAlert"}
        toggle={handleOpenPopover}
        innerClassName={'popover-alert'}
      >
        <PopoverHeader>INSERT KEYWORDS</PopoverHeader>
        <PopoverBody>
          You did not insert any keywords to monitor.
          Please fill the form inside the "Intelligence Keywords" section in the <Link to={paths.profilePath}>profile page</Link> to start monitor keywords in the darkweb.
        </PopoverBody>
      </Popover>
    </Fragment>
  )
}


export default TopBarNoKeywordAlertTooltip;
