import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const SectorsListFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/sectors`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useSectorsListQuery = (fetcher = SectorsListFetcher) => {
  return useQueryWithAuthentication(
    ['sectors-list'],
    (accessToken) => fetcher(accessToken),
    {cacheTime: Infinity, staleTime: Infinity}
  )
}
