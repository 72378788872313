import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


const _ = require('lodash');


export const EditApplicationFetcher = (accessToken, params) => {
  return axios.put(
    `${apiUrlInternal}/applications/${params.uid}`, _.omit(params, ['uid']), addAuthToFetchOpts(accessToken, {})
  ).then(res => res);
}

export const useEditApplicationMutation = (fetcher = EditApplicationFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['update-application'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: (_, {uid}) => {
      queryClient.invalidateQueries(['users-me'])
      queryClient.invalidateQueries(['application-details'])
      queryClient.invalidateQueries(['application-details', uid])
    }}
  )
}

