import {addAuthToFetchOpts} from '../../config/queryopts'
import {useMutationWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SubmitTakeDownFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/takedown_tickets`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useAddTakdownTicketMutation = (fetcher = SubmitTakeDownFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-takedown-ticket'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('add-takedown-ticket');
        queryClient.invalidateQueries(['users-me']);
        queryClient.invalidateQueries(['takedown-tickets', '']);
      }
    }
  )
}
