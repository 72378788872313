import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {useQueryWithAuthentication} from "../index";
import PropTypes from "prop-types";
import {addAuthToAxiosOpts} from "../../config/queryopts";


export const IntelligenceSearchFetcher = (query, accessToken, intel_search = null) => {
  const urlParams = new URLSearchParams();
  urlParams.set('query', query);
  if (intel_search) {
    intel_search.forEach(intel => {
      urlParams.append('intel_search', intel);
    })

  }

  return axios.get(
    `${apiUrlInternal}/intelligence?${urlParams.toString()}`,
    addAuthToAxiosOpts(accessToken, {}, 60000)
  ).then(res => res.data);
}

export const useIntelligenceSearchQuery = (query = null, intel_search = null, fetcher = IntelligenceSearchFetcher) => {
  return useQueryWithAuthentication(
    ['intelligence-search', query], (accessToken) => fetcher(query, accessToken, intel_search),
    {staleTime: Infinity, enabled: !!query, retry: false, refetchOnWindowFocus: false}
  )
}

useIntelligenceSearchQuery.propTypes = {
  query: PropTypes.string.isRequired,
}
