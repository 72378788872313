import React from "react";
import {useParams} from "react-router-dom";
import OrganizationForm from "../../shared/components/form/organizations-form/OrganizationForm";
import {useEditOrganizationMutation} from "../../mutations/UpdateOrganizations";
import {useOrganizationsQuery} from "../../queries/Organizations";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import _ from "lodash";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_ORGANIZATIONS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const OrganizationEdit = () => {
  const {organizationUid: uid} = useParams();
  const {
    data: dataOrganizations,
    isIdle: isIdleOrganizations,
    isLoading: isLoadingOrganizations,
    isError: isErrorOrganizations,
    error: errorOrganizations
  } = useOrganizationsQuery();
  const {data, isIdle, isLoading, isError, error} = useOrganizationsQuery(uid);

  if (isIdle || isLoading || isLoadingOrganizations || isIdleOrganizations) {
    return <LoadingSpinnerPage/>
  }

  if (isError || isErrorOrganizations) {
    return <ErrorHandler error={error || errorOrganizations}/>
  }

  const defaultValues = data.data;

  if (data?.data?.managed_by && _.isString(data?.data?.managed_by)) {
    defaultValues.managed_by = {
      value: data?.data?.managed_by,
      label: dataOrganizations.data.filter(org => org.uid === data?.data?.managed_by)[0].name
    }
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_ORGANIZATIONS}
      permission={PLATFORM_PERMISSION_EDITOR} onlyOnline
    >
      <OrganizationForm
        editOrganization
        organizationUid={uid}
        defaultValues={defaultValues}
        mutationFn={useEditOrganizationMutation}
      />
    </Page>
  )
}


export default OrganizationEdit;
