import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SetAllIntelxSolved = (project_uid, section, accessToken) => {
  const urlParams = new URLSearchParams();

  urlParams.set('section', section)

  return axios.put(
    `${apiUrlInternal}/keyword_projects/${project_uid}/results/intel/select-as-solved/all?${urlParams.toString()}`,
    {},
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data );
}


export const useSetAllIntelxSolved = (fetcher = SetAllIntelxSolved) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['set-as-solved-all-intel-exposure'],
    (accessToken, {project_uid, section}) => fetcher(project_uid, section, accessToken),
    {onSuccess: (_, {project_uid}) => {
        queryClient.invalidateQueries(['keyword-project-results', project_uid]);
        queryClient.invalidateQueries(['set-as-solved-all-intel-exposure']);
      }}
  )
}
