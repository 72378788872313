import AlphaAIcon from "mdi-react/AlphaAIcon";
import AlphaPIcon from "mdi-react/AlphaPIcon";
import AlphaFIcon from "mdi-react/AlphaFIcon";
import AlphaCIcon from "mdi-react/AlphaCIcon";
import React from "react";
import paths from '../../../config/paths';
import {useHistory} from 'react-router-dom';
import ThemedFabMenu from '../../../shared/components/ThemedFabMenu';

const FabEventMenu = () => {
    
    const history = useHistory();
   
    const actions = [
        {
          text: "Add analysis",
          icon: <AlphaAIcon />,
          onClick: () => history.push(paths.addAnalysisPath)
        },
        {
          text: "Add post",
          icon: <AlphaPIcon />,
          onClick: () => history.push(paths.addPostPath)
        },
        {
          text: "Add collection",
          icon: <AlphaCIcon />,
          onClick: () => history.push(paths.addCollectionPath)
        },
        {
          text: "Add Flash Threat Activity",
          icon: <AlphaFIcon />,
          onClick: () => history.push(paths.addFlashAlertPath)
        }
      ];
    return (
        <ThemedFabMenu actions={actions} />
    );
}

export default FabEventMenu;