import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const ResourceMonitorResultFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/resource_monitors/results`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useResourceMonitorResultQuery = (fetcher = ResourceMonitorResultFetcher) => {
  return useQueryWithAuthentication(
    ['resource_monitors'], (accessToken) => fetcher(accessToken)
  )
}
