import {Button, Col, Row} from "reactstrap";
import IndicatorTimeLineHistogram from "./IndicatorTimeLineHistogram";
import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import RelatedIndicatorsCard from "./RelatedIndicatorsCard";
import TargetVictimCard from "../../../shared/components/card/TargetVictimCard";
import IndicatorIntelxCard from "./IndicatorIntelxCard";
import IntelligenceCard from "./IntelligenceCard";
import toast from "react-hot-toast";
import LimitModal from "../../../shared/components/modal/LimitModal";
import {useQueryClient} from "react-query";
import _ from "lodash";
import AndarielIntelligenceTable from "./AndarielIntelligenceTable";
import NodeGraph from "./nodeChart/NodeGraph";
import env_const from "../../../config/env_const";


const IndicatorDetailFooter = ({
  indicator_details,
  excludeIntelxTable = false,
  excludeTimeLine = false,
  excludeRelatedIndicator = false,
  excludeCountryMap = false
}) => {
  const related_indicators_by_content = indicator_details.related_indicators.by_content;
  const [limitModalOpenStatus, setLimitModalOpenStatus] = useState(false);
  const [intelligenceEnabledTab, setIntelligenceEnabledTab] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(true);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [intelxEnabled, setIntelxEnabled] = useState(false);
  const queryClient = useQueryClient();

  return (
    <Fragment>
      <Row>
        <Col md={12}>
            <NodeGraph indicator_details={indicator_details} />
        </Col>
      </Row>
      {
        !excludeCountryMap && indicator_details.targeted_countries.length > 0 &&
        <Row>
          <Col>
            <TargetVictimCard
              targetedCountries={indicator_details.targeted_countries}
              targetedSectors={indicator_details.targeted_sectors}
            />
          </Col>
        </Row>
      }

      {
        !excludeTimeLine && indicator_details.related_contexts.length > 0 &&
        <Row>
          <Col>
            <IndicatorTimeLineHistogram
              related_contexts={indicator_details.related_contexts}
            />
          </Col>
        </Row>
      }

      {
        !excludeRelatedIndicator && related_indicators_by_content.length > 0 &&
        <Row>
          <Col>
            <RelatedIndicatorsCard
              related_contexts={indicator_details.related_contexts}
              related_indicators={related_indicators_by_content}
            />
          </Col>
        </Row>
      }

      {
        limitModalOpenStatus &&
        <LimitModal
          onDoAction={() => {
            toast.loading('Loading intelligence data');
            setLimitModalOpenStatus(false);
            setIntelligenceEnabledTab(true);
            setButtonEnabled(false);
          }}
          onCancel={() => {
            setLimitModalOpenStatus(false);
          }}
        />
      }

      {
        !_.isNull(indicator_details.intelligence) ?
          <>
            <Row>
              <Col>
                <AndarielIntelligenceTable intelligence={indicator_details.intelligence} indicator_details={indicator_details} />
              </Col>
            </Row>
            <Row>
              <Col>
                {
                  !excludeIntelxTable &&
                  <Row className={'mt-3'}>
                    <Col>
                      <IndicatorIntelxCard indicator={indicator_details.indicator}/>
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
          </> :
            intelligenceEnabledTab &&
              <>
                <Row>
                  <Col>
                    <IntelligenceCard
                      onContentLoad={() => {
                        setButtonVisible(false);
                        setIntelxEnabled(true);
                        queryClient.invalidateQueries(['users-me']);
                        toast.dismiss();
                      }}
                      onContentLoadError={() => {
                        setButtonEnabled(true);
                        toast.dismiss();
                      }}
                      indicator_details={indicator_details}
                    />
                  </Col>
                </Row>
                {
                  !excludeIntelxTable && intelxEnabled &&
                  <Row className={'mt-3'}>
                    <Col>
                      <IndicatorIntelxCard indicator={indicator_details.indicator}/>
                    </Col>
                  </Row>
                }
              </>
      }

      {
        !env_const.is_on_prem && buttonVisible && _.isNull(indicator_details.intelligence) &&
          <Button
            outline
            onClick={() => {
              setLimitModalOpenStatus(!limitModalOpenStatus)
            }}
            color={'success'}
            disabled={!buttonEnabled}
            className={'w-100'}
          >
            View intelligence information on {indicator_details.indicator}
          </Button>
      }

    </Fragment>
  )
}


IndicatorDetailFooter.propTypes = {
  indicator_details: PropTypes.object.isRequired,
  excludeTimeLine: PropTypes.bool,
  excludeRelatedIndicator: PropTypes.bool,
  excludeCountryMap: PropTypes.bool
}

export default IndicatorDetailFooter;
