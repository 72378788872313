import React from "react";
import {useParams} from "react-router-dom";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import {useAnalysisDetailsQuery} from "../../../queries/AnalysisDetails";
import AnalysisForm from "../../../shared/components/form/events-form/AnalysisForm";
import {getAnalysisDefaultValue} from "../../../shared/helpers/contents";
import {useEditAnalysisMutation} from "../../../mutations/UpdateAnalysis";
import {useAddContentFormQuery} from "../../../queries/AddContentForm";
import env_const from "../../../config/env_const";
import NotAllowed401 from "../../DefaultPage/401";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_EDITOR} from "../../../shared/helpers/features";
import Page from "../../../shared/components/Page";
import LoadingSpinnerPage from "../../../shared/components/LoadingSpinnerPage";


const AnalysisEdit = () => {
  const { analysisUid } = useParams();
  const { data, isError, isLoading, isIdle, error } = useAnalysisDetailsQuery(analysisUid, true);
  const {
    tags: {data: dataTags, ...tagsProps},
    mitre: {data: dataMITRE, isLoading: isLoadingDataMITRE, isIdle: isIdleDataMITRE},
  } = useAddContentFormQuery();

  if (isLoading || isIdle || isLoadingDataMITRE || isIdleDataMITRE) {
    return <LoadingSpinnerPage />
  }

  if (isError) {
    return <ErrorHandler error={error} />
  }

  if (env_const.is_clone && !data.data.is_private) {
    return <Page><NotAllowed401 /></Page>
  }

  const defaultValues = getAnalysisDefaultValue(data.data, dataMITRE);

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
    >
      <AnalysisForm
        defaultValues={defaultValues}
        mutationFn={useEditAnalysisMutation}
        analysisUid={data.data.uid}
        editForm
      />
    </Page>
  )
}

export default AnalysisEdit;
