import {useActorDetailsQuery} from "./Actor";
import {useTagsQuery} from "./Tags";
import {useCountriesQuery} from "./Countries";
import {useRuleTypesQuery} from "./RuleTypes";
import {useIndicatorTypesQuery} from "./IndicatorTypes";
import {useSectorsListQuery} from "./SectorsList";
import {useMitreTTPSQuery} from "./MitreTTPS";
import {useCurrentUserQuery} from "./CurrentUser";
import {useMalwareFamilyQuery} from "./MalwareFamily";


export const useAddContentFormQuery = () => {
  return {
    'actors': useActorDetailsQuery(),
    'tags': useTagsQuery(),
    'countries': useCountriesQuery(),
    'ruleTypes': useRuleTypesQuery(),
    'indicatorTypes': useIndicatorTypesQuery(),
    'sectors': useSectorsListQuery(),
    'currentUser': useCurrentUserQuery(),
    'mitre': useMitreTTPSQuery(),
    'malware_families': useMalwareFamilyQuery(),
  }
}
