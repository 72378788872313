import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import _ from "lodash";


export const ProjectsOrganizationTypoSquattingFetcher = (uid, accessToken) => {
  return axios.get(
    `${apiUrlInternal}/typosquatting_projects/organizations/${uid}`,
    addAuthToAxiosOpts(accessToken, {})
  ).then(res => res.data);
}


export const useProjectsOrganizationTypoSquattingQuery = (uid, fetcher = ProjectsOrganizationTypoSquattingFetcher) => {
  return useQueryWithAuthentication(
    ['project-typosquatting', uid],
    (accessToken) => fetcher(uid, accessToken),
    {refetchOnWindowFocus: false, enabled: !_.isNull(uid) && !_.isUndefined(uid)}
  )
}
