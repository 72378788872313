import React, {useEffect} from "react";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import PropTypes from "prop-types";
import {Redirect, useHistory} from "react-router-dom";
import env_const from "../../../config/env_const";
import {APP_AUTH0_PROVIDER, APP_LOCAL_PROVIDER} from "../../../shared/helpers/validators";
import paths from "../../../config/paths";
import {useCookies} from "react-cookie";
import {useQueryClient} from "react-query";
import {useAuth0} from "@auth0/auth0-react";


export const RedirectPage = ({redirectMessage, redirectSubMessage, timeout = 5000}) => {
  const [, , removeCookie] = useCookies(['accessToken']);
  const queryClient = useQueryClient();
  const {logout} = useAuth0();


  useEffect(() => {
    const timer = setTimeout(
      () => {
        queryClient.invalidateQueries(['users-me']);
        if (env_const.react_app_auth_provider === APP_AUTH0_PROVIDER) {
          logout({ returnTo: `${window.location.origin}` })
        } else if (env_const.react_app_auth_provider === APP_LOCAL_PROVIDER) {
          removeCookie('accessToken');
          return <Redirect to={paths.homePath}/>
        }
      }, timeout
    );
    return () => clearTimeout(timer);
  }, [])

  return (
    <div className="d-flex flex-row align-items-center" style={{color: "white"}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-4 d-block">{redirectMessage}</span>
            {redirectSubMessage && <div className="mb-4 lead">{redirectSubMessage}</div>}
            <LoadingSpinner/>
          </div>
        </div>
      </div>
    </div>
  )
}

RedirectPage.propTypes = {
  redirectMessage: PropTypes.string.isRequired,
  redirectSubMessage: PropTypes.string,
}


export default RedirectPage;
