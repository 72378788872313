import moment from "moment";

export const formatDateUTCtoYear = (date) => moment.utc(date).local().format("YYYY");
export const formatDateUTCtoYearMonthDay = (date) => moment.utc(date).local().format("YYYY/MM/DD");
export const formatDateUTCtoYearMonth = (date) => moment.utc(date).local().format("YYYY/MM");
export const formatDateUTCtoYearMonthDayTime = (date) => moment.utc(date).local().format("HH:mm:ss, YYYY/MM/DD");
export const formatDateUTCtoYearMonthDayTimeFile = (date) => moment.utc(date).local().format("YYYYMMDDHHmmss");
export const formatDateUTCtoMonthDayYearTimeFile = (date) => moment.utc(date).local().format("MM/DD/YYYY hh:mm:ss A");
export const formatDateUTCtoMonthDayTime = (date) => moment.utc(date).local().format("MM/DD hh:mm");
export const formatDateUTCtoYearMonthDayTimeAMPM = (date) => moment.utc(date).local().format("DD/MM/YYYY hh:mm A");


export const getDaysArray = function (start, end = moment()) {
  if(start.isSame(end, 'day')){
    start.set('day',start.day() - 1)
  }
  for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const getMonthsArray = function (start, end = moment()) {
  if(start.isSame(end, 'month')){
    start.set('month',start.month() - 1)
  }
  for (var arr = [], dt = new Date(start); dt <= end; dt.setMonth(dt.getMonth() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};
