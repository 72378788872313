import PropTypes from "prop-types";
import {useEventContentsIndicatorsQuery} from "../../../../../../queries/EventContentsIndicators";
import LoadingSpinner from "../../../../LoadingSpinner";
import React from "react";
import IndicatorsCollapses from "../../components/IndicatorsCollapses";


const IndicatorsTabPaginatedDetailsModalBody = ({contentUid, iocType, total}) => {
  const res = useEventContentsIndicatorsQuery(contentUid, [iocType], total);

  return (
    res[0].isLoading || res[0].isIdle ? <LoadingSpinner /> : res[0].data.data.map((ioc) => <IndicatorsCollapses ioc={ioc} />)
  )
}

IndicatorsTabPaginatedDetailsModalBody.propTypes = {
  iocType: PropTypes.string.isRequired,
  contentUid: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
}

export default IndicatorsTabPaginatedDetailsModalBody;
