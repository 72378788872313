const GENERIC_SECTION = "generic";
const FILE_INFO_SECTION = "file_info";
const PASSIVE_DNS_SECTION = "passive_dns";
const MALWARE_FAMILY_SECTION = "malware_family";
const WHOIS_SECTION = "whois";
const INTELLIGENCE_SECTION = "intelligence";


export const takeDownIndicatorQuerySections = [
  GENERIC_SECTION,
  FILE_INFO_SECTION,
  PASSIVE_DNS_SECTION,
  MALWARE_FAMILY_SECTION,
  WHOIS_SECTION,
  INTELLIGENCE_SECTION,
];

export const intelligenceSectionsOnly = [
  INTELLIGENCE_SECTION
]

export const genericSectionsOnly = [
  GENERIC_SECTION
]

export const investigateNoIntelSections = [
  GENERIC_SECTION,
  FILE_INFO_SECTION,
  PASSIVE_DNS_SECTION,
  MALWARE_FAMILY_SECTION,
  WHOIS_SECTION
]

