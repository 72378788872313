import PropTypes from "prop-types";
import React from "react";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";


const NistIcon = ({cve}) =>
  <InformationOutlineIcon
    onClick={(e) => {
      window.open(`https://nvd.nist.gov/vuln/detail/${cve}`, "_blank");
      e.preventDefault();
    }}
    size={24}
    style={{fill: 'white', padding: '2px'}}
    className={'border-hover-white'}
  />


NistIcon.propTypes = {
  cve: PropTypes.string.isRequired
}

export default NistIcon;
