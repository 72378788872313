import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {getPostTitle} from "../../../shared/helpers/contents";
import IndicatorsTab from "../../../shared/components/tab/IndicatorsTab";
import {formatDateUTCtoYearMonthDay} from "../../../shared/helpers/date";
import {upperCaseFirstLetter} from "../../../shared/helpers";


const RelatedIndicatorsCard = ({related_contexts, related_indicators}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeContent, setActiveContent] = useState("");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    setActiveContent(contentsWithIndicators[activeTab].content);
  }, [activeTab]);

  const contentsWithIndicators = related_indicators.map((rel_indicator) => ({
    indicators: rel_indicator.indicators,
    content: related_contexts.filter((content) => content.uid === rel_indicator.content_uid)[0]
  }));


  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Related indicators</h4>
          <p className="subhead">{activeContent.type === 'analysis' ? `Analysis title: ${activeContent.title.toLowerCase()}` : getPostTitle(activeContent)}</p>
        </div>

        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {
                contentsWithIndicators.map((item, index) =>
                  <NavItem key={item.content.uid}>
                    <NavLink
                      className={classnames({active: activeTab === index})}
                      onClick={() => toggleTab(index)}
                    >
                      {upperCaseFirstLetter(item.content.type)} ({formatDateUTCtoYearMonthDay(item.content.created_dt)})
                    </NavLink>
                  </NavItem>
                )
              }
            </Nav>
            <TabContent activeTab={activeTab}>
              {
                contentsWithIndicators.map((iocType, index) =>
                  <TabPane tabId={index} key={index}>
                    <IndicatorsTab indicators={contentsWithIndicators[activeTab].indicators} withTable/>
                  </TabPane>
                )
              }
            </TabContent>
          </div>
        </div>
      </CardBody>
    </Card>

  )
}


RelatedIndicatorsCard.propTypes = {
  related_contexts: PropTypes.array.isRequired,
  related_indicators: PropTypes.array.isRequired,
}


export default RelatedIndicatorsCard;
