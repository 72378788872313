import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const FeedlyFeedsFetcher = (
  accessToken, limit = 100, offset = 0, order_by = null, search_term = null,
  source_title = null, start = null, end = null
) => {
  const urlParams = new URLSearchParams();
  if (limit) {
    urlParams.set('limit', limit);
  }
  if (offset) {
    urlParams.set('offset', offset);
  }
  if (order_by) {
    urlParams.set('order_by', order_by);
  }
  if (search_term) {
    urlParams.set('search_term', search_term);
  }
  if (source_title) {
    urlParams.set('source_title', source_title);
  }
  if (start) {
    urlParams.set('start', start)
  }
  if (end) {
    urlParams.set('end', end)
  }

  return axios.get(`${apiUrlInternal}/news_feeds?${urlParams.toString()}`,
    addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useFeedlyFeedsQuery = (
  limit = 100, offset = 0, order_by = null, search_term = null, source_title = null,
  start = null, end = null, fetcher = FeedlyFeedsFetcher) => {
  return useQueryWithAuthentication(
    ['feedly-feeds-query', limit, offset, order_by, search_term, source_title, start, end],
    (accessToken) => fetcher(accessToken, limit, offset, order_by, search_term, source_title, start, end
    ))
}

