import React, {Fragment, useCallback, useMemo, useState} from "react";
import {Badge, Col, Row, Tooltip} from "reactstrap";
import {AnalysisArrayTypes, getAnalysisNameByValue, getAnalysisPathByValue, getFormattedAnalysisName, getPostTitle} from "../../../shared/helpers/contents";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import { ColorTagInlineBadgeList } from "../../../shared/components/badge/BadgeLists";
import { formatDateUTCtoYearMonthDay } from "../../../shared/helpers/date";
import TLPBadge from "../../../shared/components/badge/TLPBadge";
import { Link } from "react-router-dom";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import PropTypes from "prop-types";
import ContentEditsBar from "../../../shared/components/card/accessories/ContentEditsBar";
import {getTlpColor, getTlpIndex} from "../../../shared/helpers/tlp";
import {getCommonTags, getMalwareFamilyTags} from "../../../shared/helpers";
import _ from "lodash";
import ExpandVerticalIcon from "mdi-react/ExpandVerticalIcon";


const ContentHeader = ({ content, isLoadingIdle, sectorLabels, toggle, isEditable, updateFilters = () => {}}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = useCallback(() => setTooltipOpen(!tooltipOpen), [setTooltipOpen, tooltipOpen]);

  const commonTags = useMemo(() => getCommonTags(content.tags), [content.tags]);
  const malwareFamiliesTags = useMemo(() => getMalwareFamilyTags(content.tags), [content.tags]);

  return (
    <div className={'card-header__events no_cursor'} >
      <Row>
        <Col md={11}>
          <Row>
            <Col md={12}>
              <div className={"card__title__events "}>
                <h4 className={'bold-text'} >
                  {
                    (content.type === 'analysis' || content.type === 'flashalert') ?
                      content.actors && content.actors.length > 0 ?
                        `${_.join(content.actors.map((actor) => actor.name), ', ')}: ${content.title}`: content.title
                      : (content.type === 'post' ? getPostTitle(content) : content.title )
                  }
                </h4>
                {
                  (content.type !== 'analysis' && content.type !== 'flashalert' && content.type !== 'post') &&
                    <p className={'bold-text pt-1 pb-1'} > {content.text} </p>
                }
                {
                  isLoadingIdle ? <LoadingSpinner /> :
                    <Fragment>
                      { (content.type === 'analysis' || content.type === 'post' || content.type === 'flashalert') &&
                        <><h5 className="subhead__events">Targeted sectors</h5>
                        {
                          sectorLabels.length > 0 ?
                            <ColorTagInlineBadgeList
                              onTagClick={updateFilters}
                              items={sectorLabels}
                              color={'dark-blue'}
                            /> :
                            <h5>NO TARGETED SECTORS AVAILABLE</h5>
                        }
                      </>}
                      <h5 className="subhead__events">Tags</h5>
                      {commonTags.length > 0 ?
                        <ColorTagInlineBadgeList
                          onTagClick={updateFilters}
                          items={commonTags}
                        /> : <h5>NO TAGS AVAILABLE</h5>}
                      {
                        malwareFamiliesTags.length > 0 &&
                        <Fragment>
                          <h5 className="subhead__events">Malware Families</h5>
                          <ColorTagInlineBadgeList
                            onTagClick={updateFilters}
                            items={malwareFamiliesTags}
                          />
                        </Fragment>
                      }
                    </Fragment>
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {content.created_dt && <p className={'subhead'}>Published on <b>{formatDateUTCtoYearMonthDay(content.created_dt)}</b></p>}
              {content?.tlp?.tlp && <span className="cursor" onClick={() => updateFilters(_.upperCase(content.tlp.tlp))}>
                <TLPBadge tlp={content.tlp.tlp} />
              </span>}
            </Col>
          </Row>
        </Col>
        <Col md={1} className={'d-flex flex-column justify-content-center'}>
          {
            content.rules?.length > 0 &&
            <span className={'badge--event-has-rule'}>
                <Badge color={content?.tlp?.tlp ? getTlpColor(content.tlp.tlp) : 'danger'} className={'w-100 outline badge-sm'}>
                  {content.rules?.length} RULE{content.rules?.length > 1 && 'S'}
                </Badge>
              </span>
          }
          {
            content.report_id &&
            <span className={content.rules?.length > 0 ? 'badge--event-has-report-and-rule' : 'badge--event-has-rule'}>
                <Badge color={content?.tlp?.tlp ? getTlpColor(content.tlp.tlp) : 'danger'} className={'w-100 outline badge-sm'}>
                  REPORT
                </Badge>
              </span>
          }
          <Link style={{marginTop: '40px'}} to={`${getAnalysisPathByValue(content.type)}/${content.uid}`}>
            <ChevronRightIcon className={'float-right'} size={32} style={{ fill: '#ffffff' }} id={`content-${content.uid}`} />
          </Link>
          <Tooltip placement="right" isOpen={tooltipOpen} target={`content-${content.uid}`} toggle={toggleTooltip}>Go to the {getFormattedAnalysisName(content.type)} details</Tooltip>
        </Col>
      </Row>

      <Row>
        <Col md={12} className={'d-flex justify-content-end'}>
          <ExpandVerticalIcon
            size={24}
            style={{fill: '#ffffff'}}
            className={'border-hover-white'}
            onClick={ toggle }
          />
        </Col>
      </Row>

      {isEditable && <ContentEditsBar
        showExpand={(content.type === 'analysis' || content.type === 'post' || content.type === 'flashalert')}
        toggle={toggle} content={content} type={content.type} />
      }
    </div>
  )
}

ContentHeader.propTypes = {
  content: PropTypes.shape().isRequired,
  isLoadingIdle: PropTypes.bool.isRequired,
  sectorLabels: PropTypes.array.isRequired,
  toggle: PropTypes.func.isRequired,
  updateFilters:PropTypes.func,
}

export default ContentHeader;
