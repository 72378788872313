import {Badge, Button, Col, Row} from "reactstrap";
import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {ColorTagInlineBadgeList, CountryBadgeList} from "../../../../shared/components/badge/BadgeLists";
import {useSectorsListQuery} from "../../../../queries/SectorsList";
import _ from "lodash";
import {getCommonTags, getMalwareFamilyTags} from "../../../../shared/helpers";
import TLPBadge from "../../../../shared/components/badge/TLPBadge";
import {getTlpColor} from "../../../../shared/helpers/tlp";
import ActorCountryBadgeList from "../../../../shared/components/badge/ActorCountryBadgeList";
import paths from "../../../../config/paths";
import {useHistory} from "react-router-dom";
import RightIcon from "mdi-react/ChevronRightIcon";


const AnalysisPostContent = ({content, type}) => {
  const {data: dataSectors, isIdle, isLoading, isError} = useSectorsListQuery();
  const sectorLabels = isError || !content.sectors || isLoading || isIdle ? [] :
    content.sectors.map((sector) => ({name: _.find(dataSectors.data, (item) => item.uid === sector || item.uid === sector.uid).name}
    ));
  const commonTags = useMemo(() => getCommonTags(content.tags), [content.tags]);
  const malwareFamiliesTags = useMemo(() => getMalwareFamilyTags(content.tags), [content.tags]);
  const history = useHistory();

  return (
    <Row className={'row__notification-content'}>
      <Row>
        <Col md={6}>
          <h5 className="subhead__events">Targeted sectors</h5>
          {
            sectorLabels.length > 0 ?
              <ColorTagInlineBadgeList key={`${content.name}+${type}`} items={sectorLabels} color={'dark-blue'}/> :
              <h5>NO TARGETED SECTORS AVAILABLE</h5>
          }
        </Col>
        <Col md={5}>
          <h5 className="subhead__events">Malware Families</h5>
          {
            malwareFamiliesTags.length > 0 ?
              <ColorTagInlineBadgeList items={malwareFamiliesTags}/> :
              <h5>NO MALWARE FAMILIES</h5>
          }
        </Col>
        <Col md={1} className={'justify-content-end col__notification-rules'}>
          {
            content.rules?.length > 0 &&
            <h5 className={'h5__notification-content-bdg'}>
              <Badge
                style={{width: "70px"}}
                color={content?.tlp?.tlp ? getTlpColor(content.tlp.tlp) : 'danger'}
                className={'outline'}
              >
                {content.rules?.length} RULE{content.rules?.length > 1 && 'S'}
              </Badge>
            </h5>
          }
          {
            content.report_id &&
            <h5 className={'h5__notification-content-bdg'}>
              <Badge
                style={{width: "70px"}}
                color={content?.tlp?.tlp ? getTlpColor(content.tlp.tlp) : 'danger'}
                className={'outline'}>
                REPORT
              </Badge>
            </h5>
          }
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <h5 className="subhead__events">Targeted countries</h5>
          {
            content.targeted_countries.length > 0 ? <CountryBadgeList countries={content.targeted_countries}/> :
              <h5>NO TARGETED COUNTRIES</h5>
          }
        </Col>
        <Col md={6}>
          <h5 className="subhead__events">Actors origin country</h5>
          <ActorCountryBadgeList actors={content.actors}/>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <h5 className="subhead__events">Tags</h5>
          <ColorTagInlineBadgeList items={commonTags}/>
        </Col>
        <Col md={6}>
          <h5 className="subhead__events">TLP</h5>
          <TLPBadge tlp={content.tlp.tlp}/>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            onClick={() => history.push(`${type === 'analysis' ? paths.analysisPath : paths.postPath}/${content.uid}`)}
            outline
            className={'btn__notification-content'}
          >Go to the {type} <RightIcon /></Button>
        </Col>
      </Row>
    </Row>
  )
}


AnalysisPostContent.propTypes = {
  content: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}


export default AnalysisPostContent;
