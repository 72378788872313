import React, {Fragment} from "react";
import {Badge} from "reactstrap";
import ReactCountryFlag from "react-country-flag";
import PropTypes from "prop-types";
import {getCountryNameOrCodeShort} from "../../helpers/country-data";
import {getBadgeClasses} from "../../helpers/badge";



const CountryBadge = ({countryCode, size = '', flagOnly = false, style = {}}) => {
  const classes = getBadgeClasses(size);

  if (!countryCode) {
    return <Fragment />
  }

  const countryName = getCountryNameOrCodeShort(countryCode);
  return (
    <Badge className={classes} color={'secondary'} key={countryCode} style={{...style, ...{}}}>
      <ReactCountryFlag countryCode={countryCode} svg/>
      {!flagOnly && <span className={'ml-1'}>{countryName}</span>}
    </Badge>
  )
}

CountryBadge.propTypes = {
  countryCode: PropTypes.string.isRequired,
  flagOnly: PropTypes.bool,
  className: PropTypes.string
}

export default CountryBadge;
