import React, {Fragment} from "react";
import ColorTagInlineBadge from "../components/badge/ColorTagInlineBadge";
import {ColorTagInlineBadgeList, CountryBadgeList} from "../components/badge/BadgeLists";
import PropTypes from "prop-types";


export const LimitCountriesBadges = ({countries, limit, size = '', flagOnly = false}) => {
  if (countries.length <= limit + 1) {
    return <CountryBadgeList countries={countries} flagOnly={flagOnly} />
  }

  return (
    <Fragment>
      <CountryBadgeList countries={countries.slice(0, limit)} flagOnly={flagOnly} /> <ColorTagInlineBadge color={'secondary'} tag={`+${countries.length - limit} MORE`} size={size} />
    </Fragment>
  )
}

LimitCountriesBadges.propTypes = {
  countries: PropTypes.array.isRequired,
  limit: PropTypes.number.isRequired,
  size: PropTypes.string,
  flagOnly: PropTypes.bool
}


export const LimitBadges = ({items, limit, size = '', color = 'secondary',onTagClick = () => {}}) => {
  if (items.length <= limit + 1) {
    return <ColorTagInlineBadgeList onTagClick = {onTagClick} items={items} color={color} />
  }

  return (
    <Fragment>
      <ColorTagInlineBadgeList onTagClick = {onTagClick} items={items.slice(0, limit)} color={color} /> <ColorTagInlineBadge  color={color}  tag={`+${items.length - limit} MORE`} size={'size'} />
    </Fragment>
  )
}

LimitBadges.propTypes = {
  items: PropTypes.array.isRequired,
  limit: PropTypes.number.isRequired,
  color: PropTypes.string,
  onTagClick:PropTypes.func
}


