import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {useHistory} from "react-router-dom";
import {useCurrentUserQuery} from "../../../../queries/CurrentUser";
import {useOrganizationsQuery} from "../../../../queries/Organizations";
import LoadingSpinner from "../../LoadingSpinner";
import ErrorHandler from "../../ErrorHandler";
import ControlledSelectWithTitle from "../ControlledSelectWithTitle";
import RegisteredInputField from "../RegisteredInputField";
import {TLP_LABELS} from "../../../helpers/tlp";
import {
  audienceCreatableByRole,
  getFormattedFeaturesList, getUserFeature,
  isFeatureExistsForCurrentUser,
  isModuleExistsForCurrentUser, userRoleModuleEnabled
} from "../../../helpers/permissions";
import paths from "../../../../config/paths";
import {getErrorMessageFromResponse} from "../../../helpers";
import {useAddApplicationMutation} from "../../../../mutations/AddApplication";
import {useModulesListQuery} from "../../../../queries/ModuleList";
import Permissions from "../../Permissions";
import RegisteredCheckBoxField from "../RegisteredCheckBoxField";
import _ from "lodash";
import {ADMIN_ROLE, CUSTOMER_ROLE} from "../../../helpers/user";
import env_const from "../../../../config/env_const";


const ApplicationForm = ({
                           defaultValues = {},
                           mutationFn = useAddApplicationMutation,
                           editUid = null,
                           editForm = false,
                           appOrganization = {}
                         }) => {
  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();
  const {
    data: dataOrganization,
    isIdle: isIdleOrganization,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
    error: errorOrganization
  } = useOrganizationsQuery();

  const {
    data: dataModules,
    isIdle: isIdleModules,
    isLoading: isLoadingModules,
    isError: isErrorModules
  } = useModulesListQuery();

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    watch,
    setValue
  } = useForm({defaultValues: defaultValues});
  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);
  const mutation = mutationFn();
  const [groups, setGroups] = useState([]);
  const history = useHistory();
  const [organizationModules, setOrganizationModules] = useState({});

  useEffect(() => {
    if (!isIdleModules && !isLoadingModules && !isErrorModules) {
      const org_modules = {}

      if (editForm) {
        org_modules[appOrganization.uid] = appOrganization.modules.map((m) => {
          m.selected = isModuleExistsForCurrentUser(defaultValues.modules, m.name);

          m.features.map((f) => {
            f.selected = isFeatureExistsForCurrentUser(defaultValues.modules, f.name);
            f.permission = getUserFeature(defaultValues.modules, f.name).permission;
            return f;
          })
          return m;
        });
      } else if (dataOrganization) {
        _.forEach(dataOrganization.data, (org) => {
          org_modules[org.uid] = org.modules.map((m) => {

            m.features.map((f) => {
              return f;
            })
            return m;
          });
        })
      }

      setOrganizationModules(org_modules);
    }
  }, [isIdleModules, isLoadingModules, isErrorModules, dataOrganization]);


  useEffect(() => {
    if (!editForm) {
      if (!_.isEmpty(organizationModules) && (watch('organization_id')?.value)) {
        setGroups(organizationModules[watch('organization_id')?.value]);
      }
    } else {
      setGroups(organizationModules[appOrganization.uid])
    }
  }, [watch('organization_id')?.value, appOrganization.uid, organizationModules])


  useEffect(() => {
    if (!isIdleModules && !isLoadingModules && !isErrorModules && !editForm) {
      let cdata = dataModules.data && dataModules.data.map((i) => {
        i.selected = editForm ? isModuleExistsForCurrentUser(defaultValues.modules, i.name) :
          !_.isNull(userRoleModuleEnabled(CUSTOMER_ROLE, i.name));
        if (i.features) {
          i.features = i.features.map((f) => {
            if (editForm) {
              f.selected = isFeatureExistsForCurrentUser(defaultValues.modules, f.name);
            }
            if (f.selected) {
              f.permission = getUserFeature(defaultValues.modules, f.name).permission;
            } else {
              f.selected = !_.isNull(userRoleModuleEnabled(CUSTOMER_ROLE, i.name, f.name));
              f.permission = userRoleModuleEnabled(CUSTOMER_ROLE, i.name, f.name);
            }
            return f;
          });
        }
        return i
      });
      setGroups(cdata);
    }
  }, [isIdleModules, isLoadingModules, isErrorModules]);

  useEffect(() => {
    if (!mutation.isIdle && mutation.isLoading && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }

    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        toast.success(`Application ${editForm ? 'updated' : 'created'} correctly, you will be redirected to the application pages.`);
        setTimeout(() => {
          toast.dismiss();
          history.push(paths.listApplicationPath);
        }, 2000);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
        setButtonUploadDisabled(false);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])

  if (isLoadingCurrentUser || isIdleCurrentUser || isIdleOrganization || isLoadingOrganization) {
    return <LoadingSpinner/>
  }

  if (isErrorOrganization) {
    return <ErrorHandler error={errorOrganization}/>
  }

  const tlpOptions = TLP_LABELS.slice(0, dataCurrentUser.data.tlp.id + 1).map((tlp, index) => ({
    value: index,
    label: tlp
  }));
  const organizationOptions = dataOrganization.data.map((org) => ({value: org.uid, label: org.name}));
  const audiencesOptions = audienceCreatableByRole(dataCurrentUser.role).map((audience) => ({
    value: audience,
    label: audience
  }));


  const onSubmit = (data) => {
    const params = {
      name: data.name,
      tlp: data.tlp.value,
      private_tlp: data.private_tlp.value,
      is_demo: data.is_demo
    }

    params.modules = getFormattedFeaturesList(groups);

    if (editForm) {
      params.uid = editUid;
    } else {
      params.organization_id = data.organization_id.value;
      params.audience = data.audience.value;
    }

    setButtonUploadDisabled(true);
    toast.loading(`Validating and ${editForm ? 'updating' : 'creating'} application.`);
    mutation.mutate(params);

  }

  const updateSelection = (data) => {
    setGroups(prev => data);
  }

  return (
    <Container>
      <Row className={'div__sticky-top'}>
        <Col md={9}>
          <h3 className="page-title">{editForm ? 'Update' : 'New'} Application</h3>
        </Col>
        <Col md={3} className={'justify-content-end d-flex'}>
          <Button outline onClick={() => {
            handleSubmit(onSubmit)();
          }} color={'success'} disabled={buttonUploadDisabled}>{editForm ? 'Update' : 'Add'}</Button>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <form className="form form--vertical">
            <Col md={6} xs={12}>
              <RegisteredInputField
                title={'Name'}
                name={'name'}
                register={register}
                errors={errors}
                rules={{required: 'The name is required'}}
              />


              {!editForm && <> <ControlledSelectWithTitle
                name={'audience'} title={'Audience'} control={control}
                rules={{required: 'You must specify an audience'}} defaultValue={[]}
                options={audiencesOptions} valueFn={(value) => value}
              />
                {errors.audience && <span className="form__form-group-error">{errors.audience.message}</span>}</>
              }
            </Col>

            <Col md={6} xs={12}>
              <ControlledSelectWithTitle
                name={'tlp'} title={'TLP'} control={control}
                rules={{required: 'You must specify the TLP of the application'}}
                options={tlpOptions}
                defaultValue={editForm ? defaultValues.tlp : tlpOptions[0]}
                valueFn={(value) => tlpOptions.find((c) => c.value === value?.value)}
              />
              {errors.tlp && <span className="form__form-group-error">{errors.tlp.message}</span>}

              <ControlledSelectWithTitle
                name={'private_tlp'}
                title={'TLP for private content'}
                control={control}
                rules={{required: true}}
                defaultValue={editForm ? defaultValues.private_tlp : tlpOptions[0]}
                options={tlpOptions}
                valueFn={(value) => tlpOptions.find((c) => c.value === value?.value)}
              />
              {errors.private_tlp && <span className="form__form-group-error">{errors.private_tlp.message}</span>}

              {!editForm && <>  <ControlledSelectWithTitle
                name={'organization_id'} title={'Organization'} control={control}
                rules={{required: 'You must specify an organization'}} defaultValue={organizationOptions[0]}
                options={organizationOptions}
                valueFn={(value) => organizationOptions.find((c) => c.value === value?.value)}
              />
                {errors.organization_id &&
                  <span className="form__form-group-error">{errors.organization_id.message}</span>} </>}
            </Col>
            <Col className="pt-2">
              <RegisteredCheckBoxField
                name={'is_demo'}
                value={watch('is_demo')}
                onChange={(value) => {
                }}
                defaultChecked={editForm ? defaultValues.is_demo : false}
                label={"Belong to a demo account"}
                register={register}
              />
            </Col>
            <p className="form__form-group-label pl-3">Modules </p>
            <Permissions
              register={register}
              watch={watch}
              groups={groups}
              setValue={setValue}
              onPermissionChange={updateSelection}
              role={editForm && defaultValues.audience === env_const.react_app_auth0_audience ? ADMIN_ROLE : 'APPLICATION'}
            />
          </form>
        </CardBody>
      </Card>
    </Container>
  )
}


export default ApplicationForm;
