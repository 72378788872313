import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const TypoSquattingScreenshotFetcher = (result_uid, protocol, accessToken) => {
  return axios.get(
    `${apiUrlInternal}/typosquatting_projects/assets/results/${result_uid}/screenshots/${protocol}`,
    addAuthToAxiosOpts(accessToken, {})
  ).then(res => res.data);
}

export const useTypoSquattingScreenshotQuery = (result_uid, protocol, enabled, fetcher = TypoSquattingScreenshotFetcher) => {
  return useQueryWithAuthentication(
    ['typosquatting-asset-result-screenshot', result_uid, protocol],
    (accessToken) => fetcher(result_uid, protocol, accessToken),
    {enabled: enabled, refetchOnWindowFocus: false}
  )
}
