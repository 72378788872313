import React, {useMemo} from "react";
import {Col, Row} from "reactstrap";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import PropTypes from "prop-types"
import {LimitBadges} from "../../../shared/helpers/LimitBadges";
import CopyIconToClipboard from "../../../shared/components/icons/CopyIconToClipboard";
import VirusTotalIndicatorLink from "../../../shared/components/icons/VirusTotalIndicatorLink";
import {getCommonTags, getMalwareFamilyTags} from "../../../shared/helpers";


const IndicatorItem = ({style, ioc, onRowClick}) => {
  const commonTags = useMemo(() => getCommonTags(ioc.tags), [ioc.tags]);
  const malwareFamilyTags = useMemo(() => getMalwareFamilyTags(ioc.tags), [ioc.tags]);

  return (
    <Row style={{...style, ...{cursor: 'pointer'}}} className={'align-items-center row--indicator'} onClick={onRowClick}>
      <Col md={1} xs={1} >
        <p>{ioc.type}</p>
      </Col>
      <Col md={4} xs={4}>
        <Row>
          <Col md={3}>
            <VirusTotalIndicatorLink value={ioc.value} />
            <CopyIconToClipboard value={ioc.value} size={21} />
          </Col>
          <Col md={9} className={'d-inline justify-content-start'}>
            <p className={'text-truncate'}>{ioc.value}</p>
          </Col>
        </Row>
      </Col>
      <Col md={2} xs={2}>
        <p>{formatDateUTCtoYearMonthDayTime(ioc.modified_dt)}</p>
      </Col>
      <Col md={1} xs={1}>
        <LimitBadges limit={1} items={ioc.actors} />
      </Col>
      <Col md={2} xs={2}>
        <LimitBadges limit={2} items={malwareFamilyTags} itemValue={(tag) => tag}/>
      </Col>
      <Col md={2} xs={2}>
        <LimitBadges limit={2} items={commonTags} itemValue={(tag) => tag}/>
      </Col>
    </Row>
  )
}

IndicatorItem.propTypes = {
  style: PropTypes.shape().isRequired,
  ioc: PropTypes.shape().isRequired,
  onRowClick: PropTypes.func.isRequired
}

export default IndicatorItem;
