import axios from "axios";
import PropTypes from "prop-types";
import { apiUrlInternal } from "../../config/api";
import { useQueryWithAuthentication } from "../index";
import { addAuthToAxiosOpts } from "../../config/queryopts";


const StatsFetcher = (accessToken, endpoint) => {
  return axios.get(`${apiUrlInternal}/stats${endpoint}`, addAuthToAxiosOpts(accessToken, {}))
    .then(res => res.data);
}

const useStatsQuery = (endpoint = null, fetcher = StatsFetcher) => {
  return useQueryWithAuthentication(['stats', endpoint], (accessToken) => fetcher(accessToken, endpoint))
}

export const useStatsRansomwareTrackerEventsByActors = (limit = null) => {
  const urlParams = new URLSearchParams();
  if (limit) {
    urlParams.set('limit', limit);
  }
  const endpoint = `/ransomware_tracker_events_by_actor?${urlParams.toString()}`;
  return useStatsQuery(endpoint);
}

export const useStatsRansomwareTrackerEventsByCountries = () => {
  const endpoint = '/ransomware_tracker_events_by_countries';
  return useStatsQuery(endpoint);
}

export const useStatsContentsByCountries = () => {
  const endpoint = '/contents_by_countries';
  return useStatsQuery(endpoint);
}

export const useStatsIndicatorsTypes = () => {
  const endpoint = '/indicators/by_type';
  return useStatsQuery(endpoint);
}

export const useStatsIndicatorsByDays = (daysback = 0) => {
  const endpoint = `/indicators/by_days?daysback=${daysback}`;
  return useStatsQuery(endpoint);
}

export const useStatsIndicatorsAll = () => {
  const endpoint = '/indicators/all';
  return useStatsQuery(endpoint);
}

export const useStatsActorsAll = () => {
  const endpoint = '/actors/all';
  return useStatsQuery(endpoint);
}

export const useStatsTagsMostCommon = (limit = 0) => {
  const endpoint = `/tags/most_common?limit=${limit}`;
  return useStatsQuery(endpoint);
}

export const useStatsSectorsMostTargeted = (limit = 0) => {
  const endpoint = `/sectors/most_targeted?limit=${limit}`;
  return useStatsQuery(endpoint);
}

export const useStatsSandboxTasksAll = () => {
  const endpoint = '/sandbox/tasks/all';
  return useStatsQuery(endpoint);
}

export const useStatsSandboxTasksByDays = (daysback = 0) => {
  const endpoint = `/sandbox/tasks/by_days?daysback=${daysback}`;
  return useStatsQuery(endpoint);
}

export const useStatsActorsByRecentActivities = () => {
  const endpoint = '/actors/by_recent_activities';
  return useStatsQuery(endpoint);
}

export const useStatsMalwareFamiliesCommon = () => {
  const endpoint = '/malware_families/common';
  return useStatsQuery(endpoint);
}

export const useStatsMalwareFamiliesContextsByMonth = (uid) => {
  const endpoint = `/malware_families/${uid}/contents/by_month`;
  return useStatsQuery(endpoint);
}

export const useStatsActorsContextsByDay = (uid) => {
  const endpoint = `/actors/${uid}/contexts/by_day`;
  return useStatsQuery(endpoint);
}


useStatsQuery.propTypes = {
  endpoint: PropTypes.string.isRequired,
};
