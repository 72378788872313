import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


const TicketFetcher = (accessToken, endpoint) => {
    return axios.get(`${apiUrlInternal}/tickets${endpoint}`, addAuthToAxiosOpts(accessToken, {}))
      .then(res => res.data);
}

const useTicketQuery = (
  endpoint,
  options,
  fetcher = TicketFetcher
) => {
    return useQueryWithAuthentication(['tickets', endpoint],(accessToken) => fetcher(accessToken, endpoint), options)
}

export const useGetTicketMessages = (uid) => {
    return useTicketQuery(`/${uid}/messages`, {refetchInterval: 20000});
}

export const useTicketsQuery = (uid = null) => {
    const endpoint = (uid) ? `/${uid}` : '';
    return useTicketQuery(endpoint);
}

export const useTicketsUsersQuery = (enabled = false) => {
    return useTicketQuery('/assign/users', {enabled: enabled});
}
