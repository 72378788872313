import React from "react";
import ApplicationForm from "../../shared/components/form/application-form/ApplicationForm";
import {PLATFORM_FEATURE_APPLICATIONS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";

const ApplicationAdd = () => {
  return (
    <Page
      feature={PLATFORM_FEATURE_APPLICATIONS}
      permission={PLATFORM_PERMISSION_EDITOR}
    >
      <ApplicationForm/>
    </Page>
  )
}

export default ApplicationAdd;
