import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Button, Popover, PopoverBody, PopoverHeader} from "reactstrap";
import DownloadOutlineIcon from "mdi-react/DownloadOutlineIcon";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../../shared/helpers";
import {useOfflineUpdateStartMutation} from "../../../mutations/OfflineUpdateStart";
import {useOfflineUpdatesIsUpdatedQuery} from "../../../queries/OfflineUpdates";
import {useQueryClient} from "react-query";


const TopBarUpdatesAlertTooltip = () => {
  const [IsOpenedPopover, setIsOpenedPopover] = useState(false);
  const handleOpenPopover = useCallback(() => setIsOpenedPopover(!IsOpenedPopover), [IsOpenedPopover]);
  const [IsUpdated, setIsUpdated] = useState(true);
  const {data, isIdle, isLoading, isError} = useOfflineUpdatesIsUpdatedQuery();
  const mutation = useOfflineUpdateStartMutation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setIsUpdated(data.data.is_updated);
    }
  }, [isIdle, isLoading, isError, data?.data?.is_updated]);

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        queryClient.invalidateQueries(['offile-update', '/is_updated']);
        toast.success('Download start. Follow the status in the "Download updates" section in your profile');
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])

  if (isIdle || isLoading || isError || IsUpdated) {
    return <Fragment/>
  }


  return (
    <Fragment>
      <Button
        id={"PopoverKeywordAlert"}
        onClick={handleOpenPopover}
        outline
        color={'warning'}
        className={"button-tooltip-alert"}
      >
        <DownloadOutlineIcon
          size={24}
          style={Object.assign({}, {fill: '#f6da6e', padding: '2px'})}
        />
      </Button>
      <Popover
        placement="bottom"
        isOpen={IsOpenedPopover}
        target={"PopoverKeywordAlert"}
        toggle={handleOpenPopover}
        innerClassName={'popover-warning'}
      >
        <PopoverHeader>UPDATES</PopoverHeader>
        <PopoverBody>
          Start the download of the latest updates <a href={""} onClick={(e) => {
          e.preventDefault();
          toast.loading('Start preparing the download');
          mutation.mutate();
        }}>here</a>.
        </PopoverBody>
      </Popover>
    </Fragment>
  )
}


export default TopBarUpdatesAlertTooltip;
