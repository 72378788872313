import {useLocation} from "react-router-dom";

const _ = require('lodash')


// A custom hook that builds on useLocation to parse the query string for you.
export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const paramsToObject = (entries) => {
  const result = {}
  for (const [key, value] of entries) {
    if (result[key]) {
      result[key].push(value)
    } else {
      result[key] = [value];
    }
  }

  for (const [key, value] of Object.entries(result)) {
    if (value.length === 1) {
      result[key] = value[0];
    }
  }

  return result;
}

export const isParamValid = (param, requiredParam) => param !== null && requiredParam.includes(param);

export const areParamsValid = (params, requiredParams) => {
  for (const [key, value] of Object.entries(requiredParams)) {
    if (!isParamValid(params.get(key), value)) {
      return false;
    }
  }

  return true;
}

export const fixHistoryURL = (params, requiredParams, history) => {
  const path = history.location.pathname;
  let query = "?";

  _.forEach(requiredParams, (value, key) => {
    const paramValue = params.get(key);
    if (isParamValid(paramValue, value)) {
      query += `${key}=${paramValue}&`;
    } else {
      query += `${key}=${requiredParams[key][0]}&`;
      params.set(key, requiredParams[key][0]);
    }
  })

  query = query.slice(0, -1);
  history.replace(path + query);
}
