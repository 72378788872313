import React from "react";
import {Card, CardBody} from "reactstrap";
import ControlledFilepond from "../../../shared/components/form/ControlledFilepond";
import PropTypes from "prop-types";


const GeneAnalysisUploadForm = ({control}) => {
  return (
    <Card>
      <CardBody>
        <ControlledFilepond
          control={control}
          name={'sample'}
        />
      </CardBody>
    </Card>
  )
}

GeneAnalysisUploadForm.propsType = {
  control: PropTypes.shape().isRequired,
}

export default GeneAnalysisUploadForm;
