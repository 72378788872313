import {Button, Col, Row} from "reactstrap";
import RegisteredInputField from "../../../../shared/components/form/RegisteredInputField";
import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse, upperCaseFirstLetter} from "../../../../shared/helpers";
import {useQueryClient} from "react-query";
import PropTypes from "prop-types";
import _ from "lodash";
import {getPattern} from "../../../../shared/helpers/validators";
import DeleteButtonModal from "../../../../shared/components/modal/DeleteButtonModal";
import SubmitButtonModal from "../../../../shared/components/modal/SubmitButtonModal";
import CloseIconButtonModal from "../../../../shared/components/modal/CloseIconButtonModal";
import {useUpdateProjectTypoSquattingAssets} from "../../../../mutations/UpdateProjectTypoSquatting";
import {useDeleteProjectTypoSquattingAssets} from "../../../../mutations/DeleteProjectTypoSquattingAsset";
import {useDeleteTypoSquattingProject} from "../../../../mutations/DeleteTypoSquattingProject";

const AddAssetForm = ({
  project,
  assets,
  all_assets,
  organization,
  assetType,
  allTypoSquattingTypes,
  buttonsDisabled = false,
  messages,
  deleteHandler,
  submitHandler,
  intelligenceBlock,
  unblockDate,
  submit,
  submitAll,
  onlyAddition
}) => {
  const addTypoSquattingAssetMutation = useUpdateProjectTypoSquattingAssets();
  const deleteTypoSquattingMutation = useDeleteProjectTypoSquattingAssets();
  const deleteTypoSquattingProjectMutation = useDeleteTypoSquattingProject();

  const queryClient = useQueryClient();

  const deleteModalPostScriptum = "Deleting an entry will make you UNABLE to further EDIT and DELETE assets and UNABLE to ADD, EDIT and DELETE engines until:"
  const submitModalPostScriptum = deleteModalPostScriptum.replace('Deleting', 'Editing')
  const deleteProjectModalPostScriptum = deleteModalPostScriptum.replace('entry', 'engine with active entries')

  const [allowAssetAddition, setAllowAssetAddition] = useState(false)
  const [updateForm, setUpdateForm] = useState(false)
  const {register, handleSubmit, formState: {errors}, control, watch, setValue} = useForm({
    defaultValues: {assets: assets.map((val) => ({value: val.value, uid: val.uid}))}
  });

  const {fields: fieldsAssets, append: appendAssets, remove: removeAssets} = useFieldArray(
    {control, name: "assets"}
  );

  const [newAssetsToAdd, setNewAssetsToAdd] = useState(fieldsAssets)

  useEffect(() => {
    if (submitAll.toSubmit?.length && submitAll.toSubmitType.includes(assetType)) {
      setUpdateForm(true)
    }
  }, [submitAll])
  const onAdd = () => {
    appendAssets({
      value: '',
      isNew: true
    })

    setAllowAssetAddition(true)
    setNewAssetsToAdd(fieldsAssets)
  }
  const onSubmit = () => {
    let submissionAllowed = true
    all_assets.map((assets, index) => {
      assets.map((asset) => {
        asset.type = allTypoSquattingTypes[index]
      })
    })
    _.forEach(all_assets.flat(), (param) => {
      const passesRegex = new RegExp(getPattern('domain')).test(param?.value)
      if (!passesRegex) {
        submissionAllowed = false
      }
    })
    if (!submissionAllowed) return;
    const newAssets = all_assets.flat().map((val) => val.isNew);
    const paramsAsset = all_assets.flat().map((val, index) => ({
      value: val.value,
      uid: val.isNew ? null : all_assets.flat()[index]?.uid
    }));
    _.forEach(paramsAsset, (param) => {
      if (_.isNull(param.uid) || _.isUndefined(param.uid)) {
        delete param.uid;
      }
    })

    if (_.max(_.values(_.countBy(paramsAsset, (r) => r.value))) > 1) {
      toast.error("There are some duplicated values");
      return;
    }

    if (newAssets.length === 0) {
      toast.error('Insert new asset');
    } else {
      addTypoSquattingAssetMutation.mutate({
        params: {assets: paramsAsset},
        projectUid: project.uid
      })
      toast.loading('Updating settings');
    }
    setAllowAssetAddition(newAssetsToAdd.some((val) => val?.isNew))
  }
  useEffect(() => {
    if (updateForm) {
      _.forEach(submitAll.toSubmit, (res) => {
        const entry = _.filter(watch('assets'), (e) => e.value === res.value)[0];
        if (entry) {
          entry.uid = res.uid;
          setValue(
            `assets.${_.findIndex(watch('assets'), (e) => e.value === res.value)}`,
            entry
          );
        }
      });

      _.forEach(watch('assets'), (entry, index) => {
        entry.isNew = false;
        setValue(`assets.${index}`, entry);
      })

      addTypoSquattingAssetMutation.reset();
      setUpdateForm(false)
    }
  }, [updateForm])

  useEffect(() => {
    if (!addTypoSquattingAssetMutation.isIdle && !addTypoSquattingAssetMutation.isLoading) {
      toast.dismiss();
      if (addTypoSquattingAssetMutation.isSuccess) {
        toast.success('Assets updated successfully');
        queryClient.invalidateQueries(['project-typosquatting', organization.uid]);
        queryClient.invalidateQueries(['users-me'])
        _.forEach(addTypoSquattingAssetMutation.data.data, (res) => {
          const entry = _.filter(watch('assets'), (e) => e.value === res.value)[0];
          if (entry) {
            entry.uid = res.uid;
            setValue(
              `assets.${_.findIndex(watch('assets'), (e) => e.value === res.value)}`,
              entry
            );
          }
        });

        _.forEach(watch('assets'), (entry, index) => {
          entry.isNew = false;
          setValue(`assets.${index}`, entry);
        })

        submit(addTypoSquattingAssetMutation.data.data)
      } else if (addTypoSquattingAssetMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(addTypoSquattingAssetMutation)}.`);
      }
      addTypoSquattingAssetMutation.reset();
    }
  }, [
    addTypoSquattingAssetMutation.isIdle,
    addTypoSquattingAssetMutation.isLoading,
    addTypoSquattingAssetMutation.isError,
    addTypoSquattingAssetMutation.isSuccess,
    addTypoSquattingAssetMutation.error
  ])

  useEffect(() => {
    if (!deleteTypoSquattingMutation.isIdle && !deleteTypoSquattingMutation.isLoading) {
      toast.dismiss();
      if (deleteTypoSquattingMutation.isSuccess) {
        toast.success('Asset deleted successfully');
        queryClient.invalidateQueries(['project-typosquatting', organization.uid]);
        queryClient.invalidateQueries(['users-me'])
      } else if (deleteTypoSquattingMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(deleteTypoSquattingMutation)}.`);
      }
      deleteTypoSquattingMutation.reset();
    }
  }, [
    deleteTypoSquattingMutation.isIdle,
    deleteTypoSquattingMutation.isLoading,
    deleteTypoSquattingMutation.isError,
    deleteTypoSquattingMutation.isSuccess,
    deleteTypoSquattingMutation.error
  ])

  useEffect(() => {
    if (!deleteTypoSquattingProjectMutation.isIdle && !deleteTypoSquattingProjectMutation.isLoading) {
      toast.dismiss();
      if (deleteTypoSquattingProjectMutation.isSuccess) {
        toast.success('Engine deleted successfully');
        queryClient.invalidateQueries(['project-typosquatting', organization.uid]);
      } else if (deleteTypoSquattingProjectMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(deleteTypoSquattingProjectMutation)}.`);
      }
      deleteTypoSquattingProjectMutation.reset();
    }
  }, [
    deleteTypoSquattingProjectMutation.isIdle,
    deleteTypoSquattingProjectMutation.isLoading,
    deleteTypoSquattingProjectMutation.isError,
    deleteTypoSquattingProjectMutation.isSuccess,
    deleteTypoSquattingProjectMutation.error
  ])

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit(onSubmit)}>
      {
        fieldsAssets.length > 0 ?
          fieldsAssets.map((item, index) =>
            <Row className={'w-100'} key={item.id}>
              <Col md={1}>
                {
                  buttonsDisabled || !(intelligenceBlock && (_.isUndefined(watch(`assets.${index}`).isNew) || _.isNull(watch(`assets.${index}`).isNew) || !watch(`assets.${index}`).isNew)) &&
                  <CloseIconButtonModal
                    onClick={() => {
                      const rm = watch(`assets.${index}`);
                      if (rm.uid) deleteHandler(index, assetType, true)
                      else {
                        removeAssets(index);
                        deleteHandler(index, assetType)
                      }
                    }}
                    onDelete={() => {
                      const rm = watch(`assets.${index}`);
                      removeAssets(index);
                      const tmpAssetsToAdd = newAssetsToAdd
                      tmpAssetsToAdd.splice(index, 1)
                      setNewAssetsToAdd([...tmpAssetsToAdd])
                      setAllowAssetAddition(newAssetsToAdd.some((val) => val?.isNew))
                      if (_.isUndefined(rm.isNew) || _.isNull(rm.isNew) || !rm.isNew) {
                        toast.loading('Deleting asset')
                        deleteTypoSquattingMutation.mutate({
                          rmUid: rm.uid
                        });
                      }
                      deleteHandler(index, assetType)
                    }}
                    title={"Deletion Summary"}
                    messages={messages}
                    unblockDate={unblockDate}
                    postScriptum={deleteModalPostScriptum}
                  />
                }
              </Col>
              <Col md={11}>
                <RegisteredInputField
                  title={`${upperCaseFirstLetter(assetType)} #${index + 1}`}
                  name={`assets.${index}.value`}
                  register={register}
                  errors={errors}
                  disabled={intelligenceBlock && (_.isUndefined(watch(`assets.${index}`).isNew) || _.isNull(watch(`assets.${index}`).isNew) || !watch(`assets.${index}`).isNew)}
                  rules={{
                    required: 'The value is required',
                    validate: (v) => new RegExp(getPattern(assetType)).test(v) || `Insert a valid ${assetType}`
                  }}
                />
                {
                  watch(`assets.${index}.value`).includes('.') &&
                  watch(`assets.${index}.value`).split('.')[0].length < 5 &&
                    <span
                       className="form__form-group-error d-inline"
                       style={{marginLeft: '140px'}}
                    >
                      A short domain can produce a large amount of results. This can increase the number of false positive.
                    </span>
                }
              </Col>
            </Row>)
          : <h5 style={{paddingLeft: '10px'}}>Add some new {assetType}s to monitor</h5>
      }

      <Row className={'justify-content-end w-100'}>
        <Button outline disabled={buttonsDisabled} color={'success'}
                onClick={() => onAdd()}>Add {upperCaseFirstLetter(assetType)}</Button>
      </Row>

      <Row className={'w-100'}>
        <Col md={6}>
          <DeleteButtonModal
            onDelete={() => {
              toast.loading(`Deleting engine ${project.name}`);
              deleteTypoSquattingProjectMutation.mutate(project.uid);
            }}
            disabled={buttonsDisabled || intelligenceBlock}
            message={<p>Do you want to delete the engine <br/> <b>{project.name}?</b></p>}
            postScriptum={project.assets.length ? deleteProjectModalPostScriptum : null}
            unblockDate={unblockDate}
          />
        </Col>
        <Col md={6} className={'p-0'}>
          <SubmitButtonModal
            color="success" outline className={'float-right'}
            disabled={buttonsDisabled || (intelligenceBlock && !allowAssetAddition)}
            intelligenceBlock={intelligenceBlock}
            onSubmit={handleSubmit(onSubmit)}
            onClick={submitHandler}
            title={"Modifications Summary"}
            messages={messages}
            unblockDate={unblockDate}
            postScriptum={!onlyAddition ? submitModalPostScriptum : null}>
            Add to Engine
          </SubmitButtonModal>
        </Col>
      </Row>
    </form>
  )
}


AddAssetForm.propTypes = {
  project: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  assetType: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  buttonsDisabled: PropTypes.bool,
  messages: PropTypes.array,
  deleteHandler: PropTypes.func,
  submitHandler: PropTypes.func,
  intelligenceBlock: PropTypes.bool,
  unblockDate: PropTypes.string,
  submit: PropTypes.func,
  submitAll: PropTypes.array,
  onlyAddition: PropTypes.bool
}

export default AddAssetForm;
