import React from "react";
import {Card, CardBody} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import paths from "../../../config/paths";
import ThemedClampLines from "../clamp-lines/ThemedClampLines";
import classNames from "classnames";
import {formatDateUTCtoYearMonthDay} from "../../helpers/date";
import PropTypes from "prop-types";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import DeleteIconModal from "../modal/DeleteIconModal";
import BugIcon from "mdi-react/BugIcon";
import {useDeleteMalwareFamily} from "../../../mutations/DeleteMalwareFamily";

const MalwareFamilyDetailCard = ({
  malwareFamily,
  withLink = false,
  withTruncateText = false,
  withDecoration = false,
  withEditableButton = false,
  customOnClick = null,
  style = {},
  section = "Commands",
}) => {
  const classes = classNames({
    "card__with_background_icon": true,
    "card__with_background_icon_decoration": withDecoration
  })
  const history = useHistory();
  const mutation = useDeleteMalwareFamily();

  return (
    <Card style={style} className={classes}>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>
            { !customOnClick &&
              (withLink ?
                <Link
                  to={`${paths.malwareFamilyBasePath}/${malwareFamily.uid}`}>{malwareFamily.name}</Link> : malwareFamily.name)
            }
            {
              customOnClick &&
                <div className={'cursor'} onClick={() => customOnClick({type: 'malware_families',id: malwareFamily.uid})}>{malwareFamily.name}</div>
            }
            {
              malwareFamily.first_seen &&
              <p className={'subhead'}>First seen <b>{formatDateUTCtoYearMonthDay(malwareFamily.first_seen)}</b></p>
            }
          </h4>
        </div>
        {
          withTruncateText ?
            (
              <p className={'description'}>
                <ThemedClampLines
                  text={malwareFamily.description ? malwareFamily.description : ''}/>
              </p>
            ) : (<p>{malwareFamily.description}</p>)
        }
        <BugIcon/>
        {
          malwareFamily.commandsCount &&
          <p className={'subhead d-inline'}>Associated {section}: {malwareFamily.commandsCount}</p>
        }
        {
          withEditableButton &&
          <div className="actionBar">
            <PencilOutlineIcon onClick={(e) => {
              e.stopPropagation();
              history.push(`${paths.malwareFamilyBasePath}/edit/${malwareFamily.uid}`);
            }} className="border-hover-white mr-3 editIcon"/>
            <DeleteIconModal
              onDelete={() => mutation.mutate(malwareFamily.name)}
              message={'Do you really want to delete the malware family?'}
              classNames={'border-hover-white mr-3 editIcon m-0'}
            />
          </div>
        }
      </CardBody>
    </Card>
  )

}

MalwareFamilyDetailCard.prototype = {
  actor: PropTypes.shape({}).isRequired,
  style: PropTypes.shape({}).isRequired,
  withLink: PropTypes.bool,
  withTruncateText: PropTypes.bool,
  withDecoration: PropTypes.bool,
  withEditableButton: PropTypes.bool,
  customOnClick: PropTypes.func,
  commandsCount: PropTypes.bool,
}

export default MalwareFamilyDetailCard;