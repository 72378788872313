import Collapse from "../../../../shared/components/Collapse";
import React from "react";
import PropTypes from "prop-types";
import HighlightSpan from "../../../../shared/components/span/HighlightSpan";
import TypoSquattingProjectFormCollapseContent from "./TypoSquattingProjectFormCollapseContent";


const TypoSquattingProjectFormCollapse = ({project, organization}) =>
  <Collapse
    title={
      <>Engine "<HighlightSpan text={project.name}/>".
        You inserted {project.assets.length}/{project.max_assets_to_monitor} assets to monitor.
      </>
    }
    className={"with-shadow"}
  >
    <TypoSquattingProjectFormCollapseContent
      project={project}
      assets={project.assets}
      organization={organization}
    />
  </Collapse>


TypoSquattingProjectFormCollapse.propTypes = {
  project: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired
}


export default TypoSquattingProjectFormCollapse;
