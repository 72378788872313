import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SortIcon from 'mdi-react/SortIcon';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';

const Header = ({
  column,
  isSortable,
  onClick,
  onIconClick
}) => {
  const [toggled, setToggled] = useState(false);
  const [value, setValue] = useState("")
  useEffect(() => {
    setValue(column.id)
  }, [column.id])

  const toggleOnClick = (target) => {
    if (target) {
      setToggled((current) => !current);
      if (!toggled) {
        onClick(target);
      }
    }
  };

  const orderOnClick = (order, order_by) => {
    onClick(order_by);
    onIconClick(order);
  };

  const columnHasSort = (isSortable || onClick) && !column.disableSort;

  return (
    <span className="react-table__column-header">
    <span style={{marginRight: '10px'}}>
      {column.render('Header')}
    </span>
      {
        columnHasSort &&
        <Sorting
          column={column}
          onClick={!isSortable ? orderOnClick : undefined}
          orderBy={value}
        />
      }
  </span>
  );
};

Header.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    render: PropTypes.func,
    disableSort: PropTypes.bool,
  }).isRequired,
  isSortable: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

const Sorting = ({
  column,
  onClick,
  orderBy,
}) => {
  const [toggled, setToggled] = useState(false);
  const toggleOnClick = (target) => {
    if (target) {
      setToggled((current) => !current);
      onClick(target, orderBy);
    }
  };

  return (
    <span className="react-table__column-header sortable">
      {onClick ?
        <>
          {
            toggled ?
              <span onClick={() => toggleOnClick('asc', orderBy)}><SortDescendingIcon/></span>
              : <span onClick={() => toggleOnClick('desc', orderBy)}><SortAscendingIcon/></span>
          }
        </>
        :
        <>
          {column.isSortedDesc === undefined ? (
            <SortIcon/>
          ) : (
            <span>
            {column.isSortedDesc
              ? <SortDescendingIcon/>
              : <SortAscendingIcon/>
            }
            </span>
          )}
        </>
      }
  </span>
  );
};

Sorting.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.string,
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }).isRequired,
};

const getStylesResizable = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];

const ReactTableHeader = ({
  headerGroups,
  isResizable,
  isSortable,
  onHeaderClick,
  onHeaderIconClick
}) => {
  const headerPropsSortable = (props, { column }) => {
    if (column.getSortByToggleProps && isSortable) {
      return column.getSortByToggleProps;
    }
    return {column};
  };
  const headerPropsResize = (props, {column}) => {
    if (column.getResizerProps) {
      return getStylesResizable(props, column.align);
    }
    return {column};
  };

  return (
    <thead className="thead th">
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()} className="react-table thead tr">
        {headerGroup.headers.map(column => (
          <th
            {...column.getHeaderProps(headerPropsSortable)}
            {...column.getHeaderProps(headerPropsResize)}
            {...column.getHeaderProps()}
          >
            <Header
              column={column}
              isSortable={isSortable}
              onClick={onHeaderClick}
              onIconClick={onHeaderIconClick}
            />
            {isResizable && (
              <div {...column.getResizerProps()} className={`resizer ${column.isResizing && 'isResizing'}`}/>
            )}
          </th>
        ))}
      </tr>
    ))}
    </thead>
  );
};

ReactTableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.shape()),
    getHeaderGroupProps: PropTypes.func,
    getFooterGroupProps: PropTypes.func,
  })).isRequired,
  isResizable: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
};

export default ReactTableHeader;
