import React, {useState} from "react";
import {Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import MenuEntryWithCopyIcon from "../../../../../../shared/components/MenuEntryWithCopyIcon";


const RelatingIndicatorByFileCard = ({by_file, indicator}) => {
  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const navTitles = by_file.map(
    (rel) => _.sortBy(rel.indicators, (ioc) => ioc.type)[0].value
  );

  return (
    <Card className={'h-auto'}>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">RELATED HASHES TO FILENAME: {indicator}</h4>
        </div>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                {
                  navTitles.map((title, index) => {
                    return <NavLink
                      className={classnames({active: activeTab === index})}
                      onClick={() => toggleTab(index)}
                      key={index}
                    >{title}</NavLink>
                  }

                  )
                }
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              {
                by_file.map((rel, index) =>
                  <TabPane tabId={0} >
                    <div className="custom_card__container m-0 p-0" key={index}>
                    {_.sortBy(rel.indicators, (ioc) => ioc.type).map((ioc) => <MenuEntryWithCopyIcon title={ioc.type.toUpperCase()} value={ioc.value} />)}
                    </div>
                  </TabPane>
                )
              }
            </TabContent>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}


RelatingIndicatorByFileCard.propTypes = {
  by_file: PropTypes.array.isRequired,
  indicator: PropTypes.string.isRequired
}


export default RelatingIndicatorByFileCard;
