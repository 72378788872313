import React, {useState,Fragment} from 'react';
import {Col, Row, Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import {useSandboxTaskDetailsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import TaskScoreSmallGaugeChart from "../../../shared/components/charts/withdata/TaskScoreSmallGaugeChart";
import SandboxTaskSignaturesTable from "./SandboxTaskSignaturesTable";
import {WithDetailsModal} from "../../../shared/hocs/WithDetailsModal";
import CarouselGallery from '../../../shared/components/carousel/CarouselGallery';
import SandboxScreenshotImage from "./SandboxScreenshotImage";
import VirusTotalIndicatorLink from "../../../shared/components/icons/VirusTotalIndicatorLink";
import {bytesToSizeOrNA, getElementPropertyOrDefault} from "../../../shared/helpers";
import MenuEntry from "../../../shared/components/MenuEntry";
import MenuEntryWithCopyIcon from "../../../shared/components/MenuEntryWithCopyIcon";


import moment from "moment";
const SandboxTaskSignaturesTableWithDetailsModal = WithDetailsModal(SandboxTaskSignaturesTable);


const SandboxTaskReportSection = ({taskId}) => {
    const { data, isIdle, isLoading, isError,error } = useSandboxTaskDetailsQuery(taskId);
    const [ isSignatureDetailOpen, setIsSignatureDetailOpen ] = useState(false);

    if (isLoading || isIdle) {
        return <LoadingSpinner />
    }

    if (isError || !data.data) {
        return <ErrorHandler error={error} />
    }

    const defaultNullFieldValue = '-'
    return (
        <Fragment>
            <Row>
                <Col md={9}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={4}>
                                    <div  className="custom_card__container">
                                        <MenuEntry title={'FILENAME'} value={getElementPropertyOrDefault(data.data, 'filename', defaultNullFieldValue)} />
                                        <MenuEntry title={'FILESIZE'} value={bytesToSizeOrNA(data.data.filesize)} />
                                        <MenuEntry title={'CREATION DATE'} value={
                                            data.data.created_dt ? moment.utc(data.data.created_dt).local().format("MM/DD/YYYY hh:mm:ss A") : defaultNullFieldValue
                                        } />
                                    </div>
                                </Col>
                                <Col md={7} xs={6}>
                                    <div  className="custom_card__container">
                                        <MenuEntryWithCopyIcon title={'MD5'} value={data.data.md5} />
                                        <MenuEntryWithCopyIcon title={'SHA1'} value={data.data.sha1} />
                                        <MenuEntryWithCopyIcon title={'SHA256'} value={data.data.sha256} />
                                    </div>
                                </Col>
                                <Col md={1} xs={2} className={'d-flex justify-content-end'}>
                                    {data.data.sha256 && <VirusTotalIndicatorLink value={data.data.sha256} />}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={3}>
                    <TaskScoreSmallGaugeChart taskId={taskId} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="react-table__wrapper">
                                <div className="card__title">
                                    <h5 className="bold-text">Signatures</h5>
                                </div>
                            </div>
                            <SandboxTaskSignaturesTableWithDetailsModal
                                isOpen={isSignatureDetailOpen}
                                setIsDetailOpen={setIsSignatureDetailOpen}
                                title={"Signature detail"}
                                taskId = {taskId}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card className="card--not-full-height">
                        <CardBody>
                            <div className="react-table__wrapper">
                                <div className="card__title">
                                    <h5 className="bold-text">Screenshots</h5>
                                </div>
                            </div>
                            {(data.data.report && data.data.report.screenshots && data.data.report.screenshots.length > 0) &&
                            <CarouselGallery>
                                {data.data.report.screenshots.map(screenshot => <SandboxScreenshotImage taskId={taskId} imageIndex={screenshot}/>)}
                            </CarouselGallery>}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}


SandboxTaskReportSection.propTypes = {
    taskId: PropTypes.string.isRequired,
}


export default SandboxTaskReportSection;
