import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteProjectResourceMonitors = (rmUid, accessToken) => {
  return axios.delete(
    `${apiUrlInternal}/resource_monitor_projects/resource_monitors/${rmUid}`,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data );
}

export const useDeleteProjectResourceMonitors = (fetcher = DeleteProjectResourceMonitors) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-project-resource-monitors'],
    (accessToken, {rmUid}) => fetcher(rmUid, accessToken),
    {onSuccess: () => queryClient.invalidateQueries('delete-project-resource-monitors')}
  )
}
