import React from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";

const ScreenshotBase64Card = ({
  base64,
  prefix = "data:image/png;base64,",
  alt= "Screenshot"
}) =>{
  
  return (<Card>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>Screenshot</h4>
      </div>
      <div className={'mt-3'}>
        <img src={`${prefix}${base64}`} alt={alt} />
      </div>
    </CardBody>
  </Card>);
}

ScreenshotBase64Card.propTypes = {
  base64: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  alt: PropTypes.string,
}

export default ScreenshotBase64Card;
