import React from 'react';
import PropTypes from "prop-types";
import TableWithDataHookPagination from "../table/TableWithDataHookPagination";
import {
  useSearchRansomwareTrackerEventsDictPagination
} from "../../../queries/SearchRansomwareTrackerEventsDictPagination"
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import {LimitCountriesBadges} from "../../helpers/LimitBadges";
import {getCountryCode} from "../../helpers/country-data";
import RansomwareDetailsModal from "../modal/RansomwareDetailsModal";

const RANSOMWARE_TABLE_COLUMNS = [
  {Header: 'Victim', accessor: "victim"},
  {
    Header: 'Victim Country',
    accessor: 'victim_country',
    Cell: ({value}) => <LimitCountriesBadges limit={2} countries={[getCountryCode(value)]}/>
  },
  {
    Header: 'Description', id: 'description', accessor: 'description', Cell: ({value}) =>
      <div style={{maxWidth: '600px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
        {value}
      </div>
  },
  {
    Header: 'Victim Url',
    accessor: 'website',
    Cell: ({value}) => <a href={value !== 'N/D' ? value : ""}>{value !== 'N/D' ? value : ""}</a>
  },
  {Header: 'Date', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
  {
    Header: 'Details',
    id: 'full-description',
    accessor: 'description',
    Cell: ({value}) => <RansomwareDetailsModal value={value}></RansomwareDetailsModal>
  }
];

const RANSOMWARE_TABLE_CONFIG = {
  withPagination: true,
}

const RansomwareAttacksTableWithPagination = ({filters, count}) => {
  return (
    <div className="tabs tabs--bordered-bottom">
      <TableWithDataHookPagination
        tableColumns={RANSOMWARE_TABLE_COLUMNS}
        tableConfig={RANSOMWARE_TABLE_CONFIG}
        useDataQuery={useSearchRansomwareTrackerEventsDictPagination}
        queryParams={{...filters}}
        totalItems={count}
      />
    </div>
  )
}

RansomwareAttacksTableWithPagination.propTypes = {
  filters: PropTypes.object.isRequired,
}

export default RansomwareAttacksTableWithPagination;
