import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


const _ = require('lodash');


export const OfflineUpdateStartFetcher = (accessToken) => {
  return axios.post(
    `${apiUrlInternal}/offline_update/start`, {}, addAuthToFetchOpts(accessToken, {})
  ).then(res => res);
}

export const useOfflineUpdateStartMutation = (fetcher = OfflineUpdateStartFetcher) => {
  const queryClient = useQueryClient()
  return useMutationWithAuthentication(
    ['offline-update-start'],
    (accessToken) => fetcher(accessToken),
    {onSuccess: () => {
        queryClient.invalidateQueries('offline-update-start');
        queryClient.invalidateQueries(['offline-update', '/status']);
      }}
  )
}

