import React from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useHistory, useParams} from "react-router-dom";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ContentCardHeader from "../../shared/components/card/accessories/ContentCardHeader";
import ContentCardFooter from "../../shared/components/card/accessories/ContentCardFooter";
import {canUserEditContent} from "../../shared/helpers/permissions";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import ContentEditsBar from "../../shared/components/card/accessories/ContentEditsBar";
import paths from "../../config/paths";
import {useFlashAlertDetailsQuery} from "../../queries/FlashAlertDetails";
import DownloadReport from "../../shared/components/card/DownloadReport";
import TargetVictimCard from "../../shared/components/card/TargetVictimCard";
import SectorCard from "../../shared/components/card/withIcon/SectorCard";
import ActorsCard from "../../shared/components/card/ActorsCard";
import ReferencesRow from "../../shared/components/card/accessories/ReferencesRow";
import PurchaseContent from "../../shared/components/card/PurchaseContent";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_VIEWER} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";


const FlashAlertDetail = () => {
  const { uid: uid } = useParams();
  const { data, isIdle, isLoading, isError, error } = useFlashAlertDetailsQuery(uid);
  const { data: dataCurrentUser, isIdle: isIdleCurrentUser, isLoading: isLoadingCurrentUser } = useCurrentUserQuery();
  const history = useHistory();
  const updateFilters = (field, value) => {
    const urlParams = new URLSearchParams();
    urlParams.set(field, value);
    history.push(`${paths.contentsPath}?${urlParams.toString()}`);
  }

  const onCountryClick = (data) => {
    history.push(`${paths.contentsPath}?targeted_countries=${data.target.dataItem.dataContext.id}`)
  }
  if (isError) {
    return <ErrorHandler error={error} />
  }

  if (isLoading || isIdle || isIdleCurrentUser || isLoadingCurrentUser) {
    return <LoadingSpinner />
  }

  const info = data.data;
  const tlp = info.tlp.tlp;
  const isEditable = canUserEditContent(dataCurrentUser?.data, info);

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      
    >
      <Container>
        <Row>
          <Col lg={12} md={12}>
            <Card className={'h-auto'}>
              <CardBody>
                <ContentCardHeader
                  created_dt={info.created_dt}
                  confidence={info.confidence}
                  tlp={tlp}
                  updateFilters={(type,val) => updateFilters({type :type,name:val})}
                  title={info.title}
                />
                <pre className={'mb-3 pre--text'}>{info.text}</pre>
                {info.references?.length > 0 && <ReferencesRow references={info.references}/>}
                <ContentCardFooter tags={info.tags} tlp={tlp} />
                {isEditable && <div style={{position: 'absolute', bottom: '40px', right: '10px'}}><ContentEditsBar content={info} type={'flashalert'} /></div>}
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} md={6} className={'d-flex flex-column'} style={{paddingBottom: '30px'}}>
            <ActorsCard actors={info.actors} />
          </Col>
          <Col lg={6}>
            <PurchaseContent info={info} org={dataCurrentUser?.data?.organization} />
            <DownloadReport info={info} />
            <SectorCard updateFilters={(val)=> { updateFilters('targeted_sectors', val) }}  sectors={info.sectors} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TargetVictimCard updateFilters={updateFilters} onCountryClick={onCountryClick} targetedCountries={info.targeted_countries} />
          </Col>
        </Row>

      </Container>
    </Page>
  )
}

export default FlashAlertDetail;
