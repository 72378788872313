import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Pie, PieChart, Tooltip} from 'recharts';
import LoadingSpinner from "../LoadingSpinner";
import {Link} from "react-router-dom";

const _ = require('lodash')


const SmallGaugeChart = ({
  value,
  color,
  label = "",
  isLoading = false,
  tooltipText = null,
  to = "",
  height = 150
}) => {

  label = label ? label : value;
  const pieChartData = [
    {value: value, fill: color},
    {value: 100 - value, fill: '#787985'}
  ];

  return (
    isLoading && <LoadingSpinner/> || (
      <Fragment>

        <div className="chart__gauge__wrapper">
          <div className="chart__gauge__wrapper-pie">
            <PieChart height={height} width={280}
            >
              <Pie
                data={pieChartData}
                dataKey="value"
                cx={135}
                cy={140}
                startAngle={180}
                endAngle={0}
                innerRadius={113}
                outerRadius={128}
                paddingAngle={0}
              />
              {tooltipText &&
              <Tooltip
                separator={''}
                wrapperStyle={{zIndex: "10000"}}
                formatter={(value, name, props) => ["", tooltipText]}
                itemStyle={{color: "white", whiteSpace: "break-spaces"}}
              />}
            </PieChart>
            {
              to && <Link to={to}><p style={{color: color}} className="chart__gauge__value-label">{label}</p></Link>
              || <p style={{color: color}} className="chart__gauge__value-label">{label}</p>
            }
          </div>
        </div>
      </Fragment>
    )
  )
}


SmallGaugeChart.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
  to: PropTypes.string,
  tooltipText: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  height: PropTypes.number,
};

export default SmallGaugeChart;
