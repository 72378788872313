import React from "react";
import ActorForm from "../../shared/components/form/actors-form/ActorForm";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_ACTORS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";

const ActorAdd = () => {
  return (
    <Page
      feature={PLATFORM_FEATURE_ACTORS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
      unavailableOnTenant
    >
      <ActorForm />
    </Page>
  );
}

export default ActorAdd;
