import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const FeedlyFeedsFetcher = (
  accessToken, uid=null
) => {
  const uri = (uid) ? `/${uid}` : '';

  return axios.get(`${apiUrlInternal}/news_feeds${uri}`,
    addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useFeedlyFeedDetailQuery = (
  uid=null, fetcher = FeedlyFeedsFetcher) => {
  return useQueryWithAuthentication(
    ['feedly-feed-detail-query', uid],
    (accessToken) => fetcher(accessToken, uid
    ))
}