import React, {Fragment, useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import TicketModal from './components/TicketModal'
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import InfiniteScroll from "react-infinite-scroller";
import TicketDetailCard from './components/TicketDetailCard';
import {useTicketsQuery} from '../../queries/Tickets';
import EmptyDashboard from '../../shared/components/EmptyDashboard';
import ticketsIcon from '../../shared/img/tickets.svg'
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {PLATFORM_FEATURE_TICKETS_ANALYSIS} from "../../shared/helpers/features";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";

const Tickets = () => {
  const LOADING_STEP = 5;
  const INITIAL_ITEMS = 10;
  const [loadedItems, setLoadedItems] = useState(INITIAL_ITEMS);
  const [displayTicketsData, setDisplayTicketsData] = useState(undefined);
  const [ticketsData, setTicketsData] = useState(undefined);
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const {data, isIdle, isLoading, isError, error} = useTicketsQuery();

  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setTicketsData(data.data);
      setDisplayTicketsData(data.data.slice(0, INITIAL_ITEMS));
      setLoadedItems(INITIAL_ITEMS);
    }
  }, [isIdle, isLoading, isError, data])

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  const loadMoreItems = () => {
    setTimeout(() => {
      setDisplayTicketsData(ticketsData?.slice(0, loadedItems + LOADING_STEP));
      setLoadedItems(loadedItems + LOADING_STEP);
    }, 1000);
  }

  if (isIdle || isLoading) {
    return <LoadingSpinnerPage/>
  }

  if (displayTicketsData?.length === 0 && !isLoading && !isIdle) {
    return (
      <Page
        feature={PLATFORM_FEATURE_TICKETS_ANALYSIS}
        onlyOnline
      >
        <EmptyDashboard
          title="Open a case to our research team!"
          icon={ticketsIcon}
          description={<>You’ve never opened a ticket yet. <br/>
            Use our "Be my Analyst" service to allow to our researcher to investigate on samples and IoC. <br/>
            You can start by clicking the button below.</>}
          modalComp={<TicketModal
            disabled={!hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TICKETS_ANALYSIS)}/>}
        />
      </Page>
    )
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_TICKETS_ANALYSIS}
      onlyOnline
    >
      <Container className='ticketWrapper'>
        {
          isIdle || isLoading ? <LoadingSpinner/> :
            <Fragment>
              <div className={'div__sticky-top'}>
                <Row>
                  <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto'}>
                    <h3 className="page-title">Be My Analyst Tickets</h3>
                    <TicketModal disabled={
                      !hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TICKETS_ANALYSIS) ||
                      dataCurrentUser?.data?.organization?.used_be_my_analyst_ticket >= dataCurrentUser?.data?.organization?.be_my_analyst_ticket
                    }/>
                    {
                      dataCurrentUser?.data?.organization?.used_be_my_analyst_ticket >= dataCurrentUser?.data?.organization?.be_my_analyst_ticket &&
                      <h5 style={{color: "#ad4444"}}>You have reached your be my analysts ticket quota</h5>
                    }
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xl={10} lg={10} md={12} xs={12} className={'mx-auto mb-2'}>
                  {
                    ticketsData?.length > 0 &&
                    <InfiniteScroll
                      loadMore={loadMoreItems}
                      hasMore={loadedItems < ticketsData?.length}
                      loader={<LoadingSpinner/>}
                    >
                      {
                        displayTicketsData?.map((_i, index) => (
                          <TicketDetailCard ticket={displayTicketsData[index]} key={index}/>
                        ))
                      }
                    </InfiniteScroll>
                  }
                </Col>
              </Row>
            </Fragment>
        }
      </Container>
    </Page>
  );
}

export default Tickets;
