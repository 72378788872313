import {Col, Row} from "reactstrap";
import MenuEntry from "../../../../shared/components/MenuEntry";
import {getElementPropertyOrDefault} from "../../../../shared/helpers";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import PropTypes from "prop-types";
import React from "react";


const CredentialNotificationContent = ({credential}) => {
  return (
    <Row>
      <Col md={4}>
        <div className="custom_card__container">
          <MenuEntry title={'DUMP DATE'} value={credential.dumpdate ? formatDateUTCtoYearMonthDayTime(credential.dumpdate) : 'N/A'} />
          <MenuEntry title={'USERNAME'} value={getElementPropertyOrDefault(credential, 'username', 'N/A')} />

        </div>
      </Col>
      <Col md={8}>
        <div className="custom_card__container">
          <MenuEntry
            title={'URL'}
            value={getElementPropertyOrDefault(credential, 'url', 'N/A')}
            valueStyle={{wordBreak: 'break-word', whiteSpace: 'normal'}}
          />
          <MenuEntry title={'PASSWORD'} value={getElementPropertyOrDefault(credential, 'password', 'N/A')} />
        </div>
      </Col>
    </Row>
  )
}


CredentialNotificationContent.propTypes = {
  credential: PropTypes.object.isRequired
}


export default CredentialNotificationContent;
