import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";


const MalwareFamilyFetcher = (accessToken, endpoint) => {
  return axios.get(`${apiUrlInternal}/malware_families${endpoint}`, addAuthToAxiosOpts(accessToken, {}, 1200000))
    .then(res => res.data);
}


export const useMalwareFamilyQuery = (include_info = false, options = {}, fetcher = MalwareFamilyFetcher) => {
  const urlParams = new URLSearchParams();
  if (include_info) urlParams.set("include_info", `${include_info}`);
  const endpoint = `?${urlParams.toString()}`
  return useQueryWithAuthentication(['malware-family', endpoint], (accessToken) => fetcher(accessToken, endpoint), options);
}


const useMalwareFamilyDetailQuery = (endpoint = null, options = {}, fetcher = MalwareFamilyFetcher) => {
  return useQueryWithAuthentication(['malware-family', endpoint], (accessToken) => fetcher(accessToken, endpoint), options)
}


export const useMalwareFamilyDetailContentsQuery = (malwareFamilyUid) => {
  return useMalwareFamilyDetailQuery(
    `/${malwareFamilyUid}/contents`,
    {staleTime: Infinity, enabled: !!malwareFamilyUid}
  )
}


export const useMalwareFamilyDetailIndicatorsCountQuery = (malwareFamilyUid) => {
  return useMalwareFamilyDetailQuery(`/${malwareFamilyUid}/indicators/count`, {staleTime: Infinity})
}


export const useMalwareFamilyDetailIndicatorsWithPaginationQuery = (uid, ioc_type, page, limit) => {
  const urlParams = new URLSearchParams();
  if (page !== null) {
    urlParams.set('page', page);
  }
  if (limit) {
    urlParams.set('limit', limit);
  }
  if (ioc_type) {
    urlParams.set('ioc_type', ioc_type);
  }

  return useMalwareFamilyDetailQuery(`/${uid}/indicators?${urlParams.toString()}`, {staleTime: Infinity})
}

export const useMalwareFamilyDetailCommandsQuery = (uid) => {
  return useMalwareFamilyDetailQuery(`/${uid}/commands`, {enabled: !!uid, staleTime: Infinity})
}