import React, {useState} from "react";
import PropTypes from "prop-types";
import {tableColumns} from "../../../../../shared/components/darkweb/BotNetTable";
import TableWithDataHookPagination from "../../../../../shared/components/table/TableWithDataHookPagination";
import {useBotNetProjectResults} from "../../../../../queries/IdentityMonitorResults";
import {getFilterField} from "../../../../../shared/helpers/intelligence";

const BotNetTableWithPagination = ({
  project_uid,
  totalItems,
  extraColumnsAfter = [],
  extraColumnsBefore = [],
  customSort = false
}) => {
  const [orderByFilter, setOrderByFilter] = useState("")
  const [orderBy, setOrderBy] = useState('asc')
  const tableConfig = {
    withPagination: true,
    withSearchEngine: false,
    isSortable: !customSort,
  }

  const customOnHeaderClick = (target) => {
    setOrderByFilter(getFilterField(target));
  }

  const customOnHeaderIconClick = (target) => {
    setOrderBy(target)
  }

  return (<TableWithDataHookPagination
    tableColumns={extraColumnsBefore.concat(tableColumns.concat(extraColumnsAfter))}
    tableConfig={tableConfig}
    totalItems={totalItems}
    useDataQuery={useBotNetProjectResults}
    queryParams={project_uid}
    orderByFilter={orderByFilter}
    orderBy={orderBy}
    onHeaderClick={customOnHeaderClick}
    onHeaderIconClick={customOnHeaderIconClick}
  />)
}


BotNetTableWithPagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  project_uid: PropTypes.string.isRequired,
  extraColumnsAfter: PropTypes.array,
  extraColumnsBefore: PropTypes.array,
  customSort: PropTypes.bool
}


export default BotNetTableWithPagination;
