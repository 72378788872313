import React, {Fragment, useCallback, useState} from "react";
import PropTypes from "prop-types";
import ThemeModal from "../ThemeModal";
import {Button, ButtonToolbar} from "reactstrap";

const RansomwareDetailsModal = ({value}) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = useCallback(() => {
    setShowModal(!showModal)
  }, [setShowModal, showModal]);

  return (
    <Fragment>
      <Button style={{'margin-bottom': '0'}} className="rounded float-right" outline size="sm"
              onClick={() => toggleModal()}>Full Details</Button>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header-xl modal-dialog--dark'}
        style={{'transform': 'translateY(35vh)'}}
      >
        <h4 className="modal__header" key={"modal__header"}>Full Details:</h4>
        <div className="modal__body" key={"modal__body"}>
          <p>{value}</p>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" onClick={() => toggleModal()}>Close</Button>
        </ButtonToolbar>

      </ThemeModal>
    </Fragment>)
}

RansomwareDetailsModal.propTypes = {
  value: PropTypes.string.isRequired,
}

export default RansomwareDetailsModal;
