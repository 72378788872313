import Collapse from "../../../../shared/components/Collapse";
import React from "react";
import PropTypes from "prop-types";
import HighlightSpan from "../../../../shared/components/span/HighlightSpan";
import KeywordProjectFormCollapseContent from "./KeywordProjectFormCollapseContent";


const KeywordProjectFormCollapse = ({
  project,
  keywordTypes,
  organization
}) =>
  <Collapse
    title={
      <>Engine "<HighlightSpan text={project.name}/>".
        You inserted {project.keywords.length}/{project.max_keywords_to_monitor} keywords to monitor.
      </>
    }
    className={"with-shadow"}
  >
    <KeywordProjectFormCollapseContent
      project={project}
      keywords={project.keywords}
      organization={organization}
      keywordTypes={keywordTypes}
    />
  </Collapse>


KeywordProjectFormCollapse.propTypes = {
  project: PropTypes.object.isRequired,
  keywordTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  organization: PropTypes.object.isRequired
}


export default KeywordProjectFormCollapse;
