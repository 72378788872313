import {Button, Col, Row} from "reactstrap";
import RegisteredInputField from "../../../../shared/components/form/RegisteredInputField";
import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse, upperCaseFirstLetter} from "../../../../shared/helpers";
import {useQueryClient} from "react-query";
import PropTypes from "prop-types";
import _ from "lodash";
import {getPattern} from "../../../../shared/helpers/validators";
import DeleteButtonModal from "../../../../shared/components/modal/DeleteButtonModal";
import SubmitButtonModal from "../../../../shared/components/modal/SubmitButtonModal";
import {useUpdateProjectKeywords} from "../../../../mutations/UpdateProjectKeywords";
import {useDeleteProjectKeywords} from "../../../../mutations/DeleteProjectKeywords";
import {useDeleteKeywordProject} from "../../../../mutations/DeleteKeywordProject";
import CloseIconButtonModal from "../../../../shared/components/modal/CloseIconButtonModal";

const AddKeywordForm = ({
                          project,
                          keywords,
                          all_keywords,
                          organization,
                          keywordType,
                          allKeywordTypes,
                          buttonsDisabled = false,
                          messages,
                          deleteHandler,
                          submitHandler,
                          intelligenceBlock,
                          unblockDate,
                          submit,
                          submitAll,
                          onlyAddition
                        }) => {
  const addKeywordMutation = useUpdateProjectKeywords();
  const deleteKeywordMutation = useDeleteProjectKeywords();
  const deleteKeywordProjectMutation = useDeleteKeywordProject();
  const queryClient = useQueryClient();
  const deleteModalPostScriptum = "Deleting an entry will make you UNABLE to further EDIT and DELETE keywords and UNABLE to ADD, EDIT and DELETE engines until:"
  const submitModalPostScriptum = deleteModalPostScriptum.replace('Deleting', 'Editing')
  const deleteProjectModalPostScriptum =  deleteModalPostScriptum.replace('entry', 'engine with active entries')
  const [allowKeywordAddition, setAllowKeywordAddition] = useState(false)
  const [updateForm, setUpdateForm] = useState(false)
  const {register, handleSubmit, formState: {errors}, control, watch, setValue} = useForm({
    defaultValues: {keywords: keywords.map((val) => ({value: val.value, uid: val.uid}))}
  });

  const {fields: fieldsKeywords, append: appendKeywords, remove: removeKeywords} = useFieldArray(
    {control, name: "keywords"}
  );
  const [newKeywordsToAdd, setNewKeywordsToAdd] = useState(fieldsKeywords)
  useEffect(() => {
    if (submitAll.toSubmit?.length && submitAll.toSubmitType.includes(keywordType)) {
      setUpdateForm(true)
    }
  }, [submitAll])
  const onAdd = () => {
    appendKeywords({
      value: '',
      isNew: true
    })
    setAllowKeywordAddition(true)
    setNewKeywordsToAdd(fieldsKeywords)
  }

  const onSubmit = () => {
    let submissionAllowed = true
    all_keywords.map((keywords, index) => {
      keywords.map((keyword) => {
        keyword.type = allKeywordTypes[index]
      })
    })
    const newKeywords = all_keywords.flat().map((val) => val.isNew);
    const paramsKeyword = all_keywords.flat().map((val, index) => ({
      value: val.value,
      type: val.type,
      uid: val.isNew ? null : all_keywords.flat()[index]?.uid
    }));
    _.forEach(paramsKeyword, (param) => {
      const passesRegex = new RegExp(getPattern(param?.type)).test(param?.value)
      if (!passesRegex) {
        submissionAllowed = false
      }
    })
    if (!submissionAllowed) return;

    _.forEach(paramsKeyword, (param) => {
      if (_.isNull(param.uid) || _.isUndefined(param.uid)) {
        delete param.uid;
      }
    })

    if (_.max(_.values(_.countBy(paramsKeyword, (r) => r.value))) > 1) {
      toast.error("There are some duplicated values");
      return;
    }

    if (newKeywords.length === 0) {
      toast.error('Insert new keywords');
    } else {
      addKeywordMutation.mutate({
        params: {keywords: paramsKeyword},
        projectUid: project.uid
      })
      toast.loading('Updating settings');
    }
    setAllowKeywordAddition(newKeywordsToAdd.some((val) => val?.isNew))
  }

  useEffect(() => {
    if (updateForm) {
      _.forEach(submitAll.toSubmit, (res) => {
        const entry = _.filter(watch('keywords'), (e) => e.value === res.value)[0];
        if (entry) {
          entry.uid = res.uid;
          setValue(
            `keywords.${_.findIndex(watch('keywords'), (e) => e.value === res.value)}`,
            entry
          );
        }
      });

      _.forEach(watch('keywords'), (entry, index) => {
        entry.isNew = false;
        setValue(`keywords.${index}`, entry);
      })

      addKeywordMutation.reset();
      setUpdateForm(false)
    }
  }, [updateForm])

  useEffect(() => {
    if (!addKeywordMutation.isIdle && !addKeywordMutation.isLoading) {
      toast.dismiss();
      if (addKeywordMutation.isSuccess) {
        toast.success('Keywords updated successfully');
        queryClient.invalidateQueries(['project-keywords', project.uid]);
        queryClient.invalidateQueries(['organizations-keyword-projects', organization.uid]);
        queryClient.invalidateQueries(['users-me'])

        _.forEach(addKeywordMutation.data.data, (res) => {
          const entry = _.filter(watch('keywords'), (e) => e.value === res.value)[0];
          if (entry) {
            entry.uid = res.uid;
            setValue(
              `keywords.${_.findIndex(watch('keywords'), (e) => e.value === res.value)}`,
              entry
            );
          }
        });

        _.forEach(watch('keywords'), (entry, index) => {
          entry.isNew = false;
          setValue(`keywords.${index}`, entry);
        })

        submit(addKeywordMutation.data.data)
      } else if (addKeywordMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(addKeywordMutation)}.`);
      }
      addKeywordMutation.reset();
    }
  }, [
    addKeywordMutation.isIdle,
    addKeywordMutation.isLoading,
    addKeywordMutation.isError,
    addKeywordMutation.isSuccess,
    addKeywordMutation.error
  ])

  useEffect(() => {
    if (!deleteKeywordMutation.isIdle && !deleteKeywordMutation.isLoading) {
      toast.dismiss();
      if (deleteKeywordMutation.isSuccess) {
        toast.success('Keyword deleted successfully');
        queryClient.invalidateQueries(['project-keywords', project.uid]);
        queryClient.invalidateQueries(['organizations-keyword-projects', organization.uid]);
        queryClient.invalidateQueries(['users-me'])
      } else if (deleteKeywordMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(deleteKeywordMutation)}.`);
      }
      deleteKeywordMutation.reset();
    }
  }, [
    deleteKeywordMutation.isIdle,
    deleteKeywordMutation.isLoading,
    deleteKeywordMutation.isError,
    deleteKeywordMutation.isSuccess,
    deleteKeywordMutation.error
  ])

  useEffect(() => {
    if (!deleteKeywordProjectMutation.isIdle && !deleteKeywordProjectMutation.isLoading) {
      toast.dismiss();
      if (deleteKeywordProjectMutation.isSuccess) {
        toast.success('Engine deleted successfully');
        queryClient.invalidateQueries(['organizations-keyword-projects', organization.uid]);
        queryClient.invalidateQueries(['project-keywords', project.uid]);
      } else if (deleteKeywordProjectMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(deleteKeywordProjectMutation)}.`);
      }
      deleteKeywordProjectMutation.reset();
    }
  }, [
    deleteKeywordProjectMutation.isIdle,
    deleteKeywordProjectMutation.isLoading,
    deleteKeywordProjectMutation.isError,
    deleteKeywordProjectMutation.isSuccess,
    deleteKeywordProjectMutation.error
  ])

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit(onSubmit)}>
      {
        fieldsKeywords.length > 0 ?
          fieldsKeywords.map((item, index) =>
            <Row className={'w-100'} key={item.id}>
              <Col md={1}>
                {
                  buttonsDisabled || !(intelligenceBlock && (_.isUndefined(watch(`keywords.${index}`).isNew) || _.isNull(watch(`keywords.${index}`).isNew) || !watch(`keywords.${index}`).isNew)) &&
                  <CloseIconButtonModal
                    onClick={() => {
                      const rm = watch(`keywords.${index}`);
                      if (rm.uid) deleteHandler(index, keywordType, true)
                      else {
                        removeKeywords(index);
                        deleteHandler(index, keywordType)
                      }
                    }}
                    onDelete={() => {
                      const rm = watch(`keywords.${index}`);
                      removeKeywords(index);
                      const tmpKeywordsToAdd = newKeywordsToAdd
                      tmpKeywordsToAdd.splice(index, 1)
                      setNewKeywordsToAdd([...tmpKeywordsToAdd])
                      setAllowKeywordAddition(newKeywordsToAdd.some((val) => val?.isNew))

                      if (_.isUndefined(rm.isNew) || _.isNull(rm.isNew) || !rm.isNew) {
                        toast.loading('Deleting keyword')
                        deleteKeywordMutation.mutate({
                          rmUid: rm.uid
                        });
                      }
                      deleteHandler(index, keywordType)
                    }}
                    title={"Deletion Summary"}
                    messages={messages}
                    unblockDate={unblockDate}
                    postScriptum={deleteModalPostScriptum}/>
                }

              </Col>
              <Col md={11}>
                <RegisteredInputField
                  title={`${upperCaseFirstLetter(keywordType)} #${index + 1}`}
                  name={`keywords.${index}.value`}
                  register={register}
                  errors={errors}
                  disabled={intelligenceBlock && (_.isUndefined(watch(`keywords.${index}`).isNew) || _.isNull(watch(`keywords.${index}`).isNew) || !watch(`keywords.${index}`).isNew)}
                  rules={{
                    required: 'The value is required',
                    validate: (v) => new RegExp(getPattern(keywordType)).test(v) || `Insert a valid ${keywordType}`
                  }}
                />
              </Col>
            </Row>)
          : <h5 style={{paddingLeft: '10px'}}>Add some new {keywordType}s to monitor</h5>
      }

      <Row className={'justify-content-end w-100'}>
        <Button outline color={'success'} disabled={buttonsDisabled} onClick={() => onAdd()
        }>Add {upperCaseFirstLetter(keywordType)}</Button>
      </Row>

      <Row className={'w-100'}>
        <Col md={6}>
          <DeleteButtonModal
            onDelete={() => {
              toast.loading(`Deleting engine ${project.name}`);
              deleteKeywordProjectMutation.mutate(project.uid);
            }}
            disabled={buttonsDisabled || intelligenceBlock}
            message={<p>Do you want to delete the engine <br/> <b>{project.name}?</b></p>}
            postScriptum={project.keywords.length ? deleteProjectModalPostScriptum : null}
            unblockDate={unblockDate}
          />
        </Col>
        <Col md={6} className={'p-0'}>
          <SubmitButtonModal color="success" outline className={'float-right'}
                             disabled={buttonsDisabled || (intelligenceBlock && !allowKeywordAddition)}
                             intelligenceBlock={intelligenceBlock} onSubmit={handleSubmit(onSubmit)}
                             onClick={submitHandler}
                             title={"Modifications Summary"}
                             messages={messages}
                             unblockDate={unblockDate}
                             postScriptum={!onlyAddition ? submitModalPostScriptum : ""}>
            Add to Engine
          </SubmitButtonModal>
        </Col>
      </Row>
    </form>
  )
}


AddKeywordForm.propTypes = {
  project: PropTypes.object.isRequired,
  keywords: PropTypes.array.isRequired,
  keywordType: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  buttonsDisabled: PropTypes.bool,
  messages: PropTypes.array,
  deleteHandler: PropTypes.func,
  submitHandler: PropTypes.func,
  intelligenceBlock: PropTypes.bool,
  unblockDate: PropTypes.string,
  submit: PropTypes.func,
  submitAll: PropTypes.array,
  onlyAddition: PropTypes.bool
}

export default AddKeywordForm;
