import {Button, Col, Row} from "reactstrap";
import RegisteredInputField from "../../../../shared/components/form/RegisteredInputField";
import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse, upperCaseFirstLetter} from "../../../../shared/helpers";
import {useQueryClient} from "react-query";
import PropTypes from "prop-types";
import _ from "lodash";
import {getPattern} from "../../../../shared/helpers/validators";
import {useUpdateProjectResourceMonitors} from "../../../../mutations/UpdateProjectResourceMonitors";
import {useDeleteProjectResourceMonitors} from "../../../../mutations/DeleteProjectResourceMonitors";
import DeleteButtonModal from "../../../../shared/components/modal/DeleteButtonModal";
import {useDeleteResourceMonitorProject} from "../../../../mutations/DeleteResourceMonitorProject";
import SubmitButtonModal from "../../../../shared/components/modal/SubmitButtonModal";
import CloseIconButtonModal from "../../../../shared/components/modal/CloseIconButtonModal";

const AddResourceMonitorForm = ({
                                  project,
                                  resource_monitors,
                                  all_resources,
                                  organization,
                                  resourceMonitorType,
                                  allResourceMonitorsTypes,
                                  buttonsDisabled = false,
                                  messages,
                                  deleteHandler,
                                  submitHandler,
                                  intelligenceBlock,
                                  unblockDate,
                                  submit,
                                  submitAll,
                                  onlyAddition

                                }) => {
  const addResourceMonitorMutation = useUpdateProjectResourceMonitors();
  const deleteResourceMonitorMutation = useDeleteProjectResourceMonitors();
  const deleteResourceMonitorProjectMutation = useDeleteResourceMonitorProject();
  const queryClient = useQueryClient();
  const deleteModalPostScriptum = "Deleting an entry will make you UNABLE to further EDIT and DELETE resources and UNABLE to ADD, EDIT and DELETE engines until:"
  const submitModalPostScriptum = deleteModalPostScriptum.replace('Deleting', 'Editing')
  const deleteProjectModalPostScriptum =  deleteModalPostScriptum.replace('entry', 'engine with active entries')
  const [allowResourceAddition, setAllowResourceAddition] = useState(false)
  const [updateForm, setUpdateForm] = useState(false)
  const {register, handleSubmit, formState: {errors}, control, watch, setValue} = useForm({
    defaultValues: {resource_monitors: resource_monitors.map((val) => ({value: val.value, uid: val.uid}))}
  });

  const {fields: fieldsResources, append: appendResources, remove: removeResources} = useFieldArray(
    {control, name: "resource_monitors"}
  );

  const [newResourcesToAdd, setNewResourcesToAdd] = useState(fieldsResources)

  useEffect(() => {
    if (submitAll.toSubmit?.length && submitAll.toSubmitType.includes(resourceMonitorType)) {
      setUpdateForm(true)
    }
  }, [submitAll])
  const onAdd = () => {
    appendResources({
      value: '',
      isNew: true
    })

    setAllowResourceAddition(true)
    setNewResourcesToAdd(fieldsResources)
  }
  const onSubmit = () => {
    let submissionAllowed = true
    all_resources.map((resources, index) => {
      resources.map((resource) => {
        resource.type = allResourceMonitorsTypes[index]
      })
    })
    _.forEach(all_resources.flat(), (param) => {
      const passesRegex = new RegExp(getPattern(param?.type)).test(param?.value)
      if (!passesRegex) {
        submissionAllowed = false
      }
    })
    if (!submissionAllowed) return;
    const newResources = all_resources.flat().map((val) => val.isNew);
    const paramsResource = all_resources.flat().map((val, index) => ({
      value: val.value,
      uid: val.isNew ? null : all_resources.flat()[index]?.uid
    }));
    _.forEach(paramsResource, (param) => {
      if (_.isNull(param.uid) || _.isUndefined(param.uid)) {
        delete param.uid;
      }
    })

    if (_.max(_.values(_.countBy(paramsResource, (r) => r.value))) > 1) {
      toast.error("There are some duplicated values");
      return;
    }

    if (newResources.length === 0) {
      toast.error('Insert new resource');
    } else {
      addResourceMonitorMutation.mutate({
        params: {resource_monitors: paramsResource},
        projectUid: project.uid
      })
      toast.loading('Updating settings');
    }
    setAllowResourceAddition(newResourcesToAdd.some((val) => val?.isNew))
  }
  useEffect(() => {
    if (updateForm) {
      _.forEach(submitAll.toSubmit, (res) => {
        const entry = _.filter(watch('resource_monitors'), (e) => e.value === res.value)[0];
        if (entry) {
          entry.uid = res.uid;
          setValue(
            `resource_monitors.${_.findIndex(watch('resource_monitors'), (e) => e.value === res.value)}`,
            entry
          );
        }
      });

      _.forEach(watch('resource_monitors'), (entry, index) => {
        entry.isNew = false;
        setValue(`resource_monitors.${index}`, entry);
      })

      addResourceMonitorMutation.reset();
      setUpdateForm(false)
    }
  }, [updateForm])

  useEffect(() => {
    if (!addResourceMonitorMutation.isIdle && !addResourceMonitorMutation.isLoading) {
      toast.dismiss();
      if (addResourceMonitorMutation.isSuccess) {
        toast.success('Resources updated successfully');
        queryClient.invalidateQueries(['organizations-resource-monitor-projects', organization.uid]);
        queryClient.invalidateQueries(['users-me'])
        _.forEach(addResourceMonitorMutation.data.data, (res) => {
          const entry = _.filter(watch('resource_monitors'), (e) => e.value === res.value)[0];
          if (entry) {
            entry.uid = res.uid;
            setValue(
              `resource_monitors.${_.findIndex(watch('resource_monitors'), (e) => e.value === res.value)}`,
              entry
            );
          }
        });

        _.forEach(watch('resource_monitors'), (entry, index) => {
          entry.isNew = false;
          setValue(`resource_monitors.${index}`, entry);
        })

        submit(addResourceMonitorMutation.data.data)
      } else if (addResourceMonitorMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(addResourceMonitorMutation)}.`);
      }
      addResourceMonitorMutation.reset();
    }
  }, [
    addResourceMonitorMutation.isIdle,
    addResourceMonitorMutation.isLoading,
    addResourceMonitorMutation.isError,
    addResourceMonitorMutation.isSuccess,
    addResourceMonitorMutation.error
  ])

  useEffect(() => {
    if (!deleteResourceMonitorMutation.isIdle && !deleteResourceMonitorMutation.isLoading) {
      toast.dismiss();
      if (deleteResourceMonitorMutation.isSuccess) {
        toast.success('Resource deleted successfully');
        queryClient.invalidateQueries(['organizations-resource-monitor-projects', organization.uid]);
        queryClient.invalidateQueries(['users-me'])
      } else if (deleteResourceMonitorMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(deleteResourceMonitorMutation)}.`);
      }
      deleteResourceMonitorMutation.reset();
    }
  }, [
    deleteResourceMonitorMutation.isIdle,
    deleteResourceMonitorMutation.isLoading,
    deleteResourceMonitorMutation.isError,
    deleteResourceMonitorMutation.isSuccess,
    deleteResourceMonitorMutation.error
  ])

  useEffect(() => {
    if (!deleteResourceMonitorProjectMutation.isIdle && !deleteResourceMonitorProjectMutation.isLoading) {
      toast.dismiss();
      if (deleteResourceMonitorProjectMutation.isSuccess) {
        toast.success('Engine deleted successfully');
        queryClient.invalidateQueries(['organizations-resource-monitor-projects', organization.uid]);
      } else if (deleteResourceMonitorProjectMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(deleteResourceMonitorProjectMutation)}.`);
      }
      deleteResourceMonitorProjectMutation.reset();
    }
  }, [
    deleteResourceMonitorProjectMutation.isIdle,
    deleteResourceMonitorProjectMutation.isLoading,
    deleteResourceMonitorProjectMutation.isError,
    deleteResourceMonitorProjectMutation.isSuccess,
    deleteResourceMonitorProjectMutation.error
  ])

  return (
    <form className="form form--horizontal" onSubmit={handleSubmit(onSubmit)}>
      {
        fieldsResources.length > 0 ?
          fieldsResources.map((item, index) =>
            <Row className={'w-100'} key={item.id}>
              <Col md={1}>
                {
                  buttonsDisabled || !(intelligenceBlock && (_.isUndefined(watch(`resource_monitors.${index}`).isNew) || _.isNull(watch(`resource_monitors.${index}`).isNew) || !watch(`resource_monitors.${index}`).isNew)) &&
                  <CloseIconButtonModal
                    onClick={() => {
                      const rm = watch(`resource_monitors.${index}`);
                      if (rm.uid) deleteHandler(index, resourceMonitorType, true)
                      else {
                        removeResources(index);
                        deleteHandler(index, resourceMonitorType)
                      }
                    }}
                    onDelete={() => {
                      const rm = watch(`resource_monitors.${index}`);
                      removeResources(index);
                      const tmpResourcesToAdd = newResourcesToAdd
                      tmpResourcesToAdd.splice(index, 1)
                      setNewResourcesToAdd([...tmpResourcesToAdd])
                      setAllowResourceAddition(newResourcesToAdd.some((val) => val?.isNew))
                      if (_.isUndefined(rm.isNew) || _.isNull(rm.isNew) || !rm.isNew) {
                        toast.loading('Deleting resource')
                        deleteResourceMonitorMutation.mutate({
                          rmUid: rm.uid
                        });
                      }
                      deleteHandler(index, resourceMonitorType)
                    }}
                    title={"Deletion Summary"}
                    messages={messages}
                    unblockDate={unblockDate}
                    postScriptum={deleteModalPostScriptum}
                  />
                }
              </Col>
              <Col md={11}>
                <RegisteredInputField
                  title={`${upperCaseFirstLetter(resourceMonitorType)} #${index + 1}`}
                  name={`resource_monitors.${index}.value`}
                  register={register}
                  errors={errors}
                  disabled={intelligenceBlock && (_.isUndefined(watch(`resource_monitors.${index}`).isNew) || _.isNull(watch(`resource_monitors.${index}`).isNew) || !watch(`resource_monitors.${index}`).isNew)}
                  rules={{
                    required: 'The value is required',
                    validate: (v) => new RegExp(getPattern(resourceMonitorType)).test(v) || `Insert a valid ${resourceMonitorType}`
                  }}
                />
              </Col>
            </Row>)
          : <h5 style={{paddingLeft: '10px'}}>Add some new {resourceMonitorType}s to monitor</h5>
      }

      <Row className={'justify-content-end w-100'}>
        <Button outline disabled={buttonsDisabled} color={'success'}
                onClick={() => onAdd()}>Add {upperCaseFirstLetter(resourceMonitorType)}</Button>
      </Row>

      <Row className={'w-100'}>
        <Col md={6}>
          <DeleteButtonModal
            onDelete={() => {
              toast.loading(`Deleting engine ${project.name}`);
              deleteResourceMonitorProjectMutation.mutate(project.uid);
            }}
            disabled={buttonsDisabled || intelligenceBlock}
            message={<p>Do you want to delete the engine <br/> <b>{project.name}?</b></p>}
            postScriptum={project.resource_monitors.length ? deleteProjectModalPostScriptum : null}
            unblockDate={unblockDate}
          />
        </Col>
        <Col md={6} className={'p-0'}>
          <SubmitButtonModal color="success" outline className={'float-right'}
                             disabled={buttonsDisabled || (intelligenceBlock && !allowResourceAddition)}
                             intelligenceBlock={intelligenceBlock} onSubmit={handleSubmit(onSubmit)}
                             onClick={submitHandler}
                             title={"Modifications Summary"}
                             messages={messages}
                             unblockDate={unblockDate}
                             postScriptum={!onlyAddition ? submitModalPostScriptum : null}>
            Add to Engine
          </SubmitButtonModal>
        </Col>
      </Row>
    </form>
  )
}


AddResourceMonitorForm.propTypes = {
  project: PropTypes.object.isRequired,
  resource_monitors: PropTypes.array.isRequired,
  resourceMonitorType: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  buttonsDisabled: PropTypes.bool,
  messages: PropTypes.array,
  deleteHandler: PropTypes.func,
  submitHandler: PropTypes.func,
  intelligenceBlock: PropTypes.bool,
  unblockDate: PropTypes.string,
  submit: PropTypes.func,
  submitAll: PropTypes.array,
  onlyAddition: PropTypes.bool
}

export default AddResourceMonitorForm;
