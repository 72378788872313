import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";

export const AddFeedlyFeedsPreferencesFetcher = (
  accessToken, params
) => {
  const urlParams = new URLSearchParams();
  if (params.preferenceType) {
    urlParams.set('preference_type', params.preferenceType);
  }

  const data = {preferences: [{value: params.value}]}
  return axios.post(`${apiUrlInternal}/news_feeds/preferences?${urlParams.toString()}`, data,
    addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useAddFeedlyFeedsPreferenceQuery = (
  fetcher = AddFeedlyFeedsPreferencesFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-feedly-feeds-preferences'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['feedly-feeds-preferences', 'malware_family'])
        queryClient.invalidateQueries(['feedly-feeds-preferences', 'actor']);
      }
    }
  )
}
