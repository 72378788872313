import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const UpdateProjectTypoSquattingAssetsFetcher = (accessToken, projectUid, params) => {
  return axios.put(
    `${apiUrlInternal}/typosquatting_projects/${projectUid}/assets`,
    params,
    addAuthToFetchOpts(accessToken, {})
  ).then(res => res.data);
}

export const useUpdateProjectTypoSquattingAssets = (fetcher = UpdateProjectTypoSquattingAssetsFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['update-organization-typosquatting-assets'],
    (accessToken, {params, projectUid}) => fetcher(accessToken, projectUid, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('update-organization-typosquatting-assets');
        queryClient.invalidateQueries('users-me');
      }
    }
  )
}
