import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import paths from "../../../../config/paths";
import toast from 'react-hot-toast';
import axios from "axios";
import {addAuthToAxiosOpts} from "../../../../config/queryopts";
import {useCookieAccessToken} from "../../../../queries";
import {apiUrlInternal} from "../../../../config/api";
import LoadingSpinner from "../../LoadingSpinner";
import ErrorHandler from "../../ErrorHandler";
import {useAddContentFormQuery} from "../../../../queries/AddContentForm";
import DescriptionCard from "./components/DescriptionCard";
import ReportUploadCard from "./components/ReportUploadCard";
import BasicInformationForm from "./components/BasicInformationForm";
import {getFlashAlertParamsWithValidation} from "../../../helpers/contents";
import {getErrorMessageFromResponse} from "../../../helpers";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import {useAddFlashAlertMutation} from "../../../../mutations/AddFlashAlert";
import {getFilesToUpload} from "../../../helpers/upload_file";
import _ from "lodash";
import AttachmentsUploadCard from "./components/AttachmentsUploadCard";


const FlashAlertForm = ({
  defaultValues = { indicators: [{ tags: [], value: '', type: {value: 'domain', label: 'domain'}}]},
  mutationFn = useAddFlashAlertMutation,
  uid,
  editForm = false
}) => {
  let isIdleLoading = false;
  let isError = false;
  const {
    data: accessToken,
    isError: tokenIsError,
    isLoading: tokenIsLoading,
    isIdle: tokenIsIdle,
    error
  } = useCookieAccessToken()
  const mutation = mutationFn();
  const history = useHistory();
  const formQuery = useAddContentFormQuery();
  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    watch,
    setError,
    clearErrors,
    setValue,
    getValues
  } = useForm({
    defaultValues: defaultValues
  });


  const onSubmit = (data) => {
    if (isIdleLoading) {
      toast.error('Wait until the form is loaded');
      return;
    } else if (isError) {
      toast.error('There was an error while loading the form');
      return;
    }

    const params = getFlashAlertParamsWithValidation(data);
    toast.dismiss()

    if (Object.keys(params.errors).length > 0) {
      Sentry.captureException(params.errors);
      console.log(params.errors);
      for (const [field, value] of Object.entries(params.errors)) {
        setError(field, value);
      }
    } else {
      delete params['errors'];
      setButtonUploadDisabled(true);

      const reportFM = getFilesToUpload(data.report, []);
      const attachmentsFM = getFilesToUpload(data.attachments, defaultValues.attachments);
      params.attachments = attachmentsFM.old;

      if (reportFM.toUpload.length === 0 && attachmentsFM.toUpload.length === 0) {
        if (editForm) mutation.mutate({params: params, uid: uid});
        else mutation.mutate(params);
      } else {
        const filesToUpload = _.concat(reportFM.toUpload, attachmentsFM.toUpload);
        const formData = new FormData();
        _.forEach(filesToUpload, (f) => {
          formData.append('files', f.file, f.name);
        });
        axios.post(`${apiUrlInternal}/attachments`, formData,
          addAuthToAxiosOpts(accessToken, {'Content-Type': 'multipart/form-data'}, 0),
        ).then((res) => {
          if (res.status === 200) {
            const files = res.data['data'];
            if (reportFM.toUpload.length > 0) {
              params.report_id = files[0]['file_id'];
              params.report_filename = files[0]['file_name'];
              if (files.length > 1) {
                params.attachments = [];
                _.forEach(files.slice(1), (el) => {
                  params.attachments.push({file_id: el['file_id'], file_name: el['file_name']});
                })
              }
            } else {
              params.attachments = [];
              _.forEach(files, (el) => {
                params.attachments.push({file_id: el['file_id'], file_name: el['file_name']});
              })
            }
            params.attachments = _.concat(attachmentsFM.old, params.attachments);

            if (editForm) mutation.mutate({params: params, uid: uid});
            else mutation.mutate(params);
          } else {
            toast.dismiss();
            toast.error(`Error while uploading the attachment. Please contact the administrator.`);
            setButtonUploadDisabled(false);
          }
        }).catch(() => {
          toast.dismiss();
          toast.error(`Error while uploading the attachment. Please contact the administrator.`);
          setButtonUploadDisabled(false);
        })
      }

      toast.loading('Validating and uploading Flash Threat Activity.');
    }
  }

  useEffect(() => {
    if (!mutation.isIdle && mutation.isLoading && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }

    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        toast.success(`Flash Threat Activity uploaded correctly, you will be redirect.`);
        const targetPath = uid ? `${paths.flashAlertPath}/${uid}` :
          mutation.data?.data?.uid ? `${paths.flashAlertPath}/${mutation.data.data.uid}` : paths.contentsPath;
        setTimeout(() => {
          history.push(targetPath);
          toast.dismiss();
        }, 2000);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
        setButtonUploadDisabled(false);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])


  for (const props of Object.values(formQuery)) {
    isIdleLoading |= props.isIdle || props.isLoading;
    isError |= props.isError;
  }

  if (tokenIsIdle || tokenIsLoading) {
    return <Container><LoadingSpinner/></Container>
  }

  if (tokenIsError) {
    return <ErrorHandler error={error}/>
  }

  return (
    <Container>
      <Row className={'div__sticky-top'}>
        <Col md={9}>
          <h3 className="page-title">{editForm ? 'Edit' : 'New'} Flash Threat Activity</h3>
        </Col>
        <Col md={3} className={'justify-content-end d-flex'}>
          <Button outline onClick={() => {
            clearErrors('');
            handleSubmit(onSubmit)();
          }} color={'success'} disabled={buttonUploadDisabled}>{editForm ? 'Edit' : 'Publish'}</Button>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Basic information</h4>
            {
              Object.keys(errors).length > 0 && <span className="form__form-group-error float-right">
                There are some validation errors inside the form
              </span>
            }
          </div>
          {
            isIdleLoading ? <LoadingSpinner/> :
              isError ? <h3>Unable to load the form correctly</h3> :
                <form className="form form--vertical">
                  <BasicInformationForm
                    control={control}
                    register={register}
                    errors={errors}
                    watch={watch}
                    defaultValues={defaultValues}
                    editForm={editForm}
                    setValue={setValue}
                    isFlashAlert={true}
                    getValues={getValues}
                  />

                </form>
          }
        </CardBody>
      </Card>

      <ReportUploadCard
        control={control}
        defaultValues={defaultValues}
      />

      <AttachmentsUploadCard
        control={control}
        defaultValues={defaultValues}
      />

      <DescriptionCard
        register={register}
      />

    </Container>
  )
}

FlashAlertForm.propTypes = {
  defaultValues: PropTypes.shape(),
  mutationFn: PropTypes.func,
  editForm: PropTypes.bool
}

export default FlashAlertForm;
