import React from "react";
import TargetAccountIcon from "mdi-react/TargetAccountIcon";
import PropTypes from "prop-types";
import CardWithIcon from "../CardWithIcon";
import {useActorDetailsQuery} from "../../../../queries/Actor";
import LoadingSpinner from "../../LoadingSpinner";
import ActorDetailCard from "../ActorDetailCard";


const ActorKnownCard = ({actorUid}) => {
  const { data, isIdle, isLoading, isError } = useActorDetailsQuery(actorUid);

  if (isIdle || isLoading) {
    return (
      <CardWithIcon title={'ACTOR'} content={<LoadingSpinner />} icon={<TargetAccountIcon />} />
    )
  }

  if (isError) {
    return (
      <CardWithIcon
        title={'ACTOR'} icon={<TargetAccountIcon />}
        content={<p className="p__custom_card__container-title">Unable to load the information about the actor</p>}
      />
    )
  }

  return (
    <ActorDetailCard
      actor={data.data}
      withLink
      withTruncateText
      style={{height: 'auto'}}
    />
  )
}

ActorKnownCard.propTypes = {
  actorUid: PropTypes.string.isRequired,
}

export default ActorKnownCard;
