import React from "react";
import {Card, CardBody} from "reactstrap";
import {useRansomwareTrackerEventsQuery} from "../../../../queries/RansomwareTrackerEvents";
import {Link} from "react-router-dom";
import paths from "../../../../config/paths";
import TableWithData from "../../../../shared/components/table/TableWithData";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import {LimitCountriesBadges} from "../../../../shared/helpers/LimitBadges";
import PropTypes from "prop-types";
import {getCountryCode} from "../../../../shared/helpers/country-data";
import ThemedClampLines from "../../../../shared/components/clamp-lines/ThemedClampLines";


const tableColumns = [
  {Header: 'Actor', accessor: "actor_name"},
  {Header: 'Victim', accessor: "victim"},
  {
    Header: 'Victim Country',
    accessor: 'victim_country',
    Cell: ({value}) => <LimitCountriesBadges limit={2} countries={[getCountryCode(value)]}/>
  },
  {Header: 'Description', accessor: 'description', Cell: ({value}) =>
      <div style={{maxWidth: '600px'}}>
        <ThemedClampLines
           text={value}
           lines={1}
           maxLines={4}
        />
      </div>
  },
  {Header: 'Victim Url', accessor: 'website', Cell: ({value}) => <a href={value !== 'N/D' ? value : ""}>{value !== 'N/D' ? value : ""}</a>},
  {Header: 'Date', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
];



const LastRansomwareTrackerEventsTable = ({limit = 10, ...props}) => {
  const {data, isIdle, isLoading, isError} = useRansomwareTrackerEventsQuery(limit);
  const tableConfig = {
    withPagination: false,
    withHover: false,
    manualPageSize: [limit],
    striped: true
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}><Link to={paths.ransomwareTrackerEventsPath}>Last Ransomware
            Activity</Link></h4>
        </div>
        <TableWithData
          data={data?.data}
          isLoading={isLoading || isIdle}
          isError={isError}
          tableColumns={tableColumns}
          tableConfig={tableConfig}
          {...props}
        />
      </CardBody>
    </Card>
  )
}

LastRansomwareTrackerEventsTable.propTypes = {
  limit: PropTypes.number
}

export default LastRansomwareTrackerEventsTable;
