import React from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import {useFeedlyFeedsPreferenceQuery} from "../../../queries/FeedlyFeedsPreferences";
import FeedlyFeedsPreferencesForm from "../../../shared/components/form/FeedlyFeedsPreferences";

const NewsFeedsPreferencesSection = () => {

  const {
    data: actorPreferenceData,
    isError: actorPreferenceIsError,
    isLoading: actorPreferenceIsLoading,
    isIdle: actorPreferenceIsIdle,
    error: actorPreferenceError
  } = useFeedlyFeedsPreferenceQuery('actor');

  const {
    data: malwareFamilyPreferenceData,
    isError: malwareFamilyPreferenceIsError,
    isLoading: malwareFamilyPreferenceIsLoading,
    isIdle: malwareFamilyPreferenceIsIdle,
    error: malwareFamilyPreferenceError
  } = useFeedlyFeedsPreferenceQuery('malware_family');

  if (actorPreferenceIsLoading || malwareFamilyPreferenceIsLoading || actorPreferenceIsIdle || malwareFamilyPreferenceIsIdle) {
    return <LoadingSpinner/>
  }

  if (actorPreferenceError || malwareFamilyPreferenceError) {
    return <ErrorHandler error={actorPreferenceError || malwareFamilyPreferenceError}/>
  }

  const defaultValues = {actor_preferences: actorPreferenceData?.data, malware_families_preferences: malwareFamilyPreferenceData?.data}

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>News Feed Preferences</h4>
          <p className={'subhead'}>
          </p>
          <FeedlyFeedsPreferencesForm defaultValues={defaultValues}/>
        </div>
      </CardBody>
    </Card>
  )
}

NewsFeedsPreferencesSection.propTypes = {
  defaultValues: PropTypes.shape(),
}

export default NewsFeedsPreferencesSection;