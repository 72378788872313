import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import _ from 'lodash';
import classnames from 'classnames';
import { useCurrentUserQuery } from '../../../queries/CurrentUser';
import {apiUrlInternal} from "../../../config/api";
import DownloadFileIcon from "../icons/DownloadFileIcon";

import { useSetAllIntelxSolved } from '../../../mutations/SetAllIntelxSolved';
import { useSetIntelxSolved } from '../../../mutations/SetIntelxSolved';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { getErrorMessageFromResponse } from '../../helpers';
import RegisteredCheckBoxField from '../form/RegisteredCheckBoxField';
import IntelSectTableWithPagination
  from '../../../containers/IntelligenceIdentity/components/IntelSect/components/IntelSectTableWithPagination';
import { intelSectBuckets } from '../../helpers/intelligence';
import { isUserInternal } from '../../helpers/permissions';
import {download_file} from "../../helpers/download_file";
import {formatDateUTCtoYearMonthDayTimeFile} from "../../helpers/date";
import moment from "moment";
import {useCookieAccessToken} from "../../../queries";

const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
};

const SECTIONS = {
  leaks: 0,
  dumpster: 1,
  pastes: 2,
  darknet: 3,
}

const IntelxTabsResult = ({
  leaks,
  dumpster,
  pastes,
  darknet,
  project_uid,
  project_name
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { data } = useCurrentUserQuery();
  const total = leaks + pastes + dumpster + darknet;
  const mutationSetAllIntelxSolved = useSetAllIntelxSolved();
  const mutationSetIntelxSolved = useSetIntelxSolved();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [btnSelectDisabled, setBtnSelectDisabled] = useState(true);
  const {
    register,
    setValue,
    watch
  } = useForm();
  const [uidsSelected, setUidsSelected] = useState([]);
  const {data: accessToken} = useCookieAccessToken();

  const unsetSelection = () => {
    for (
      let i = 0;
      i < _.max([leaks, dumpster, pastes, darknet]);
      ++i
    ) {
      setValue(`intelx.${i}`, false);
    }
  };

  useEffect(() => {
    setBtnSelectDisabled(uidsSelected.length === 0);
  }, [uidsSelected]);

  useEffect(() => {
    toast.dismiss();

    if (!mutationSetAllIntelxSolved.isIdle) {
      if (mutationSetAllIntelxSolved.isLoading) {
        toast.loading('Marking all Intel as solved');
      } else {
        setBtnDisabled(false);
        if (mutationSetAllIntelxSolved.isSuccess) {
          toast.success('Intel marked as solved');
          unsetSelection();
        } else if (mutationSetAllIntelxSolved.isError) {
          toast.error(getErrorMessageFromResponse(mutationSetAllIntelxSolved));
          mutationSetAllIntelxSolved.reset();
        }
      }
    }
  }, [
    mutationSetAllIntelxSolved.isLoading,
    mutationSetAllIntelxSolved.isIdle,
    mutationSetAllIntelxSolved.isSuccess,
    mutationSetAllIntelxSolved.isError,
    getErrorMessageFromResponse(mutationSetAllIntelxSolved),
  ]);

  useEffect(() => {
    if (!mutationSetIntelxSolved.isIdle) {
      toast.dismiss();

      if (mutationSetIntelxSolved.isLoading) {
        toast.loading('Marking Intel as solved');
      } else {
        setBtnDisabled(false);
        if (mutationSetIntelxSolved.isSuccess) {
          toast.success('Intel marked as solved');
          unsetSelection();
        } else if (mutationSetIntelxSolved.isError) {
          toast.error(getErrorMessageFromResponse(mutationSetIntelxSolved));
          mutationSetIntelxSolved.reset();
        }
      }
    }
  }, [
    mutationSetIntelxSolved.isLoading,
    mutationSetIntelxSolved.isIdle,
    mutationSetIntelxSolved.isSuccess,
    mutationSetIntelxSolved.isError,
    getErrorMessageFromResponse(mutationSetIntelxSolved),
  ]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const keywordValueColumn =  [{
    Header: 'Keyword',
    accessor: 'keyword.value'
  },]

  const getSectionNameByIndex = (index) => {
    return _.keys(SECTIONS)[index];
  };

  const getExtraColumnBefore = (section) => {
    return (
      [
        {
          Header: 'Status',
          accessor: 'solved',
          Cell: ({
            value,
            row
          }) => {
            const label = value ?
              <p className={'d-inline'} style={{ color: '#4ce1b6' }}>Solved</p> :
              <p className={'d-inline'} style={{ color: '#ff4861' }}>New Item</p>;

            const index = row.index;
            const uid = row.original.uid;

            return (
              <>
                {
                  !value &&
                  <RegisteredCheckBoxField
                    name={`${section}.${index}`}
                    onChange={() => {
                      if (watch(`${section}.${index}`) && !uidsSelected.includes(uid)) {
                        setUidsSelected(uidsSelected.concat([uid]));
                      } else if (!watch(`${section}.${index}`) && uidsSelected.includes(uid)) {
                        setUidsSelected(uidsSelected.filter(val => val !== uid));
                      }
                    }}
                    value={watch(`${section}.${index}`)}
                    defaultChecked={false}
                    label={''}
                    register={register}
                    style={{ display: 'inline' }}
                    className={'d-inline'}
                  />
                }
                {label}
              </>
            );
          }
        }
      ]
    );
  };
  return (
    <div className="tabs tabs--bordered-bottom">
      <div className="tabs__wrap">
        <Button
          onClick={(e) => {
            e.preventDefault();
            download_file(
              `${apiUrlInternal}/keyword_projects/${project_uid}/results/intel/export`,
              `export_${project_name}_intel_${formatDateUTCtoYearMonthDayTimeFile(moment())}.csv`,
              accessToken,
              60000,
              'Creating the CSV file, it can take few minutes'
            );
          }}
          outline
          color={'success'}
          className={'float-right py-1 mr-0'}
        >
          Download
        </Button>
        <Button
          onClick={() => {
            setBtnDisabled(true);
            mutationSetAllIntelxSolved.mutate({
              project_uid: project_uid,
              section: getSectionNameByIndex(activeTab)
            });
          }}
          className={'float-right py-1 mr-2'}
          disabled={btnDisabled}
        >
          Mark All As Solved
        </Button>
        <Button
          onClick={() => {
            setBtnDisabled(true);
            mutationSetIntelxSolved.mutate({
              project_uid: project_uid,
              uids: uidsSelected
            });
          }}
          className={'float-right py-1 mr-2'}
          disabled={btnDisabled || btnSelectDisabled}
        >
          Mark Selected As Solved
        </Button>
        <Nav tabs>
          {
            leaks > 0 &&
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === SECTIONS.leaks })}
                onClick={() => toggleTab(SECTIONS.leaks)}
              >LEAKS ({leaks})</NavLink>
            </NavItem>
          }
          {
            dumpster > 0 &&
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === SECTIONS.dumpster })}
                onClick={() => toggleTab(SECTIONS.dumpster)}
              >DUMPSTER ({dumpster})</NavLink>
            </NavItem>
          }
          {
            pastes > 0 &&
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === SECTIONS.pastes })}
                onClick={() => toggleTab(SECTIONS.pastes )}
              >PASTES ({pastes})</NavLink>
            </NavItem>
          }
          {
            darknet > 0 &&
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === SECTIONS.darknet })}
                onClick={() => toggleTab(SECTIONS.darknet)}
              >DARKNET ({darknet})</NavLink>
            </NavItem>
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          {
            <TabPane tabId={activeTab} key={intelSectBuckets[Object.values(intelSectBuckets)[activeTab]]}>
              <IntelSectTableWithPagination
                project_uid={project_uid}
                totalItems={total}
                type={intelSectBuckets[Object.values(intelSectBuckets)[activeTab]]}
                extraColumnsAfter={isUserInternal(data?.data) ? keywordValueColumn : keywordValueColumn.concat(
                  [{
                  Header: '', accessor: 'systemid', disableSort: true, Cell: ({value, row}) =>
                      <DownloadFileIcon
                        url={`${apiUrlInternal}/intel/download?uuid=${value}&source=${row.original.bucket}`}
                        filename={value}
                        timeout={60000}
                      />
                }])}
                extraColumnsBefore={getExtraColumnBefore(intelSectBuckets[Object.values(intelSectBuckets)[activeTab]])}
                tableConfig={tableConfig}
                customSort={true}
              />
            </TabPane>
          }
        </TabContent>
      </div>
    </div>
  );
};

IntelxTabsResult.propTypes = {
  leaks: PropTypes.number.isRequired,
  dumpster: PropTypes.number.isRequired,
  pastes: PropTypes.number.isRequired,
  darknet: PropTypes.number.isRequired,
};

export default IntelxTabsResult;
