import React from "react";
import PostForm from "../../../shared/components/form/events-form/PostForm";
import Page from "../../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS, PLATFORM_PERMISSION_EDITOR} from "../../../shared/helpers/features";


const PostAdd = () => {
  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      permission={PLATFORM_PERMISSION_EDITOR} onlyOnline
    >
      <PostForm />
    </Page>
  )
}


export default PostAdd;
