import React, {Fragment, useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import TableWithDataHook from "../../../shared/components/table/TableWithDataHook";
import {useSandboxTaskDetailsQuery} from "../../../queries/Sandbox/SandboxTaskDetails";
import ReactTableGeneralCustomizer from "../../../shared/components/table/components/ReactTableGeneralCustomizer";

const _ = require('lodash')

const tableColumns = [
    { Header: 'Registry', accessor: 'registry'},
    { Header: 'Action', accessor: 'type',filter:stringIncludesFilter },
];

const tableConfig = {
    withPagination: true,
    withHover: false,
    withSearchEngine: true,
}

function stringIncludesFilter(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return filterValue.indexOf(rowValue) > -1
    })
}

function mergeArraysDataAccessor(data) {
    if(!data.data.report.behavior.summary)
        return []
    var read_keys = data.data.report.behavior.summary.read_keys.map(x => ({registry:x,type:"Read"}));
    var write_keys = data.data.report.behavior.summary.write_keys.map(x => ({registry:x,type:"Write"}));
    var delete_keys = data.data.report.behavior.summary.delete_keys.map(x => ({registry:x,type:"Delete"}));
    return _(read_keys)
        .concat( write_keys, delete_keys)
        .value();
}

const FilterableRegistriesTable = ({taskId, onRowClick, ...props}) => {
    const [registryStatus, setRegistryStatus] = useState('ReadWriteDelete')
    const [showReadRegistries, setShowReadRegistries] = useState(true);
    const [showWriteRegistries, setShowWriteRegistries] = useState(true);
    const [showDeleteRegistries, setShowDeleteRegistries] = useState(true);

    const handleClickShowReadRegistries = () => {
        if(showReadRegistries) {
            setRegistryStatus(oldStatus => oldStatus.replace('Read',''));
        } else {
            setRegistryStatus(oldStatus => oldStatus+'Read');
        }
        setShowReadRegistries(!showReadRegistries);
    }

    const handleClickShowWriteRegistries = () => {
        if(showWriteRegistries) {
            setRegistryStatus(oldStatus => oldStatus.replace('Write',''));
        } else {
            setRegistryStatus(oldStatus => oldStatus+'Write');
        }
        setShowWriteRegistries(!showWriteRegistries);
    }

    const handleClickShowDeleteRegistries = () => {
        if(showDeleteRegistries) {
            setRegistryStatus(oldStatus => oldStatus.replace('Delete',''));
        } else {
            setRegistryStatus(oldStatus => oldStatus+'Delete');
        }
        setShowDeleteRegistries(!showDeleteRegistries);
    }

    const getRowDataId = useCallback((row, i) => (row.original.uid), [])

    const filters = [{
        accessorName:'type',
        filterState:registryStatus
    }]

    const arrayTableCustomizerSingleToggle = [
        {
            id: 0, text: 'Read registries', func: handleClickShowReadRegistries, isChecked: showReadRegistries,
        },
        {
            id: 1, text: 'Write registries', func: handleClickShowWriteRegistries, isChecked: showWriteRegistries,
        },
        {
            id: 2, text: 'Deleted registries', func: handleClickShowDeleteRegistries, isChecked: showDeleteRegistries,
        },
    ];

    return (
        <Fragment>
            <div className="react-table__wrapper">
                <div className="card__title">
                    <h5 className="bold-text">Registries</h5>
                </div>
                <ReactTableGeneralCustomizer buttonText={"Filter"} arrayTableCustomizerSingleToggle={arrayTableCustomizerSingleToggle}/>
            </div>
            <TableWithDataHook
                useDataQuery={useSandboxTaskDetailsQuery}
                queryParam={taskId}
                tableColumns={tableColumns}
                tableConfig={tableConfig}
                getRowDataId={getRowDataId}
                onRowClick={onRowClick}
                dataAccessor={(data) => mergeArraysDataAccessor(data)}
                filters={filters}
                {...props}
            />
        </Fragment>
    );
}

FilterableRegistriesTable.propTypes = {
    onRowClick: PropTypes.func,
};

export default FilterableRegistriesTable;
