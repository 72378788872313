import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";
import _ from "lodash";


const NotificationFetcher = (accessToken, endpoint, urlParamsString) => {
  return axios.get(`${apiUrlInternal}/notifications/${endpoint}${urlParamsString ? '?' + urlParamsString : ''}`, addAuthToAxiosOpts(accessToken, {}))
    .then(res => res.data);
}


const useNotificationQuery = (endpoint = null, options = {}, urlParamsString = null, fetcher = NotificationFetcher) => {
  return useQueryWithAuthentication(
    ['notifications', endpoint, urlParamsString],
    (accessToken) => fetcher(accessToken, endpoint, urlParamsString),
    options
  )
}


export const useNotificationUnreadByUser = (user_uid, limit = null, offset = null, sources = []) => {
  const endpoint = `user/${user_uid}/unread`;
  const urlParams = new URLSearchParams();

  if (limit !== null) {
    urlParams.set('limit', limit);
  }

  if (offset !== null) {
    urlParams.set('offset', offset);
  }

  _.forEach(sources, (source) => urlParams.append('source', source));

  return useNotificationQuery(
    endpoint,
    {enabled: !_.isNull(user_uid) && !_.isUndefined(user_uid), staleTime: 0, cacheTime: 0},
    urlParams.toString()
  );
}


export const useNotificationUnreadSummaryByUser = (user_uid) => {
  const endpoint = `user/${user_uid}/unread/summary`;

  return useNotificationQuery(
    endpoint,
    {
      enabled: !_.isNull(user_uid) && !_.isUndefined(user_uid),
      refetchInterval: 120000,
      cacheTime: Infinity
    }
  );
}


export const useAllNotificationByUser = (user_uid, limit = null, offset = null, sources = []) => {
  const endpoint = `user/${user_uid}`;
  const urlParams = new URLSearchParams();

  if (limit !== null) {
    urlParams.set('limit', limit);
  }

  if (offset !== null) {
    urlParams.set('offset', offset);
  }

  _.forEach(sources, (source) => urlParams.append('source', source));

  return useNotificationQuery(
    endpoint,
    {enabled: !_.isNull(user_uid) && !_.isUndefined(user_uid), staleTime: 0, cacheTime: 0},
    urlParams.toString()
  );
}

export const useNotificationTypes = () => {
  const endpoint = `types`;

  return useNotificationQuery(endpoint, {staleTime: Infinity});
}