import ColorTagInlineBadge from "./ColorTagInlineBadge";
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const MaliciousStatsBadge = ({stats}) => {
  let color;
  const malicious = stats?.malicious ?? '0';
  const total = _.sum(_.values(stats));

  if (malicious <= 10) {
    color = 'success';
  } else if (malicious === 0 && total === 0) {
    color = 'white'
  } else if (malicious <= 20) {
    color = 'warning';
  } else {
    color = 'danger';
  }

  return <ColorTagInlineBadge badgeStyle={{margin: '0px 5px'}} color={color} tag={`${malicious} / ${total} DETECTION`} />
}


MaliciousStatsBadge.propTypes = {
  stats: PropTypes.object.isRequired,
}

export default MaliciousStatsBadge;
