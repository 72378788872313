import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const AddOrganizationFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/organizations`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useAddOrganizationMutation = (fetcher = AddOrganizationFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-organization'],
    (accessToken, params) => fetcher(accessToken, params),
    {onSuccess: () => {
        queryClient.invalidateQueries('add-organization');
        queryClient.invalidateQueries(['organizations', null]);
      }}
  )
}
