import React, {Fragment} from "react";
import PropTypes from "prop-types";
import allCountries from "../maps/EasyVectorMap/sample-data.json";
import {countByField} from "../../helpers";
import SimpleMapsWithData from "../maps/EasyVectorMap/SimpleMapsWithData";


const _ = require('lodash')


const BotNetSourceMap = ({botnets}) => {
  const sourceCountries = countByField(botnets, (val) => val.geoip?.country_code2);

  const sourceCountriesArray = _.keys(sourceCountries).map((iso_code) => ({
    id: iso_code,
    value: sourceCountries[iso_code]
  }))

  const completeSourceCountries = allCountries.map((country) => {
    const match = _.find(sourceCountriesArray, (c) => c.id === country.id);
    if (!match) {
      return {id: country.id, value: 0}
    } else {
      return {id: match.id, value: match.value}
    }
  });

  return (
    <Fragment>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>BOTNET SOURCE COUNTRIES</h4>
      </div>
      <SimpleMapsWithData
        data={_.maxBy(completeSourceCountries, (val) => val.value).value === 0 ? [] : completeSourceCountries}
        height={'70vh'}
        valueLabel={'bots'}
      />
    </Fragment>
  )
}


BotNetSourceMap.propTypes = {
  botnets: PropTypes.array.isRequired
}


export default BotNetSourceMap;
