import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const DeleteActorsFetcher = (uid, accessToken) => {
  return axios.delete(`${apiUrlInternal}/actors/${uid}`, addAuthToFetchOpts(accessToken, {})).then(res => res.data );
}

export const useDeleteActors = (fetcher = DeleteActorsFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-actor'],
    (accessToken, uid) => fetcher(uid, accessToken),
    {onSuccess: () => {
        queryClient.invalidateQueries('delete-actor');
        queryClient.invalidateQueries(['actor', 'detail', null]);
      }}
  )
}
