import React from "react";
import {Card, CardBody} from "reactstrap";
import {useFeedlyFeedsQuery} from "../../../../queries/FeedlyFeeds";
import {Link} from "react-router-dom";
import paths from "../../../../config/paths";
import TableWithData from "../../../../shared/components/table/TableWithData";
import {formatDateUTCtoYearMonthDayTime} from "../../../../shared/helpers/date";
import PropTypes from "prop-types";

const tableColumns = [
  {Header: 'Title', accessor: 'article_title'},
  {Header: 'Author', accessor: "source_title"},
  {Header: 'Date', accessor: 'date', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
  {Header: 'Article Url', accessor: 'article_url', Cell: ({value}) => <a href={value}>{value}</a>},
];


const LastNewsFeedlyFeedsTable = ({limit = 10, ...props}) => {
  const {data, isIdle, isLoading, isError} = useFeedlyFeedsQuery(limit);
  const tableConfig = {
    withPagination: false,
    withHover: false,
    manualPageSize: [limit],
    striped: true
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}><Link to={paths.feedlyFeedsPath}>Latest News</Link></h4>
        </div>
        <TableWithData
          data={data?.data}
          isLoading={isLoading || isIdle}
          isError={isError}
          tableColumns={tableColumns}
          tableConfig={tableConfig}
          {...props}
        />
      </CardBody>
    </Card>
  )
}

LastNewsFeedlyFeedsTable.propTypes = {
  limit: PropTypes.number
}

export default LastNewsFeedlyFeedsTable;
