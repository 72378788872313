import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import ThemeModal from "../../../shared/components/ThemeModal";
import {useForm} from "react-hook-form";
import ControlledSelectWithTitle from "../../../shared/components/form/ControlledSelectWithTitle";
import {getErrorMessageFromResponse, upperCaseFirstLetter} from "../../../shared/helpers";
import toast from "react-hot-toast";
import {useAddTicketMutation} from '../../../mutations/Tickets/AddTicket';
import RegisteredInputField from '../../../shared/components/form/RegisteredInputField';
import ControlledFilepond from '../../../shared/components/form/ControlledFilepond';
import {useCookieAccessToken} from '../../../queries';
import {apiUrlInternal} from '../../../config/api';
import axios from 'axios';
import {addAuthToAxiosOpts} from '../../../config/queryopts';
import ErrorHandler from '../../../shared/components/ErrorHandler';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import {TLP_LABELS} from '../../../shared/helpers/tlp';
import PropTypes from "prop-types";


const TicketModal = ({disabled}) => {
  const [showModal, setShowModal] = useState(false);
  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);
  const defaultValues = {url: '', priority: {label: 'Low', value: 'low'}};
  const {register, handleSubmit, formState: {errors}, control} = useForm({
    defaultValues: defaultValues
  });
  const mutation = useAddTicketMutation();
  const toggleModal = useCallback(() => setShowModal(!showModal), [setShowModal, showModal]);

  const priorityOptions = ['low', 'medium', 'high'].map((sev) => ({
    value: sev, label: upperCaseFirstLetter(sev)
  }));

  const btnClass = buttonUploadDisabled ? 'disabled rounded float-right' : 'rounded float-right';
  const {
    data: accessToken,
    isError: tokenIsError,
    isLoading: tokenIsLoading,
    isIdle: tokenIsIdle,
    error
  } = useCookieAccessToken()

  const tlpOptions = TLP_LABELS.map((tlp, index) => ({value: index, label: tlp}));
  
  const onSubmit = (data) => {
    if (data.report && data.report.length > 0) {
      setButtonUploadDisabled(true);
      const formData = new FormData();
      formData.append('file', data.report[0].file, data.report[0].filename);
      toast.loading('Validating and uploading file.');
      axios.post(`${apiUrlInternal}/tickets/attachment`, formData,
        addAuthToAxiosOpts(accessToken, {'Content-Type': 'multipart/form-data'}, 0),
      ).then((res) => {
        if (res.status === 200) {
          toast.dismiss();
          toast.loading('Validating and creating the ticket');
          mutation.mutate({
            title: data.title,
            attachment_uid: res.data.data.uid,
            description: data.description,
            priority: data.priority.value,
            tlp:data.tlp.value
          });
        } else {
          toast.dismiss();
          toast.error(`Error while uploading the attachment. Please contact the administrator.`);
          setButtonUploadDisabled(false);
        }
      }).catch(() => {
        toast.dismiss();
        toast.error(`Error while uploading the attachment. Please contact the administrator.`);
        setButtonUploadDisabled(false);
      })
    } else {
      toast.loading('Validating and creating the ticket');
      mutation.mutate({
        title: data.title,
        description: data.description,
        priority: data.priority.value,
        tlp:data.tlp.value
      });
    }
  }

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      setButtonUploadDisabled(false);
      if (mutation.isSuccess) {
        toast.success(`Your task has been uploaded`);
        toggleModal();
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.error, mutation.isSuccess])

  if (tokenIsIdle || tokenIsLoading) {
    return <Container><LoadingSpinner/></Container>
  }

  if (tokenIsError) {
    return <ErrorHandler error={error}/>
  }

  return (
    <Fragment>
      <Button
        className="rounded float-right"
        outline size="sm"
        onClick={toggleModal}
        disabled={disabled}
      >
        Open Ticket
      </Button>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header-xl modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal}/>
          <h4 className="text-modal modal__title">Create ticket</h4>
        </div>

        <div className="modal__body">
          <form className="form form--vertical">
            <Container>
              <Row>
                <Col md={6}>
                  <RegisteredInputField
                    title={'Title'}
                    name={'title'}
                    register={register}
                    placeholder={'Title'}
                    errors={errors}
                    rules={{required: 'The title is required'}}
                  />
                </Col>
                <Col md={3}>
                  <ControlledSelectWithTitle
                    name={'priority'}
                    title={'Priority'}
                    rules={{required: 'The priority is required'}}
                    defaultValue={defaultValues?.priority} control={control} valueFn={(value) => value}
                    options={priorityOptions}
                  />
                </Col>
                <Col md={3}>
                  <ControlledSelectWithTitle
                    name={'tlp'} 
                    title={'TLP'} 
                    control={control}
                    rules={{required: 'You must specify the TLP of the application'}}
                    options={tlpOptions} 
                    defaultValue={tlpOptions[0]}
                    valueFn={(value) => tlpOptions.find((c) => c.value === value?.value)}
                  />
                </Col>
                
              </Row>
              <Row>
                <Col md={12}>
                  <span className={'form__form-group-label'}>Description</span>
                  <textarea
                    name={'description'}
                    placeholder={"Describe the case"}
                    className={'textarea--add-contents form-group'}
                    {...register('description')}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <span className={'form__form-group-label'}>Attachment</span>
                  <ControlledFilepond
                    control={control}
                    name={'report'}
                    allowMultiple={false}
                    allowRevert={false}
                    maxFiles={1}
                    instantUpload={false}
                    defaultValue={defaultValues?.url}
                    labelIdle={'Drag & Drop your report or <span class="filepond--label-action">Browse</span>'}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Button outline
                          className="rounded"
                          onClick={toggleModal}>CANCEL</Button>
                </Col>
                <Col md={6}>
                  <Button
                    outline
                    className={btnClass}
                    color={'success'}
                    onClick={() => handleSubmit(onSubmit)()}>OPEN</Button>
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      </ThemeModal>
    </Fragment>
  );
};


TicketModal.propTypes = {
  disabled: PropTypes.bool
}

export default TicketModal;
