import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";


export const ApplicationDetailsFetcher = (accessToken, uid = null) => {
  const uri = (uid) ? `/${uid}` : '';
  return axios.get(`${apiUrlInternal}/applications${uri}`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useApplicationDetailsQuery = (uid = null, fetcher = ApplicationDetailsFetcher) => {
  return useQueryWithAuthentication(['application-details', uid],
    (accessToken) => fetcher(accessToken, uid), {refetchOnWindowFocus: false})
}

useApplicationDetailsQuery.propTypes = {
  uid: PropTypes.string,
};
