import _ from "lodash";
import {formatDateUTCtoYearMonthDay} from "./date";

export const SEC_COMMUNICATING_FILES = 'communicating_files';
export const SEC_DROPPED_FILES = 'dropped_files';
export const SEC_CONTACTED_DOMAINS = 'contacted_domains';
export const SEC_CONTACTED_URLS = 'contacted_urls';
export const SEC_CONTACTED_IPS = 'contacted_ips';
export const SEC_RELATED_INDICATORS_INNER = 'related_indicators_inner';
export const SEC_RELATED_INDICATORS = 'related_indicators';
export const SEC_INDICATOR = 'indicator';

export const NODE_TOOLTIP_CLASS = '#node_tool_tip';

const NODE_STRENGTH = 0.7;


const getNodeIcon = (type) => {
  let path;
  switch (type) {
    case SEC_COMMUNICATING_FILES:
    case SEC_DROPPED_FILES:
      path = 'M9,7V17H11V13H14V11H11V9H15V7H9Z';
      break;
    case SEC_CONTACTED_DOMAINS:
      path = 'M9,7V17H13A2,2 0 0,0 15,15V9A2,2 0 0,0 13,7H9M11,9H13V15H11V9Z';
      break;
    case SEC_CONTACTED_URLS:
      path = 'M9,7V15A2,2 0 0,0 11,17H13A2,2 0 0,0 15,15V7H13V15H11V7H9Z';
      break;
    case SEC_CONTACTED_IPS:
      path = 'M14,7V9H13V15H14V17H10V15H11V9H10V7H14ZZ';
      break;
    case SEC_RELATED_INDICATORS_INNER:
    case SEC_RELATED_INDICATORS:
      path = 'M9,7V17H11V13H11.8L13,17H15L13.76,12.85C14.5,12.55 15,11.84 15,11V9A2,2 0 0,0 13,7H9M11,9H13V11H11V9Z';
      break;
    default:
      path = 'M14,7V9H13V15H14V17H10V15H11V9H10V7H14ZZ';
  }
  return path;
}


const add_node = (baseNodes, baseLinks, indicator_details, label, get_value) => {
  if (indicator_details && indicator_details[label] && indicator_details[label].length > 0) {
    baseNodes.push({
      id: label,
      group: 1,
      label: _.upperFirst(label.replace('_', ' ')),
      level: 2,
      data: indicator_details,
      type: label,
      icon: getNodeIcon(label)
    });
    baseLinks.push({target: SEC_INDICATOR, source: label, strength: NODE_STRENGTH});
    indicator_details[label].forEach((cf, cfi) => {
      if (get_value(cf)){
        baseNodes.push({
          id: get_value(cf),
          group: 1,
          label: get_value(cf),
          level: 3,
          data: indicator_details[label][cfi],
          type: label,
          icon: getNodeIcon(label)
        });
        baseLinks.push({target: label, source: get_value(cf), strength: NODE_STRENGTH});
      }
    });
  }
}


export const getNodesInfo = (indicator_details) => {
  let baseNodes = [];
  let baseLinks = [];

  baseNodes.push({
    id: 'indicator', group: 0, label: indicator_details.indicator, level: 1,
    data: indicator_details, type: SEC_INDICATOR, icon: getNodeIcon(SEC_INDICATOR)
  });

  add_node(baseNodes, baseLinks, indicator_details, SEC_COMMUNICATING_FILES, (c) => c.md5);
  add_node(baseNodes, baseLinks, indicator_details, SEC_CONTACTED_URLS, (c) => c.value);
  add_node(baseNodes, baseLinks, indicator_details, SEC_DROPPED_FILES, (c) => c.md5);
  add_node(baseNodes, baseLinks, indicator_details, SEC_CONTACTED_IPS, (c) => c.value);
  add_node(baseNodes, baseLinks, indicator_details, SEC_CONTACTED_DOMAINS, (c) => c.value);

  if (
    indicator_details && indicator_details.related_indicators &&
    indicator_details.related_indicators.by_content &&
    indicator_details.related_indicators.by_content.length > 0
  ) {
    baseNodes.push({
      id: SEC_RELATED_INDICATORS,
      group: 1,
      label: 'Related contents',
      level: 2,
      data: indicator_details,
      type: SEC_RELATED_INDICATORS,
      icon: getNodeIcon(SEC_RELATED_INDICATORS)
    });
    baseLinks.push({target: SEC_INDICATOR, source: SEC_RELATED_INDICATORS, strength: NODE_STRENGTH});
    indicator_details.related_indicators.by_content.forEach((ri, rii) => {
      const related_context = indicator_details.related_contexts.find((c) => c.uid === ri.content_uid);
      baseNodes.push({
        id: ri.content_uid,
        group: 1,
        label: `${_.upperFirst(related_context.type)} ${formatDateUTCtoYearMonthDay(related_context.created_dt)}`,
        level: 3,
        data: indicator_details.related_indicators.by_content[rii],
        type: SEC_RELATED_INDICATORS,
        icon: getNodeIcon(SEC_RELATED_INDICATORS)
      });
      baseLinks.push({target: SEC_RELATED_INDICATORS, source: ri.content_uid, strength: NODE_STRENGTH});
      if (ri.indicators && ri.indicators.length > 0) {
        ri.indicators.forEach((i, ii) => {
          baseNodes.push({
            id: i.value,
            group: 2,
            label: i.value,
            level: 4,
            data: ri.indicators[ii],
            type: SEC_RELATED_INDICATORS_INNER,
            icon: getNodeIcon(SEC_RELATED_INDICATORS_INNER)
          });
          baseLinks.push({target: ri.content_uid, source: i.value, strength: NODE_STRENGTH});
        });
      }
    });
  }

  return [baseNodes, baseLinks];
}

export const canIShowToolTip = (node) => {
  return (
      node.type === SEC_COMMUNICATING_FILES ||
      node.type === SEC_DROPPED_FILES ||
      node.type === SEC_CONTACTED_DOMAINS ||
      node.type === SEC_CONTACTED_IPS ||
      node.type === SEC_CONTACTED_URLS
    ) && node.level === 3 ||
    node.type === SEC_RELATED_INDICATORS_INNER && node.level === 4;
}
