import React from "react";
import {Col, Row} from "reactstrap";
import {formatDateUTCtoYearMonthDayTimeAMPM} from "../../../shared/helpers/date";
import PropTypes from "prop-types"
import {LimitBadges} from "../../../shared/helpers/LimitBadges";
import RuleInfoModal from "./RuleInfoModal";


const _ = require('lodash');


const RuleItem = ({style, rule, onRowClick, onTagClick}) => {
  return (
    <Row
      style={{...style, cursor: !_.isNull(onRowClick) ? 'pointer' : 'auto'}}
      className={'align-items-center row--rule'}
      onClick={!_.isNull(onRowClick) ? onRowClick : () => {
      }}
    >
        <Col md={1} xs={1}>
          <RuleInfoModal body={rule.text} title={rule.title}/>
        </Col>
        <Col md={1} xs={1}>
          <p>{rule.type}</p>
        </Col>
        <Col md={3} xs={3}>
          <p>{rule.title}</p>
        </Col>
        <Col md={3} xs={3}>
          <LimitBadges onTagClick={onTagClick} limit={2} items={rule.tags?.length ? rule.tags : []} itemValue={(tag) => tag ? tag : ""}/>
        </Col>
        <Col md={2} xs={2}>
          <p>{formatDateUTCtoYearMonthDayTimeAMPM(rule.created_at)}</p>
        </Col>
        <Col md={2} xs={2}>
          <p>{formatDateUTCtoYearMonthDayTimeAMPM(rule.modified_at)}</p>
        </Col>

    </Row>
  )
}

RuleItem.propTypes = {
  style: PropTypes.shape().isRequired,
  rule: PropTypes.shape().isRequired,
  onRowClick: PropTypes.func.isRequired,
  onTagClick: PropTypes.func,
  rulesSection: PropTypes.bool
}

export default RuleItem;