import {Button, Col, Row} from "reactstrap";
import CloseIcon from "mdi-react/CloseIcon";
import ControlledSelectWithTitle from "../../ControlledSelectWithTitle";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {useFieldArray} from "react-hook-form";
import classNames from "classnames";
import {useAddContentFormQuery} from "../../../../../queries/AddContentForm";
import {getTagOptionsByTypes} from "../../../../helpers/contents";
import {flatten} from "../../../../helpers/index";
import {value} from "lodash/seq";

const _ = require('lodash')



const CommandsForm = ({control, errors, register}) => {
  let isErrorIdleLoading = false;
  const {
    tags: {data: dataTags, ...tagsProps},
    mitre: {data: dataMITRE, ...mitreTechniquesProps},
  } = useAddContentFormQuery();

  _.forEach([mitreTechniquesProps, tagsProps], (props) => {
    isErrorIdleLoading |= props.isError || props.isLoading || props.isIdle;
  })

  const {fields: fieldsCommands, append: appendCommands, remove: removeCommands} = useFieldArray(
    {control, name: "commands"}
  );

  if (isErrorIdleLoading) {
    return <Fragment/>
  }

  const mitreTechniques = flatten(dataMITRE.data, 'techniques').map((technique) => ({
    value: technique.uid,
    label: `${technique.mitre_code} - ${technique.name}`
  }))
  const [commonTagOptions, malwareFamilyTagOptions, ...rest] = getTagOptionsByTypes(dataTags.data);

  return (
    <Fragment>
      {
        fieldsCommands.map((item, index) =>
          <Row className={'w-100'} key={item.id}>
            <Col md={3} lg={3} xl={2}>
              <Row>
                <Col md={2} className={'d-flex flex-column'}>
                  <CloseIcon
                    style={{fill: 'white', cursor: 'pointer', marginBottom:'10px'}}
                    onClick={() => removeCommands(index)}
                  />
                </Col>
                <Col md={10} lg={10} xl={10}>
                  <div className="form__form-group">
                    <ControlledSelectWithTitle
                      name={`commands.${index}.techniques`} title={'Mitre Techniques'} control={control}
                      isSearchable isMulti isClearable valueFn={(value) => value}
                      options={mitreTechniques}
                    />
                    <ControlledSelectWithTitle
                      name={`commands.${index}.malware_families`} title={'Malware Families'} control={control}
                      isClearable isSearchable isMulti valueFn={(value) => value}
                      options={malwareFamilyTagOptions}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={9} lg={9} xl={9} >
              <Row>
                <div className={classNames({
                  'form__form-group': true,
                  "form__form-group-error-validation": errors?.commands && errors.commands[index] && errors.commands[index].text
                })}>
                  <span className="form__form-group-label">Command</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                    <textarea
                      name={'text'}
                      placeholder={"text"}
                      {...register(`commands.${index}.value`)}
                      className={'textarea--command-body'}
                    />
                      {
                        errors?.commands && errors.commands[index] && errors.commands[index].text
                        && <span className="form__form-group-error">{errors.commands[index].text}</span>
                      }
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <div className={classNames({
                  'form__form-group': true,
                  "form__form-group-error-validation": errors?.commands && errors.commands[index] && errors.commands[index].description
                })}>
                  <span className="form__form-group-label">Command description</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                    <textarea
                      name={'description'}
                      placeholder={"Description"}
                      {...register(`commands.${index}.description`)}
                      className={'textarea--command-description'}
                    />
                      {
                        errors?.commands && errors.commands[index] && errors.commands[index].description
                        && <span className="form__form-group-error">{errors.commands[index].description}</span>
                      }
                    </div>
                  </div>
                </div>
              </Row>
            </Col>

          </Row>
        )
      }
      <Row className={'justify-content-end w-100'}>
        <Button outline color={'success'} onClick={() => appendCommands({})}>
          Add command
        </Button>
      </Row>
    </Fragment>
  )
}


CommandsForm.propsType = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  register: PropTypes.func.isRequired,
}


export default CommandsForm;
