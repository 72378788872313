import _ from "lodash";
import env_const from "../../config/env_const";
import {
  PLATFORM_FEATURE_ACTORS,
  PLATFORM_FEATURE_APPLICATIONS, PLATFORM_FEATURE_CONTENTS,
  PLATFORM_FEATURE_ORGANIZATIONS, PLATFORM_FEATURE_USERS,
  PLATFORM_PERMISSION_EDITOR,
  PLATFORM_PERMISSION_VIEWER
} from "./features";
import {ADMIN_ROLE, CUSTOMER_ROLE, DEMO_ROLE, EDITOR_ROLE, INTERNAL_ROLE, OPERATOR_ROLE, SALES_ROLE} from "./user";
import {
  MANAGEMENT,
  REPORT_AND_ANALYSIS,
  SURFACE_ATTACK_MANAGEMENT,
  TRIAGE
} from "./modules";
import FabEventMenu from "../../containers/Contents/components/FabEventMenu";
import React from "react";

export const isUserEditorOrAdmin = (user) => (user?.role === ADMIN_ROLE || user?.role === EDITOR_ROLE);

export const isUserDemo = (user) => user?.role === DEMO_ROLE;

export const canUserEditTickets = (user) => isUserEditorOrAdmin(user) && !env_const.is_on_prem;

export const hasOnPremPlatform = (user) => user?.organization && user?.organization?.has_on_prem_platform ? user.organization.has_on_prem_platform : false;

export const hasUserKeywords = (user) => {
  return _.isUndefined(user?.keyword_count) || _.isNull(user?.keyword_count) || user.keyword_count > 0
}

export const isUserInternal = (user) => user?.role === INTERNAL_ROLE;

export const getFormattedFeaturesList = (items) => {
  let finalGroups = [];
  items.forEach((g, index) => {
    let group = {uid: g.uid, z_order: g.z_order ? g.z_order : index, features: []};
    if (g.features && g.features.length > 0) {
      g.features.forEach((f, innerIndex) => {
        if (f.selected) {
          group.features.push({
            uid: f.uid,
            z_order: f.z_order ? f.z_order : innerIndex ?? innerIndex,
            permission: f.permission ? f.permission : 'viewer'
          })
        }
      })
    }
    if (g.selected) {
      finalGroups.push(group);
    }
  })
  return finalGroups;
};

export const isFeatureExistsForCurrentUser = (modules, feature) =>
  modules && feature ? _.filter(
    _.flatten(modules.map((m) => m.features)),
    (f) => f.name === feature
  ).length === 1 : false


export const isModuleExistsForCurrentUser = (modules, module) =>
  _.filter(modules, (m) => m.name === module).length === 1


export const getUserFeature = (modules, feature) =>
  isFeatureExistsForCurrentUser(modules, feature) ? _.filter(
    _.flatten(modules.map((m) => m.features)),
    (f) => f.name === feature
  )[0] : []


export const isAllFeaturesEnabledForRole = (modules, role) =>
   _.every(modules, (m) =>
    _.every(m.features, (f) =>
      (f.selected && userRoleModuleEnabled(role, m.name, f.name)) ||
      !userRoleModuleEnabled(role, m.name, f.name) || _.isUndefined(f.selected)
    )
  )


export const hasUserEditorPermissionOnFeature = (modules, feature) =>
  _.filter(
    _.flatten(modules.map((m) => m.features)),
    (f) => f.name === feature && f.permission === PLATFORM_PERMISSION_EDITOR
  ).length === 1


const INTERNAL_AUDIENCE = 'internal';
const EXTERNAL_AUDIENCE = 'external';


export const audienceCreatableByRole = (role) => {
  switch (role) {
    case ADMIN_ROLE:
      return [INTERNAL_AUDIENCE, EXTERNAL_AUDIENCE];
    default:
      return [EXTERNAL_AUDIENCE]
  }
}

export const userCreatableByRole = (role) => {
  switch (role) {
    case ADMIN_ROLE:
      return [ADMIN_ROLE, INTERNAL_ROLE, DEMO_ROLE, EDITOR_ROLE, SALES_ROLE, CUSTOMER_ROLE, OPERATOR_ROLE];
    case OPERATOR_ROLE:
      return [SALES_ROLE, CUSTOMER_ROLE, DEMO_ROLE];
    default:
      return []
  }
}

export const canRoleUpdateAllUsersFields = (role) =>
  [ADMIN_ROLE, OPERATOR_ROLE].includes(role)


export const userRoleModuleEnabled = (role, group, feature = null) => {
  const roles_permission = {
    [ADMIN_ROLE]: {
      [REPORT_AND_ANALYSIS]: PLATFORM_PERMISSION_EDITOR,
      [TRIAGE]: PLATFORM_PERMISSION_EDITOR,
      [SURFACE_ATTACK_MANAGEMENT]: PLATFORM_PERMISSION_EDITOR,
      [MANAGEMENT]: PLATFORM_PERMISSION_EDITOR
    },
    [EDITOR_ROLE]: {
      [REPORT_AND_ANALYSIS]: PLATFORM_PERMISSION_EDITOR,
      [TRIAGE]: PLATFORM_PERMISSION_EDITOR,
      [SURFACE_ATTACK_MANAGEMENT]: PLATFORM_PERMISSION_EDITOR,
      [MANAGEMENT]: null
    },
    [CUSTOMER_ROLE]: {
      [REPORT_AND_ANALYSIS]: PLATFORM_PERMISSION_EDITOR,
      [TRIAGE]: PLATFORM_PERMISSION_EDITOR,
      [SURFACE_ATTACK_MANAGEMENT]: PLATFORM_PERMISSION_EDITOR,
      [MANAGEMENT]: {
        [PLATFORM_FEATURE_USERS]: PLATFORM_PERMISSION_EDITOR,
        [PLATFORM_FEATURE_ORGANIZATIONS]: null,
        [PLATFORM_FEATURE_APPLICATIONS]: null
      }
    },
    [DEMO_ROLE]: {
      [REPORT_AND_ANALYSIS]: PLATFORM_PERMISSION_VIEWER,
      [TRIAGE]: PLATFORM_PERMISSION_EDITOR,
      [SURFACE_ATTACK_MANAGEMENT]: PLATFORM_PERMISSION_EDITOR,
      [MANAGEMENT]: null
    },
    [INTERNAL_ROLE]: {
      [REPORT_AND_ANALYSIS]: PLATFORM_PERMISSION_VIEWER,
      [TRIAGE]: PLATFORM_PERMISSION_EDITOR,
      [SURFACE_ATTACK_MANAGEMENT]: PLATFORM_PERMISSION_EDITOR,
      [MANAGEMENT]: null
    },
    [SALES_ROLE]: {
      [REPORT_AND_ANALYSIS]: PLATFORM_PERMISSION_VIEWER,
      [TRIAGE]: PLATFORM_PERMISSION_EDITOR,
      [SURFACE_ATTACK_MANAGEMENT]: PLATFORM_PERMISSION_EDITOR,
      [MANAGEMENT]: {
        [PLATFORM_FEATURE_USERS]: PLATFORM_PERMISSION_EDITOR,
        [PLATFORM_FEATURE_ORGANIZATIONS]: PLATFORM_PERMISSION_VIEWER,
        [PLATFORM_FEATURE_APPLICATIONS]: null
      }
    },
    [OPERATOR_ROLE]: {
      [REPORT_AND_ANALYSIS]: null,
      [TRIAGE]: null,
      [SURFACE_ATTACK_MANAGEMENT]: null,
      [MANAGEMENT]: PLATFORM_PERMISSION_EDITOR
    },
    APPLICATION: {
      [REPORT_AND_ANALYSIS]: PLATFORM_PERMISSION_VIEWER,
      [TRIAGE]: PLATFORM_PERMISSION_EDITOR,
      [SURFACE_ATTACK_MANAGEMENT]: PLATFORM_PERMISSION_EDITOR,
      [MANAGEMENT]: null
    }
  }

  if (_.isNull(role) || _.isUndefined(role)) {
    return null;
  }


  if (_.isNull(feature)) {
    return roles_permission[role][group]
  } else {
    return roles_permission[role][group] instanceof Object && !_.isUndefined(roles_permission[role][group][feature]) ?
      roles_permission[role][group][feature] : roles_permission[role][group]
  }
}

export const canUserManageActor = (user) =>
  hasUserEditorPermissionOnFeature(user.modules, PLATFORM_FEATURE_ACTORS) && user.role !== CUSTOMER_ROLE


export const canUserCreateMalwareFamily = (user) =>
  hasUserEditorPermissionOnFeature(user.modules, PLATFORM_FEATURE_CONTENTS) && user.role !== CUSTOMER_ROLE

export const canUserCreateContent = (user) => {
  if (
    hasUserEditorPermissionOnFeature(user.modules, PLATFORM_FEATURE_CONTENTS) &&
    ((!env_const.is_clone && user.role !== CUSTOMER_ROLE) || (env_const.is_clone))
  ) {
    return true;
  }

  return false;
}

export const canUserEditContent = (user, content) => {
  if (
    hasUserEditorPermissionOnFeature(user.modules, PLATFORM_FEATURE_CONTENTS) &&
    ((!env_const.is_clone && user.role !== CUSTOMER_ROLE) || (env_const.is_clone && content.is_private))
  ) {
    return true;
  }

  return false;
}