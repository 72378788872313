import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {useCookieAccessToken} from "../../../queries";
import ConfirmModal from "../modal/ConfirmModal";
import {useReportPurchaseMutation} from "../../../mutations/Quotas/ReportPurchase";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../helpers";
import {useReportRemainingQuotaQuery} from "../../../queries/ReportRemainingQuota";
import Loading from "../Loading";
import LoadingSpinner from "../LoadingSpinner";


const PurchaseContent = ({info, org}) => {
  const {data: accessToken} = useCookieAccessToken();
  const mutation = useReportPurchaseMutation();

  const { data, isIdle, isLoading, error } = useReportRemainingQuotaQuery(
    org?.org_type === 'mssp'
  );

  const onPurchaseAccept = () => {
    toast.loading('Sending request to buy this report');
    mutation.mutate({
      content_id: info['uid']
    });
  };

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isError) toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])

  return (
    <>
      {info?.is_report_purchased === false &&
        <Card className={'h-auto'}>
          <CardBody className="text-white">
            {
              !info['is_report_purchased'] &&
              <Row>
                <Col md={12} sm={12} className={'pt-2'}>
                  {
                    org.managed_by ?
                      <span>Contact your MSSP provider to purchase this report.</span>:
                      <span>You have to purchase the access to report, attachments or rules to view/download them.</span>
                  }

                </Col>
                <Col className={'pt-2'}>
                  {
                    (!isLoading && !isIdle) || org.managed_by ?
                      <ConfirmModal
                        onConfirm={() => onPurchaseAccept()}
                        btnText={"Purchase"}
                        title={"Purchase report and attachments"}
                        body={
                          data?.data?.count === 0 ?
                          `You have no more quotas to buy the report. Please contact the sales to extend this limit`:
                            `Are you sure to purchase the access to attachments and report for your organization? 
                           You can buy only ${data?.data?.count} more reports.`
                        }
                        disabled={org.managed_by || data?.data?.count === 0}
                      /> : <LoadingSpinner />
                  }
                </Col>
              </Row>
            }
          </CardBody>
        </Card>
      }
    </>
  )
}


PurchaseContent.propsType = {
  info: PropTypes.array.isRequired,
  org: PropTypes.object.isRequired
}

export default PurchaseContent;
