import TableWithData from "../../../shared/components/table/TableWithData";
import React from "react";
import PropTypes from "prop-types";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";
import DownloadFileIcon from "../../../shared/components/icons/DownloadFileIcon";
import {apiUrlInternal} from "../../../config/api";
import { bytesToSizeOrNA } from "../../../shared/helpers";


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
}

const tableColumns = [
  {Header: 'File name', accessor: (row) => row['filename']},
  {
    Header: 'File size',
    accessor: 'filesize',
    Cell: ({row}) => <span>{ bytesToSizeOrNA(row.original.filesize) }</span>
  },
  {Header: 'Created Time', accessor: 'created_dt', Cell: ({value}) => formatDateUTCtoYearMonthDayTime(value)},
  {
    Header: '', accessor: 'uid', Cell: ({row}) => <DownloadFileIcon
      url={`${apiUrlInternal}/takedown_tickets/attachment/` + row.original.uid}
      filename={row.original.filename}
      style={{marginRight: '20px', float: 'right'}}
    />
  }
];


const TakedownTicketAttachmentTable = ({attachments}) =>
  <TableWithData
    data={attachments}
    tableColumns={tableColumns}
    tableConfig={tableConfig}
  />



TakedownTicketAttachmentTable.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired
}


export default TakedownTicketAttachmentTable;
