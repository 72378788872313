import {Button, Card, CardBody, Row} from "reactstrap";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import CountryBadge from "../../../shared/components/badge/CountryBadge";
import {Link, useHistory} from "react-router-dom";
import paths from "../../../config/paths";
import TargetAccountIcon from "mdi-react/TargetAccountIcon";
import {HashLink} from 'react-router-hash-link';
import classNames from "classnames";
import ThemedClampLines from "../clamp-lines/ThemedClampLines";
import PencilOutlineIcon from "mdi-react/PencilOutlineIcon";
import DeleteIconModal from "../modal/DeleteIconModal";
import {DeleteActorsFetcher, useDeleteActors} from "../../../mutations/DeleteActors";
import toast from "react-hot-toast";
import {actorExportFormatEnum} from "../../helpers/download_file";

const _ = require('lodash')


const ActorDetailCard = ({
  actor, withLink = false,
  withTruncateText = false,
  withDecoration = false,
  withEditableButton = false,
  style = {},
  updateFilters = null,
  exportActor = null
}) => {
  const classes = classNames({
    "card__with_background_icon": true,
    "card__with_background_icon_decoration": withDecoration
  })
  const history = useHistory();
  const mutation = useDeleteActors();

  useEffect(() => {
    if (!mutation.isIdle) {
      toast.dismiss()
      if (mutation.isLoading) {
        toast.loading('Deleting actor');
      } else {
        if (mutation.isSuccess) {
          history.push(paths.actorPath);
        } else if (mutation.isError) {
          toast.error('Error during the deletion of the actor');
          mutation.reset();
        }
      }
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.isSuccess])

  return (
    <Card style={style} className={classes}>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>
            {withLink ? <Link to={`${paths.actorPath}/${actor.uid}`}>{actor.name}</Link> : actor.name}
          </h4>
          <span
            onClick={() => updateFilters !== null && updateFilters({
              'type': 'origin_country',
              'name': actor.origin_country
            })}
            className={`float-right ${updateFilters !== null ? 'cursor' : ''}`}>
            <CountryBadge countryCode={actor.origin_country} style={{fontSize: '12px'}}/>
          </span>
          {
            actor.aliases.length > 0 &&
            <p className={'subhead'}>Know as <b>{_.join(actor.aliases, ', ')}</b></p>
          }
        </div>
        {actor.state_sponsored && <h5>This is a <b>state sponsored</b> group.</h5>}
        {withTruncateText ? <p className="description"><ThemedClampLines
          text={actor.description ? actor.description : ''}
        /></p> : <p>{actor.description}</p>}

        <TargetAccountIcon/>
        {
          withEditableButton &&
          <div className="actionBar">
            <PencilOutlineIcon onClick={(e) => {
              e.stopPropagation();
              history.push(`${paths.actorPath}/edit/${actor.uid}`);
            }} className="border-hover-white mr-3 editIcon"/>
            <DeleteIconModal
              onDelete={() => mutation.mutate(actor.uid)}
              message={'Do you really want to delete the actor?'}
              classNames={'border-hover-white mr-3 editIcon m-0'}
            />
          </div>
        }

        <div
          className={`d-flex flex-sm-row flex-column w-100 ${exportActor ? 'justify-content-between' : 'justify-content-end'}`
          }>
          {
            exportActor &&
            <div className={'actors-export-action-bar'}>
              <p>Export as:</p>
              <span className={'export-choice'} onClick={() => exportActor(actorExportFormatEnum.JSON)}>JSON</span>
              <span className={'export-choice'} onClick={() => exportActor(actorExportFormatEnum.PDF)}>PDF</span>
            </div>
          }

          <HashLink to={`${paths.actorPath}/${actor.uid}#indicators`}>
            <p className={'card__with_background_icon-indicators-label'}>Checkout the indicators</p>
          </HashLink>
        </div>

      </CardBody>
    </Card>
  )
}

ActorDetailCard.propType = {
  actor: PropTypes.shape().isRequired,
  style: PropTypes.shape().isRequired,
  withLink: PropTypes.bool,
  withTruncateText: PropTypes.bool,
  withDecoration: PropTypes.bool,
  withEditableButton: PropTypes.bool,
  updateFilters: PropTypes.func,
  exportActor: PropTypes.func
}

export default ActorDetailCard;
