import React from "react";
import arrow from "../img/arrow.svg";
import {Col, Container, Row} from "reactstrap";
import PropTypes from "prop-types";

const EmptyDashboard = ({title,description,modalComp,icon}) => {
  return (
    <Container className={'ticket-empty__container'}>
      <Row className={'mt-5 justify-content-center'}>
        <Col className={'ticket-empty__col'}>
          <img src={icon} className={'ticket-empty__img-icon'}/>
        </Col>
      </Row>

      <Row>
        <Col className={'ticket-empty__col'}>
          <h2 className={'ticket-empty__h2-title'}>{title}</h2>
        </Col>
      </Row>

      <Row className={'position-relative'}>
        <Col className={'ticket-empty__col'}>
          <h4 className={'ticket-empty__h4-desc'}>
            {description}
          </h4>
        </Col>
      </Row>

      <Row className={'ticket-empty__col'}>
        <Col className={'ticket-empty-empty__col-submit'}>
          <img src={arrow} className={'ticket-empty__img-arrow'}/>
          {modalComp}
        </Col>
      </Row>
    </Container>
  )
}

EmptyDashboard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    modalComp:PropTypes.shape().isRequired,
    icon:PropTypes.shape().isRequired
}


export default EmptyDashboard;
