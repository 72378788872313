import React from "react";
import RedirectPage from "../index";
import {PLATFORM_FEATURE_CONTENTS} from "../../../../shared/helpers/features";
import Page from "../../../../shared/components/Page";


export const ResetPasswordRedirectPage = () => {
  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
      onlyOnline
    >
      <RedirectPage
        redirectMessage={'You are going to be logged out'}
        redirectSubMessage={'An email has been sent in order to reset the password'}
      />
    </Page>
  )
}

export default ResetPasswordRedirectPage;
