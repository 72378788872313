import {useQueries, useQuery} from "react-query";
import {useCookies} from "react-cookie";
import _ from "lodash";
import {useAuth0} from "@auth0/auth0-react";
import env_const from "../config/env_const";
import {APP_AUTH0_PROVIDER, APP_LOCAL_PROVIDER} from "../shared/helpers/validators";


export const useAuth0AccessToken = () => {
  const {getAccessTokenSilently} = useAuth0();
  return useQuery('auth0-access-token', async () => ( env_const.react_app_auth_provider === APP_AUTH0_PROVIDER && await getAccessTokenSilently()), {refetchOnWindowFocus: false})
}


export const useCookieAccessToken = () => {
  const [cookies] = useCookies(['accessToken']);
  const accessToken = cookies?.accessToken;
  const authDataAccessToken = useAuth0AccessToken();
  const data = useAuth0();
  if (env_const.react_app_auth_provider === APP_LOCAL_PROVIDER) {
    return {
      data: accessToken,
      isIdle: false,
      isLoading: false,
      isError: false,
      error: {},
      isAuthenticated: !_.isNull(accessToken) && !_.isUndefined(accessToken)
    };
  }

  if (!data.isAuthenticated && !data.isLoading && env_const.react_app_auth_provider === APP_AUTH0_PROVIDER) {
    return {isAuthenticated: data.isAuthenticated};
  }

  const authData = Object.assign({}, data, authDataAccessToken);

  if (env_const.react_app_auth_provider === APP_AUTH0_PROVIDER  ) {
    return authData
  }

  return {};
}


const getOptions = (options, accessToken) => {
  options = (options) ? options : {};
  options.enabled = (options.hasOwnProperty('enabled')) ? options.enabled && !!accessToken : !!accessToken;

  return options;
}


export const useQueryWithAuthentication = (queryKey, queryFnWithToken, options = null) => {
  /*
  Check isIdle before isLoading since it will be true while getting the accessToken.
  For more info refer to "Dependent Queries" on react-query docs.
   */
  const {data: accessToken} = useCookieAccessToken();
  return useQuery(queryKey, () => queryFnWithToken(accessToken), getOptions(options, accessToken));
}


export const useParallelQueryWithAuthentication = (queries, options = null) => {
  /*
  Check isIdle before isLoading since it will be true while getting the accessToken.
  For more info refer to "Dependent Queries" on react-query docs.
   */
  const {data: accessToken} = useCookieAccessToken();
  return useQueries(queries.map((q) => Object.assign({}, {
    queryKey: q.queryKey,
    queryFn: () => q.queryFn(accessToken)
  }, getOptions(options, accessToken))));
}
