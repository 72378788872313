import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import IndicatorRiskScoreGauge from '../../IndicatorRiskScoreGauge';
import RelatedActorsCard from '../../RelatedActorsCard';
import IndicatorHashCard from './components/IndicatorHashCard';
import PropTypes from 'prop-types';
import ContentTimeLineCard from '../../ContentTimeLineCard';
import IndicatorDetailFooter from '../../IndicatorDetailFooter';
import ThreatStatsPieChart from '../../ThreatStatsPieChart';
import HashRelationsCard from '../../HashRelationsCard';
import MalwareFamilyTimeLineCard from './components/MalwareFamilyTimeLineCard';
import FirstLastSeenRow from '../../FirstLastSeenRow';

const HashDetails = ({ indicator_details }) => {
  return (
    <Fragment>
      <Row>
        <Col lg={5} md={12}>
          <Row>
            <Col>
              <IndicatorRiskScoreGauge score={!indicator_details.whitelisted ? indicator_details.score : 0}
                                       tags={indicator_details.tags} whitelisted={indicator_details.whitelisted}/>
              <FirstLastSeenRow last_seen={indicator_details.last_seen} first_seen={indicator_details.first_seen}/>
              <RelatedActorsCard actors={indicator_details.actors} isKnown={indicator_details.is_known}/>
              {indicator_details.stats && <ThreatStatsPieChart stats={indicator_details.stats}/>}
            </Col>
          </Row>
        </Col>

        <Col lg={7} md={12}>
          <Row>
            <Col>
              <IndicatorHashCard
                file_info={indicator_details.file_info}
                indicator={indicator_details.indicator}
                indicator_type={indicator_details.indicator_type}
              />
              {indicator_details.related_contexts.length > 0 &&
                <ContentTimeLineCard contexts={indicator_details.related_contexts}/>}
            </Col>
          </Row>
        </Col>
      </Row>


      <Row>
        <Col>
          <HashRelationsCard
            indicator={indicator_details.indicator}
          />
        </Col>
      </Row>


      {indicator_details.family_info?.hits?.length > 0 &&
        <MalwareFamilyTimeLineCard family_info={indicator_details.family_info}/>}
      <IndicatorDetailFooter indicator_details={indicator_details}/>
    </Fragment>
  );
};

HashDetails.propTypes = {
  indicator_details: PropTypes.object.isRequired
};

export default HashDetails;
