import Layout from "../../containers/Layout";
import React from "react";
import {useCookieAccessToken} from "../../queries";
import {Redirect, useLocation} from "react-router-dom";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import LoadingSpinner from "./LoadingSpinner";
import env_const from "../../config/env_const";
import {APP_LOCAL_PROVIDER} from "../helpers/validators";
import ErrorHandler from "./ErrorHandler";
import NotAllowed401 from "../../containers/DefaultPage/401";
import {PLATFORM_PERMISSION_EDITOR, PLATFORM_PERMISSION_VIEWER} from "../helpers/features";
import {CUSTOMER_ROLE} from "../helpers/user";
import _ from "lodash";
import paths from "../../config/paths";
import PropTypes from "prop-types";


const Page = ({
  children,
  feature = null,
  permission = PLATFORM_PERMISSION_VIEWER,
  onlyOnline = false,
  unavailableOnTenant = false,
  fullPageWrapper = false
}) => {
  const {isAuthenticated, isLoading} = useCookieAccessToken();
  const location = useLocation();
  const {
    data,
    isLoading: isLoadingCurrentUser,
    isIdle: isIdleCurrentUser,
    isError,
    error
  } = useCurrentUserQuery();

  if (isLoading) {
    return <LoadingSpinner/>;
  }

  if (!isAuthenticated) {
    if (env_const.react_app_auth_provider === APP_LOCAL_PROVIDER) {
      return (
        <Redirect to={{
            pathname: paths.loginPath,
            state: {referrer: location.pathname}
          }}
        />
      )
    }
  }

  if (isIdleCurrentUser || isLoadingCurrentUser) {
    return <LoadingSpinner/>
  }

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  if (!_.isNull(feature) && !_.isNull(permission)) {
    const features = _.flatten(data?.data?.modules?.map((module) => module.features) || []);

    const user_feature = _.filter(features, (f) => f.name === feature)

    if (
      user_feature.length === 0 ||
      (user_feature[0].permission !== PLATFORM_PERMISSION_EDITOR && permission === PLATFORM_PERMISSION_EDITOR) ||
      (unavailableOnTenant && (env_const.is_clone || (!env_const.is_clone && data?.data?.role === CUSTOMER_ROLE)))
    ) {
      return <NotAllowed401/>
    }
  }

  if (onlyOnline && env_const.is_on_prem) {
    return <NotAllowed401/>
  }

  if (fullPageWrapper) {
    return (
      <>
        <Layout/>
        <div className="container__wrap-full-width">
          {children}
        </div>
      </>
    )
  } else {
    return (
      <>
        <Layout/>
        <div className="container__wrap">
          {children}
        </div>
      </>
    )
  }
}


Page.propTypes = {
  feature: PropTypes.string,
  permission: PropTypes.string,
  onlyOnline: PropTypes.bool,
  unavailableOnTenant: PropTypes.bool
}


export default Page;