import React from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";

const TargetOrganizationsCard = ({
    targetedOrganizations = [],
}) => {
    return (
        <Card className={'h-auto'}>
            <CardBody>
                <div className="card__title">
                    <h4 className={'bold-text d-inline'}>Targeted Organizations</h4>
                </div>
                <div className={'mt-3'}>
                    {
                        targetedOrganizations.map((el, index) =>
                            <p className="custom_card__container-title" key={index}>
                                {el.name}&nbsp;&nbsp;&nbsp;
                                (
                                <span>&nbsp;{el.site}&nbsp;</span>
                                )
                            </p>
                        )
                    }
                </div>
            </CardBody>
        </Card>
    );
}

TargetOrganizationsCard.propTypes = {
    targetedOrganizations: PropTypes.array.isRequired,
}

export default TargetOrganizationsCard;
