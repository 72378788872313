import React, {useCallback, useState} from "react";
import {Card, CardBody, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import ErrorHandler from "../../shared/components/ErrorHandler";
import BasicInfoCard from "./components/BasicInfoCard";
import DarkWebKeywordsSection from "./components/DarkWebKeywordsSection";
import ProfileOverview from "./components/ProfileOverview";
import ResourceMonitorSection from "./components/ResourceMonitorSection";
import OrganizationSection from "./components/OrganizationSection";
import IntelligenceHistory from "./components/IntelligenceHistory";
import env_const from "../../config/env_const";
import {hasOnPremPlatform, isFeatureExistsForCurrentUser,} from "../../shared/helpers/permissions";
import DownloadUpdatesSection from "./components/DownloadUpdatesSection";
import {useCookieAccessToken} from "../../queries";
import RansomwareTrackerPreferencesSection from "./components/RansomwareTrackerPreferencesSection";
import NewsFeedsPreferencesSection from "./components/NewsFeedsPreferencesSection";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {
  PLATFORM_FEATURE_CONTENTS,
  PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS,
  PLATFORM_FEATURE_INTELLIGENCE,
  PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS,
  PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS
} from "../../shared/helpers/features";
import TypoSquattingSection from "./components/TypoSquattingSection";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const SECT_PROFILE = 0;
const SECT_DARKWEB_KEYWORDS = 1;
const SECT_RESOURCE_MONITOR = 2;
const SECT_INTELLIGENCE_HISTORY = 3;
const SECT_ORGANIZATION = 4;
const SECT_DOWNLOAD_UPDATES = 5;
const SECT_RANSOMWARE_TRACKER_PREFERENCES = 6;
const SECT_NEWS_FEEDS_PREFERENCES = 7;
const SECT_TYPOSQUATTING = 8;
const SECT_MENU_SETTINGS = 9;



const Profile = () => {
  const { user } = useCookieAccessToken();

  const {
    data,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser,
    error: errorCurrentUser
  } = useCurrentUserQuery();
  const [overviewProfile, setOverviewProfile] = useState(SECT_PROFILE);
  const changeActiveIndex = useCallback((index) => setOverviewProfile(index), []);

  if (isLoadingCurrentUser || isIdleCurrentUser) {
    return <LoadingSpinnerPage/>
  }

  if (isErrorCurrentUser) {
    return <ErrorHandler error={errorCurrentUser}/>
  }

  const currentUser = data.data;

  return (
    <Page>
      <Container>
        <Row className={'justify-content-center'}>
          <Col md={4} xl={3} lg={3} xs={12}>
            <Row>
              <Col md={12}>
                <BasicInfoCard
                  picture={user && user.picture}
                  email={user && user.email}
                  username={user && user.name || user && user.fullName}
                />
              </Col>

              <Col md={12}>
                <ListGroup className={'listgroup__profile'}>
                  <ListGroupItem
                    active={overviewProfile === SECT_PROFILE}
                    onClick={() => changeActiveIndex(SECT_PROFILE)}
                  >Profile</ListGroupItem>

                  {
                    !env_const.is_on_prem && isFeatureExistsForCurrentUser(currentUser.modules, PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS) &&
                    <ListGroupItem
                      active={overviewProfile === SECT_DARKWEB_KEYWORDS}
                      onClick={() => changeActiveIndex(SECT_DARKWEB_KEYWORDS)}
                    >Identity Intelligence Engine</ListGroupItem>
                  }

                  {
                    !env_const.is_on_prem && isFeatureExistsForCurrentUser(currentUser.modules, PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS) &&
                    <ListGroupItem
                      active={overviewProfile === SECT_RESOURCE_MONITOR}
                      onClick={() => changeActiveIndex(SECT_RESOURCE_MONITOR)}
                    >Attack Surface Intelligence Engine</ListGroupItem>
                  }

                  {
                    !env_const.is_on_prem && isFeatureExistsForCurrentUser(currentUser.modules, PLATFORM_FEATURE_INTELLIGENCE) &&
                    <ListGroupItem
                      active={overviewProfile === SECT_INTELLIGENCE_HISTORY}
                      onClick={() => changeActiveIndex(SECT_INTELLIGENCE_HISTORY)}
                    >Intelligence Search History</ListGroupItem>
                  }
                  {
                    !env_const.is_on_prem && isFeatureExistsForCurrentUser(currentUser.modules, PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS) &&
                    <ListGroupItem
                      active={overviewProfile === SECT_TYPOSQUATTING}
                      onClick={() => changeActiveIndex(SECT_TYPOSQUATTING)}>
                      Typosquatting Settings
                    </ListGroupItem>
                  }

                  {
                    !!currentUser.organization.has_custom_logo && !env_const.is_on_prem &&
                    <ListGroupItem
                      active={overviewProfile === SECT_ORGANIZATION}
                      onClick={() => changeActiveIndex(SECT_ORGANIZATION)}>
                      Organization Logo
                    </ListGroupItem>
                  }

                  {
                    !env_const.is_on_prem && isFeatureExistsForCurrentUser(currentUser.modules, PLATFORM_FEATURE_CONTENTS) &&
                    <ListGroupItem
                      active={overviewProfile === SECT_RANSOMWARE_TRACKER_PREFERENCES}
                      onClick={() => changeActiveIndex(SECT_RANSOMWARE_TRACKER_PREFERENCES)}
                    >Ransomware Tracker Preferences</ListGroupItem>
                  }
                  {
                    !env_const.is_on_prem && isFeatureExistsForCurrentUser(currentUser.modules, PLATFORM_FEATURE_CONTENTS) &&
                    <ListGroupItem
                      active={overviewProfile === SECT_NEWS_FEEDS_PREFERENCES}
                      onClick={() => changeActiveIndex(SECT_NEWS_FEEDS_PREFERENCES)}
                    >News Feed Preferences</ListGroupItem>
                  }
                  {
                    !env_const.is_on_prem && hasOnPremPlatform(currentUser) &&
                    <ListGroupItem
                      active={overviewProfile === SECT_DOWNLOAD_UPDATES}
                      onClick={() => changeActiveIndex(SECT_DOWNLOAD_UPDATES)}>
                      Download Updates
                    </ListGroupItem>
                  }

                  {/*{*/}
                  {/*  !env_const.is_on_prem &&*/}
                  {/*    <ListGroupItem*/}
                  {/*      active={overviewProfile === SECT_MENU_SETTINGS}*/}
                  {/*      onClick={() => changeActiveIndex(SECT_MENU_SETTINGS)}>*/}
                  {/*      Menu Order Settings*/}
                  {/*    </ListGroupItem>*/}
                  {/*}*/}

                </ListGroup>
              </Col>

            </Row>
          </Col>
          <Col md={7} xl={7} lg={7} xs={12}>
            <Row>
              <Col md={12}>
                {
                  (overviewProfile === SECT_PROFILE) ? (
                    <ProfileOverview currentUser={currentUser} authUser={user && user ? user : currentUser}/>
                  ) : (overviewProfile === SECT_DARKWEB_KEYWORDS) ? (
                    <DarkWebKeywordsSection organization={currentUser.organization}/>
                  ) : (overviewProfile === SECT_RESOURCE_MONITOR) ? (
                    <ResourceMonitorSection organization={currentUser.organization}/>
                  ) : (overviewProfile === SECT_TYPOSQUATTING) ? (
                    <TypoSquattingSection organization={currentUser.organization}/>
                  ) : (overviewProfile === SECT_INTELLIGENCE_HISTORY) ? (
                    <IntelligenceHistory currentUser={currentUser} />
                  ) : (overviewProfile === SECT_ORGANIZATION && !!currentUser.organization.has_custom_logo) ? (
                    <OrganizationSection currentUser={currentUser} />
                  ) : (overviewProfile === SECT_DOWNLOAD_UPDATES && hasOnPremPlatform(currentUser)) ? (
                    <DownloadUpdatesSection />
                  ) : (overviewProfile === SECT_RANSOMWARE_TRACKER_PREFERENCES) ? (
                    <RansomwareTrackerPreferencesSection currentUser={currentUser}/>
                  ) : (overviewProfile === SECT_NEWS_FEEDS_PREFERENCES) ? (
                    <NewsFeedsPreferencesSection currentUser={currentUser}/>
                    // ) : (overviewProfile === SECT_MENU_SETTINGS) ? (
                    //   <MenuSettings currentUser={currentUser} />
                  ) : <Row><Col><Card><CardBody>ERROR! SHOULD NEVER REACH THIS!</CardBody></Card></Col></Row>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

export default Profile;