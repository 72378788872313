import TagCloud from "react-tag-cloud";
import randomColor from "randomcolor";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


const _ = require('lodash');


const ThemedTagCloud = ({
  words,
  onWordClick = null,
  refreshIntervalMs = 9000,
  huwWords = '#40e5e8',
  maxWordSize = 50,
  minWordSize = 15
}) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const classes = classNames({
    'word-cloud': true,
    'word-cloud-hover': !!onWordClick
  })

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate();
    }, refreshIntervalMs);
    return () => clearTimeout(interval);
  }, [])

  return (
    <TagCloud
      style={{
        color: () => randomColor({ hue: huwWords }),
        fontFamily: 'Roboto, sans-serif',
        width: '100%',
        height: '100%',
        padding: 10,
        paddingBottom: '35px'
      }}
    >
      {
        words.map((word) =>
          <div
            style={{ fontSize: () => {
                const vmax = _.maxBy((words), (w) => w.value).value;
                const vmin = _.minBy((words), (w) => w.value).value;
                const d = vmax - vmin;

                return Math.min(Math.max(((word.value + 1) - vmin) / d * maxWordSize, minWordSize), maxWordSize);
              } }}
            className={classes}
            key={word.text}
            onClick={() => {
              if (onWordClick) {
                onWordClick(word.text)
              }
            }}
          >
            {word.text}
          </div>
        )
      }
    </TagCloud>
  )
}


ThemedTagCloud.propTypes = {
  words: PropTypes.array.isRequired,
  onWordClick: PropTypes.func,
  hueWords: PropTypes.string,
  refreshIntervalMs: PropTypes.number
}


export default ThemedTagCloud;