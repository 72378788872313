import React, {useState, Fragment} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from 'classnames';
import PropTypes from "prop-types";
import TableWithData from "../table/TableWithData";

const _ = require('lodash')

const TabsWithDataTable = ({dataPerTag}) => {
    const [activeTab, setActiveTab] = useState(0);

    const tabsKeys = _.keys(dataPerTag)

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    if (dataPerTag === undefined) {
        return <Fragment />
    }

    return (
        <div className="tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
                <Nav tabs>
                    {
                        tabsKeys.map((key, index) =>
                            ((dataPerTag[key].data.length > 0) &&
                            <NavItem key={key}>
                                <NavLink
                                    className={classnames({active: activeTab === index})}
                                    onClick={() => toggleTab(index, key)}
                                    key={key}
                                >
                                    {tabsKeys[index].toUpperCase()} ({dataPerTag[key].data.length})
                                </NavLink>
                            </NavItem>)
                        )
                    }
                </Nav>
                <TabContent activeTab={activeTab}>
                    {
                        tabsKeys.map((key, index) =>
                            ((dataPerTag[key].data.length > 0) &&
                            <TabPane tabId={index} key={index}>
                                {
                                    <TableWithData
                                        data={dataPerTag[key].data}
                                        tableColumns={dataPerTag[key].tableColumns}
                                        tableConfig={dataPerTag[key].tableConfig}
                                        onRowClick={dataPerTag[key].onRowClick}
                                        filters={dataPerTag[key].filters}
                                        getRowDataId={dataPerTag[key].getRowDataId}
                                    />
                                }
                            </TabPane>)
                        )
                    }
                </TabContent>
            </div>
        </div>
    )
}

TabsWithDataTable.propTypes = {
    dataPerTag: PropTypes.array.isRequired
}

export default TabsWithDataTable;
