import Collapse from "../../../../shared/components/Collapse";
import React from "react";
import PropTypes from "prop-types";
import CommandsCollapseContent from "./CommandsCollapseContent";


const CommandsCollapse = ({
                            command
                          }) => {
  return (<Collapse
    title={
      <>{command.value}
      </>
    }
    className={"with-shadow"}
  >
    <CommandsCollapseContent
      command={command}
    />
  </Collapse>)
}


CommandsCollapse.propTypes = {
  commands: PropTypes.object
}


export default CommandsCollapse;

