import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import _ from "lodash";


export const ProjectsOrganizationResourceMonitorFetcher = (uid, accessToken) => {
  return axios.get(
    `${apiUrlInternal}/resource_monitor_projects/organizations/${uid}`,
    addAuthToAxiosOpts(accessToken, {})
  ).then(res => res.data);
}


export const useProjectsOrganizationResourceMonitorQuery = (uid, fetcher = ProjectsOrganizationResourceMonitorFetcher) => {
  return useQueryWithAuthentication(
    ['organizations-resource-monitor-projects', uid],
    (accessToken) => fetcher(uid, accessToken),
    {refetchOnWindowFocus: false, enabled: !_.isNull(uid) && !_.isUndefined(uid)}
  )
}
