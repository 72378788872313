import React from "react";
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {getBaseScore, getCveItems} from "../../../shared/helpers/cve";
import CveScoreGauge from "../../../shared/components/cve/CveScoreGauge";
import CveDetailsContent from "../../../shared/components/cve/CveDetailsContent";
import CveReferenceRows from "../../../shared/components/cve/CveReferenceRows";


const ResourceMonitorResultCollapseContent = ({vuln}) => {
  const cve_info = vuln.nvd_info;
  const [baseScore, cvssVersion, baseSeverity] = getBaseScore(cve_info.impact);
  const cveItems = getCveItems(cve_info.impact, cvssVersion);

  return (
    <Row>
      <Col md={4}>
        <CveScoreGauge baseScore={baseScore} cvssVersion={cvssVersion} baseSeverity={baseSeverity} />
      </Col>
      <Col md={8}>
        <CveDetailsContent cve={cve_info} cveItems={cveItems} />
      </Col>

      <Col>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>REFERENCES</h4>
        </div>

        <CveReferenceRows refs={cve_info.references} />
      </Col>

    </Row>
  )
}


ResourceMonitorResultCollapseContent.propTypes = {
  vuln: PropTypes.object.isRequired
}


export default ResourceMonitorResultCollapseContent;
