import React from "react";
import TableWithData from "../table/TableWithData";
import {formatDateUTCtoYearMonthDayTime} from "../../helpers/date";
import PropTypes from "prop-types";
import CountryBadge from "../badge/CountryBadge";
import _ from "lodash";


export const tableColumns = [
  { Header: 'Seen on', accessor: 'created_dt',
    Cell: ({value}) => value ? formatDateUTCtoYearMonthDayTime(value) : 'N/A'
  },
  { Header: 'Forum', accessor: 'forum_name' },
  { Header: 'URL', accessor: 'url' },
  { Header: 'OS', accessor: 'bot_os' },
  { Header: 'Country', accessor: 'bot_country', Cell: ({value}) => <CountryBadge countryCode={value} /> },
  { Header: 'Installed on', accessor: 'bot_installed',
    Cell: ({value}) => value ? formatDateUTCtoYearMonthDayTime(value) : 'N/A'
  },
];


const getCsvMappedData = (data)=>{
  let finalData = [];
  if(data && data.length > 0){
    data.forEach((item) => {
      finalData.push({
        'Seen on': item.created_dt ? formatDateUTCtoYearMonthDayTime( item.created_dt ) : 'N/A',
        'Forum': item.forum_name,
        'URL': item.url,
        'OS': item.bot_os,
        'Country': item.bot_country,
        'Installed on': item.bot_installed ? formatDateUTCtoYearMonthDayTime( item.bot_installed ) : 'N/A',
      })
    });
  }
  return finalData && finalData.length > 0 ? finalData : [];
};


const tableConfig = {
  withPagination: true,
  withSearchEngine: true,
  isSortable: true,
  withCsvDownload: true
}


const DarksearchBotsTable = ({bots, hasDownloadIcon = true, extraColumnsAfter = [], extraColumnsBefore = []}) =>
  <TableWithData
    data={_.orderBy(bots,['created_dt'],['desc'])}
    tableColumns={
      hasDownloadIcon ?
        extraColumnsBefore.concat(tableColumns.concat(extraColumnsAfter)) :
        extraColumnsBefore.concat(tableColumns.slice(0, -1).concat(extraColumnsAfter))
    }
    tableConfig={tableConfig}
    csvData={hasDownloadIcon ? getCsvMappedData(_.orderBy(bots,['created_dt'],['desc'])) : []}
  />



DarksearchBotsTable.propTypes = {
  bots: PropTypes.array.isRequired,
  hasDownloadIcon: PropTypes.bool,
  extraColumnsAfter: PropTypes.array,
  extraColumnsBefore: PropTypes.array,
}


export default DarksearchBotsTable;
