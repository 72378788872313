import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";


export const RansomwareTrackerEventsFetcher = (
  accessToken, limit = 100, offset = 0, order_by = null, search_term = null,
  actor_name = null, victim_country = null, start = null, end = null
) => {
  const urlParams = new URLSearchParams();
  if (limit) {
    urlParams.set('limit', limit);
  }
  if (offset) {
    urlParams.set('offset', offset);
  }
  if (order_by) {
    urlParams.set('order_by', order_by);
  }
  if (search_term) {
    urlParams.set('search_term', search_term);
  }
  if (actor_name) {
    urlParams.set('actor_name', actor_name);
  }
  if (victim_country) {
    urlParams.set('victim_country', victim_country);
  }
  if (start) {
    urlParams.set('start', start)
  }
  if (end) {
    urlParams.set('end', end)
  }

  return axios.get(`${apiUrlInternal}/ransomware_tracker_events?${urlParams.toString()}`,
    addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useRansomwareTrackerEventsQuery = (
  limit = 100, offset = 0, order_by = null, search_term = null, actor_name = null,
  victim_country = null, start = null, end = null,
  fetcher = RansomwareTrackerEventsFetcher) => {
  return useQueryWithAuthentication(
    ['ransomware-tracker-events-query', limit, offset, order_by, search_term, actor_name, victim_country, start, end],
    (accessToken) => fetcher(accessToken, limit, offset, order_by, search_term, actor_name, victim_country, start, end
    ))
}

