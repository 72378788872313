import {addAuthToAxiosOpts} from '../config/queryopts'
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import { useQueryWithAuthentication } from '.';


export const associatedMalwareFetcher = (accessToken) => {
  return axios.get(`${apiUrlInternal}/associated_malware`, addAuthToAxiosOpts(accessToken, {})).then(res => res.data);
}

export const useAssociatedMalwareQuery = (fetcher = associatedMalwareFetcher) => {
    return useQueryWithAuthentication(
      ['associated_malwares'], (accessToken) => fetcher(accessToken), {staleTime: Infinity}
    )
}
