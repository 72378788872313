import {Input} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";


const SearchBar = ({
  searchTerm,
  searchPlaceholder,
  setSearch,
}) => {

  return (
    <div>
      <Input
        type={'text'}
        name={'search'}
        id={'search'}
        value={searchTerm}
        placeholder={searchPlaceholder}
        className={'search_bar'}
        onChange={(e) => {
          e.preventDefault();
          setSearch(e.target.value);
        }}
      />
    </div>
  )
}

SearchBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
}


export default SearchBar;