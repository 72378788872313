import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import FilesInfoCollapse from './FilesInfoCollapse';
import { useIndicatorInvestigateQuery } from '../../../queries/IndicatorInvestigateFilesServices';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroller';
import ErrorHandler from '../../../shared/components/ErrorHandler';

const CommunicatingFilesCard = ({
  indicator
}) => {
  const LOADING_STEP = 30;
  const INITIAL_ITEMS = 30;
  const [loadedItems, setLoadedItems] = useState(INITIAL_ITEMS);
  const [next, setNext] = useState(null);
  const [totalContents, setTotalContents] = useState(0);
  const [displayContentArray, setDisplayContentArray] = useState([]);
  const [contentArray, setContentArray] = useState([]);

  const {
    data,
    isIdle,
    isLoading,
    isError,
    error
  } = useIndicatorInvestigateQuery(
    'communicating_files', indicator, next
  );

  useEffect(() => {
    if (!isIdle && !isLoading && !isError && data?.data) {
      setTotalContents(data.metadata.total);

      if (!next && displayContentArray.length === 0) {
        setContentArray(contentArray.concat(data.data));
        setDisplayContentArray(displayContentArray.concat(data.data));
        setLoadedItems(INITIAL_ITEMS);
      } else {
        setContentArray(contentArray.concat(data.data));
        setDisplayContentArray(displayContentArray.concat(data.data));
        setLoadedItems(loadedItems + LOADING_STEP);
      }
    }
  }, [isIdle, isLoading, isError, data?.data]);

  const loadMoreItems = () => {
    if (contentArray.length > loadedItems + LOADING_STEP) {
      setDisplayContentArray(contentArray.slice(0, loadedItems + LOADING_STEP));
      setLoadedItems(loadedItems + LOADING_STEP);
    } else if (contentArray.length === totalContents) {
      setDisplayContentArray(contentArray);
      setLoadedItems(totalContents);
    } else if (data?.metadata.next) {
      setNext(data?.metadata?.next);
    }
  };
  if (isError) {
    return <ErrorHandler error={error}/>;
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">Communicating Files</h4>
        </div>
        {
          !(isIdle || isLoading) && displayContentArray.length === 0 &&
          totalContents === 0 && <h4>No communicating files found</h4>
        }
        {
          (isIdle || isLoading) && displayContentArray.length === 0 &&
          <LoadingSpinner/>
        }
        {
          contentArray.length > 0 &&
          <div className="card__body-communicating-files">
            <InfiniteScroll
              loadMore={loadMoreItems}
              hasMore={loadedItems < totalContents}
              loader={isLoading && !isIdle && <LoadingSpinner/>}
              useWindow={false}
            >
              {
                <FilesInfoCollapse data={displayContentArray}/>
              }
            </InfiniteScroll>
          </div>
        }
      </CardBody>
    </Card>
  );
};

CommunicatingFilesCard.propTypes = {
  indicator: PropTypes.string.isRequired
};

export default CommunicatingFilesCard;
