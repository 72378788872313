import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarNav from './tobar_nav/TopbarNav';
import TopbarProfile from "../topbar/TopbarProfile";
import {hasUserKeywords, hasOnPremPlatform, isUserDemo} from "../../../shared/helpers/permissions";
import TopBarDemoTooltip from "../topbar/TopBarDemoTooltip";
import TopBarNoKeywordAlertTooltip from "../topbar/TopBarNoKeywordAlertTooltip";
import TopbarNotification from "./TopbarNotification";
import {getIcon, OPERATOR_ROLE} from "../../../shared/helpers/user";
import env_const from "../../../config/env_const";
import TopBarUpdatesAlertTooltip from '../topbar/TopBarUpdatesAlertTooltip';


const TopbarWithNavigation = ({ changeMobileSidebarVisibility, user,modules }) => (

  <div className="topbar topbar--navigation">
    <div className="topbar__left">
      <Link className="topbar__logo" style={{'backgroundImage': `url(${getIcon(user)})`}} to="/" /> :
      <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} />

    </div>
    <TopbarNav user={user} modules={modules} />
    <div className="topbar__right">
      {isUserDemo(user) && <TopBarDemoTooltip />}
      {!env_const.is_on_prem && !hasUserKeywords(user) &&  <TopBarNoKeywordAlertTooltip />}
      
      { hasOnPremPlatform(user) && user.role !== OPERATOR_ROLE && <TopBarUpdatesAlertTooltip />}

      <div className="topbar__right-over">
        {!env_const.is_on_prem && <TopbarNotification user_uid={user?.uid}/>}
        <TopbarProfile />
      </div>

    </div>
  </div>
);

TopbarWithNavigation.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired
};

export default TopbarWithNavigation;
