import React, {useEffect, useState} from "react"
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {Link, useHistory, useParams} from "react-router-dom";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {formatDateUTCtoYearMonthDayTime} from "../../shared/helpers/date";
import ColorTagInlineBadge from "../../shared/components/badge/ColorTagInlineBadge";
import ControlledSelectWithTitle from "../../shared/components/form/ControlledSelectWithTitle";
import {useForm} from "react-hook-form";
import {getErrorMessageFromResponse, upperCaseFirstLetter} from "../../shared/helpers";
import {apiUrlInternal} from "../../config/api";
import {canUserEditTickets, hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {getColorBadgeByPriority} from "../../shared/helpers/priority";
import {useCloseTicketMutation} from "../../mutations/Tickets/CloseTicket";
import {usePriorityUpdateTicketMutation} from "../../mutations/Tickets/UpdatePriorityTicket";
import {useAssignUserTicketMutation} from "../../mutations/Tickets/AssignUserTicket";
import toast from "react-hot-toast";
import DeleteButtonModal from "../../shared/components/modal/DeleteButtonModal";
import ChatData from "../Tickets/components/ChatData";
import {useTicketsQuery, useTicketsUsersQuery} from "../../queries/Tickets";
import DownloadFileIcon from "../../shared/components/icons/DownloadFileIcon";
import _ from "lodash";
import TLPBadge from "../../shared/components/badge/TLPBadge";
import {useDeleteTicket} from "../../mutations/Tickets/DeleteTicket";
import DeleteIconModal from "../../shared/components/modal/DeleteIconModal";
import paths, {ticketsPath} from "../../config/paths";
import {PLATFORM_FEATURE_TICKETS_ANALYSIS} from "../../shared/helpers/features";
import ConfirmModal from "../../shared/components/modal/ConfirmModal";
import {useOrganizationsTicketCoinsQuery} from "../../queries/OrganizationTicketCoins";
import {useAssignTicketCoinTicketMutation} from "../../mutations/Tickets/AssignTicketCoinTicket";
import {useAcceptTicketCoinTicketMutation} from "../../mutations/Tickets/AcceptTicketCoinTicket";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const TicketDetail = () => {
  const history = useHistory();
  const {ticketUid: uid} = useParams();
  const deleteMutation = useDeleteTicket();
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const {data: dataUsers, isIdle: isIdleUsers, isLoading: isLoadingUsers, error: isUsersError} = useTicketsUsersQuery(
    canUserEditTickets(dataCurrentUser.data)
  );
  const {data: dataTicketCoins} = useOrganizationsTicketCoinsQuery(dataCurrentUser.data.organization.uid);

  const ticketCoinOptions = _.range(11).map((el) => ({value: el, label: el}));
  const closeMutation = useCloseTicketMutation();
  const updatePriorityMutation = usePriorityUpdateTicketMutation()
  const updateUserTicketMutation = useAssignUserTicketMutation();
  const assignTicketCoinTicketMutation = useAssignTicketCoinTicketMutation();
  const acceptTicketCoinTicketMutation = useAcceptTicketCoinTicketMutation();
  const {data, isIdle, isLoading, isError, error} = useTicketsQuery(uid);
  const [ticketDetail, setTicketDetail] = useState({});
  const [ticketUsers, setTicketUsers] = useState([]);
  const [isTicketCoinAcceptable, setIsTicketCoinAcceptable] = useState(false);
  const {control} = useForm();
  const priorityOptions = ['low', 'medium', 'high'].map((sev) => ({
    value: sev, label: upperCaseFirstLetter(sev)
  }));
  const assigneeOptions = ticketUsers ? ticketUsers.map((user) => ({
    value: user.uid, label: user.email
  })) : [];

  const onTicketClose = () => {
    closeMutation.mutate({
      uid: ticketDetail.uid
    });
  };

  const onTicketCoinAccept = () => {
    acceptTicketCoinTicketMutation.mutate({
      uid: ticketDetail.uid,
      ticketCoinUid: ticketDetail.ticket_coin.uid
    });
  };

  const handlePriorityChange = (option) => {
    updatePriorityMutation.mutate({
      uid: ticketDetail.uid,
      priority: option.value
    });
  }

  const handleAssigneeChange = (option) => {
    updateUserTicketMutation.mutate({
      uid: ticketDetail.uid,
      user_uid: option.value
    });
  }

  const handleTicketCoinsAmountChange = (option) => {
    assignTicketCoinTicketMutation.mutate({
      uid: ticketDetail.uid,
      coins: option.value
    });
  }

  const isEditable = canUserEditTickets(dataCurrentUser ? dataCurrentUser.data : null);

  const canUserAcceptTicketCoin = () => {
    setIsTicketCoinAcceptable(
      ticketDetail?.ticket_coin?.status === 'requested' &&
      dataCurrentUser?.data?.organization?.uid === ticketDetail?.ticket_coin?.organization_id
    );
  }

  useEffect(() => {
    if (!closeMutation.isIdle) {
      if (closeMutation.isLoading) {
        toast.loading('closing ticket');
      } else {
        toast.dismiss();
        if (closeMutation.isSuccess) {
          toast.success('ticket closed successfully');
        } else if (closeMutation.isError) {
          toast.error(getErrorMessageFromResponse(closeMutation));
          closeMutation.reset();
        }
      }
    }
  }, [closeMutation.isIdle, closeMutation.isError, closeMutation.isLoading, closeMutation.isSuccess])

  useEffect(() => {
    canUserAcceptTicketCoin();
  }, [ticketDetail, dataCurrentUser])

  useEffect(() => {
    if (!updatePriorityMutation.isIdle) {
      if (updatePriorityMutation.isLoading) {
        toast.loading('updating priority');
      } else {
        toast.dismiss();
        if (updatePriorityMutation.isSuccess) {
          toast.success('priority updated successfully');
        } else if (updatePriorityMutation.isError) {
          toast.error(getErrorMessageFromResponse(updatePriorityMutation));
          updatePriorityMutation.reset();
        }
      }
    }
  }, [updatePriorityMutation.isIdle, updatePriorityMutation.isError, updatePriorityMutation.isLoading, updatePriorityMutation.isSuccess])

  useEffect(() => {
    if (!updateUserTicketMutation.isIdle) {
      if (updateUserTicketMutation.isLoading) {
        toast.loading('assigning user');
      } else {
        toast.dismiss();
        if (updateUserTicketMutation.isSuccess) {
          toast.success('assigned user successfully');
        } else if (updateUserTicketMutation.isError) {
          toast.error(getErrorMessageFromResponse(updateUserTicketMutation));
          updateUserTicketMutation.reset();
        }
      }
    }
  }, [updateUserTicketMutation.isIdle, updateUserTicketMutation.isError, updateUserTicketMutation.isLoading, updateUserTicketMutation.isSuccess])

  useEffect(() => {
    if (!deleteMutation.isIdle) {
      if (deleteMutation.isLoading) {
        toast.loading('Deleting ticket');
      } else {
        toast.dismiss();
        if (deleteMutation.isSuccess) {
          toast.success('Ticket deleted successfully');
          history.push(paths.ticketsPath);
        } else if (deleteMutation.isError) {
          toast.error(getErrorMessageFromResponse(deleteMutation));
          deleteMutation.reset();
        }
      }
    }
  }, [deleteMutation.isIdle, deleteMutation.isError, deleteMutation.error, deleteMutation.isLoading, deleteMutation.isSuccess])

  useEffect(() => {
    if (!acceptTicketCoinTicketMutation.isIdle) {
      if (acceptTicketCoinTicketMutation.isLoading) {
        toast.loading('Accepting ticket coins');
      } else {
        toast.dismiss();
        if (acceptTicketCoinTicketMutation.isSuccess) {
          toast.success('Ticket coins accepted successfully');
          history.push(paths.ticketsPath);
        } else if (acceptTicketCoinTicketMutation.isError) {
          toast.error(getErrorMessageFromResponse(acceptTicketCoinTicketMutation));
          acceptTicketCoinTicketMutation.reset();
        }
      }
    }
  }, [acceptTicketCoinTicketMutation.isIdle, acceptTicketCoinTicketMutation.isError, acceptTicketCoinTicketMutation.error, acceptTicketCoinTicketMutation.isLoading, acceptTicketCoinTicketMutation.isSuccess])

  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setTicketDetail(data.data);
    }
  }, [isIdle, isLoading, isError, data])

  useEffect(() => {
    if (!isIdleUsers && !isLoadingUsers && !isUsersError) {
      setTicketUsers(dataUsers.data);
    }
  }, [isIdleUsers, isLoadingUsers, isUsersError, dataUsers])

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  if (isLoading || isIdle || !ticketDetail) {
    return <LoadingSpinnerPage/>
  }

  return (
    ticketDetail && ticketDetail.uid && assigneeOptions ?
      <Page
        feature={PLATFORM_FEATURE_TICKETS_ANALYSIS}
        onlyOnline
      >
        <Container className="ticketDetailWrapper">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Ticket Details</h3>
              <h3 className="page-subhead subhead">
                <Link to={`${ticketsPath}`}>Back to Tickets</Link>
              </h3>
            </Col>
          </Row>
          <Card className={'h-auto'}>
            <CardBody>
              <h4 className="card__title d-inline bold-text">
                {ticketDetail.title}
                {ticketDetail.tlp && <TLPBadge style={{float: 'right', 'margin-left': '7px', 'margin-right': '7px'}}
                                               tlp={ticketDetail.tlp.tlp}/>}
                {
                  !_.isNull(ticketDetail.attachment) &&
                  <DownloadFileIcon
                    url={`${apiUrlInternal}/tickets/attachment/${ticketDetail.attachment.uid}`}
                    filename={_.split(ticketDetail.attachment.storage_id, '/')[1]}
                    style={{fill: 'white', padding: '2px', float: 'right'}}
                    timeout={60000}
                    size={24}
                    className={'border-hover-white float-right'}
                  />
                }
                {
                  isEditable && hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TICKETS_ANALYSIS) &&
                  <div className={'float-right'}>
                    <DeleteIconModal
                      onDelete={() => {
                        deleteMutation.mutate(ticketDetail.uid);
                      }}
                      message={'Do you really want to delete the ticket?'}
                    />
                  </div>
                }
                {
                  ticketDetail.created_by &&
                  <p className={'subhead'}>Created by <b>{ticketDetail.created_by.email}</b>&nbsp;on&nbsp;
                    <b>{formatDateUTCtoYearMonthDayTime(ticketDetail.created_dt)}</b>
                  </p>
                }
              </h4>

              <Row>
                <Col md={12} className="pb-5">
              <pre className="descriptionInfo">
                {ticketDetail.description}
              </pre>
                </Col>
              </Row>
              <Row>
                <Col md={9} sm={12} className={'ticket-badge__status_ptions'}>
                  <ColorTagInlineBadge
                    color={getColorBadgeByPriority(ticketDetail.priority)}
                    tag={`PRIORITY:${ticketDetail.priority.toUpperCase()}`}
                  />
                  <ColorTagInlineBadge
                    color={'primary'}
                    tag={`STATUS:${ticketDetail.status.toUpperCase()}`}
                  />
                  {
                    ticketDetail?.ticket_coin &&
                    <ColorTagInlineBadge
                      color={'info'}
                      tag={`TICKET COINS:${ticketDetail?.ticket_coin?.coins}`}
                    />
                  }
                </Col>
                {
                  isTicketCoinAcceptable &&
                  <Col md={3} sm={12} className={'pt-2'}>
                    <ConfirmModal
                      onConfirm={() => onTicketCoinAccept()}
                      btnText={'Accept Coins'}
                      title={"Accept ticket coins evaluation"}
                      body={`Your organization has ${dataTicketCoins?.data?.count} left. Are you sure to use ${ticketDetail.ticket_coin.coins} ticket coins?`}
                    />
                  </Col>
                }
              </Row>
              {isEditable && <Row className="pt-5 w-100">
                <form className="form form--vertical  w-100">
                  <Col md={3}>
                    <ControlledSelectWithTitle
                      name={'priority'}
                      title={'Priority'}
                      rules={{required: 'The priority is required'}}
                      onChangeCustomHandle={handlePriorityChange}
                      defaultValue={
                        _.filter(priorityOptions, (option) => option.value === ticketDetail.priority)
                      }
                      control={control}
                      valueFn={(value) => value}
                      options={priorityOptions}
                    />
                  </Col>
                  <Col md={3} disabled>
                    <ControlledSelectWithTitle
                      name={'assigned_to'}
                      title={'Assigned To'}
                      onChangeCustomHandle={handleAssigneeChange}
                      rules={{required: 'The assigned to is required'}}
                      defaultValue={
                        _.filter(assigneeOptions, (option) => option.value === ticketDetail?.assigned_to?.uid)
                      }
                      control={control}
                      valueFn={(value) => value}
                      options={assigneeOptions}
                    />
                  </Col>
                  {
                    (!ticketDetail?.ticket_coin || ticketDetail?.ticket_coin?.status === 'requested') &&
                    <Col md={3} disabled>
                      <ControlledSelectWithTitle
                        name={'ticket_coins_amount'}
                        title={'Ticket coins amount'}
                        onChangeCustomHandle={handleTicketCoinsAmountChange}
                        rules={{required: 'The ticket coins amount is required'}}
                        defaultValue={
                          _.filter(ticketCoinOptions, (option) => option.value === ticketDetail?.ticket_coin?.coins)
                        }
                        control={control}
                        valueFn={(value) => value}
                        options={ticketCoinOptions}
                      />
                    </Col>
                  }
                  <Col md={3}>
                    <div
                      style={{'width': '100%', 'display': 'flex', 'justify-content': 'flex-end', 'marginTop': '25px'}}>
                      <DeleteButtonModal
                        buttonText="close"
                        buttonActionText="close"
                        onDelete={() => {
                          onTicketClose();
                        }}
                        message={<p>Do you really want to close this ticket ?</p>}
                      />
                    </div>
                  </Col>
                </form>
              </Row>
              }
            </CardBody>
          </Card>
          <Card className={'h-auto'}>
            <CardBody>
              <div className={"card__title"}>
                <h4 className={'bold-text'}>Messages</h4>
              </div>
              <ChatData ticket={ticketDetail}/>
            </CardBody>
          </Card>
        </Container>
      </Page>
      :
      <p> it should not reach here</p>
  )
}

export default TicketDetail;
