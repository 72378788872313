import {Button, Col, Row} from "reactstrap";
import CloseIcon from "mdi-react/CloseIcon";
import ControlledSelectWithTitle from "../../ControlledSelectWithTitle";
import ControlledCreatableSelectWithTitle from "../../ControlledCreatableSelectWithTitle";
import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFieldArray} from "react-hook-form";
import classNames from "classnames";
import {useAddContentFormQuery} from "../../../../../queries/AddContentForm";
import {getTagOptionsByTypes} from "../../../../helpers/contents";
import {getPattern} from "../../../../helpers/validators";
import toast from "react-hot-toast";
import {useIndicatorHashesMutation} from "../../../../../mutations/IndicatorHahes";
import {getErrorMessageFromResponse} from "../../../../helpers";
import RegisteredCheckBoxField from "../../RegisteredCheckBoxField";
import CampaignControlledSelect from "../../components/CampaignControlledSelect";
import BotnetControlledSelect from "../../components/BotnetControlledSelect";
import TagControlledSelect from "../../components/TagControlledSelect";


const _ = require('lodash')


const IndicatorsForm = ({control, errors, register, watch, setValue}) => {
  let isErrorIdleLoading = false;
  const mutation = useIndicatorHashesMutation();

  const {
    indicatorTypes: {data: dataIndicatorTypes, ...indicatorTypesProps},
    tags: {data: dataTags, ...tagsProps}
  } = useAddContentFormQuery();

  const [currentSha256FormEntryIndex, setCurrentSha256FormEntryIndex] = useState(-1);

  _.forEach([indicatorTypesProps, tagsProps], (props) => {
    isErrorIdleLoading |= props.isError || props.isLoading || props.isIdle;
  })

  const {fields: fieldsIndicators, append: appendIndicators, remove: removeIndicators} = useFieldArray(
    {control, name: "indicators"}
  );

  const setHashesTags = () => {
    const tags = watch(`indicators.${currentSha256FormEntryIndex}.tags`);
    const botnets = watch(`indicators.${currentSha256FormEntryIndex}.botnets`);
    const campaigns = watch(`indicators.${currentSha256FormEntryIndex}.campaigns`);
    const malware_families = watch(`indicators.${currentSha256FormEntryIndex}.malware_families`);
    const lastIndex = watch('indicators').length;

    setValue(`indicators.${lastIndex - 1}.tags`, tags);
    setValue(`indicators.${lastIndex - 2}.tags`, tags);
    setValue(`indicators.${lastIndex - 1}.botnets`, botnets);
    setValue(`indicators.${lastIndex - 2}.botnets`, botnets);
    setValue(`indicators.${lastIndex - 1}.campaigns`, campaigns);
    setValue(`indicators.${lastIndex - 2}.campaigns`, campaigns);
    setValue(`indicators.${lastIndex - 1}.malware_families`, malware_families);
    setValue(`indicators.${lastIndex - 2}.malware_families`, malware_families);
  }
  

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        if (mutation.data.data.length === 0) {
          return;
        }

        const md5s = _.join(mutation.data.data.map((hash) => hash.md5), '\n');
        const sha1s = _.join(mutation.data.data.map((hash) => hash.sha1), '\n');
        const indicatorsTypeBulk = watch(`indicators.${currentSha256FormEntryIndex}.type`).value.includes('Bulk') ? 'Bulk ' : '';

        appendIndicators([{
          type: {value: `${indicatorsTypeBulk}md5`, label: `${indicatorsTypeBulk}md5`},
          value: md5s
        }, {
          type: {value: `${indicatorsTypeBulk}sha1`, label: `${indicatorsTypeBulk}sha1`},
          value: sha1s
        }]);


        setHashesTags();
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])


  if (isErrorIdleLoading) {
    return <Fragment/>
  }

  const indicatorTypes = _.filter(dataIndicatorTypes.data, (type) => type !== 'filename');
  const indicatorTypesOptions = indicatorTypes.map((type) => ({value: type, label: type})).concat(
    indicatorTypes.map((type) => ({value: "Bulk " + type, label: "Bulk " + type}))
  );


  const [commonTagOptions, malwareFamilyTagOptions, botnetOptions, campaignOptions] = getTagOptionsByTypes(dataTags.data);

  return (
    <Fragment>
      {
        fieldsIndicators.map((item, index) =>
          item.type.value !== 'filename' &&
          <Row className={'w-100'} key={item.id}>
            <Col md={3} lg={3} xl={2}>
              <Row>
                <Col md={2} className={'d-flex flex-column justify-content-center'}>
                  <CloseIcon
                    style={{fill: 'white', cursor: 'pointer'}}
                    onClick={() => removeIndicators(index)}
                  />
                </Col>
                <Col md={10}>
                  <ControlledSelectWithTitle
                    name={`indicators.${index}.type`} title={'Type'} control={control}
                    options={indicatorTypesOptions}
                    defaultValue={watch(`indicators.${index}.type`) ? watch(`indicators.${index}.type`) : indicatorTypesOptions[0]}
                    valueFn={(value) => indicatorTypesOptions.find(c => c.value === value?.value)}
                  />

                  {
                    watch(`indicators.${index}.type.value`)?.includes('sha256') &&
                    <Button
                      outline
                      disabled={!watch(`indicators.${index}.value`)}
                      color={'success'}
                      onClick={() => {
                        setCurrentSha256FormEntryIndex(index);
                        const sha256s = [];
                        const splittedSha256 = watch(`indicators.${index}.value`)?.split("\n") || [];
                        
                        for (let i = 0; i < splittedSha256.length; ++i) {
                          const hash = splittedSha256[i];
                          if (!new RegExp(getPattern('sha256')).test(hash)) {
                            toast.error(`${hash} is not a valid 256`);
                            return;
                          } else {
                            sha256s.push(hash);
                            setCurrentSha256FormEntryIndex(index);
                          }
                        }

                        toast.loading('Check for other hashes');
                        mutation.mutate(sha256s);
                      }
                      }>
                      Autocomplete
                    </Button>
                  }

                  {
                    watch(`indicators.${index}.type.value`)?.includes('domain') && watch('tlp').value > 1 &&
                    <RegisteredCheckBoxField
                      name={`indicators.${index}.dns0`}
                      value={watch(`indicators.${index}.dns0`)}
                      onChange={(value) => {}}
                      defaultChecked={false}
                      label={"Share with dns0"}
                      register={register}
                    />
                  }

                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <TagControlledSelect
                control={control}
                commonTagOptions={commonTagOptions}
                name={`indicators.${index}.tags`}
                isClearable={
                  watch(`indicators.${index}.tags`) && !watch(`indicators.${index}.tags`).find((t) => t.isFixed)
                }
              />
              <ControlledSelectWithTitle
                name={`indicators.${index}.malware_families`} title={'Malware Families'} control={control}
                isClearable={watch(`indicators.${index}.malware_families`) && !watch(`indicators.${index}.malware_families`).find((t) => t.isFixed)}
                isSearchable isMulti
                options={malwareFamilyTagOptions} valueFn={(value) => value}
              />
              <Row>
                <Col xs={12} md={12} lg={6}>
                  <BotnetControlledSelect
                    name={`indicators.${index}.botnets`}
                    control={control}
                     botnetOptions={botnetOptions}
                  />
                </Col>
                <Col xs={12} md={12} lg={6}>
                  <CampaignControlledSelect
                     campaignOptions={campaignOptions}
                     control={control}
                     name={`indicators.${index}.campaigns`}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={5} lg={5} xl={6}>
              <div className={classNames({
                'form__form-group': true,
                "form__form-group-error-validation": errors?.indicators && errors.indicators[index] && errors.indicators[index].value
              })
              }>
                <span className="form__form-group-label">Value</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-input-wrap">
                    {
                      watch(`indicators.${index}.type`)?.label?.includes('Bulk') ?
                        <textarea {...register(`indicators.${index}.value`, {required: 'The value is required'})} /> :
                        <input {...register(`indicators.${index}.value`, {required: 'The value is required'})} />
                    }
                    {
                      errors?.indicators && errors.indicators[index] && errors.indicators[index].value
                      && <span className="form__form-group-error">{errors.indicators[index].value.message}</span>
                    }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )
      }
      {
        errors.indicators && errors.indicators.message &&
        <span className="form__form-group-error">{errors.indicators.message}</span>
      }
      <Row className={'justify-content-end w-100'}>
        <Button outline color={'success'} onClick={() => appendIndicators({type: indicatorTypesOptions[0]})}>
          Add indicator
        </Button>
      </Row>
    </Fragment>
  )
}


IndicatorsForm.propsType = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
}


export default IndicatorsForm;
