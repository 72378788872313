import React from 'react';
import {Col, Container, Row} from 'reactstrap';
import {connect} from "react-redux";
import TargetVictimStats from "./components/TargetVictimStats";
import LastFileSandboxTable from "./components/LastFileSandboxTable";
import TargetedSectorsHorizontalBarChart from "./components/TargetedSectorsHorizontalBarChart";
import MostCommonTagsWordCloud from "./components/MostCommonTagsWordCloud";
import SandboxTaskResultsPie from './components/SandboxTaskResultsPie';
import IndicatorsPieChart from "./components/IndicatorsPieChart";
import LastContentsTable from "./components/LastContentsTable";
import LastRansomwareTrackerEventsTable from "./components/LastRansomwareTrackerEventsTable";
import MalwareFamiliesHorizontalBarChart from "./components/MalwareFamiliesHorizontalBarChart";
import {useCookieAccessToken} from "../../../queries";
import RansomwareEventsByActorsHorizontalBarChart from "./components/RansomwareTrackerEventsByActor";
import RansomwareTargetVictimStats from "./components/RansomwareTrackerMostTargetedCountries";
import LastNewsFeedlyFeedsTable from "./components/LastNewsFeedlyFeedsTable";
import env_const from "../../../config/env_const";
import {isFeatureExistsForCurrentUser} from "../../../shared/helpers/permissions";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import {
  PLATFORM_FEATURE_ACTORS,
  PLATFORM_FEATURE_CONTENTS,
  PLATFORM_FEATURE_OBSERVABLES,
  PLATFORM_FEATURE_SANDBOX
} from "../../../shared/helpers/features";
import {OPERATOR_ROLE} from "../../../shared/helpers/user";
import ActorsCarousel from "./components/ActorsCarousel";


const CompanyDashboard = () => {
  const {user} = useCookieAccessToken();
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const userCanViewActors = isFeatureExistsForCurrentUser(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_ACTORS);
  const userCanViewContents = isFeatureExistsForCurrentUser(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_CONTENTS);
  const userCanViewObservables = isFeatureExistsForCurrentUser(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_OBSERVABLES);
  const userCanViewSandbox = isFeatureExistsForCurrentUser(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_SANDBOX);

  if (dataCurrentUser?.data?.role === OPERATOR_ROLE) {
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="welcome-title">Welcome Back, {(user ? (user.name || user.fullName) : 'Guest')}
            </h3>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="welcome-title">Welcome Back, {(user ? (user.name || user.fullName) : 'Guest')}
          </h3>
        </Col>
      </Row>

      <Row>
        {
          userCanViewContents &&
          <Col md={12} xl={userCanViewObservables ? 7 : 12}>
            <MalwareFamiliesHorizontalBarChart malwareFamiliesLimit={7}/>
          </Col>
        }
        {
          userCanViewObservables &&
          <Col md={12} xl={userCanViewContents ? 5 : 12}>
            <IndicatorsPieChart/>
          </Col>
        }
      </Row>

      {
        userCanViewContents && !(
          env_const.is_clone && ['almond', 'xmco'].includes(dataCurrentUser.data.organization.name.toLowerCase())
        ) &&
        <Row>
          <Col md={12}>
            <TargetVictimStats/>
          </Col>
        </Row>
      }

      {
        userCanViewActors &&
        <>
          <Row className={'mt-2'}>
            <Col md={12}>
              <h3 className="page-title">LATEST ACTIVE ACTORS</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="m-auto" >
              <ActorsCarousel />
            </Col>
          </Row>
        </>
      }

      {
        userCanViewContents &&
        <>
          <Row>
            <Col md={12}>
              <LastContentsTable contentsLimit={7}/>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <MostCommonTagsWordCloud tagsLimit={30}/>
            </Col>
            <Col md={6}>
              <TargetedSectorsHorizontalBarChart/>
            </Col>
          </Row>

          {
            !env_const.is_on_prem &&
            <Row>
              <Col md={12} className="m-auto">
                <LastNewsFeedlyFeedsTable/>
              </Col>
            </Row>
          }
        </>
      }

      {
        !env_const.is_on_prem && userCanViewContents &&
        <>
          <Row>
            <Col md={12} className="mt-3">
              <h3 className="page-title">Ransomware Tracker</h3>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <LastRansomwareTrackerEventsTable limit={10}/>
            </Col>
          </Row>

          <Row>
            <Col md={12} xl={7} className="mb-5">
              <RansomwareTargetVictimStats/>
            </Col>
            <Col md={12} xl={5} className="mb-5">
              <RansomwareEventsByActorsHorizontalBarChart/>
            </Col>
          </Row>
        </>
      }

      {
        userCanViewSandbox &&
        <>
          <Row>
            <Col md={12} className="mt-3">
              <h3 className="page-title">Sandbox Statistics</h3>
            </Col>
          </Row>

          <Row>
            <Col md={12} xl={8} className="mb-5">
              <LastFileSandboxTable tasksLimit={6}/>
            </Col>
            <Col md={12} xl={4} className="mb-5">
              <SandboxTaskResultsPie/>
            </Col>
          </Row>
        </>
      }

    </Container>
  );
};

CompanyDashboard.propTypes = {};

export default connect(state => ({theme: state.theme, rtl: state.rtl}))(CompanyDashboard);
