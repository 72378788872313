import {Col, Row} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";


const LegendItem = ({style}) => {
  return (
    <Row style={style} className={'align-items-center'}>
      <Col md={1} xs={1}></Col>
      <Col md={1} xs={1}><h5 className={'bold-text'}>Type</h5></Col>
      <Col md={3} xs={3}><h5 className={'bold-text'}>Title</h5></Col>
      <Col md={3} xs={3}><h5 className={'bold-text'}>Tags</h5></Col>
      <Col md={2} xs={2}><h5 className={'bold-text'}>Created at</h5></Col>
      <Col md={2} xs={2}><h5 className={'bold-text'}>Modified at</h5></Col>
    </Row>
  )
}

LegendItem.propTypes = {
  style: PropTypes.shape().isRequired
}

export default LegendItem;
