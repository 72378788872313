import React from 'react';
import {Card, CardBody, Col, Row} from "reactstrap"
import MenuEntry from "../../../../../shared/components/MenuEntry"
import MenuEntryWithCopyIcon from "../../../../../shared/components/MenuEntryWithCopyIcon"
import PropTypes from "prop-types";
import TooltipHeader from './TooltipHeader';
import NodeTags from "../../../../../shared/components/badge/NodeTags";
import NodeStats from "../../../../../shared/components/card/NodeStats";

const TooltipFiles = ({node}) => {
  const data = node.data;
  return (
    <Card className={'h-auto'} style={{'paddingBottom': '0px'}}>
      <CardBody>
        <TooltipHeader node={node}/>
        <Row>
          <Col md={6}>
            <div className="custom_card__container">
              <MenuEntryWithCopyIcon title={'MD5'} value={data.md5}/>
              <MenuEntryWithCopyIcon title={'SHA1'} value={data.sha1}/>
              <MenuEntryWithCopyIcon title={'sha256'} value={data.sha256}/>
              <MenuEntryWithCopyIcon title={'SSDEEP'} value={data.ssdeep}/>
              <MenuEntry title={'TYPE DESCRIPTION'} value={data.type_description}/>
            </div>
          </Col>
          <Col md={6}>
            <div className="custom_card__container">
              <MenuEntry title={'first_seen'} value={data.first_seen}/>
              <NodeTags node={node} />
              <NodeStats node={node} />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

TooltipFiles.propTypes = {
  node: PropTypes.shape().isRequired,
}

export default TooltipFiles;
