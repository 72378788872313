import {Card, CardBody, Col, Row} from "reactstrap";
import React from "react";


const TypoSquattingResultLegend = () => {
  return (
    <Card className={'pb-2 mb-4 sticky-top'}>
      <CardBody className={"py-2"}>
        <Row>
          <Col md={3} style={{paddingLeft: '45px'}}>
            <p>Fraud Domain</p>
          </Col>
          <Col md={1}>
            <p>Score <br/>
              (up to 100)</p>
          </Col>
          <Col md={1}>
            <p>HTTP Status</p>
          </Col>
          <Col md={1}>
            <p>HTTPS Status</p>
          </Col>
          <Col md={1}>
            <p>Port 80</p>
          </Col>
          <Col md={1}>
            <p>Port 443</p>
          </Col>
          <Col md={3}>
            <p>History</p>
          </Col>
          <Col md={1}>
            <p>Algorithm</p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}


TypoSquattingResultLegend.propTypes = {}


export default TypoSquattingResultLegend;