import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Collapse from '../../../shared/components/Collapse';
import { Col, Row } from 'reactstrap';
import paths from '../../../config/paths';
import { ColorTagInlineBadgeList } from '../../../shared/components/badge/BadgeLists';
import CopyIconToClipboard from '../../../shared/components/icons/CopyIconToClipboard';
import VirusTotalIndicatorLink from '../../../shared/components/icons/VirusTotalIndicatorLink';
import ColorTagInlineBadge from '../../../shared/components/badge/ColorTagInlineBadge';
import MenuEntryWithCopyIconAndLink from '../../../shared/components/MenuEntryWithCopyIconAndLink';
import MenuEntryWithCopyIcon from '../../../shared/components/MenuEntryWithCopyIcon';
import MaliciousStatsBadge from '../../../shared/components/badge/MaliciousStatsBadge';
import { bytesToSizeOrNA } from '../../../shared/helpers';

const FilesInfoCollapse = ({ data }) => {
  return (
    data.map((file) =>
      <Collapse
        title={
          <Fragment>
            <span className={'span__font-monospace'} style={{ lineHeight: '30px' }}>{file.sha256}</span>
            <span className={'float-right mr-3'}>
              <h4>
                <ColorTagInlineBadge color={'secondary'} tag={file.type_description}/>
                <MaliciousStatsBadge stats={file.stats}/>
                <CopyIconToClipboard
                  value={file.sha256}
                  className={'icon__copy-file-info-collapse'}
                />
                <VirusTotalIndicatorLink value={file.sha256}/>
              </h4>
            </span>
          </Fragment>
        }
        className={'with-shadow'}
        key={file.sha256}
      >
        <Row>
          <Col md={7}>
            <div className="custom_card__container">
              {
                file.md5 && <MenuEntryWithCopyIconAndLink
                  title={'MD5'}
                  value={file.md5}
                  url={`${paths.investigatePath}?indicator=${(file.md5)}`}
                />
              }
              {
                file.sha1 && <MenuEntryWithCopyIconAndLink
                  title={'SHA1'}
                  value={file.sha1}
                  url={`${paths.investigatePath}?indicator=${(file.sha1)}`}
                />
              }
              {
                file.sha256 && <MenuEntryWithCopyIconAndLink
                  title={'SHA256'}
                  value={file.sha256}
                  url={`${paths.investigatePath}?indicator=${(file.sha256)}`}
                />
              }
              {
                file.ssdeep && <MenuEntryWithCopyIcon title={'SSDEEP'} value={file.ssdeep}/>
              }

            </div>
          </Col>

          <Col md={5}>
            {file.size && <MenuEntryWithCopyIcon title={'FILE SIZE'} value={bytesToSizeOrNA(file.size)}/>}
            {
              file.names &&
              <div className="custom_card__container">
                <p className="custom_card__container-subhead">FILE NAMES</p>
                {
                  file.names.map((name) =>
                    <p className="custom_card__container-title">{name}</p>
                  )
                }
              </div>
            }

            {
              file.tags &&
              <div className="custom_card__container">
                <p className="custom_card__container-subhead">TAGS</p>
                <h4><ColorTagInlineBadgeList items={file.tags.map((tag) => ({ name: tag }))}/></h4>
              </div>
            }
          </Col>
        </Row>
      </Collapse>
    )
  );
};

FilesInfoCollapse.propTypes = {
  data: PropTypes.array.isRequired
};

export default FilesInfoCollapse;
