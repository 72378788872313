import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const AddMalwareFetcher = (accessToken, params) => {
  return axios.post(`${apiUrlInternal}/malware_families`, params, addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}

export const useAddMalwareMutation = (fetcher = AddMalwareFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['add-malware'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('add-malware');
        queryClient.invalidateQueries('malware-family');
      }
    }
  )
}
