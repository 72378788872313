import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";

export const DeleteFeedlyFeedsPreferencesFetcher = (
  accessToken, params
) => {
  const urlParams = new URLSearchParams();
  if (params.uid) {
    urlParams.set('uid', params.uid);
  }
  return axios.delete(`${apiUrlInternal}/news_feeds/preferences?${urlParams.toString()}`,
    addAuthToFetchOpts(accessToken, {})).then(res => res.data);
}


export const useDeleteFeedlyFeedsPreferenceQuery = (
  fetcher = DeleteFeedlyFeedsPreferencesFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['delete-feedly-feeds-preferences'],
    (accessToken, params) => fetcher(accessToken, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['feedly-feeds-preferences', 'malware_family'])
        queryClient.invalidateQueries(['feedly-feeds-preferences', 'actor']);
      }
    }
  )
}
