import React from "react";
import PropTypes from "prop-types";

const DnsResolutionHistogramTooltip = ({active, payload, label, style}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={style.contentStyle}>
        <p className="intro">{label}</p>
        {payload[0].payload.tooltip.map(x => (<p className="label">{x.name}: {x.value}</p>))}
        <p className="desc">Total: {payload[0].payload.tooltip.reduce((acc, curr) => acc + Number(curr.value), 0)}</p>
      </div>
    );
  }

  return null;
};

DnsResolutionHistogramTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.object,
  label: PropTypes.string,
  style: PropTypes.object
}

export default DnsResolutionHistogramTooltip;