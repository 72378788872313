import PropTypes from "prop-types";
import React from "react";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";


const IpInfoIcon = ({asn}) =>
  <InformationOutlineIcon
    onClick={(e) => {
      window.open(`https://ipinfo.io/AS${asn}`, "_blank");
      e.preventDefault();
    }}
    size={24}
    style={{fill: 'white', padding: '2px'}}
    className={'border-hover-white'}
  />


IpInfoIcon.propTypes = {
  asn: PropTypes.number.isRequired
}

export default IpInfoIcon;
