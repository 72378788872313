import React from "react";
import PropTypes from "prop-types";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import paths from "../../../../../config/paths";
import CountryBadge from "../../../../../shared/components/badge/CountryBadge";
import ThemedClampLines from "../../../../../shared/components/clamp-lines/ThemedClampLines";
import {formatDateUTCtoYearMonthDay} from "../../../../../shared/helpers/date";


const ActorCarouselCard = ({content}) => {
  const actor = content;

  return (
    <Card className="carouselCard">
      <CardBody style={{borderRadius: "5px", borderLeft: "4px solid #3A888B"}}>
        <Row>
          <Col md={12}>
            <div className="card__title">
              <h4 className={'bold-text d-inline'}>
                <Link to={`${paths.actorPath}/${actor.uid}`}>{actor.name}</Link>
              </h4>
              <span className={'float-right'}><CountryBadge countryCode={actor.origin_country} style={{fontSize: '12px'}}/></span>
            </div>
          </Col>

          <Col md={12}>
            <p>
              <ThemedClampLines
                text={actor.description ? actor.description : ''}
              />
            </p>
          </Col>

          <Col className="mt-3">
            <Row style={{height: '40px'}}>
              <Col className={'m-auto card__title'} md={7}>
                {actor.last_seen && <p className={'subhead'}>Last activity on  <b style={{color: 'white'}}>{formatDateUTCtoYearMonthDay(actor.last_seen)}</b></p>}
              </Col>
              <Col md={5}>
                <Link to={`${paths.actorPath}/${actor.uid}`}>
                  <Button
                    className="card-carousel-button"
                    size={32}
                  >See Actor</Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}


ActorCarouselCard.propTypes = {
  content: PropTypes.shape().isRequired,
}


export default ActorCarouselCard;
