import React, {useState} from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import FilesInfoCollapse from "./FilesInfoCollapse";
import classnames from "classnames";
import IpsInfoTable from "./IpsInfoTable";
import DomainsInfoTable from "./DomainsInfoTable";
import UrlsInfoTable from "./UrlsInfoTable";
import PaginatedTabPane from './PaginatedTabPane';


const HashRelationsCard = ({
  indicator,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [totals, setTotal] = useState({
    dropped_files: "",
    bundled_files: "",
    contacted_ips: "",
    contacted_domains: "",
    contacted_urls: "",
  })
  const setTotals = (number, endpoint_type) => {
    totals[endpoint_type] = number
    setTotal({...totals})
  }
  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">Other relations</h4>
        </div>
        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({active: activeTab === 0})}
                    onClick={() => toggleTab(0)}
                  >DROPPED FILES {totals.dropped_files !== "" && `(${totals.dropped_files})`}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({active: activeTab === 1})}
                    onClick={() => toggleTab(1)}
                  >BUNDLED FILES {totals.bundled_files !== "" && `(${totals.bundled_files})`}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({active: activeTab === 2})}
                    onClick={() => toggleTab(2)}
                  >CONTACTED IPs {totals.contacted_ips !== "" && `(${totals.contacted_ips})`}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({active: activeTab === 3})}
                    onClick={() => toggleTab(3)}
                  >CONTACTED DOMAINs {totals.contacted_domains !== "" && `(${totals.contacted_domains})`}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({active: activeTab === 4})}
                    onClick={() => toggleTab(4)}
                  >CONTACTED URLs {totals.contacted_urls !== "" && `(${totals.contacted_urls})`}</NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <PaginatedTabPane indicator={indicator} tabId={0} Component={FilesInfoCollapse} endpoint_type={'dropped_files'} setTotal={setTotals}/>
                <PaginatedTabPane indicator={indicator} tabId={1} Component={FilesInfoCollapse} endpoint_type={'bundled_files'} setTotal={setTotals}/>
                <PaginatedTabPane indicator={indicator} tabId={2} Component={IpsInfoTable} endpoint_type={'contacted_ips'} setTotal={setTotals}/>
                <PaginatedTabPane indicator={indicator} tabId={3} Component={DomainsInfoTable} endpoint_type={'contacted_domains'} setTotal={setTotals}/>
                <PaginatedTabPane indicator={indicator} tabId={4} Component={UrlsInfoTable} endpoint_type={'contacted_urls'} setTotal={setTotals}/>
            </TabContent>
          </div>
        </div>

      </CardBody>
    </Card>
  )
}


HashRelationsCard.propTypes = {
  indicator: PropTypes.string.isRequired,
}


export default HashRelationsCard;
