import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import React from "react";


const DescriptionCard = ({register, rules = {}}) => {
  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>Description</h4>
        </div>
        <textarea
          className={'textarea--add-contents'}
          {...register("description", rules)}
        />
      </CardBody>
    </Card>
  )
}

DescriptionCard.propTypes = {
  register: PropTypes.func.isRequired
}

export default DescriptionCard;
