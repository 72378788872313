import {Card, CardBody} from "reactstrap";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../LoadingSpinner";
import RansomwareAttacksTableWithPagination from "../table/RansomwareAttacksTableWithPagination";
import _ from "lodash";
import {
  useSearchRansomwareTrackerEventsQueryWithDict
} from "../../../containers/RansomwareTrackerEvents/queries/SearchRansomwareTrackerEvents";


const RansomwareAttackTablePaginationCard = ({
                                               filters,
                                               title = 'Ransomware Attacks',
                                             }) => {
  const {data, isIdle, isLoading, isError} = useSearchRansomwareTrackerEventsQueryWithDict(filters);

  if (isIdle || isLoading) {
    return (
      <Card><CardBody>
        <div className="card__title"><h4 className={'bold-text d-inline'}>{title}</h4><LoadingSpinner/></div>
      </CardBody></Card>
    )
  }

  if (isError) {
    return (
      <Card><CardBody>
        <div className="card__title"><h4 className={'bold-text d-inline'}>{title}</h4><h3>Unable to load the
          content</h3></div>
      </CardBody></Card>
    )
  }

  if (_.sum(_.values(data?.data)) === 0 || data?.metadata.total === 0) {
    return <Fragment/>
  }

  return (
    data?.metadata.total && <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>{title}</h4>
        </div>
        <RansomwareAttacksTableWithPagination filters={filters} count={data?.metadata.total}/>
      </CardBody>
    </Card>
  )
}


RansomwareAttackTablePaginationCard.propsType = {
  filters: PropTypes.shape(),
  title: PropTypes.string,
}

export default RansomwareAttackTablePaginationCard;
