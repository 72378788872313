import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SetNotificationReadFetcher = (user_uid, not_uid, accessToken) => {
  return axios.post(`${apiUrlInternal}/notifications/user/${user_uid}/read/${not_uid}`, {}, addAuthToFetchOpts(accessToken, {})).then(res => res.data );
}


export const useSetNotificationRead = (fetcher = SetNotificationReadFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['notification-user-read'],
    (accessToken, {user_uid, not_uid}) => fetcher(user_uid, not_uid, accessToken),
    {onSuccess: () => {
        queryClient.invalidateQueries('notification-user-read');
      }}
  )
}
