import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import _ from "lodash";


export const eventContentsIndicatorsFetcher = (accessToken, contentUid, ioc_type, page, limit = null) => {
  const urlParams = new URLSearchParams();
  if (page !== null) {
    urlParams.set('page', page);
  }
  if (limit) {
    urlParams.set('limit', limit);
  }
  if (ioc_type) {
    urlParams.set('ioc_type', ioc_type);
  }

  return axios.get(`${apiUrlInternal}/contents/${contentUid}/indicators?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {}))
    .then(res => res.data);
}

export const useEventContentsIndicatorsWithPaginationQuery = (contentUid, ioc_type, limit, page, fetcher = eventContentsIndicatorsFetcher) => {
  return useQueryWithAuthentication(['contents-indicator', contentUid, ioc_type, limit, page],
    (accessToken) => fetcher(accessToken, contentUid, ioc_type, limit, page),
    {enabled: contentUid !== null && !_.isUndefined(contentUid)}
  )
}