import {addAuthToFetchOpts} from '../config/queryopts'
import {useMutationWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import {useQueryClient} from "react-query";


export const SetAllNotificationReadFetcher = (user_uid, accessToken) => {
  return axios.post(`${apiUrlInternal}/notifications/user/${user_uid}/read/all`, {}, addAuthToFetchOpts(accessToken, {})).then(res => res.data );
}


export const useSetAllNotificationRead = (fetcher = SetAllNotificationReadFetcher) => {
  const queryClient = useQueryClient();
  return useMutationWithAuthentication(
    ['notification-user-read-all'],
    (accessToken, user_uid) => fetcher(user_uid, accessToken),
    {onSuccess: () => queryClient.invalidateQueries('notification-user-read-all')}
  )
}
