import React from "react"
import AlphaAIcon from "mdi-react/AlphaAIcon";
import AlphaCIcon from "mdi-react/AlphaCIcon";
import AlphaDIcon from "mdi-react/AlphaDIcon";
import AlphaRIcon from "mdi-react/AlphaRIcon";
import {formatDateUTCtoYearMonthDayTime} from "../../../shared/helpers/date";


export const CLOSED_OPTIONS = [
  {label: 'closed as fp', value: 'closed_as_fp'},
  {label: 'closed taken down', value: 'closed_taken_down'}
];


export const getTicketEvents = (ticketDetail, type = 'events') => {
  const events = [];
  let percentage;

  events[0] = {
    title: `${ticketDetail.created_by.email} has opened a ticket to takedown ${ticketDetail.indicator}`,
    date: formatDateUTCtoYearMonthDayTime(ticketDetail.created_dt),
    letter: <AlphaCIcon size={48}/>
  }
  percentage = 25;

  if (ticketDetail.assigned_dt) {
    events[1] = {
      title: `Ticket has been assign to ${ticketDetail.assigned_to?.email}`,
      date: formatDateUTCtoYearMonthDayTime(ticketDetail.assigned_dt),
      letter: <AlphaAIcon size={48}/>
    }
    percentage = 50;
  }

  if (ticketDetail.request_sent_dt) {
    events[2] = {
      title: `Request has been sent to the provider to take down ${ticketDetail.indicator}`,
      date: formatDateUTCtoYearMonthDayTime(ticketDetail.request_sent_dt),
      letter: <AlphaRIcon size={48}/>
    }
    percentage = 75;
  }

  if (ticketDetail.closed_dt) {
    let closeMsg = '';

    if (ticketDetail.status === 'closed_taken_down') {
      closeMsg = `${ticketDetail.indicator} has been taken down by the provider`;
    } else {
      closeMsg = 'Ticket close due to false positive';
    }

    events[3] = {
      title: closeMsg,
      date: formatDateUTCtoYearMonthDayTime(ticketDetail.closed_dt),
      letter: <AlphaDIcon size={48}/>
    }

    percentage = 100;
  }

  return type === 'events' ? events : percentage;
}
