import {addAuthToAxiosOpts} from '../config/queryopts'
import {useQueryWithAuthentication} from "./index";
import {apiUrlInternal} from "../config/api"
import axios from "axios";
import PropTypes from "prop-types";


const _ = require('lodash');


export const IndicatorDetailsByValueFetcher = (
  accessToken, value, indicator_type = null, include_ext = true, sections = null, no_paginated_results=true
) => {

  const urlParams = new URLSearchParams();

  if (indicator_type) {
    urlParams.set('indicator_type', indicator_type);
  }
  if (include_ext) {
    urlParams.set('include_ext', true);
  }
  if (sections) {
    sections.forEach((tag) => urlParams.append('sections', tag));
  }
  if (no_paginated_results) {
    urlParams.set('no_paginated_results', no_paginated_results)
  }

  urlParams.set('indicator', value)

  return axios.get(
    `${apiUrlInternal}/investigate?${urlParams.toString()}`, addAuthToAxiosOpts(accessToken, {}, 40000)
  ).then(res => res.data);
}


export const useIndicatorDetailsByValueQuery = (
  value, indicator_type = null, include_ext = true, sections = null, fetcher = IndicatorDetailsByValueFetcher
) => {
  return useQueryWithAuthentication(
    ['investigate', value, indicator_type, include_ext, sections],
    (accessToken) => fetcher(accessToken, value, indicator_type, include_ext, sections),
    {staleTIme: Infinity, refetchOnWindowFocus: false, enabled: !_.isUndefined(value) && value !== ''}
  )
}


useIndicatorDetailsByValueQuery.propTypes = {
  value: PropTypes.string.isRequired,
  indicator_type: PropTypes.string,
  include_ext: PropTypes.bool,
  sections: PropTypes.array
};
