import React, {Fragment, useEffect, useState} from "react"
import {Col, Row} from "reactstrap";
import IndicatorDetail from "../../InvestigateDetail/components/IndicatorDetail";
import {useIndicatorDetailsByValueQuery} from "../../../queries/IndicatorInvestigate";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import PropTypes from "prop-types";
import {takeDownIndicatorQuerySections} from "../../../shared/helpers/investigate";

const _ = require('lodash');


const TakeDownInvestigateSec = ({ticketDetail}) => {
  const [indicator_details, setIndicator_details] = useState(null);
  const {data, isIdle, isLoading, isError, error} = useIndicatorDetailsByValueQuery(
    ticketDetail.indicator,
    ticketDetail.indicator_type,
    true,
    takeDownIndicatorQuerySections
  );

  useEffect(() => {
    if (!isIdle && !isLoading && !isError) {
      setIndicator_details(data.data);
    }
  }, [isIdle, isLoading, isError, data])

  if (isError) {
    return <ErrorHandler error={error}/>
  }

  if (isLoading || isIdle || !indicator_details) {
    return <LoadingSpinner/>
  }

  return (
    <Fragment>
      <IndicatorDetail indicator_details={indicator_details} showFooter={true}/>
    </Fragment>
  )
}

TakeDownInvestigateSec.propTypes = {
  ticketDetail: PropTypes.shape().isRequired
}

export default TakeDownInvestigateSec;
