import React from "react";
import {useParams} from "react-router-dom";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorHandler from "../../shared/components/ErrorHandler";
import _ from "lodash";
import ApplicationForm from "../../shared/components/form/application-form/ApplicationForm";
import { useApplicationDetailsQuery } from "../../queries/Applications";
import { useEditApplicationMutation } from "../../mutations/UpdateApplicationMutation";
import { getApplicationDefaultValues } from "../../shared/helpers/contents";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_APPLICATIONS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const ApplicationEdit = () => {
  const { applicationUid: uid } = useParams();
  const {data, isIdle, isLoading, isError, error} = useApplicationDetailsQuery(uid);

  if (isIdle || isLoading) {
    return <LoadingSpinnerPage />
  }

  if (isError) {
    return <ErrorHandler error={error} />
  }

  const defaultValues = getApplicationDefaultValues(data.data);

  return (
    <Page
      feature={PLATFORM_FEATURE_APPLICATIONS}
      permission={PLATFORM_PERMISSION_EDITOR}
    >
      <ApplicationForm
        defaultValues={defaultValues}
        mutationFn={useEditApplicationMutation}
        editUid={uid}
        editForm
        appOrganization={data.data.organization}
      />
    </Page>
  )
}


export default ApplicationEdit;
