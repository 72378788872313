import axios from "axios";
import {apiUrlInternal} from "../../config/api";
import {addAuthToAxiosOpts} from "../../config/queryopts";
import {useQueryWithAuthentication} from "../index";

const RetroHuntFetcher = (accessToken, endpoint) => {
  return axios.get(`${apiUrlInternal}/retrohunt/${endpoint}`, addAuthToAxiosOpts(accessToken, {}))
    .then(res => res.data);
}

const useRetroHuntQuery = (endpoint = null, options = {}, fetcher = RetroHuntFetcher) => {
  return useQueryWithAuthentication(['retrohunt', endpoint],(accessToken) => fetcher(accessToken, endpoint), options)
}

export const useRetroHuntTasks = (uid = null, limit = null, offset = null) => {
  const endpoint = `tasks${(uid) ? `/${uid}` : ''}`;
  return useRetroHuntQuery(endpoint, {refetchInterval: 20000});
}


export const useRetroHuntTaskMatches = (uid, limit = 0, offset = 0) => {
  const urlParams = new URLSearchParams();
  if (limit) {
    urlParams.set('limit', limit);
  }
  if (offset) {
    urlParams.set('offset', offset * limit);
  }
  const endpoint = `tasks/${uid}/matches?${urlParams.toString()}`;

  return useRetroHuntQuery(endpoint);
}
