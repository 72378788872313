import React from "react";
import {Badge} from "reactstrap";
import PropTypes from "prop-types";
import {getTlpColor} from "../../helpers/tlp";
import {getBadgeClasses} from "../../helpers/badge";

const _ = require('lodash')


const TLPBadge = ({tlp, size= '', style = {}}) => {
  tlp = _.toUpper(tlp);
  const classes = getBadgeClasses(size)

  return (
    <Badge style={style} color={getTlpColor(tlp)} className={classes}>TLP:{tlp}</Badge>
  )
}

TLPBadge.propTypes = {
  tlp: PropTypes.string.isRequired,
  size: PropTypes.string,
}

export default TLPBadge;
