import React, {Fragment, useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Button, ButtonToolbar} from "reactstrap";
import ThemeModal from "../../../shared/components/ThemeModal";


const DeleteButtonModal = ({
  onDelete,
  message,
  buttonText = 'Delete',
  buttonActionText = 'Delete',
  disabled = false,
  postScriptum,
  unblockDate
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => setShowModal(!showModal), [setShowModal, showModal]);
  return (
    <Fragment>
      <Button
        disabled={disabled}
        onClick={() => toggleModal()}
        color={'danger'}
        outline
      >{buttonText}</Button>
      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => {
            toggleModal();
          }}/>
          <h4 className="text-modal modal__title">{message}</h4>
          {postScriptum && unblockDate && message &&
            <div style={{padding: "25px"}}>{postScriptum}<br/><b className="text-danger">{unblockDate}</b></div>}
        </div>

        <ButtonToolbar className="modal__footer">
          <Button outline className="rounded" color='danger' onClick={() => {
            onDelete();
            toggleModal();
          }}>{buttonActionText}</Button>
          <Button outline className="rounded" onClick={toggleModal}>Cancel</Button>
        </ButtonToolbar>
      </ThemeModal>
    </Fragment>
  )
}


DeleteButtonModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  message: PropTypes.element.isRequired,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool
}

export default DeleteButtonModal;
