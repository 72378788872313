import React from "react";
import AlphaMIcon from "mdi-react/AlphaMIcon";
import {useHistory} from "react-router-dom";
import ThemedFabMenu from "../../../shared/components/ThemedFabMenu";
import paths from "../../../config/paths";


const FabMalwareFamilyMenu = () => {
  const history = useHistory();
  const actions = [
    {
      text: "Add Malware",
      icon: <AlphaMIcon/>,
      onClick: () => history.push(paths.addMalwarePath)
    }
  ];

  return (
    <ThemedFabMenu actions={actions}/>
  );
}


export default FabMalwareFamilyMenu;
