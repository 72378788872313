import {Col, Row} from "reactstrap";
import React from "react";
import CarouselWithDataHook from "../../../../shared/components/carousel/CarouselWithDataHook";
import {useStatsActorsByRecentActivities} from "../../../../queries/Stats";
import ActorCarouselCard from "./carousel/ActorCarouselCard";


const ActorsCarousel = () =>
  <Row>
    <Col md={12} className="d-inline-flex align-content-center m-auto">
      <CarouselWithDataHook
        useDataQuery={useStatsActorsByRecentActivities}
        CarouselElement={ActorCarouselCard}
        cardCount={5}
      />
    </Col>
  </Row>


ActorsCarousel.propTypes = {}


export default ActorsCarousel;