import React, {Fragment, useMemo} from "react";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import paths from "../../../config/paths";
import {ColorTagInlineBadgeList} from "../badge/BadgeLists";
import Index from "../modal/IndicatorsTab";
import PropTypes from "prop-types";
import VirusTotalIndicatorLink from "../icons/VirusTotalIndicatorLink";
import CopyIconToClipboard from "../icons/CopyIconToClipboard";
import {getCommonTags} from "../../helpers";


const _ = require('lodash')


const IndicatorsTabRows = ({
  structuredDisplayIndicators, structuredIndicators, activeIocType, maxRows
}) =>
  <Fragment >
    {
      _.sortBy(structuredDisplayIndicators, (ioc) => ioc.value).map(
        (ioc) => getCommonTags(ioc.tags).length > 0 ?
          <Row className={'w-100'} key={ioc.value}>
            <Col md={7} xs={6} className={'text-break'}>
              <Link to={`${paths.investigatePath}?indicator=${ioc.value}`}>{ioc.value}</Link>
            </Col>
            <Col md={3} xs={4}>
              <ColorTagInlineBadgeList items={getCommonTags(ioc.tags)} />
            </Col>
            <Col md={2}>
              <VirusTotalIndicatorLink value={ioc.value} />
              <CopyIconToClipboard value={ioc.value} size={21} />
            </Col>
            }
          </Row> :
          <Row className={'w-100'} key={ioc.value}>
            <Col md={12}>
              <Link to={`${paths.investigatePath}?indicator=${ioc.value}`}>{ioc.value}</Link>
            </Col>
          </Row>
      )
    }
    {
      structuredIndicators.length > maxRows &&
      <Index
        indicators={structuredIndicators}
        iocType={activeIocType}
      />
    }
  </Fragment>


IndicatorsTabRows.propTypes = {
  structuredDisplayIndicators: PropTypes.array.isRequired,
  structuredIndicators: PropTypes.array.isRequired,
  activeIocType: PropTypes.string.isRequired,
  maxRows: PropTypes.number.isRequired
}

export default IndicatorsTabRows;
