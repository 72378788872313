import VerticalBarChart from "../../../../shared/components/charts/VerticalBarChart";
import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";


const MostVulnHostsVerticalBarCard = ({
  hostVulnerabilities
}) =>

  <Card>
    <CardBody>
      <div className="card__title">
        <h4 className="bold-text">Most Vulnerable Hosts</h4>
      </div>

      <VerticalBarChart
        data={
          _.orderBy(
            _.toPairs(hostVulnerabilities).map(
              (value) => ({text: value[0], value: value[1]})
            ),
            val => val.value,
            'desc'
          ).slice(0, 5)
        }
        height={350}
        dataKey={'value'}
        labelDataKey={'text'}
        legendLabel={'Vulnerabilities'}
      />
    </CardBody>
  </Card>


MostVulnHostsVerticalBarCard.propTypes = {
  hostVulnerabilities: PropTypes.object.isRequired
}


export default MostVulnHostsVerticalBarCard;
