import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export var useD3 = (renderChartFn, dependencies) => {
    var ref = useRef();
    useEffect(() => {
        renderChartFn(d3.select(ref.current));
        return () => {};
    }, dependencies);

    return ref;
}