const countries = require('country-data').countries;

export const getCountryNameOrCode = (countryCode) => countries[countryCode]?.name || countryCode;

export const getCountryNameOrCodeShort = (countryCode) => {
  let countryName = getCountryNameOrCode(countryCode);
  if (countryCode === countryName) {
    return countryCode;
  }

  if (countryName.includes(',')) {
    if (countryName.split(',')[0].toLowerCase() === 'korea') {
      countryName = countryName.includes("Democratic People's Republic Of") ? 'North Korea' : 'South Korea';
    } else {
      countryName = countryName.split(',')[0];
    }
  }

  return countryName;
}

export const getCountryCode = (countryName) => {
  let first_ret = countries.all.find(country => countryName === country.name)?.alpha2
  if (first_ret) return first_ret
  let ret = countryName ?
    countries.all.find(country => countryName === country.alpha2 ||
      countryName === country.alpha3 ||
      country.name.includes(countryName) ||
      countryName === country.ioc ||
      countryName.includes(country.name))?.alpha2 : "";
  return ret ? ret : ""
}