import React, {useState} from "react";
import PropTypes from "prop-types";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import AddResourceMonitorForm from "./AddResourceMonitorForm";
import {getPattern} from "../../../../shared/helpers/validators";
import {useCurrentUserQuery} from "../../../../queries/CurrentUser";
import {hasUserEditorPermissionOnFeature} from "../../../../shared/helpers/permissions";
import {PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS} from "../../../../shared/helpers/features";
import getIntelligenceBlock from "../../profile_utils";
import ProfilePreferencesMessage from "../../../../shared/components/ProfilePreferencesMessage";

const ResourceMonitorForm = ({
                               project,
                               resource_monitors,
                               resourceMonitorTypes,
                               organization
                             }) => {
  const [activeTab, setActiveTab] = useState(0);
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const {
    block: intelligenceBlock,
    unblockDate: unblockDate
  } = getIntelligenceBlock(organization, 'last_project_resource_monitor_update_dt')
  const [resourceMonitors, setResourceMonitors] = useState(resourceMonitorTypes.map((type) => (resource_monitors.filter((rm) => new RegExp(getPattern(type)).test(rm.value)))))
  const previousResourceMonitors = resourceMonitorTypes.map((type) => (resource_monitors.filter((rm) => new RegExp(getPattern(type)).test(rm.value))))
  const [formattedModalMessages, setFormattedModalMessages] = useState([])
  const [submitAll, setSubmitAll] = useState({toSubmit: [], toSubmitType: []})
  const [onlyAddition, setOnlyAddition] = useState(false)
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const getChanges = (mode, idx, type) => {

    let result = []
    resourceMonitorTypes.map((resourceType, index) => {
      let toBeAdded = []
      let toBeDeleted = []
      const typeResult = {}
      typeResult.type = resourceType
      const getDifference = (array1, array2) => {
        return array1.filter(object1 => {
          return !array2.some(object2 => {
            return object1.value === object2.value;
          });
        });
      }
      const difference = getDifference(resourceMonitors[index], previousResourceMonitors[index])
      const reverseDiff = getDifference(previousResourceMonitors[index], resourceMonitors[index])
      const regexErrors = difference.filter((resource) => {
        const passesRegex = new RegExp(getPattern(resourceType)).test(resource.value)
        if (!passesRegex) return resource
      })
      let toBeEdited = difference.filter((resource) => resource.uid && !resource?.isNew)
      if (mode !== 'deletion') {
        toBeAdded = difference.filter((resource) => !resource?.uid && resource.isNew)
      } else {
        if (type === resourceType && previousResourceMonitors[index][idx]) {
          if (toBeEdited.length) toBeDeleted = [previousResourceMonitors[index][idx]]
          else toBeDeleted = [resourceMonitors[index][idx]]
        }
        toBeEdited = toBeEdited.filter((resource) => {
          reverseDiff.filter((res) => !resource.value !== res.value && resource.uid === res.uid)
        })
      }
      typeResult.result = {
        toBeAdded: toBeAdded,
        toBeEdited: toBeEdited,
        toBeDeleted: toBeDeleted,
        regexErrors: regexErrors
      }
      result.push(typeResult)
    })
    return result
  }

  const getFormattedModalText = (mode, deletionUid, type) => {
    const changes = getChanges(mode, deletionUid, type)
    let messages = []
    let onlyAdditions = []
    resourceMonitorTypes.map((resourceType, index) => {
      const toBeAdded = changes[index].result.toBeAdded.map(a => a?.value)
      const toBeDeleted = changes[index].result.toBeDeleted.map(a => a?.value)
      const toBeEdited = changes[index].result.toBeEdited.map(a => a?.value)
      const regexErrors = changes[index].result.regexErrors.map(a => a?.value)
      const forDeletion = toBeDeleted.filter(function (val) {
        return val !== undefined && !regexErrors.includes(val);
      });
      const forAddition = toBeAdded.filter(function (val) {
        return val !== undefined && !regexErrors.includes(val);
      });
      const forEdition = toBeEdited.filter(function (val) {
        return val !== undefined && !regexErrors.includes(val);
      });
      let message = ProfilePreferencesMessage({
        preferences: {
          forAddition: forAddition,
          forEdition: forEdition,
          forDeletion: forDeletion,
          regexErrors: regexErrors,
        }, mode: mode,
        preferencesType: changes[index].type
      })
      const deletion = !!message.preferences.forDeletion.length
      const addition = !!message.preferences.forAddition.length
      const edition = !!message.preferences.forEdition.length
      const regexErr = !!message.preferences.regexErrors.length
      if (!addition && !edition && !deletion && !regexErr) message = null
      if (message){
        if ((addition || regexErr) && !deletion && !edition) {
          onlyAdditions.push(true)
        } else onlyAdditions.push(false)
        messages[index] = message
      }

    })
    if (onlyAdditions.every(v => v === true)) setOnlyAddition(true)
    else setOnlyAddition(false)
    return messages
  }

  const onChangeHandler = (change, type) => {
    const formIndex = change.target.name.split('.')[1]
    const newResource = {
      value: change.target.value,
      isNew: true
    }
    resourceMonitorTypes.map((resourceType, index) => {

      if (resourceType === type) {

        if (resourceMonitors[index].length) {
          if (resourceMonitors[index][formIndex]?.uid) {
            newResource.isNew = false
            newResource.uid = resourceMonitors[index][formIndex].uid
          }
          resourceMonitors[index][formIndex] = newResource
        } else resourceMonitors[index].push(newResource)

      }
      setFormattedModalMessages(getFormattedModalText())

    })
  }
  const onSubmitHandler = () => {
    resourceMonitorTypes.map((resourceType, index) => {
      if (resourceMonitors[index].length) {
        setFormattedModalMessages(getFormattedModalText())
      }
    })

  }
  const onDeleteHandler = (idx, type, onlyMessage) => {
    if (!onlyMessage) {
      resourceMonitorTypes.map((resourceType, index) => {
          if (resourceType === type && resourceMonitors[index].length) {
            const tmpResources = resourceMonitors[index]
            tmpResources.splice(idx, 1)
            resourceMonitors[index] = tmpResources
            setResourceMonitors(resourceMonitors)
          }
        }
      )
    }
    setFormattedModalMessages(getFormattedModalText('deletion', idx, type))
  }
  const onSubmitAll = (allResources, index) => {
    const typesToUpdate = []
    resourceMonitorTypes.map((type, idx) => {
      resourceMonitors[idx].map((res) => {
        allResources.map((res2) => {
          if (res.value === res2.value) {
            res.isNew = false
            res.uid = res2.uid
          }
        })
      })
      if (idx !== index) {
        typesToUpdate.push(type)
      }
      setSubmitAll({toSubmit: allResources, toSubmitType: typesToUpdate})
    })
  }

  return (
    <div className="tabs tabs--bordered-bottom">
      <div className="tabs__wrap">
        <Nav tabs>
          {
            resourceMonitorTypes.map((type, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classnames({active: activeTab === index})}
                  onClick={() => {
                    toggleTab(index)

                  }}
                >{type.toUpperCase()}</NavLink>
              </NavItem>
            ))
          }
        </Nav>
        <TabContent activeTab={activeTab}>
          {
            resourceMonitorTypes.map((type, index) =>
              <TabPane tabId={index} key={index} onChange={(a) => onChangeHandler(a, type)}>
                <AddResourceMonitorForm
                  key={index}
                  project={project}
                  resource_monitors={resourceMonitors[index].filter((rm) => new RegExp(getPattern(type)).test(rm.value))}
                  all_resources={resourceMonitors}
                  organization={organization}
                  resourceMonitorType={type}
                  allResourceMonitorsTypes={resourceMonitorTypes}
                  messages={formattedModalMessages}
                  buttonsDisabled={!hasUserEditorPermissionOnFeature(
                    dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS
                  )}
                  deleteHandler={(index, type, onlyMessage) => onDeleteHandler(index, type, onlyMessage)}
                  submitHandler={() => onSubmitHandler()}
                  intelligenceBlock={intelligenceBlock}
                  unblockDate={unblockDate}
                  submit={(allResources) => onSubmitAll(allResources, index)}
                  submitAll={submitAll}
                  onlyAddition={onlyAddition}
                />
              </TabPane>
            )
          }
        </TabContent>
      </div>
    </div>
  )
}


ResourceMonitorForm.propTypes = {
  project: PropTypes.object.isRequired,
  resource_monitors: PropTypes.array.isRequired,
  resourceMonitorTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  organization: PropTypes.object.isRequired
}


export default ResourceMonitorForm;
