import React from "react";
import {Badge, Card, CardBody, Col, Row} from "reactstrap";
import { useDarknetProjectResultsCount } from '../../../../queries/IdentityMonitorResults';
import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
import DarksearchTabs from "../../../../shared/components/darkweb/DarksearchTabs";
import PropTypes from "prop-types";
import { darkNetTypes } from '../../../../shared/helpers/intelligence';


const Darknet = ({project_uid, project_name}) => {
  const {
    data: botsCount,
    isIdle: botsCountIsIdle,
    isLoading: botsCountIsLoading,
    isFetching: botsCountIsFetching,
    isError: botsCountIsError
  } = useDarknetProjectResultsCount(project_uid, darkNetTypes.bots)
  const {
    data: sellersCount,
    isIdle: sellersCountIsIdle,
    isLoading: sellersCountIsLoading,
    isFetching: sellersCountIsFetching,
    isError: sellersCountIsError
  } = useDarknetProjectResultsCount(project_uid, darkNetTypes.sellers)
  const {
    data: threadsCount,
    isIdle: threadsCountIsIdle,
    isLoading: threadsCountIsLoading,
    isFetching: threadsCountIsFetching,
    isError: threadsCountIsError
  } = useDarknetProjectResultsCount(project_uid, darkNetTypes.threads)

  if (botsCountIsIdle || sellersCountIsIdle || threadsCountIsIdle || botsCountIsLoading || sellersCountIsLoading || threadsCountIsLoading || botsCountIsFetching || sellersCountIsFetching || threadsCountIsFetching) {
    return <LoadingSpinner/>
  }

  if (botsCountIsError || sellersCountIsError || threadsCountIsError) {
    return <Card><CardBody><h4>Unable to load the content</h4></CardBody></Card>;
  }
  const bots = botsCount?.data ? botsCount.data.count : 0
  const sellers = sellersCount?.data ? sellersCount.data.count : 0
  const threads = threadsCount?.data ? threadsCount.data.count : 0

  const totalLength = bots + sellers + threads

  if (totalLength === 0) {
    return (
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Badge color={'success'} className={'w-100 outline badge-lg'}>NO FINDING IN DARK WEB FORUMS FOUND</Badge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <Badge color={'danger'} className={'w-100 outline badge-lg'}>
              {
                totalLength === 1 ? `THERE IS ${totalLength} FINDING IN THE DARK WEB FORUMS` :
                  `THERE ARE ${totalLength} FINDINGS IN THE DARK WEB FORUMS`
              }
            </Badge>
          </Col>
        </Row>

        <Row className={'mt-3'}>
          <Col>
            <DarksearchTabs
              project_uid={project_uid}
              bots={bots}
              threads={threads}
              sellers={sellers}
              isIdentityIntelligence
              project_name={project_name}
            />
          </Col>
        </Row>

      </CardBody>
    </Card>
  )
}


Darknet.propTypes = {
  project_uid: PropTypes.string.isRequired,
  project_name: PropTypes.string.isRequired
}


export default Darknet;
