import React from 'react';
import paths from "../../../../config/paths";
import {Switch} from "react-router-dom";
import PrivateRoute from "../../../../shared/components/router/PrivateRoute";
import NotFound404 from "../../../DefaultPage/404";
import AnalysisAdd from "../../../ContentsAdd/Analysis";
import PostAdd from "../../../ContentsAdd/Post";
import AnalysisEdit from "../../../ContentsEdit/Analysis";
import PostEdit from "../../../ContentsEdit/Post";
import ApplicationAdd from "../../../ApplicationAdd";
import ApplicationsList from "../../../Applications";
import CollectionAdd from "../../../ContentsAdd/Collection";
import GeneAnalysis from "../../../GeneAnalysis";
import OrganizationsList from "../../../Organizations";
import OrganizationAdd from "../../../OrganizationAdd";
import Notification from "../../../Notification";
import ResourceMonitor from "../../../ResourceMonitor";
import Tickets from "../../../Tickets";
import TicketDetail from '../../../TicketDetail';
import IntelligenceIdentity from "../../../IntelligenceIdentity";
import IntelligenceSearch from "../../../IntelligenceSearch";
import ActorAdd from '../../../ActorAdd';
import ActorEdit from '../../../ActorEdit';
import TakeDownService from '../../../TakeDownService';
import TakeDownServiceDetail from '../../../TakeDownServiceDetail';
import Dashboard from "../../../Dashboards";
import Contents from "../../../Contents";
import ActorsList from "../../../Actors";
import UserAdd from "../../../UsersAdd";
import UsersList from "../../../Users";
import MalwareFamilyDetail from "../../../MalwareFamilyDetail";
import AnalysisDetail from "../../../AnalysisDetail";
import PostDetail from "../../../PostDetail";
import InvestigateDetail from "../../../InvestigateDetail";
import SandboxTaskDetails from "../../../SandboxTaskDetails";
import SandboxTasks from "../../../Sandbox";
import ActorDetail from "../../../ActorDetail";
import RetroHuntTasks from "../../../RetroHunt";
import Profile from "../../../Profile";
import ResetPasswordRedirectPage from "../../../DefaultPage/Redirect/ResetPassword";
import FlashAlertAdd from '../../../ContentsAdd/FlashAlert';
import FlashAlertEdit from '../../../ContentsEdit/FlashAlert';
import FlashAlertDetail from '../../../FlashAlertDetail';
import MalwareFamilyAdd from "../../../MalwareFamilyAdd";
import MalwareFamilyEdit from "../../../MalwareFamilyEdit";
import RansomwareTrackerEventList from "../../../RansomwareTrackerEvents";
import MalwareFamilies from "../../../MalwareFamilies";
import FeedlyFeedsList from "../../../FeedlyFeeds";
import FeedlyFeedDetail from "../../../FeedlyFeedDetail";
import UserEdit from '../../../UsersEdit';
import OrganizationEdit from '../../../OrganizationEdit';
import ApplicationEdit from '../../../ApplicationEdit';
import Activities from "../../../Activities";
import ActivityDetail from "../../../ActivityDetail";
import Commands from "../../../Commands";
import Rules from "../../../Rules";
import TypoSquatting from "../../../TypoSquatting";
import IndicatorTable from "../../../Indicators";
import RulesSection from "../../../RulesSection";
import ActivityAdd from "../../../ActivitiesAdd/Activity";

export default () => {
  return (
    <div>
      <Switch>
        <PrivateRoute
          exact
          path="/"
          component={Dashboard}
        />

        <PrivateRoute
          path={paths.addActivitiesPath}
          component={ActivityAdd}
        />

        <PrivateRoute
          path={paths.activitiesDetailPath}
          component={ActivityDetail}
        />

        <PrivateRoute
          path={paths.addFlashAlertPath}
          component={FlashAlertAdd}
        />

        <PrivateRoute
          path={paths.editFlashAlertPath}
          component={FlashAlertEdit}
        />

        <PrivateRoute
          path={paths.flashAlertDetailPath}
          component={FlashAlertDetail}
        />

        <PrivateRoute
          path={paths.takeDownServiceDetailPath}
          component={TakeDownServiceDetail}
        />

        <PrivateRoute
          exact
          path={paths.takeDownServicePath}
          component={TakeDownService}
        />

        <PrivateRoute
          exact
          path={paths.ticketsPath}
          component={Tickets}
        />

        <PrivateRoute
          path={paths.ticketDetailPath}
          component={TicketDetail}
        />

        <PrivateRoute
          path={paths.addPostPath}
          component={PostAdd}
        />

        <PrivateRoute
          path={paths.addAnalysisPath}
          component={AnalysisAdd}
        />

        <PrivateRoute
          path={paths.editPostPath}
          component={PostEdit}
        />

        <PrivateRoute
          path={paths.editAnalysisPath}
          component={AnalysisEdit}
        />

        <PrivateRoute
          path={paths.addCollectionPath}
          component={CollectionAdd}
        />

        <PrivateRoute
          path={paths.actorAddPath}
          component={ActorAdd}
        />

        <PrivateRoute
          path={paths.editActorPath}
          component={ActorEdit}
        />

        <PrivateRoute
          path={paths.addMalwarePath}
          component={MalwareFamilyAdd}
        />

        <PrivateRoute
          path={paths.editMalwarePath}
          component={MalwareFamilyEdit}
        />

        <PrivateRoute
          path={paths.intelligenceSearchPath}
          component={IntelligenceSearch}
        />

        <PrivateRoute
          path={paths.resourceMonitorPath}
          component={ResourceMonitor}
        />

        <PrivateRoute
          path={paths.typoSquattingPath}
          component={TypoSquatting}
        />

        <PrivateRoute
          path={paths.intelligenceIdentityPath}
          component={IntelligenceIdentity}
        />

        <PrivateRoute
          path={paths.addOrganizationPath}
          component={OrganizationAdd}
        />

        <PrivateRoute
          path={paths.editOrganizationPath}
          component={OrganizationEdit}
        />

        <PrivateRoute
          path={paths.listOrganizationPath}
          component={OrganizationsList}
        />

        <PrivateRoute
          path={paths.geneAnalysisPath}
          component={GeneAnalysis}
        />

        <PrivateRoute
          exact path={paths.contentsPath}
          component={Contents}
        />

        <PrivateRoute
          exact
          path={paths.activitiesPath}
          component={Activities}
        />

        <PrivateRoute
          exact
          path={paths.actorPath}
          component={ActorsList}
        />

        <PrivateRoute
          path={paths.addUserPath}
          component={UserAdd}
        />

        <PrivateRoute
          path={paths.editUserPath}
          component={UserEdit}
        />

        <PrivateRoute
          path={paths.listUserPath}
          component={UsersList}
        />

        <PrivateRoute
          path={paths.addApplicationPath}
          component={ApplicationAdd}
        />

        <PrivateRoute
          path={paths.editApplicationPath}
          component={ApplicationEdit}
        />

        <PrivateRoute
          path={paths.listApplicationPath}
          component={ApplicationsList}
        />

        <PrivateRoute
          exact
          path={paths.malwareFamilyBasePath}
          component={MalwareFamilies}
        />

        <PrivateRoute
          path={paths.malwareFamilyPath}
          component={MalwareFamilyDetail}
        />

        <PrivateRoute
          path={paths.analysisDetailPath}
          exact
          component={AnalysisDetail}
        />

        <PrivateRoute
          path={paths.postDetailPath}
          exact
          component={PostDetail}
        />

        <PrivateRoute
          path={paths.investigateDetailPath}
          component={InvestigateDetail}
        />

        <PrivateRoute
          path={paths.sandboxTasksDetailPath}
          component={SandboxTaskDetails}
        />

        <PrivateRoute
          exact
          path={paths.sandboxTasksPath}
          component={SandboxTasks}
        />

        <PrivateRoute
          path={paths.actorDetailPath}
          exact
          component={ActorDetail}
        />

        <PrivateRoute
          path={paths.retroHuntTasks}
          component={RetroHuntTasks}
        />

        <PrivateRoute
          path={paths.notificationPath}
          component={Notification}
        />

        <PrivateRoute
          path={paths.profilePath}
          component={Profile}
        />

        <PrivateRoute
          path={paths.redirectLogoutPath}
          component={ResetPasswordRedirectPage}
        />

        <PrivateRoute
          exact
          path={paths.ransomwareTrackerEventsPath}
          component={RansomwareTrackerEventList}
        />

        <PrivateRoute
          exact path={paths.feedlyFeedsPath}
          component={FeedlyFeedsList}
        />

        <PrivateRoute
          path={paths.feedlyFeedDetailPath}
          component={FeedlyFeedDetail}
        />

        <PrivateRoute
          path={paths.commandsPath}
          component={Commands}
        />

        <PrivateRoute
          path={paths.rulesSectionPath}
          component={RulesSection}
        />

        <PrivateRoute
          exact
          path={paths.indicatorPath}
          component={IndicatorTable}
        />

        <PrivateRoute
          path={paths.rulesPath}
          component={Rules}
        />

        <PrivateRoute
          path="*"
          component={NotFound404}
        />
      </Switch>
    </div>
  )
};
