import PropTypes from "prop-types";
import {Button} from "reactstrap";
import ThemeModal from "../../../shared/components/ThemeModal";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {useTypoSquattingScreenshotQuery} from "../../../queries/TypoSquattingResultScreenshot";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorHandler from "../../../shared/components/ErrorHandler";


const TypoSquattingScreenShotModal = ({result_id, domain_name, protocol}) => {
  const [showModal, setShowModal] = useState(false);
  const [base64_screenshot, setBase64_screenshot] = useState();
  const toggleModal = useCallback(() => setShowModal(!showModal), [setShowModal, showModal]);

  const {
    data,
    isLoading,
    isIdle,
    isError,
    error
  } = useTypoSquattingScreenshotQuery(result_id, protocol, showModal);

  useEffect(() => {
    if (!isLoading && !isIdle && !isError && data?.data) {
      if (data.data.length > 0) {
        setBase64_screenshot('data:image/png;base64, ' + data.data[0].screenshot_b64);
      }
    }
  }, [isLoading, isIdle, isError, data]);

  return (
    <Fragment>
      <Button
        className={`rounded mb-0 float-right mx-2`}
        outline size="sm"
        onClick={toggleModal}
      >
        {protocol.toUpperCase()} SCREENSHOT
      </Button>

      <ThemeModal
        isOpen={showModal}
        toggle={toggleModal}
        modalClassName={'ltr-support'}
        className={'modal-dialog--header-xl modal-dialog--dark'}
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggleModal}/>
          <h4 className="text-modal modal__title">Screenshot of <h3 className={'d-inline'}>{domain_name}</h3> {protocol} website</h4>
        </div>

        <div className="modal__body">
          {
            (isLoading || isIdle) ? <LoadingSpinner /> :
              isError ? <ErrorHandler error={error} /> :
                base64_screenshot ? <img src={base64_screenshot} alt={`${domain_name} ${protocol} screenshot`} /> :
                  <h4>Screenshot for {domain_name} is not available</h4>
          }
        </div>

      </ThemeModal>
    </Fragment>
  )
}


TypoSquattingScreenShotModal.propTypes = {
  result_id: PropTypes.string.isRequired,
  protocol: PropTypes.string.isRequired,
  domain_name: PropTypes.string.isRequired
}

export default TypoSquattingScreenShotModal;
