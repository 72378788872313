import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
import GeneAnalysisResultsReport from "./components/GeneAnalysisResultsReport";
import GenePercentagesPieChart from "./components/GenePercentagesPieChart";
import CollapsibleGeneDetail from "./components/CollapsibleGeneDetail";
import GeneAnalysisUploadForm from "./components/GeneAnalysisUploadForm";
import {useForm} from "react-hook-form";
import {useAddGeneAnalysisMutation} from "../../mutations/AddGeneAnalysis";
import toast from "react-hot-toast";
import {getErrorMessageFromResponse} from "../../shared/helpers";
import {useCurrentUserQuery} from "../../queries/CurrentUser";
import {hasUserEditorPermissionOnFeature} from "../../shared/helpers/permissions";
import {PLATFORM_FEATURE_GENES_ANALYSIS} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";

const _ = require('lodash');

const mapData = (geneAnalysisResultData) => {
  if (!geneAnalysisResultData || !geneAnalysisResultData.data || !geneAnalysisResultData.data.result) {
    return [];
  }

  let total_genes = 0;
  let mappedData = [];
  geneAnalysisResultData.data.result.forEach(x => {
    total_genes += x.total_count;
    mappedData.push({
      family: x.family,
      code_genes: x.total_count,
      gene_details: (x.genes ? x.genes.map((g) => g.type + ' [' + g.count + ']').join(', ') : null),
      genes_with_count: (x.genes ? x.genes.map((g) => ({name: g.type, value: g.count})) : [])
    })
  });

  mappedData.forEach(x => {
    x.percentage = (x.code_genes / total_genes).toFixed(4);
    x.total_count = total_genes;
  });

  return _.orderBy(mappedData, ['percentage'], ['desc']);
}

const GeneAnalysis = () => {
  const [geneAnalysisData, setGeneAnalysisData] = useState(null);
  const [enableButton, setEnableButton] = useState(false);
  const {control, handleSubmit, watch} = useForm();
  const mutation = useAddGeneAnalysisMutation();
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const mappedData = useMemo(() => mapData(geneAnalysisData), [geneAnalysisData]);

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        setGeneAnalysisData(mutation.data);
        setEnableButton(false);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])

  useEffect(() => {
    if (watch('sample') && watch('sample').length > 0) {
      setEnableButton(true);
    } else {
      setGeneAnalysisData(null);
    }
  }, [watch('sample')])

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('file', data.sample[0].file, data.sample[0].filename);
    mutation.mutate(formData)
    toast.loading("Uploading sample, this could take a couple of minutes...");
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_GENES_ANALYSIS}
      onlyOnline
    >
      <Container className="tasks-container">
        <Row className={'div__sticky-top'}>
          <Col>
            <h3 className="page-title">Genes Analysis</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              disabled={!enableButton || !hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_GENES_ANALYSIS)}
              className="rounded float-right" outline size={'sm'}
              onClick={handleSubmit(onSubmit)}><span className="lnr lnr-upload"/> Upload Sample</Button>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <GeneAnalysisUploadForm control={control}/>
          </Col>
        </Row>

        <Row className="dashboard">
          <Col lg={9} xl={9} md={12} xs={12}>
            {geneAnalysisData && <GeneAnalysisResultsReport geneAnalysisResultData={geneAnalysisData}/>}
          </Col>
          <Col lg={3} xl={3} md={12} xs={12}>
            {geneAnalysisData && <GenePercentagesPieChart geneAnalysisResultData={mappedData}/>}
          </Col>
        </Row>

        {
          mappedData.length > 0 &&
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Fragment>
                    {mappedData?.map((data) => (
                      <CollapsibleGeneDetail geneData={data}/>
                    ))}
                  </Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </Page>
  );
}

export default GeneAnalysis;