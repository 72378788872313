import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useAddTicketMessageMutation} from '../../../mutations/Tickets/AddMessage';
import toast from 'react-hot-toast';
import {getErrorMessageFromResponse} from '../../../shared/helpers';
import axios from 'axios';
import {apiUrlInternal} from '../../../config/api';
import {addAuthToAxiosOpts} from '../../../config/queryopts';
import {useCookieAccessToken} from '../../../queries';
import {Container} from 'reactstrap';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import ErrorHandler from '../../../shared/components/ErrorHandler';
import ChatInfo from '../../../shared/components/chat/ChatInfo';
import {useGetTicketMessages} from '../../../queries/Tickets';
import {useForm} from "react-hook-form";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import {hasUserEditorPermissionOnFeature} from "../../../shared/helpers/permissions";
import {PLATFORM_FEATURE_TICKETS_ANALYSIS} from "../../../shared/helpers/features";


const ChatData = ({ticket}) => {
  const [currentMessages, setCurrentMessages] = useState([]);
  const {data, isIdle, isLoading, error} = useGetTicketMessages(ticket.uid);
  const {data: dataCurrentUser} = useCurrentUserQuery();
  const mutation = useAddTicketMessageMutation();
  const defaultValues = {};
  const {register, handleSubmit, control, setValue} = useForm({
    defaultValues: defaultValues
  });
  const {
    data: accessToken,
    isError: tokenIsError,
    isLoading: tokenIsLoading,
    isIdle: tokenIsIdle
  } = useCookieAccessToken();
  const downloadPathApi = `${apiUrlInternal}/tickets/attachment/`;

  const onSubmit = (data) => {
    if (data.report && data.report.length > 0) {
      const formData = new FormData();
      formData.append('file', data.report[0].file, data.report[0].filename);
      toast.loading('Validating and uploading file.');
      axios.post(`${apiUrlInternal}/tickets/attachment`, formData,
        addAuthToAxiosOpts(accessToken, {'Content-Type': 'multipart/form-data'}, 0),
      ).then((res) => {

        toast.dismiss();
        if (res.status === 200) {
          toast.loading('Sending message');
          mutation.mutate({
            uid: ticket.uid,
            attachment_uid: res.data.data.uid,
            content: data.content,
          });
        } else {
          toast.error(`Error while uploading the attachment. Please contact the administrator.`);
        }

      }).catch(() => {
        toast.dismiss();
        toast.error(`Error while uploading the attachment. Please contact the administrator.`);
      })
    } else {
      toast.loading('Sending message');
      mutation.mutate({
        uid: ticket.uid,
        attachment_uid: null,
        content: data.content,
      });
    }
  }

  useEffect(() => {
    if (!isIdle && !isLoading && !error) {
      setCurrentMessages(data.data);
    }
  }, [isIdle, isLoading, error, data])


  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        toast.success(`Message sent`);
        setValue('content', '');
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.error, mutation.isSuccess])


  if (tokenIsIdle || tokenIsLoading) {
    return <Container><LoadingSpinner/></Container>
  }

  if (tokenIsError) {
    return <ErrorHandler error={error}/>
  }

  return (
    <div className='chart_data'>
      <ChatInfo
        messages={currentMessages}
        onSent={handleSubmit(onSubmit)}
        apiPath={downloadPathApi}
        register={register}
        control={control}
        canSendMessage={ticket.status !== 'closed' && hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_TICKETS_ANALYSIS)}
      />
    </div>
  );
};


ChatData.propTypes = {
  ticket: PropTypes.shape().isRequired
}

export default ChatData;
