import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import IndicatorRiskScoreGauge from '../../IndicatorRiskScoreGauge';
import RelatedActorsCard from '../../RelatedActorsCard';
import IndicatorUrlCard from './components/IndicatorUrlCard';
import PropTypes from 'prop-types';
import ContentTimeLineCard from '../../ContentTimeLineCard';
import IndicatorDetailFooter from '../../IndicatorDetailFooter';
import CommunicatingFilesCard from '../../CommunicatingFilesCard';
import ThreatStatsPieChart from '../../ThreatStatsPieChart';
import FirstLastSeenRow from '../../FirstLastSeenRow';

const UrlDetails = ({ indicator_details }) => {
  return (
    <Fragment>
      <Row>
        <Col lg={5} md={12}>
          <Row>
            <Col>
              <IndicatorRiskScoreGauge score={!indicator_details.whitelisted ? indicator_details.score : 0}
                                       tags={indicator_details.tags} whitelisted={indicator_details.whitelisted}/>
              <FirstLastSeenRow last_seen={indicator_details.last_seen} first_seen={indicator_details.first_seen}/>
              <RelatedActorsCard actors={indicator_details.actors} isKnown={indicator_details.is_known}/>
              {indicator_details.stats && <ThreatStatsPieChart stats={indicator_details.stats}/>}
            </Col>
          </Row>
        </Col>

        <Col lg={7} md={12}>
          <Row>
            <Col>
              <IndicatorUrlCard indicator={indicator_details.indicator}/>
              {indicator_details.related_contexts.length > 0 &&
                <ContentTimeLineCard contexts={indicator_details.related_contexts}/>}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <CommunicatingFilesCard indicator={indicator_details.indicator}/>
        </Col>
      </Row>

      <IndicatorDetailFooter indicator_details={indicator_details}/>
    </Fragment>
  );
};

UrlDetails.propTypes = {
  indicator_details: PropTypes.object.isRequired,
};

export default UrlDetails;
