import IntelxStatsTabsResult from "../../../shared/components/tab/IntelxStatsTabsResult";
import React from "react";
import PropTypes from "prop-types";
import {useIntelxSearchStatsQuery} from "../../../queries/Intelx";
import {Card, CardBody, Col, Row} from "reactstrap";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";


const IndicatorIntelxCard = ({indicator}) => {
  const {
    data: data, isIdle: isIdle, isLoading: isLoading, isError: isError
  } = useIntelxSearchStatsQuery(indicator);

  const stats = data?.data;

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className={'bold-text d-inline'}>intelligence related files</h4>
          <p className={'subhead'}>This section shows the files which include <b>{indicator}</b> in their content</p>
        </div>

        <Row className={'mt-3'}>
          <Col>
            {
              isLoading || isIdle ? <LoadingSpinner /> :
                isError ? <h4>Unable to load the content</h4> :
                  <IntelxStatsTabsResult
                    stats={stats}
                    keyword={indicator}
                  />
            }
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}


IndicatorIntelxCard.propTypes = {
  indicator: PropTypes.string.isRequired
}


export default IndicatorIntelxCard;
