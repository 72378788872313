import ControlledSelectWithTitle from "../ControlledSelectWithTitle";
import ControlledCreatableSelectWithTitle from "../ControlledCreatableSelectWithTitle";
import React from "react";
import env_const from "../../../../config/env_const";
import PropTypes from "prop-types";


export const TagControlledSelect = ({commonTagOptions, control, name, isClearable}) => {
  if (env_const.is_clone) {
    return (
      <ControlledSelectWithTitle
        name={name} title={'Tags'} control={control}
        isClearable={isClearable}
        isSearchable
        isMulti
        valueFn={(value) => value}
        options={commonTagOptions}
      />
    )
  }
  else {
    return (
      <ControlledCreatableSelectWithTitle
        name={name} title={'Tags'} control={control}
        isSearchable
        isMulti
        isClearable={isClearable}
        valueFn={(value) => value}
        options={commonTagOptions}
      />
    )
  }
}


TagControlledSelect.propTypes = {
  commonTagOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
}


export default TagControlledSelect;
