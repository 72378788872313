import React, {useEffect, useState} from "react"
import {Col, Container, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import paths from "../../config/paths";
import LegendItem from "./components/LegendItem";
import RuleItem from "./components/RuleItem";
import WindowedInfiniteLoader from "../../shared/components/infinite-loader/WindowedInfiniteLoader";
import ErrorHandler from "../../shared/components/ErrorHandler";
import {useRulesQuery} from "../../queries/Rules";
import FakeItem from "../Indicators/components/FakeItem";
import {paramsToObject, useQueryParams} from "../../shared/components/router/QueryNavigationHelpers";
import Page from "../../shared/components/Page";
import {PLATFORM_FEATURE_CONTENTS} from "../../shared/helpers/features";


const _ = require("lodash");


const Rules = () => {
  const RulesLimit = 15;
  const FAKE_ITEMS = 10;
  const history = useHistory();
  const [rulesArray, setRulesArray] = useState([]);
  const [next, setNext] = useState(null);
  const [totalRules, setTotalRules] = useState(0);
  const params = paramsToObject(useQueryParams().entries());
  const initialTags = params.tags ? params.tags.split(',') : [];
  const [filters, setFilters] = useState({tags: initialTags});

  const {data, isIdle, isLoading, isError, error} = useRulesQuery(filters.tags, next, RulesLimit);

  useEffect(() => {
    if (!isLoading && !isIdle && !isError && data) {
      setTotalRules(data.metadata.total);

      if (!next) {
        if (data.metadata.total <= FAKE_ITEMS) {
          setRulesArray([{isFake: false}].concat(data.data));
        } else {
          setRulesArray([{isFake: false}].concat(data.data.concat(_.range(FAKE_ITEMS).map(() => ({isFake: true})))));
        }
      } else {
        setRulesArray(prevIndicatorArray =>
          !data.metadata.next ? _.filter(prevIndicatorArray.concat(data.data), (ioc) => !ioc.isFake) :
            _.filter(prevIndicatorArray, (ioc) => !ioc.isFake).concat(data.data).concat(_.range(FAKE_ITEMS).map(() => ({isFake: true})))
        );
      }

    }
  }, [isLoading, isIdle, isError, data]);

  useEffect(() => {
    if ((isIdle || isLoading) && !data) {
      setRulesArray([])
      setNext(null)
    }
  }, [filters])


  if (error) {
    return <ErrorHandler error={error}/>
  }

  const isItemLoaded = ({index}) => !rulesArray[index].isFake;
  const loadMoreItems = () => {
    if (data?.metadata?.next) {
      setNext(data.metadata.next);
    }
    return new Promise(() => {
    });
  };

  const RowItem = ({index, style}) => {
    style = {...style, ...{borderColor: '#333246', borderStyle: 'solid', borderWidth: '1px 0px 1px 0px', margin: '0px'}}
    const rule = rulesArray[index];

    const onRowClick = rule?.contexts?.length > 0  && ['analysis', 'post'].includes(rule.contexts[0].type) ? () => {
        history.push(
          `${
            rule.contexts[0].type === 'analysis' ? paths.analysisPath : paths.postPath
          }/${rule.contexts[0].uid}`
        )
    } : null;

    const onTagClick = (tag) => {
      history.replace(`${paths.rulesPath}?tags=${tag}`);
      setFilters(prevState => {
        const tags = [tag];
        return {tags};
      })
    }
    if (index === 0) {
      return <LegendItem style={style}/>
    }

    return !rule.isFake ? <RuleItem style={style} rule={rule} onTagClick={onTagClick} onRowClick={onRowClick}/> :
      <FakeItem style={style}/>
  }

  return (
    <Page
      feature={PLATFORM_FEATURE_CONTENTS}
    >
      <div className={'div__sticky-top'}>
      </div>
      <Row>
        <Col md={12}>
          <WindowedInfiniteLoader
            loadMoreItems={loadMoreItems}
            RowItem={RowItem}
            isItemLoaded={isItemLoaded}
            isLoading={(isIdle || isLoading) && rulesArray.length === 0}
            itemsArray={rulesArray}
            searchTerm={filters.search_term}
          />
        </Col>
      </Row>
    </Page>
  )
}

export default Rules;
