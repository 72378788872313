import {addAuthToFetchOpts} from '../../config/queryopts'
import {useQueryWithAuthentication} from "../index";
import {apiUrlInternal} from "../../config/api"

export const sandboxTasksFetcher = (accessToken, taskId=null) => {
  const uri = (taskId) ? `/${taskId}` : '';
  return fetch(`${apiUrlInternal}/sandbox/tasks${uri}`, addAuthToFetchOpts(accessToken, {}))
    .then(res => res.json());
}

export const useSandboxTasksFetcherQuery = (taskId = null, fetcher = sandboxTasksFetcher) => {
  return useQueryWithAuthentication(['sandbox-tasks', taskId], (accessToken) => fetcher(accessToken, taskId))
}
