import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import RegisteredInputField from "../RegisteredInputField";
import RegisteredCheckBoxField from "../RegisteredCheckBoxField";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useAddOrganizationMutation} from "../../../../mutations/AddOrganization";
import {useQueryClient} from "react-query";
import {useHistory} from "react-router-dom";
import toast from "react-hot-toast";
import paths from "../../../../config/paths";
import {getErrorMessageFromResponse} from "../../../helpers";
import Permissions from "../../Permissions";
import {CUSTOMER_ROLE} from "../../../helpers/user";
import {
  canRoleUpdateAllUsersFields,
  getFormattedFeaturesList,
  getUserFeature,
  isFeatureExistsForCurrentUser,
  isModuleExistsForCurrentUser,
  userRoleModuleEnabled
} from "../../../helpers/permissions";
import {useModulesListQuery} from "../../../../queries/ModuleList";
import DatePickerWithLabel from "../DatePickerWithLabel";
import moment from "moment/moment";
import {useAddOrganizationTaxiiAccountMutation} from "../../../../mutations/AddOrganizationTaxiiAccount";
import RegisteredPasswordField from "../RegisteredPasswordField";
import ConfirmModal from "../../modal/ConfirmModal";
import {useReportQuotaPurchaseMutation} from "../../../../mutations/Quotas/ReportQuotaPurchase";
import {useInvestigateQuotaPurchaseMutation} from "../../../../mutations/Quotas/InvestigateQuotaPurchase";
import ControlledSelectWithTitle from "../ControlledSelectWithTitle";
import env_const from "../../../../config/env_const";
import {APP_LOCAL_PROVIDER} from "../../../helpers/validators";
import {useOrganizationsQuery} from "../../../../queries/Organizations";
import LoadingSpinner from "../../LoadingSpinner";
import {useCurrentUserQuery} from "../../../../queries/CurrentUser";


const OrganizationForm = ({
  defaultValues = {
    max_resources_to_monitor: 10,
    max_project_resource_monitor: 5,
    max_project_keyword: 5,
    max_keywords_to_monitor: 1,
    max_intelligence_search: 5,
    has_custom_logo: false,
    takedown_ticket: 1,
    be_my_analyst_ticket: 1,
    org_type: '',
    active: true,
    managed_by: null
  },
  mutationFn = useAddOrganizationMutation,
  organizationUid,
  editOrganization = false
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    control
  } = useForm({defaultValues: defaultValues});
  const {
    register: taxiiAccountRegister,
    formState: {errors: taxiiAccountErrors},
    getValues: taxiiAccountGetValues
  } = useForm({defaultValues: {taxii_account_password: ''}});

  const [buttonUploadDisabled, setButtonUploadDisabled] = useState(false);
  const mutation = mutationFn();
  const addOrganizationTaxiiAccountMutation = useAddOrganizationTaxiiAccountMutation();
  const queryClient = useQueryClient();
  const reportQuotaPurchaseMutation = useReportQuotaPurchaseMutation();
  const investigateQuotaPurchaseMutation = useInvestigateQuotaPurchaseMutation();
  const [groups, setGroups] = useState([]);
  const history = useHistory();
  const [activationDate, setActivationDate] = useState(
    defaultValues.activation_dt && moment(defaultValues.activation_dt).toDate()
  );
  const {
    data: dataModules,
    isIdle: isIdleModules,
    isLoading: isLoadingModules,
    isError: isErrorModules
  } = useModulesListQuery();

  const {
    data: dataCurrentUser,
    isIdle: isIdleCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser
  } = useCurrentUserQuery();

  const {
    data: dataOrganization,
    isIdle: isIdleOrganization,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
    error
  } = useOrganizationsQuery(null, canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role));

  useEffect(() => {
    if (!mutation.isIdle && mutation.isLoading && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }

    if (!mutation.isIdle && !mutation.isLoading) {
      toast.dismiss();
      if (mutation.isSuccess) {
        toast.success(`Organization added correctly, you will be redirected in the organization pages.`);
        queryClient.invalidateQueries(['organizations', null]);
        setTimeout(() => {
          toast.dismiss();
          history.push(paths.listOrganizationPath);
        }, 2000);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}.`);
        setButtonUploadDisabled(false);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isLoading, mutation.isError, mutation.isSuccess, mutation.error])

  useEffect(() => {
    if (!isIdleModules && !isLoadingModules && !isErrorModules) {
      let cdata = dataModules.data && dataModules.data.map((i) => {
        i.selected = editOrganization ? isModuleExistsForCurrentUser(defaultValues.modules, i.name) : true;
        if (i.features) {
          i.features = i.features.map((f) => {
            if (editOrganization) {
              f.selected = isFeatureExistsForCurrentUser(defaultValues.modules, f.name);
              if (f.selected) {
                f.permission = getUserFeature(defaultValues.modules, f.name).permission;
              }
            } else {
              f.selected = userRoleModuleEnabled(CUSTOMER_ROLE, i.name, f.name);
              f.permission = userRoleModuleEnabled(CUSTOMER_ROLE, i.name, f.name);
            }
            return f;
          });
        }
        return i
      });
      setGroups(cdata);
    }
  }, [isIdleModules, isLoadingModules, isErrorModules]);

  useEffect(() => {
    if (((!reportQuotaPurchaseMutation.isIdle && reportQuotaPurchaseMutation.isLoading) ||
      (!investigateQuotaPurchaseMutation.isIdle && investigateQuotaPurchaseMutation.isLoading)) && !buttonUploadDisabled) {
      setButtonUploadDisabled(true);
    }

    if ((!reportQuotaPurchaseMutation.isIdle && !reportQuotaPurchaseMutation.isLoading) ||
      (!investigateQuotaPurchaseMutation.isIdle && !investigateQuotaPurchaseMutation.isLoading)) {
      toast.dismiss();
      if (reportQuotaPurchaseMutation.isSuccess || investigateQuotaPurchaseMutation.isSuccess) {
        toast.success(`Quotas added correctly`);
      } else if (reportQuotaPurchaseMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(reportQuotaPurchaseMutation)}.`);
      } else if (investigateQuotaPurchaseMutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(investigateQuotaPurchaseMutation)}.`);
      }
      setButtonUploadDisabled(false);
      reportQuotaPurchaseMutation.reset();
      investigateQuotaPurchaseMutation.reset();
    }
  }, [
    reportQuotaPurchaseMutation.isIdle, reportQuotaPurchaseMutation.isLoading, reportQuotaPurchaseMutation.isError,
    reportQuotaPurchaseMutation.isSuccess, reportQuotaPurchaseMutation.error, investigateQuotaPurchaseMutation.isIdle,
    investigateQuotaPurchaseMutation.isLoading, investigateQuotaPurchaseMutation.isError,
    investigateQuotaPurchaseMutation.isSuccess, investigateQuotaPurchaseMutation.error
  ])

  useEffect(() => {
      if (!addOrganizationTaxiiAccountMutation.isIdle) {
        if (addOrganizationTaxiiAccountMutation.isLoading) {
          toast.loading('Creating taxii account');
        } else {
          toast.dismiss();
          if (addOrganizationTaxiiAccountMutation.isSuccess) {
            toast.success('Taxii account created successfully');
          } else if (addOrganizationTaxiiAccountMutation.isError) {
            toast.error(getErrorMessageFromResponse(addOrganizationTaxiiAccountMutation));
            addOrganizationTaxiiAccountMutation.reset();
          }
        }
      }
    }, [addOrganizationTaxiiAccountMutation.isIdle, addOrganizationTaxiiAccountMutation.isError,
      addOrganizationTaxiiAccountMutation.error, addOrganizationTaxiiAccountMutation.isLoading,
      addOrganizationTaxiiAccountMutation.isSuccess]
  )

  const onSubmit = (data) => {
    const params = {
      name: data.name,
      max_resources_to_monitor: data.max_resources_to_monitor,
      max_project_resource_monitor: data.max_project_resource_monitor,
      max_keywords_to_monitor: data.max_keywords_to_monitor,
      max_project_keyword: data.max_project_keyword,
      max_intelligence_search: data.max_intelligence_search,
      has_custom_logo: data.has_custom_logo,
      activation_dt: activationDate,
      takedown_ticket: data.takedown_ticket,
      be_my_analyst_ticket: data.be_my_analyst_ticket,
      add_be_my_analyst_coins: data.add_be_my_analyst_coins,
      modules: getFormattedFeaturesList(groups),
      active: data.active,
      managed_by: data?.managed_by?.value ? data?.managed_by?.value : null
    }

    setButtonUploadDisabled(true);

    if (editOrganization) {
      toast.loading(`Validating and updating organization ${data.name}`);
      mutation.mutate({
        uid: organizationUid,
        params: params
      });
    } else {
      toast.loading(`Validating and creating organization ${data.name}`);
      mutation.mutate(params);
    }
  }

  const onInvestigateQuotaPurchaseConfirm = () => {
    investigateQuotaPurchaseMutation.mutate({
      organization_uid: organizationUid
    });
  };

  const onReportQuotaPurchaseConfirm = () => {
    reportQuotaPurchaseMutation.mutate({
      organization_uid: organizationUid
    });
  };

  const onAddTaxiiAccountConfirm = () => {
    addOrganizationTaxiiAccountMutation.mutate({
      organizationUid: organizationUid,
      password: taxiiAccountGetValues()['taxii_account_password']
    });
  };

  const updateSelection = (data) => {
    setGroups(() => data);
  }

  if (isLoadingOrganization || isIdleOrganization || isIdleCurrentUser || isLoadingCurrentUser) {
    return <LoadingSpinner />
  }

  const organizationOptions = (env_const.is_on_prem || env_const.react_app_auth_provider === APP_LOCAL_PROVIDER) ?
    [] : [{value: null, label: 'None'}].concat(dataOrganization.data.filter(
      (org) => org.org_type === 'mssp'
    ).map(
      (org) => ({value: org.uid, label: org.name})
    ));

  return (
    <Container>
      <Row className={'div__sticky-top'}>
        <Col md={9}>
          <h3 className="page-title">
            {editOrganization ? 'Edit' : 'New'} Organization
          </h3>
        </Col>
        <Col md={3} className={'justify-content-end d-flex'}>
          <Button outline onClick={() => {
            handleSubmit(onSubmit)();
          }} color={'success'} disabled={buttonUploadDisabled}>
            {editOrganization ? 'Edit' : 'Add'}
          </Button>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <form className="form form--vertical">
            <Row className={'w-100'}>
              <Col xs={12}><p className="form__form-group-label py-2"><b>Main info</b></p></Col>
              <Col>
                <RegisteredInputField
                  title={'Name'}
                  name={'name'}
                  register={register}
                  errors={errors}
                  rules={{required: 'The name is required'}}
                />
              </Col>
            </Row>
            <Row className={'w-100'}>
              <Col md={6}>
                <RegisteredInputField
                  title={'Max number of Attack Surface Intelligence Engine'}
                  name={'max_project_resource_monitor'}
                  register={register}
                  errors={errors}
                  rules={{
                    required: 'The max_project_resource_monitor is required',
                    validate: {
                      positive: v => parseInt(v) >= 0 || 'This value should be greater than 0',
                    },
                    valueAsNumber: true,
                  }}
                />
              </Col>
              <Col md={6}>
                <RegisteredInputField
                  title={'Max number of resources to monitor per engine'}
                  name={'max_resources_to_monitor'}
                  register={register}
                  errors={errors}
                  rules={{
                    required: 'The max_resources_to_monitor is required',
                    validate: {
                      positive: v => parseInt(v) >= 0 || 'This value should be greater than 0',
                    },
                    valueAsNumber: true,
                  }}
                />
              </Col>
            </Row>
            <Row className={'w-100'}>
              <Col md={6}>
                <RegisteredInputField
                  title={'Max number of Identity Intelligence Engine'}
                  name={'max_project_keyword'}
                  register={register}
                  errors={errors}
                  rules={{
                    required: 'The max_project_keyword is required',
                    validate: {
                      positive: v => parseInt(v) >= 0 || 'This value should be greater than 0',
                    },
                    valueAsNumber: true,
                  }}
                />
              </Col>
              <Col md={6}>
                <RegisteredInputField
                  title={'Max number of keywords to monitor per engine'}
                  name={'max_keywords_to_monitor'}
                  register={register}
                  errors={errors}
                  rules={{
                    required: 'The max_keywords_to_monitor is required',
                    validate: {
                      positive: v => parseInt(v) >= 0 || 'This value should be greater than 0',
                    },
                    valueAsNumber: true,
                  }}
                />
              </Col>
            </Row>
            <Row className={'w-100'}>
              <Col md={6}>
                <RegisteredInputField
                  title={'Max number of Intelligence Search for Year'}
                  name={'max_intelligence_search'}
                  register={register}
                  errors={errors}
                  rules={{
                    required: 'The max_project_keyword is required',
                    validate: {
                      positive: v => parseInt(v) >= 0 || 'This value should be greater than 0',
                    },
                    valueAsNumber: true,
                  }}
                />
              </Col>
              {
                editOrganization &&
                <Col md={6}>
                  <DatePickerWithLabel
                    label={'Activation date'}
                    selected={activationDate}
                    onChange={(date) => setActivationDate(date)}
                  />
                </Col>
              }
            </Row>
            <Row className={'w-100'}>
              <Col md={6}>
                <RegisteredInputField
                  title={'Max number of Be My Analyst Tickets'}
                  name={'be_my_analyst_ticket'}
                  register={register}
                  errors={errors}
                  rules={{
                    required: 'The be_my_analyst_ticket is required',
                    validate: {
                      positive: v => parseInt(v) >= 0 || 'This value should be greater than 0',
                    },
                    valueAsNumber: true,
                  }}
                />
              </Col>
              <Col md={6}>
                <RegisteredInputField
                  title={'Max number of Takedown Tickets'}
                  name={'takedown_ticket'}
                  register={register}
                  errors={errors}
                  rules={{
                    required: 'The takedown_ticket is required',
                    validate: {
                      positive: v => parseInt(v) >= 0 || 'This value should be greater than 0',
                    },
                    valueAsNumber: true,
                  }}
                />
              </Col>
            </Row>
            <Row className={'w-100'}>
              <Col>
                <RegisteredCheckBoxField
                  name={'has_custom_logo'}
                  value={watch('has_custom_logo')}
                  onChange={() => {
                  }}
                  defaultChecked={false}
                  label={"Has custom logo"}
                  register={register}
                />
              </Col>
              <Col>
                <RegisteredCheckBoxField
                  name={'active'}
                  value={watch('active')}
                  onChange={() => {
                  }}
                  defaultChecked={defaultValues.active}
                  label={'Is Active'}
                  register={register}
                />
              </Col>
            </Row>
            <Row className={'w-100 mt-3'}>
              <Col xs={12}><p className="form__form-group-label py-2"><b>Ticket coins</b> (actual
                value <b>{defaultValues['be_my_analyst_coins']}</b>) </p></Col>
              <Col md={6}>
                <RegisteredInputField
                  title={'Number of ticket coins to add'}
                  name={'add_be_my_analyst_coins'}
                  register={register}
                  errors={errors}
                  rules={{
                    validate: {
                      positive: v => parseInt(v) >= 0 || 'This value should be greater than 0',
                    },
                    value: 0,
                    valueAsNumber: true,
                  }}
                />
              </Col>
              <Col md={6}>
                {
                  !(
                    env_const.is_on_prem || env_const.react_app_auth_provider === APP_LOCAL_PROVIDER ||
                    !canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role)
                  ) &&
                  <>
                    <ControlledSelectWithTitle
                      name={'managed_by'} title={'Managed By'} control={control}
                      defaultValue={editOrganization && defaultValues.managed_by ? defaultValues.managed_by : organizationOptions[0]}
                      isDisabled={!canRoleUpdateAllUsersFields(dataCurrentUser?.data?.role)}
                      options={organizationOptions}
                      valueFn={(value) => organizationOptions.find((c) => c.value === value?.value)}
                    />
                    {errors.organization_id &&
                      <span className="form__form-group-error">{errors.organization_id.message}</span>}
                  </>
                }
              </Col>
            </Row>
            <Row className={'w-100 mt-3'}>
              <Col xs={12}><p className="form__form-group-label py-2"><b>Modules</b></p></Col>
              <Permissions
                register={register}
                watch={watch}
                groups={groups}
                setValue={setValue}
                onPermissionChange={updateSelection}
                role={CUSTOMER_ROLE}
              />
            </Row>
          </form>
        </CardBody>
      </Card>
      {
        editOrganization &&
        <Card>
          <CardBody>
            <form className="form form--vertical">
              <Row className={'w-100'}>
                <Col xs={12}><p className="form__form-group-label py-2"><b>Taxii organization's account</b></p></Col>
                <Col md={3} sm={6} xs={12} className={'pt-2'}>
                  <RegisteredPasswordField
                    title={'Taxii Account Password'}
                    name={'taxii_account_password'}
                    register={taxiiAccountRegister}
                    errors={taxiiAccountErrors}
                    placeholder={'taxii account password'}
                    // rules={{required: 'The taxii account password is required'}}
                  />
                </Col>
                <Col md={3} sm={6} xs={12} className={'pt-2'}>
                  <div style={{height: '100%', display: 'flex', alignItems: 'flex-end'}}>
                    <ConfirmModal
                      onConfirm={onAddTaxiiAccountConfirm}
                      btnText={`Create taxii account`}
                      title={"Create taxii account"}
                      body={`Are you sure to create a taxii account for organization '${defaultValues.name}'?`}
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
      }
      {
        editOrganization && defaultValues?.org_type === 'mssp' &&
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}><p className="form__form-group-label py-2"><b>Organization quotas</b></p></Col>
              <Col md={3} sm={6} xs={12} className={'pt-2'}>
                <ConfirmModal
                  onConfirm={() => onReportQuotaPurchaseConfirm()}
                  btnText={`Add report quotas`}
                  title={"Add organization quotas"}
                  body={`Are you sure to add report and attachments quotas? You'll add +12 report quotas to the actual number available.`}
                />
              </Col>
              <Col md={3} sm={6} xs={12} className={'pt-2'}>
                <ConfirmModal
                  onConfirm={() => onInvestigateQuotaPurchaseConfirm()}
                  btnText={`Add investigate quotas`}
                  title={"Add organization quotas"}
                  body={`Are you sure to add investigate quotas? You'll add +6 sandbox, +2 retrohunt and +12 genes analysis quotas to the actual number available.`}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      }
    </Container>
  )
}


OrganizationForm.propTypes = {}

export default OrganizationForm;
