import React from 'react';
import paths from "../../../../config/paths";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import DownIcon from "mdi-react/ChevronDownIcon";
import TopbarNavLink from "./TopbarNavLink";
import TopbarNavCategory from "./TopbarNavCategory";
import {
  PLATFORM_FEATURE_ACTORS, PLATFORM_FEATURE_APPLICATIONS,
  PLATFORM_FEATURE_CONTENTS, PLATFORM_FEATURE_GENES_ANALYSIS,
  PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS,
  PLATFORM_FEATURE_INTELLIGENCE, PLATFORM_FEATURE_INVESTIGATE, PLATFORM_FEATURE_OBSERVABLES,
  PLATFORM_FEATURE_ORGANIZATIONS,
  PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS, PLATFORM_FEATURE_RETROHUNT, PLATFORM_FEATURE_SANDBOX,
  PLATFORM_FEATURE_TAKEDOWN_TICKETS,
  PLATFORM_FEATURE_TICKETS_ANALYSIS,
  PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS,
  PLATFORM_FEATURE_USERS
} from "../../../../shared/helpers/features";
import env_const from "../../../../config/env_const";
import {canAccessModule} from "../../layoutUtils";

const _ = require('lodash');

export const getRoutePath = (name) => {
  let path = '';
  switch (name) {
    case PLATFORM_FEATURE_APPLICATIONS:
      path = paths.listApplicationPath
      break;
    case PLATFORM_FEATURE_OBSERVABLES:
      path = paths.indicatorPath
      break;
    case PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS:
      path = paths.resourceMonitorPath
      break;
    case PLATFORM_FEATURE_ACTORS:
      path = paths.actorPath
      break;
    case PLATFORM_FEATURE_CONTENTS:
      path = paths.contentsPath
      break;
    case PLATFORM_FEATURE_INVESTIGATE:
      path = paths.investigatePath
      break;
    case PLATFORM_FEATURE_GENES_ANALYSIS:
      path = paths.geneAnalysisPath
      break;
    case PLATFORM_FEATURE_SANDBOX:
      path = paths.sandboxTasksPath
      break;
    case PLATFORM_FEATURE_RETROHUNT:
      path = paths.retroHuntTasks
      break;
    case PLATFORM_FEATURE_TICKETS_ANALYSIS:
      path = paths.ticketsPath
      break;
    case PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS:
      path = paths.intelligenceIdentityPath
      break;
    case PLATFORM_FEATURE_INTELLIGENCE:
      path = paths.intelligenceSearchPath
      break;
    case PLATFORM_FEATURE_TAKEDOWN_TICKETS:
      path = paths.takeDownServicePath
      break;
    case PLATFORM_FEATURE_ORGANIZATIONS:
      path = paths.listOrganizationPath
      break;
    case PLATFORM_FEATURE_USERS:
      path = paths.listUserPath
      break;
    case PLATFORM_FEATURE_TYPOSQUATTING_PROJECTS:
      path = paths.typoSquattingPath
      break;
    default:
      break;
  }
  return path;
}

const TopbarNav = ({user, modules}) => {
  let userModules = user?.modules;
  let allowMitreFeatures = false;
  return (
    <nav className="topbar__nav">
      {
        userModules && modules && modules.map(
          (m) => {
            if (!canAccessModule(user, m.name)) return <></>;
            const userModule = _.find(userModules, {name: m.name});
            return (
              <UncontrolledDropdown className="topbar__nav-dropdown">
                <DropdownToggle
                  className="topbar__nav-dropdown-toggle"
                >
                  {m.value.toUpperCase()}<DownIcon/>
                </DropdownToggle>
                <DropdownMenu className="topbar__nav-dropdown-menu dropdown__menu">
                  {m.features && m.features.map((f, index) => {
                    const topbarNavLinks = [];
                    const userFeature = userModule ? _.find(userModule.features, {name: f.name}) : undefined;

                    const icon = !userFeature ? 'lock' : ''
                    if (f.name === PLATFORM_FEATURE_CONTENTS && userFeature) allowMitreFeatures = true

                    topbarNavLinks.push(
                      <DropdownItem id={`dropdown-item-${index}`} disabled={!userFeature}>
                        <TopbarNavLink title={_.capitalize(f.value)} route={getRoutePath(f.name)}
                                       icon={icon}
                        />
                      </DropdownItem>
                    )

                    if (f.name === PLATFORM_FEATURE_CONTENTS) {
                      if (env_const.is_on_prem) {
                        topbarNavLinks.push(
                          <>
                            <DropdownItem disabled={!userFeature}>
                              <TopbarNavLink
                                title={'Activities'} route={paths.activitiesPath} icon={icon}/>
                            </DropdownItem>
                            <DropdownItem disabled={!userFeature}>
                              <TopbarNavLink
                                title={'Malware Families'} route={paths.malwareFamilyBasePath} icon={icon}/>
                            </DropdownItem>
                          </>
                        )
                      } else {
                        topbarNavLinks.push(
                          <>
                            <DropdownItem disabled={!userFeature}>
                              <TopbarNavLink
                                title={'Activities'} route={paths.activitiesPath} icon={icon}/>
                            </DropdownItem>
                            <DropdownItem disabled={!userFeature}>
                              <TopbarNavLink
                                title={'Ransomware Tracker'} route={paths.ransomwareTrackerEventsPath} icon={icon}/>
                            </DropdownItem>
                            <DropdownItem disabled={!userFeature}>
                              <TopbarNavLink
                                title={'News Feed'} route={paths.feedlyFeedsPath} icon={icon}/>
                            </DropdownItem>
                            <DropdownItem disabled={!userFeature}>
                              <TopbarNavLink
                                title={'Malware Families'} route={paths.malwareFamilyBasePath} icon={icon}/>
                            </DropdownItem>
                          </>
                        )
                      }
                    } else if (m.name === 'triage' && m.features.length === index+1) {
                      const icon = !allowMitreFeatures ? 'lock' : ''
                      topbarNavLinks.push(
                        <>
                          <TopbarNavCategory title="Mitre">
                            <DropdownItem disabled={!allowMitreFeatures}>
                              <TopbarNavLink
                                title={'Commands'} route={paths.commandsPath} icon={icon}/>
                              <TopbarNavLink
                                title={'Rules'} route={paths.rulesSectionPath} icon={icon}/>
                            </DropdownItem>
                          </TopbarNavCategory>
                        </>)
                    } else if (
                      env_const.is_on_prem && (
                        [
                          PLATFORM_FEATURE_TAKEDOWN_TICKETS, PLATFORM_FEATURE_IDENTITY_MONITOR_PROJECTS,
                          PLATFORM_FEATURE_RESOURCE_MONITOR_PROJECTS
                        ].includes(f.name)
                      )
                    ) {
                      return <></>
                    }
                    return topbarNavLinks;
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }
        )
      }
    </nav>
  )
}


export default TopbarNav;
