import PropTypes from "prop-types";
import {useFeedlyFeedsQuery} from "../../../queries/FeedlyFeeds";

export const useFeedlyFeedQueryWithDict = ({
     limit = 0,
     offset = 0,
     order_by = '',
     search_term = '',
     source_title = '',
     start = '',
     end = ''
   }) => {
  return useFeedlyFeedsQuery(
    limit, offset, order_by, search_term, source_title, start, end
  )
}


useFeedlyFeedQueryWithDict.propTypes = {
  filters: PropTypes.shape().isRequired,
  limit: PropTypes.number
};