import React from "react";
import {useParams} from "react-router-dom";
import {useUserDetailsQuery} from "../../queries/Users";
import UserForm from "../../shared/components/form/user-form/UserForm";
import {useEditUserMutation} from "../../mutations/UpdateUserMutation";
import ErrorHandler from "../../shared/components/ErrorHandler";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {getUserDefaultValue} from "../../shared/helpers/contents";
import {PLATFORM_FEATURE_USERS, PLATFORM_PERMISSION_EDITOR} from "../../shared/helpers/features";
import Page from "../../shared/components/Page";
import LoadingSpinnerPage from "../../shared/components/LoadingSpinnerPage";


const UserEdit = () => {
  const { uid:uid } = useParams();
  const { data, isError, isLoading, isIdle, error } = useUserDetailsQuery(uid);

  if (isLoading || isIdle) {
    return <LoadingSpinnerPage />
  }

  if (isError) {
    return <ErrorHandler error={error} />
  }

  const defaultValues = getUserDefaultValue(data.data);

  return (
    <Page
      feature={PLATFORM_FEATURE_USERS}
      permission={PLATFORM_PERMISSION_EDITOR}
      onlyOnline
    >
      <UserForm
        defaultValues={defaultValues}
        mutationFn={useEditUserMutation}
        editUid={data.data.uid}
        userOrganization={data.data.organization}
        editForm
      />
    </Page>
  )
}

export default UserEdit;
