import React, {useState} from "react";
import PropTypes from "prop-types";
import TableWithDataHookPagination from "../../../../../shared/components/table/TableWithDataHookPagination";
import {useIntelResults} from "../../../../../queries/IdentityMonitorResults";
import {INTELX_TABLE_COLUMNS} from '../../../../../shared/components/tab/IntelxStatsTabsResult';
import {getFilterField} from "../../../../../shared/helpers/intelligence";


const IntelSectTableWithPagination = ({
  project_uid,
  type,
  totalItems,
  extraColumnsAfter = [],
  extraColumnsBefore = [],
  customSort = false
}) => {
  const [orderByFilter, setOrderByFilter] = useState("")
  const [orderBy, setOrderBy] = useState('')
  const tableConfig = {
    withPagination: true,
    withSearchEngine: false,
    isSortable: !customSort,
  }

  const customOnHeaderClick = (target) => {
    setOrderByFilter(getFilterField(target))
  }

  const customOnHeaderIconClick = (target) => {
    setOrderBy(target)
  }

  return (<TableWithDataHookPagination
    tableColumns={extraColumnsBefore.concat(INTELX_TABLE_COLUMNS.concat(extraColumnsAfter))}
    tableConfig={tableConfig}
    useDataQuery={useIntelResults}
    type={type}
    queryParams={project_uid}
    totalItems={totalItems}
    orderByFilter={orderByFilter}
    orderBy={orderBy}
    onHeaderClick={customOnHeaderClick}
    onHeaderIconClick={customOnHeaderIconClick}
  />)
}


IntelSectTableWithPagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  project_uid: PropTypes.string.isRequired,
  extraColumnsAfter: PropTypes.array,
  extraColumnsBefore: PropTypes.array,
  customSort: PropTypes.bool
}


export default IntelSectTableWithPagination;
