import React, {Fragment, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import paths from "../../../config/paths";
import SearchBarOnEnter from "../../../shared/components/search-menu/SearchBarOnEnter";
import {Col, Container, Row} from "reactstrap";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import {isIP, isValidBotnetSearchValue, isValidDomain} from "../../../shared/helpers/validators";
import {useCredentialExposureQuery} from "../../../queries/DarkWeb/CredentialExposure";
import {useBotnetExposureQuery} from "../../../queries/DarkWeb/BotnetExposure";
import {useDarkSearchQuery} from "../../../queries/DarkWeb/DarkSearch";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import DarkSearchDomainResults from "../components/DarkSearchDomainResults";
import logo from "../../../shared/img/iceberg.svg";
import AppError from "../../DefaultPage/AppError";
import {useIntelxSearchStatsQuery} from "../../../queries/Intelx";
import {paramsToObject, useQueryParams} from "../../../shared/components/router/QueryNavigationHelpers";
import {useUpdateIntelligence} from "../../../mutations/UpdateInteligence";
import {getErrorMessageFromResponse} from "../../../shared/helpers";
import toast from "react-hot-toast";
import LimitModal from "../../../shared/components/modal/LimitModal";
import {useCurrentUserQuery} from "../../../queries/CurrentUser";
import _ from "lodash";
import is_ip_private from "private-ip";
import {hasUserEditorPermissionOnFeature} from "../../../shared/helpers/permissions";
import {PLATFORM_FEATURE_INVESTIGATE} from "../../../shared/helpers/features";
import {download_file} from "../../../shared/helpers/download_file";
import {apiUrlInternal} from "../../../config/api";
import {useCookieAccessToken} from "../../../queries";
import {ADMIN_ROLE, EDITOR_ROLE, SALES_ROLE} from "../../../shared/helpers/user";

const OnlineIntelligenceSearch = () => {
  const params = paramsToObject(useQueryParams().entries());
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState(params.keyword || "");
  const [isEmptySearch, setIsEmptySearch] = useState(true);
  const [searchStartStatus, setSearchStartStatus] = useState(false);
  const [intelResults, setIntelResults] = useState({});
  const [intelSearchTerm, setIntelSearchTerm] = useState("");
  const [searchHasResults, setSearchHasResults] = useState(false);
  const {data: accessToken} = useCookieAccessToken();
  const isKeywordValid = (keyword) => keyword.length > 5 && searchStartStatus;
  const [isPrivateIp, setIsPrivateIp] = useState(isIP(params.keyword) && is_ip_private(params.keyword))

  const {
    data: dataCredential,
    isLoading: isLoadingCredential,
    isError: isErrorCredential,
    error: errorCredential
  } = useCredentialExposureQuery(isKeywordValid(intelSearchTerm) ? intelSearchTerm : null);
  const {
    data: dataBotnet,
    isLoading: isLoadingBotnet,
    isError: isErrorBotnet,
    error: errorBotnet
  } = useBotnetExposureQuery(isValidBotnetSearchValue(intelSearchTerm) && isKeywordValid(intelSearchTerm) ? searchTerm : null);
  const {
    data: dataDarkSearch,
    isLoading: isLoadingDarkSearch,
    isError: isErrorDarkSearch,
    error: errorDarkSearch
  } = useDarkSearchQuery(isKeywordValid(intelSearchTerm) ? intelSearchTerm : null);
  const {
    data: dataIntelligenceStats,
    isLoading: isLoadingIntelligenceStats,
    isError: isErrorIntelligenceStats,
    error: errorIntelligenceStats
  } = useIntelxSearchStatsQuery(isKeywordValid(intelSearchTerm) ? intelSearchTerm : null);

  const {
    data: dataCurrentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser,
    error: errorCurrentUser
  } = useCurrentUserQuery();


  const [limitModalStatus, setLimitModalStatus] = useState(false);

  const mutation = useUpdateIntelligence();

  useEffect(() => {
    if (searchTerm && params.keyword !== searchTerm) {
      history.push(`${paths.intelligenceSearchPath}?keyword=${searchTerm}`);
    }
    setIsEmptySearch(!searchTerm);
  }, [searchTerm])

  const isLoadingResults = isLoadingDarkSearch || isLoadingCredential || isLoadingBotnet || isLoadingIntelligenceStats || isLoadingCurrentUser;
  const isErrorResults = isErrorDarkSearch && isErrorBotnet && isErrorCredential && isErrorIntelligenceStats && isErrorCurrentUser;
  const emptyResult = {data: {total: 0}};

  useEffect(() => {
    if (!_.isUndefined(dataDarkSearch) || !_.isUndefined(dataBotnet) || !_.isUndefined(dataCredential) || !_.isUndefined(dataIntelligenceStats))
      setIntelResults({
        dataCredential: _.isUndefined(dataCredential) ? emptyResult : dataCredential,
        dataBotnet: _.isUndefined(dataBotnet) ? emptyResult : dataBotnet,
        dataDarkSearch: _.isUndefined(dataDarkSearch) ? emptyResult : dataDarkSearch,
        dataIntelligenceStats: _.isUndefined(dataIntelligenceStats) ? emptyResult : dataIntelligenceStats,
      });

    if (!_.isEmpty(intelResults)) {
      let totalIntelx = 0;

      const filteredDataBotnet = intelResults.dataBotnet.data.total > 0 ?
        intelResults.dataBotnet.data.events.filter((e) => e.isp && e.ip && e.ip !== 'NULL') : [];
      _.forEach(intelResults.dataIntelligenceStats?.data, (value,) => {
        totalIntelx += value
      });

      if (
        intelResults.dataDarkSearch.data.total +
        intelResults.dataCredential.data.total +
        filteredDataBotnet.length +
        totalIntelx !== 0
      ) {
        setSearchHasResults(true);
      }

    }

  }, [dataCredential?.data, dataBotnet?.data, dataDarkSearch?.data, dataIntelligenceStats?.data])

  useEffect(() => {
    if (!mutation.isIdle && !mutation.isLoading) {
      if (mutation.isSuccess) {
        toast.dismiss();
        setSearchStartStatus(true);
        setIntelSearchTerm(searchTerm);
        setSearchHasResults(false);
      } else if (mutation.isError) {
        toast.error(`Error: ${getErrorMessageFromResponse(mutation)}`);
      }
      mutation.reset();
    }
  }, [mutation.isIdle, mutation.isError, mutation.isLoading, mutation.error, mutation.isSuccess]);

  let ErrorHandlerForm = <Fragment/>;

  if (isErrorResults || isPrivateIp) {
    const error = errorDarkSearch?.request || errorCredential?.request || errorBotnet?.request || errorIntelligenceStats?.request || errorCurrentUser?.request;
    if (!error) {
      ErrorHandlerForm = <Col>
        <AppError header={"Insert a valid IP address"} msg={"You cannot search for internal IP address"}/>
      </Col>
    }
    else {
      if (error.status === 400) {
        ErrorHandlerForm =
          <Col><AppError header={" "} msg={`The domain "${searchTerm}" is too generic. Try a different one.`}/></Col>
      } else {
        ErrorHandlerForm = <Col><ErrorHandler error={error}/></Col>;
      }
    }
  }

  const customHandle = (val) => {
    if (val && val.length > 0) {
      setLimitModalStatus(true);
      setSearchStartStatus(false);
    } else {
      toast.error('input is missing');
    }
  }

  const onChange = (e) => {
    setIsPrivateIp(isIP(e.target.value) && is_ip_private(e.target.value));
  }
  return (
    <Fragment>
      {(isEmptySearch || _.isEmpty(intelResults)) && !isLoadingResults && !isErrorResults &&
        <img src={logo} alt={'Darkweb icon'} title={'Darkweb icon'} className={"img__darkweb-icon"}/>}
      {!isPrivateIp && limitModalStatus &&
        <LimitModal
          onDoAction={() => {
            toast.loading('Validating your search')
            setLimitModalStatus(false);
            mutation.mutate({
              q: searchTerm,
              uid: dataCurrentUser.data.organization.uid
            });
          }}
          onCancel={() => {
            setLimitModalStatus(false);
          }}
        />
      }
      <Container>
        <Row>
          <Col md={12}>
            <SearchBarOnEnter
              searchPlaceholder={'Type any keyword and press enter'}
              setSearch={setSearchTerm}
              searchTerm={searchTerm}
              errorMessage={searchTerm && searchTerm.length <= 5 && 'Insert a keyword longer than 5 characters'}
              withSearchButton
              customHandle={customHandle}
              buttonDisabled={isPrivateIp}
              onChangeHandler={onChange}
              searchDisabled={
                !hasUserEditorPermissionOnFeature(dataCurrentUser?.data?.modules || [], PLATFORM_FEATURE_INVESTIGATE) ||
                isPrivateIp
              }
            />
          </Col>
        </Row>
        {
          isValidDomain(searchTerm) && [EDITOR_ROLE, ADMIN_ROLE, SALES_ROLE].includes(
            dataCurrentUser?.data?.role
          ) && searchStartStatus &&
          <a href={''} className={'a-colored'} onClick={
            (e) => {
              e.preventDefault();
              download_file(
                `${apiUrlInternal}/intelligence/report?query=${searchTerm}`,
                `report_${searchTerm}.pdf`,
                accessToken,
                600000,
                'Creating the report, it can take few minutes'
              );
            }
          }>Click here to download the intelligence report on {searchTerm}</a>
        }
        {searchHasResults && isLoadingResults && <Row><LoadingSpinner/></Row>}
        <Row>
          {
            (isErrorResults || isPrivateIp) ? ErrorHandlerForm :
              (isEmptySearch || _.isEmpty(intelResults)) && !isLoadingResults ?
                <Col>
                  <h2 className={"h2_darkweb-title"}>Explore the Dark Web</h2>
                  <h3 className={"h3_darkweb-subtitle"}>
                    Try typing any keyword in the search bar to discover the related information gathered through our
                    intelligence.
                  </h3>
                </Col> :
                !searchHasResults && isLoadingResults ? <LoadingSpinner/> :
                  <DarkSearchDomainResults
                    dataCredential={isErrorCredential ? emptyResult : intelResults.dataCredential}
                    dataBotnet={isErrorBotnet || !isValidBotnetSearchValue(intelSearchTerm) ? emptyResult : intelResults.dataBotnet}
                    dataDarkSearch={isErrorDarkSearch ? emptyResult : intelResults.dataDarkSearch}
                    dataIntelligenceStats={isErrorIntelligenceStats ? {} : intelResults.dataIntelligenceStats}
                    keyword={intelSearchTerm}
                    isLoadingResults={isLoadingResults}
                  />
          }
        </Row>
      </Container>
    </Fragment>
  )
}


export default OnlineIntelligenceSearch;
