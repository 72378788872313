import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {useCookieAccessToken} from "../../../queries";
import DownloadIcon from "mdi-react/DownloadIcon";
import {download_file} from "../../helpers/download_file";
import {apiUrlInternal} from "../../../config/api";


const DownloadSha256Icon = ({sha256}) => {
  const {data: accessToken} = useCookieAccessToken();

  const classes = classNames({
    'border-hover-white': true
  })

  return (
    <DownloadIcon
      onClick={() => download_file(`${apiUrlInternal}/indicators/download/${sha256}`, sha256, accessToken)}
      size={24}
      style={{fill: 'white', padding: '2px'}}
      className={classes}
    />
  )
}


DownloadSha256Icon.propTypes = {
  sha256: PropTypes.string.isRequired
}


export default DownloadSha256Icon;
