import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import React, {Fragment} from "react";
import ActorCard from "./withIcon/ActorCard";
import ActorsDetailTab from "../tab/ActorsDetailTab";


const ActorsCard = ({
  actors,
  title,
  isKnown = true,
  classNames = ''
}) => {

  if (!isKnown) {
    return <Fragment />
  }

  return (
    <Card className={'h-auto card--related-actors ' + classNames}>
      <CardBody className={'pl-0 pr-0 pb-0'}>
        {
          title &&
            <div className="card__title card__tile__related-actor">
              <h4 className={'bold-text d-inline'}>{title}</h4>
            </div>
        }
        {
          actors.length <= 1 &&
          <ActorCard actorUid={actors[0]?.uid} /> || <ActorsDetailTab  actors={actors}/>
        }
      </CardBody>
    </Card>
  )
}


ActorsCard.propsType = {
  actors: PropTypes.array.isRequired,
  isKnown: PropTypes.bool,
  title: PropTypes.string.isRequired,
  classNames: PropTypes.string.isRequired,
}

export default ActorsCard;
