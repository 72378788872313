import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import DnsResolutionTable from "./DnsResolutionTable";

const DnsResolutionTableCard = ({dns_resolutions}) =>
  <Card className={'h-auto'}>
    <CardBody>
      <div className="card__title">
        <h4 className={'bold-text d-inline'}>DNS Telemetries</h4>
      </div>

      <DnsResolutionTable
        resolutions={dns_resolutions?.resolutions ? dns_resolutions.resolutions : []}
      />
    </CardBody>
  </Card>


DnsResolutionTableCard.propTypes = {
  dns_resolutions: PropTypes.object.isRequired,
}


export default DnsResolutionTableCard;
