import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import _ from "lodash";
import BotNetTable from "../../../shared/components/darkweb/BotNetTable";
import CredentialTable from "../../../shared/components/darkweb/CredentialTable";
import DarksearchBotsTable from "../../../shared/components/darkweb/DarksearchBotsTable";
import SellersTable from "../../../shared/components/darkweb/SellersTable";
import ThreadsTable from "../../../shared/components/darkweb/ThreadsTable";
import moment from "moment";
import {useIndicatorDetailsByValueQuery} from "../../../queries/IndicatorInvestigate";
import ErrorHandler from "../../../shared/components/ErrorHandler";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import {intelligenceSectionsOnly} from "../../../shared/helpers/investigate";
import AndarielIntelligenceTable from "./AndarielIntelligenceTable";


const IntelligenceCard = ({indicator_details, onContentLoad, onContentLoadError}) => {
  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const { data, isIdle, isLoading, isError, error } = useIndicatorDetailsByValueQuery(
    indicator_details.indicator, indicator_details.indicator_type, true, intelligenceSectionsOnly
  );

  useEffect(() => {
    if (!isLoading && !isError && !isIdle) {
      onContentLoad();
    }
  }, [isIdle, isLoading, isError])


  if (isIdle || isLoading) {
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className={'bold-text d-inline'}>Intelligence</h4>
            <p className="subhead">Related data retrieved by out intelligence</p>
          </div>
          <LoadingSpinner/>
        </CardBody>
      </Card>
      );
  }

  if (isError) {
    onContentLoadError();
    return <Row><Col><ErrorHandler error={error}/></Col></Row>
  }

  const intelligence = data?.data?.intelligence;

  return <AndarielIntelligenceTable indicator_details={indicator_details} intelligence={intelligence} />

  // const tabs = _.omitBy({
  //   'BOTNET': intelligence?.botnets?.events,
  //   'CREDENTIAL': intelligence?.credentials?.credentials,
  //   'BOTS': intelligence?.darknet?.bots,
  //   'SELLERS': intelligence?.darknet?.sellers,
  //   'THREADS': intelligence?.darknet?.threads
  // }, (v) => _.isNull(v) || _.isUndefined(v) || _.isEmpty(v));
  //
  //
  // const renderIntelligenceTable = (item) => {
  //   if (item === 'BOTNET') {
  //     return <BotNetTable botnets={tabs[item]}/>
  //   } else if (item === 'CREDENTIAL') {
  //     return <CredentialTable credentials={
  //       _.sortBy(
  //         tabs[item].filter((c) => moment(c.dumpdate, "YYYY-MM-DDTHH:mm:ss.SSSSSS", true).isValid()), c => c.dumpdate
  //       ).reverse()
  //     }/>
  //   } else if (item === 'BOTS') {
  //     return <DarksearchBotsTable bots={_.sortBy(tabs[item], (t) => t.created_dt).reverse()}/>
  //   } else if (item === 'SELLERS') {
  //     return <SellersTable sellers={_.sortBy(tabs[item], (t) => t.created_dt).reverse()}/>
  //   } else if (item === 'THREADS') {
  //     return <ThreadsTable threads={_.sortBy(tabs[item], (t) => t.created_dt).reverse()}/>
  //   }
  // }
  //
  //
  // return (
  //   <Card>
  //     <CardBody>
  //       <div className="card__title">
  //         <h4 className={'bold-text d-inline'}>Intelligence</h4>
  //         <p className="subhead">Related data retrieved by out intelligence</p>
  //       </div>
  //
  //       {
  //         _.keys(tabs).length > 0 ?
  //           <div className="tabs tabs--bordered-bottom">
  //             <div className="tabs__wrap">
  //               <Nav tabs>
  //                 {
  //                   _.keys(tabs).map((item, index) =>
  //                     <NavItem key={index}>
  //                       <NavLink
  //                         className={classnames({active: activeTab === index})}
  //                         onClick={() => toggleTab(index)}
  //                       >{item} ({tabs[item].length})</NavLink>
  //                     </NavItem>
  //                   )
  //                 }
  //               </Nav>
  //               <TabContent activeTab={activeTab}>
  //                 {
  //                   _.keys(tabs).map((item, index) =>
  //                     <TabPane tabId={index} key={index}>
  //                       {renderIntelligenceTable(item)}
  //                     </TabPane>
  //                   )
  //                 }
  //               </TabContent>
  //             </div>
  //           </div> :
  //           <h4>No intelligence results related to {indicator_details.indicator}</h4>
  //       }
  //
  //     </CardBody>
  //   </Card>
  // )
}


IntelligenceCard.propTypes = {
  indicator_details: PropTypes.object.isRequired,
  onContentLoad: PropTypes.func.isRequired,
  onContentLoadError: PropTypes.func.isRequired
}

export default IntelligenceCard;
