import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import IndicatorRiskScoreGauge from "../../IndicatorRiskScoreGauge";
import RelatedActorsCard from "../../RelatedActorsCard";
import ContentTimeLineCard from "../../ContentTimeLineCard";
import IndicatorDetailFooter from "../../IndicatorDetailFooter";
import CounterAttackTableCard from "../../CounterAttackTableCard";
import _ from "lodash";
import FirstLastSeenRow from "../../FirstLastSeenRow";

const EmailDetails = ({indicator_details}) => {
  return (
    <Fragment>
      <Row>
        <Col lg={5} md={12}>
          <Row>
            <Col>
              <IndicatorRiskScoreGauge
                score={!indicator_details.whitelisted ? indicator_details.score : 0}
                tags={indicator_details.tags}
              />
              <FirstLastSeenRow last_seen={indicator_details.last_seen} first_seen={indicator_details.first_seen} />
              <RelatedActorsCard actors={indicator_details.actors} isKnown={indicator_details.is_known} />
            </Col>
          </Row>
        </Col>

        <Col lg={7} md={12}>
          <Row>
            <Col>
              {indicator_details.related_contexts.length > 0 && <ContentTimeLineCard contexts={indicator_details.related_contexts}/>}
              {
                indicator_details.attacker_activities.length > 0 &&
                <CounterAttackTableCard
                  attacker_activities={indicator_details.attacker_activities}
                  extraColumns={[{
                    id: 'Malware',
                    Header: 'Malware',
                    accessor: 'tags',
                    Cell: ({value}) => _.replace(_.split(value, ',')[0], 'malware:', '')
                  },
                  {
                    id: 'Family',
                    Header: 'Family',
                    accessor: 'tags',
                    Cell: ({value}) => _.replace(_.split(value, ',')[1], 'family:', '')
                  }
                  ]}
                />
              }
            </Col>
          </Row>
        </Col>
      </Row>

      <IndicatorDetailFooter indicator_details={indicator_details} />
    </Fragment>
  )
}

EmailDetails.propTypes = {
  indicator_details: PropTypes.object.isRequired,
}


export default EmailDetails;
